
export function isTable(template: string) {
    return template.indexOf('|') > 0;
}


export function parseTable<THeader, TBody>(template: string, replace: (field: string) => string, headerFactory: (field: string) => THeader, bodyFactory: (field: string) => TBody) {
    const parts = template.trim().split('|');
    const headerFields = [];
    const bodyTemplateFields = [];
    for (const part of parts) {
        const fieldParts = part.split('§');
        const field = replace(fieldParts[0]);
        headerFields.push(headerFactory(field));
        bodyTemplateFields.push(bodyFactory(fieldParts[fieldParts.length - 1]));
    }
    return { headerFields, bodyTemplateFields };
}



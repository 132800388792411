import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import { greekToLatin } from '../../../new_array';
import * as RICHTEXTHTML from '../../../richtext/html';
import { ServerConnection } from '../../../ui/RestAPI';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoMatchingImage_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

export class MatchingImageData_Answer {

    public readonly id: string;
    public readonly connectedTo = ko.observable<string>();
    public readonly correctConnectTo: string;
    public readonly text: string;

    constructor(readonly vm: MatchingImageData, readonly nAnswer: number) {
        const itemAnswers = vm._data.miAnswers;
        const answer = itemAnswers[nAnswer];
        this.text = translate(answer.value, {});
        this.id = answer.id;
        this.connectedTo(answer.selectedImage && answer.selectedImage.id);
        this.correctConnectTo = answer.correctImage && answer.correctImage.id;
    }
}

export class MatchingImageData_Image {
    public readonly id: string;
    public readonly latinId: string;
    public readonly href: string;
    public readonly alt: string;
    public readonly width: number;
    public readonly height: number;
    public readonly imageName: string;

    public isConnected() {
        return this.vm.answers.some(x => x.connectedTo() === this.id);
    }
    constructor(readonly vm: MatchingImageData, readonly nImage: number) {
        const itemImages = vm._data.images;
        const image = itemImages[nImage];

        this.id = image.id;
        this.latinId = greekToLatin(this.id.substr(-1));
        const imgAtt = vm.meta.getAttachment(image.miImage.id);
        this.href = ServerConnection.getDataUrl(imgAtt?.hrefResolved);
        this.width = imgAtt?.imageMetadata?.width ?? 0;
        this.height = imgAtt?.imageMetadata?.height ?? 0;
        this.imageName = imgAtt?.name ?? '';
        this.alt = imgAtt?.altText?.value ?? '';
    }
}

export class MatchingImageData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly _data: DATA) {
        super();
        this.headerText = translate(_data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(_data.question, {}),
            attachments: this.meta.attachments,
        });


        this.answers = _data.miAnswers.map((d, index) => new MatchingImageData_Answer(this, index));
        this.images = _data.images.map((d, index) => new MatchingImageData_Image(this, index));
        this.fromJS(_data);
    }
    public fromJS(data: DATA) {
    }
    public readonly imgUrl: string;
    public readonly headerText: string;
    public readonly questionHTML: string;

    public readonly answers: MatchingImageData_Answer[];
    public readonly images: MatchingImageData_Image[];

    public async connect({ answerId, imageId: imageId }: { answerId: string, imageId: string }) {
        if (!answerId || !imageId) {
            return;
        }

        for (const ans of this.answers) {
            if (ans.id === answerId) {
                ans.connectedTo(imageId);
            } else if (ans.connectedTo() === imageId) {
                ans.connectedTo(undefined);
            }
        }
    }

    public IsInteractionStarted() {
        return this.answers.some(x => !!x.connectedTo());
    }
    public IsInteractionComplete() {
        return this.answers.every(x => !!x.connectedTo());
    }
    public async reset() {
        for (const answer of this.answers) {
            answer.connectedTo(undefined);
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            MatchingImage: {
                answers: this.answers.map(x => ({
                    answerId: x.id,
                    imageId: x.connectedTo(),
                }))
            }
        };
        return retVal;
    }

}
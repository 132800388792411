import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { AS } from '../../../dx_helper';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_EXAMORDER from '../examorder/route';
import * as WIDGET_ITEMREVIEWSELECTION from '../itemreviewselection/route';
import * as WIDGET_TRANSLATION from '../translation/route';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

interface ICardAction {
    readonly caption: ko.Subscribable<string>;
    readonly action: UIAction<undefined>;
}

interface ICard {
    readonly cardTitle: ko.Subscribable<string>;
    readonly cardText: ko.Subscribable<string>;
    readonly actions: ko.Subscribable<ICardAction[]>;
    readonly defaultAction?: ko.Subscribable<ICardAction>;
}

class ExamOrdersCard implements ICard {
    constructor(readonly viewModel: ViewModel) {

    }
    public readonly cardTitle = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.home.EXAM_ORDER']);
    });
    public readonly cardText = ko.pureComputed(() => {
        return '';
    });
    public readonly defaultAction = ko.pureComputed(() => {
        return <ICardAction>{
            caption: ko.pureComputed(() => {
                return i18next.t(['ui.subjectcoordinator.home.OPEN']);
            }),
            action: new UIAction(undefined, async () => {
                const subject = this.viewModel.params.currentRoute.widgetParams.subjectDocRefId;
                await ROUTES.routeManager.navigateToHREF(WIDGET_EXAMORDER.FACTORY.href({
                    subject
                }));
            })
        };
    });
    public readonly actions = ko.pureComputed(() => {
        const retVal: ICardAction[] = [];
        if (this.defaultAction && this.defaultAction()) {
            retVal.push(this.defaultAction());
        }
        return retVal;
    });
}
class TranslationCard implements ICard {
    constructor(readonly viewModel: ViewModel) {

    }
    public readonly cardTitle = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.home.TRANSLATION']);
    });
    public readonly cardText = ko.pureComputed(() => {
        return '';
    });
    public readonly defaultAction = ko.pureComputed(() => {
        return AS<ICardAction>({
            caption: ko.pureComputed(() => {
                return i18next.t(['ui.subjectcoordinator.home.OPEN']);
            }),
            action: new UIAction(undefined, async () => {
                const subject = this.viewModel.params.currentRoute.widgetParams.subjectDocRefId;
                await ROUTES.routeManager.navigateToHREF(WIDGET_TRANSLATION.FACTORY.href({
                    subject
                }));
            })
        });
    });
    public readonly actions = ko.pureComputed(() => {
        const retVal: ICardAction[] = [];
        if (this.defaultAction && this.defaultAction()) {
            retVal.push(this.defaultAction());
        }
        return retVal;
    });
}

class ItemReviewSelectionCard implements ICard {
    constructor(readonly viewModel: ViewModel) {

    }
    public readonly cardTitle = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.home.ITEM_REVIEW']);
    });
    public readonly cardText = ko.pureComputed(() => {
        return '';
    });
    public readonly defaultAction = ko.pureComputed(() => {
        return <ICardAction>{
            caption: ko.pureComputed(() => {
                return i18next.t(['ui.subjectcoordinator.home.OPEN']);
            }),
            action: new UIAction(undefined, async () => {
                const subject = this.viewModel.params.currentRoute.widgetParams.subjectDocRefId;
                await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMREVIEWSELECTION.FACTORY.href({
                    subject
                }));
            })
        };
    });
    public readonly actions = ko.pureComputed(() => {
        const retVal: ICardAction[] = [];
        if (this.defaultAction && this.defaultAction()) {
            retVal.push(this.defaultAction());
        }
        return retVal;
    });
}
export class ViewModel extends AbstractWidget {
    public readonly topRoutes = ko.pureComputed(() => {
        const cards = [];
        cards.push(new ItemReviewSelectionCard(this));
        if (this.itemTranslationEnabled()) {
            cards.push(new TranslationCard(this));
        }
        cards.push(new ExamOrdersCard(this));
        return cards;
    });
    public readonly itemTranslationEnabled = ko.observable(false);
    constructor(readonly params: IParams) {
        super();
    }

    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.ui_subjectcoordinator_home_data({});
        this.itemTranslationEnabled(data.config.itemTranslationEnabled);
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly loaded = ko.observable(false);
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { log } from '../../../debug';
import { bounds, update_observable } from '../../../helper';
import * as ISO8601 from '../../../iso8601';
import { GetSession } from '../../../itemdefinition/model/session';
import * as API from '../../../its-itembank-api.g';
import { IMutationInteractionInput, SessionModesEnum, SessionStartModesEnum } from '../../../its-itembank-api.g';
import { ServerConnection } from '../../../ui/RestAPI';
import { Timer } from '../../../ui/timer';
import { detectScormWindow, sendScormData } from './scorm';

type Q = Awaited<ReturnType<API.Sdk['ui_service_participation_data']>>;
type M = Awaited<ReturnType<API.Sdk['ui_service_participation_ticktock']>>;
type TICKTOCK = M['examSession']['tickTock'];

let IDX = 0;

export type InteractionInput = IMutationInteractionInput;
export type ItemDynamicData = TICKTOCK['items'][0];
export type SectionDynamicData = TICKTOCK['sections'][0];
export type SessionDynamicData = {
    itemInfo: TICKTOCK['itemInfo'];
};


function msToCMIDuration(ms: number) {
    log(`msToCMIDuration(${ms} ms)`);
    let ts = ms / 1000;
    let sec = (ts % 60);

    ts -= sec;
    let tmp = (ts % 3600);  //# of seconds in the total # of minutes
    ts -= tmp;              //# of seconds in the total # of hours

    // convert seconds to conform to CMITimespan type (e.g. SS.00)
    sec = Math.round(sec * 100) / 100;

    let strSec = new String(sec);
    let strWholeSec = strSec;
    let strFractionSec = "";

    if (strSec.indexOf(".") != -1) {
        strWholeSec = strSec.substring(0, strSec.indexOf("."));
        strFractionSec = strSec.substring(strSec.indexOf(".") + 1, strSec.length);
    }

    if (strWholeSec.length < 2) {
        strWholeSec = "0" + strWholeSec;
    }
    strSec = strWholeSec;

    if (strFractionSec.length) {
        strSec = strSec + "." + strFractionSec;
    }

    let hour, min;
    if ((ts % 3600) != 0)
        hour = 0;
    else hour = (ts / 3600);
    if ((tmp % 60) != 0)
        min = 0;
    else min = (tmp / 60);


    const rtnVal = ('' + hour).padStart(2, '0') + ":" + ('' + min).padStart(2, '0') + ":" + ('' + strSec).padStart(2, '0');

    log(`msToCMIDuration(${ms} ms): ${rtnVal}`);
    return rtnVal;
}

export class ExamTickTock {

    private readonly id = `ExamTickTock#${IDX++}`;
    private started: Date;
    private timepassed = 0;
    private timepassed_lastsent = 0;

    private interactionQueue: InteractionInput[] = [];
    private navigatedToQueue: string[] = [];

    public readonly timer = new Timer();
    public sessionId: string;
    private iv = -1;

    private totalTime: number;
    private readonly timePassedSeconds_lastreceived = ko.observable<number>();
    private passedTimeUpdateInterval: number;
    public readonly timeleftMinutes = ko.observable<number>();
    public readonly timeleftPercent = ko.observable<number>();
    public readonly timePassedPercent = ko.observable<number>();
    public readonly startMode = ko.observable<SessionStartModesEnum>();
    public readonly mode = ko.observable<SessionModesEnum>();


    public readonly timePassedSeconds = ko.pureComputed(() => {
        return this.timer.timePassedSeconds() + this.timePassedSeconds_lastreceived();
    });
    public readonly timePassedMinutes = ko.pureComputed(() => {
        return Math.floor(this.timePassedSeconds() / 60);
    });


    constructor() {
        log(`Init new ExamTimer`);
    }

    public queueInteraction(data: InteractionInput) {
        this.interactionQueue.push(data);
    }
    public queueNavigatedTo(data: string) {
        if (data) {
            this.navigatedToQueue.push(data);
        }
    }

    public start() {
        if (this.started) {
            return;
        }
        if (!this.passedTimeUpdateInterval) {
            throw new Error('call ExamTickTock.init() first');
        }
        log(`${this.id}: Exam Timer started for ${this.sessionId}`);
        this.started = new Date();
        if (this.timer.timeLeftSeconds() > 0) {
            this.timer.start();
        }
    }

    public readonly items_total = ko.observable(0);
    public readonly items_pending = ko.observable(0);
    public readonly items_completed = ko.observable(0);
    public readonly items_locked = ko.observable(0);
    public readonly currentItem = ko.observable<string>();

    public readonly itemsData = new Map<string, ItemDynamicData>();
    public readonly sectionsData = new Map<string, SectionDynamicData>();
    public sessionData: SessionDynamicData;

    private update(data: Q['examSession'] | M['examSession']['tickTock']) {
        update_observable(this.items_total, data.itemInfo.total);
        update_observable(this.items_pending, data.itemInfo.pending);
        update_observable(this.items_completed, data.itemInfo.completed);
        update_observable(this.items_locked, data.itemInfo.locked);
        this.itemsData.clear();
        for (const item of data.items) {
            this.itemsData.set(item.itemDocRefId, item);
        }
        this.sectionsData.clear();
        for (const section of data.sections) {
            this.sectionsData.set(section.id, section);
        }
        this.sessionData = data;
    }
    public async stop() {
        this.collect();
        this.started = undefined;
        this.timer.stop();
        return this.send();
    }

    public collect() {
        const prev = this.started;
        const now = new Date();
        if (!prev) {
            return;
        }
        // the candidate may change the clock on his computer. The remaining time must only decrease. 
        // And adjusting the clock to much should not immediatly result in session termination.
        const tp = bounds(now.getTime() - prev.getTime(), 0, this.passedTimeUpdateInterval * 2 * 1000);
        this.timepassed += tp;
        this.started = now;
    }

    private sendScormData(q: TICKTOCK) {
        const wnd = detectScormWindow();
        if (!wnd) {
            return;
        }
        sendScormData(q.scormData);
    }
    public async send(options?: { submitted: string[] }) {
        clearTimeout(this.iv);
        if (this.mode() !== SessionModesEnum.Participating) {
            //don't update timer, because we are not participating anymore
            return;
        }
        this.collect();
        const diff = this.timepassed - this.timepassed_lastsent;
        const interactions = this.interactionQueue.splice(0, this.interactionQueue.length);
        const navigatedTo = this.navigatedToQueue.splice(0, this.navigatedToQueue.length);
        try {
            const examState = GetSession(this.sessionId).GetExamState();
            examState.interactions = interactions;
            examState.navigatedItems = navigatedTo;
            examState.submittedItems = options && options.submitted;

            /// MESSL, 2020-11-09 - update the lastsent before sending the data, because the server might count the time even tough the request might fail (http timeout)
            this.timepassed_lastsent = this.timepassed;

            const r = await ServerConnection.api.ui_service_participation_ticktock({
                session: this.sessionId,
                timePassedMS: diff,
                updateExamState: examState,
                objectives: !!detectScormWindow(),
            });
            const q = r.examSession.tickTock;
            this.update(q);
            const timeLeft = ISO8601.isoDurationToCentisec(q.timeLeft.timeLeft);
            update_observable(this.timeleftMinutes, Math.ceil(timeLeft / (100 * 60)));
            update_observable(this.timeleftPercent, Math.round(timeLeft * 100 / this.totalTime));
            update_observable(this.timePassedPercent, Math.round((this.totalTime - timeLeft) * 100 / this.totalTime));
            update_observable(this.timePassedSeconds_lastreceived, Math.round(ISO8601.isoDurationToCentisec(q.timeLeft.timePassed) / 100));
            update_observable(this.mode, q.mode);
            update_observable(this.startMode, q.startMode);
            log(`${this.id}: time left: ${this.timeleftMinutes()}, mode: ${this.mode()}, start mode: ${this.startMode()}`);
            this.timer.reset();
            this.timer.setTimeLimit(q.timeLeft.timeLeft);
            if (this.started) {
                this.timer.start();
            }
            this.sendScormData(q);
            const nextTockCS = Math.min(timeLeft, this.passedTimeUpdateInterval * 100);
            clearTimeout(this.iv);
            this.iv = <any>setTimeout(() => this.send(), nextTockCS * 10);
        } catch (e) {
            log(`There was an error while tickTock ${e.message}`);
            this.navigatedToQueue.unshift(...navigatedTo);
            this.interactionQueue.unshift(...interactions);
            await this.requery();
            if (this.mode() !== SessionModesEnum.Participating) {
                //don't update timer, because we are not participating anymore
                return;
            }
            clearTimeout(this.iv);
            this.iv = <any>setTimeout(() => this.send(), 10 * 1000);//retry in 10 seconds if there is an error
        }
    }

    private async requery() {
        log(`examTimer requery`);
        const r = await ServerConnection.api.ui_service_participation_data({
            session: this.sessionId
        });
        this.update(r.examSession);
        update_observable(this.mode, r.examSession.mode);
        update_observable(this.startMode, r.examSession.startMode);
        this.passedTimeUpdateInterval = r.config.passedTimeUpdateInterval;
        this.totalTime = ISO8601.isoDurationToCentisec(r.examSession.totalTime);

    }
    public async init(sessionId: string) {
        if (this.sessionId == sessionId) {
            return;
        }
        this.sessionId = sessionId;
        await this.requery();
        log(`${this.id}: Updating session passed time every ${this.passedTimeUpdateInterval} seconds`);
        await this.send();
    }

    public dispose() {
        if (this.iv >= 0) {
            clearTimeout(this.iv);
        }
    }
}
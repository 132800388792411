import notify from 'devextreme/ui/notify';
import * as i18next from './i18n/i18n';

export type toastTypeInfo = 'info' | 'warning' | 'error' | 'success';

class ToastService {
    public info(message: string) {
        if (!message) {
            return;
        }
        notify(message, 'info');
    }

    public warning(message: string) {
        if (!message) {
            return;
        }
        notify(message, 'warning');
    }

    public error(message: string) {
        if (!message) {
            return;
        }
        notify(message, 'error');
    }

    public success(message: string) {
        if (!message) {
            return;
        }
        notify(message, 'success');
    }
    public NYI() {
        this.error(i18next.t(['toastservice.THIS_PART_OF_THE_PROGRAM_IS_CURRENTLY_UNAVAILABLE']));
    }
}

export const toastService = new ToastService();

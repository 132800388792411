import * as ko from 'knockout';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ExaminationOrderStatus, ExamOrderSubjectStatus } from '../../../its-itembank-api.g';
import * as Modal from '../../../modal';
import { toastService } from '../../../toastService';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_examorderedit_route']>>;
export const WIDGET_NAME = 'ui-subjectcoordinator-examorderedit';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
    examOrderDocRefId: string;
}

export class ExamOrderEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;

    public static async CREATE(subjectDocRef: string, examOrderDocRef: string) {
        const d = await ServerConnection.api.ui_subjectcoordinator_examorderedit_route({
            examOrderDocRef,
            subjectDocRef
        });

        return new ExamOrderEditRoute(
            {
                subjectDocRefId: subjectDocRef,
                examOrderDocRefId: examOrderDocRef
            },
            d.examOrder.byId.title,
            d.examOrder.byId.status,
            d.examOrder.byId.subject.status
        );
    }


    public readonly routeActions = ko.pureComputed(() => {
        const actions: ROUTES.IRouteAction[] = [];
        if (this._examOrderStatus === ExaminationOrderStatus.Populating) {
            switch (this._subjectStatus) {
                case ExamOrderSubjectStatus.Open:
                    actions.push({
                        icon: 'check',
                        caption: i18next.t(['ui.subjectcoordinator.examorderedit.FINISH_EXAM_ORDER']),
                        action: this.actionFinishExamOrder
                    });
                    break;
            }
        }
        actions.push({
            icon: 'help',
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.HELP']),
            action: this.showHelp
        });
        return actions;
    });

    public readonly actionFinishExamOrder = new UIAction(undefined, async () => {
        const message = i18next.t(['ui.subjectcoordinator.examorderedit.ARE_YOU_SURE_AFTER_CLICKING_ON_FINISH_EXAM_ORDER_CHANGES_ARE_NO_LONGER_POSSIBLE']);

        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await legacyPushPull(async () => {
            await ServerConnection.api.ui_subjectcoordinator_examorderedit_finishsubject({
                examOrderDocRef: this.widgetParams.examOrderDocRefId,
                subjectDocRef: this.widgetParams.subjectDocRefId
            });
            return DONE;
        });
        await ROUTES.routeManager.back();
    });

    public readonly title = ko.pureComputed(() => this._title);

    constructor(
        readonly widgetParams: IParamsInner,
        readonly _title: string,
        readonly _examOrderStatus: ExaminationOrderStatus,
        readonly _subjectStatus: ExamOrderSubjectStatus) {
    }

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.subjectcoordinator.examorderedit.THE_GENERAL_DATA_OF_THE_EXAM_ORDER_IS_ONLY_USED_FOR_YOUR_INFORMATION_IT_HAS_BEEN_DEFINED_BY_THE_SUPERUSER_IT_IS_YOUR_TASK_TO_ALLOCATE_THE_TOTAL_POINTS_TO_THE_CATEGORIES_AND_OBJECTIVES_IF_YOU_ADD_AN_ITEM_TO_AN_EXAM_ORDER_IN_THE_ITEM_REVIEW_THIS_ITEM_IS_SHOWN_IN_THE_EXAM_ORDER']);

        toastService.info(msg);
    });
}

export const FACTORY = ROUTES.registerRoute<{ examorder: string, subject: string }>({
    route: '/subjectcoordinator/subject/:subject/examorder/:examorder',
    toParams: map => ({
        examorder: map.get('examorder'),
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('examorder', p.examorder);
        map.set('subject', p.subject);
    },
    create: async p => {
        const hc = await ExamOrderEditRoute.CREATE(p.subject, p.examorder);
        return hc;
    },
});

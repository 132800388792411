﻿import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { htmlString } from './widget.html.g';



const WIDGET_NAME = 'widgets-evaluationremark';
const WIDGET_PARENT_NAME = 'widgets';

export interface IParams {
    remark: ko.MaybeSubscribable<string>;
}

export class ViewModel extends AbstractWidget {
    public readonly remark = ko.pureComputed(() => {
        return ko.unwrap(this.params.remark);
    });
    constructor(readonly params: IParams) {
        super();
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

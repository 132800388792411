import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import * as API from '../../../../its-itembank-api.g';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, datagrid, refreshDx, selectBoxDS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import * as i18n from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-matchingtext-edit';
type Q = Awaited<ReturnType<API.Sdk['matchingtext_edit_data']>>;

export type IParams = IItemDefinitionWidgetParams;


export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    private readonly _pairs: Q['MatchingTextEdit']['get']['pairs'] = [];
    public readonly loaded = ko.observable(false);
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly leftTitle = ko.observable('');
    public readonly rightTitle = ko.observable('');

    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                leftTitle: this.leftTitle,
                rightTitle: this.rightTitle,
                header: this.header,
                question: this.question,
                shuffleLeft: this.shuffleLeft,
                shuffleRight: this.shuffleRight,
            },
            items: [],
        };

        retVal.onInitialized = e => {
            this.forms.add(e.component);
        };
        retVal.onDisposing = e => {
            this.forms.delete(e.component);
        };

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchingtext.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.matchingtext.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.matchingtext.edit.INSTRUCTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.matchingtext.edit.ENTER_THE_QUESTION_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            editorType: 'dxTextBox',
            dataField: 'leftTitle',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchingtext.edit.TITLE_OF_LEFT_COLUMN'])
            }
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            editorType: 'dxTextBox',
            dataField: 'rightTitle',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchingtext.edit.TITLE_OF_RIGHT_COLUMN'])
            }
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'shuffleLeft',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchingtext.edit.SHUFFLE_LEFT']),
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.MatchingTextEdit_ShuffleMode.Shuffled,
                    value: i18n.t(['itemdefinition.kosovo.matchingtext.edit.SHUFFLED']),
                },
                {
                    key: API.MatchingTextEdit_ShuffleMode.Ordered,
                    value: i18n.t(['itemdefinition.kosovo.matchingtext.edit.ORDERED']),
                },
            ]),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'shuffleRight',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchingtext.edit.SHUFFLE_RIGHT']),
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.MatchingTextEdit_ShuffleMode.Shuffled,
                    value: i18n.t(['itemdefinition.kosovo.matchingtext.edit.SHUFFLED']),
                },
                {
                    key: API.MatchingTextEdit_ShuffleMode.Ordered,
                    value: i18n.t(['itemdefinition.kosovo.matchingtext.edit.ORDERED']),
                },
            ]),
        }));
        return retVal;
    });

    public readonly shuffleLeft = ko.observable<API.MatchingTextEdit_ShuffleMode>();
    public readonly shuffleRight = ko.observable<API.MatchingTextEdit_ShuffleMode>();

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.matchingtext_edit_data({
            itemId: this.itemId
        });
        const d = q.MatchingTextEdit.get;
        this._pairs.splice(0, this._pairs.length, ...d.pairs);
        this.question(d.question.value);
        this.header(d.header.value);
        this.leftTitle(d.leftTitle.value);
        this.rightTitle(d.rightTitle.value);
        this.shuffleLeft(d.shuffleLeft);
        this.shuffleRight(d.shuffleRight);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        await refreshDx(this);
    }

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'index',
                        data: this._pairs
                    }
                },
                editing: {
                    allowAdding: this.canEdit,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
                columns: [],
                onEditorPreparing: e => {
                    if ((e.dataField === 'leftText.value') || (e.dataField === 'rightText.value')) {
                        e.editorName = 'dxTextArea';
                    }
                }
            }
        });
        retVal.columns.push(
            {
                dataField: 'index',
                caption: i18n.t(['itemdefinition.kosovo.matchingtext.edit.ID']),
                width: 100,
                allowEditing: false
            });
        retVal.columns.push(
            {
                dataField: 'leftText.value',
                caption: i18n.t(['itemdefinition.kosovo.matchingtext.edit.LEFT_TEXT']),
                customizeText: (cellInfo) => {
                    if (typeof cellInfo.value === 'string') {
                        return cellInfo.value.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    } else {
                        return '';
                    }
                },
                encodeHtml: false,
            });

        retVal.columns.push(
            {
                dataField: 'rightText.value',
                caption: i18n.t(['itemdefinition.kosovo.matchingtext.edit.RIGHT_TEXT']),
                customizeText: (cellInfo) => {
                    if (typeof cellInfo.value === 'string') {
                        return cellInfo.value.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    } else {
                        return '';
                    }
                },
                encodeHtml: false,
            });
        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.matchingtext_edit_update({
                    params: {
                        itemId: this.itemId,
                        removePairs: [e.key]
                    }
                }));
            })();
        };

        retVal.onInitNewRow = e => {
            let index = '';
            for (let i = 0; i < 26; ++i) {
                index = String.fromCharCode(65 + i);
                if (!this._pairs.some(x => x.index === index)) {
                    break;
                }
            }
            if (!index) {
                index = 'A';
            }
            e.data = AS<Q['MatchingTextEdit']['get']['pairs'][0]>({
                id: undefined,
                index,
                leftText: {
                    value: i18n.t(['itemdefinition.kosovo.matchingtext.edit.LEFT_TEXT']),
                },
                rightText: {
                    value: i18n.t(['itemdefinition.kosovo.matchingtext.edit.RIGHT_TEXT']),
                }
            });
        };
        retVal.onRowInserting = e => {
            e.cancel = (async () => {
                await ServerConnection.api.matchingtext_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertPairs: [{
                            index: e.data.index,
                            leftText: xnone(e.data.leftText && e.data.leftText.value),
                            rightText: xnone(e.data.rightText && e.data.rightText.value),
                        }]
                    }
                });
            })();
        };
        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.matchingtext_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertPairs: [{
                            index: e.key,
                            leftText: xnone(e.newData.leftText && e.newData.leftText.value),
                            rightText: xnone(e.newData.rightText && e.newData.rightText.value),
                        }]
                    }
                }));
            })();
        };
        return retVal;
    });

    private readonly _attachments: Q['documents']['get']['attachments'] = [];

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async v => {
            await ServerConnection.api.matchingtext_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.matchingtext_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.leftTitle, `${WIDGET_NAME}/${this.itemId}/leftTitle`, async v => {
            await ServerConnection.api.matchingtext_edit_update({
                params: {
                    itemId: this.itemId,
                    leftTitle: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.rightTitle, `${WIDGET_NAME}/${this.itemId}/rightTitle`, async v => {
            await ServerConnection.api.matchingtext_edit_update({
                params: {
                    itemId: this.itemId,
                    rightTitle: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.shuffleLeft, `${WIDGET_NAME}/${this.itemId}/shuffleLeft`, async v => {
            await ServerConnection.api.matchingtext_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleLeft: v,
                }
            });
            return DONE;
        });
        this.onChange(this.shuffleRight, `${WIDGET_NAME}/${this.itemId}/shuffleRight`, async v => {
            await ServerConnection.api.matchingtext_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleRight: v,
                }
            });
            return DONE;
        });
        this.loaded(true);
    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchingtext.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly enterOptionPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchingtext.edit.ENTER_ONE_OPTION_HERE']);
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

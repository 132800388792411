import { error } from '../debug';
import { ItemMode } from '../model/interfaces';


export interface ItemDefinitionFactory {
    id: string;
    uuid: string;
    widgetNames: {
        fallback?: string;
        modes?: Map<ItemMode, string>;
    };
    aliases?: string[];
    grading?: 'BULK' | 'SESSION' | 'NONE';
}


class ItemDefinitionFactoryProviderImplementation {
    private readonly factories = new Map<string, ItemDefinitionFactory>();
    private readonly aliases = new Map<string, string>();

    private setAlias(aliasName: string, factoryId: string) {
        const existingAlias = this.aliases.get(aliasName);
        if (existingAlias) {
            if (existingAlias === factoryId) {
                return;
            }
            throw new Error(`Unable to register alias ${aliasName} for ${factoryId} - there is already ${existingAlias} registered for it!`);
        }
        this.aliases.set(aliasName, factoryId);
    }

    private addAlias(aliasName: string, factory: ItemDefinitionFactory) {
        const baseName = aliasName.replace(/^(itemdefinition\-)/, '').toLowerCase();
        this.setAlias(baseName, factory.id);
        this.setAlias(`itemdefinition-${baseName}`, factory.id);
    }

    public register(factory: ItemDefinitionFactory) {
        if (this.factories.has(factory.id)) {
            throw new Error(`${factory.id} itemdefinition factory was used more than once!`);
        }
        if (!factory.widgetNames.modes) {
            factory.widgetNames.modes = new Map<ItemMode, string>();
        }
        if (!factory.widgetNames.modes.has('EDIT')) {
            factory.widgetNames.modes.set('EDIT', 'ui-author-noeditmode');
        }
        if (!factory.widgetNames.modes.has('INSPECT')) {
            factory.widgetNames.modes.set('INSPECT', factory.widgetNames.modes.get('EDIT'));
        }
        this.factories.set(factory.id, factory);
        this.setAlias(factory.uuid, factory.id);
        this.addAlias(factory.id, factory);
        if (factory.aliases) {
            for (const alias of factory.aliases) {
                this.addAlias(alias, factory);
            }
        }
    }

    public getWidgetName(itemType: string, mode: ItemMode) {
        const factory = this.getFactory(itemType);
        const modeWidget = factory.widgetNames.modes && factory.widgetNames.modes.get(mode);
        if (modeWidget) {
            return modeWidget;
        }
        const fallback = factory.widgetNames.fallback;
        if (!fallback) {
            throw new Error(`NYI: ${itemType} has no widget for mode ${mode}`);
        }
        return fallback;
    }



    private normalizeItemType(itemType: string): string {
        if (!itemType) {
            error('No itemtype was given!');
            throw new Error('No itemtype was given!');
        }
        itemType = itemType.toLowerCase();
        const alias = this.aliases.get(itemType);
        if (!alias) {
            throw new Error(`No itemdefinition with name ${itemType}`);
        }
        return alias;

    }

    public getFactory(itemType: string): ItemDefinitionFactory {
        const alias = this.normalizeItemType(itemType);
        return this.factories.get(alias);
    }

}
export const itemDefinitionFactoryProvider = new ItemDefinitionFactoryProviderImplementation();

import CustomStore from 'devextreme/data/custom_store';
import { getParent } from '../../../../helper';
import { ServerConnection } from '../../../RestAPI';

interface IEntry {
    id: string;
    parentId: string;
    display: string;
    name: string;
}
export class AlternationsStore extends CustomStore {
    constructor(readonly itemId: string) {
        super({
            loadMode: 'raw',
            key: 'id',
            byKey: async (key) => {
                return this._items.find(x => x.id === key);
            },
            load: async () => {
                const r = await ServerConnection.api.ui_subjectcoordinator_itemreview_metadata_alternations({
                    itemId: this.itemId,
                });
                this._items.splice(0, this._items.length, ...r.taxonomy.getById.items.map(x => ({
                    id: x.taxon,
                    parentId: getParent(x.taxon),
                    name: x.name.value,
                    display: `${x.taxon} ${x.name.value}`
                })));
                for (const cat of r.item.get.metaData.alternations.map(x => x.taxon)) {
                    if (cat && !this._items.find(x => x.id === cat)) {
                        this._items.push({
                            id: cat,
                            parentId: getParent(cat),
                            name: '?',
                            display: `${cat} ?`
                        });
                    }
                }
                return this._items;

            }
        });
    }
    public getDisplay(key: string) {
        const obj = this._items.find(x => x.id === key);
        if (!obj) {
            return '?';
        }
        return obj.display;
    }
    private readonly _items: IEntry[] = [];
}

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { log } from '../../debug';
import { AS } from '../../dx_helper';
import * as HELPER from '../../helper';
import { LFtoBR } from '../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { UIAction } from '../../ui/uiAction';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { Action, Note, OrganisationData } from '../model/organisation/OrganisationData';
import { GetSession } from '../model/session';
import * as i18n from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-organisation';

export type IParams = IItemDefinitionWidgetParams;

export class VMAction {
    public readonly action: string;
    constructor(readonly model: MyModel, readonly note: VMNote, readonly _action: Action) {
        this.action = _action.action;
    }

    public readonly isSelected = ko.pureComputed(() => {
        return this.note._note.givenId() === this._action.id;
    });

    public readonly css = ko.pureComputed(() => {
        let idx = this.note.actions.indexOf(this);
        const classes = ['action'];
        classes.push(`action-${HELPER.ToBase26(idx + 1)}`);
        if (this.isSelected()) {
            classes.push('action-selected');
        } else {
            classes.push('action-unselected');
        }
        if (this.model.mode() !== 'INTERACTIVE') {
            const correctId = this.note._note.correctId;
            if (correctId) {
                const isKey = correctId === this._action.id;
                if (isKey) {
                    classes.push(`action-key`);
                } else {
                    classes.push(`action-distractor`);
                }
            }
        }

        return classes.join(' ');
    });

    public readonly selectAction = new UIAction(undefined, async () => {
        if (this.model.mode() !== 'INTERACTIVE') {
            return;
        }
        this.note._note.givenId(this._action.id);
    });
}

export class VMNote {
    public readonly caption: string;
    public readonly actions: VMAction[];

    public readonly openNoteButton = ko.pureComputed(() => {
        return AS<DevExpress.ui.dxButton.Properties>({
            text: this.caption,
            icon: 'far fa-envelope-open',
            onClick: this.selectNote.click,
        });
    });

    public readonly css = ko.pureComputed(() => {
        const css = ['note'];
        css.push(`note-type-${this._note.noteType}`);
        return css.join(' ');
    });

    public readonly header = ko.pureComputed(() => {
        return LFtoBR(this._note.noteHeader);
    });

    public readonly body = ko.pureComputed(() => {
        return LFtoBR(this._note.noteBody);
    });

    public readonly given = ko.pureComputed(() => {
        const givenId = this._note.givenId();
        if (!givenId) {
            return undefined;
        }
        return this.actions.find(x => x._action.id === givenId);
    });

    constructor(readonly model: MyModel, readonly _note: Note) {
        this.caption = _note.caption;
        this.actions = _note.actions.map(x => new VMAction(model, this, x));
    }


    public readonly selectNote = new UIAction(undefined, async () => {
        this.model.selectedNote(this);
        this.model.notePopupVisible(true);
    });

}
export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly notes: VMNote[];

    public readonly selectedNote = ko.observable<VMNote>();

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof OrganisationData)) {
            throw new Error();
        }
        this.data = data;
        this.notes = data.notes.map(x => new VMNote(this, x));

    }

    public readonly notePopupVisible = ko.observable(false);

    public readonly notePopup = ko.pureComputed(() => {
        return AS<DevExpress.ui.dxPopup.Properties>({
            visible: <any>this.notePopupVisible,
            contentTemplate: 'item',
            maxWidth: '620px',
        });
    });


    public readonly closeNote = new UIAction(undefined, async () => {
        this.notePopupVisible(false);
        this.selectedNote(undefined);
    });
    public readonly closeNoteButton = ko.pureComputed(() => {
        return AS<DevExpress.ui.dxButton.Properties>({
            onClick: this.closeNote.click,
            text: i18n.t(['itemdefinition.organisation.CLOSE']),
        });
    });

    public readonly data: OrganisationData;

    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                HELPER.assertNever(mode);
                throw new Error();

        }
    });

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });



    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        return false;
        /*
        if (this.shapes.some(x => x.answer.isSelected())) {
            return false;
        }
        const golemData = getGolemData(this.itemId);
        let pickedId: string;
        if (typeof golemData === 'string') {
            pickedId = golemMatch(this.shapes.map(x => ({
                key: x.answer.id,
                value: x.answer.shape
            })), golemData);

        }
        if (!pickedId) {
            log(`Golem has no suggestion for ${this.itemId}. Pick a random one.`);
            pickedId = getRandomEntry(this.shapes).answer.id;
        }
        const pick = this.shapes.find(x => x.answer.id === pickedId);
        await pick.select.invoke(undefined, true);
        */
        return true;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();


        this.loaded(true);
    }

    public readonly textHTML = ko.pureComputed(() => this.data.textHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME),
});

import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export const WIDGET_NAME = 'ui-superuser-examinationoverview';
export class ExaminationOverviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.examinationoverview.EXAM']);
    });

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.examinationoverview.AN_URL_IS_CREATED_FOR_EACH_EXAM_OF_THE_TYPE_PUBLIC_THE_EXPORT_OF_THE_EXAM_DATA_ALL_ITEMS_IS_POSSIBLE_FOR_ALL_EXAM_TYPES']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.superuser.examinationoverview.HELP']),
                action: this.showHelp
            }];
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/examination',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return new ExaminationOverviewRoute(undefined);
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { GqlDS } from '../../../dxHelper/graphQLDataSource';
import { AS, refreshDx } from '../../../dx_helper';
import { AtoZZ, DONE, DONEENUM } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { SpellingMatrix_GapFormat } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { ServerConnection } from '../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as i18n from './../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-spellingmatrix-edit';
type Q = Awaited<ReturnType<API.Sdk['spellingmatrix_edit_data']>>;

interface ITable {
    originalId: string;
    key: string;
    caption: string;
    answers: string;
}

interface IValue {
    originalId: string;
    key: string;
    tableKey: string;
    label: string;
    value: string;
    firstLetterUpperCase: boolean;
}

export type IParams = IItemDefinitionWidgetParams;

export class TablesDS extends GqlDS<ITable, 'key'>{
    constructor(
        { itemId, fetch }: { itemId: string, fetch: () => ITable[] }
    ) {
        super({
            fetch,
            indexGenerator: AtoZZ,
            key: 'key',
            onNewRow: () => {

            },
            upsert: async (key, data) => {
                await ServerConnection.api.spellingmatrix_edit_update({
                    params: {
                        itemId,
                        addTables: [{
                            tableId: key,
                            caption: xnone(data.caption),
                            setAnswers: data.answers && data.answers.split('|'),
                        }]
                    }
                });
                return DONEENUM.DONE;
            },
            remove: async key => {
                await ServerConnection.api.spellingmatrix_edit_update({
                    params: {
                        itemId,
                        removeTables: [key],
                    }
                });
                return DONEENUM.DONE;
            },
        });
    }
}

export class ValuesDS extends GqlDS<IValue, 'key'>{
    constructor({ itemId, fetch }: { itemId: string, fetch: () => IValue[] }) {
        super({
            fetch,
            indexGenerator: AtoZZ,
            key: 'key',
            onNewRow: data => {
                //data.key = NewKey(args)
            },
            upsert: async (key, data) => {
                let gapFormat: SpellingMatrix_GapFormat = undefined;
                if (data.firstLetterUpperCase === true) {
                    gapFormat = SpellingMatrix_GapFormat.FirstLetterUpperCase;
                } else if (data.firstLetterUpperCase === false) {
                    gapFormat = SpellingMatrix_GapFormat.AsIs;

                }
                await ServerConnection.api.spellingmatrix_edit_update({
                    params: {
                        itemId,
                        addValues: [{
                            valueId: key,
                            key: data.key,
                            label: data.label,
                            value: data.value,
                            tableId: data.tableKey,
                            gapFormat,
                        }]
                    }
                });
                return DONEENUM.DONE;
            },
            remove: async key => {
                await ServerConnection.api.spellingmatrix_edit_update({
                    params: {
                        itemId,
                        removeTables: [key],
                    }
                });
                return DONEENUM.DONE;
            },
        });
    }
}
export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly focusedShape = ko.observable('');
    public readonly loaded = ko.observable(false);
    //public readonly answers = ko.observableArray();
    public readonly header = ko.observable('');
    public readonly text = ko.observable('');


    private data: Q['SpellingMatrixEdit']['get'];

    private readonly _attachments: Q['documents']['get']['attachments'] = [];
    private readonly _tables: ITable[] = [];
    private readonly _values: IValue[] = [];

    private readonly tables: TablesDS;

    private readonly values: ValuesDS;

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.tables = new TablesDS({ itemId: params.itemId, fetch: () => this._tables });
        this.values = new ValuesDS({
            itemId: params.itemId,
            fetch: () => {
                return this._values;
            },
        });
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.text,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.HEADER'])
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.invisiblehotspot.edit.ENTER_THE_HEADER_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.invisiblehotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']),
        }));

        return retVal;
    });


    public readonly tablesGrid = ko.pureComputed(() => {
        let retVal: DevExpress.ui.dxDataGrid.Properties = {
            columns: [],
            dataSource: {
                store: this.tables.store,

            },
            onInitNewRow: this.tables.initNewRow,
            keyExpr: 'key',
            editing: {
                mode: 'cell',
                allowAdding: true,
                allowDeleting: true,
                allowUpdating: true,
            }
        };
        retVal.columns.push({
            caption: 'Id',
            dataField: 'key',
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.spellingmatrix.edit.TITLEOFLIST']),
            dataField: 'caption',
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.spellingmatrix.edit.SPELLINGOPTIONS']),
            dataField: 'answers',
            dataType: 'string',
        });
        return retVal;
    });

    public readonly valuesGrid = ko.pureComputed(() => {
        let retVal: DevExpress.ui.dxDataGrid.Properties = {
            columns: [],
            dataSource: {
                store: this.values.store,

            },
            keyExpr: 'key',
            onInitNewRow: this.values.initNewRow,
            editing: {
                mode: 'cell',
                allowAdding: true,
                allowDeleting: true,
                allowUpdating: true,
            }
        };
        retVal.columns.push({
            caption: 'Id',
            dataField: 'key',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.spellingmatrix.edit.TABLE']),
            dataField: 'tableKey',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.spellingmatrix.edit.WORD']),
            dataField: 'label',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.spellingmatrix.edit.VALUE']),
            dataField: 'value',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.spellingmatrix.edit.FIRSTLETTERUPPERCASE']),
            dataField: 'firstLetterUpperCase',
            dataType: 'boolean',
        });
        return retVal;
    });
    public readonly tablesGridCaption = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.spellingmatrix.edit.TABLES']);
    });
    public readonly valuesGridCaption = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.spellingmatrix.edit.WORDS']);
    });
    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.spellingmatrix_edit_data({
            itemId: this.itemId
        });
        const d = q.SpellingMatrixEdit.get;
        this.data = d;
        this.header(d.header.defaultValue);
        this.text(d.text.defaultValue);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        this._tables.splice(0, this._tables.length, ...d.tables.map(x => ({
            originalId: x.id,
            key: x.key,
            caption: x.caption.defaultValue,
            answers: x.answers.join('|'),
        })));
        this._values.splice(0, this._tables.length, ...d.values.map(x => ({
            originalId: x.id,
            key: x.key,
            tableKey: x.tableId,
            label: x.label,
            value: x.value,
            firstLetterUpperCase: x.gapFormat === 'FirstLetterUpperCase',
        })));
        await refreshDx(this);

    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.text, `${WIDGET_NAME}/${this.itemId}/text`, async v => {
            await ServerConnection.api.spellingmatrix_edit_update({
                params: {
                    itemId: this.itemId,
                    text: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.spellingmatrix_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });


        this.loaded(true);
    }



}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-testaliasoverview';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class TestAliasOverviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.testaliasoverview.TEST_ALIASES']);
    });


    private constructor(readonly widgetParams: IParamsInner) {
    }
    static async CREATE(widgetParams: IParamsInner) {
        const retVal = new TestAliasOverviewRoute(widgetParams);
        return retVal;
    }
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/testalias',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return TestAliasOverviewRoute.CREATE(undefined);
    },
});

import * as ko from 'knockout';
import { log } from '../../../debug';
import { getGolemData, golemMatch } from '../../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry } from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { translate } from '../../model/ItemDataModel';
import { GetSession } from '../../model/session';
import { SingleChoiceTextData, SingleChoiceTextData_Answer } from "../../model/singlechoicetext/SingleChoiceTextData";
import { htmlString } from './singlechoicetext.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-singlechoicetext';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

class VMAnswer {
    constructor(private readonly model: MyModel, public readonly answer: SingleChoiceTextData_Answer) {
    }

    public readonly id = ko.pureComputed(() => this.answer.id);
    public readonly value = ko.pureComputed(() => this.answer.value());

    public readonly isSelected = ko.pureComputed(() => this.answer.isSelected());
    public readonly isCorrectAnswer = ko.pureComputed(() => this.answer.isCorrectAnswer());

    public readonly select = new UIAction(undefined, async () => {
        await this.model.clickAnswer.invoke(this.id(), true);
    });
}
export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly data: SingleChoiceTextData;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SingleChoiceTextData)) {
            throw new Error();
        }
        this.data = data;
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                const answers = this.shuffledAnswers();
                const golemData = getGolemData(this.itemId);
                if (!answers.some(x => x.isSelected())) {
                    if (typeof golemData === 'string') {
                        const idx = golemMatch(answers.map((x, idx) => ({
                            key: idx,
                            value: translate(x.answer.rawData.text, this.params),
                        })), golemData);
                        if (idx >= 0) {
                            const answer = answers[idx];
                            log(`Golem says we should use answer #${idx} ${answer.id()}`);
                            await answer.select.invoke();
                            return true;
                        }
                    }
                    await getRandomEntry(answers).select.invoke();
                    return true;
                }
                return false;
            }
        });
        await super.initialize();

        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly hasImage = ko.pureComputed(() => this.data.hasImage);
    public readonly imgUrl = ko.pureComputed(() => this.data.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.data.imgAlt);

    public readonly shuffledAnswers = ko.pureComputed(() => {
        return this.data.answers.map(x => new VMAnswer(this, x));
    });
    public readonly questionHTML = ko.pureComputed(() => {
        return this.data.questionHTML;
    });
    public readonly headerText = ko.pureComputed(() => {
        return this.data.headerText;
    });
    public readonly score = ko.pureComputed(() => {
        return this.data.meta.accumulatedScore();
    });
    public readonly clickAnswer = new UIAction<string>(undefined, async (e, answerId) => {
        await this.data.setAnswer(answerId);
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import * as query from '../../getquery';
import { InitSession } from '../../itemdefinition/model/session';
import * as API from '../../its-itembank-api.g';
import { naturalCompare } from '../../shareable/natural-sort';
import { ItemHostBase } from "../ItemHostBase";
import { ServerConnection } from '../RestAPI';
import * as i18n from './../../i18n/i18n';
import { htmlString } from './widget.html.g';
type Q = Awaited<ReturnType<API.Sdk['ui_sessionreport_data']>>;

function formatNumber(x: number) {
    if (isNaN(x) || typeof x !== 'number') {
        return 'NaN';
    }
    const str = x.toFixed(1);
    if (str.endsWith('0')) {
        return str.substr(0, str.length - 2);
    } else {
        return str;
    }
}

const WIDGET_NAME = 'ui-sessionreport';

class Objective {
    constructor(readonly model: ViewModel, readonly params: Q['examSession']['objectives'][0]) {

    }
    public readonly id = ko.pureComputed(() => {
        return this.params.objectiveId;
    });
    public readonly desc = ko.pureComputed(() => {
        const text = '{title}';
        const t = text
            .replace(/\{id\}/g, this.params.objectiveId || '')
            .replace(/\{title\}/g, this.params.description && this.params.description.value || '')
            .replace(/\{pointsPercent\}/g, formatNumber(this.params.scoreGained * 100 / this.params.scoreMax))
            .replace(/\{pointsGained\}/g, formatNumber(this.params.scoreGained))
            .replace(/\{pointsPending\}/g, formatNumber(this.params.scorePending))
            .replace(/\{pointsLost\}/g, formatNumber(this.params.scoreLost))
            .replace(/\{pointsMax\}/g, formatNumber(this.params.scoreMax));
        return t;
    });

    public readonly score = ko.pureComputed(() => {
        return {
            pointsGained: this.params.scoreGained || 0,
            pointsLost: this.params.scoreLost || 0,
            pointsMax: this.params.scoreMax || 0,
            pointsPending: this.params.scorePending || 0
        };
    });
}

export class ViewModel extends ItemHostBase {
    public readonly H1_SessionReport = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.SESSION_REPORT']);
    });

    public readonly labelSessionId = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.SESSION_ID']) + ':';
    });

    public readonly labelExternalId = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.EXTERNAL_ID']) + ':';
    });

    public readonly labelSessionStartTime = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.SESSION_START_TIME']) + ':';
    });

    public readonly labelSessionCloseTime = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.SESSION_CLOSE_TIME']) + ':';
    });

    public readonly labelScore = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.SCORE']) + ':';
    });

    public readonly H2_Objectives = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.OBJECTIVES']);
    });

    public readonly H2_Items = ko.pureComputed(() => {
        return i18n.t(['ui.sessionreport.ITEMS']);
    });

    public readonly objectives = ko.observable<Objective[]>();
    public readonly currentSessionId = ko.observable('');
    public readonly externalId = ko.pureComputed(() => {
        const d = this.currentSession();
        return d && d.externalId;
    });

    public readonly sessionStartTime = ko.pureComputed(() => {
        const d = this.currentSession();
        return d && d.sessionStartTime;
    });

    public readonly sessionCloseTime = ko.pureComputed(() => {
        const d = this.currentSession();
        return d && d.sessionCloseTime;
    });

    public readonly currentSession = ko.observable<Q['examSession']>();
    public readonly currentExam = ko.pureComputed(() => {
        const d = this.currentSession();
        return d && d.examInfo;
    });

    public readonly score = ko.pureComputed(() => {
        const d = this.currentSession();
        if (!d) {
            return undefined;
        }
        return d.score;
    });

    public readonly itemNotStartedText = ko.pureComputed(()=>{
        return i18n.t(['ui.sessionreport.ITEM_NOT_STARTED']);
    })

    public readonly items = ko.pureComputed(() => {
        const s = this.currentSession();
        if (!s || !s.items) {
            return undefined;
        }
        return s.items.map(x => {
            return {
                itemId: x.itemId,
                itemType: x.itemType,
                started: x.interactionStatus?.started,
            };
        });
    });
    protected async onInitialize() {
        await super.onInitialize();
        const sessionId = query.params.get('session');
        if (!sessionId) {
            throw new Error('Ungültiger Aufruf');
        }
        this.currentSessionId(sessionId);
        const data = await ServerConnection.api.ui_sessionreport_data(
            {
                sessionDocRef: sessionId
            });
        const r = data.examSession;
        this.currentSession(r);
        this.objectives(r.objectives
            .sort((a, b) => naturalCompare(a.objectiveId, b.objectiveId))
            .map(x => new Objective(this, x)));

        await InitSession(sessionId, []);
    }
}

export type IParams = undefined;

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel();
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
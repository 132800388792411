export const id = 'spellingmatrix';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '68f94aeb-d34d-4a11-a201-a4060039d74f',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import * as i18n from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'ui-superuser-profileedit-help';

type IParams = undefined;

export class ViewModel extends DxWidget {
    constructor(readonly params: IParams) {
        super();
    }
    public readonly helpCaption = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.HELP']));

    public readonly helpHtml = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.HELPHTML']));

    public readonly PlaceholderCaption = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.PLACEHOLDERS']));
    public readonly SessionVar_StartDate = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_STARTDATE']));
    public readonly SessionVar_TopItemId = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_TOPITEMID']));
    public readonly SessionVar_TopItemNr = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_TOPITEMNR']));
    public readonly SessionVar_TopItemCount = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_TOPITEMCOUNT']));
    public readonly SessionVar_TestTitle = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_TESTTITLE']));
    public readonly SessionVar_SessionId = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_SESSIONID']));
    public readonly SessionVar_ExternalId = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_EXTERNALID']));
    public readonly SessionVar_CandidateId = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_CANDIDATEID']));
    public readonly SessionVar_CandidateName = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_CANDIDATENAME']));
    public readonly SessionVar_TotalTime = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_TOTALTIME']));
    public readonly SessionVar_TotalTimeMinutes = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_TOTALTIMEMINUTES']));
    public readonly SessionVar_GradeName = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_GRADENAME']));
    public readonly SessionVar_GradeLetter = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_GRADELETTER']));
    public readonly SessionVar_PassedYesNo = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR']));
    public readonly SessionVar_FIELD_ID = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_FIELD_ID']));
    public readonly SessionVar_DRIVE_H = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.SESSIONVAR_DRIVE_H']));
    public readonly ResultPagePlaceholderCaption = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.PLACEHOLDERS_FOR_RESULT_PAGE_FIELD_TEXT']));
    public readonly ResultPageVar_pointsPercent = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_POINTSPERCENT']));
    public readonly ResultPageVar_pointsGained = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_POINTSGAINED']));
    public readonly ResultPageVar_pointsPending = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_POINTSPENDING']));
    public readonly ResultPageVar_pointsLost = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_POINTSLOST']));
    public readonly ResultPageVar_pointsMax = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_POINTSMAX']));
    public readonly ResultPageVar_moduleTitle = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_MODULETITLE']));
    public readonly ResultPageVar_candidateId = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_CANDIDATEID']));
    public readonly ResultPageVar_candidateDisplay = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.RESULTPAGEVAR_CANDIDATEDISPLAY']));
    public readonly ExampleCaption = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.EXAMPLE']));
    public readonly ObjectiveTextPlaceholderCaption = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.PLACEHOLDERS_FOR_FIELD_OBJECTIVE_TEXT']));
    public readonly CommonPlaceholderCaption = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.COMMON_PLACEHOLDERS_CAN_BE_USED_IN_ALL_TEXT_FIELDS']));
    public readonly ObjectiveTextPlaceholderDescriptionHTML = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ALL_PLACEHOLDERS_USED_IN_THIS_FIELD_SHOW_THE_DEFINED_VALUES_FOR_EACH_OBJECTIVE']));
    public readonly ObjectiveTextPlaceholderCommentHTML = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.TO_ENABLE_I_TABLE_MODE_I_SEPARTE_THE_FIELDS_WITH_TO_DEFINE_THE_COLUMN_HEADER_ADD_THE_SYMBOL_AT_THE_END_OF_THE_HEADER_NAME']));
    public readonly ObjectiveTextVar_id = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_ID']));
    public readonly ObjectiveTextVar_title = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_TITLE']));
    public readonly ObjectiveTextVar_pointsPercent = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_POINTSPERCENT']));
    public readonly ObjectiveTextVar_pointsGained = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_POINTSGAINED']));
    public readonly ObjectiveTextVar_pointsPending = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_POINTSPENDING']));
    public readonly ObjectiveTextVar_pointsLost = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_POINTSLOST']));
    public readonly ObjectiveTextVar_pointsMax = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_POINTSMAX']));
    public readonly ObjectiveTextVar_score = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.OBJECTIVETEXTVAR_SCORE']));

    public readonly ItemTextPlaceholderCaption = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.PLACEHOLDERS_FOR_FIELD_ITEM_TEXT']));
    public readonly ItemTextPlaceholderDescriptionHTML = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ALL_PLACEHOLDERS_USED_IN_THIS_FIELD_SHOW_THE_DEFINED_VALUES_FOR_EACH_ITEM']));
    public readonly ItemTextVar_id = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_ID']));
    public readonly ItemTextVar_index = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_INDEX']));
    public readonly ItemTextVar_title = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_TITLE']));
    public readonly ItemTextVar_pointsPercent = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_POINTSPERCENT']));
    public readonly ItemTextVar_pointsGained = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_POINTSGAINED']));
    public readonly ItemTextVar_pointsPending = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_POINTSPENDING']));
    public readonly ItemTextVar_pointsLost = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_POINTSLOST']));
    public readonly ItemTextVar_pointsMax = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_POINTSMAX']));
    public readonly ItemTextVar_score = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.ITEMTEXTVAR_SCORE']));
    public readonly ItemTextPlaceholderCommentHTML = ko.pureComputed(() => i18n.t(['ui.superuser.profileedit.help.TO_ENABLE_I_TABLE_MODE_I_SEPARTE_THE_FIELDS_WITH_TO_DEFINE_THE_COLUMN_HEADER_ADD_THE_SYMBOL_AT_THE_END_OF_THE_HEADER_NAME']));

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
import * as SuperUser_ExamOrderEditRoute from './superuser/examorderedit/route';
import * as SuperUser_GolemScriptEditRoute from './superuser/golemscriptedit/route';
import * as SuperUser_ProfileEditRoute from './superuser/profileedit/route';
import * as SuperUser_SchoolTypeEditRoute from './superuser/schooltypeedit/route';

export const superuser = {
    ExamOrderEdit: SuperUser_ExamOrderEditRoute.FACTORY,
    ProfileEdit: SuperUser_ProfileEditRoute.FACTORY,
    SchoolTypeEdit: SuperUser_SchoolTypeEditRoute.FACTORY,
    GolemScriptEdit: SuperUser_GolemScriptEditRoute.FACTORY,
};

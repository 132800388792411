import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import dxDataGrid from 'devextreme/ui/data_grid';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { log } from '../../../debug';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import { assertNever, BRtoLF, DONE, joinStrings, LFtoBR } from '../../../helper';
import { formatMessage } from '../../../i18n/data';
import { isoDurationToCentisec } from '../../../iso8601';
import * as API from '../../../its-itembank-api.g';
import * as MODAL from '../../../modal';
import { toastService } from '../../../toastService';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import { routeManager } from '../../routes';
import * as SC_EXAMORDEREDIT from '../../subjectcoordinator/examorderedit/route';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import * as TRANSLATOR_EDIT from '../../translator/translateexamorder/route';
import { UIAction } from '../../uiAction';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import * as ADDSUBJECT from './addsubject/widget';
import { AlternationsStore } from './AlternationsStore';
import { htmlString } from './examorderedit.html.g';
import { AUDIENCE, FORMDATA, MyFormBuilder, PROFILE, Q } from './MyFormBuilder';
import { IParams, WIDGET_NAME } from './route';

type SUBJECTDATA = Q['examOrder']['byId']['subjects'][0] & { l: { [key: string]: boolean } };
type ITEM = Awaited<ReturnType<API.Sdk['ui_superuser_examorderedit_items']>>['examOrder']['byId']['items'][0];
class ItemStore extends CustomStore {
    readonly items: ITEM[] = [];
    constructor(readonly examOrderRef: string, readonly subjectRef: string) {
        super({
            loadMode: 'raw',
            key: 'id',
            load: async () => {
                const r = await ServerConnection.api.ui_superuser_examorderedit_items({
                    examOrderDocRef: this.examOrderRef,
                    subjectDocRef: this.subjectRef,
                });
                this.items.splice(0, this.items.length, ...r.examOrder.byId.items);
                return this.items;
            },
            byKey: async key => {
                return this.items.find(x => x.id === key);
            },
        });
    }
}

export class ViewModel extends DxWidget {
    private readonly itemStores = new Map<string, ItemStore>();

    public readonly _allAudiences: AUDIENCE[] = [];
    private readonly _subjects: SUBJECTDATA[] = [];
    public readonly _profiles: PROFILE[] = [];

    public readonly editableBySuperUser = ko.observable(false);
    public readonly alternationsStore: AlternationsStore;

    public readonly form = new MyFormBuilder(this);

    public readonly levelOfSecrecyEnabled = ko.pureComputed(() => {
        const c = this.config();
        if (!c) {
            return false;
        }
        return c.config.levelOfSecrecyEnabled;
    });

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        this.form.formOptions.onFieldDataChanged = x => {
            void this.updateDatabase(x.dataField, x.value);
        };
    }


    public readonly deleteSelectedExamOrderSubject = new UIAction<SUBJECTDATA>(undefined, async (e, args) => {
        await legacyPushPull(async () => {
            const examOrderId = this.formData.examinationOrderId;
            const examOrderDocRef = this.params.currentRoute.widgetParams.examOrderDocRefId;
            await ServerConnection.api.ui_superuser_examorderedit_update({
                params: {
                    examOrderId,
                    examOrderDocRef,
                    removeSubjects: [args.subject.docReferenceId]
                }
            });
        });
    });
    public readonly openAsSubjectCoordinator = new UIAction<SUBJECTDATA>(undefined, async (e, args) => {
        await legacyPushPull();
        const examorder = this.params.currentRoute.widgetParams.examOrderDocRefId;
        const subject = args.subject.docReferenceId;
        await routeManager.navigateToHREF(SC_EXAMORDEREDIT.FACTORY.href({
            examorder,
            subject
        }));
    });

    public readonly openAsTranslator = new UIAction<SUBJECTDATA>(undefined, async (e, args) => {
        await legacyPushPull();
        const examorder = this.params.currentRoute.widgetParams.examOrderDocRefId;
        const subject = args.subject.docReferenceId;
        await routeManager.navigateToHREF(TRANSLATOR_EDIT.FACTORY.href({
            examorder,
        }));
    });


    private readonly subjectsStore = new CustomStore({
        loadMode: 'raw',
        key: 'id',
        update: async (key, data) => {
            const x = this._subjects.find(x => x.id === key);
            Object.assign(x, data);

            const examOrderId = this.formData.examinationOrderId;
            const examOrderDocRef = this.params.currentRoute.widgetParams.examOrderDocRefId;
            const s: API.IUpsertExamOrderSubjectInput = {
                subjectDocRef: x.subject.docReferenceId,
                addLanguages: [],
                removeLanguages: [],
            };
            if (data.score >= 0) {
                s.score = data.score;
            }
            if (typeof data.sectionTitle?.defaultValue === 'string') {
                s.sectionTitle = {
                    xnone: data.sectionTitle.defaultValue
                };
            }
            if (data.l) {
                for (const tag of Object.keys(data.l)) {
                    if (data.l[tag]) {
                        s.addLanguages.push(tag);
                    } else {
                        s.removeLanguages.push(tag);
                    }
                }
            }
            await ServerConnection.api.ui_superuser_examorderedit_update({
                params: {
                    examOrderId,
                    examOrderDocRef,
                    addSubjects: [s]
                }
            });
        },
        load: async () => {
            return this._subjects;
        },
        byKey: async key => {
            return this._subjects.find(x => x.id === key);
        }
    });

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            discriminator: 'items_v3',
            config: {
                columns: [],
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                editing: {
                    allowUpdating: this.editableBySuperUser(),
                    mode: 'row',
                },
                searchPanel: {
                    visible: true,
                },
                filterPanel: {
                    visible: false,
                },
                masterDetail: {
                    enabled: true,
                    template: (elem, info) => {
                        const retVal = $('<div />');
                        const data: SUBJECTDATA = info.data;
                        if (!this.itemStores.has(data.subject.docReferenceId)) {
                            this.itemStores.set(data.subject.docReferenceId,
                                new ItemStore(this.params.currentRoute.widgetParams.examOrderDocRefId, data.subject.docReferenceId));
                        }
                        const gridOptions = datagrid({
                            WIDGET_NAME,
                            discriminator: 'subject',
                            widget: this,
                            scope: data.subject.docReferenceId,
                            config: {
                                allowColumnResizing: true,
                                wordWrapEnabled: true,
                                editing: {
                                    allowAdding: false,
                                    allowDeleting: false,
                                    allowUpdating: false,
                                    mode: 'row',
                                },

                                sorting: {
                                    mode: 'single',
                                },
                                searchPanel: {
                                    visible: true,
                                },
                                filterRow: {
                                    visible: false,
                                },
                                headerFilter: {
                                    visible: false,
                                },

                                filterPanel: {
                                    visible: true
                                },
                                selection: {
                                    mode: 'single'
                                },
                                dataSource: {
                                    sort: 'index',
                                    store: this.itemStores.get(data.subject.docReferenceId),

                                },
                                export: {
                                    enabled: true,
                                    fileName: `examorder_${this.params.currentRoute.widgetParams.examOrderDocRefId}_${data.subject.docReferenceId}`,
                                    customizeExcelCell: o => {
                                        if (!o.gridCell) {
                                            return;
                                        }
                                        if (o.gridCell.rowType !== 'data') {
                                            return;
                                        }
                                        if (typeof o.value === 'string') {
                                            o.gridCell.value = BRtoLF(o.value);
                                        }
                                        o.wrapTextEnabled = true;
                                    }
                                },

                            },
                        });

                        gridOptions.columns.push({
                            caption: i18next.t(['ui.subjectcoordinator.examorderedit.ITEM_ID']),
                            dataField: 'item.itemId',
                            width: 100,
                            dataType: 'string',
                            allowEditing: false,
                            cssClass: 'itsr3-uitest-hide',
                        });

                        gridOptions.columns.push({
                            caption: i18next.t(['ui.superuser.examorderedit.CATEGORY']),
                            dataType: 'string',
                            name: 'objective',
                            width: 100,
                            calculateDisplayValue: x => {
                                const queryRow: ITEM = x;
                                return joinStrings(', ', ...queryRow.item.accumulatedObjectives.map(x => x.taxon).sort());
                            },
                            calculateCellValue: x => {
                                const queryRow: ITEM = x;
                                return queryRow.item.accumulatedObjectives.map(x => x.taxon);
                            },
                        });
                        gridOptions.columns.push({
                            caption: i18next.t(['ui.superuser.examorderedit.OBJECTIVE']),
                            name: 'category',
                            width: 120,
                            dataType: 'string',

                            calculateCellValue: (x: ITEM) => {
                                return x.item.accumulatedCategories.map(x => x.taxon);
                            },

                            calculateDisplayValue: (x: ITEM) => {
                                return x.item.accumulatedCategories.map(x => x.taxon).join(', ');
                            }
                        });

                        gridOptions.columns.push({
                            caption: i18next.t(['ui.subjectcoordinator.examorderedit.MAX_SCORE']),
                            dataField: 'item.accumulatedScoreMax',
                            dataType: 'number',
                            allowEditing: false,
                            width: 100,
                        });
                        gridOptions.columns.push({
                            caption: i18next.t(['ui.subjectcoordinator.examorderedit.VISIBLE_TEXT']),
                            dataField: 'item.visibleText',
                            dataType: 'string',
                            allowEditing: false,
                            // width: 100,
                            customizeText: (cellInfo) => {
                                return LFtoBR(cellInfo.valueText);
                            },
                            encodeHtml: false,
                        });
                        gridOptions.columns.push({
                            caption: i18next.t(['ui.subjectcoordinator.examorderedit.ITEM_TYPE']),
                            dataField: 'item.itemType.title.value',
                            dataType: 'string',
                            width: 100,
                            allowEditing: false,
                        });
                        gridOptions.columns.push({
                            caption: i18next.t(['ui.subjectcoordinator.examorderedit.CHANCE']),
                            dataField: 'chance',
                            format: {
                                type: 'percent',
                                precision: 2,
                            },
                            dataType: 'number',
                            width: 100,
                            allowEditing: false,
                        });

                        new dxDataGrid(retVal, gridOptions);
                        return retVal;
                    }
                },
                selection: {
                    mode: 'single',
                },
                groupPanel: {
                    visible: false,
                },
                dataSource: {
                    store: this.subjectsStore,
                }
            }
        });

        if (this.editableBySuperUser()) {
            retVal.onToolbarPreparing = e => {
                e.toolbarOptions.items.unshift({
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                        hint: 'add subject',
                        icon: 'add',

                        onClick: this.actionAddSubject.click
                    }
                });
            };
        }

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderedit.SUBJECT']),
            dataField: 'subject.title',
            allowEditing: false,
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderedit.SCORE']),
            dataField: 'score',
            width: 100,
            dataType: 'number',
        });

        retVal.columns.push({
            caption: i18n.t(['ui.superuser.examorderedit.SECTION_TITLE']),
            dataField: 'sectionTitle.defaultValue',
            dataType: 'string',
            width: 150,
        });

        const c = this.config();
        for (const lang of c.config.examLanguages) {
            retVal.columns.push({
                caption: lang.name.value,
                dataType: 'boolean',
                dataField: `l.${lang.id}`
            });
        }

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderedit.STATUS']),
            dataType: 'string',
            width: 100,
            allowEditing: false,
            calculateDisplayValue: x => {
                const queryRow: SUBJECTDATA = x;
                const status = queryRow.status;
                switch (status) {
                    case API.ExamOrderSubjectStatus.Open:
                        return i18next.t(TRANSLATIONS.SubjectCoordinatorStatusString['open']);
                    case API.ExamOrderSubjectStatus.Finished:
                        return i18next.t(TRANSLATIONS.SubjectCoordinatorStatusString['finished']);
                    default:
                        assertNever(status);
                        throw new Error();
                }
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderedit.ACTIONS']),
            type: 'buttons',
            buttons: [
                'edit',
                {
                    text: i18n.t(['ui.superuser.examorderedit.SUBJECT_COORDINATOR']),
                    onClick: e => this.openAsSubjectCoordinator.intent(e.row.data),
                    visible: (d) => {
                        const config = this.config();
                        if (!config) {
                            return false;
                        }
                        const data: SUBJECTDATA = d.row && d.row.data;
                        return data && !!data.subject.iAmSubjectCoordinator && config.examOrder.byId.status === API.ExaminationOrderStatus.Populating;
                    },
                },
                {
                    text: i18n.t(['ui.superuser.examorderedit.TRANSLATOR']),
                    onClick: e => this.openAsTranslator.intent(e.row.data),
                    visible: (d) => {
                        const config = this.config();
                        if (!config) {
                            return false;
                        }
                        const data: SUBJECTDATA = d.row && d.row.data;
                        return data && !!data.subject.iAmTranslator && config.examOrder.byId.status === API.ExaminationOrderStatus.Populating;
                    },
                },
                {
                    text: i18next.t(['ui.superuser.examorderedit.DELETE']),
                    onClick: e => this.deleteSelectedExamOrderSubject.intent(e.row.data)
                }
            ],
        });
        return retVal;
    });

    public readonly addSubjectPopupVisible = ko.observable(false);
    public readonly addSubjectPopupOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxPopup.Properties = {
            onShowing: async () => {
                await legacyPushPull();
            },
            title: i18next.t(['ui.superuser.examorderedit.ADD_SUBJECT']),
            contentTemplate: () => {
                const x = $(`<div data-bind="component:{name:'${ADDSUBJECT.WIDGET_NAME}',params:params}"></div>`);
                ko.applyBindings({
                    params: AS<ADDSUBJECT.IParams>({
                        examOrderDocRefId: this.params.currentRoute.widgetParams.examOrderDocRefId,
                        popupVisible: this.addSubjectPopupVisible
                    })
                }, x.get(0));
                return x;
            },
            visible: <any>this.addSubjectPopupVisible,
        };
        return retVal;
    });

    public readonly actionAddSubject = new UIAction(undefined, async () => {
        this.addSubjectPopupVisible(true);
    });

    private readonly formData: FORMDATA = <any>{};

    private async updateDatabase(field: string, value: any) {
        const examOrderDocRef = this.params.currentRoute.widgetParams.examOrderDocRefId;
        const examOrderId = this.formData.examinationOrderId;
        const up = this.form.fields.get(field);
        if (up) {
            await ServerConnection.api.ui_superuser_examorderedit_update({
                params: Object.assign({
                    examOrderDocRef,
                    examOrderId,
                }, up(value))
            });
            return DONE;
        }
        return DONE;
    }


    public readonly examTime = ko.observable<Date>(new Date());
    public readonly examDate = ko.observable<Date>(new Date());


    constructor(readonly params: IParams) {
        super();
        this.alternationsStore = new AlternationsStore(params.currentRoute.widgetParams.examOrderDocRefId);
    }

    public readonly config = ko.observable<Q>();
    public readonly loaded = ko.observable(false);

    public async OnRefresh() {
        await super.OnRefresh();
        const config = await ServerConnection.api.ui_superuser_examorderedit_data({
            docRef: this.params.currentRoute.widgetParams.examOrderDocRefId,
        });
        this.config(config);

        const objectives = new Set<string>();
        const categories = new Set<string>();
        const audiences = new Map<string, string>();
        for (const itemStore of Array.from(this.itemStores.values())) {
            for (const item of itemStore.items) {
                for (const o of item.item.accumulatedObjectives) {
                    objectives.add(o.taxon);
                }
                for (const c of item.item.accumulatedCategories) {
                    categories.add(c.taxon);
                }
            }
        }
        this._profiles.splice(0, this._profiles.length, ...config.TestDefinitionProfile.all);
        this._allAudiences.splice(0, this._allAudiences.length, ...config.audiences.all.filter(x => !x.isDeleted));
        this._subjects.splice(0, this._subjects.length, ...config.examOrder.byId.subjects.map(x => {
            const retVal: SUBJECTDATA = Object.assign(x, { l: {} });
            for (const l of config.config.examLanguages) {
                retVal.l[l.id] = false;
            }
            for (const l of x.localizations) {
                retVal.l[l.id] = true;
            }
            return retVal;
        }));
        let max_time_allowed_minutes = Math.round(isoDurationToCentisec(config.examOrder.byId.maxTimeAllowed) / (60 * 100));
        if (!isFinite(max_time_allowed_minutes) || max_time_allowed_minutes < 0) {
            max_time_allowed_minutes = 0;
        }
        Object.assign(this.formData, config.examOrder.byId, {
            alternations_array: config.examOrder.byId.alternations.map(x => x.taxon),
            status_translated: i18next.t(TRANSLATIONS.StatusString[config.examOrder.byId.status]),
            max_time_allowed_minutes,
        });
        this.editableBySuperUser(this.formData.status === API.ExaminationOrderStatus.Open);
        await refreshDx(this);
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.initFormOptions();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }

    public readonly isActionEditAvailable = ko.pureComputed(() => {
        const config = this.config();
        if (!config) {
            return false;
        }
        switch (config.examOrder.byId.status) {
            case API.ExaminationOrderStatus.Populating:
            case API.ExaminationOrderStatus.Ready:
                return true;
        }
        return false;
    });
    public readonly isActionSendAvailable = ko.pureComputed(() => {
        const config = this.config();
        if (!config) {
            return false;
        }
        return config.examOrder.byId.status === API.ExaminationOrderStatus.Open;
    });
    public readonly isActionPublishAvailable = ko.pureComputed(() => {
        const config = this.config();
        if (!config) {
            return false;
        }
        return config.examOrder.byId.status === API.ExaminationOrderStatus.Ready;
    });
    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbar.Item>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'far fa-edit',
                text: i18n.t(['ui.superuser.examorderedit.EDIT']),
                visible: <any>this.isActionEditAvailable,
                onClick: () => {
                    void legacyPushPull(async () => {
                        await ServerConnection.api.ui_superuser_examorderedit_edit({
                            examOrderDocRef: this.params.currentRoute.widgetParams.examOrderDocRefId
                        });
                    });
                }
            })
        }));
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'upload',
                text: i18next.t(['ui.superuser.examorderedit.SEND']),
                visible: <any>this.isActionSendAvailable,
                onClick: this.sendExamOrder.click
            })
        }));
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'fas fa-graduation-cap',
                text: i18next.t(['ui.superuser.examorderedit.CREATE_EXAM']),
                visible: <any>this.isActionPublishAvailable,
                onClick: this.actionCreateExam.click
            })
        }));

        return DONE;
    }

    private readonly actionCreateExam = new UIAction(undefined, async () => {
        const d = await ServerConnection.api.ui_superuser_examorderedit_validate_before_to_publish({
            examOrder: this.params.currentRoute.widgetParams.examOrderDocRefId,
        });
        if (!d.examOrder.byId.validToPublish.ok) {
            toastService.error(d.examOrder.byId.validToPublish.validationMessages.map(x => x.value).join('\n'));
            return;
        }
        const c = this.config().examOrder.byId;
        const examId = c.examinationOrderId;
        const langs = new Set<string>();
        for (const subj of c.subjects) {
            for (const loc of subj.localizations) {
                langs.add(loc.id);
            }
        }
        const examIds = Array.from(langs).map(x => `${examId},${x}`);
        const existingExams = await ServerConnection.api.ui_superuser_examorderedit_examinations({
            filter: {
                id: examIds,
                includeObsolete: false,
            }
        });
        log(existingExams);
        if (existingExams.examination.get.length > 0) {
            const count = existingExams.examination.get.length;
            const raw = i18next.t(['ui.superuser.examorderedit.THERE_ARE_ALREADY_COUNT_EXAMS_WITH_ID_EXAMID_IF_YOU_CONTINUE_EXISTING_EXAMS_WILL_BE_MADE_OBSOLETE']);
            const msg = formatMessage(raw,
                {
                    count, examId
                });
            if (!await MODAL.confirmYesNo(msg)) {
                return;
            }
        }
        if (!await MODAL.confirmYesNo(i18next.t(['ui.superuser.examorderedit.YOU_ARE_ABOUT_TO_CREATE_AN_EXAM_OUT_OF_THIS_EXAM_ORDER_DO_YOU_WANT_TO_CONTINUE']))) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.ui_superuser_examorderedit_publish({
                docRef: this.params.currentRoute.widgetParams.examOrderDocRefId
            });
            await ServerConnection.api.ui_superuser_examorderedit_setobsolete({
                docRefs: existingExams.examination.get.map(x => x.docReferenceId)
            });
        });
        await routeManager.back();
    });

    private async confirmSend() {
        switch (await MODAL.dialog({
            message: i18next.t(['ui.superuser.examorderedit.DO_YOU_REALLY_WANT_TO_SEND_THE_EXAM_ORDER_TO_THE_SUBJECT_COORDINATOR_AFTER_CLICKING_ON_SEND_CHANGES_ARE_NO_LONGER_POSSIBLE']),
            yesCaption: i18next.t(['modal.SEND']),
            noCaption: i18next.t(['modal.CANCEL']),
        })) {
            case MODAL.ModalResult.YES:
                return true;

            default:
                return false;
        }
    }

    public readonly sendExamOrder = new UIAction(undefined, async () => {
        const d = await ServerConnection.api.ui_superuser_examorderedit_validate_to_sendtosubjectcoordinator({
            examOrder: this.params.currentRoute.widgetParams.examOrderDocRefId,
        });
        if (!d.examOrder.byId.validToSendToSubjectCoordinator.ok) {
            toastService.error(d.examOrder.byId.validToSendToSubjectCoordinator.validationMessages.map(x => x.value).join('\n'));
            return;
        }
        if (!await this.confirmSend()) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.ui_superuser_examorderedit_sendtosubjectcoordinator({
                docRef: this.params.currentRoute.widgetParams.examOrderDocRefId
            });
        });
        await routeManager.back();
    });
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

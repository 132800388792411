import * as ko from 'knockout';
import { log } from '../../../debug';
import { getGolemData, golemMatch, isStringMap } from '../../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry, greekToIndex } from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { CrossoutData, CrossoutData_Line, CrossoutData_Line_Fragment } from '../../model/crossout/CrossoutData';
import { GetSession } from '../../model/session';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './crossout.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-crossout';

class LineVM {
    readonly lineId: string;
    constructor(readonly model: MyModel, readonly data: CrossoutData_Line) {
        this.lineId = data.id;
    }
    public readonly correctText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.crossout.CORRECT']);
    });
    public readonly isLineCorrect = ko.pureComputed(() => this.data.isLineCorrect());
    public readonly isCorrect = ko.pureComputed(() => this.data.isCorrect());

    public readonly clickLineCorrect = new UIAction(undefined, async () => {
        await this.model.selectLineFragment.intent({
            lineId: this.lineId,
            fragmentId: `${this.lineId}/Fragment:#`
        });
    });

    public readonly fragments = ko.pureComputed(() => {
        return this.data.fragments.map(x => new FragmentVM(this.model, this, x));
    });
}

class FragmentVM {
    public readonly value: string;
    public readonly id: string;
    constructor(readonly model: MyModel, readonly line: LineVM, readonly data: CrossoutData_Line_Fragment) {
        this.id = data.id;
        this.value = data.value;
    }

    public readonly click = new UIAction(undefined, async () => {
        await this.model.selectLineFragment.intent({
            fragmentId: this.id,
            lineId: this.line.lineId
        });
    });

    public readonly isSelected = ko.pureComputed(() => this.data.isSelected());
    public readonly isCorrect = ko.pureComputed(() => this.data.isCorrect);
}

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof CrossoutData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: CrossoutData;

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        for (const x of this.lines()) {
            if (x.data.selectedFragment()) {
                continue;
            }
            const selectable = x.fragments().map(x => ({
                key: x.id,
                value: x.value
            }));
            let choiceId: string;
            const golemData = getGolemData(this.itemId);
            if (isStringMap(golemData)) {
                const idx = greekToIndex(x.lineId.substr(-1));
                const choice = golemData[idx + 1];
                if (choice) {
                    if (choice === '✓') {
                        await x.clickLineCorrect.invoke(undefined, true);
                        return true;
                    }
                    choiceId = golemMatch(selectable, choice);
                }
            }
            if (!choiceId) {
                choiceId = getRandomEntry(selectable).key;
            }
            const f = x.fragments().find(x => x.id === choiceId);
            await f.click.invoke(undefined, true);
            return true;
        }
        return false;
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.loaded(true);
    }


    public readonly lines = ko.pureComputed(() => {
        return this.data.lines.map(x => new LineVM(this, x));
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly selectLineFragment = new UIAction<{ lineId: string, fragmentId: string }>(undefined, async (e, args) => {
        await this.data.selectAnswer({
            lineId: args.lineId,
            fragmentId: args.fragmentId,
        });
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

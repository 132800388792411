import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import { formatMessage } from '../../../i18n/data';
import * as API from '../../../its-itembank-api.g';
import * as Modal from '../../../modal';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_settings_data']>>;

export class ViewModel extends DxWidget {
    public readonly sharePointsSectionTitle = ko.pureComputed(() => i18next.t(['ui.superuser.settings.CONNECTED_SHAREPOINTS']));

    // tslint:disable-next-line:max-func-body-length
    public readonly actionSyncSharePoint = new UIAction(undefined, async (e, args) => {
        const ids = this.grid().getSelectedRowKeys();
        if (!ids.length) {
            return;
        }

        const id = ids[0];
        const entry = this.ds.find(x => x.id === id);
        const message = formatMessage(i18next.t(['ui.superuser.settings.DO_YOU_WANT_TO_SYNCHRONIZE_THE_SHAREPOINT_CONNECTION_ID']), { id });
        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        const trigger = entry.triggerKey;
        const url = ServerConnection.getDataUrl(`trigger/sharepoint`, { id, trigger });
        this.terminalUrl(url.replace(/^http/, 'ws'));
    });
    public readonly terminalUrl = ko.observable<string>();

    public sharePointSyncNowButtonOptions() {
        const retVal: DevExpress.ui.dxButton.Properties = {
            icon: 'pulldown',
            text: i18next.t(['ui.superuser.settings.SYNCHRONIZE']),
            onClick: () => this.actionSyncSharePoint.click()
        };
        return retVal;
    }
    public readonly actionAddSharePoint = new UIAction(undefined, async (e) => {
        let idNr = 1;
        let id: string;
        const existingIds = new Set(this.ds.map(x => x.id));
        while (true) {
            id = `${idNr}`;
            if (!existingIds.has(id)) {
                break;
            }
            idNr++;
        }
        const pwdResponse = await ServerConnection.api.ui_superuser_settings_generatepassword({});
        const triggerKey = pwdResponse.generatePassword;
        await legacyPushPull(() => ServerConnection.api.ui_superuser_settings_updatesharepointconnection({
            params: {
                id,
                triggerKey,
            }
        }));
    });

    private readonly ds = new Array<Q['config']['sharePointConnections'][0]>();
    private readonly grid = ko.observable<DevExpress.ui.dxDataGrid>();
    public readonly loaded = ko.observable(false);
    public readonly sharePointEndPointsGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.grid,
            config: {
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'id',
                        data: this.ds
                    }
                },
                selection: {
                    mode: 'single'

                },
                masterDetail: {
                    enabled: false,
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: true,
                    allowUpdating: true,
                    mode: 'row'
                },
                onRowRemoving: e => {
                    e.cancel = (async () => {
                        const id: string = e.key;
                        const entry = this.ds.find(x => x.id === id);
                        const message = formatMessage(i18next.t(['ui.superuser.settings.DO_YOU_WANT_TO_REMOVE_THE_SHAREPOINT_CONNECTION_ID_POINTING_TO_SITEURL']), { id: entry.id, siteUrl: entry.siteUrl });
                        if (!await Modal.confirmYesNo(message)) {
                            throw new Error('Aborted by user');
                        }

                        await legacyPushPull(() => ServerConnection.api.ui_superuser_settings_deletesharepointconnection({
                            ids: [id]
                        }));
                    })();
                },
                onRowUpdating: (e) => {
                    e.cancel = legacyPushPull<void>(async () => {
                        await ServerConnection.api.ui_superuser_settings_updatesharepointconnection({
                            params: {
                                id: e.key,
                                password: e.newData.password,
                                username: e.newData.username,
                                siteUrl: e.newData.siteUrl,
                                downloadFolder: e.newData.downloadFolder,
                                triggerKey: e.newData.triggerKey,
                                forcedImport: e.newData.forcedImport,
                            }
                        });
                    });
                },

                onToolbarPreparing: (e: any) => {
                    var toolbarItems = e.toolbarOptions.items;
                    toolbarItems.push({
                        widget: 'dxButton',
                        options: {
                            icon: 'add',
                            text: i18next.t(['ui.superuser.settings.ADD']),
                            onClick: (e: any) => {
                                this.actionAddSharePoint.click();
                            }
                        },
                        location: 'after'
                    });

                    toolbarItems.push({
                        widget: 'dxButton',
                        location: 'after',
                        options: this.sharePointSyncNowButtonOptions()
                    });
                }
            }
        });
        retVal.columns.push({
            dataField: 'id',
            caption: 'ID',
            allowEditing: false,
        });
        retVal.columns.push({
            dataField: 'siteUrl',
            caption: i18next.t(['ui.superuser.settings.URL_OF_SHAREPOINT_SITE'])
        });

        retVal.columns.push({
            dataField: 'downloadFolder',
            caption: i18next.t(['ui.superuser.settings.FOLDER'])
        });

        retVal.columns.push({
            dataField: 'username',
            caption: i18next.t(['ui.superuser.settings.USER'])
        });

        retVal.columns.push({
            dataField: 'password',
            caption: i18next.t(['ui.superuser.settings.PASSWORD']),
            calculateDisplayValue: x => '*****'
        });
        retVal.columns.push({
            dataField: 'triggerKey',
            caption: i18next.t(['ui.superuser.settings.ACCESS_CODE']),
            cssClass: 'itsr3-uitest-hide',
            calculateDisplayValue: x => '*****'
        });
        retVal.columns.push({
            dataField: 'forcedImport',
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
            caption: i18next.t(['ui.superuser.settings.FORCED_IMPORT']),
        });
        return retVal;
    });

    constructor(readonly params: IParams) {
        super();
    }

    public async OnRefresh() {
        await super.OnRefresh();

        const data = await ServerConnection.api.ui_superuser_settings_data({});
        this.ds.splice(0, this.ds.length, ...data.config.sharePointConnections);
        const grid = this.grid();
        if (grid) {
            grid.getDataSource().reload();
        }
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import { greekToLatin } from '../../../new_array';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoMatchingText_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

export class MatchingTextData_Answer {

    public readonly id: string;
    public readonly connectedTo = ko.observable<string>();
    public readonly correctConnectTo: string;
    public readonly text: string;

    constructor(readonly vm: MatchingTextData, readonly nAnswer: number) {
        const itemAnswers = vm._data.mtAnswers;
        const answer = itemAnswers[nAnswer];
        this.text = translate(answer.value, {});
        this.id = answer.id;
        this.connectedTo(answer.selectedText);
        this.correctConnectTo = answer.correctText;
    }
}

export class MatchingTextData_Text {
    public readonly id: string;
    public readonly latinId: string;
    public readonly text: string;

    public isConnected() {
        return this.vm.answers.some(x => x.connectedTo() === this.id);
    }
    constructor(readonly vm: MatchingTextData, readonly nText: number) {
        const itemImages = vm._data.texts;
        const image = itemImages[nText];

        this.id = image.id;
        this.latinId = greekToLatin(this.id.substr(-1));
        this.text = translate(image.value, {});
    }
}

export class MatchingTextData extends ItemDataModel {
    public readonly answerTitle: string;
    public readonly textTitle: string;
    constructor(readonly meta: ItemModel, readonly _data: DATA) {
        super();
        this.headerText = translate(_data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(_data.question, {}),
            attachments: meta.attachments,
        });

        this.answerTitle = translate(_data.answerTitle, {});
        this.textTitle = translate(_data.textTitle, {});

        this.answers = _data.mtAnswers.map((d, index) => new MatchingTextData_Answer(this, index));
        this.texts = _data.texts.map((d, index) => new MatchingTextData_Text(this, index));
        this.fromJS(_data);
    }
    public fromJS(data: DATA) {
    }
    public readonly imgUrl: string;
    public readonly headerText: string;
    public readonly questionHTML: string;

    public readonly answers: MatchingTextData_Answer[];
    public readonly texts: MatchingTextData_Text[];

    public async connect({ answerId, textId }: { answerId: string, textId: string }) {
        if (!answerId || !textId) {
            return;
        }

        for (const ans of this.answers) {
            if (ans.id === answerId) {
                ans.connectedTo(textId);
            } else if (ans.connectedTo() === textId) {
                ans.connectedTo(undefined);
            }
        }
    }

    public IsInteractionStarted() {
        return this.answers.some(x => !!x.connectedTo());
    }
    public IsInteractionComplete() {
        return this.answers.every(x => !!x.connectedTo());
    }
    public async reset() {
        for (const answer of this.answers) {
            answer.connectedTo(undefined);
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            MatchingText: {
                answers: this.answers.map(x => ({
                    answerId: x.id,
                    textId: x.connectedTo(),
                })),
            }
        };
        return retVal;
    }

}
export const id = 'surveysinglechoicetext';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'bebc1093-a6fc-47e3-b47f-2ea83fc3860d',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});
﻿import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { UIAction } from '../../ui/uiAction';
import { htmlString } from './button.html.g';

const WIDGET_NAME = 'widgets-button';
const WIDGET_PARENT_NAME = 'widgets';

type voidClick = () => void;

function isUIAction(data: voidClick | UIAction<{}>): data is UIAction<{}> {
    return data instanceof UIAction;
}

export interface IParams {
    iconSize?: string;
    text?: ko.MaybeSubscribable<string>;
    css?: ko.MaybeSubscribable<string | { [key: string]: boolean }>;
    click?: voidClick | UIAction<{}>;
    icon?: string;
    title?: ko.MaybeSubscribable<string>;
}

export class MyModel extends AbstractWidget {
    public readonly i18n = ko.pureComputed(() => {
        const caption = ko.unwrap(this.params.text);
        if (caption) {
            return caption;
        }
        return undefined;
    });

    public readonly title = ko.pureComputed(() => {
        return ko.unwrap(this.params.title);
    });
    public readonly iconSize: string;
    public readonly css = ko.pureComputed(() => {
        return ko.unwrap(this.params.css);
    });

    constructor(readonly params: IParams) {
        super();
        if (!params) {
            params = {};
        }
        this.iconSize = params.iconSize || '';
    }

    private isStyleBasedIcon() {
        if (!this.params.icon) {
            return false;
        }
        return this.params.icon.startsWith('fas ') || this.params.icon.startsWith('far ') || this.params.icon.startsWith('fa ');
    }
    public readonly iconText = ko.pureComputed(() => {
        if (this.isStyleBasedIcon()) {
            return '';
        }
        return this.params.icon || undefined;
    });

    public readonly hasIcon = ko.pureComputed(() => {
        return !!this.params.icon;
    })

    public readonly iconClass = ko.pureComputed(() => {
        if (this.isStyleBasedIcon()) {
            return this.params.icon + ' ' + this.iconSize;
        } else {
            return 'material-icons alignFix' + ' ' + this.iconSize;
        }
    });

    public readonly buttonStyleCSS = ko.pureComputed(() => {
        const retVal = new Array<string>();
        const css = this.css();
        if (css) {
            if (typeof css === 'string') {
                retVal.push(css);
            } else {
                for (const key of Object.keys(css)) {
                    if (css[key]) {
                        retVal.push(key);
                    }
                }
            }
        }
        return retVal.join(' ');
    });

    public onClick() {
        const click = this.params.click;
        if (click) {
            if (isUIAction(click)) {
                void click.invoke(undefined, true);
            } else {
                (<voidClick>click)();
            }
        }
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { Intent, UIAction } from '../../uiAction';
import * as WIDGET_GRADING from '../grading/route';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './selectitem.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_manualgrading_selectitem_data']>>;

const WIDGET_PARENT_NAME = 'ui-manualgrading';

export class ItemGradingData {
    public readonly itemDocRefId = ko.pureComputed(() => {
        return this.data.item.itemId;
    });
    constructor(readonly data: Q['itemsForManualGrading'][0], readonly actionSelectItem: Intent<string>) {
    }

    public readonly itemTypeText = ko.pureComputed(() => {
        return i18next.t(['ui.manualgrading.selectitem.ITEM_TYPE']);
    });
    public readonly gradingCountText = ko.pureComputed(() => {
        return i18next.t(['ui.manualgrading.selectitem.AMOUNT_OF_ITEMS_TO_EVALUATE']);
    });

    public readonly gradingCount = ko.pureComputed(() => {
        return this.data.sessionsForItemCount;
    });
    public readonly title = ko.pureComputed(() => {
        return this.data.item.metaData.title;
    });
    public readonly itemType = ko.pureComputed(() => {
        return this.data.item.itemType.title.value;
    });
    public readonly selectItem = new UIAction(undefined, async () => {
        await this.actionSelectItem(this.itemDocRefId());
    });
}

export class ViewModel extends AbstractWidget {
    public readonly loaded = ko.observable(false);
    public readonly availableItems = ko.observableArray<ItemGradingData>();

    constructor(readonly params: IParams) {
        super();
    }


    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly subjectDocRefId = ko.pureComputed(() => {
        return this.params.currentRoute.widgetParams.subjectDocRefId;
    });
    public async OnRefresh() {
        await super.OnRefresh();
        const d = await ServerConnection.api.ui_manualgrading_selectitem_data({
            subject: this.subjectDocRefId(),
        });
        const items = d.itemsForManualGrading.map(item => new ItemGradingData(item, this.actionSelectItem.intent));
        this.availableItems(items);
    }

    public readonly actionSelectItem = new UIAction<string>(undefined, async (e, itemDocRefId) => {
        await ROUTES.routeManager.navigateToHREF(WIDGET_GRADING.FACTORY.href({
            subject: this.params.currentRoute.widgetParams.subjectDocRefId,
            item: itemDocRefId
        }));
    });

    public readonly noSessionsForGrading = ko.pureComputed(() => {
        return i18next.t(['ui.manualgrading.selectitem.NO_SESSIONS_AVAILABLE_FOR_EVALUATION']);
    });
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

﻿// tslint:disable:max-func-body-length


// Note: SCORM and IEEE 1484.11.1 require centisec precision
// Will return non-standard '∞' if n is 'Infinity'
export function centisecsToISODuration(n: number) {
    if (n === Infinity) {
        return '∞';
    }
    if (n === -Infinity) {
        return '-∞';
    }
    // Note: SCORM and IEEE 1484.11.1 require centisec precision
    // Parameters:
    // n = number of centiseconds
    let str = 'P';
    let nD = 0;
    let nH = 0;
    let nMin = 0;
    n = Math.max(n, 0); // there is no such thing as a negative duration
    let nCs = n;
    // Next set of operations uses whole seconds
    nCs = Math.round(nCs);
    nD = Math.floor(nCs / 8640000);
    nCs -= nD * 8640000;
    nH = Math.floor(nCs / 360000);
    nCs -= nH * 360000;
    nMin = Math.floor(nCs / 6000);
    nCs -= nMin * 6000;

    if (nD > 0) {
        str += nD + 'D';
    }
    if ((nH > 0) || (nMin > 0) || (nCs > 0)) {
        str += 'T';
        if (nH > 0) {
            str += nH + 'H';
        }
        if (nMin > 0) {
            str += nMin + 'M';
        }
        if (nCs > 0) {
            str += (nCs / 100) + 'S';
        }
    }
    if (str === 'P') {
        str = 'PT0H0M0S';
    }
    // technically PT0S should do but SCORM test suite assumes longer form.
    return str;
}

/* If str is not set (undefined, null, ''), then PT0M is assumed */
export function isoDurationToCentisec(str: string) {
    if (!str || typeof str !== 'string') {
        return 0;
    }
    if (str === '∞') {
        return Infinity;
    }
    if (str === '-∞') {
        return -Infinity;
    }
    // Only gross syntax check is performed here
    // Months calculated by approximation based on average number
    // of days over 4 years (365*4+1), not counting the extra days
    // in leap years. If a reference date was available,
    // the calculation could be more precise, but becomes complex,
    // since the exact result depends on where the reference date
    // falls within the period (e.g. beginning, end or ???)
    // 1 year ~ (365*4+1)/(4*60*60*24*100) = 3155760000 centiseconds
    // 1 month ~ (365*4+1)/(4*12*60*60*24*100) = 262980000 centiseconds
    // 1 day = 8640000 centiseconds
    // 1 hour = 360000 centiseconds
    // 1 minute = 6000 centiseconds
    const aV = new Array(0, 0, 0, 0, 0, 0);
    let bErr = false;
    let bTFound = false;
    if (str.indexOf('P') !== 0) {
        bErr = true;
    }
    if (!bErr) {
        const aT = new Array('Y', 'M', 'D', 'H', 'M', 'S');
        let p = 0;
        let i = 0;
        str = str.substr(1); //get past the P
        for (i = 0; i < aT.length; i++) {
            if (str.indexOf('T') === 0) {
                str = str.substr(1);
                i = Math.max(i, 3);
                bTFound = true;
            }
            p = str.indexOf(aT[i]);
            if (p > -1) {
                // Is this a M before or after T?
                if ((i === 1) && (str.indexOf('T') > -1) && (str.indexOf('T') < p)) {
                    continue;
                }
                if (aT[i] === 'S') {
                    aV[i] = parseFloat(str.substr(0, p));
                } else {
                    aV[i] = parseInt(str.substr(0, p), 10);
                }
                if (isNaN(aV[i])) {
                    bErr = true;
                    break;
                } else if ((i > 2) && (!bTFound)) {
                    bErr = true;
                    break;
                }
                str = str.substr(p + 1);
            }
        }
        if ((!bErr) && (str.length !== 0)) {
            bErr = true;
        }
    }
    if (bErr) {
        return undefined;
    }
    return aV[0] * 3155760000 + aV[1] * 262980000
        + aV[2] * 8640000 + aV[3] * 360000 + aV[4] * 6000
        + Math.round(aV[5] * 100);
}

// Legacy functions to translate to/from SCORM 1.2 format

export function scorm12DurationToCs(str: string) {
    // Format is [HH]HH:MM:SS[.SS] or maybe sometimes MM:SS[.SS]
    // Does not catch all possible errors
    // First convert to centisecs
    const a = str.split(':');
    let nS = 0;
    let n = 0;
    let nMult = 1;
    let bErr = ((a.length < 2) || (a.length > 3));
    if (!bErr) {
        for (let i = a.length - 1; i >= 0; i--) {
            n = parseFloat(a[i]);
            if (isNaN(n)) {
                bErr = true;
                break;
            }
            nS += n * nMult;
            nMult *= 60;
        }
    }
    if (bErr) {
        return NaN;
    }
    return Math.round(nS * 100);
}

export function centisecsToMinutes(n: number) {
    if (n === Infinity) {
        return '∞';
    }
    const d = centisecsToDuration(n);
    return (d.hours * 60 + d.minutes).toString();
}

export function centisecsToDuration(n: number) {
    if (n === Infinity) {
        return {
            hours: Infinity,
            minutes: 0,
            seconds: 0,
            centiseconds: 0,
            text: '∞'
        };
    }
    if (n === -Infinity) {
        return {
            hours: -Infinity,
            minutes: 0,
            seconds: 0,
            centiseconds: 0,
            text: '∞'
        };
    }
    n = Math.round(n);
    const nH = Math.floor(n / 360000);
    let nCs = n - nH * 360000;
    const nM = Math.floor(nCs / 6000);
    nCs = nCs - nM * 6000;
    const nS = Math.floor(nCs / 100);
    nCs = nCs - nS * 100;

    let str = '';
    if (nH) {
        str += nH + ':';
    }
    if (nM < 10) {
        str += '0';
    }
    str += nM + ':';
    if (nS < 10) {
        str += '0';
    }
    str += nS;
    return {
        hours: nH,
        minutes: nM,
        seconds: nS,
        centiseconds: nCs,
        text: str
    };
}
export function centisecsToSCORM12Duration(n: number) {
    // Format is [HH]HH:MM:SS[.SS]
    let bTruncated = false;
    n = Math.round(n);
    let nH = Math.floor(n / 360000);
    let nCs = n - nH * 360000;
    const nM = Math.floor(nCs / 6000);
    nCs = nCs - nM * 6000;
    const nS = Math.floor(nCs / 100);
    nCs = nCs - nS * 100;
    if (nH > 9999) {
        nH = 9999;
        bTruncated = true;
    }
    let str = '0000' + nH + ':';
    str = str.substr(str.length - 5, 5);
    if (nM < 10) {
        str += '0';
    }
    str += nM + ':';
    if (nS < 10) {
        str += '0';
    }
    str += nS;
    if (nCs > 0) {
        str += '.';
        if (nCs < 10) {
            str += '0';
        }
        str += nCs;
    }
    //if (bTruncated) alert ("Hours truncated to 9999 to fit HHHH:MM:SS.SS format")
    return str;
}

// time stamp helper function. Returns a time stamp in ISO format 

export function makeISOtimeStamp(objSrcDate?: Date, bRelative?: boolean, nResolution?: null | 0 | 2) {
    // Make an ISO 8601 time stamp string as specified for SCORM 2004
    // * objDate is an optional ECMAScript Date object;
    //   if objDate is null, "this instant" is assumed.
    // * bRelative is optional; if bRelative is true,
    //   the time stamp will show local time with a time offset from UTC;
    //   otherwise the time stamp will show UTC (a.k.a. Zulu) time.
    // * nResolution is optional; it specifies max decimal digits
    //   for fractions of second; it can be null, 0 or 2. If null, 2 is assumed.
    let s = '';
    let nY = 0;
    let nM = 0;
    let nD = 0;
    let nH = 0;
    let nMin = 0;
    let nS = 0;
    let nMs = 0;
    let nCs = 0;
    const bCentisecs = ((isNaN(nResolution)) || (nResolution !== 0));
    // Need to make a copy of the source date object because we will
    // tweak it if we need to round up to next second
    const objDate = new Date();
    if (objSrcDate) {
        objDate.setTime(objSrcDate.getTime());
    }

    if (bRelative) {
        nMs = objDate.getMilliseconds();
    } else {
        nMs = objDate.getUTCMilliseconds();
    }

    if (bCentisecs) {
        // Default precision is centisecond. Let us see whether we need to add
        // a rounding up adjustment
        if (nMs > 994) {
            ((bRelative) ? objDate.setMilliseconds(1000) : objDate.setUTCMilliseconds(1000));
        } else {
            nCs = Math.floor(nMs / 10);
        }
    } else {
        // Precision is whole seconds; round up if necessary
        if (nMs > 499) {
            ((bRelative) ? objDate.setMilliseconds(1000) : objDate.setUTCMilliseconds(1000));
        }
    }
    if (bRelative) {
        nY = objDate.getFullYear();
        nM = objDate.getMonth();
        nD = objDate.getDate();
        nH = objDate.getHours();
        nMin = objDate.getMinutes();
        nS = objDate.getSeconds();
    } else {
        nY = objDate.getUTCFullYear();
        nM = objDate.getUTCMonth();
        nD = objDate.getUTCDate();
        nH = objDate.getUTCHours();
        nMin = objDate.getUTCMinutes();
        nS = objDate.getUTCSeconds();
    }
    // Note: Date.Month() and Date.UTCMonth() are base 0 not 1
    s = nY + '-' +
        zeroPad(nM + 1, 2) + '-' +
        zeroPad(nD, 2) + 'T' +
        zeroPad(nH, 2) + ':' +
        zeroPad(nMin, 2) + ':' +
        zeroPad(nS, 2);
    if (nCs > 0) {
        s += '.' + zeroPad(nCs, 2);
    }
    if (bRelative) {
        // Need to flip the sign of the time zone offset
        let nTZOff = -objDate.getTimezoneOffset();
        if (nTZOff >= 0) {
            s += '+';
        }
        s += zeroPad(Math.round(nTZOff / 60), 2);
        nTZOff = nTZOff % 60;
        if (nTZOff > 0) {
            s += ':' + zeroPad(nTZOff, 2);
        }
    } else {
        s += 'Z';
    }
    return s;
}

function zeroPad(n: number, nLength: number) {
    // Takes a number and pads it with leading 0 to the length specified.
    // The padded length does not include negative sign if present.
    const bNeg = (n < 0);
    let s = n.toString();
    if (bNeg) {
        s = s.substr(1, s.length);
    }
    while (s.length < nLength) {
        s = '0' + s;
    }
    if (bNeg) {
        s = '-' + s;
    }
    return s;
}

let gsParseErr = '';

export function dateFromISOString(strDate: string) {
    // Convert an ISO 8601 formatted string to a local date
    // Returns an ECMAScript Date object or null if an error was detected
    // Assumes that the string is well formed and SCORM conformant
    // otherwise a runtime error may occur in this function.
    // In practice the data range is limited to the date range supported
    // by the ECMAScript Date object. See the ECMAScript standard for details.
    let sDate = strDate; // The date part of the input, after a little massaging
    let sTime: string = null; // The time part of the input, if it is included
    let sTimeOffset: string = null; // UTC offset, if specified in the input string
    let sUTCOffsetSign = '';
    let a: string[] = null; // Will be reused for all kinds of string splits
    let n = 0;
    let nY = 0;
    let nM = 0;
    let nD = 1;
    let nH = 0;
    let nMin = 0;
    let nS = 0;
    let nMs = 0;

    gsParseErr = '';

    // If this is "Zulu" time, it will make things a little easier
    const bZulu = (sDate.indexOf('Z') > -1);
    if (bZulu) {
        sDate = sDate.substr(0, sDate.length - 1);
    }

    // Parse the ISO string into date and time
    if (sDate.indexOf('T') > -1) {
        a = sDate.split('T');
        sDate = a[0];
        sTime = a[1];
    }
    // Parse the date part
    a = sDate.split('-');
    nY = parseInt(a[0], 10);
    if ((isNaN(nY)) || (nY > 9999) || (nY < 0)) {
        gsParseErr = 'Invalid year value:\n' + strDate;
        return null;
    }
    if (a.length > 1) {
        nM = parseInt(a[1], 10) - 1; // months are in base 0
        if (nM < 0) {
            alert('a[1] =' + a[1] + ' from ' + strDate);
        }
        if (a.length > 2) {
            nD = parseInt(a[2], 10); // days are in base 1
        }
    }
    // Done with the date. If there is a time part, parse it out.
    if (sTime) {
        if (sTime.indexOf('-') > -1) {
            sUTCOffsetSign = '-';
        }
        if (sTime.indexOf('+') > -1) {
            sUTCOffsetSign = '+';
        }
        if (sUTCOffsetSign !== '') {
            if (bZulu) {
                gsParseErr = 'You can\'t have both UTC offset and Zulu in ISO time stamp: \n' + strDate;
                return null;
            }
            a = sTime.split(sUTCOffsetSign);
            sTime = a[0];
            sTimeOffset = a[1];
        }
        a = sTime.split(':');
        nH = parseInt(a[0], 10);
        if (a.length > 1) {
            nMin = parseInt(a[1], 10);
            if (a.length > 2) {
                (a[2].indexOf('.') < 0 ? nS = parseInt(a[2], 10) : nS = parseFloat(a[2]));
                if (isNaN(nS)) {
                    gsParseErr = 'Error parsing seconds: ' + a[2] + '\n' + strDate;
                    return null;
                }
                nMs = Math.round((nS % 1) * 1000);
                nS = Math.floor(nS);
            }
        }
    } else if (bZulu) {
        gsParseErr = 'UTC not allowed in time stamp unless there is a Time part:\n' +
            strDate;
        return null;
    }
    const objDate = new Date();
    if (bZulu) {
        objDate.setUTCFullYear(nY, nM, nD);
        objDate.setUTCHours(nH, nMin, nS, nMs);
    } else {
        // Calculate and apply the time offset for local time
        if (sTimeOffset) {
            let nOffset = 0;
            a = sTimeOffset.split(':');
            nOffset = parseInt(a[0], 10);
            if (isNaN(nOffset)) {
                gsParseErr = 'Found UTC time offset but offset value is NaN:\n' + strDate;
                return null;
            }
            nOffset = nOffset * 60;
            if (a.length > 1) {
                n = parseInt(a[1], 10);
                if (isNaN(n)) {
                    gsParseErr = 'Found UTC time offset minutes but minute value is NaN:\n' +
                        strDate;
                    return null;
                }
                nOffset += n;
            }
            nOffset = nOffset * 60; // minutes to milliseconds
            if (sUTCOffsetSign === '-') {
                nOffset = -nOffset;
            }
            objDate.setTime(objDate.getTime() + nOffset);
        }
        objDate.setFullYear(nY, nM, nD);
        objDate.setHours(nH, nMin, nS, nMs);
    }
    return objDate;
}

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import { superuser } from '../../ui-routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_golemscriptoverview_data']>>;
type GOLEMSCRIPT = Q['golemScripts']['all'][0];
export class ViewModel extends DxWidget {
    public readonly actionEdit = new UIAction<GOLEMSCRIPT>(undefined, async (e, args) => {
        const golemScriptDocRefId = args.docReferenceId;
        await ROUTES.routeManager.navigateToHREF(superuser.GolemScriptEdit.href({
            golemScriptDocRefId
        }));
    });

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._golemScripts;
                        },
                        byKey: async key => {
                            return this._golemScripts.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.golemscriptoverview.TITLE']),
            dataField: 'title',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.golemscriptoverview.ID']),
            dataField: 'golemScriptId',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.golemscriptoverview.DEACTIVATED']),
            dataField: 'isDeleted',
            dataType: 'boolean',
        });

        retVal.columns.push({
            type: 'buttons',
            caption: i18next.t(['ui.superuser.golemscriptoverview.ACTIONS']),
            width: '80',
            buttons: [{
                text: i18next.t(['ui.superuser.golemscriptoverview.EDIT']),
                onClick: e => {
                    const data: GOLEMSCRIPT = e.row.data;
                    void this.actionEdit.intent(data);
                },
            }]
        });
        retVal.filterValue = ['isDeleted', '=', false];
        return retVal;
    });

    private readonly _golemScripts: GOLEMSCRIPT[] = [];
    public readonly loaded = ko.observable(false);

    public async OnRefresh() {
        await super.OnRefresh();
        const result = await ServerConnection.api.ui_superuser_golemscriptoverview_data({});
        this._golemScripts.splice(0, this._golemScripts.length, ...result.golemScripts.all);

        await refreshDx(this);
    }

    constructor(readonly params: IParams) {
        super();
    }

    public readonly actionNewGolemScript = new UIAction(undefined, async () => {
        const newResult = await ServerConnection.api.ui_superuser_golemscriptoverview_new({});
        const golemScriptDocRefId = newResult.golemScripts.new.docReferenceId;
        await ROUTES.routeManager.navigateToHREF(superuser.GolemScriptEdit.href({
            golemScriptDocRefId
        }));
    });

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'plus',
                text: i18next.t(['ui.superuser.golemscriptoverview.NEW']),
                onClick: this.actionNewGolemScript.click
            })
        }));
        return DONE;
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }

}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

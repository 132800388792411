import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { log } from '../../../debug';
import { AS } from '../../../dx_helper';
import * as query from '../../../getquery';
import * as HELPER from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { DisplayItemIdEnum, DisplayObjectiveIdEnum, EMailConfirmed } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { naturalCompare } from '../../../shareable/natural-sort';
import { DefaultWindowManager } from '../../base';
import { ServerConnection } from '../../RestAPI';
import { UIAction } from '../../uiAction';
import { Item } from '../../widgets/itemoverviewtable/Item';
import { ItemOverviewBodyFieldTemplate } from '../../widgets/itemoverviewtable/ItemOverviewBodyFieldTemplate';
import { ItemOverviewHeaderField } from '../../widgets/itemoverviewtable/ItemOverviewHeaderField';
import * as ITEM_TABLE from '../../widgets/itemoverviewtable/widget';
import { Objective } from '../../widgets/objectiveoverviewtable/Objective';
import { ObjectiveOverviewBodyFieldTemplate } from '../../widgets/objectiveoverviewtable/ObjectiveOverviewBodyFieldTemplate';
import { ObjectiveOverviewHeaderField } from '../../widgets/objectiveoverviewtable/ObjectiveOverviewHeaderField';
import * as OBJECTIVE_TABLE from '../../widgets/objectiveoverviewtable/widget';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { formatNumber } from './formatNumber';
import { isTable } from './tables';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_resultscreens_default_data']>>;
const WIDGET_NAME = 'ui-resultscreens-default';

export interface IParams {
    closeWindow?: () => Promise<void>;
    mode: 'print' | 'screen',
    sessionId: string;
}




export class ViewModel extends AbstractWidget {
    public constructor(readonly params: IParams) {
        super();
    }


    public readonly objectiveOverviewTableParams = ko.pureComputed(() => {
        return {
            name: OBJECTIVE_TABLE.WIDGET_NAME,
            params: AS<OBJECTIVE_TABLE.IParams>({
                objectives: this.objectives,
                objectiveOverviewHeader: this.objectiveOverviewHeader,
            })
        };
    });
    public readonly itemOverviewTableParams = ko.pureComputed(() => {
        return {
            name: ITEM_TABLE.WIDGET_NAME,
            params: AS<ITEM_TABLE.IParams>({
                items: this.items,
                itemOverviewHeader: this.itemOverviewHeader,
            })
        };
    });

    public readonly itemOverviewHeader = ko.observable<ItemOverviewHeaderField[]>();
    public readonly itemBodyFields = ko.observable<ItemOverviewBodyFieldTemplate[]>();

    public readonly objectiveOverviewHeader = ko.observable<ObjectiveOverviewHeaderField[]>();
    public readonly objectiveBodyFields = ko.observable<ObjectiveOverviewBodyFieldTemplate[]>();


    public readonly loaded = ko.observable(false);

    public readonly hasEMail = ko.pureComputed(() => {
        const es = this.result().examSession;
        const ei = es.examInfo;
        const rp = ei.resultPage;
        if (!rp) {
            return false;
        }
        return rp.email !== 'no' && ei.itemHost === 'hierarchical';
    });

    public readonly hasPDFButton = ko.pureComputed(() => {
        const es = this.result().examSession;
        const ei = es.examInfo;
        const rp = ei.resultPage;
        if (!rp) {
            return false;
        }
        return rp.pdf === 'yes' && rp.pdfButton.visibility !== 'hide' && ei.itemHost === 'hierarchical';
    });

    public readonly emailConsentOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxCheckBox.Properties = {
            value: <any>this.emailEnabled,
            text: this.emailConsentText(),
        };
        return retVal;

    });
    public readonly emailConsentText = ko.pureComputed(() => {
        const id = this.result();
        if (!id) {
            return '';
        }
        const rp = id.examSession.examInfo.resultPage;
        return rp && rp.emailText.value;
    });

    public readonly emailEnabled = ko.observable(false);

    public readonly hasCloseButton = ko.pureComputed(() => {
        const es = this.result().examSession;
        const ei = es.examInfo;
        const rp = ei.resultPage;
        if (rp) {
            if (rp.closeButton.visibility === 'hide') {
                return false;
            }
        }
        return !!this.params.closeWindow;
    });

    public readonly displayItemId = ko.pureComputed(() => {
        const es = this.result().examSession;
        const ei = es.examInfo;
        const rp = ei.resultPage;
        const ps = ei.pdfReportSettings;
        const setting = (ps && ps.displayItemId) || (rp && rp.displayItemId);
        if (setting == DisplayItemIdEnum.No) {
            return false;
        } else {
            return true;
        }
    });
    public readonly displayObjectiveId = ko.pureComputed(() => {
        const es = this.result().examSession;
        const ei = es.examInfo;
        const rp = ei.resultPage;
        const ps = ei.pdfReportSettings;
        const setting = (ps && ps.displayObjectiveId) || (rp && rp.displayObjectiveId);
        if (setting == DisplayObjectiveIdEnum.No) {
            return false;
        } else {
            return true;
        }
    });

    public readonly text = ko.pureComputed(() => {
        const text = this.textRaw() || this.defaultText();
        if (!text) {
            return '';
        }
        const data = this.result();
        const score = data && data.examSession && data.examSession.score;
        if (!score) {
            return '';
        }
        const html = RICHTEXTHTML.process({
            html: text,
            convertLFtoBR: 'auto'
        });
        return html
            .replace(/\{pointsPercent\}/g, formatNumber(score.pointsPercentage))
            .replace(/\{pointsGained\}/g, formatNumber(score.pointsGained))
            .replace(/\{pointsPending\}/g, formatNumber(score.pointsPending))
            .replace(/\{pointsLost\}/g, formatNumber(score.pointsLost))
            .replace(/\{pointsMax\}/g, formatNumber(score.pointsMax))
            .replace(/\{moduleTitle\}/g, data.examSession.examInfo && data.examSession.examInfo.title || '')
            .replace(/\{candidateId\}/g, data.examSession.candidate && data.examSession.candidate.candidateId || '')
            .replace(/\{candidateDisplay\}/g, data.examSession.candidate && data.examSession.candidate.display || '')
            ;
    });

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (this.hasCloseButton()) {
            await this.actionClose.invoke();
            return true;
        }
        return false;
    }

    public readonly actionClose = new UIAction(undefined, async () => {
        if (this.params.closeWindow) {
            await this.params.closeWindow();
        }
    });
    public readonly actionPDF = new UIAction(undefined, async () => {
        const wnd = DefaultWindowManager.prepareOpenWindow('');
        const url = ServerConnection.getDataUrl(`sessions/${this.params.sessionId}/pdf`, {}, true);
        DefaultWindowManager.openWindow(url, '');
    });

    private readonly defaultText = ko.pureComputed(() => {
        return i18next.t(['ui.resultscreens.default.PLEASE_CLOSE_THIS_WINDOW']);
    });
    public readonly closeButtonOptions = ko.pureComputed(() => {
        const retVal = AS<DevExpress.ui.dxButton.Properties>({
            text: this.closeButtonLabel() || i18next.t(['ui.resultscreens.default.CLOSE']),
            icon: 'close',
            onClick: this.actionClose.click,
        });
        return retVal;
    });

    public readonly pdfButtonOptions = ko.pureComputed(() => {
        const retVal = AS<DevExpress.ui.dxButton.Properties>({
            text: this.pdfButtonLabel() || i18next.t(['ui.resultscreens.default.PDF']),
            icon: 'download',
            onClick: this.actionPDF.click,
        });
        return retVal;
    });

    private readonly result = ko.observable<Q>();

    public readonly objectiveHeader = ko.observable('');
    public readonly itemHeader = ko.observable('');


    public readonly objectiveTextRaw = ko.observable('');
    public readonly itemTextRaw = ko.observable('');
    public readonly textRaw = ko.observable('');

    public readonly hasObjectives = ko.pureComputed(() => !!this.objectiveTextRaw());
    public readonly objectives = ko.observable<Objective[]>();

    public readonly pdfButtonLabel = ko.observable('');
    public readonly closeButtonLabel = ko.observable('');
    public readonly hasItems = ko.pureComputed(() => !!this.itemTextRaw());
    public readonly items = ko.observable<Item[]>();

    public readonly finishPageEMailOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxTextBox.Properties = {
            value: <any>this.email,
            disabled: <any>this.emailDisabled,
        };
        return retVal;
    });
    public readonly emailDisabled = ko.pureComputed(() => !this.emailEnabled());
    public readonly email = ko.observable('');
    public readonly emailLabel = ko.pureComputed(() => {
        const id = this.result();
        if (!id) {
            return '';
        }
        const rp = id.examSession.examInfo.resultPage;
        return rp && rp.emailLabel.value;
    });

    public readonly hasButtons = ko.pureComputed(() => {
        return this.hasCloseButton() || this.hasPDFButton();
    });

    private async refresh() {
        const data = await ServerConnection.api.ui_resultscreens_default_data({
            sessionDocRefId: this.params.sessionId,
            withPDF: this.params.mode === 'print',
            withResultPage: this.params.mode === 'screen',
        });
        this.result(data);
        const es = data.examSession;
        const ei = es && data.examSession.examInfo;
        const rp = ei && ei.resultPage;
        const ps = ei && ei.pdfReportSettings;
        const psOrRp = rp ?? ps;
        this.textRaw(psOrRp.text.value);
        this.objectiveHeader(psOrRp.objectiveHeader.value);
        this.objectiveTextRaw(psOrRp.objectiveText.value);
        this.closeButtonLabel(rp?.closeButton?.label?.value || '');
        this.pdfButtonLabel(rp?.pdfButton?.label?.value || '');
        this.itemHeader(psOrRp.itemHeader.value);
        this.itemTextRaw(psOrRp.itemText.value);
        this.emailEnabled((es?.notification?.emailConfirmed !== EMailConfirmed.No) || false);
        this.email(es?.notification?.email || '');
        const includeObjectives = !!this.objectiveTextRaw();
        const includeItems = !!this.itemTextRaw();
        if (includeObjectives || includeItems) {
            const minDepth = psOrRp.objectiveMinDepth || 0;
            const maxDepth = psOrRp.objectiveMaxDepth || 4;
            const d = await ServerConnection.api.ui_resultscreens_default_objectives({
                includeItems,
                includeObjectives,
                maxDepth,
                sessionDocRefId: this.params.sessionId
            });
            if (includeObjectives) {
                const objs = d.examSession.objectives;
                const rootIds = objs.filter(x => x.objectiveId.split('.').length === 1).map(x => x.objectiveId);
                if (rootIds.length === 1) {
                    const remove = rootIds[0];
                    const replace = remove + '.';
                    for (const obj of objs) {
                        if (obj.objectiveId === remove) {
                            obj.objectiveId = '';
                        } else if (obj.objectiveId.startsWith(replace)) {
                            obj.objectiveId = obj.objectiveId.substr(replace.length);
                        }
                    }
                }
                const sortedObjs = objs
                    .filter(x => x.objectiveDepth >= minDepth)
                    .filter(x => x.objectiveDepth <= maxDepth)
                    .filter(x => !!x.objectiveId)
                    .sort((a, b) => naturalCompare(a.objectiveId, b.objectiveId));
                this.objectives(sortedObjs.map(x => new Objective(this, x)));
                log(sortedObjs.map(x => x.objectiveId));
            } else {
                this.objectives(undefined);
            }
            if (includeItems) {
                this.items(d.examSession.items
                    .map(x => new Item(this, x)));
            } else {
                this.items(undefined);
            }
        }

        const itemText = psOrRp?.itemText?.value || '';
        if (isTable(itemText)) {
            const parse = ITEM_TABLE.parseItemTable(itemText);
            this.itemOverviewHeader(parse.headerFields);
            this.itemBodyFields(parse.bodyTemplateFields);
        } else {
            this.itemOverviewHeader(undefined);
            const bodyFields = [];
            if (this.displayItemId()) {
                bodyFields.push(new ItemOverviewBodyFieldTemplate('{id}', 'ID'));
            }
            bodyFields.push(new ItemOverviewBodyFieldTemplate(itemText, 'Description'));
            bodyFields.push(new ItemOverviewBodyFieldTemplate('{score}', 'Score'));
            this.itemBodyFields(bodyFields);
        }

        const objectiveText = psOrRp?.objectiveText?.value || '';
        if (isTable(objectiveText)) {
            const parse = OBJECTIVE_TABLE.parseObjectiveTable(objectiveText, val => {
                return val
                    .replace(/\{id\}/g, i18n.t(['ui.resultscreens.default.ITEM_ID']))
                    .replace(/\{title\}/g, i18n.t(['ui.resultscreens.default.ITEM_TITLE']))
                    .replace(/\{pointsPercent\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_PERCENT']))
                    .replace(/\{pointsGained\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_GAINED']))
                    .replace(/\{pointsPending\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_PENDING']))
                    .replace(/\{pointsLost\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_LOST']))
                    .replace(/\{pointsMax\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_MAX']))
                    .replace(/\{score\}/g, i18n.t(['ui.resultscreens.default.ITEM_SCORE']))
                    ;
            });
            this.objectiveOverviewHeader(parse.headerFields);
            this.objectiveBodyFields(parse.bodyTemplateFields);
        } else {
            this.objectiveHeader(undefined);
            const bodyFields = [];
            if (this.displayObjectiveId()) {
                bodyFields.push(new ObjectiveOverviewBodyFieldTemplate('{id}', 'ID'));
            }
            bodyFields.push(new ObjectiveOverviewBodyFieldTemplate(objectiveText, 'Description'));
            bodyFields.push(new ObjectiveOverviewBodyFieldTemplate('{score}', 'Score'));
            this.objectiveBodyFields(bodyFields);
        }
    }
    public async initialize() {
        this.registerGremlin({
            name: WIDGET_NAME,
            priority: 1000,
            action: () => this.gremlins()
        });
        await super.initialize();
        await this.refresh();

        this.onChange(this.email, `${WIDGET_NAME}/email`, async val => {
            await ServerConnection.api.ui_resultscreens_setnotificationemail({
                sessionId: this.params.sessionId,
                email: val
            });
            return HELPER.DONE;
        });

        this.onChange(this.emailEnabled, `${WIDGET_NAME}/emailEnabled`, async val => {
            await ServerConnection.api.ui_resultscreens_setnotificationemail({
                sessionId: this.params.sessionId,
                confirmed: val
            });
            return HELPER.DONE;
        });

        const skipResultPage = query.params.get('skipResultPage') || 'no';
        if (skipResultPage === 'yes') {
            await this.actionClose.invoke();
        }

        this.loaded(true);
    }
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxForm from 'devextreme/ui/form';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, datagrid } from '../../../dx_helper';
import { DONE } from '../../../helper';
import { formatMessage } from '../../../i18n/data';
import * as API from '../../../its-itembank-api.g';
import * as Modal from '../../../modal';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { legacyPushPull } from '../../../ui/docmanager';
import { ServerConnection } from '../../../ui/RestAPI';
import { UIAction } from '../../../ui/uiAction';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import { AddMissingAttachments } from '../../helper';
import { buildPropertyForm } from '../../kosovo/handson/edit/formbuilder';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';


let initOnce: Promise<void>;

function initInvestigators() {
    if (initOnce) {
        return initOnce;
    }
    const fn = async () => {
        const investigators = await ServerConnection.api.inapplication_combo_edit_investigators(
            {
            });
        allEnrichmentServiceProviders(investigators.enrichment.status.serviceProviders);
    };
    initOnce = fn();
    return initOnce;
}

const WIDGET_NAME = 'itemdefinition-inapplicationcombo-edit';
interface IPlainTreeEntry {
    id: string;
    parentId?: string;
    text: string;
    uri?: string;
    name?: string;
    description?: string;
    serviceProviderId: string;
    rootGroupId: string;
    partialId: string;
}
export type IParams = IItemDefinitionWidgetParams;
type QUERY_INV = Awaited<ReturnType<API.Sdk['inapplication_combo_edit_investigators']>>;
type Q = Awaited<ReturnType<API.Sdk['inaplicationcombo_edit_data']>>;
const allEnrichmentServiceProviders = ko.observable<QUERY_INV['enrichment']['status']['serviceProviders']>();
type PREPERATOR = Q['inApplicationComboEdit']['get']['preparators'][0];
type FILE = Q['inApplicationComboEdit']['get']['filestructure'][0];
type ALL_FILE = Q['item']['getFileStructure'][0];
type ATTACHMENT = Q['documents']['get']['attachments'][0];
type PROPERTY = PREPERATOR['properties'][0];
export class ViewModel extends DxWidget {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly loaded = ko.observable(false);

    private readonly isReadOnly: boolean;
    private readonly canEdit: boolean;
    private readonly _preparators: PREPERATOR[] = [];
    private readonly _files: FILE[] = [];
    private readonly _allFiles: ALL_FILE[] = [];
    private readonly _attachments: ATTACHMENT[] = [];
    public readonly newPreparatorId = ko.observable('');
    public readonly newPreparatorPopupVisible = ko.observable(false);

    public readonly newPreparatorPopup = ko.pureComputed(() => {
        if (!this.loaded()) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxPopup.Properties = {
            title: i18n.t(['itemdefinition.inapplicationcombo.edit.ADD_PREPARATOR']),
            visible: <any>this.newPreparatorPopupVisible,
            toolbarItems: [],
            closeOnOutsideClick: true,
        };
        retVal.toolbarItems.push({
            widget: 'dxButton',
            location: 'after',
            options: {
                text: 'Ok',
                onClick: this.addPreparator.click
            }
        });
        return retVal;
    });
    public readonly newPreparatorTreeListOptions = ko.pureComputed(() => {
        if (!this.loaded()) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxTreeList.Properties = {
            dataSource: this.preparatorDataSource(),
            dataStructure: 'plain',
            autoExpandAll: false,
            height: '100%',
            allowColumnResizing: true,
            columnAutoWidth: true,
            columnHidingEnabled: true,
            filterRow: {
                visible: true
            },
            headerFilter: {
                visible: true,
            },
            sorting: {
                mode: 'single'
            },
            selection: {
                mode: 'single',
                allowSelectAll: false,
            },
            columns: [],
            onSelectionChanged: (e) => {
                if (!e.selectedRowsData || !e.selectedRowsData.length) {
                    return;
                }
                const data: IPlainTreeEntry = e.selectedRowsData[0];
                if (data.uri) {
                    this.newPreparatorId(e.selectedRowKeys[0]);
                } else {
                    e.component.deselectAll();
                }

            }
        };
        retVal.columns.push({
            dataField: 'rootGroupId',
            caption: i18n.t(['itemdefinition.inapplicationcombo.edit.CATEGORY']),
            sortIndex: 0,
            sortOrder: 'asc',
        });
        retVal.columns.push({
            dataField: 'partialId',
            caption: i18n.t(['itemdefinition.inapplicationcombo.edit.PREPARATOR'])
        });
        retVal.columns.push({
            dataField: 'description',
            caption: i18n.t(['itemdefinition.inapplicationcombo.edit.DESCRIPTION'])
        });
        return retVal;
    });
    preparePreparatorToolbar(e: { model?: ViewModel, toolbarOptions?: DevExpress.ui.dxToolbar.Properties }) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',

                options: {
                    icon: 'add',
                    disabled: this.isReadOnly,
                    onClick: () => {
                        this.newPreparatorPopupVisible(true);
                        //this.addPreparator.click
                    }
                }
            }
        );
    }
    public readonly addPreparator = new UIAction(undefined, async () => {
        this.newPreparatorPopupVisible(false);
        const newPrepId = this.newPreparatorId();
        if (!newPrepId) {
            return;
        }
        const [provider, uri] = newPrepId.split('|');

        const sp = allEnrichmentServiceProviders().find(x => x.id === provider);
        if (!sp) {
            throw new Error(`How?`);
        }
        const eSrc = sp.preparatorDescriptions.find(x => x.id === uri);
        if (!eSrc) {
            throw new Error(`How?`);
        }

        const currentKeys = new Set<string>(this._preparators.map(x => x.preparatorId));
        let key: string;
        for (let i = 1; ; ++i) {
            key = `PREP${i}`;
            if (!currentKeys.has(key)) {
                break;
            }
        }
        await legacyPushPull(() => ServerConnection.api.inapplicatiocombo_edit_update({
            params: {
                itemId: this.itemId,
                upsertPreparators: [{
                    preparatorId: key,
                    preparatorUri: uri,
                    serviceProviderId: provider
                }]
            }
        }));
    });
    public readonly preparatorDataSource = ko.pureComputed(() => {
        const all = allEnrichmentServiceProviders();
        const ds: IPlainTreeEntry[] = [];
        const branchIds = new Set<string>();

        for (const sp of all) {
            for (const prep of sp.preparatorDescriptions) {
                const parts = prep.id.split('.');
                const ids = [];
                for (let l = 1; l <= parts.length; ++l) {
                    ids.push(sp.id + '|' + parts.slice(0, l).join('.'));
                }
                const parentIds = [undefined, ...ids];
                for (let l = 0; l < parts.length - 1; ++l) {
                    if (branchIds.has(ids[l])) {
                        continue;
                    }
                    branchIds.add(ids[l]);
                    ds.push({
                        id: ids[l],
                        parentId: parentIds[l],
                        rootGroupId: parts[0],
                        serviceProviderId: sp.id,
                        text: parts[l],
                        partialId: '',
                    });
                }
                ds.push({
                    id: ids[ids.length - 1],
                    text: parts[parts.length - 1],
                    rootGroupId: parts[0],
                    serviceProviderId: sp.id,
                    parentId: parentIds[ids.length - 1],
                    name: prep.name.value,
                    description: prep.description.value,
                    uri: prep.id,
                    partialId: parts.slice(1).join('.'),
                });
            }
        }
        return ds;
    });
    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.inaplicationcombo_edit_data({
            itemId: this.params.itemId,
        });
        const d = data.inApplicationComboEdit.get;
        this.data.handson = d;
        this._allFiles.splice(0, this._allFiles.length, ...data.item.getFileStructure);
        this._files.splice(0, this._files.length, ...d.filestructure);
        this._preparators.splice(0, this._preparators.length, ...d.preparators);
        this._attachments.splice(0, this._attachments.length, ...data.documents.get.attachments);

        AddMissingAttachments(this._attachments, [
            ...this._files.map(x => x.resourceName),
        ]);
        this.instruction(d.instruction.value);
        this.header(d.header.value);

        await this.refreshGrid();
        if (this.form) {
            this.form.repaint();
        }
    }

    private readonly preparatorGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    private readonly filesGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    public getPreparatorDetailOptions(prepData: PREPERATOR): DevExpress.ui.dxForm.Properties {
        const props: any = {};

        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: props,
            readOnly: this.isReadOnly,
            items: []
        };
        const collapse = async () => {
            const grid = this.preparatorGrid();
            if (!grid) {
                return;
            }
            const key = prepData.preparatorId;
            await grid.collapseRow(key);
        };

        this.buildForm(retVal, prepData.properties, async () => {
            await legacyPushPull(() => ServerConnection.api.inapplicatiocombo_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertPreparators: [{
                        preparatorId: prepData.preparatorId,
                        preparatorUri: prepData.preparatorUri,
                        serviceProviderId: prepData.serviceProviderId,
                        upsertProperties: this.toUpsertProperty(props),
                    }]
                }

            }));
            await collapse();
        }, collapse);
        return retVal;
    }
    private encodeValue(value: any): string {
        if (typeof value === 'undefined') {
            return '';
        }
        if (typeof value === 'object') {
            if (value === null) {
                return '';
            }
        }
        if (typeof value === 'boolean') {
            return value.toString();
        }
        if (typeof value === 'number') {
            return value.toString();
        }
        if (typeof value === 'string') {
            return value;
        }

        throw new Error(`Unable to encode value ${value} of type ${typeof value}`);
    }
    private toUpsertProperty(formData: any) {
        return Object.keys(formData).map(propertyId => {
            const value = this.encodeValue(formData[propertyId]);
            return {
                propertyId, value
            };
        });
    }

    private buildForm(options: DevExpress.ui.dxForm.Properties, properties: PROPERTY[], onSave: () => void, onCancel: () => void) {
        buildPropertyForm(options, properties, onSave, onCancel, this._allFiles);
    }
    private async refreshGrid() {
        const grids = [this.filesGrid(), this.preparatorGrid()];
        for (const grid of grids) {
            if (grid) {
                //tslint:disable-next-line:await-promise
                await grid.refresh();
            }
        }
    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.inapplicationcombo.edit.ENTER_THE_TITLE_HERE_OPTIONAL']);
    });
    public readonly instruction = ko.observable('');
    public readonly header = ko.observable('');

    public readonly addFile = new UIAction(undefined, async () => {
        const currentKeys = new Set<string>(this._files.map(x => x.fileId));
        let key: string;
        for (let i = 1; ; ++i) {
            key = `F${i}`;
            if (!currentKeys.has(key)) {
                break;
            }
        }
        await legacyPushPull(() => ServerConnection.api.inapplicatiocombo_edit_update({
            params: {
                itemId: this.itemId,
                upsertFiles: [{
                    fileId: key,
                    userCreated: false,
                    path: ''
                }]
            }
        }));
    });
    prepareFilesToolbar(e: { model?: ViewModel, toolbarOptions?: DevExpress.ui.dxToolbar.Properties }) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    hint: 'my hint',
                    disabled: this.isReadOnly,
                    icon: 'add',
                    onClick: this.addFile.click
                }
            }
        );
    }

    public readonly fileGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.filesGrid,
            config: {
                noDataText: i18n.t(['itemdefinition.inapplicationcombo.edit.THERE_ARE_NO_FILES_YET']),
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'fileId',
                        data: this._files
                    }
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
                onToolbarPreparing: (e) => this.prepareFilesToolbar(e),
                columns: []
            }
        });
        retVal.columns.push(
            {
                dataField: 'fileId',
                caption: i18n.t(['itemdefinition.inapplicationcombo.edit.ID']),
                width: 100,
                allowEditing: false
            });

        retVal.columns.push(
            {
                dataField: 'path',
                caption: i18n.t(['itemdefinition.inapplicationcombo.edit.PATH']),
                editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                    hint: i18n.t(['itemdefinition.inapplicationcombo.edit.PATH_IN_FORM_OF_H_FOLDER_SUBFOLDER_FILE_TXT']),
                    placeholder: 'H:/file.jpg'
                }),

                validationRules: [{
                    type: 'pattern',
                    pattern: /^[Hh][:][/][-_a-zA-Z0-9./]+/,
                    message: i18n.t(['itemdefinition.inapplicationcombo.edit.PATH_MUST_START_WITH_H_AND_ONLY_CONTAIN_LETTERS_AND_NUMBERS'])
                }],
                allowEditing: this.canEdit,
            });
        retVal.columns.push(
            {
                dataField: 'resourceName',
                caption: i18n.t(['itemdefinition.inapplicationcombo.edit.ASSET_NAME']),
                allowEditing: this.canEdit,
                lookup: {
                    dataSource: this.attachmentsStore,
                    valueExpr: 'name',
                    displayExpr: 'name'
                },
            });
        retVal.columns.push(
            {
                dataField: 'userCreated',
                width: 100,
                caption: i18n.t(['itemdefinition.inapplicationcombo.edit.USER_CREATED']),
                allowEditing: this.canEdit
            });
        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatiocombo_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeFiles: [e.key]
                    }
                }));
            })();
        };

        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatiocombo_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertFiles: [{
                            fileId: e.key,
                            resourceName: e.newData.resourceName,
                            path: e.newData.path,
                            userCreated: e.newData.userCreated
                        }]
                    }
                }));
            })();
        };
        return retVal;
    });

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.itemDocId = 'item,' + this.itemId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public async initialize() {
        this.attachmentsStore = new CustomStore({
            loadMode: 'raw',
            key: 'name',
            byKey: async key => {
                if (!this._attachments) {
                    return undefined;
                }
                return this._attachments.find(x => x.name === key);
            },
            load: async (options) => {
                return this._attachments;
            }
        });
        await super.initialize();
        await initInvestigators();
        await this.OnRefresh();

        this.onChange(this.instruction, `${WIDGET_NAME}/${this.itemId}/instruction`, async val => {
            await ServerConnection.api.inapplicatiocombo_edit_update({
                params: {
                    itemId: this.itemId,
                    instruction: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.inapplicatiocombo_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val),
                }
            });
            return DONE;
        });



        this.loaded(true);
    }

    private readonly orig: {
        handson?: Q['inApplicationComboEdit']['get'],
    } = {};
    private readonly data: {
        handson?: Q['inApplicationComboEdit']['get'],
    } = {};

    private form: DevExpress.ui.dxForm;

    private attachmentsStore: DevExpress.data.CustomStore;

    public readonly sections = ko.pureComputed(() => {
        const dataSource: DevExpress.ui.dxAccordionItemTemplate[] = [];
        const retVal: DevExpress.ui.dxAccordion.Properties = {
            collapsible: true,
            multiple: true,
            dataSource
        };
        dataSource.push({
            title: i18n.t(['itemdefinition.inapplicationcombo.edit.FILE_STRUCTURE']),
            template: () => {
                const retVal: any = $('<div />');
                new dxDataGrid(retVal, this.fileGridOptions());
                return retVal;
            }
        });
        dataSource.push(
            {
                title: i18n.t(['itemdefinition.inapplicationcombo.edit.PREPARATORS']),
                template: () => {
                    const retVal: any = $('<div />');
                    const x = new dxDataGrid(retVal, this.preparatorGridOptions());
                    return retVal;
                },
            }
        );
        return retVal;
    });

    public readonly preparatorGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            discriminator: 'preparators',
            widget: this,
            gridVar: this.preparatorGrid,
            config: {
                noDataText: i18n.t(['itemdefinition.inapplicationcombo.edit.THERE_ARE_NO_PREPARATORS_YET']),
                allowColumnResizing: true,
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'preparatorId',
                        data: this._preparators
                    }
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: this.canEdit,
                    allowUpdating: false,
                    mode: 'popup',
                },
                onToolbarPreparing: (e: any) => this.preparePreparatorToolbar(e),
            }
        });
        retVal.columns.push(
            {
                dataField: 'preparatorId',
                caption: i18n.t(['itemdefinition.inapplicationcombo.edit.ID']),
                width: 100,
            });
        retVal.columns.push(
            {
                dataField: 'preparatorUri',
                allowEditing: false,
                caption: i18n.t(['itemdefinition.inapplicationcombo.edit.PREPARATOR']),
                calculateDisplayValue: (e: PREPERATOR) => {
                    const sp = allEnrichmentServiceProviders().find(x => x.id === e.serviceProviderId);
                    if (!sp) {
                        return e.preparatorUri;
                    }
                    const i = sp.preparatorDescriptions.find(x => x.id === e.preparatorUri);
                    if (!i) {
                        return e.preparatorUri;
                    }
                    return `${i.name.value} (${i.id})`;
                }
            });
        retVal.onRowRemoving = (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatiocombo_edit_update({
                    params: {
                        itemId: this.itemId,
                        removePreparators: [e.key]
                    }
                }));
            })();
        };
        retVal.masterDetail = {
            enabled: true,
            template: (elem, info) => {
                const retVal: any = $('<div />');
                new dxForm(retVal, this.getPreparatorDetailOptions(info.data));
                return retVal;
            }
        };

        return retVal;
    });
    public async confirmSwitch(targetTool: string) {
        const message = formatMessage(i18n.t(['itemdefinition.inapplicationcombo.edit.ASSET_IS_CHANGED_TO_TARGETTOOL_ARE_YOU_SURE']), { targetTool });

        return await Modal.confirmYesNo(message);
    }



    public readonly imageDragLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.inapplicationcombo.edit.DRAG_IMAGE_HERE']);
    });
    public readonly imageFileSizeWarning = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.inapplicationcombo.edit.MAXIMUM_IMAGE_FILE_SIZE_1MB']);
    });

    public readonly videoDragLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.inapplicationcombo.edit.DRAG_VIDEO_FILE_HERE']);
    });
    public readonly videoFileSizeWarning = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.inapplicationcombo.edit.MAXIMUM_VIDEO_FILE_SIZE_5MB']);
    });

    public readonly audioDragLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.inapplicationcombo.edit.DRAG_AUDIO_FILE_HERE']);
    });
    public readonly audioFileSizeWarning = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.inapplicationcombo.edit.MAXIMUM_AUDIO_FILE_SIZE_3MB']);
    });

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: {
                header: this.header,
                isReadOnly: this.isReadOnly,
                instruction: this.instruction,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.inapplicationcombo.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.inapplicationcombo.edit.ENTER_THE_QUESTION_TEXT_HERE']),
                readOnly: this.isReadOnly,
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.inapplicationcombo.edit.INSTRUCTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'instruction',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.inapplicationcombo.edit.ENTER_THE_QUESTION_INSTRUCTION_TEXT_HERE'])
        }));
        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import { assertNever } from '../../helper';
import * as API from '../../its-itembank-api.g';
import { ChoiceTextData } from './choicetext/ChoiceTextData';
import { CrossoutData } from './crossout/CrossoutData';
import { EssayData } from './essay/EssayData';
import { IModelFactory } from './factory';
import { FillInGapData } from './fillingap/FillInGapData';
import { FillInPoolData } from './fillinpool/FillInPoolData';
import { HandsOnData } from './handson/HandsOnData';
import { InAppComboData } from './inapplicationcombo/InAppComboData';
//import * as INIT from './init.query.graphql.g';
import { InstructionData } from './instruction/InstructionData';
import { InvisibleHotspotData } from './invisiblehotspot/InvisibleHotspotData';
import { ItemComboData } from './itemcombo/ItemComboData';
import { ItemDataModel } from './ItemDataModel';
import { ItemModel } from './ItemModel';
import { MatchingHotspotData } from './matchinghotspot/MatchingHotspotData';
import { MatchingImageData } from './matchingimage/MatchingImageData';
import { MatchingTextData } from './matchingtext/MatchingTextData';
import { MultipleChoiceTextData } from './multiplechoicetext/MultipleChoiceTextData';
import { MultistepHotspotData } from './multistephotspot/MultistepHotspotData';
import { OrderingSelectionData } from './orderingselection/OrderingSelectionData';
import { OrderingSentencesData } from './orderingsentences/OrderingSentencesData';
import { OrderingWordsData } from './orderingwords/OrderingWordsData';
import { OrganisationData } from './organisation/OrganisationData';
import { PlaceHolderData } from './placeholder/PlaceHolderData';
import { SingleChoiceHotSpotData } from './singlechoicehotspot/SingleChoiceImageData';
import { SingleChoiceImageData } from './singlechoiceimage/SingleChoiceImageData';
import { SingleChoiceMatrixData } from './singlechoicematrix/SingleChoiceMatrixData';
import { SingleChoiceTextData } from './singlechoicetext/SingleChoiceTextData';
import { SpellingMatrixData } from './spellingmatrix/SpellingMatrixData';
import { SurveyComboData } from './surveycombo/SurveyComboData';
import { SurveyComparisionData } from './surveycomparision/SurveyComparisionData';
import { SurveySingleChoiceMatrixData } from './surveysinglechoicematrix/SurveySingleChoiceMatrixData';
import { SurveySingleChoiceTextData } from './surveysinglechoicetext/SurveySingleChoiceTextData';
import { SurveyTextEntryData } from './surveytextentry/SurveyTextEntryData';
import { SurveyTextInputData } from './surveytextinput/SurveyTextInputData';
import { TextEntryPoolData } from './textentrypool/TextEntryPoolData';
import { TextEntryAutoData } from './textentry_auto/TextEntryAutoData';
import { TextEntryManualData } from './textentry_manual/TextEntryManualData';
import { TypingTestData } from './typingtest/TypingTestData';
import { TypingTest2023Data } from './typingtest2023/TypingTestData';

type INIT = Awaited<ReturnType<API.Sdk['item_model_init']>>;
type ITEM = INIT['examSession']['items'][0];

export class ModelFactory implements IModelFactory {
    create(model: ItemModel, rawData: ITEM): ItemDataModel {
        if (!rawData) {
            return undefined;
        }
        if (!rawData.data) {
            return undefined;
        }
        const typeName = rawData.data.__typename;
        switch (rawData.data.__typename) {
            case 'KosovoSingleChoiceText_QueryItemSessionData':
                return new SingleChoiceTextData(model, rawData.data);
            case 'InApplicationCombo_QueryItemSessionData':
                return new InAppComboData(model, rawData.data);
            case 'KosovoChoiceText_QueryItemSessionData':
                return new ChoiceTextData(model, rawData.data);
            case 'HandsOn_QueryItemSessionData':
                return new HandsOnData(model, rawData.data);
            case 'KosovoMatchingHotSpot_QueryItemSessionData':
                return new MatchingHotspotData(model, rawData.data);
            case 'KosovoMatchingImage_QueryItemSessionData':
                return new MatchingImageData(model, rawData.data);
            case 'KosovoTextEntry_Auto_QueryItemSessionData':
                return new TextEntryAutoData(model, rawData.data);
            case 'KosovoFillinGap_QueryItemSessionData':
                return new FillInGapData(model, rawData.data);
            case 'KosovoSingleChoiceImage_QueryItemSessionData':
                return new SingleChoiceImageData(model, rawData.data);
            case 'KosovoSingleChoiceMatrix_QueryItemSessionData':
                return new SingleChoiceMatrixData(model, rawData.data);
            case 'KosovoOrderingWords_QueryItemSessionData':
                return new OrderingWordsData(model, rawData.data);
            case 'KosovoOrderingSentences_QueryItemSessionData':
                return new OrderingSentencesData(model, rawData.data);
            case 'KosovoOrderingSelection_QueryItemSessionData':
                return new OrderingSelectionData(model, rawData.data);
            case 'KosovoEssay_QueryItemSessionData':
                return new EssayData(model, rawData.data);
            case 'TypingTest_QueryItemSessionData':
                return new TypingTestData(model, rawData.data);
            case 'TypingTest2023_QueryItemSessionData':
                return new TypingTest2023Data(model, rawData.data);
            case 'SurveyTextInput_QueryItemSessionData':
                return new SurveyTextInputData(model, rawData.data);
            case 'LavakLineTextEntry_QueryItemSessionData':
                return new TextEntryPoolData(model, rawData.data);
            case 'Placeholder_QueryItemSessionData':
                return new PlaceHolderData(model, rawData.data);
            case 'KosovoTextEntry_Manual_QueryItemSessionData':
                return new TextEntryManualData(model, rawData.data);
            case 'SurveySingleChoiceText_QueryItemSessionData':
                return new SurveySingleChoiceTextData(model, rawData.data);
            case 'KosovoSingleChoiceHotSpot_QueryItemSessionData':
                return new SingleChoiceHotSpotData(model, rawData.data);
            case 'KosovoMatchingText_QueryItemSessionData':
                return new MatchingTextData(model, rawData.data);
            case 'KosovoContainer_QueryItemSessionData':
                return new ItemComboData(model, rawData.data);
            case 'LavakFillinPool_QueryItemSessionData':
                return new FillInPoolData(model, rawData.data);
            case 'SurveySingleChoiceMatrix_QueryItemSessionData':
                return new SurveySingleChoiceMatrixData(model, rawData.data);
            case 'LavakMultiplechoice_QueryItemSessionData':
                return new MultipleChoiceTextData(model, rawData.data);
            case 'KosovoCrossout_QueryItemSessionData':
                return new CrossoutData(model, rawData.data);
            case 'InvisibleHotSpot_QueryItemSessionData':
                return new InvisibleHotspotData(model, rawData.data);
            case 'SurveyTextEntry_QueryItemSessionData':
                return new SurveyTextEntryData(model, rawData.data);
            case 'SurveyComparision_QueryItemSessionData':
                return new SurveyComparisionData(model, rawData.data);
            case 'SurveyCombo_QueryItemSessionData':
                return new SurveyComboData(model, rawData.data);
            case 'SpellingMatrix_QueryItemSessionData':
                return new SpellingMatrixData(model, rawData.data);
            case 'Organisation_QueryItemSessionData':
                return new OrganisationData(model, rawData.data);
            case 'MultistepHotSpot_QueryItemSessionData':
                return new MultistepHotspotData(model, rawData.data);
            case 'Instruction_QueryItemSessionData':
                return new InstructionData(model, rawData.data);
            default:
                assertNever(rawData.data);
                throw new Error(`Unsupported ItemType ${typeName}`);
        }
    }
}

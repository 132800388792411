import type DevExpress from 'devextreme/bundles/dx.all';
import $ from 'jquery';
import * as ko from 'knockout';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { selectBoxDS } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { ItemEditVisibility, IUpsertSubjectInput } from '../../../its-itembank-api.g';
import * as DS from './../../../enums/datasource';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';

export type Q = Awaited<ReturnType<API.Sdk['ui_superuser_subjectedit_data']>>;
export type SUBJECT = Q['subjectById'];
export type DATA = SUBJECT & { colorRGBA: string; alternateColorRGBA: string };
export type U = Omit<Omit<Omit<IUpsertSubjectInput, "id">, "docRef">, "subjectId"> | { subjectId?: string };

export class MyFormBuilder extends FormBuilder<DATA, U> {
    constructor(readonly vm: {
        attachmentsStore: DevExpress.data.CustomStore;
        languagesStore: DevExpress.data.CustomStore;
        imgUrl: ko.Observable<string>;
    }) {
        super();
    }

    protected onInit() {
        super.onInit();
        this.formOptions.colCount = 1;
        this.initSubjectData();
        this.initSystem();
    }
    private initSubjectData() {
        const g = this.addGroup(this.formOptions, {
            colCount: 2,
            caption: i18next.t(['ui.superuser.subjectedit.SUBJECT_DATA']),
        });

        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.superuser.subjectedit.NAME_OF_SUBJECT'])
            },
            dataField: 'title',
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.subjectedit.ENTER_THE_NAME_HERE']),
            }

        }, val => ({ title: val }));

        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.superuser.subjectedit.SHORTCUT'])
            },
            dataField: 'subjectId',
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.subjectedit.ENTER_THE_SHORTCUT_HERE']),
            },
            validationRules: [
                {
                    type: 'required',
                    message: i18next.t(['ui.superuser.subjectedit.A_SHORTCUT_IS_REQUIRED'])
                },
                {
                    type: 'pattern',
                    pattern: '^[A-Z,0-9]+$',
                    message: i18next.t(['ui.superuser.subjectedit.THE_SHORTCUT_CAN_ONLY_CONSIST_OF_UPPERCASE_LETTERS_AND_DIGITS'])
                }
            ]
        }, val => ({ subjectId: val }));

        this.addSelectBox(g, {
            dataField: 'defaultLocalization.id',
            editorOptions: {
                dataSource: {
                    store: this.vm.languagesStore
                },
                displayExpr: 'name.value',
                valueExpr: 'id',
                showClearButton: true,
            },
            label: {
                text: i18next.t(['ui.superuser.subjectedit.LANGUAGE_FOR_ITEM_CREATION'])
            }
        }, val => ({ defaultLocalization: val }));

        this.addCheckbox(g, {
            dataField: 'allowNonTranslateable',
            editorOptions: {},
            label: {
                text: i18next.t(['ui.superuser.subjectedit.ALLOW_NON_TRANSLATEABLE_ITEM_TYPES'])
            }
        }, val => ({ allowNonTranslateable: val }));

        const attachmentOptions: DevExpress.ui.dxSelectBox.Properties = {
            dataSource: {
                store: this.vm.attachmentsStore,
            },
            valueExpr: 'name',
            displayExpr: 'name',
            showClearButton: true,
        };

        this.addSelectBox(g, {
            label: {
                text: i18next.t(['ui.superuser.subjectedit.IMAGE'])
            },
            dataField: 'image.name',
            editorOptions: attachmentOptions,
        }, val => ({ image: val }));

        this.addSimpleItem(g, {
            template: (e) => {
                const x = $(`
                <div data-bind="if:vm.imgUrl">
                    <img style="max-width: 360px; max-height: 360px; " class="img-rounded  thumb" data-bind="attr: { src: vm.imgUrl }" />
                </div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            },
            label: {
                text: i18next.t(['ui.superuser.subjectedit.IMAGE'])
            }
        });
        this.addColorBox(g, {
            dataField: 'colorRGBA',
            label: {
                text: i18next.t(['ui.superuser.subjectedit.COLOR'])
            },
            editorOptions: {
                showClearButton: true,
                editAlphaChannel: false,
            }
        },
            ['#b57bcc', '#7BA170', '#b65d6f', '#B4B4B4', '#8fa8fc', '#FD8204', '#8FC440', '#00A0AF'
            ], val => {
                return ({ color: val || '' });
            });
        this.addColorBox(g, {
            dataField: 'alternateColorRGBA',
            label: {
                text: i18n.t(['ui.superuser.subjectedit.myformbuilder.ALTERNATECOLOR'])
            },
            editorOptions: {
                showClearButton: true,
                editAlphaChannel: false,
            }
        },
            ['#b57bcc', '#7BA170', '#b65d6f', '#B4B4B4', '#8fa8fc', '#FD8204', '#8FC440', '#00A0AF'
            ], val => {
                return ({ alternateColor: val || '' });
            });

        this.addSelectBox<ItemEditVisibility>(g, {
            label: {
                text: i18next.t(['ui.superuser.subjectedit.myformbuilder.ITEM_VISIBILITY'])
            },
            dataField: 'itemEditVisibility',
            editorOptions: selectBoxDS(DS.ItemEditVisibility())
        }, val => ({ itemEditVisibility: val }));

    }
    private initSystem() {
        const g2 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.subjectedit.SYSTEM']),
            colCount: 2
        });

        this.addCheckbox(g2, {
            label: {
                text: i18next.t(['ui.superuser.subjectedit.DEACTIVATE_SUBJECT'])
            },
            dataField: 'isDeleted',
        }, val => ({ isDeleted: val }));

        this.addTextBox(g2, {
            label: {
                text: i18next.t(['ui.superuser.subjectedit.INTERNAL_ID'])
            },
            dataField: '_id',
            editorOptions: {
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
            }
        });
    }
}

import { AbstractWidget, widgets } from '../AbstractWidget';
import { DONE } from '../helper';

export function CallOnItemWidgets<T>(cb: (items: AbstractItemDefinition[]) => T) {
    const items: AbstractItemDefinition[] = [];
    for (const w of Array.from(widgets)) {
        if (w instanceof AbstractItemDefinition) {
            items.push(w);
        }
    }
    return cb(items);
}
export class AbstractItemDefinition extends AbstractWidget {
    public async OnReset() {
        return DONE;
    }
}

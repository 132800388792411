import * as ko from 'knockout';
import { dir, log } from '../../debug';
import { getGolemData, isStringMap } from '../../Gremlin';
import * as i18next from '../../i18n/i18n';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { GetSession } from '../model/session';
import { SurveyTextEntryData, SurveyTextEntryData_Gap, SurveyTextEntryData_Text } from '../model/surveytextentry/SurveyTextEntryData';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveytextentry';

const GREMLIN_TEXTENTRY = 'xxx';

function isGap(element: IGapOrText): element is Gap {
    if ((<Gap>element).isGap()) {
        return true;
    }
    return false;
}

class Text implements IGapOrText {
    constructor(public readonly vm: MyModel, public readonly data: SurveyTextEntryData_Text) {
        this.text = data.text;
        this.html = data.html;
    }
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }
    public readonly text: string;
    public readonly html: string;
}

interface IGapOrText {
    readonly isText: () => this is Text;
    readonly isGap: () => this is Gap;
}

class Gap implements IGapOrText {
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }
    //public readonly gap: Q.NS.Gaps;
    public readonly gapId: string;
    public readonly value: ko.Observable<string>;
    constructor(readonly model: MyModel, readonly data: SurveyTextEntryData_Gap) {
        this.gapId = data.gapId;
        //this.gap = model.itemData().gaps.find(gap => gap.id === gapId);
        this.value = this.data.givenAnswer;
    }

    public readonly isGapEmpty = ko.pureComputed(() => {
        const val = this.value();
        if ((val === '') || (!val)) {
            return true;
        }
        return false;
    });
}

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SurveyTextEntryData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: SurveyTextEntryData;

    private async gremlin() {
        if (!this.loaded()) {
            return true;
        }
        const frags = this.fragments();
        for (const f of frags) {
            if (f instanceof Gap) {
                if (!f.value()) {
                    const golemData = getGolemData(this.itemId);
                    let val = GREMLIN_TEXTENTRY;
                    dir({
                        golemData,
                        isStringMap: isStringMap(golemData),
                        f
                    });
                    if (isStringMap(golemData)) {
                        const x = golemData[f.data.gap.gapId];
                        if (typeof x === 'string') {
                            val = x;
                        }
                    }
                    f.value(val);
                    return true;
                }
            }
        }
        return false;
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlin()
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly fragments = ko.pureComputed(() => {
        const retVal: Array<Text | Gap> = this.data.fragments.map(x => {
            if (x instanceof SurveyTextEntryData_Text) {
                return new Text(this, x);
            } else if (x instanceof SurveyTextEntryData_Gap) {
                return new Gap(this, x);
            } else {
                throw new Error();
            }
        });

        return retVal;
    });

    public readonly hasImage = ko.pureComputed(() => this.data.hasImage);
    public readonly imgUrl = ko.pureComputed(() => this.data.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.data.imgAlt);

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly gapPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_auto.ANSWER']);
    });

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_auto.ENTER_THE_TEXT_HERE']);
    });

    public readonly enterAnswerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveytextinput.ENTER_THE_TEXT_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

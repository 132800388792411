import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'LavakMultiplechoice_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type ANSWER = DATA['mcAnswers'][0];

export class MultipleChoiceTextData_Answer {
    constructor(private readonly model: MultipleChoiceTextData, public readonly rawData: ANSWER) {
        this.id = rawData.id;
        this.value = RICHTEXTHTML.process({
            html: translate(this.rawData.text, {}),
            attachments: this.model.meta.attachments,
        });
        this.evaluationRemark = rawData.evaluationRemark;
        this.isCorrectAnswer = rawData.isCorrectAnswer;
    }
    public readonly id: string;
    public readonly value: string;
    public readonly isSelected = ko.observable(false);
    public readonly isCorrectAnswer: boolean;
    public readonly evaluationRemark: string;
}

export class MultipleChoiceTextData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.answers = data.mcAnswers.map(a => new MultipleChoiceTextData_Answer(this, a));
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.minSelectionLimit = data.minSelectionLimit;
        this.maxSelectionLimit = data.maxSelectionLimit;
        this.evaluationRemark = data.evaluationRemark;
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'LavakMultiplechoice_QueryItemSessionData') {
            throw new Error();
        }
        for (const d of data.mcAnswers) {
            const a = this.answers.find(x => x.id === d.id);
            a.isSelected(d.isSelected);
        }
    }
    public readonly questionHTML: string;
    public readonly answers: MultipleChoiceTextData_Answer[];
    public readonly headerText: string;
    public readonly minSelectionLimit: number;
    public readonly maxSelectionLimit: number;
    public readonly evaluationRemark: string;
    public async switchAnswer(answerId: string) {

        const other = this.answers.filter(x => x.id !== answerId);
        const otherSelected = other.filter(x => x.isSelected());
        const thisOption = this.answers.find(x => x.id === answerId);
        const newAnswerCount = otherSelected.length + (thisOption.isSelected() ? 0 : 1);
        if (newAnswerCount > this.maxSelectionLimit) {

        }

        thisOption.isSelected(!thisOption.isSelected());
    }

    public IsInteractionStarted() {
        return this.answers.some(x => x.isSelected());
    }
    public IsInteractionComplete() {
        const count = this.answers.filter(x => x.isSelected()).length;
        return ((count >= this.minSelectionLimit) && (count <= this.maxSelectionLimit));
    }
    public async reset() {
        for (const answer of this.answers) {
            answer.isSelected(false);
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            MultipleChoice: {
                selected: this.answers.filter(x => x.isSelected()).map(x => x.id),
            }
        };
        return retVal;
    }

}

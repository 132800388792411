import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import * as Modal from '../../../modal';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import * as TOKENIZER from '../../../tokenizer';
import { legacyPushPull } from '../../../ui/docmanager';
import { ServerConnection } from '../../../ui/RestAPI';
import { UIAction } from '../../../ui/uiAction';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
//import * as ADD_GAP from './addgap.mutation.graphql.g';
//import * as Q from './data.query.graphql.g';
//import * as REMOVE_GAP from './removegap.mutation.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveytextentry-edit';
type Q = Awaited<ReturnType<API.Sdk['surveytextentry_edit_data']>>;
type GAP = Q['SurveyTextEntryEdit']['get']['gaps'][0];

class GapVM {
    constructor(readonly vm: ViewModel, readonly gapId: string) {
        this.canEdit = !vm.isReadOnly;
        this.pattern = vm.getPatternKO(gapId);
        this.validationMessage = vm.getValidationMessageKO(gapId);
    }

    public readonly pattern: ko.Observable<string>;
    public readonly validationMessage: ko.Observable<string>;
    public readonly canEdit: boolean;


    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.vm.isReadOnly,
            formData: {
                pattern: this.pattern,
                validationMessage: this.validationMessage,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'pattern',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.textentry_auto.edit.PATTERN_REGEX']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'validationMessage',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.textentry_auto.edit.VALIDATION_MESSAGE']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
            }),
        }));
        return retVal;
    });

    public update(data: GAP) {

    }

    public readonly gapTag = ko.pureComputed(() => {
        return TOKENIZER.GAP_TOKEN.compile({
            id: this.gapId
        });
    });
    public readonly isSelected = ko.pureComputed(() => this.gapId === this.vm.selectectGapId());


    public selectAction = new UIAction(undefined, async () => {
        this.vm.selectectGapId(this.gapId);
    });
    public deleteGapAction = new UIAction(undefined, async () => {
        const itemData = this.vm.item();
        if (!itemData) {
            return;
        }

        const message = i18next.t(['itemdefinition.kosovo.textentry_auto.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_GAP']);

        if (!await Modal.confirmYesNo(message)) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.surveytextentry_edit_removegap({
                itemId: this.vm.itemId,
                gapId: this.gapId
            });
        });
    });
}

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly loaded = ko.observable(false);
    public readonly answers = ko.observableArray();

    public readonly choiceTextDeleteGapButtonLabel = i18next.t(['itemdefinition.kosovo.textentry_auto.edit.DELETE_GAP']);

    public readonly choiceTextAddVariantButtonLabel = i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ADD_OPTION']);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                text: this.text,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.textentry_auto.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.textentry_auto.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.textentry_auto.edit.TEXT']),
            },
            readOnly: this.isReadOnly,
            dataField: 'text',
            placeholder: this.enterTextPlaceholder(),
            docType: API.Doctype.Item,
            docReferenceId: this.itemId,
            tokenTypes: [TOKENIZER.GAP_TOKEN],
            toolbarItems: [{
                'icon': 'far fa-plus-square',
                'text': i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ADD_GAP']),
                onClick: this.addGapFragmentAction.click,
                disabled: this.isReadOnly,
            }],
            onInitialized: async editor => {
                this.editor(editor);
                this.disposables.addDiposable(editor.currentTag.subscribe(val => {
                    const r = TOKENIZER.GAP_TOKEN.parse(val);
                    const id = r && r.id;
                    if (!id) {
                        this.selectectGapId(undefined);
                        this.selectGapOnRefresh = undefined;
                    } else {
                        if (this._gapIds().find(x => x === id)) {
                            this.selectectGapId(id);
                        } else {
                            this.selectGapOnRefresh = id;
                        }
                    }
                }));
            }
        }));
        return retVal;
    });


    private readonly editor = ko.observable<HTMLEDITOR.ViewModel>();

    private readonly _gaps = new Map<string, GapVM>();

    public readonly item = ko.observable<Q['SurveyTextEntryEdit']['get']>();

    public readonly _gapIds = ko.observable<string[]>([]);

    public readonly gaps = ko.pureComputed(() => {
        return this._gapIds().map(x => this._gaps.get(x));
    });

    public readonly selectectGapId = ko.observable('');
    public readonly text = ko.observable('');

    private selectGapOnRefresh: string;

    public readonly addGapFragmentAction = new UIAction(undefined, async () => {
        const r = await ServerConnection.api.surveytextentry_edit_addgap({
            itemId: this.itemId,
        });
        await this.editor().insertToken(TOKENIZER.GAP_TOKEN.compile({
            id: r.SurveyTextEntryEdit.addGap.gapId
        }));
        await legacyPushPull();
    });


    public readonly selectedGap = ko.pureComputed(() => {
        const selected = this.selectectGapId();
        if (!selected) {
            return undefined;
        }
        return this._gaps.get(selected);
    });

    private readonly patternKO = new Map<string, ko.Observable<string>>();
    private readonly validationMessageKO = new Map<string, ko.Observable<string>>();
    public getPatternKO(gapId: string) {
        if (!this.patternKO.has(gapId)) {
            throw new Error();
        }
        return this.patternKO.get(gapId);
    }
    public getValidationMessageKO(gapId: string) {
        if (!this.validationMessageKO.has(gapId)) {
            throw new Error();
        }
        return this.validationMessageKO.get(gapId);
    }

    getCreatePatternKO(g: GAP) {
        const gapId = g.gapId;
        if (this.patternKO.has(gapId)) {
            this.patternKO.get(gapId)(g.pattern);
        } else {
            const val = ko.observable(g.pattern);
            this.onChange(val, `${WIDGET_NAME}/${this.itemId}/pattern/${gapId}`, async v => {
                await ServerConnection.api.surveytextentry_edit_upsert({
                    params: {
                        itemId: this.itemId,
                        upsertGaps: [{
                            gapId,
                            pattern: v
                        }]
                    }
                });
                return DONE;
            });
            this.patternKO.set(gapId, val);
        }
        return this.patternKO.get(gapId);
    }
    getCreateValidationMessageKO(g: GAP) {
        const gapId = g.gapId;
        if (this.validationMessageKO.has(gapId)) {
            this.validationMessageKO.get(gapId)(g.validationMessage.value);
        } else {
            const val = ko.observable(g.validationMessage.value);
            this.onChange(val, `${WIDGET_NAME}/${this.itemId}/validationmessage/${gapId}`, async v => {
                await ServerConnection.api.surveytextentry_edit_upsert({
                    params: {
                        itemId: this.itemId,
                        upsertGaps: [{
                            gapId,
                            validationMessage: {
                                xnone: v
                            }
                        }]
                    }
                });
                return DONE;
            });
            this.validationMessageKO.set(gapId, val);
        }
        this.validationMessageKO.get(gapId);
    }
    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.surveytextentry_edit_data({
            itemId: this.itemId
        });

        const d = data.SurveyTextEntryEdit.get;
        if (!d) {
            return;
        }
        this.text(d.text.value);
        this.header(d.header.value);
        this.question(d.question.value);
        for (const g of d.gaps) {
            this.getCreatePatternKO(g);
            this.getCreateValidationMessageKO(g);
            if (!this._gaps.has(g.gapId)) {
                this._gaps.set(g.gapId, new GapVM(this, g.gapId));
            }


            this._gaps.get(g.gapId).update(g);
        }
        const gapIds = d.gaps.map(x => x.gapId).sort();
        if (gapIds.join(' ') !== this._gapIds().join(' ')) {
            this._gapIds(gapIds);
        }
        this.item(d);

        const selectGap = this.selectGapOnRefresh || this.selectectGapId();
        if (gapIds.find(x => x === selectGap)) {
            this.selectectGapId(selectGap);
        } else {
            this.selectectGapId(undefined);
        }
        this.selectGapOnRefresh = undefined;
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async () => {
            await ServerConnection.api.surveytextentry_edit_upsert({
                params: {
                    itemId: this.itemId,
                    question: xnone(this.question())
                }
            });
            return DONE;
        });
        this.onChange(this.text, `${WIDGET_NAME}/${this.itemId}/text`, async () => {
            await ServerConnection.api.surveytextentry_edit_upsert({
                params: {
                    itemId: this.itemId,
                    text: xnone(this.text())
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async () => {
            await ServerConnection.api.surveytextentry_edit_upsert({
                params: {
                    itemId: this.itemId,
                    header: xnone(this.header())
                }
            });
            return DONE;
        });


        this.loaded(true);
    }

    public readonly header = ko.observable('');

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });
    public readonly question = ko.observable('');

    public readonly gapPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ANSWER']);
    });

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ENTER_THE_TEXT_HERE']);
    });

    public readonly enterAnswerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_auto.edit.ENTER_THE_ANSWER_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

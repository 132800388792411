import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as SelectItemRoute from '../selectitem/route';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './selectsubject.html.g';

const WIDGET_PARENT_NAME = 'ui-manualgrading';

export class ViewModel extends AbstractWidget {
    public readonly loaded = ko.observable(false);
    public readonly select = new UIAction<string>(undefined, async (e, subjectDocRefId) => {
        await ROUTES.routeManager.navigateToHREF(SelectItemRoute.FACTORY.href(
            {
                subject: subjectDocRefId
            }));
    });

    constructor(readonly params: IParams) {
        super();
    }

    public async initialize() {
        await super.initialize();
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, datagrid, refreshDx } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import * as i18n from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-matchingimage-edit';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;
type Q = Awaited<ReturnType<API.Sdk['matchingimage_edit_data']>>;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    private readonly _pairs: Q['MatchingImageEdit']['get']['pairs'] = [];
    public readonly loaded = ko.observable(false);
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchingimage.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.matchingimage.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.matchingimage.edit.QUESTION']),
            },
            dataField: 'question',
            readOnly: this.isReadOnly,
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.matchingimage.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        return retVal;
    });

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.matchingimage_edit_data({
            itemId: this.itemId
        });
        const d = q.MatchingImageEdit.get;
        this._pairs.splice(0, this._pairs.length, ...d.pairs);
        this.question(d.question.value);
        this.header(d.header.value);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        await refreshDx(this);
    }

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'index',
                        data: this._pairs
                    }
                },
                editing: {
                    allowAdding: this.canEdit,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
                columns: [],
                onEditorPreparing: e => {
                    if (e.dataField === 'value.value') {
                        e.editorName = 'dxTextArea';
                    }
                }
            }
        });
        retVal.columns.push(
            {
                dataField: 'index',
                caption: i18n.t(['itemdefinition.kosovo.matchingimage.edit.ID']),
                width: 100,
                allowEditing: false
            });
        retVal.columns.push(
            {
                dataField: 'value.value',
                caption: i18n.t(['itemdefinition.kosovo.matchingimage.edit.TEXT']),
                customizeText: (cellInfo) => {
                    if (typeof cellInfo.value === 'string') {
                        return cellInfo.value.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    } else {
                        return '';
                    }
                },
                encodeHtml: false,

            });

        retVal.columns.push(
            {
                dataField: 'image.name',
                caption: i18n.t(['itemdefinition.kosovo.matchingimage.edit.IMAGE']),
                allowEditing: true,
                lookup: {
                    dataSource: {
                        store: this.attachmentsStore,
                    },
                    valueExpr: 'name',
                    displayExpr: 'name'
                },
            });
        retVal.onInitNewRow = e => {
            let index = '';
            for (let i = 0; i < 26; ++i) {
                index = String.fromCharCode(65 + i);
                if (!this._pairs.some(x => x.index === index)) {
                    break;
                }
            }
            if (!index) {
                index = 'A';
            }
            e.data = {
                index,
                value: {
                    value: i18n.t(['itemdefinition.kosovo.matchingimage.edit.NEW'])
                },
            };
        };
        retVal.onRowInserting = e => {
            e.cancel = (async () => {
                await ServerConnection.api.matchingimage_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertPairs: [{
                            index: e.data.index,
                            image: e.data.image && e.data.image.name,
                            value: xnone(e.data.value && e.data.value.value)
                        }]
                    }
                });
            })();
        };
        retVal.onRowRemoving = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.matchingimage_edit_update({
                    params: {
                        itemId: this.itemId,
                        removePairs: [e.key]
                    }
                }));
            })();
        };

        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await ServerConnection.api.matchingimage_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertPairs: [{
                            index: e.key,
                            image: e.newData.image && e.newData.image.name,
                            value: xnone(e.newData.value && e.newData.value.value)
                        }]
                    }
                });
            })();
        };
        return retVal;
    });

    private attachmentsStore: DevExpress.data.CustomStore;
    private readonly _attachments: Q['documents']['get']['attachments'] = [];

    public async initialize() {
        this.attachmentsStore = new CustomStore({
            loadMode: 'raw',
            key: 'name',
            byKey: async key => {
                if (!this._attachments) {
                    return undefined;
                }
                return this._attachments.find(x => x.name === key);
            },
            load: async (options) => {
                return this._attachments;
            }
        });

        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.matchingimage_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.matchingimage_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val)
                }
            });
            return DONE;
        });
        this.loaded(true);
    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchingimage.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly enterOptionPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchingimage.edit.ENTER_ONE_OPTION_HERE']);
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

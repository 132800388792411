export const id = 'typingtest2023';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';

itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '77d37a93-bba2-4850-aaad-21d042da821e',
    widgetNames: {
        fallback: `itemdefinition-${id}-result`,
        modes: new Map<ItemMode, string>([
            ['INTERACTIVE', `itemdefinition-${id}-interactive`],
            ['RESULT', `itemdefinition-${id}-result`],
            ['EDIT', `itemdefinition-${id}-edit`],
        ])
    },
});

import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { datagrid } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { IGetItemTypesFilter, TriStateEnum } from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_ITEMEDIT from '../itemedit/route';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './newitem.html.g';
import { IParams, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_author_newitem_data']>>;

export class ViewModel extends DxWidget {

    constructor(readonly params: IParams) {
        super();
    }


    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);

    public async OnRefresh() {
        await super.OnRefresh();
        const filter: IGetItemTypesFilter = {
            enabled: TriStateEnum.Yes
        };
        const p = this.params.currentRoute.widgetParams;
        if (p.subjectDocRefId) {
            filter.subjectDocRef = p.subjectDocRefId;
        }
        if (p.parentItemDocRefId) {
            filter.parentItemDocRef = p.parentItemDocRefId;
        }

        filter.supportsSubItems = TriStateEnum.All;
        const result = await ServerConnection.api.ui_author_newitem_data({
            filter,
        });
        this._itemTypes.splice(0, this._itemTypes.length, ...result.itemtypes);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    private readonly _itemTypes: Q['itemtypes'] = [];



    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: this._itemTypes,
                selection: {
                    mode: 'single'
                },
                filterRow: {
                    visible: false
                },
                searchPanel: {
                    visible: false
                },
                headerFilter: {
                    visible: false
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: false,
                    allowUpdating: false,
                },
                paging: {
                    enabled: false
                },
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.newitem.NAME']),
            sortOrder: 'asc',
            sortIndex: 0,
            width: 180,
            dataField: 'title.value'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.newitem.DESCRIPTION']),
            dataField: 'description.value'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.newitem.ACTIONS']),
            width: 80,
            cellTemplate: (elem, info) => {
                const retVal = $('<div />');
                $('<a />')
                    .text(i18next.t(['ui.author.newitem.CREATE']))
                    .css({ cursor: 'pointer' })
                    .on('click', () => this.newItemClick.click(info.data.id))
                    .appendTo(retVal);
                return retVal;
            }
        });

        return retVal;
    });

    public async routeToEditItem(itemId: string) {
        await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMEDIT.FACTORY.href({
            subjectDocRefId: this.params.currentRoute.widgetParams.subjectDocRefId,
            itemDocRefId: itemId,
        }), true);
    }

    public readonly newItemClick = new UIAction<string>(undefined, async (e, itemType) => {

        const p = this.params.currentRoute.widgetParams;
        const subject = p.subjectDocRefId;
        const parentItemId = this.params.currentRoute.widgetParams.parentItemDocRefId || '';
        const category = '';
        const objectiveId = '';
        const q = await ServerConnection.api.ui_author_newitem_new({
            params: {
                parentItemId,
                baseId: parentItemId,
                itemType,
                subject,
                category,
                objectiveId,
                defaultLanguage: 'x-none'
            }
        });
        await this.routeToEditItem(q.item.newItem.docReferenceId);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_NEWITEM from '../newitem/route';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-author-itemoverview';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export interface IParamsInner {
    subjectDocRefId: string;
}

export class OverviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    private readonly _subjectTitle = ko.observable('');

    public readonly title = ko.pureComputed(() => {
        const subjectTitle = this._subjectTitle();
        return subjectTitle;
    });
    static async CREATE(widgetParams: IParamsInner) {
        const retVal = new OverviewRoute(widgetParams);
        await retVal.init();
        return retVal;
    }

    private async init() {
        const r = await ServerConnection.api.ui_author_itemoverview_route({
            subjectDocRef: this.widgetParams.subjectDocRefId
        });
        this._subjectTitle(r.subjectById.title);
    }

    //Add RouteActions here
    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'add',
                caption: i18next.t(['ui.author.itemoverview.NEW_ITEM']),
                action: this.newItem
            },
            {
                icon: 'help',
                caption: i18next.t(['ui.author.itemoverview.HELP']),
                action: this.showHelp
            }];
    });
    public readonly newItem = new UIAction(undefined, async () => {
        const subjectDocRefId = this.widgetParams.subjectDocRefId;

        await ROUTES.routeManager.navigateToHREF(WIDGET_NEWITEM.FACTORY.href({
            parentItemDocRefId: '',
            subjectDocRefId,
        }));

    });
    constructor(readonly widgetParams: IParamsInner) {
    }

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.author.itemoverview.BY_HOVERING_OVER_A_CATEGORY_OBJECTIVE_WITH_YOUR_MOUSE_YOU_CAN_VIEW_THE_CORRESPONDING_DESCRIPTION_HOVERING_OVER_AN_ITEM_ID_MAKES_THE_TITLE_OF_THE_ITEM_VISIBLE']);

        toastService.info(msg);
    });
}

export const FACTORY = ROUTES.registerRoute<{ subject: string }>({
    route: '/author/subject/:subject/item',
    toParams: map => ({
        subject: map.get('subject')
    }),
    fromParams: (p, map) => {
        map.set('subject', p.subject);
    },
    create: async p => {
        const homeRoute = await OverviewRoute.CREATE({
            subjectDocRefId: p.subject
        });
        return homeRoute;
    },
});

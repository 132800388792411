import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'SurveyComparision_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type SCALE = DATA['smScale'][0];
type ROW = DATA['smRows'][0];
type COLUMN = DATA['smColumns'][0];
type CELL = DATA['smCells'][0];

export class Scale {
    public readonly id: string;
    constructor(readonly model: SurveyComparisionData, readonly data: SCALE) {
        this.id = data.id;
        this.hint = translate(data.hint, {});
        this.invalid = data.invalid;

    }
    public readonly hint: string;
    public readonly invalid: boolean;
}
export class Row {
    public readonly id: string;
    constructor(readonly model: SurveyComparisionData, readonly data: ROW) {
        this.id = data.id;
        this.caption = translate(data.caption, {});

    }
    public readonly caption: string;
}

export class Column {
    constructor(readonly model: SurveyComparisionData, readonly data: COLUMN) {
        this.caption = translate(data.caption, {});
    }
    public readonly caption: string;
}
export class Cell {
    public readonly id: string;
    public readonly selected = ko.observable<string>();
    public readonly initial = ko.observable<string>();
    public readonly invalid = ko.pureComputed(() => {
        const selected = this.selected();
        if (!selected) {
            return true;
        }
        const scale = this.model.scale.find(x => x.id === selected);
        if (!scale) {
            throw new Error('Stupid programmer');
        }
        return scale.invalid;
    });

    constructor(readonly model: SurveyComparisionData, readonly colId: string, readonly rowId: string) {
    }
    public fromJS(data: CELL) {
        this.selected(data && data.selected && data.selected.id);
        this.initial(data && data.initial && data.initial.id);
    }

}
export class SurveyComparisionData extends ItemDataModel {
    getCellId(col: Column, row: Row) {
        return `${col.data.id}_${row.data.id}`;
    }
    constructor(readonly meta: ItemModel, data: DATA) {
        super();

        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.rows = data.smRows.map(x => new Row(this, x));
        this.columns = data.smColumns.map(x => new Column(this, x));
        this.scale = data.smScale.map(x => new Scale(this, x));
        for (const c of this.columns) {
            for (const r of this.rows) {
                const key = this.getCellId(c, r);
                this.cells.set(key, new Cell(this, c.data.id, r.data.id));
            }
        }
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'SurveyComparision_QueryItemSessionData') {
            throw new Error();
        }
        for (const cell of data.smCells) {
            const col = this.columns.find(x => x.data.id === cell.col.id);
            const row = this.rows.find(x => x.data.id === cell.row.id);
            const key = this.getCellId(col, row);
            this.cells.get(key).fromJS(cell);
        }
    }
    public readonly questionHTML: string;
    public readonly rows: Row[];
    public readonly columns: Column[];
    public readonly scale: Scale[];
    public readonly cells: Map<string, Cell> = new Map<string, Cell>();
    public readonly headerText: string;


    public IsInitialData() {
        return Array.from(this.cells.values()).every(x => x.selected() === x.initial());
    }
    public IsInteractionStarted() {
        return Array.from(this.cells.values()).some(x => !!x.selected());
    }
    public IsInteractionComplete() {
        return Array.from(this.cells.values()).every(x => !x.invalid());
    }
    public async reset() {
        for (const cell of Array.from(this.cells.values())) {
            cell.selected(cell.initial());
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SurveyComparision: {

                cells: Array.from(this.cells.values()).map(x => ({
                    rowId: x.rowId,
                    colId: x.colId,
                    selected: x.selected(),
                })),
            }
        };
        return retVal;
    }

}

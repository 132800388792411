import * as ko from 'knockout';
import * as API from '../../../its-itembank-api.g';
import { ItemOverviewBodyField } from './ItemOverviewBodyField';
import { ItemOverviewBodyFieldTemplate } from './ItemOverviewBodyFieldTemplate';

type Q_OBJ = Awaited<ReturnType<API.Sdk['ui_resultscreens_default_objectives']>>;
type ITEM = Q_OBJ['examSession']['items'][0];

export class Item {
    public readonly itemId: string;
    public readonly itemType: string;
    constructor(readonly model: { itemBodyFields: ko.MaybeSubscribable<ItemOverviewBodyFieldTemplate[]> }, readonly params: ITEM) {
        this.itemId = params.itemId;
        this.itemType = params.itemType;
    }


    public readonly fields = ko.pureComputed(() => {
        return ko.unwrap(this.model.itemBodyFields).map(x => new ItemOverviewBodyField(x, this));
    });

}

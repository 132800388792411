import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-itemtypeoverview';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class ItemTypeOverviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.itemtypeoverview.ROUTE_TITLE']);
    });

    constructor(readonly widgetParams: IParamsInner) {
    }

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.itemtypeoverview.IF_YOU_DEACTIVATE_AN_ITEM_TYPE_THE_AUTHOR_CAN_NO_LONGER_CHOOSE_THIS_ITEM_TYPE_FOR_ITEM_CREATION_ALREADY_CREATED_ITEMS_OF_THE_DEACTIVATED_ITEM_TYPE_ARE_STILL_AVAILABLE_IF_THE_CONTENT_OF_ONE_COLUMN_IS_NOT_COMPLETELY_VISIBLE_YOU_CAN_ADJUST_THE_WIDTH_BY_PULLING_THE_LINE']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.superuser.itemtypeoverview.HELP']),
                action: this.showHelp
            }];
    });
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/itemtype',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return new ItemTypeOverviewRoute(undefined);
    },
});

export const id = 'kosovo-crossout';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '634c0b49-243a-4c02-8741-065c98dc2b48',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import $ from 'jquery';
import * as ko from 'knockout';
import { log } from '../debug';
import { toastService } from '../toastService';
import * as i18next from './../i18n/i18n';
import { helpWindow } from './base';
import { legacyPushPull } from './docmanager';
import { app } from './EntryApp';
import { EntryPoint, ItemHostBase } from './ItemHostBase';
import { ServerConnection } from './RestAPI';
import * as ROUTES from './routes';
import { prepareToolbar } from './toptoolbar.service';
import { UIAction } from './uiAction';

export abstract class RoutableBase extends ItemHostBase {
    public readonly actionRefresh = new UIAction(undefined, async () => {
        await legacyPushPull();
    });
    public readonly actionSaveNow = new UIAction(undefined, async () => {
        await legacyPushPull(async () => { });
        toastService.info(i18next.t(['ui.routablebase.DATA_HAS_BEEN_SAVED']));
    });
    public readonly actionOnlineHelp = new UIAction<string>(undefined, async (e, helpId) => {
        log(`Show Help of ${helpId}`);
        const wnd = helpWindow.prepareOpenWindow('');
        const data = await ServerConnection.api.online_help({
            helpId,
            widgetName: [this.currentRouteWidgetName()]
        });
        const url = data.config.onlineHelpById.url;
        log(`Show url ${url}`);
        helpWindow.openWindow(url, 'help');
    });
    public readonly searchValuePlaceholder = ko.pureComputed(() => {
        return i18next.t(['ui.routablebase.ITEM_ID']);
    });
    public readonly searchValue = ko.observable<string>();
    public readonly actionSearch = new UIAction(undefined, async () => {
        toastService.info(`Edit ${this.searchValue()}`);
    });
    public readonly currentRoute = ko.pureComputed(() => {
        return ROUTES.routeManager.current();
    });
    public readonly currentRouteTitle = ko.pureComputed(() => {
        const current = this.currentRoute();
        if (!current) {
            return;
        }
        return current.title();
    });
    public readonly currentRouteWidgetName = ko.pureComputed(() => {
        const p = this.currentRoute();
        if (!p) {
            return undefined;
        }
        return p.widgetName;
    });
    public readonly currentRouteWidgetParams = ko.pureComputed(() => {
        const p = this.currentRoute();
        if (!p) {
            return undefined;
        }
        const retVal: ROUTES.IRoutableParams<{}> = {
            currentRoute: p,
        };
        return retVal;
    });
    protected actionChangeRole = new UIAction<{
        ep: EntryPoint;
    }>(undefined, async (e, args) => {
        log(`Changing main widget to: ${args.ep.mainWidget}`);
        app.selectApp(args.ep.mainWidget);

    });
    protected abstract getRoleSwitcherMenu(): {};
    protected getRoleSwitcherMenuBase(currentRole: string) {
        const retVal = {
            location: 'after',
            widget: 'dxDropDownMenu',
            locateInMenu: 'auto',
            options: {
                text: 'Change Role',
                icon: 'far fa-user',
                buttonIcon: 'far fa-user',
                buttonText: `${currentRole}`,
                items: new Array<{}>()
            }
        };
        return retVal;
    }
    public readonly clickAction = new UIAction(undefined, async () => {
        await ROUTES.routeManager.back();
    });
    public readonly toolbarOptions = ko.pureComputed(() => {
        const route = this.currentRoute();
        const languageSwitcher = !route || !!route.languageSwitcher;
        const retVal: DevExpress.ui.dxToolbar.Properties = {
            items: []
        };
        if (history.length) {
            retVal.items.push({
                location: 'before',
                widget: 'dxButton',
                options: {
                    type: 'back',
                    text: 'Back',
                    onClick: this.clickAction.click
                }
            });
        }
        const roleSwitcher = this.getRoleSwitcherMenu();
        if (roleSwitcher) {
            retVal.items.push(roleSwitcher);
        }

        if (languageSwitcher) {
            retVal.items.push({
                location: 'before',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                    icon: 'fas fa-globe',
                    text: this.currentLocalization()?.englishName ?? '?',
                    onClick: this.nextLanguage.click,
                }
            });
        }
        retVal.items.push({
            location: 'before',
            locateInMenu: 'never',
            template: () => {
                return $(`<div class='toolbar-label'><b>${this.instanceName()}</b> ${this.itemBankVersion}</div>`);
            }
        });
        if (route) {
            const actions = ko.unwrap(route.routeActions) || [];
            for (const action of actions) {
                retVal.items.push({
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'always',
                    options: {
                        text: ko.unwrap(action.caption),
                        icon: ko.unwrap(action.icon),
                        onClick: action.action.click
                    }
                });
            }
        }
        retVal.items.push({
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                text: i18next.t(['ui.routablebase.REFRESH'])
            },
            onClick: this.actionRefresh.click
        });
        retVal.items.push({
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
                icon: 'save',
                text: i18next.t(['ui.routablebase.SAVE']),
                onClick: this.actionSaveNow.click
            }
        });
        for (const help of this.onlineHelp()) {
            retVal.items.push({
                location: 'after',
                locateInMenu: 'always',
                widget: 'dxButton',
                options: {
                    icon: 'help',
                    text: help.label.value,
                    onClick: this.actionOnlineHelp.asAction(help.id).click
                }
            });
        }
        if (this.loggedin()) {
            retVal.items.push({
                location: 'after',
                locateInMenu: 'always',
                widget: 'dxButton',
                options: {
                    icon: 'fas fa-sign-out',
                    text: i18next.t(['ui.routablebase.LOGOUT']),
                    onClick: this.actionLogout.click
                }
            });
        }
        prepareToolbar(retVal);
        return retVal;
    });

    public readonly nextLanguage = new UIAction(undefined, async () => {
        const langs = this.baseConfig.config.languageSwitcher;
        const current = this.currentLocalization()?.id || '';
        let idx = langs.findIndex(x => x.id === current);
        idx++;
        if (idx >= langs.length) {
            idx = 0;
        }
        const newLang = langs[idx].id;
        if (newLang === current) {
            return;
        }
        const result = await ServerConnection.api.ui_switchlanguage({
            lang: newLang,
        });
        window.location.reload();
    });
}

import DevExpress from 'devextreme';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import { log } from '../../../../debug';
import { FormBuilder, UpdateFunction } from '../../../../dxHelper/formBuilder';
import { AS, datagrid, dg_addTreeList, IDxWidget } from '../../../../dx_helper';
import { getAncestorsAndSelf } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { IUpdateItemMetaDataInput } from '../../../../its-itembank-api.g';
import { xnone } from '../../../../model/languagemap';
import * as i18n from './../../../../i18n/i18n';
import * as i18next from './../../../../i18n/i18n';
import { SyllabusStore } from './SyllabusStore';

type Q = Awaited<ReturnType<API.Sdk['ui_author_itemedit_itemmetadatablock_get']>>;
type ALL_AUDIENCE = Q['item']['get']['metaData']['subject']['audiences'][0];

export type D = Q['item']['get'] & {
    translatedAuthoringStatus: string;
    itemTypeDisplay: string;
    audiences: string[];
    alternations: string[];
    objectiveId: string[];
    objectiveScore: number;
};
export type U = Omit<IUpdateItemMetaDataInput, "itemId"> & {
    objectiveId?: string;
    objectiveScore?: number;
};

export class MyFormBuilder extends FormBuilder<D, U> {
    constructor(readonly vm: IDxWidget & {
        hasObjectives: ko.Subscribable<boolean>;
        isSubItem: ko.Subscribable<boolean>;
        subjectId: ko.Subscribable<string>;
        //translatedAuthoringStatus: ko.Subscribable<string>;
        //itemTypeDisplayName: ko.Subscribable<string>;
        displayNoteForAuthor: ko.Subscribable<boolean>;
        //itemTypeUUID: ko.Subscribable<string>;
        displayCopiedFrom: ko.Subscribable<boolean>;
        displayRevisionFrom: ko.Subscribable<boolean>;
        syllabusStore: SyllabusStore;
        objectiveStore: CustomStore;
        categoriesStore: CustomStore;
        languagesStore: DevExpress.data.CustomStore;
        alternationsStore: CustomStore;
        allAudiences: ALL_AUDIENCE[];
        isReadOnly: boolean;
        WIDGET_NAME: string,
    }) {
        super();
    }

    protected onInit() {
        super.onInit();

        this.addTextBox(this.formOptions, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.TITLE'])
            },
            dataField: 'metaData.title'
        }, val => ({ title: val }));

        this.addTextBox(this.formOptions, {
            label: {
                text: i18n.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.TITLE_VISIBLE_TO_EVERYONE']),
            },
            dataField: 'metaData.publicTitle.defaultValue'
        }, val => ({ publicTitle: xnone(val) }));

        this.initObjectives();
        this.initTopItem();
        this.initCommonData();
    }

    private editable<UPDATE, VAL>(update: UpdateFunction<UPDATE, VAL>): UpdateFunction<UPDATE, VAL> {
        if (this.vm.isReadOnly) {
            return undefined;
        }
        return update;
    }
    private initCommonData() {
        const g = this.formOptions;
        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.STATUS']),
            },
            dataField: 'translatedAuthoringStatus',
        });
        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ITEM_TYPE']),
            },
            dataField: 'itemTypeDisplay',
        });

        this.addSelectBox<string>(g, {
            dataField: 'metaData.defaultLocalization.id',
            editorOptions: {
                dataSource: {
                    store: this.vm.languagesStore
                },
                displayExpr: 'name.value',
                valueExpr: 'id',
                showClearButton: true,
            },
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.LANGUAGE_OF_ITEM']),
            }
        }, this.editable(val => ({ defaultLocalization: val })));


        this.addNumberBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.MINIMUM_WIDTH'])
            },
            dataField: 'metaData.minWidth',
            editorOptions: {
                min: 0,
                max: 4000,
                format: { type: 'decimal', precision: 0 },
            },
        }, this.editable(val => ({ minWidth: val })));

        this.addNumberBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.MINIMUM_HEIGHT'])
            },
            dataField: 'metaData.minHeight',
            editorOptions: {
                min: 0,
                max: 4000,
                format: { type: 'decimal', precision: 0 },
            },

        }, this.editable(val => ({ minHeight: val })));

        this.addTextBox(g, {
            label: {
                text: i18n.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.CURRENT_AUTHOR'])
            },
            dataField: 'workflow.currentAuthor.userId',
        });
        if (this.vm.displayNoteForAuthor()) {
            this.addTextArea(g, {
                label: {
                    text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.NOTE']),
                },
                dataField: 'workflow.noteForAuthor',
                editorOptions: {
                    autoResizeEnabled: true
                }
            });
        }
        if (this.vm.displayCopiedFrom()) {
            this.addTextBox(g, {
                label: {
                    text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.COPIED_FROM'])
                },
                dataField: 'metaData.copyFrom.itemId',
            });
        }
        if (this.vm.displayRevisionFrom()) {
            this.addTextArea(g, {
                label: {
                    text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ORIGINAL_ITEM'])
                },
                dataField: 'metaData.revisionFrom.itemId',
            });
        }
    }
    private initTopItem() {
        if (this.vm.isSubItem()) {
            return;
        }
        const g = this.formOptions;
        this.addTagBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.AUDIENCE']),
            },
            dataField: 'audiences',
            editorOptions: {
                dataSource: this.vm.allAudiences,
                displayExpr: 'audienceId',
                valueExpr: 'docReferenceId',
                showSelectionControls: true,
                applyValueMode: 'useButtons',
                readOnly: this.vm.isReadOnly,
                placeholder: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.SELECT'])
            }
        }, this.editable(val => ({ setAudiences: val })));

        this.addTreeList(g,
            {
                label: {
                    text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ALTERNATIONS']),
                },
                dataField: 'alternations',
                editorOptions: {
                    dataSource: {
                        store: this.vm.alternationsStore,
                    },
                    displayExpr: 'display',
                    valueExpr: 'id',
                    dropDownOptions: {
                        height: 'auto',
                    },
                }
            },
            {
                height: 400,
                autoExpandAll: true,
                searchPanel: {
                    visible: false
                },
                selection: {
                    mode: 'multiple'
                },
                rootValue: '',
                columns: [
                    {
                        caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ID']),
                        dataField: 'id',
                        width: '150'
                    },
                    {
                        caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.NAME']),
                        dataField: 'display'
                    }
                ],
                editing: {
                    allowAdding: false,
                    allowDeleting: false,
                    allowUpdating: false,
                },

            },
            this.editable(val => ({ setAlternations: val })));
    }

    private initObjectives() {
        if (!this.vm.hasObjectives()) {
            return;
        }
        const g = this.formOptions;
        const subjectId = this.vm.subjectId();

        this.addLookup<string>(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.OBJECTIVE']),
            },
            dataField: 'metaData.category.taxon',
            editorOptions: {
                displayExpr: 'display',
                valueExpr: 'id',
                dataSource: {
                    store: this.vm.categoriesStore
                }
            }

        }, this.editable(val => ({ categoryId: val })));

        this.addNumberBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.DIFFICULTY']),
            },
            dataField: 'metaData.itemDifficultyIndex',
            editorOptions: {
                min: 0,
                max: 100
            }
        }, this.editable(val => ({ difficulty: val.toString() })));

        const objectivesDg = datagrid({
            WIDGET_NAME: this.vm.WIDGET_NAME,
            discriminator: 'objectivesDg#1',
            widget: this.vm,
            config: {
                editing: {
                    allowAdding: !this.vm.isReadOnly,
                    allowDeleting: !this.vm.isReadOnly,
                    allowUpdating: !this.vm.isReadOnly,
                    mode: 'cell',
                },
                dataSource: {
                    store: this.vm.objectiveStore,
                },
            }
        });
        dg_addTreeList({
            grid: objectivesDg,
            column: {
                dataType: 'string',
                dataField: 'objectiveId',
                caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.CATEGORY']),
                lookup: {
                    displayExpr: 'display',
                    valueExpr: 'id',
                    dataSource: {
                        store: this.vm.syllabusStore
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                    },
                ],
                cellTemplate: (e, cellInfo) => {
                    const objId = cellInfo.value;
                    return this.htmlForObjective(objId);
                },
            },
            /*
            fieldTemplate: (value: { objectiveId: string }) => {
                log(`Field template: ${JSON.stringify(value)}`);
                return this.htmlForObjective(value.objectiveId);
            },
            */
            fieldTemplate: (value: string) => {
                log(`Field template: ${JSON.stringify(value)}`);
                return this.htmlForObjective(value);
            },
            treeListOptions: {
                searchPanel: {
                    visible: true,
                },
                columns: [
                    {
                        caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ID']),
                        dataField: 'id',
                        width: '150'
                    },
                    {
                        caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.NAME']),
                        dataField: 'display'
                    },
                ]
            }
        });
        objectivesDg.columns.push({
            dataType: 'number',
            dataField: 'objectiveScore',
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.POINTS']),
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
        });

        this.addDataGrid(g, {
            label: {
                text: i18n.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.SCORING']),
            },
            editorOptions: objectivesDg,
        });
    }

    htmlForObjective(objId: string) {

        const texts = getAncestorsAndSelf(objId).reverse().map(x => `<b>${x}</b> ${this.vm.syllabusStore.getDisplay(x)}`);
        return $('<div />').append(
            $('<div />').dxTextBox({ value: objId, readOnly: true }).css('display', 'none'),
            $('<div />').html(texts.join('<br />'))
        );

    }

}

import { log } from '../../debug';
import $ from 'jquery';

let global_id = 0;
class VideoPlayer extends HTMLElement {
    static get observedAttributes(): string[] {
        return [];
    }

    private jPlayerRoot: JQuery<HTMLElement>;
    private readonly containerId: string;
    private readonly playerId: string;
    private widthPx: string;
    private heightPx: string;
    constructor() {
        super();
        const source = this.getAttribute('source');
        const widthRaw = this.getAttribute('width');
        const heightRaw = this.getAttribute('height');
        const hasZoom = this.getAttribute('zoom') === "yes";
        let width = parseInt(widthRaw, 10);
        let height = parseInt(heightRaw, 10);
        if (isNaN(width)) {
            width = 640;
        }
        if (isNaN(height)) {
            height = 360;
        }
        this.widthPx = `${width}px`;
        this.heightPx = `${height}px`;

        log(`video-player element created with ${source} ${this.widthPx}*${this.heightPx}`);
        const id = ++global_id;
        this.containerId = `video_player_container_${id}`;
        this.playerId = `video_player_player_${id}`;

        const div = document.createElement('div');
        this.appendChild(div);
        div.innerHTML = `
<div id="${this.containerId}" class="jp-video" style="width:${this.widthPx}" role="application" aria-label="media player">
        <div class="jp-type-single">
            <div id="${this.playerId}" class="jp-jplayer"></div>
            <div class="jp-gui">
                <div class="jp-video-play" style="margin-top:-${this.heightPx};height:${this.heightPx}">
                    <button class="jp-video-play-icon" role="button" tabindex="0">play</button>
                </div>
                <div class="jp-interface">
                    <div class="jp-progress">
                        <div class="jp-seek-bar">
                            <div class="jp-play-bar"></div>
                        </div>
                    </div>
                    <div class="jp-current-time" role="timer" aria-label="time">&#160;</div>
                    <div class="jp-duration" role="timer" aria-label="duration">&#160;</div>
                    <div class="jp-controls-holder">
                        <div class="jp-controls">
                            <button class="jp-play" role="button" tabindex="0">play</button>
                            <button class="jp-stop" role="button" tabindex="0">stop</button>
                        </div>
                        <div class="jp-volume-controls">
                            <button class="jp-mute" role="button" tabindex="0">mute</button>
                            <button class="jp-volume-max" role="button" tabindex="0">max volume</button>
                            <div class="jp-volume-bar">
                                <div class="jp-volume-bar-value"></div>
                            </div>
                        </div>
                        <div class="jp-toggles">
                            <button class="jp-repeat" role="button" tabindex="0">repeat</button>
                            <button class="jp-full-screen" role="button" tabindex="0">full screen</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="jp-no-solution">
                <span>Update Required</span>
                To play the media you will need to update your browser to a recent version.
	
            </div>
        </div>
    </div>
</div>`;
        if (!hasZoom) {
            div.querySelector<HTMLElement>('.jp-full-screen').style.display = 'none';
        }
    }
    connectedCallback() {
        const source = this.getAttribute('source');
        log(`video-player element added to page. Source=${source}`);
        this.jPlayerRoot = $(`#${this.playerId}`, this.querySelector('div'));

        this.jPlayerRoot.jPlayer({
            ready: () => {
                log('video-player ready');
                this.jPlayerRoot.jPlayer('setMedia', {
                    title: '',
                    m4v: source,
                    ogv: source,
                    webmv: source,
                });
            },
            solution: 'html',
            cssSelectorAncestor: `#${this.containerId}`,
            supplied: 'webmv, ogv, m4v',
            size: {
                width: this.widthPx,
                height: this.heightPx,
                cssClass: ''
            },

            useStateClassSkin: true,
            autoBlur: false,
            smoothPlayBar: true,
            keyEnabled: false,
            remainingDuration: true,
            toggleDuration: true
        });

    }
    disconnectedCallback() {
        log('video-player element removed from page.');
        if (this.jPlayerRoot) {
            this.jPlayerRoot.jPlayer('destroy');
        }
    }

    adoptedCallback() {
        log('video-player element moved to new page.');
    }

    attributeChangedCallback(name: unknown, oldValue: unknown, newValue: unknown) {
        console.log('video-player element attributes changed.');
        this.updateStyle();
    }

    private updateStyle() {

    }

}
log(`register video-player`);
customElements.define('video-player', VideoPlayer);

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { datagrid, refreshDx } from '../../../../dx_helper';
import * as API from '../../../../its-itembank-api.g';
import { legacyPushPull } from '../../../docmanager';
import { ServerConnection } from '../../../RestAPI';
import { UIAction } from '../../../uiAction';
import * as i18next from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_examorderedit_addsubject_data']>>;

export const WIDGET_NAME = 'ui-superuser-examorderedit-addsubject';

type FORMDATA = Q['examOrder']['byId'];


export interface IParams {
    examOrderDocRefId: string;
    popupVisible: ko.Observable<boolean>;
}

export class ViewModel extends DxWidget {
    private readonly formData: FORMDATA = <any>{};

    public readonly addSubjectHeader = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.examorderedit.addsubject.ADD_SUBJECT_TO_EXAM_ORDER']);
    });
    public readonly subjectLabel = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.examorderedit.addsubject.SUBJECT']);
    });

    public readonly addButtonOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18next.t(['ui.superuser.examorderedit.addsubject.ADD']),
            icon: 'add',
            onClick: this.actionAddExam.click
        };
        return retVal;
    });

    private readonly subjects = new CustomStore({
        loadMode: 'raw',
        key: 'docReferenceId',
        load: async () => {
            return this._subjects;
        },
        byKey: async key => {
            return this._subjects.find(x => x.docReferenceId === key);
        }
    });
    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: this.subjects
                },
                columns: [],
                selection: {
                    mode: 'multiple'

                }
            }
        });
        retVal.columns.push({
            dataField: 'subjectId',
            caption: i18next.t(['ui.superuser.examorderedit.addsubject.SUBJECT_ID']),
            dataType: 'string',
        });
        retVal.columns.push({
            dataField: 'title',
            caption: i18next.t(['ui.superuser.examorderedit.addsubject.TITLE']),
            dataType: 'string',
        });

        retVal.selectedRowKeys = <any>this.subjectDocRefId;
        return retVal;
    });

    public readonly subjectDocRefId = ko.observable<string[]>();
    public readonly actionAddExam = new UIAction(undefined, async () => {
        const selected = this.subjectDocRefId();
        if (!selected && !selected.length) {
            return;
        }
        await legacyPushPull(async () => {
            const examOrderDocRef = this.params.examOrderDocRefId;
            const examOrderId = this.formData.examinationOrderId;
            await ServerConnection.api.ui_superuser_examorderedit_addsubject({
                params: {
                    examOrderDocRef,
                    examOrderId,
                    addSubjects: selected.map(x => ({
                        subjectDocRef: x
                    }))
                }
            });
        });
        this.params.popupVisible(false);
    });

    constructor(readonly params: IParams) {
        super();
    }

    public readonly config = ko.observable<Q>();
    public readonly loaded = ko.observable(false);

    private readonly _subjects: Q['examOrder']['byId']['audience']['subjects'] = [];

    public async OnRefresh() {
        await super.OnRefresh();
        const config = await ServerConnection.api.ui_superuser_examorderedit_addsubject_data({
            docRef: this.params.examOrderDocRefId
        });
        this.config(config);
        Object.assign(this.formData, config.examOrder.byId);

        const existing = new Set<string>(this.formData.subjects.map(x => x.subject.docReferenceId));
        const avail = this.formData.audience && this.formData.audience.subjects || [];
        this._subjects.splice(0, this._subjects.length, ...avail.filter(x => !existing.has(x.docReferenceId)));
        await refreshDx(this);
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }

}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

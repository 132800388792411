export const BUILD_INFO = {
  "semVerMajor": 2,
  "ITS_ITEMBANK_UI_VERSION": "v2.2.15--2023-12-06--14-11-55",
  "CI_COMMIT_REF_NAME": "v2.2.15",
  "releaseType": "FINAL",
  "CI_COMMIT_REF_SLUG": "v2-2-15",
  "PAGES_HOSTNAME": "bitmedia.gitlab.io",
  "DEFAULT_CI_REGISTRY_IMAGE": "registry.gitlab.com/bitmedia/its/its-itembank/its-itembank-ui",
  "DOCKER_TAG": "v2.2.15",
  "PAGES_PATH": "/ITS/its-itembank/its-itembank-ui",
  "ITS_ITEMBANK_UI": "registry.gitlab.com/bitmedia/its/its-itembank/its-itembank-ui:v2.2.15--2023-12-06--14-11-55",
  "CI_COMMIT_TAG": "v2.2.15",
  "grow": 1,
  "ITS_ITEMBANK_UI_IMAGE": "registry.gitlab.com/bitmedia/its/its-itembank/its-itembank-ui",
  "serviceName": "its-itembank-ui",
  "TARGET_PLATFORMS": "linux/amd64,linux/arm64",
  "CI_COMMIT_SHA": "b0bfc0c75ee13be2126c8e0538001a66e9f88c9c",
  "DOCKER_TAG_ALTERNATE": "v2.2.15--2023-12-06--14-11-55",
  "buildDate": "2023-12-06T14:11:55.0000000+00:00",
  "semVer": "2.2.15"
} as const;

import * as ko from 'knockout';
import { log } from '../debug';
import { DONE, DONEENUM } from '../helper';


export const connectionLost = ko.observable(false);
export const connectionLostTime = ko.observable(0);

type RefreshFunction = () => Promise<DONEENUM>;
let refreshFunctions = new Array<RefreshFunction>();

export function RegisterRefreshFunction(fn: RefreshFunction) {
    refreshFunctions.push(fn);
}

export async function legacyPushPull<T>(work?: () => Promise<T>): Promise<T> {
    await runQueue({ noRefresh: true });
    const retVal = work && await work();
    await Promise.all(refreshFunctions.map(x => x()));
    return retVal;
}

export type WORKER = () => Promise<any>;
interface IQueueEntry {
    work: WORKER;
    callId: string;
    triggerRefresh: boolean;
}

const queuedCalls: IQueueEntry[] = [];

async function _runNextEntry() {
    const [nextCall] = queuedCalls.splice(0, 1);
    if (nextCall) {
        try {
            await nextCall.work();
            if (nextCall.triggerRefresh) {
                triggerRefresh = true;
            }
        } catch (e) {
            log(`Error at ${nextCall.callId}: ${e}`);
            triggerRefresh = true;
        }
    }
    return DONE;
}
async function _runAllEntries(options: IRunQueueOptions) {
    try {
        while (queuedCalls.length > 0) {
            await _runNextEntry();
        }
    } catch (e) {
        log(`runQueue: exception in queue`);
    }
    if (!options.noRefresh) {
        if (triggerRefresh) {
            triggerRefresh = false;
            await Promise.all(refreshFunctions.map(x => x()));
        }
    }
    return DONE;
}

let triggerRefresh = false;
let queueRunning: Promise<DONEENUM>;
interface IRunQueueOptions {
    noRefresh?: boolean;
}
export async function runQueue(options?: IRunQueueOptions) {
    if (queueRunning) {
        return queueRunning;
    }
    const v = _runAllEntries(options || {});
    queueRunning = v;
    v.then(() => queueRunning = undefined, e => queueRunning = undefined);
    return v;
}
function startQueue() {
    if (queueRunning) {
        return;
    }
    void runQueue();
}
setInterval(startQueue, 500);

export function queueApiCall(callId: string, work: () => Promise<any>, triggerRefresh?: boolean): DONEENUM {
    if (queuedCalls.length && queuedCalls[queuedCalls.length - 1].callId === callId) {
        queuedCalls[queuedCalls.length - 1].work = work;
        return DONE;
    }
    queuedCalls.push({
        work,
        callId,
        triggerRefresh: triggerRefresh || false,
    });
    return DONE;
}
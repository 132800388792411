import * as ko from 'knockout';
import { Objective } from './Objective';
import { ObjectiveFieldPart } from './ObjectiveFieldPart';
import type { ObjectiveOverviewBodyFieldTemplate } from './ObjectiveOverviewBodyFieldTemplate';


export class ObjectiveOverviewBodyField {
    constructor(readonly template: ObjectiveOverviewBodyFieldTemplate, readonly objective: Objective) {
    }

    public readonly cssClass = ko.pureComputed(() => {
        return this.template.cssClass;
    });

    public readonly parts = ko.pureComputed(() => {
        return this.template.parts.map(x => new ObjectiveFieldPart(x, this));
    });
}

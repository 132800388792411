export const id = 'kosovo-fillingap';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';


itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '108396f5-f8d4-4289-b11c-3fc76674d04a',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});
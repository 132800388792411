import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { log } from '../../debug';
import * as i18n from './../../i18n/i18n';
import { htmlString } from './widget.html.g'; //tslint:disable-line

const WIDGET_NAME = 'widgets-imagepopup';

export class ImagePopupController {
    public readonly img = ko.observable('');
    public close() {
        log(`Closing popup image`);
        this.img('');
    }
    public show(url: string) {
        log(`Showing popup image ${url}`);
        this.img(url);
    }
}
export const ImagePopup = new ImagePopupController();
(<any>window).ImagePopup = ImagePopup;

export interface IParams {

}

export class ViewModel extends AbstractWidget {

    public readonly hint = ko.pureComputed(() => {
        return i18n.t(['widgets.imagepopup.CLICK_TO_CLOSE_THE_POPUP']);
    });

    public close() {
        ImagePopup.close();
    }
    public readonly img = ko.pureComputed(() => {
        return ImagePopup.img();
    });
    public readonly imgStyle = ko.pureComputed(() => {
        return {
            backgroundImage: `url(${this.img()})`,
        };
    });

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();


    }



    public async initialize() {
        await super.initialize();
    }

    public dispose() {
        super.dispose();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});


import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18n from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-author-selectsubject';

export type IParamsInner = {};
export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export const topTitle = ko.pureComputed(() => i18n.t(['ui.author.AUTHOR']));

export class SelectSubjectRoute implements ROUTES.IRoute<IParamsInner> {

    public readonly languageSwitcher = true;
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18n.t(['ui.author.selectsubject.SUBJECTS']);
    });
    public readonly topTitle = topTitle;
    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute({
    route: '/author',
    toParams: () => ({}),
    fromParams: () => { },
    create: async p => {
        return new SelectSubjectRoute(undefined);
    },
});

import $ from 'jquery';
import { log } from './debug';
import * as query from './getquery';
import { sleep } from './helper';
import { multiCompare, numberCompare, reverse } from './tree';

export { golemMatch } from './golem';

let counter = 0;
export interface IGremlin {
    name?: string;
    priority?: number;
    action: () => Promise<boolean>;
}
interface IGremlinInternal extends IGremlin {
    counter: number;
}
const gremlins = new Map<string, IGremlinInternal>();
let sortedGremlins: IGremlinInternal[];

export function registerGremlin(gremlin: IGremlin) {
    if (!hasGremlins) {
        return undefined;
    }

    counter++;
    const id = `${counter}`;
    sortedGremlins = null;
    const g = Object.assign({ counter }, gremlin);
    gremlins.set(id, g);
    log(`Register gremlin ${g.counter} Prio=${g.priority || 0} Name=${gremlin.name}`);
    return {
        dispose() {
            log(`Unregister gremlin ${id}  ${gremlin.name}`);
            gremlins.delete(id);
        }
    };
}

let currentAttack: Promise<void> = null;

let _stopAttack = false;
let _continuousAttack: Promise<void> = null;

async function continuousAttack() {
    while (!_stopAttack) {
        await sleep(gremlinDelaySeconds * 1000);
        await startAttack();
    }
}

async function fetchGolemScript(): Promise<string> {
    const g = query.params.get('golem');
    if (!g) {
        return undefined;
    }
    return $.ajax({
        method: 'GET',
        url: g,
    });

}

type GolemData = string | number | string[] | number[] | { [key: string]: string };
let golemScript: { [key: string]: GolemData } = {};

export function isStringArray(data: GolemData): data is string[] {
    if (!data) {
        return false;
    }
    if (!Array.isArray(data)) {
        return false;
    }
    if (data.length === 0) {
        return true;
    }
    return typeof data[0] === 'string';
}

export function isStringMap(data: GolemData): data is { [key: string]: string } {
    if (!data) {
        return false;
    }
    if (typeof data === 'string') {
        return false;
    }
    if (typeof data === 'number') {
        return false;
    }
    if (Array.isArray(data)) {
        return false;
    }
    return true;
}

export function getGolemData(itemId: string): GolemData {
    return golemScript[itemId];
}



export function setGolemScript(script: string) {
    if (!script) {
        return;
    }
    if (typeof script === 'string') {
        golemScript = JSON.parse(script);
    } else if (typeof script === 'object') {
        golemScript = script;
    }
}

export const hasGremlins = !!query.params.get('gremlins');
// tslint:disable-next-line:only-arrow-functions
export const gremlinDelaySeconds = (function () {
    const g = query.params.get('gremlins');
    const delaySeconds = parseFloat(g);
    if (!isNaN(delaySeconds)) {
        return delaySeconds;
    }
    return 1;
})();


export async function stopAttack() {
    _stopAttack = true;
    if (!_continuousAttack) {
        return;
    }
    await _continuousAttack;
}
export async function startContinuousAttack() {
    if (!hasGremlins) {
        return;
    }


    _stopAttack = false;
    if (_continuousAttack) {
        return _continuousAttack;
    }
    log(`There are gremlins in the house! They'll attack every ${gremlinDelaySeconds} second(s)`);
    try {
        _continuousAttack = continuousAttack();
        await _continuousAttack;
    }
    catch (e) {
        log(e);
    }
    finally {
        _continuousAttack = null;
    }
}
export async function startAttack() {
    if (currentAttack) {
        return currentAttack;
    }
    try {
        log('start new attack');
        currentAttack = attack();
        await currentAttack;
    }
    catch (e) {
        log(e);
    }
    finally {
        currentAttack = null;
        log('attack completed');
    }
}
export async function attack() {
    if (!sortedGremlins) {
        sortedGremlins = Array.from(gremlins.values());
        sortedGremlins.sort((a, b) => multiCompare(a, b, [
            (a, b) => numberCompare(a.priority || 0, b.priority || 0),
            (a, b) => reverse(numberCompare(b.counter, a.counter))
        ]));
    }
    log(`attack order: ${sortedGremlins.map(x => x.name).join(', ')} `);
    for (const entry of sortedGremlins) {
        log(`try ${entry.name || 'gremlin'}`);
        const result = await entry.action();
        if (result) {
            log(`Gremlin on ${entry.name || 'thingy'} attacked`);
            return;
        }
    }
}
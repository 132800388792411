import * as ko from 'knockout';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';

export const WIDGET_NAME = 'ui-superuser-golemscriptedit';

export interface IParamsInner {
    golemScriptDocRefId: string;
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class GolemScriptEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return this._title;
    });

    constructor(readonly widgetParams: IParamsInner, readonly _title: string) {
    }

}


export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/superuser/golemscript/:id',
    toParams: map => ({
        golemScriptDocRefId: map.get('id'),
    }),
    fromParams: (p, map) => {
        map.set('id', p.golemScriptDocRefId);
    },
    create: async p => {
        const q = await ServerConnection.api.ui_superuser_golemscriptedit_route({
            docRef: p.golemScriptDocRefId
        });

        const route = new GolemScriptEditRoute(p, q.golemScripts.byId.golemScriptId);
        return route;
    },
});

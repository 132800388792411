import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import STABLE_STRINGIFY from 'json-stable-stringify';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { writeToClipboard } from '../../../clipboard';
import { dir, log } from '../../../debug';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import { AddMissingAttachments } from '../../../itemdefinition/helper';
import { ButtonVisibility, IUpsertGrade, IUpsertTestDefinitionProfile } from '../../../its-itembank-api.g';
import { xnone } from '../../../model/languagemap';
import { registerOnUpdateToolbar } from '../../../ui/toptoolbar.service';
import { UIAction } from '../../../ui/uiAction';
import { ServerConnection } from '../../RestAPI';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { MyFormBuilder, PROFILE, Q } from './MyFormBuilder';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

export const GRADE_CONDITION_REGEX = /^([\[\(])([0-9]+)([%P]),([0-9]+)([%P])([\]\)])$/;

export class ViewModel extends DxWidget {
    private readonly formData: PROFILE = <any>{};
    private attachmentsStore: DevExpress.data.Store;

    public readonly helpVisible = ko.observable(false);
    private readonly helpVisibleChanged = ko.pureComputed(() => this.helpVisible()).extend({
        rateLimit: {
            method: 'notifyWhenChangesStop',
            timeout: 500,
        }
    });

    private readonly _grades: PROFILE['grades'] = [];

    public readonly form = new MyFormBuilder(this);

    private readonly changeCounter = ko.observable(0);
    public dirty() {
        this.changeCounter(this.changeCounter() + 1);
    }


    private upsertCodeButton({ visibility, label }: { visibility: ButtonVisibility, label: { value: string } }) {
        return {
            visibility,
            label: xnone(label.value),
        };
    }
    public readonly upsertCode = ko.pureComputed(() => {
        //call changeCounter() to detect changes
        const change = this.changeCounter();
        const retVal: IUpsertTestDefinitionProfile = {
            id: this.formData.id,
            docRef: this.profile,
            title: this.formData.title,
            isDeleted: this.formData.isDeleted,
            continueWith: this.formData.continueWith,
            continuationMode: this.formData.continuationMode,
            examinationType: this.formData.examinationType,
            itemHost: this.formData.itemHost,
            maxTimeAllowed: this.formData.maxTimeAllowed,
            navigationStyle: this.formData.navigationStyle,
            resetOnNavigate: this.formData.resetOnNavigate,
            scoreRoundingMode: this.formData.scoreRoundingMode,
            setGrades: this._grades.map(e => AS<IUpsertGrade>({
                id: e.letter,
                letter: e.letter,
                condition: e.condition,
                text: xnone(e.text && e.text.value),
                passed: e.passed

            })),
            pdfReportSettings: {
                itemHeader: xnone(this.formData.pdfReportSettings.itemHeader.value),
                itemText: xnone(this.formData.pdfReportSettings.itemText.value),
                objectiveHeader: xnone(this.formData.pdfReportSettings.objectiveHeader.value),
                itemDetailCaption: xnone(this.formData.pdfReportSettings.itemDetailCaption.value),
                objectiveMaxDepth: this.formData.pdfReportSettings.objectiveMaxDepth,
                objectiveMinDepth: this.formData.pdfReportSettings.objectiveMinDepth,
                displayItemId: this.formData.pdfReportSettings.displayItemId,
                displayObjectiveId: this.formData.pdfReportSettings.displayObjectiveId,
                objectiveText: xnone(this.formData.pdfReportSettings.objectiveText.value),
                text: xnone(this.formData.pdfReportSettings.text.value),
                emailSubject: xnone(this.formData.pdfReportSettings.emailSubject.value),
                emailBody: xnone(this.formData.pdfReportSettings.emailBody.value),
                pageHeader: xnone(this.formData.pdfReportSettings.pageHeader.value),
                pageFooter: xnone(this.formData.pdfReportSettings.pageFooter.value),
                includeItems: this.formData.pdfReportSettings.includeItems,
            },
            welcomePage: {
                text: xnone(this.formData.welcomePage.text.value),
                startButton: this.upsertCodeButton(this.formData.welcomePage.startButton),
                cancelButton: this.upsertCodeButton(this.formData.welcomePage.cancelButton),
            },
            executionPage: {
                displayTime: this.formData.executionPage.displayTime,
                displayProgress: this.formData.executionPage.displayProgress,
                minWidth: this.formData.executionPage.minWidth,
                minHeight: this.formData.executionPage.minHeight,
                submitButton: this.upsertCodeButton(this.formData.executionPage.submitButton),
                postponeButton: this.upsertCodeButton(this.formData.executionPage.postponeButton),
                finishButton: this.upsertCodeButton(this.formData.executionPage.finishButton),
                previousButton: this.upsertCodeButton(this.formData.executionPage.previousButton),
                nextButton: this.upsertCodeButton(this.formData.executionPage.nextButton),
                overviewButton: this.upsertCodeButton(this.formData.executionPage.overviewButton),
                resetButton: this.upsertCodeButton(this.formData.executionPage.resetButton),
                showImmediateResult: this.formData.executionPage.showImmediateResult,
                showItemMaxScore: this.formData.executionPage.showItemMaxScore,
                immediateResultText: xnone(this.formData.executionPage.immediateResultText.value),
                immediateResultSeconds: this.formData.executionPage.immediateResultSeconds,
                timeLabel: xnone(this.formData.executionPage.timeLabel.value),
                progressLabel: xnone(this.formData.executionPage.progressLabel.value),
            },
            resultPage: {
                closeButton: this.upsertCodeButton(this.formData.resultPage.closeButton),
                itemHeader: xnone(this.formData.resultPage.itemHeader.value),
                itemText: xnone(this.formData.resultPage.itemText.value),
                displayItemId: this.formData.resultPage.displayItemId,
                displayObjectiveId: this.formData.resultPage.displayObjectiveId,
                objectiveHeader: xnone(this.formData.resultPage.objectiveHeader.value),
                objectiveMaxDepth: this.formData.resultPage.objectiveMaxDepth,
                objectiveMinDepth: this.formData.resultPage.objectiveMinDepth,
                objectiveText: xnone(this.formData.resultPage.objectiveText.value),
                text: xnone(this.formData.resultPage.text.value),
                pdfButton: this.upsertCodeButton(this.formData.resultPage.pdfButton),
                emailLabel: xnone(this.formData.resultPage.emailLabel.value),
                emailText: xnone(this.formData.resultPage.emailText.value),
                pdf: this.formData.resultPage.pdf,
                email: this.formData.resultPage.email,
            },
            revisableItemCount: this.formData.revisableItemCount,
        };
        return retVal;
    });

    public readonly upsertCodeJson = ko.pureComputed(() => {
        const r = this.upsertCode();
        return STABLE_STRINGIFY(r, { space: 2 });
    });

    private readonly lastSaved = ko.observable('');
    public readonly needsSave = ko.pureComputed(() => {
        return this.lastSaved() !== this.upsertCodeJson();
    });

    public readonly gradeGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                noDataText: i18next.t(['ui.superuser.profileedit.THERE_ARE_NO_GRADES_DEFINED']),
                allowColumnResizing: true,

                dataSource: {
                    store: {
                        type: 'array',
                        key: 'letter',
                        data: this._grades
                    }
                },
                editing: {
                    allowAdding: true,
                    allowDeleting: true,
                    allowUpdating: true,
                    mode: 'row',
                },
                columns: []
            }
        });
        retVal.columns.push(
            {
                dataField: 'letter',
                caption: i18next.t(['ui.superuser.profileedit.LETTER']),
                validationRules: [{
                    type: 'pattern',
                    pattern: /^[0-9A-Z]$/,
                    message: i18next.t(['ui.superuser.profileedit.MUST_BE_A_NUMBER_OR_A_LETTER_E_G_1_A_B_F'])
                }],
                width: 50,
                allowEditing: true
            });
        retVal.columns.push(
            {
                dataField: 'text.value',
                caption: i18next.t(['ui.superuser.profileedit.TEXT']),
                width: 100,
                allowEditing: true
            });
        retVal.columns.push(
            {
                dataField: 'condition',
                caption: i18next.t(['ui.superuser.profileedit.CONDITION']),
                width: 200,
                validationRules: [{
                    type: 'pattern',
                    pattern: GRADE_CONDITION_REGEX,
                    message: i18next.t(['ui.superuser.profileedit.YOU_MUST_DEFINE_A_RANGE_LIKE_0_50_OR_50_100_OR_0P_10P_MEANS_INCLUDING_THE_VALUE_EXCLUDES_THE_VALUE_THE_NUMBER_CAN_BE_OR_POINTS_P'])
                }],
                dataType: 'string',
                allowEditing: true,
            });
        retVal.columns.push(
            {
                dataField: 'passed',
                caption: i18next.t(['ui.superuser.profileedit.PASSED']),
                width: 50,
                dataType: 'boolean',
                allowEditing: true,
            });
        retVal.onInitNewRow = e => {
            e.data.letter = 'A';
            e.data.passed = true;
        };
        retVal.onRowInserted = e => {
            this.dirty();
        };

        retVal.onRowUpdated = e => {
            this.dirty();
        };
        retVal.onRowRemoved = (e) => {
            this.dirty();
        };

        return retVal;
    });

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        this.form.formOptions.onFieldDataChanged = x => {
            this.dirty();
        };
    }

    public readonly actionCopyUpsert = new UIAction(undefined, async (e, args) => {
        await writeToClipboard(this.upsertCodeJson());
    });

    public readonly loaded = ko.observable(false);

    private readonly profile: string;

    constructor(readonly params: IParams) {
        super();
        this.profile = this.params.currentRoute.widgetParams.profile;
    }
    public readonly tabs = ko.pureComputed(() => {
        const items: DevExpress.ui.dxTabPanel.Item[] = [];
        const retVal: DevExpress.ui.dxTabPanel.Properties = {
            dataSource: items,
            deferRendering: true,
            height: '100%',
            onSelectionChanged: e => {
                log('itemedit tab: selection changed');
                dir(e);
            }

        };
        items.push({
            title: i18next.t(['ui.superuser.profileedit.DATA']),
            template: () => {
                const x = $(`
                <div style="padding:1em;height:100%;overflow-y:auto" data-bind="dxForm:form.formOptions">
            </div>
                `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: i18next.t(['ui.superuser.profileedit.ASSETS']),
            template: () => {
                const x = $(`<div data-bind="component:{name:'widgets-assetmanager',params:{docRef:profile,docType:'testdefinitionprofile'}}"></div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            }
        });

        return retVal;
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const config = await ServerConnection.api.ui_superuser_profileedit_data({
            profile: this.profile,
        });
        this._attachments.splice(0, this._attachments.length, ...config.documents.get.attachments);
        this._grades.splice(0, this._grades.length, ...config.TestDefinitionProfile.byId.grades);
        Object.assign(this.formData, config.TestDefinitionProfile.byId);
        AddMissingAttachments(this._attachments, [
            //this.formData.image && this.formData.image.name
        ]);
        await refreshDx(this);
        this.lastSaved(this.upsertCodeJson());

    }
    private readonly _attachments: Q['documents']['get']['attachments'] = [];

    public async initialize() {
        this.attachmentsStore = new CustomStore({
            loadMode: 'raw',
            key: 'name',
            byKey: async key => {
                if (!this._attachments) {
                    return undefined;
                }
                return this._attachments.find(x => x.name === key);
            },
            load: async (options) => {
                return this._attachments;
            }
        });

        await super.initialize();
        this.initFormOptions();
        await this.OnRefresh();

        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.disposables.addDiposable(this.helpVisibleChanged.subscribe(x => {
            this.triggerResize();
        }));
        this.onChange(this.upsertCode, `${WIDGET_NAME}/${this.profile}/upsert`, async v => {
            if (this.needsSave()) {
                log(`save profile: ${v}`);
                await ServerConnection.api.ui_superuser_profileedit_update({
                    params: v
                });
            }
            return DONE;
        });

        this.loaded(true);
    }

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItem>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'help',
                text: i18next.t(['ui.author.itemedit.HELP']),
                onClick: this.toggleHelpAction.click,
            })
        }));

        toolbar.items.push(AS<DevExpress.ui.dxToolbarItem>({
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'always',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'far fa-copy',
                text: i18n.t(['itemdefinition.multistephotspot.edit.COPY_DEFINITION_TO_CLIPBOARD']),
                onClick: this.actionCopyUpsert.click
            })
        }));
        return DONE;
    }

    private readonly toggleHelpAction = new UIAction(undefined, async () => {
        this.helpVisible(!this.helpVisible());
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
import { RestAPIBase } from "./RestAPIBase";

export class RestAPI extends RestAPIBase {
    constructor(dataURL: string) {
        super(dataURL, {});
    }


    public getExportUrl(docType: string, docRefId: string) {
        let retVal = `actions/export/${docType}/${docRefId}`;
        let query = '';
        if (this.apiKeyValue) {
            query += '&browserSessionId=' + this.apiKeyValue;
        }
        if (query !== '') {
            retVal += '?' + query;
        }
        return this.getDataUrl(retVal);
    }
    public getAttachmentUrl({ docId, attachmentId, revpos, convertTo, encoding, preventCache }: { docId: string, attachmentId: string, revpos?: number, convertTo?: string, encoding?: string, preventCache?: boolean }) {
        let retVal = 'attachment/' + docId + '/' + attachmentId;
        let query = '';
        if (revpos) {
            query += '&revpos=' + revpos;
        }
        if (this.apiKeyValue) {
            query += '&browserSessionId=' + this.apiKeyValue;
        }
        if (convertTo) {
            query += '&convertTo=' + encodeURIComponent(convertTo);
        }
        if (encoding) {
            query += '&encoding=' + encodeURIComponent(encoding);
        }
        if (preventCache) {
            query += '&nonce=' + encodeURIComponent((new Date()).getTime());
        }
        if (query !== '') {
            retVal += '?' + query;
        }
        return this.getDataUrl(retVal);
    }
    public getLaunchDemoExamUrl(examId: string, onclose: 'back' | 'blank' | 'close') {
        return this.getDataUrl(`launchDemoExam/${examId}/?onclose=${onclose}`);
    }
}

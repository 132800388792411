import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { FormBuilder, HasItems } from '../../../dxHelper/formBuilder';
import * as TRANSLATIONS from '../../../enums/translations';
import { assertNever } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';

export type Q = Awaited<ReturnType<API.Sdk['ui_superuser_useredit_init']>>;
export type UPDATE = Awaited<ReturnType<API.Sdk['ui_superuser_useredit_update']>>;
export interface FORMDATA {
    _id: string;
    secret: string;
    displayName: string;
    initials: string;
    userId: string;
    mobilephone: string;

    subjectPrivileges: { [key: string]: string[] };
    localizationPrivileges: { [key: string]: string[] };
    contextlessPrivileges: { [key: string]: boolean };
}

export type SUBJECT = Q['subjects'][0];
type U = Omit<Omit<API.IUpsertUserInput, "userId">, "userDocRef"> | Pick<API.IUpsertUserInput, "userId">;

export const privilege_order: API.Privilege[] = [
    API.Privilege.AuthorSubjects,
    API.Privilege.EvaluatorSubjects,
    API.Privilege.SubjectcoordinatorSubjects,
    API.Privilege.TranslatorSubjects,
    API.Privilege.TranslatorLocalizations,
    API.Privilege.Admin,
    API.Privilege.Superuser,
];
export function getPrivilegeType(privilege: API.Privilege): 'basic' | 'subject' | 'localization' {
    switch (privilege) {
        case API.Privilege.AuthorSubjects:
            return 'subject';
        case API.Privilege.EvaluatorSubjects:
            return 'subject';
        case API.Privilege.SubjectcoordinatorSubjects:
            return 'subject';
        case API.Privilege.TranslatorSubjects:
            return 'subject';
        case API.Privilege.TranslatorLocalizations:
            return 'localization';
        case API.Privilege.Admin:
            return 'basic';
        case API.Privilege.Superuser:
            return 'basic';
        default:
            assertNever(privilege);
    }
}
export class MyFormBuilder extends FormBuilder<FORMDATA, U> {
    constructor(readonly vm: {
        isPasswordVisible: ko.Observable<boolean>;
        subjectsTagBoxOptions: ko.Subscribable<DevExpress.ui.dxTagBox.Properties>;
        localizationsTagBoxOptions: ko.Subscribable<DevExpress.ui.dxTagBox.Properties>;
        allSubjects: SUBJECT[],
        forms: Set<DevExpress.ui.dxForm>
    }) {
        super();
    }
    protected onInit() {
        super.onInit();
        this.formOptions.colCount = 1;
        this.formOptions.width = '100%';

        this.initUserData();
        this.initPassword();
        this.initRoles();
        this.initMetadata();
    }

    private initMetadata() {
        const g4 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.useredit.myformbuilder.SYSTEM']),
            colCount: 2,
        });
        this.addCheckbox(g4, {
            dataField: 'isDeleted',
            editorOptions: {
            },
            label: {
                text: i18next.t(['ui.superuser.useredit.myformbuilder.DEACTIVATE_USER'])
            }
        }, isDeleted => ({ isDeleted }));
        this.addTextBox(g4, {
            dataField: '_id',
            editorOptions: {
                readOnly: true,
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
            },
            label: {
                text: i18next.t(['ui.superuser.useredit.myformbuilder.INTERNAL_ID'])
            }
        });
    }

    private getSubjectIds(docRefs: string[]): string[] {
        return (docRefs || []).map(x => this.vm.allSubjects.find(y => y.docReferenceId === x).subjectId);
    }

    private updateRoleSubjects(role: API.Privilege, subjectDocRefs: string[]): U {

        const subjects = this.getSubjectIds(subjectDocRefs);
        if (!subjects.length) {
            return {
                removePrivileges: [role]
            };
        } else {
            return {
                addPrivileges: [{
                    privilege: role,
                    setContext: subjects
                }]
            };
        }

    }
    private updateRoleLocalizations(role: API.Privilege, localizationIds: string[]): U {

        const data = localizationIds;
        if (!data.length) {
            return {
                removePrivileges: [role]
            };
        } else {
            return {
                addPrivileges: [{
                    privilege: role,
                    setContext: data
                }]
            };
        }

    }

    private addSubjectPrivilge(g3: HasItems, privilege: API.Privilege) {
        this.addTagBox(g3, {
            label: {
                text: i18next.t(TRANSLATIONS.UserRoleString[privilege]),
            },
            dataField: `subjectPrivileges.${privilege}`,
            editorOptions: this.vm.subjectsTagBoxOptions()

        }, val => this.updateRoleSubjects(privilege, val));
    }

    private addContextlessPrivilge(g3: HasItems, privilege: API.Privilege) {
        this.addCheckbox(g3, {
            label: {
                text: i18next.t(TRANSLATIONS.UserRoleString[privilege]),
            },
            dataField: `contextlessPrivileges.${privilege}`,
            editorOptions: {
            }
        }, val => {
            if (val) {
                return {
                    addPrivileges: [{
                        privilege: privilege
                    }]
                };
            } else {
                return {
                    removePrivileges: [privilege]
                };
            }
        });
    }
    private addLocalizationPrivilge(g3: HasItems, privilege: API.Privilege) {
        this.addTagBox(g3, {
            label: {
                text: i18next.t(TRANSLATIONS.UserRoleString[privilege]),
            },
            dataField: `localizationPrivileges.${privilege}`,
            editorOptions: this.vm.localizationsTagBoxOptions()
        }, val => this.updateRoleLocalizations(privilege, val));
    }
    private initRoles() {

        const g3 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.useredit.myformbuilder.ROLE_OF_THE_USER']),
            colCount: 3,

        });

        for (const p of privilege_order) {
            switch (getPrivilegeType(p)) {
                case 'basic':
                    this.addContextlessPrivilge(g3, p);
                    break;
                case 'subject':
                    this.addSubjectPrivilge(g3, p);
                    break;
                case 'localization':
                    this.addLocalizationPrivilge(g3, API.Privilege.TranslatorLocalizations);
                    break;
            }
        }
    }
    private readonly passwordMode = ko.pureComputed(() => {
        return this.vm.isPasswordVisible() ? 'text' : 'password';
    });

    private updatePasswordVisible() {
        for (const form of Array.from(this.vm.forms)) {
            const editor = form.getEditor('secret');
            if (editor) {
                editor.option('mode', this.passwordMode());
            }
        }
    }

    private initPassword() {
        const g2 = this.addGroup(this.formOptions, {
            colCount: 3,
            caption: i18next.t(['ui.superuser.useredit.myformbuilder.PASSWORD']),
        });

        this.addTextBox(g2, {
            dataField: 'secret',
            editorOptions: {
                spellcheck: false,
                inputAttr: {
                    'autocomplete': 'off',
                },
                mode: this.passwordMode(),
                buttons: [{
                    location: 'after',
                    name: 'togglemode',
                    options: {
                        icon: 'key',
                        onClick: () => {
                            this.vm.isPasswordVisible(!this.vm.isPasswordVisible());
                            this.updatePasswordVisible();
                        }
                    }
                }, {
                    location: 'after',
                    name: 'resetpassword',
                    options: {
                        icon: 'refresh',
                        onClick: async () => {
                            this.vm.isPasswordVisible(true);
                            this.updatePasswordVisible();
                            const pwd = await ServerConnection.api.ui_superuser_useredit_generatepassword({});
                            for (const form of Array.from(this.vm.forms)) {
                                form.updateData('secret', pwd.generatePassword);
                            }
                        }
                    }
                }]
            },
            label: {
                text: i18next.t(['ui.superuser.useredit.myformbuilder.PASSWORD'])
            }
        }, val => ({ password: val }));
    }
    private initUserData() {
        const g1 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.useredit.myformbuilder.USER_DATA']),
            colCount: 3,
        });
        this.addTextBox(g1, {
            dataField: 'displayName',
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.useredit.myformbuilder.NAME_JOHN_DOE'])
            },
            label: {
                text: i18next.t(['ui.superuser.useredit.myformbuilder.FIRST_AND_LAST_NAME'])
            }
        }, displayName => ({ displayName }));
        this.addTextBox(g1, {
            dataField: 'initials',
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.useredit.myformbuilder.DEFINE_A_SHORCUT'])
            },
            label: {
                text: i18next.t(['ui.superuser.useredit.myformbuilder.SHORTCUT'])
            },
            validationRules: [
                {
                    type: 'required',
                    message: i18next.t(['ui.superuser.useredit.myformbuilder.A_SHORTCUT_IS_REQUIRED'])
                },
                {
                    type: 'pattern',
                    pattern: '^[A-Z]+$',
                    message: i18next.t(['ui.superuser.useredit.myformbuilder.THE_SHORTCUT_CAN_ONLY_CONSIST_OF_UPPERCASE_LETTERS'])
                }
            ]
        }, initials => ({ initials }));
        this.addTextBox(g1, {
            dataField: 'userId',
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.useredit.myformbuilder.DEFINE_A_USERNAME'])
            },
            label: {
                text: i18next.t(['ui.superuser.useredit.myformbuilder.USER_NAME'])
            },
            validationRules: [
                {
                    type: 'required',
                    message: i18next.t(['ui.superuser.useredit.myformbuilder.A_USERNAME_IS_REQUIRED'])
                }
            ]
        }, userId => ({ userId }));
        this.addTextBox(g1, {
            dataField: 'mobilephone',
            label: {
                text: i18next.t(['ui.superuser.useredit.myformbuilder.MOBILE_PHONE'])
            },
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.useredit.myformbuilder.INSERT_THE_MOBILE_PHONE_NUMBER_HERE'])
            }
        }, mobilephone => ({ mobilephone }));
    }
}
export const id = 'organisation';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'ee670973-5cbf-4195-aaa7-359551688b80',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

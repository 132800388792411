import { log } from '../../debug';
import { IUpdateExamStateInput } from '../../its-itembank-api.g';
import { ServerConnection } from '../../ui/RestAPI';
import { ItemModel } from './ItemModel';
import { ModelFactory } from './ModelFactory';
import { SessionBase } from './sessionbase';

const factory = new ModelFactory();

export class Session extends SessionBase {
    constructor(sessionId: string, readonly localizationId: string[]) {
        super(sessionId);
    }
    private readonly _items = new Map<string, ItemModel>();

    public getItemData(itemId: string) {
        return this.GetItemModel(itemId).data;
    }
    public GetItemModel(itemId: string) {
        return this._items.get(itemId);
    }
    public GetItemModels() {
        return Array.from(this._items.values());
    }
    public async _init() {
        const normalizedLocalizationId = (this.localizationId || []).filter(x => !!x);
        const r = await ServerConnection.api.item_model_init({
            sessionId: this.sessionId,
            localizationId: normalizedLocalizationId.length ? normalizedLocalizationId : undefined,
        });
        for (const item of r.examSession.items) {
            if (!this._items.has(item.itemId)) {
                const model = new ItemModel(this, item);
                const data = factory.create(model, item);
                model.data = data;
                this._items.set(item.itemId, model);
            }
            this._items.get(item.itemId).fromJS(item);
        }
    }

    public GetExamState() {
        const retVal: IUpdateExamStateInput = {
            submittedItems: [],
            itemState: [],
        };
        for (const model of Array.from(this._items.values())) {
            retVal.itemState.push(model.data.getItemState());
        }
        return retVal;
    }

}

let singleton: Session;

export function ClearSession() {
    singleton = undefined;
    log('Clear Session Called');
}
export async function InitSession(sessionId: string, localizationId: string[]) {
    if (singleton) {
        if (singleton.sessionId !== sessionId) {
            throw new Error(`InitSession was already called with session id ${singleton.sessionId} (new: ${sessionId})`);
        }
    } else {
        log(`Init Session Called ${sessionId} ${localizationId}`);
        singleton = new Session(sessionId, localizationId);
        await singleton._init();
    }
}
export function HasSession(sessionId: string) {
    if (!singleton) {
        return false;
    }
    return singleton.sessionId === sessionId;
}
export function GetSession(sessionId: string) {
    if (!singleton) {
        throw new Error(`InitSession was not called yet`);
    }
    if (singleton.sessionId !== sessionId) {
        throw new Error(`InitSession was called with a different session id`);
    }
    return singleton;
}
export class OnceManager {
    private readonly _data = new Set<string>();
    public clear() {
        this._data.clear();
    }
    public do<T>(name: string, cb: () => T): T {
        if (this._data.has(name)) {
            return undefined;
        }
        this._data.add(name);
        return cb();
    }
}

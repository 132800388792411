export const id = 'invisiblehotspot';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'b8aa2533-1a0e-4f43-8104-00dd2525314a',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { selectBoxDS } from '../../../dx_helper';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import * as API from '../../../its-itembank-api.g';
import * as DS from './../../../enums/datasource';
import * as i18next from './../../../i18n/i18n';
import { AlternationsStore } from './AlternationsStore';
export type Q = Awaited<ReturnType<API.Sdk['ui_superuser_examorderedit_data']>>;
export type FORMDATA = Q['examOrder']['byId'] & {
    alternations_array: string[];
    status_translated: string;
    max_time_allowed_minutes: number | '∞';

};

export type PROFILE = Q['TestDefinitionProfile']['all'][0];
export type AUDIENCE = Q['audiences']['all'][0];
type U = Omit<Omit<Omit<API.IUpsertExamOrderInput, "id">, "docRef">, "examOrderId"> | Partial<Pick<API.IUpsertExamOrderInput, "examOrderId">>;

export class MyFormBuilder extends FormBuilder<Q['examOrder']['byId'], U> {
    constructor(readonly vm: {
        editableBySuperUser: ko.Subscribable<boolean>,
        levelOfSecrecyEnabled: ko.Subscribable<boolean>,
        _profiles: PROFILE[],
        _allAudiences: AUDIENCE[],
        alternationsStore: AlternationsStore,
        gridOptions: () => DevExpress.ui.dxDataGrid.Properties
    }) {
        super();
    }
    protected onInit() {
        super.onInit();
        this.formOptions.colCount = 1;
        this.formOptions.width = '100%';

        this.initExamOrderData();
        this.initDetails();
        this.initSubjects();
        this.initMetadata();
    }

    private initMetadata() {
        const g = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.examorderedit.myformbuilder.SYSTEM']),
            colCount: 2,
        });

        this.addCheckbox(g, {
            dataField: 'isDeleted',
            editorOptions: {
                readOnly: false
            },
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.DEACTIVATE_EXAM_ORDER'])
            }
        }, val => ({ isDeleted: val }));

        this.addTextBox(g, {
            dataField: 'status_translated',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.STATUS'])
            }
        });

        this.addDateBox(g, {
            dataField: 'dateCreated',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.CREATED_AT'])
            },
            editorOptions: {
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
                type: 'datetime',
            }
        });

        this.addTextBox(g, {
            dataField: '_id',
            editorOptions: {
                readOnly: true,
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
            },
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.INTERNAL_ID'])
            }
        });
    }
    private readonly ro = ko.pureComputed(() => {
        return !this.vm.editableBySuperUser();
    });

    private initSubjects() {
        const g1 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.examorderedit.myformbuilder.SELECT_THE_SCORE_AND_LANGUAGES_FOR_EACH_SUBJECT'])
        });
        this.addDataGrid(g1, {
            editorOptions: this.vm.gridOptions()
        });
    }
    private initExamOrderData() {
        const g1 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.examorderedit.myformbuilder.EXAM_ORDER_DATA']),
            colCount: 2,
        });

        this.addTextBox(g1, {
            dataField: 'title',
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.examorderedit.myformbuilder.ENTER_THE_NAME_HERE']),
                readOnly: <any>this.ro,
            },
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.NAME_OF_EXAM_ORDER'])
            }
        }, val => ({ title: val }));

        this.addTextBox(g1, {
            dataField: 'examinationOrderId',
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.examorderedit.myformbuilder.ENTER_THE_SHORTCUT_HERE']),
                readOnly: <any>this.ro,
            },
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.SHORTCUT'])
            },
            validationRules: [
                {
                    type: 'required',
                    message: i18next.t(['ui.superuser.examorderedit.myformbuilder.A_SHORTCUT_IS_REQUIRED'])
                },
                {
                    type: 'pattern',
                    pattern: '^[A-Z,0-9]+$',
                    message: i18next.t(['ui.superuser.examorderedit.myformbuilder.THE_SHORTCUT_CAN_ONLY_CONSIST_OF_UPPERCASE_LETTERS_AND_DIGITS'])
                }
            ]
        }, val => ({ examOrderId: val }));

    }
    private initDetails() {


        const gDetails = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.examorderedit.myformbuilder.DETAILS']),
            colCount: 4,
            items: [],
        });


        this.addNumberBox(gDetails, {
            dataField: 'scoreMax',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.TOTAL_SCORE'])
            },
            editorOptions: {
                readOnly: <any>this.ro,
                min: 0,
            }
        }, val => ({ score: val }));

        this.addNumberBox(gDetails, {
            dataField: 'itemCount',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.AMOUNT_OF_ITEMS'])
            },
        });

        this.addTextBox(gDetails, {
            dataField: 'examDate',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.DATE_OF_EXAM'])
            },
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.examorderedit.myformbuilder.YYYY_MM_DD']),
                readOnly: <any>this.ro,
            }
        }, val => ({ examDate: val }));
        this.addTextBox(gDetails, {
            dataField: 'examTime',
            //editorType: 'dxDateBox',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.START_TIME'])
            },

            editorOptions: {
                readOnly: <any>this.ro,
                placeholder: 'hh:mm'
            }
        }, val => ({ examTime: val }));

        let max_time_allowed_minutes: DevExpress.ui.dxNumberBox;
        this.addNumberBox(gDetails, {
            dataField: 'max_time_allowed_minutes',
            //editorType: 'dxDateBox',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.EXAM_DURATION'])
            },

            editorOptions: {
                readOnly: <any>this.ro,
                placeholder: '60 - 0=∞',
                onInitialized: e => max_time_allowed_minutes = e.component,
                onDisposing: e => {
                    if (max_time_allowed_minutes === e.component) {
                        max_time_allowed_minutes = undefined;
                    }
                },
                min: 0,
                buttons: [
                    'clear',
                    {
                        name: '∞',
                        location: 'after',
                        options: {
                            text: '∞',
                            onClick: e => {
                                if (max_time_allowed_minutes) {
                                    max_time_allowed_minutes.option('value', 0);
                                }
                            }
                        }
                    }]
            }
        }, val => {
            if (isFinite(val) && val > 0) {
                return { maxTimeAllowed: `PT${Math.round(val)}M` };
            } else {
                return { maxTimeAllowed: '∞' };
            }
        });
        /*
        this.addTextBox(gDetails, {
            dataField: 'maxTimeAllowed',
            //editorType: 'dxDateBox',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.EXAM_DURATION'])
            },

            editorOptions: {
                readOnly: <any>this.ro,
                placeholder: 'PT60M'
            }
        }, val => ({ maxTimeAllowed: val }));
        */
        this.addSelectBox<API.LevelOfSecrecy>(gDetails, {
            dataField: 'levelOfSecrecy',
            visible: <any>this.vm.levelOfSecrecyEnabled,
            editorOptions: Object.assign(selectBoxDS(DS.LevelOfSecrecyString()), {
                readOnly: <any>this.ro,
                searchEnabled: false,
            }),
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.LEVEL_OF_SECRECY'])
            }
        }, val => ({ levelOfSecrecy: val }));

        this.addLookup(gDetails, {
            dataField: 'profile.docReferenceId',
            editorOptions: {
                //showPopupTitle: false,
                readOnly: <any>this.ro,
                searchEnabled: false,
                dataSource: this.vm._profiles,
                displayExpr: 'title',
                valueExpr: 'docReferenceId'
            },
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.PROFILE'])
            }
        }, val => ({ profile: val }));

        this.addLookup(gDetails, {
            dataField: 'audience.docReferenceId',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.AUDIENCE'])
            },
            editorOptions: {
                //showPopupTitle: false,
                readOnly: <any>this.ro,
                dataSource: this.vm._allAudiences,
                displayExpr: 'audienceId',
                valueExpr: 'docReferenceId',
                placeholder: i18next.t(['ui.superuser.examorderedit.myformbuilder.SELECT'])
            }
        }, val => ({ audience: val }));
        this.addNumberBox(gDetails, {
            dataField: 'syllabusDepth',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.LEVEL_FOR_DIVISION_OF_POINTS_SYLLABUS'])
            },
            editorOptions: {
                readOnly: <any>this.ro,
                min: 0,
                max: 5,
                mode: 'number'
            }
        }, val => ({ syllabusDepth: val }));
        this.addNumberBox(gDetails, {
            dataField: 'categoryDepth',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.LEVEL_FOR_DIVISION_OF_POINTS_CATEGORY'])
            },
            editorOptions: {
                readOnly: <any>this.ro,
                min: 0,
                max: 5,
                mode: 'number'
            }
        }, val => ({ categoryDepth: val }));

        this.addTagBox(gDetails, {
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.ALIASES'])
            },
            editorOptions: {
                placeholder: i18next.t(['ui.superuser.examorderedit.myformbuilder.INPUT_CONFIRM_WITH_ENTER']),
                readOnly: <any>this.ro,
                acceptCustomValue: true,
                showDropDownButton: false,
                multiline: true,
                openOnFieldClick: false,
            },
            dataField: 'aliases',
        }, val => ({ setAliases: val }));

        this.addSelectBox<API.ItemOrderStrategy>(gDetails, {
            dataField: 'itemOrderStrategy',
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.ITEM_ORDER_STRATEGY'])
            },
            editorOptions: Object.assign(
                selectBoxDS(DS.ItemOrderStrategy()),
                {
                    readOnly: <any>this.ro,
                })
        }, val => ({ itemOrderStrategy: val }));

        this.addSelectBox<API.ExaminationOrderDefineScore>(gDetails, {
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.WHO_CAN_SET_SCORE'])
            },
            dataField: 'defineScore',
            editorOptions: Object.assign(
                selectBoxDS(DS.ExaminationOrderDefineScore()),
                {
                    readOnly: <any>this.ro,
                })
        }, val => ({ defineScore: val }));

        this.addTreeList(gDetails, {
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.ALTERNATIONS']),
            },
            dataField: 'alternations_array',
            editorOptions: {
                readOnly: <any>this.ro,
                dataSource: {
                    store: this.vm.alternationsStore,
                },
                displayExpr: 'display',
                valueExpr: 'id',
                dropDownOptions: {
                    height: 'auto',
                },
            }
        },
            {
                columns: [
                    {
                        caption: i18next.t(['ui.superuser.examorderedit.myformbuilder.ID']),
                        dataField: 'id',
                        width: '150'
                    },
                    {
                        caption: i18next.t(['ui.superuser.examorderedit.myformbuilder.NAME']),
                        dataField: 'display'
                    }
                ],
            },

            val => ({ setAlternations: val })
        );

        this.addSelectBox<API.ScoreRoundingModeEnum>(gDetails, {
            label: {
                text: i18next.t(['ui.superuser.examorderedit.myformbuilder.SCORE_ROUNDING_MODE']),
            },
            editorOptions: Object.assign(selectBoxDS(DS.ScoreRoundingMode()), {
                readOnly: <any>this.ro,
                searchEnabled: false,
            }),
            dataField: 'scoreRoundingMode',
        }, val => ({ scoreRoundingMode: val }));

    }
}
import * as ko from 'knockout';
import { log } from '../../../debug';
import { getGolemData } from '../../../Gremlin';
import * as HELPER from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry } from '../../../new_array';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { EssayData } from '../../model/essay/EssayData';
import { GetSession } from '../../model/session';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './essay.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-essay';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

const GREMLIN_TEXT = ['Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'];

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly data: EssayData;
    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof EssayData)) {
            throw new Error();
        }
        this.data = data;

    }


    private gremlinState: {
        text: string;
    };
    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (!this.gremlinState) {
            const golemData = getGolemData(this.itemId);
            let text = '';
            if (typeof golemData === 'string') {
                text = golemData;
            } else {
                text = getRandomEntry(GREMLIN_TEXT);
            }
            this.gremlinState = {
                text
            };
        }
        if (!this.gremlinState.text) {
            return false;
        }
        const nextSlice = this.gremlinState.text.substr(0, 5);
        this.gremlinState.text = this.gremlinState.text.substr(5);
        this.text(this.text() + nextSlice);
        return true;
    }
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT' || mode === 'GRADING') {
            throw new Error(`${WIDGET_NAME} - no edit or grading mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);


        this._text(this.data.essayText());

        this.onChange(this._text, `${WIDGET_NAME}/${this.itemId}/text`, async val => {
            await this.data.setAnswer(val);
            return HELPER.DONE;
        }, { triggerRefresh: false });
        this.loaded(true);
    }

    public readonly essayPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.essay.ENTER_THE_TEXT_HERE']);
    });

    private readonly _text = ko.observable<string>().extend({
        rateLimit: {
            method: 'notifyWhenChangesStop',
            timeout: 500
        }
    });

    public readonly text = ko.pureComputed({
        read: () => {
            return this._text();
        },
        write: newValue => {
            this._text(newValue);
        },
        owner: this
    });


    public readonly isTextAreaDisabled = ko.pureComputed(() => {
        const mode = this.mode();

        if (mode !== 'INTERACTIVE') {
            return true;
        }
        return false;
    });

    public readonly wordCountText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.essay.WORDS']);
    });

    public readonly wordCount = ko.pureComputed(() => {
        const text = this._text();
        if (!text) { return 0; }
        return text.trim().split(/\s+/).length;
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public async OnReset() {
        this._text(this.data.essayText());
        return HELPER.DONE;
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

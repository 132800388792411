import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-subjectcoordinator-translation';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
}

export class TranslationRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.translation.TRANSLATION_SELECTION']);
    });

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.subjectcoordinator.translation.YOU_CAN_SELECT_AS_ONE_OR_MORE_EXAM_ORDERS_AND_ONE_OR_MORE_LANGUAGES_FOR_THE_TRANSLATION_AS_YOU_LIKE_GO_TO_THE_EXAM_ORDER_TO_SEE_FOR_WHICH_LANGUAGES_THE_ITEMS_HAVE_TO_BE_TRANSLATED']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.subjectcoordinator.translation.HELP']),
                action: this.showHelp
            }];
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{ subject: string }>({
    route: '/subjectcoordinator/subject/:subject/translation',
    toParams: map => ({
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('subject', p.subject);
    },
    create: async p => {
        const route = new TranslationRoute(
            {
                subjectDocRefId: p.subject,
            });
        return route;
    },
});

import CodeMirror from 'codemirror';
import $ from 'jquery';
import * as ko from 'knockout';
import { dir } from '../../../debug';
import { getGolemData } from '../../../Gremlin';
import { DONE } from '../../../helper';
import * as ISO8601 from '../../../iso8601';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { ParticipationContext } from '../../../ui/service/participation/ParticipationContext';
import { ParticipationService } from '../../../ui/service/participation/service';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { TypingTestData } from '../../model/typingtest/TypingTestData';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';


export type IParams = IItemDefinitionWidgetParams;

const WIDGET_NAME = 'itemdefinition-typingtest-interactive';

function additionalArgs(src: CodeMirror.EditorConfiguration, other: {}) {
    return Object.assign(src, other);
}

function clean(text: string) {
    return text.replace(/\s+/g, ' ');
}

export class ViewModel extends AbstractItemDefinition {
    public itemId: string;
    private templateEditor: CodeMirror.Editor = undefined;
    private inputEditor: CodeMirror.Editor = undefined;
    private readonly ctx: ParticipationContext | undefined = undefined;
    public readonly data: TypingTestData;
    private readonly started = new Date();

    public readonly timePassed = ko.pureComputed(() => {
        const tpSeconds = this.ctx?.tickTock.timePassedSeconds() || 0;
        const res = ISO8601.centisecsToDuration(tpSeconds * 100);
        return res.text;
    });

    constructor(readonly params: IParams, readonly rootElem: HTMLElement) {
        super();

        this.itemId = params.itemId;

        this.ctx = ParticipationService.GetSession(this.params.sessionId);
        const item = GetSession(this.params.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof TypingTestData)) {
            throw new Error();
        }
        this.data = data;

        const elem = <HTMLElement>this.rootElem;
        const templateEditor = $(elem).find(`[data-marker='template']`).get(0);
        const inputEditor = $(elem).find(`[data-marker='input']`).get(0);

        const options: CodeMirror.EditorConfiguration = {
            mode: 'text/html',
            scrollbarStyle: 'overlay',

            //viewportMargin: Infinity,
            lineWrapping: true,
        };
        this.templateEditor = CodeMirror(templateEditor, additionalArgs({
            ...options,

            readOnly: 'nocursor',
            value: 'bla'
        }, {
            styleSelectedText: true,
        }));
        this.inputEditor = CodeMirror(inputEditor, {
            ...options,
            readOnly: false,
            placeholder: 'Geben Sie hier den Text ein.' || '',
            extraKeys: {
                'Ctrl-V': () => { },
                'Ctrl-C': () => { },
                'Ctrl-X': () => { },
                'Enter': () => { },
            },
        });
        this.inputEditor.on('beforeChange', (editor, e) => {
            if (e.origin === 'paste' || e.origin === 'cut') {
                e.cancel();
                return;
            }
        });
        this.inputEditor.on('scroll', editor => {
            const si = this.inputEditor.getScrollInfo();
            this.templateEditor.scrollTo(si.left, si.top);
        });

    }
    public readonly inputText = ko.observable('');

    public readonly typedCharactersText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest.interactive.TYPED_CHARACTERS']);
    });

    public readonly writtenLength = ko.pureComputed(() => {
        return this.inputText().length;
    });

    public readonly loaded = ko.observable(false);

    public readonly output = ko.observable('');

    private gremlinState: {
        autoText: String;
    };

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (!this.gremlinState) {
            const data = getGolemData(this.itemId);
            if (typeof data === 'string') {
                this.gremlinState = {
                    autoText: clean(data),
                };
            } else {
                this.gremlinState = {
                    autoText: clean(this.data.text)
                };
            }
        }

        const toInsert = this.gremlinState.autoText.substr(0, 30);
        this.gremlinState.autoText = this.gremlinState.autoText.substr(30);
        if (toInsert) {
            const doc = this.inputEditor.getDoc();
            doc.setCursor(this.inputEditor.getValue().length);
            this.inputEditor.replaceSelection(toInsert);
            return true;
        }
        return false;

    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();

        this.templateEditor.setValue(this.data.text);
        this.inputEditor.setValue(this.data.typedText());
        this.inputEditor.refresh();
        this.templateEditor.refresh();

        this.inputEditor.on('change', cm => {
            this.inputText(cm.getValue());
        });
        this.inputEditor.on('cursorActivity', editor => {
            const cursor = editor.getDoc().getCursor();
            dir(cursor);
            const doc = this.templateEditor.getDoc();
            const word = this.templateEditor.findWordAt(cursor);
            doc.setSelection(word.anchor, word.head);
        });


        if (this.params.mode === 'INTERACTIVE') {
            this.onChange(this.inputText, `${WIDGET_NAME}/${this.itemId}/text`, async () => {
                await this.data.setAnswer(this.inputText());
                return DONE;
            });
        }
        this.loaded(true);
    }
    dispose() {
        $(this.templateEditor.getWrapperElement()).remove();
        $(this.inputEditor.getWrapperElement()).remove();
        super.dispose();
    }
    public async OnReset() {
        this.inputEditor.setValue(this.data.typedText());
        this.inputEditor.refresh();
        return DONE;
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo.element as HTMLElement);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

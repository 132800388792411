interface IITSR2External {
    browserSessionID?: string,
    exporting?: boolean,
    export?(): void,
    startBulkGrading?(): void
    back?(): void
    ready?(): Promise<void>
}
function getExternal(): IITSR2External {
    if (typeof window !== 'object') {
        return undefined;
    }
    const wnd = <any>window;
    if (typeof wnd.ITSR2_External !== 'object') {
        return undefined;
    }
    return wnd.ITSR2_External;
}

export const ITSR2_External = getExternal();

﻿import * as ko from 'knockout';
import { log } from '../../../debug';
import { getGolemData, isStringArray } from '../../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { TextEntryPoolData, TextEntryPool_Line } from '../../model/textentrypool/TextEntryPoolData';
import { htmlString } from './linetextentry.html.g';

const MYITEMTYPE = 'itemdefinition-lavak-linetextentry';
const WIDGET_NAME = MYITEMTYPE;

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public readonly loaded = ko.observable(false);
    public readonly mode = ko.observable<ItemMode>();
    public readonly itemId: string;
    public readonly sessionId: string;

    constructor(readonly params: IParams) {
        super();
        this.itemId = ko.unwrap(params.itemId);
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = ko.unwrap(params.sessionId);

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof TextEntryPoolData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly data: TextEntryPoolData;

    private gremlinState: { lines: string[] };

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (!this.gremlinState) {
            const golemData = getGolemData(this.itemId);
            let lines = this.lines().map((x, idx) => `xxxx ${idx}`);
            if (isStringArray(golemData)) {
                lines = golemData;
            }
            this.gremlinState = {
                lines
            };
            log(JSON.stringify({
                itemId: this.itemId,
                gremlinState: this.gremlinState,
            }));
        }
        for (const l of this.lines()) {
            if (!l.val()) {
                l.val(this.gremlinState.lines[l.lineIndex]);
                return true;
            }
        }
        return false;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT' || mode === 'GRADING') {
            throw new Error(`${WIDGET_NAME} - no edit or grading mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);

    }
    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });

    public readonly evaluationRemark = ko.pureComputed(() => this.data.evaluationRemark());

    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly lines = ko.pureComputed(() => {
        return this.data.lines.map(x => new VMLine(this, x));
    });

    public updateAnswer(args: { lineIndex: number, value: string }) {
        this.data.setAnswer(args.lineIndex, args.value);
    }
}

class VMLine {
    public readonly lineIndex: number;
    constructor(readonly model: MyModel, readonly data: TextEntryPool_Line) {
        this.lineIndex = data.lineIndex;
    }

    public readonly isCorrect = ko.pureComputed(() => {
        return this.data.isCorrect() === true;
    });
    public readonly isIncorrect = ko.pureComputed(() => {
        return this.data.isCorrect() === false;
    });
    public readonly val = ko.pureComputed({
        read: () => this.data.value(),
        write: newVal => {
            this.model.updateAnswer({
                lineIndex: this.lineIndex,
                value: newVal
            });
        }
    });//.extend({ required: { message: 'Bitte geben Sie einen Wert ein!' } });

    public readonly display = ko.pureComputed(() => (this.lineIndex + 1) + '.)');
    public readonly evaluationRemark = ko.pureComputed(() => this.data.evaluationRemark());
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

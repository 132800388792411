import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-subjectoverview';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class SubjectOverviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.subjectoverview.SUBJECT']);
    });

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.subjectoverview.FOR_EACH_SUBJECT_YOU_SEE_IN_THE_TABLE_WHICH_SUBJECT_COORDINATOR_AND_AUTHORS_ARE_ASSIGNED_TO_THIS_SUBJECT_IF_THE_FIELD_IS_EMPTY_THERE_HAVE_NOT_BEEN_ASSIGNED_ANY_USERS_YET_GO_TO_USER_TO_DEFINE_A_SUBJECT_COORDINATOR_OR_AUTHOR_FOR_THE_SUBJECT']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.superuser.subjectoverview.HELP']),
                action: this.showHelp
            }
        ];
    });

    private constructor(readonly widgetParams: IParamsInner) {
    }
    static async CREATE(widgetParams: IParamsInner) {
        const retVal = new SubjectOverviewRoute(widgetParams);
        return retVal;
    }
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/subject',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return SubjectOverviewRoute.CREATE(undefined);
    },
});
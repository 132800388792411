import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir } from '../../../debug';
import { GqlDS } from '../../../dxHelper/graphQLDataSource';
import { AS, lookupDS, refreshDx } from '../../../dx_helper';
import { ORGANISATION_NOTETYPE } from '../../../enums/datasource';
import { AtoZZ, DONE, DONEENUM } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { ServerConnection } from '../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['organisation_edit_data']>>;

const WIDGET_NAME = 'itemdefinition-organisation-edit';

type ActionID = 'A' | 'B' | 'C' | 'D';
const actionIDs: ActionID[] = ['A', 'B', 'C', 'D'];

interface INote {
    originalId: string;
    key: string;
    caption: string;
    noteHeader: string;
    noteBody: string;
    noteType: API.Organisation_NoteType;
    A: string;
    B: string;
    C: string;
    D: string;
}

export type IParams = IItemDefinitionWidgetParams;

export class NotesDS extends GqlDS<INote, 'key'>{
    constructor(
        { itemId, fetch }: { itemId: string, fetch: () => INote[] }
    ) {
        super({
            fetch,
            indexGenerator: AtoZZ,
            key: 'key',
            onNewRow: () => {

            },
            upsert: async (key, data) => {
                let addActions = [];

                for (const actionId of actionIDs) {
                    if (typeof data[actionId] === 'string') {
                        addActions.push({
                            actionId,
                            action: xnone(data[actionId]),
                        });
                    }
                }
                if (!addActions.length) {
                    addActions = undefined;
                }
                await ServerConnection.api.organisation_edit_update({
                    params: {
                        itemId,

                        addNotes: [{
                            noteId: key,
                            key: data.key,
                            noteType: data.noteType,
                            noteBody: xnone(data.noteBody),
                            noteHeader: xnone(data.noteHeader),
                            caption: xnone(data.caption),
                            correctActionId: 'A',
                            addActions,
                        }],
                    }
                });
                return DONEENUM.DONE;
            },
            remove: async key => {
                await ServerConnection.api.organisation_edit_update({
                    params: {
                        itemId,
                        removeNotes: [key],
                    }
                });
                return DONEENUM.DONE;
            },
        });
    }
}

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly focusedShape = ko.observable('');
    public readonly loaded = ko.observable(false);
    //public readonly answers = ko.observableArray();
    public readonly header = ko.observable('');
    public readonly text = ko.observable('');


    private data: Q['OrganisationEdit']['get'];

    private readonly _attachments: Q['documents']['get']['attachments'] = [];
    private readonly _notes: INote[] = [];

    private readonly notes: NotesDS;


    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.notes = new NotesDS({ itemId: params.itemId, fetch: () => this._notes });
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.text,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.HEADER'])
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.invisiblehotspot.edit.ENTER_THE_HEADER_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.invisiblehotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']),
        }));

        return retVal;
    });


    public readonly notesGrid = ko.pureComputed(() => {
        let retVal: DevExpress.ui.dxDataGrid.Properties = {
            columns: [],
            dataSource: {
                store: this.notes.store,

            },
            onInitNewRow: this.notes.initNewRow,
            keyExpr: 'key',
            wordWrapEnabled: true,
            editing: {
                mode: 'cell',
                allowAdding: true,
                allowDeleting: true,
                allowUpdating: true,
            }
        };
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.organisation.edit.ID']),
            dataField: 'key',
            dataType: 'string',
            allowEditing: !this.isReadOnly,
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.organisation.edit.CAPTION']),
            dataField: 'caption',
            dataType: 'string',
            allowEditing: !this.isReadOnly,
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.organisation.edit.NOTE_TYPE']),
            dataField: 'noteType',
            dataType: 'string',
            allowEditing: !this.isReadOnly,
            lookup: lookupDS(ORGANISATION_NOTETYPE(), {
                allowClearing: false,
            })
        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.organisation.edit.NOTE_HEADER']),
            dataField: 'noteHeader',
            dataType: 'string',
            allowEditing: !this.isReadOnly,
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                readOnly: this.isReadOnly,
                height: 'auto',
            })

        });
        retVal.columns.push({
            caption: i18n.t(['itemdefinition.organisation.edit.NOTE_BODY']),
            dataField: 'noteBody',
            dataType: 'string',
            allowEditing: !this.isReadOnly,
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                readOnly: this.isReadOnly,
                height: 'auto',
            })

        });
        for (const actionId of actionIDs) {
            retVal.columns.push({
                caption: actionId == 'A' ? i18n.t(['itemdefinition.organisation.edit.ACTIONID_KEY'], { actionId }) : i18n.t(['itemdefinition.organisation.edit.ACTIONID_DISTRACTOR'], { actionId }),
                dataField: actionId,
                dataType: 'string',
                allowEditing: !this.isReadOnly,
                editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                    readOnly: this.isReadOnly,
                    height: 'auto',
                })
            });
        }
        retVal.onEditorPreparing = e => {
            dir(e);
            if (e.parentType !== 'dataRow') {
                return;
            }
            if (!e.dataField) {
                return;
            }

            if (e.dataField !== 'noteHeader' && e.dataField !== 'noteBody' && actionIDs.findIndex(x => x === e.dataField) < 0) {
                return;
            }
            e.editorName = 'dxTextArea';
            e.editorOptions.height = 300;
        };
        return retVal;
    });

    public readonly notesGridCaption = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.organisation.edit.NOTES']);
    });
    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.organisation_edit_data({
            itemId: this.itemId
        });
        const d = q.OrganisationEdit.get;
        this.data = d;
        this.header(d.header.defaultValue);
        this.text(d.text.defaultValue);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        this._notes.splice(0, this._notes.length, ...d.notes.map(x => ({
            originalId: x.id,
            key: x.key,
            caption: x.caption.defaultValue,
            noteHeader: x.noteHeader.defaultValue,
            noteBody: x.noteBody.defaultValue,
            noteType: x.noteType,
            A: x.actions.find(x => x.key === 'A')?.action?.defaultValue || '',
            B: x.actions.find(x => x.key === 'B')?.action?.defaultValue || '',
            C: x.actions.find(x => x.key === 'C')?.action?.defaultValue || '',
            D: x.actions.find(x => x.key === 'D')?.action?.defaultValue || '',
        })));
        await refreshDx(this);

    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.text, `${WIDGET_NAME}/${this.itemId}/text`, async v => {
            await ServerConnection.api.organisation_edit_update({
                params: {
                    itemId: this.itemId,
                    text: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.organisation_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });


        this.loaded(true);
    }



}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

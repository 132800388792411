import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { log } from '../../../debug';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { queueApiCall } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
import { IParams, IParamsInner, WIDGET_NAME } from './route';
import { htmlString } from './translationedit.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_translationedit_data']>>;

interface IDXOnRowUpdate {
    data: IItemTranslationEntry,
    key: IItemTranslationEntry
}

interface IItemTranslationEntry {
    itemDocRefId: string,
    key: string,
    id: string,
    defaultLanguage: string,
    data: { [key: string]: string }
}

export class ViewModel extends DxWidget {
    public readonly innerParams: IParamsInner;

    constructor(readonly params: IParams) {
        super();
        this.innerParams = params.currentRoute.widgetParams;
    }

    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);

    private lastQuery: Q = undefined;

    public async OnRefresh() {
        await super.OnRefresh();
        const query = await ServerConnection.api.ui_subjectcoordinator_translationedit_data({
            params: {
                examOrderDocRefIds: this.innerParams.examOrderDocRefIds,
                itemDocRefIds: undefined,
                isoCodes: ['x-none', ...this.innerParams.isoCodes],
                subjectDocRefId: this.innerParams.subjectDocRefId
            },
        });
        this.lastQuery = query;
        this.itemListRaw(query);
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly itemListRaw = ko.observable<Q>();

    public readonly data = ko.pureComputed(() => {
        const retVal: IItemTranslationEntry[] = [];
        const rawData = this.itemListRaw();
        if (!rawData) {
            return undefined;
        }
        for (const item of rawData.getItemTranslations) {
            if (!item.entries) {
                continue;
            }
            const iso = item.isoCode;
            for (const entry of item.entries) {
                for (const translation of entry.translations) {
                    let itemEntry: IItemTranslationEntry;
                    itemEntry = retVal.find(item => {
                        if (item.itemDocRefId === entry.itemDocRefId && item.key === translation.key) {
                            return true;
                        }
                        return false;
                    });
                    if (!itemEntry) {
                        itemEntry = {
                            id: translation.id,
                            itemDocRefId: entry.itemDocRefId,
                            key: translation.key,
                            defaultLanguage: entry.defaultLanguage,
                            data: {}
                        };
                        itemEntry.data[iso] = translation.value;
                        retVal.push(itemEntry);
                    } else {
                        itemEntry.data[iso] = translation.value || '';
                    }
                }
            }
        }
        return retVal;
    });

    public readonly dxDataGridOptions = ko.pureComputed(() => {
        const data = this.data() || [];
        if (!data) {
            return undefined;
        }
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: data,
                editing: {
                    mode: 'cell',
                    allowUpdating: true
                },
                filterRow: {
                    visible: true,
                },
                searchPanel: {
                    visible: true,
                },
                columns: [
                    {
                        dataField: 'itemDocRefId',
                        allowEditing: false,
                        caption: i18next.t(['ui.subjectcoordinator.translationedit.ITEM_ID'])
                    },
                    {
                        dataField: 'key',
                        caption: i18next.t(['ui.subjectcoordinator.translationedit.KEY']),
                        allowEditing: false
                    }
                ],
                allowColumnResizing: true,
                wordWrapEnabled: true,
                export: {
                    enabled: true,
                    fileName: 'Translations',
                    allowExportSelectedData: false
                },
                hoverStateEnabled: true,
            }
        });
        const codes = new Set(this.lastQuery.config.contentLanguages.map(x => x.id));
        codes.delete('x-none');
        retVal.columns.push({
            dataField: 'data.x-none',
            dataType: 'string',
            caption: this.lastQuery.config.contentLanguages.find(x => x.id === 'x-none').name.value,
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                readOnly: true
            })
        });

        for (const key of this.innerParams.isoCodes.slice(0, this.innerParams.isoCodes.length).sort()) {
            if (!codes.has(key)) {
                continue;
            }
            const colName = this.itemListRaw().config.contentLanguages.find(x => x.isoCode === key)?.name?.value || key;

            retVal.columns.push({
                dataField: 'data.' + key,
                caption: colName,
                dataType: 'string',
                editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({

                })
            });
        }
        retVal.onRowUpdated = (e: IDXOnRowUpdate) => this.updateTranslation(e);

        return retVal;
    });

    public updateTranslation(e: IDXOnRowUpdate) {
        log(e);
        for (const key of Object.keys(e.data.data)) {
            const isoCode = key;
            const id = `${e.key.itemDocRefId}:${isoCode}:${e.key.key}`;
            queueApiCall(`${WIDGET_NAME}/updateTranslation/${id}`, () =>
                ServerConnection.api.ui_subjectcoordinator_translationedit_update(
                    {
                        params: {
                            translation: {
                                id: id,
                                key: e.key.key,
                                value: e.data.data[isoCode]
                            }
                        }
                    }
                ));
        }
    }
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import moment from 'moment';
import { DxWidget } from '../../../AbstractWidget';
import { writeToClipboard } from '../../../clipboard';
import { error, log } from '../../../debug';
import { AS, datagrid, dxAlert, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import * as API from '../../../its-itembank-api.g';
import { IExamSessionsQueryInput, SessionModesEnum } from '../../../its-itembank-api.g';
import { confirmYesNo } from '../../../modal';
import { DefaultWindowManager } from '../../base';
import { ServerConnection } from '../../RestAPI';
import { UIAction } from '../../uiAction';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { D, MyFormBuilder } from './MyFormBuilder';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_sessions_init']>>;
type SESSION = Q['examSessions'][0];

export class ViewModel extends DxWidget {
    public readonly loaded = ko.observable(false);

    public readonly formData: D;
    public readonly form = new MyFormBuilder(this);
    private readonly isFetching = ko.observable(false);

    public readonly loadPanelOptions = ko.pureComputed(() => {
        return AS<DevExpress.ui.dxLoadPanel.Properties>({
            visible: <any>this.isFetching
        });
    });
    public readonly actionRemove = new UIAction<SESSION>(undefined, async (e, args) => {
        const sessionId = args.docReferenceId;
        if (!await confirmYesNo(i18n.t(['ui.superuser.sessions.ARE_YOU_SURE_TO_DELETE_THE_SESSION']))) {
            return;
        }
        const r = await ServerConnection.api.ui_superuser_sessions_delete({ sessionDocRefId: [sessionId] });
        await this.search();
    });

    public readonly actionSearch = new UIAction(undefined, async (e) => {
        let moreData = false;
        try {
            this.isFetching(true);
            log(`Searching using filter ${JSON.stringify(this.formData)}`);
            const result = await this.search();
            if (result === 'moredata') {
                moreData = true;
            }
        } catch (e) {
            error(e);
            alert('Error occured');
        } finally {
            this.isFetching(false);
        }
        if (moreData) {
            await dxAlert(i18n.t(['ui.superuser.sessions.NOT_ALL_RESULTS_MATCHING_THE_CRITERIA_ARE_DISPLAYED']));
        }
    });

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        /*
        this.form.formOptions.onFieldDataChanged = x => {
            //void this.updateDatabase(x.dataField, x.value);
        };
        */
    }

    public readonly actionResults = new UIAction<SESSION>(undefined, async (e, args) => {
        const wnd = DefaultWindowManager.prepareOpenWindow('');
        const q = await ServerConnection.api.ui_superuser_sessions_getreport({
            sessionId: args.docReferenceId
        });
        DefaultWindowManager.openWindow(q.sessionReport.href, '');
    });

    public readonly actionJSON = new UIAction<SESSION>(undefined, async (e, args) => {
        const x = await ServerConnection.api.ui_superuser_sessions_json({
            sessionId: args.docReferenceId
        });
        const jsonString = x.examSession.json;

        await writeToClipboard(jsonString);
    });

    public readonly actionPDF = new UIAction<SESSION>(undefined, async (e, args) => {
        const wnd = DefaultWindowManager.prepareOpenWindow('');
        const url = ServerConnection.getDataUrl(`sessions/${args.docReferenceId}/pdf`, {}, true);
        DefaultWindowManager.openWindow(url, '');
    });

    constructor(readonly params: IParams) {
        super();
        const now = moment();
        const examStartMin = now.startOf('day').subtract(1, 'day').toISOString();
        this.formData = {
            laxExternalId: '',
            examAlias: '',
            sessionStartTimeMin: examStartMin,
            sessionStartTimeMax: undefined,
            limit: 100,
            skip: 0,
        };
    }

    private readonly _sessions: SESSION[] = [];

    private async search() {
        const limit = this.formData.limit;
        const filter: IExamSessionsQueryInput = {
            all: true,
            limit,
            skip: this.formData.skip,
        };
        if (this.formData.laxExternalId) {
            filter.laxExternalId = this.formData.laxExternalId.split(/\+s/g);
            filter.all = false;
        }
        if (this.formData.sessionStartTimeMin) {
            filter.sessionStartTimeMin = this.formData.sessionStartTimeMin;
            filter.all = false;
        }
        if (this.formData.sessionStartTimeMax) {
            filter.sessionStartTimeMax = this.formData.sessionStartTimeMax;
            filter.all = false;
        }
        if (this.formData.examAlias) {
            filter.examAlias = this.formData.examAlias.split(/\s+/g);
            filter.all = false;
        }

        const r = await ServerConnection.api.ui_superuser_sessions_init({
            filter
        });
        this._sessions.splice(0, this._sessions.length, ...r.examSessions);
        await this.OnRefresh();
        if (r.examSessions.length >= limit) {
            return 'moredata';
        } else {
            return 'ok';
        }
    }
    public async OnRefresh() {
        await super.OnRefresh();
        await refreshDx(this);
    }

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            discriminator: 'main_v2',
            config: {
                allowColumnResizing: true,
                allowColumnReordering: true,
                //focusedRowEnabled: true,
                columnChooser: {
                    enabled: true,

                },
                export: {
                    enabled: true,
                },
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true,
                },
                filterRow: {
                    visible: true,
                },
                filterPanel: {
                    visible: true,
                },
                headerFilter: {
                    visible: true,
                    allowSearch: true,
                },
                selection: {
                    mode: 'single',
                },
                groupPanel: {
                    visible: false,
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._sessions;
                        },
                        byKey: async key => {
                            return this._sessions.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.EXTERNAL_ID']),
            dataField: 'externalId',
            dataType: 'string',
            sortOrder: 'asc',
            sortIndex: 0,
            cssClass: 'itsr3-uitest-hide',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.SESSION_ID']),
            dataField: 'docReferenceId',
            dataType: 'string',
            cssClass: 'itsr3-uitest-hide',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.MODE']),
            dataField: 'mode',
            dataType: 'string',
            calculateDisplayValue: (queryRow: SESSION): string => {
                switch (queryRow.mode) {
                    case SessionModesEnum.New:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['New']);
                    case SessionModesEnum.AvailableForParticipation:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['AvailableForParticipation']);
                    case SessionModesEnum.Participating:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['Participating']);
                    case SessionModesEnum.Participated:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['Participated']);
                    case SessionModesEnum.AvailableForGrading:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['AvailableForGrading']);
                    case SessionModesEnum.Grading:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['Grading']);
                    case SessionModesEnum.Graded:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['Graded']);
                    case SessionModesEnum.AvailableForExport:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['AvailableForExport']);
                    case SessionModesEnum.Exported:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['Exported']);
                    case SessionModesEnum.Archived:
                        return i18next.t(TRANSLATIONS.TESTSESSION_MODE['Archived']);
                }
                return queryRow.mode;
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.EXAM_ALIAS']),
            dataField: 'examInfo.examAlias',
            dataType: 'string',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.EXAM_ID']),
            dataField: 'examInfo.examId',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.EXAM_TYPE']),
            dataField: 'examInfo.examType',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.TIME_IN_TEST']),
            dataField: 'timeLeft.timePassed',
            dataType: 'string',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.TIME_LEFT']),
            dataField: 'timeLeft.timeLeft',
            dataType: 'string',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.MAX_TIME']),
            dataField: 'totalTime',
            dataType: 'string',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.SCORE']),
            dataField: 'score.pointsGained',
            dataType: 'number',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.MAX_SCORE']),
            dataField: 'score.pointsMax',
            dataType: 'number',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.SCORE_PERCENT']),
            dataField: 'score.pointsPercentage',
            dataType: 'number',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.START_TIME']),
            dataField: 'sessionStartTime',
            dataType: 'datetime',
            cssClass: 'itsr3-uitest-hide',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.CLOSE_TIME']),
            dataField: 'sessionCloseTime',
            dataType: 'datetime',
            cssClass: 'itsr3-uitest-hide',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.PROGRESS_PERCENT']),
            dataField: 'itemInfo.percentCompleted',

            dataType: 'number',

        });
        retVal.columns.push({
            caption: i18n.t(['ui.superuser.sessions.CANDIDATE_ID']),
            dataField: 'candidate.candidateId',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18n.t(['ui.superuser.sessions.CANDIDATE_NAME']),
            dataField: 'candidate.display',
            dataType: 'string',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.PROGRESS']),
            cssClass: 'itsr3-monospace',
            dataField: 'itemInfo.progress',
            dataType: 'string',
            allowFiltering: false,
            allowSorting: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.PASSED']),
            dataField: 'examInfo.grade.passed',
            dataType: 'boolean',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.CREATED']),
            dataField: 'auditCreated',
            dataType: 'datetime',
            cssClass: 'itsr3-uitest-hide',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.LAST_CHANGED']),
            dataField: 'auditUpdated',
            dataType: 'datetime',
            cssClass: 'itsr3-uitest-hide',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.sessions.ACTIONS']),

            allowExporting: false,
            width: '80',
            cellTemplate: (elem, info) => {
                const retVal = $('<span />');
                if (true) {
                    const link = $('<a />');
                    link.appendTo(retVal);
                    link.text(i18next.t(['ui.superuser.sessions.RESULTS']));
                    link.css({ cursor: 'pointer' });
                    link.on('click', () => {
                        const user: SESSION = info.data;
                        void this.actionResults.intent(user);

                    });
                    retVal.append(' ');
                }
                if (true) {
                    const link = $('<a />');
                    link.appendTo(retVal);
                    link.text(i18next.t(['ui.superuser.sessions.PDF']));
                    link.css({ cursor: 'pointer' });
                    link.on('click', () => {
                        const user: SESSION = info.data;
                        void this.actionPDF.intent(user);

                    });
                    retVal.append(' ');
                }
                if (true) {
                    const link = $('<a />');
                    link.appendTo(retVal);
                    link.text(i18next.t(['ui.superuser.sessions.JSON']));
                    link.css({ cursor: 'pointer' });
                    link.on('click', () => {
                        const user: SESSION = info.data;
                        void this.actionJSON.intent(user);

                    });
                    retVal.append(' ');
                }

                if (true) {
                    const link = $('<a />');
                    link.appendTo(retVal);
                    link.text(i18n.t(['ui.superuser.sessions.REMOVE']));
                    link.css({ cursor: 'pointer' });
                    link.on('click', () => {
                        const user: SESSION = info.data;
                        void this.actionRemove.intent(user);

                    });
                    retVal.append(' ');
                }
                return retVal;
            }
        });
        return retVal;
    });


    public async initialize() {
        await super.initialize();
        this.initFormOptions();
        await this.OnRefresh();
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput, IUpdateItemStateInput_Organisation_Given, Organisation_NoteType } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
//import * as INIT from '../init.query.graphql.g';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";
//export { Organisation_NoteType } from '../init.query.graphql.g';

function getData(item: INIT) {
    if (item.data.__typename === 'Organisation_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type NOTE = DATA['orgNotes'][0];
type ACTION = NOTE['orgActions'][0];
export class Action {
    constructor(private readonly model: OrganisationData, public readonly note: NOTE, public readonly rawData: ACTION) {
        this.id = rawData.id;
        this.action = translate(rawData.orgAction, {});
        this.fromJS(rawData);
    }
    public readonly id: string;
    public readonly action: string;

    public fromJS(data: ACTION) {
    }
}

export class Note {
    constructor(private readonly model: OrganisationData, public readonly rawData: NOTE) {
        this.id = rawData.id;
        this.caption = translate(rawData.orgCaption, {});
        this.actions = rawData.orgActions.map(x => new Action(model, rawData, x));
        this.correctId = rawData.orgCorrect?.id || '';
        this.noteBody = translate(rawData.orgNoteBody, {});
        this.noteHeader = translate(rawData.orgNoteHeader, {});
        this.noteType = rawData.orgNoteType;
        this.fromJS(rawData);
    }

    public readonly id: string;
    public readonly caption: string;
    public readonly noteType: Organisation_NoteType;
    public readonly noteBody: string;
    public readonly noteHeader: string;
    public readonly actions: Action[];
    public readonly givenId = ko.observable('');
    public readonly correctId: string;
    public fromJS(data: NOTE) {
        this.givenId(data.orgGiven?.id || '');
    }
}

export class OrganisationData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        //this.correctAnswer = data.correctAnswer;
        this.headerText = translate(data.header, {});
        this.textHTML = RICHTEXTHTML.process({
            html: translate(data.orgText, {}),
            attachments: this.meta.attachments,
        });

        this.notes = data.orgNotes.map(a => new Note(this, a));
        this.fromJS(data);
    }

    public fromJS(data: DATA) {
        if (data.__typename !== 'Organisation_QueryItemSessionData') {
            throw new Error();
        }
        for (const srcTb of data.orgNotes) {
            const tgtTb = this.notes.find(x => x.id == srcTb.id);
            tgtTb.fromJS(srcTb);
        }
    }
    public readonly textHTML: string;
    public readonly notes: Note[];
    public readonly headerText: string;

    private getGiven(): { total: number, data: IUpdateItemStateInput_Organisation_Given[] } {
        const retVal: { total: number, data: IUpdateItemStateInput_Organisation_Given[] } = {
            total: 0,
            data: [],
        };
        for (const note of this.notes) {
            retVal.total++;
            const given = note.givenId();
            if (!given) {
                continue;
            }
            retVal.data.push({
                noteId: note.id,
                actionId: given,
            });
        }
        return retVal;
    }
    public IsInteractionStarted() {
        const given = this.getGiven();
        return given.data.length > 0;
    }
    public IsInteractionComplete() {
        const given = this.getGiven();
        return given.data.length === given.total;
    }
    public async reset() {
        for (const note of this.notes) {
            note.givenId('');
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            Organisation: {
                given: this.getGiven().data,
            },
        };
        return retVal;
    }

}

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { ServerConnection } from '../../../ui/RestAPI';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoMatchingHotSpot_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type SHAPE = DATA['shapes'][0];

const SHAPES = [{
    id: 'circle',
    regEx: /^Circle\(x=([0-9]+)%,y=([0-9]+)%,r=([0-9]+)%\)$/i
}];

export class MatchingHotspotData_Answer {

    public readonly id: string;
    public readonly connectedTo = ko.observable<string>();
    public readonly correctConnectTo: string;
    public readonly text: string;

    constructor(readonly vm: MatchingHotspotData, readonly nAnswer: number) {
        const itemAnswers = vm._data.mhsAnswers;
        const answer = itemAnswers[nAnswer];
        this.text = translate(answer.value, {});
        this.id = answer.id;
        this.connectedTo(answer.selectedShape);
        this.correctConnectTo = answer.correctShape;
    }
}

export class MatchingHotspotData_Shape {
    public readonly id: string;

    public readonly x: number;
    public readonly y: number;
    public readonly r: number;

    public readonly shape: SHAPE;

    public isConnected() {
        return this.vm.answers.some(x => x.connectedTo() === this.id);
    }
    constructor(readonly vm: MatchingHotspotData, nShape: number) {
        const itemShapes = vm._data.shapes;
        const shape = itemShapes[nShape];
        this.shape = shape;

        const shapeStr = shape.shape;
        const shapeId = shape.id;
        let shapeFormatId = '';
        for (const shapeFormat of SHAPES) {
            if (shapeFormatId !== '') {
                continue;
            }
            const regEx = shapeFormat.regEx.exec(shapeStr);
            if (!regEx) {
                continue;
            }
            shapeFormatId = shapeFormat.id;
            switch (shapeFormatId) {
                case 'circle':
                    this.x = parseFloat(regEx[1]);
                    this.y = parseFloat(regEx[2]);
                    this.r = parseFloat(regEx[3]);
                    this.id = ko.unwrap(shapeId);
                    break;
                default:
                    throw new Error('Unhandled shape format ' + shapeStr + ' (should be format ' + shapeFormatId + ')');
            }
        }
        if (!shapeFormatId) {
            throw new Error('Unhandled shape format ' + shapeStr + ' (format not detected)');
        }

    }
}

export class MatchingHotspotData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly _data: DATA) {
        super();
        this.headerText = translate(_data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(_data.question, {}),
            attachments: this.meta.attachments,
        });

        this.width = _data.mhsWidth;
        this.height = _data.mhsHeight;
        const imgAtt = meta.getAttachment(_data.mhsImage?.id);
        this.imgUrl = ServerConnection.getDataUrl(imgAtt?.hrefResolved);
        this.imgAlt = imgAtt?.altText?.value ?? '';
        this.answers = _data.mhsAnswers.map((d, index) => new MatchingHotspotData_Answer(this, index));
        this.shapes = _data.shapes.map((d, index) => new MatchingHotspotData_Shape(this, index));
        this.fromJS(_data);
    }
    public fromJS(data: DATA) {
    }
    public readonly width: number;
    public readonly height: number;
    public readonly imgUrl: string;
    public readonly imgAlt: string;
    public readonly headerText: string;
    public readonly questionHTML: string;

    public readonly answers: MatchingHotspotData_Answer[];
    public readonly shapes: MatchingHotspotData_Shape[];
    public async connect({ answerId, shapeId }: { answerId: string, shapeId: string }) {
        if (!answerId || !shapeId) {
            return;
        }

        for (const ans of this.answers) {
            if (ans.id === answerId) {
                ans.connectedTo(shapeId);
            } else if (ans.connectedTo() === shapeId) {
                ans.connectedTo(undefined);
            }
        }
    }

    public IsInteractionStarted() {
        return this.answers.some(x => !!x.connectedTo());
    }
    public IsInteractionComplete() {
        return this.answers.every(x => !!x.connectedTo());
    }
    public async reset() {
        for (const answer of this.answers) {
            answer.connectedTo(undefined);
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            MatchingHotSpot: {
                answers: this.answers.map(x => ({
                    answerId: x.id,
                    shapeId: x.connectedTo(),
                }))
            }
        };
        return retVal;
    }

}
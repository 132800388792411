import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';
type Q = Awaited<ReturnType<API.Sdk['ui_superuser_testaliasedit_data']>>;
type ALIAS = Q['TestAlias']['byId'];

export class ViewModel extends DxWidget {
    private readonly formData: ALIAS = <any>{};

    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: this.formData,
            items: [],
            onInitialized: x => {
                this.forms.add(x.component);
            },
            onDisposing: x => {
                this.forms.delete(x.component);
            },
            onFieldDataChanged: x => {
                void this.updateDatabase(x.dataField, x.value);
            }
        };

        const g1: DevExpress.ui.dxFormGroupItem = {
            itemType: 'group',
            colCount: 2,
            caption: i18next.t(['ui.superuser.testaliasedit.PROFILE']),
            items: []
        };
        retVal.items.push(g1);

        g1.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            label: {
                text: i18next.t(['ui.superuser.testaliasedit.ID'])
            },
            dataField: 'alias',
            editorOptions: {
            }

        }));


        g1.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            label: {
                text: i18next.t(['ui.superuser.testaliasedit.RESOLVES_TO'])
            },
            dataField: 'resolvesTo',
            editorType: 'dxTagBox',
            editorOptions: AS<DevExpress.ui.dxTagBox.Properties>({
                placeholder: i18next.t(['ui.superuser.testaliasedit.INPUT_CONFIRM_WITH_ENTER']),
                acceptCustomValue: true,
                openOnFieldClick: false,
            }),
        }));
        return retVal;
    });

    public readonly loaded = ko.observable(false);

    private readonly docRef: string;

    constructor(readonly params: IParams) {
        super();
        this.docRef = this.params.currentRoute.widgetParams.docRef;
    }

    private async updateDatabase(fieldName: string, value: any) {
        const docRef = this.docRef;
        const alias = this.formData.alias;
        switch (fieldName) {
            case 'alias':
                await ServerConnection.api.ui_superuser_testaliasedit_update({
                    params: {
                        docRef,
                        alias,
                    }
                });
                return DONE;

            case 'resolvesTo':
                await ServerConnection.api.ui_superuser_testaliasedit_update({
                    params: {
                        docRef,
                        alias,
                        setResolvesTo: value
                    }
                });
                return DONE;

            case 'isDeleted':
                await ServerConnection.api.ui_superuser_testaliasedit_update({
                    params: {
                        docRef,
                        alias,
                        isDeleted: value
                    }
                });
                return DONE;
        }
        return DONE;
    }
    public async OnRefresh() {
        await super.OnRefresh();
        const config = await ServerConnection.api.ui_superuser_testaliasedit_data({
            profile: this.docRef,
        });
        Object.assign(this.formData, config.TestAlias.byId);
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
import CustomStore from 'devextreme/data/custom_store';
import { getParent } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { ServerConnection } from '../../../RestAPI';

type Q = Awaited<ReturnType<API.Sdk['ui_author_itemedit_itemmetadatablock_allobjectives']>>;

interface IEntry {
    id: string;
    parentId: string;
    display: string;
}
export class SyllabusStore extends CustomStore {
    constructor(readonly itemId: string) {
        super({
            loadMode: 'raw',
            key: 'id',
            byKey: async (key) => {
                return this._items.find(x => x.id === key);
            },
            load: async () => {
                const r = await ServerConnection.api.ui_author_itemedit_itemmetadatablock_allobjectives({
                    itemId: this.itemId,
                });
                this._items.splice(0, this._items.length, ...r.item.get.metaData.subject.syllabus.map(x => ({
                    id: x.taxon,
                    parentId: getParent(x.taxon),
                    display: x.name.value
                })));
                return this._items;

            }
        });
    }
    public getDisplay(key: string) {
        const obj = this._items.find(x => x.id === key);
        if (!obj) {
            return '?';
        }
        return obj.display;
    }
    private readonly _items: IEntry[] = [];
}

import * as ko from 'knockout';
import { DONE } from '../../helper';
import { IItemDefinitionWidgetParams } from '../../model/interfaces';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { PlaceHolderData } from '../model/placeholder/PlaceHolderData';
import { GetSession } from '../model/session';
import { htmlString } from './placeholder.html.g';

export type IParams = IItemDefinitionWidgetParams;

const WIDGET_NAME = 'itemdefinition-placeholder';
const WIDGET_PARENT_NAME = 'itemdefinition';

export class MyModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly data: PlaceHolderData;

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;

        const item = GetSession(this.params.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof PlaceHolderData)) {
            throw new Error();
        }
        this.data = data;

    }

    public async OnReset() {
        await this.data.setDone();
        return DONE;
    }
    public async initialize() {
        await super.initialize();
        await this.data.setDone();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { dir, log } from '../../../../debug';
import { AS, datagrid, refreshDx, tbAddButton } from '../../../../dx_helper';
import * as TRANSLATIONS from '../../../../enums/translations';
import { assertNever, DONE } from '../../../../helper';
import { AuthoringStatusEnum } from '../../../../its-itembank-api.g';
import { confirmYesNo } from '../../../../modal';
import { ObjectiveStore } from '../../../author/itemedit/itemmetadatablock/ObjectiveStore';
import { legacyPushPull } from '../../../docmanager';
import { ServerConnection } from '../../../RestAPI';
import * as ROUTES from '../../../routes';
import { UIAction } from '../../../uiAction';
import * as WIDGET_ITEMREVIEW from '../route';
import * as i18next from './../../../../i18n/i18n';
import { AlternationsStore } from './AlternationsStore';
import { CategoriesStore } from './CategoriesStore';
import { ALL_AUDIENCE, ALL_AUTHOR, CHILDITEM, D, MyFormBuilder, Q } from './MyFormBuilder';
import { SyllabusStore } from './SyllabusStore';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'ui-subjectcoordinator-itemreview-metadata';

export interface IParams {
    item: string;
}

let htmlId = 0;

interface ExamOrder {
    docReferenceId: string;
    state: 'published' | 'provisioned';
    examinationOrderId: string;
    title: string;
}

export class MyModel extends DxWidget {
    public readonly WIDGET_NAME = WIDGET_NAME;
    public readonly form = new MyFormBuilder(this);

    public readonly allAudiences: ALL_AUDIENCE[] = [];

    public readonly _item = ko.observable<Q>();

    private readonly actionAssignToProvisioned = new UIAction<ExamOrder>(undefined, async (e, args) => {
        await this.manageItemInExamOrder({
            examOrderDocRefId: args.docReferenceId,
            remove: false
        });
    });
    private readonly actionUnassignToProvisioned = new UIAction<ExamOrder>(undefined, async (e, args) => {
        await this.manageItemInExamOrder({
            examOrderDocRefId: args.docReferenceId,
            remove: true
        });
    });

    public isProvisioned(examOrderDocRefId: string) {
        const item = this._item();
        if (!item) {
            return undefined;
        }
        const provisioned = item.item.get.workflow.provisioned || [];
        return !!provisioned.find(id => id.docReferenceId === examOrderDocRefId);
    }

    public readonly canAddToExamOrder = ko.pureComputed(() => {
        const item = this._item();
        return !item.item.get.workflow.obsolete;
    });
    private async manageItemInExamOrder(data: { examOrderDocRefId: string, remove: boolean }) {
        const itemDocRefId = this.itemDocRefId();
        if (!itemDocRefId) {
            return;
        }
        const examOrderDocRef = data.examOrderDocRefId;
        const examOrder = this._item().item.get.subject.openExamOrders.find(x => x.docReferenceId === examOrderDocRef);
        const examOrderId = examOrder.examinationOrderId;
        if (data.remove) {
            await legacyPushPull(async () => {
                await ServerConnection.api.ui_subjectcoordinator_itemreview_metadata_removefromexamorder({
                    itemId: itemDocRefId,
                    examOrderDocRef,
                    examOrderId,
                });
            });
        } else {
            await legacyPushPull(async () => {
                await ServerConnection.api.ui_subjectcoordinator_itemreview_metadata_assigntoexamorder({
                    itemId: itemDocRefId,
                    examOrderDocRef,
                    examOrderId,
                });
            });
        }
    }


    public readonly itemDocRefId = ko.pureComputed(() => this.params.item);

    public readonly currentAuthor = ko.pureComputed(() => {
        const itemData = this._item();
        if (!itemData) {
            return undefined;
        }
        const author = itemData.item.get.workflow.currentAuthor;
        if (!author) {
            return undefined;
        }
        return author.docReferenceId;
    });

    public readonly isContainer = ko.observable(false);

    public readonly isTopItem = ko.observable(false);
    public readonly isSubItem = ko.observable(false);
    public readonly objectiveStore: ObjectiveStore;
    public readonly syllabusStore: SyllabusStore;
    public readonly alternationsStore: AlternationsStore;
    public readonly categoriesStore: CategoriesStore;
    public readonly formData: D = <D>{};

    constructor(readonly params: IParams) {
        super();

        this.objectiveStore = new ObjectiveStore(params.item);
        this.syllabusStore = new SyllabusStore(params.item);
        this.categoriesStore = new CategoriesStore(params.item);
        this.alternationsStore = new AlternationsStore(params.item);

    }

    public readonly loaded = ko.observable(false);
    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly allAuthors: ALL_AUTHOR[] = [];
    public readonly subjectDocRefId = ko.observable('');
    public readonly hasObjectives = ko.observable(false);
    public readonly displayCopiedFrom = ko.observable(false);
    public readonly displayRevisionFrom = ko.observable(false);
    private readonly _descendentItems: CHILDITEM[] = [];
    private readonly _examOrders: ExamOrder[] = [];

    public async OnRefresh() {
        await super.OnRefresh();
        const x = await ServerConnection.api.ui_subjectcoordinator_itemreview_metadata({
            itemId: this.params.item,

        });
        this.allAuthors.splice(0, this.allAuthors.length, ...x.item.get.subject.authors);
        this.obsolete(x.item.get.workflow.obsolete);
        this._item(x);

        const raw = x.item.get;

        const updatedAudiences = raw.metaData.audience.map(x => x.docReferenceId).sort();

        const updatedAlternations = raw.metaData.alternations.map(x => x.taxon).sort();

        this.hasObjectives(raw.itemType.supportsObjectives);
        //const objectiveId = raw.itemType.supportsObjectives && [(raw.metaData.objectives.length && raw.metaData.objectives[0].objectiveId || '?')];
        //const objectiveScore = raw.itemType.supportsObjectives && (raw.metaData.objectives.length && raw.metaData.objectives[0].scoreMax || 0);

        const translatedAuthoringStatus = i18next.t(TRANSLATIONS.AuthoringStatus[raw.workflow.authoringStatus]);
        const item: D = Object.assign(raw, {
            translatedAuthoringStatus,
            itemTypeDisplay: `${raw.itemType.title.value} (${raw.itemType.uuid})`,
            audiences: updatedAudiences,
            alternations: updatedAlternations,
            //objectiveId,
            //objectiveScore,
        });
        Object.assign(this.formData, item);
        dir(item);
        this.subjectId(item && item.subject && item.subject.subjectId || '');
        this.subjectDocRefId(item && item.subject.docReferenceId);
        this.isContainer(item.itemType.supportsSubItems);
        this.displayCopiedFrom(!!(item.metaData.copyFrom && item.metaData.copyFrom.itemId));
        this.displayRevisionFrom(!!(item.metaData.revisionFrom && item.metaData.revisionFrom.itemId));
        this.isSubItem(item.parentItemId.length > 0);
        this.isTopItem(!this.isSubItem());
        this.itemLocked(item.workflow.locked);
        this.itemUnlocked(!this.itemLocked());
        this.itemDone(item.workflow.authoringStatus === 'done');


        this.allAudiences.splice(0, this.allAudiences.length, ...item.subject.audiences);
        this._descendentItems.splice(0, this._descendentItems.length, ...item.childItems);
        const newExamOrders: ExamOrder[] = [];
        newExamOrders.push(...item.workflow.published.map(x => AS<ExamOrder>({
            docReferenceId: x.docReferenceId,
            state: 'published',
            examinationOrderId: x.examinationOrderId,
            title: x.title,
        })));

        newExamOrders.push(...x.item.get.subject.openExamOrders.map(x => AS<ExamOrder>({
            docReferenceId: x.docReferenceId,
            state: 'provisioned',
            examinationOrderId: x.examinationOrderId,
            title: x.title,
        })));
        this._examOrders.splice(0, this._examOrders.length, ...newExamOrders);
        await refreshDx(this);

    }
    public async initialize() {
        await super.initialize();
        await this.syllabusStore.load();
        await this.categoriesStore.load();
        await this.alternationsStore.load();


        await this.OnRefresh();
        this.initFormOptions();
        this.loaded(true);
    }

    private async updateDatabase(field: string, value: any) {
        const itemId = this.params.item;
        const up = this.form.fields.get(field);

        log(`${WIDGET_NAME}: Update database field ${field} to ${value}`);
        if (!up) {
            return DONE;
        }
        const p = up(value);
        const metadata = p.metadata || {};
        /*
        if (p.objectiveId) {
            metadata.setObjectives = [{
                objectiveId: p.objectiveId,
                score: this.formData.objectiveScore
            }];
            p.objectiveId = undefined;
        }
        if (p.objectiveScore) {
            metadata.setObjectives = [{
                objectiveId: this.formData.objectiveId && this.formData.objectiveId[0],
                score: p.objectiveScore
            }];
            p.objectiveScore = undefined;
        }
        */
        const workflow = p.workflow || {};
        dir({ metadata, workflow });
        await ServerConnection.api.ui_subjectcoordinator_itemreview_metadata_update({
            metadata: Object.assign({
                itemId,
            }, metadata),
            workflow: Object.assign({
                itemId,
            }, workflow)
        });
        return DONE;
    }

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        this.form.formOptions.onFieldDataChanged = x => {
            void this.updateDatabase(x.dataField, x.value);
        };
    }

    public readonly examOrderGrid = ko.pureComputed(() => {
        const retVal = datagrid<ExamOrder>({
            WIDGET_NAME,
            widget: this,
            discriminator: 'examorders',
            config: {
                allowColumnResizing: true,
                headerFilter: {
                    visible: true,
                },
                focusedRowEnabled: true,
                selection: {
                    mode: 'single',
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'docReferenceId',
                        load: async () => {
                            return this._examOrders;
                        },
                        byKey: async key => {
                            return this._examOrders.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.EXAMINATION_ORDER_ID']),
            width: 100,
            dataField: 'examinationOrderId'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.INTERNAL_ID']),
            width: 10,
            dataField: 'docReferenceId'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.TITLE']),
            width: 200,
            dataField: 'title'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.STATE']),
            dataField: 'state',
            calculateCellValue: e => {
                switch (e.state) {
                    case 'provisioned': return i18next.t(['ui.subjectcoordinator.itemreview.metadata.PROVISIONED']);
                    case 'published': return i18next.t(['ui.subjectcoordinator.itemreview.metadata.PUBLISHED']);
                    default:
                        assertNever(e.state);
                        throw new Error();
                }
            }
        });
        retVal.columns.push({
            type: 'buttons',
            buttons: [
                {
                    name: 'assign',
                    text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.ASSIGN']),
                    onClick: e => {
                        e.event.stopPropagation();
                        e.event.preventDefault();
                        if (!e.row?.data) {
                            return;
                        }
                        this.actionAssignToProvisioned.click(e.row.data);
                    },
                    visible: e => {
                        const data = e.row?.data;
                        if (!data) {
                            return false;
                        }
                        if (data.state == 'published') {
                            return false;
                        }
                        return !this.isProvisioned(data.docReferenceId);
                    }

                },
                {
                    name: 'unassign',
                    text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.UNASSIGN']),
                    onClick: e => {
                        e.event.stopPropagation();
                        e.event.preventDefault();
                        if (!e.row?.data) {
                            return;
                        }
                        this.actionUnassignToProvisioned.click(e.row.data);
                    },
                    visible: e => {
                        const data = e.row?.data;
                        if (!data) {
                            return false;
                        }
                        if (data.state == 'published') {
                            return false;
                        }
                        return this.isProvisioned(data.docReferenceId);
                    }

                }
            ]
        });
        return retVal;

    });
    public readonly subItemGrid = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            discriminator: 'subitems',
            widget: this,
            config: {
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._descendentItems;
                        },
                        byKey: async key => {
                            return this._descendentItems.find(x => x.id === key);
                        }
                    })
                }
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.NR']),
            width: 20,
            dataField: 'index'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.ITEM_ID']),
            dataField: 'item.itemId'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.TITLE']),
            dataField: 'item.metaData.title',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.ITEM_TYPE']),
            dataField: 'item.itemType.title.value',
            dataType: 'string',

        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.STATUS']),
            dataField: 'item.workflow.authoringStatus',
            dataType: 'string',
            calculateDisplayValue: (e: CHILDITEM): string => {
                return i18next.t(TRANSLATIONS.AuthoringStatus[e.item.workflow.authoringStatus || AuthoringStatusEnum.Open]);
            }
        });
        return retVal;
    });


    public readonly toolbarOptions = ko.pureComputed<DevExpress.ui.dxToolbar.Properties>(() => {
        const retVal: DevExpress.ui.dxToolbar.Properties = {
            items: [],
        };
        const hasSuccessor = !!this._item().item.get.metaData.successorItem;
        tbAddButton(retVal,
            this.actionReopen,
            {
                visible: !hasSuccessor && !this.itemLocked() && this.itemDone(),
                location: 'before',
                options: {
                    text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.REOPEN'])
                }
            }
        );
        tbAddButton(retVal,
            this.actionRevise,
            {
                visible: !hasSuccessor && this.itemLocked(),
                location: 'before',
                options: {
                    text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.REVISE_ITEM']),
                }
            }
        );
        tbAddButton(retVal,
            this.actionGotoSuccessor,
            {
                visible: hasSuccessor,
                location: 'before',
                options: {
                    text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.GOTO_NEWER_VERSION']),
                }
            }
        );
        return retVal;
    });

    public readonly itemLocked = ko.observable(false);
    public readonly itemDone = ko.observable(false);


    public readonly itemUnlocked = ko.observable(false);

    public readonly actionGotoSuccessor = new UIAction(undefined, async () => {
        const meta = this._item().item.get.metaData;
        const successor = meta.successorItem && meta.successorItem.docReferenceId;
        if (!successor) {
            return;
        }
        const subjectDocRefId = this._item().item.get.subject.docReferenceId;

        await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMREVIEW.FACTORY.href(
            {
                subject: subjectDocRefId,
                items: [successor],
            }
        ));

    });

    public readonly actionReopen = new UIAction(undefined, async () => {
        await legacyPushPull(async () => {
            await ServerConnection.api.ui_subjectcoordinator_itemreview_metadata_reopenitem(
                {
                    item: this.itemDocRefId(),
                });
        });
    });

    public readonly actionRevise = new UIAction(undefined, async () => {
        if (!await confirmYesNo(i18next.t(['ui.subjectcoordinator.itemreview.metadata.DO_YOU_WANT_TO_REVISE_THE_ITEM_THIS_ITEM_WILL_BECOME_OBSOLETE_AND_A_COPY_OF_THIS_ITEM_WILL_BE_MADE']))) {
            return;
        }
        const result = await ServerConnection.api.ui_subjectcoordinator_itemreview_metadata_reviseitem(
            {
                item: {
                    docRefId: this.itemDocRefId(),
                }
            });
        const subjectDocRefId = this._item().item.get.subject.docReferenceId;

        await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMREVIEW.FACTORY.href(
            {
                subject: subjectDocRefId,
                items: [result.item.reviseItem.newItemDocRefId],
            }));
    });

    public readonly subjectId = ko.observable('');



    //public readonly selectedAuthorId = ko.observable(this.currentAuthor());
    //public readonly noteForAuthor = ko.observable('');
    public readonly obsolete = ko.observable(false);
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

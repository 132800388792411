import * as ko from 'knockout';
import { dir, log } from '../../../debug';
import { golemMatch } from '../../../golem';
import { getGolemData, isStringArray } from '../../../Gremlin';
import { DONE } from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import * as ARRAYHELPER from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { translate } from '../../model/ItemDataModel';
import { OrderingWordsData, OrderingWordsData_Answer } from '../../model/orderingwords/OrderingWordsData';
import { GetSession } from '../../model/session';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './orderingwords.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-orderingwords';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

interface IAnswerListItem {
    id: string;
    value: string;
}

class AnswerItem {
    public readonly id: string;
    public readonly latinId: string;
    public readonly value: string;
    constructor(readonly model: MyModel, readonly data: OrderingWordsData_Answer) {
        this.id = data.id;
        this.latinId = ARRAYHELPER.greekToLatin(this.id.substr(-1));
        this.value = data.value;
    }
    public readonly isSelected = ko.pureComputed(() => {
        return this.model.selectedAnswerId() === this.id;
    });
    public click = new UIAction(undefined, async () => {
        await this.model.selectAnswer.intent(this.id);
    });
    public readonly index = ko.pureComputed(() => {
        return this.data.index();
    });
}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    // public readonly selectedAnswer = ko.observable<AnswerItem>();

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof OrderingWordsData)) {
            throw new Error();
        }
        this.data = data;
        for (const answer of data.answers.map(x => new AnswerItem(this, x))) {
            this.answers.set(answer.id, answer);
        }
    }

    public readonly correctOrderText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.orderingwords.CORRECT_ORDER']);
    });

    private gremlinState: {
        targetOrder: string[]
    };
    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (!this.gremlinState) {
            const golemData = getGolemData(this.itemId);
            let targetOrder: string[];
            if (isStringArray(golemData)) {

                const options = this.data.answerOrder().map(x => ({
                    key: x,
                    value: this.data.answers.find(a => a.id === x).value
                }));
                dir({
                    options,
                    golemData
                });
                targetOrder = golemData.map(key => golemMatch(options, key));
            }
            if (!targetOrder) {
                targetOrder = ARRAYHELPER.shuffleArray(this.data.answerOrder().slice());
            }
            this.gremlinState = {
                targetOrder
            };
        }
        for (let nWord = 0; nWord < this.gremlinState.targetOrder.length; ++nWord) {
            if (this.data.answerOrder()[nWord] === this.gremlinState.targetOrder[nWord]) {
                continue;
            }
            const pos = this.shuffledAnswers().findIndex(x => x.id === this.gremlinState.targetOrder[nWord]);
            const p = this.shuffledAnswers()[pos];
            if (!p.isSelected()) {
                await p.click.invoke(undefined, true);
                return true;
            }
            const moveLeft = pos - nWord;
            if (moveLeft > 0) {
                await this.moveLeft.invoke(undefined, true);
                return true;
            }
        }
        return false;
    }
    public readonly data: OrderingWordsData;
    public readonly answers = new Map<string, AnswerItem>();
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);
    }

    public async OnReset() {
        this.selectedAnswerId(undefined);
        return DONE;
    }
    public selectAnswer = new UIAction<string>(undefined, async (e, answerId) => {
        this.selectedAnswerId(answerId);
    });

    public readonly selectedAnswer = ko.pureComputed(() => {
        if (!this.selectedAnswerId()) {
            return undefined;
        }
        return this.answers.get(this.selectedAnswerId());
    });

    public readonly isMovableLeft = ko.pureComputed(() => {
        const ans = this.selectedAnswer();
        if (!ans) {
            return false;
        }
        return ans.index() > 0;
    });
    public moveLeft = new UIAction(undefined, async () => {
        await this.data.moveLeft(this.selectedAnswerId());
    });

    public readonly isMovableRight = ko.pureComputed(() => {
        const ans = this.selectedAnswer();
        if (!ans) {
            return false;
        }
        return ans.index() < (this.answers.size - 1);
    });
    public moveRight = new UIAction(undefined, async () => {
        await this.data.moveRight(this.selectedAnswerId());
    });
    public readonly selectedAnswerId = ko.observable<string>();
    public readonly shuffledAnswers = ko.pureComputed(() => {
        return this.data.answerOrder().map(id => this.answers.get(id));
    });

    public readonly correctAnswerIdString = ko.pureComputed(() => {
        return this.data.rawData.owCorrectAnswer.map(val => ARRAYHELPER.greekToLatin(val.id.substr(-1))).join(' ');
    });

    public readonly answerString = ko.pureComputed(() => {
        return this.data.rawData.owCorrectAnswer.map(val => translate(val.value, this.params)).join(' ');
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly answerList = ko.pureComputed(() => {
        const retVal: Array<IAnswerListItem> = [];
        this.data.rawData.owCorrectAnswer.map((val, index) => {
            retVal.push({
                id: ARRAYHELPER.greekToLatin(val.id.substr(-1)),
                value: translate(val.value, this.params),
            });
        });
        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { TypingTest_Fragment_Mode } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { TypingTestData } from '../../model/typingtest/TypingTestData';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';


export type IParams = IItemDefinitionWidgetParams;

const WIDGET_NAME = 'itemdefinition-typingtest-result';

export class ViewModel extends AbstractItemDefinition {
    public itemId: string;
    public readonly data: TypingTestData;

    constructor(readonly params: IParams, readonly rootElem: HTMLElement) {
        super();

        this.itemId = params.itemId;
        const item = GetSession(this.params.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof TypingTestData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly writtenLengthText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest.result.WRITTEN_LENGTH']);
    });
    public readonly errorsText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest.result.ERRORS']);
    });
    public readonly errorQuotaText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest.result.ERROR_QUOTA']);
    });
    public readonly writtenLength = ko.pureComputed(() => this.data.rawData.textLength);
    public readonly errors = ko.pureComputed(() => this.data.errors());
    public readonly errorQuota = ko.pureComputed(() => {
        if (!this.writtenLength()) {
            return 0;
        }
        return Math.round(this.errors() * 100 * 100 / this.writtenLength()) / 100;
    });

    public readonly loaded = ko.observable(false);

    public readonly output = ko.observable('');

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        const outputHtml = this.data.rawData.fragments.map(x => {
            const pointsSpan = x.errorPoints > 0 ? `<span class="errorPoints"><span>${x.errorPoints}</span></span>` : '';
            switch (x.mode) {
                case TypingTest_Fragment_Mode.Normal:
                    return x.text;
                case TypingTest_Fragment_Mode.Added:
                    return `<span class="added">${x.text}${pointsSpan}</span>`;
                case TypingTest_Fragment_Mode.Removed:
                    return `<span class="removed">${x.text}${pointsSpan}</span>`;
                default:
                    throw new Error();
            }
        }).join('');
        this.output(outputHtml);
        this.loaded(true);
    }

    dispose() {
        super.dispose();
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo.element as HTMLElement);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

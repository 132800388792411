import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './modulestatuslegend.html.g';

const WIDGET_NAME = 'ui-widgets-modulestatuslegend';
const WIDGET_PARENT_NAME = 'ui';

export type IParams = undefined;

export class MyModel extends AbstractWidget {
    constructor(params: IParams) {
        super();
    }
    public readonly legendOpenText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.modulestatuslegend.CAN_BE_STARTED']);
    });
    public readonly legendActiveText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.modulestatuslegend.IS_ACTIVE']);
    });
    public readonly legendClosedText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.modulestatuslegend.IS_CLOSED']);
    });
    public readonly legendEvaluationNeededText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.modulestatuslegend.NEEDS_TO_BE_EVALUATED']);
    });
    public readonly legendEvaluatedText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.modulestatuslegend.EXAM_WAS_EVALUATED']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { IUpsertGolemScriptInput } from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_golemscriptedit_init']>>;
type FormData = Q['golemScripts']['byId'] & { userSubjects: string[] };

type UPDATE = Omit<Partial<IUpsertGolemScriptInput>, "docRef">;
class MyFormBuilder extends FormBuilder<FormData, UPDATE> {

    protected onInit() {
        super.onInit();
        this.initData();
        this.initSystem();
    }
    private initData() {

        const g1 = this.addGroup(this.formOptions, {
            colCount: 2,
            caption: i18next.t(['ui.superuser.golemscriptedit.DATA']),
        });

        this.addTextBox(g1, {
            dataField: 'title',
            label: {
                text: i18next.t(['ui.superuser.golemscriptedit.TITLE']),
            }
        }, val => ({ title: val }));

        this.addTextBox(g1, {
            dataField: 'golemScriptId',
            label: {
                text: i18next.t(['ui.superuser.golemscriptedit.ID']),
            },
            validationRules: [
                {
                    type: 'required',
                    message: i18next.t(['ui.superuser.golemscriptedit.AN_ID_IS_REQUIRED']),
                },
                {
                    type: 'pattern',
                    pattern: '^[A-Z,0-9]+$',
                    message: i18next.t(['ui.superuser.golemscriptedit.AN_ID_MUST_ONLY_CONTAIN_UPPERCASE_LETTERS_AND_NUMBERS'])
                }
            ]
        }, val => ({ golemScriptId: val }));

        this.addTextArea(g1, {
            dataField: 'scriptYaml',
            label: {
                text: i18next.t(['ui.superuser.golemscriptedit.YAML']),
                location: 'top',
            },
            colSpan: 2,
            editorOptions: {
                autoResizeEnabled: true,
                maxHeight: 250,
            },
        }, val => ({ scriptYaml: val }));
    }
    private initSystem() {

        const g2 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.golemscriptedit.SYSTEM']),
            colCount: 2,
        });

        this.addCheckbox(g2, {
            dataField: 'isDeleted',
            label: {
                text: i18next.t(['ui.superuser.golemscriptedit.DISABLED']),
            }
        }, val => ({ isDeleted: val }));

        this.addTextBox(g2, {
            dataField: '_id',
            editorOptions: {
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
            },
            label: {
                text: i18next.t(['ui.superuser.golemscriptedit.INTERNAL_ID'])
            }
        });
    }

}

export class ViewModel extends DxWidget {

    private readonly formData: FormData = <any>{};

    public readonly form = new MyFormBuilder();

    public async OnRefresh() {
        await super.OnRefresh();
        const r = await ServerConnection.api.ui_superuser_golemscriptedit_init({ docRef: this.params.currentRoute.widgetParams.golemScriptDocRefId });
        //this._subjects.splice(0, this._subjects.length, ...r.data.subjects);
        Object.assign(this.formData, r.golemScripts.byId);
        //this.formData.userSubjects = this.formData.subjects.map(x => x.docReferenceId);
        await refreshDx(this);
    }

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        this.form.formOptions.onFieldDataChanged = x => {
            void this.updateDatabase(x.dataField, x.value);
        };
    }


    private async updateDatabase(fieldName: string, value: any) {
        const golemScriptDocRef = this.params.currentRoute.widgetParams.golemScriptDocRefId;
        const golemScriptId = this.formData.golemScriptId;
        const up = this.form.fields.get(fieldName);
        if (up) {
            await ServerConnection.api.ui_superusre_golemscriptedit_update({
                params: Object.assign({
                    golemScriptDocRef,
                    golemScriptId,
                }, up(value))
            });
            return DONE;

        }
        return DONE;
    }

    constructor(readonly params: IParams) {
        super();
    }
    public async initialize() {
        await super.initialize();
        this.initFormOptions();
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly loaded = ko.observable(false);
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import CodeMirror from 'codemirror';
//import * as themes from 'codemirror/theme';
import $ from 'jquery';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { htmlString } from './widget.html.g';


const WIDGET_NAME = 'widgets-code';
const WIDGET_PARENT_NAME = 'widgets';

export interface IParams {
    mode: string,
    value: ko.Subscribable<string>;
}

export class ViewModel extends AbstractWidget {
    private editor: CodeMirror.Editor;
    private readonly options: CodeMirror.EditorConfiguration = {};

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.options.mode = params.mode;
        this.options.theme = 'monokai';
        this.options.viewportMargin = Infinity;
        this.options.value = ko.isObservable(params.value) ? params.value() : params.value();
    }
    async initialize() {
        const elem = <HTMLElement>this.componentInfo.element;
        const markerElem = $(elem).find('[data-marker=\'code\']');
        this.editor = CodeMirror(markerElem.get(0), this.options);
        this.editor.on('change', (cm) => {
            var value = this.params.value;
            value(cm.getValue());
        });
        this.disposables.addDiposable(this.params.value.subscribe(() => {
            const val = this.params.value();
            if (this.editor.getValue() !== val) {
                this.editor.setValue(val);
            }
        }));
    }
    dispose() {
        const wrapperElement = $(this.editor.getWrapperElement());
        wrapperElement.remove();
        super.dispose();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput, SurveyTextInput_QueryItemSessionData_Option } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'SurveyTextInput_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;


export class SurveyTextInputData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: meta.attachments,
        });
        this.option = data.stiOption;
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'SurveyTextInput_QueryItemSessionData') {
            throw new Error();
        }
        this.essayText(data.essayText);
        this.done(data.stiDone);
    }
    public readonly option: SurveyTextInput_QueryItemSessionData_Option;
    public readonly questionHTML: string;
    public readonly headerText: string;
    public readonly essayText = ko.observable<string>();
    public readonly done = ko.observable(false);

    public async setAnswer(text: string) {
        this.essayText(text);
        if (this.option === 'Required') {
            this.done(!!text);
        } else {
            if (text) {
                this.done(true);
            }
        }
    }

    public IsInteractionStarted() {
        return this.done();
    }
    public IsInteractionComplete() {
        return this.done();
    }
    public async reset() {
        this.essayText('');
        this.done(false);
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SurveyTextInput: {
                text: this.essayText(),
                done: this.done(),
            }
        };
        return retVal;
    }

}

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { InvisibleHotSpotEdit_Scoring, InvisibleHotSpot_CorrectState, IUpdateItemStateInput } from '../../../its-itembank-api.g';
import { indexToLatin } from '../../../new_array';
import * as RICHTEXTHTML from '../../../richtext/html';
import { ServerConnection } from '../../../ui/RestAPI';
//import { InvisibleHotSpotEdit_Scoring as ScoringMode } from '../init.query.graphql.g';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'InvisibleHotSpot_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

type RECT = DATA['ihsZones'][0];
type ANSWER = DATA['ihsAnswers'][0];

export class InvisibleHotspotData_Answer {
    constructor(private readonly model: InvisibleHotspotData, public readonly rawData: ANSWER) {
        this.id = rawData.id;
        this.index = rawData.index;
        this.x = rawData.x;
        this.y = rawData.y;
        this.correct = rawData.correct;
    }
    public readonly id: string;
    public readonly index: string;
    public readonly x: number;
    public readonly y: number;
    public readonly correct: InvisibleHotSpot_CorrectState;
    //public readonly isSelected = ko.pureComputed(() => this.id === this.model.selectedAnswer());
    //public readonly isCorrectAnswer = ko.pureComputed(() => this.id === this.model.correctAnswer);
}

export class InvisibleHotspotData extends ItemDataModel {
    public readonly imgUrl: string;
    public readonly imgAlt: string;
    public readonly zoneRects: RECT[];
    public readonly scoringMode: InvisibleHotSpotEdit_Scoring;
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        const imgAtt = meta.getAttachment(data.ihsImage?.id);
        this.imgUrl = ServerConnection.getDataUrl(imgAtt?.hrefResolved);
        this.imgAlt = imgAtt?.altText?.value ?? '';
        this.imageWidth = data.imageWidth;
        this.imageHeight = data.imageHeight;
        //this.correctAnswer = data.correctAnswer;
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.markerRadius = data.markerRadius;
        this.minAnswers = data.minAnswers;
        this.maxAnswers = data.maxAnswers;
        this.scoringMode = data.scoringMode;

        this.zoneRects = [];
        if (data.ihsZones) {
            const groups = new Map<string, string>();
            for (const rect of data.ihsZones) {
                const origGroup = rect.group;
                if (!groups.has(origGroup)) {
                    groups.set(origGroup, indexToLatin(groups.size));
                }
                this.zoneRects.push({
                    group: groups.get(origGroup),
                    ...rect
                });
            }
        }
        this.fromJS(data);
    }

    public addAnswer({ x, y }: { x: number, y: number }) {
        if (this.answers().length >= this.maxAnswers) {
            return;
        }
        let index = '';
        const indexes = new Set(this.answers().map(x => x.index));
        for (let i = 0; ; ++i) {
            index = 'P' + i;
            if (!indexes.has(index)) {
                break;
            }
        }
        this.answers.push(new InvisibleHotspotData_Answer(this, { id: '', index, x, y }));
    }

    public fromJS(data: DATA) {
        if (data.__typename !== 'InvisibleHotSpot_QueryItemSessionData') {
            throw new Error();
        }
        this.answers(data.ihsAnswers.map(a => new InvisibleHotspotData_Answer(this, a)));
    }
    public readonly questionHTML: string;
    public readonly imageWidth: number;
    public readonly imageHeight: number;
    public readonly markerRadius: number;
    public readonly minAnswers: number;
    public readonly maxAnswers: number;
    public readonly answers = ko.observableArray<InvisibleHotspotData_Answer>([]);
    //public readonly selectedAnswer = ko.observable<string>();
    //public readonly correctAnswer: string;
    public readonly headerText: string;
    /*
    public async setAnswer(answerId: string) {
        this.selectedAnswer(answerId);
    }
*/
    public IsInteractionStarted() {
        return this.answers().length > 0;
    }
    public IsInteractionComplete() {
        const nAnswers = this.answers().length;
        return nAnswers >= this.minAnswers && nAnswers <= this.maxAnswers;
    }
    public async reset() {
        this.answers([]);
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            InvisibleHotSpot: {
                givenAnswers: this.answers().map(x => ({
                    answerId: x.index,
                    x: x.x,
                    y: x.y,
                })),
            },
        };
        return retVal;
    }

}

﻿import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { htmlString } from './test.html.g';

const WIDGET_NAME = 'widgets-test';
const WIDGET_PARENT_NAME = 'widgets';

export type IParams = undefined;

export class MyModel extends AbstractWidget {
    public counter = ko.observable(0);

    constructor(params: IParams) {
        super();
    }

    public add() {
        this.counter(this.counter() + 1);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, dxAddTab_Component } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import { formatMessage } from '../../../../i18n/data';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../../model/interfaces';
import * as RICHTEXTHTML from '../../../../richtext/html';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { registerOnUpdateToolbar } from '../../../../ui/toptoolbar.service';
import { UIAction } from '../../../../ui/uiAction';
import * as i18next from './../../../../i18n/i18n';
import { Q, GradingGap } from './data';
//import * as Q from './data.query.graphql.g';
import * as GAP_WIDGET from './gap/widget';
//import * as SUBMIT from './submit.mutation.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-textentry_manual-grading';


export interface IImage {
    ind: number;
    name: string;
}

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly mode = ko.observable<ItemMode>('GRADING');
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
    }

    private rawData: Q['TextEntryManualGrading']['get'];
    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.textentry_manual_grading_data({
            itemId: this.itemId,
        });

        const d = q.TextEntryManualGrading.get;
        this.rawData = d;
        this.gradingGaps(d.gaps.map(gap => new GradingGap(this, gap)));
        this._question(d.question.value);
        this._header(d.header.value);
        this._text(d.text.value);
    }

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'far fa-check-circle',
                text: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.SUBMIT']),
                onClick: this.gradingSubmitAction.click
            })
        }));
        return DONE;
    }

    public async initialize() {
        await super.initialize();
        await ServerConnection.api.textentry_manual_grading_update({
            itemId: this.itemId
        });
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }

    public readonly gradingGaps = ko.observable<GradingGap[]>();

    public readonly questionHTML = ko.pureComputed(() => {
        return RICHTEXTHTML.process({
            html: this._question(),
            attachments: this.rawData && this.rawData.attachments,
        });
    });

    public readonly headerText = ko.pureComputed(() => {
        return this._header();
    });

    private readonly _question = ko.observable('');
    private readonly _header = ko.observable('');
    private readonly _text = ko.observable('');

    public readonly gradingSubmitAction = new UIAction(undefined, async () => {
        const message = i18next.t(['itemdefinition.kosovo.textentry_manual.grading.DO_YOU_WANT_TO_SUBMIT_THE_EVALUATION_OF_THIS_ITEM']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }
        await this.gradingSubmit();
    });


    private async gradingSubmit() {
        await ServerConnection.api.textentry_manual_grading_submit({
            itemId: this.itemId
        });
        await legacyPushPull();
    }

    public readonly gradingText = ko.pureComputed(() => {
        let retVal = '';
        const text = this._text();
        const gapRegEx = /\$\(gap\:([0-9]+)\)/gi;
        const fragments = text.split(gapRegEx);
        fragments.forEach((textFrag, index) => {
            if (index % 2) {
                const text = formatMessage(i18next.t(['itemdefinition.kosovo.textentry_manual.grading.GAP_GAPID']), { gapId: textFrag });
                retVal += `[${text}]`;
            } else {
                retVal += textFrag;
            }
        });
        return retVal;
    });
    public readonly tabs = ko.pureComputed(() => {
        const retVal = AS<DevExpress.ui.dxTabPanel.Properties>({

        });
        for (const gap of this.gradingGaps()) {
            const g = gap;
            dxAddTab_Component({
                component: GAP_WIDGET.WIDGET_NAME,
                widget: this,
                tabPanel: retVal,
                tab: {
                    title: formatMessage(i18next.t(['itemdefinition.kosovo.textentry_manual.grading.GAP_GAPID']), { gapId: gap.gapId() }),

                },
                componentParams: ko.pureComputed(() => AS<GAP_WIDGET.IParams>({
                    gap: g,
                })),
            });
        }
        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

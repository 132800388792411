export const id = 'kosovo-matchingtext';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '2be4fb6c-588e-44c5-8270-69bf237ca88e',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});
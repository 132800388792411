import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput, SurveyCombo_ShowHideButtonOrder, SurveyCombo_ShowSubItems, SurveyCombo_SubItem_StatusEnum } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'SurveyCombo_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type SUBITEM = DATA['scSubItems'][0];
//export { SurveyCombo_ShowHideButtonOrder, SurveyCombo_ShowSubItems } from '../init.query.graphql.g';
export class SurveyComboData_SubItem {
    constructor(readonly parent: SurveyComboData, data: SUBITEM, readonly index: number) {
        this.itemType = data.itemType;
        this.itemDocRefId = data.itemDocRefId;
        this.fromJS(data);
    }
    public fromJS(data: SUBITEM) {
        this.status(data.status);
    }
    public readonly itemDocRefId: string;
    public readonly itemType: string;
    public readonly status = ko.observable<SurveyCombo_SubItem_StatusEnum>();

}

export class SurveyComboData extends ItemDataModel {
    public readonly subItems: SurveyComboData_SubItem[];
    public readonly order: SurveyCombo_ShowHideButtonOrder;
    public readonly showSubItems = ko.observable<SurveyCombo_ShowSubItems>(SurveyCombo_ShowSubItems.Undefined);
    public readonly subItemsVisible = ko.pureComputed(() => {
        if (this.order === SurveyCombo_ShowHideButtonOrder.None) {
            return true;
        }
        return this.showSubItems() === SurveyCombo_ShowSubItems.Yes;
    });

    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.order = data.showHideButtonOrder;
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.text, {}),
            attachments: this.meta.attachments,
        });
        this.showCaption = translate(data.showCaption, {});
        this.hideCaption = translate(data.hideCaption, {});
        this.subItems = data.scSubItems.map((d, index) => new SurveyComboData_SubItem(this, d, index));
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        this.showSubItems(data.showSubItems);
        for (let i = 0; i < data.scSubItems.length; ++i) {
            this.subItems[i].fromJS(data.scSubItems[i]);
        }
    }
    public readonly headerText: string;
    public readonly questionHTML: string;
    public readonly showCaption: string;
    public readonly hideCaption: string;


    public IsInteractionStarted() {
        if (this.order != SurveyCombo_ShowHideButtonOrder.None) {
            if (this.showSubItems() === SurveyCombo_ShowSubItems.Undefined) {
                return false;
            }
            return true;
        }
        if (!this.subItems.length) {
            return true;
        }
        return this.subItems.some(x => this.meta.session.getItemData(x.itemDocRefId).IsInteractionStarted());
    }
    public IsInteractionComplete() {
        if (this.order != SurveyCombo_ShowHideButtonOrder.None) {
            if (this.showSubItems() === SurveyCombo_ShowSubItems.Undefined) {
                return false;
            }
            if (this.showSubItems() === SurveyCombo_ShowSubItems.No) {
                return true;
            }
        }
        if (!this.subItems.length) {
            return true;
        }
        return this.subItems.every(x => this.meta.session.getItemData(x.itemDocRefId).IsInteractionComplete());
    }
    public async reset() {
        for (const subItem of this.subItems) {
            const subItemData = this.meta.session.getItemData(subItem.itemDocRefId);
            await subItemData.reset();
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SurveyCombo: {
                showSubItems: this.showSubItems(),
            }
        };
        return retVal;
    }

}

import * as ko from 'knockout';
import { log } from '../../debug';
import { getGolemData, golemMatch } from '../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { getRandomEntry } from '../../new_array';
import { UIAction } from '../../ui/uiAction';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { GetSession } from '../model/session';
import { SurveySingleChoiceMatrixData, SurveySingleChoiceMatrixData_Cell, SurveySingleChoiceMatrixData_Column, SurveySingleChoiceMatrixData_Row } from '../model/surveysinglechoicematrix/SurveySingleChoiceMatrixData';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveysinglechoicematrix';
const WIDGET_PARENT_NAME = 'itemdefinition';

export class VMCell {
    public readonly cellId: string;
    public readonly isSelected = ko.pureComputed(() => this.cell.isSelected());

    constructor(readonly model: MyModel, public rowId: string, readonly cell: SurveySingleChoiceMatrixData_Cell) {
        this.cellId = cell.id;
    }

    public readonly click = new UIAction(undefined, async () => {
        await this.model.clickAnswer.intent({ cellId: this.cellId, rowId: this.rowId });
    });

}

export class VMRow {
    public readonly id: string;
    constructor(readonly model: MyModel, readonly data: SurveySingleChoiceMatrixData_Row) {
        this.id = data.id;
    }

    public readonly text = ko.pureComputed(() => {
        return this.data.text;
    });

    public readonly cells = ko.pureComputed(() => {
        return this.data.cells.map(cell => new VMCell(this.model, this.id, cell));
    });
}

export class VMColumn {
    public readonly value: string;
    constructor(readonly model: MyModel, readonly col: SurveySingleChoiceMatrixData_Column) {
        this.value = col.value;
    }
}

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SurveySingleChoiceMatrixData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: SurveySingleChoiceMatrixData;
    public readonly surveyOptionText = ko.pureComputed(() => {
        switch (this.data.option) {
            case 'Required':
                return '';
            case 'Optional':
                return i18next.t(['itemdefinition.surveysinglechoicematrix.YOU_DO_NOT_NEED_TO_ANSWER_THIS_ITEM']);
            default:
                return '';
        }
    });

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        const rows = this.shuffledRows();
        const rowMap = new Map<string, number>();
        const golemData = getGolemData(this.itemId);
        log(`Golem data: ${JSON.stringify(golemData)}`);
        if (typeof golemData === 'object' && !Array.isArray(golemData)) {
            for (const rowKey of Object.keys(golemData)) {
                const rowId = golemMatch(rows.map(x => ({ key: x.id, value: x.text() })), rowKey);
                const row = rows.find(x => x.id === rowId);
                log(`match row: ${rowKey}=>${row && row.id}`);
                if (row) {
                    const colKey = golemData[rowKey];
                    log(`col key: ${colKey}`);
                    if (typeof colKey === 'string') {
                        const idx = golemMatch(this.shuffledColumns().map((x, idx) => ({ key: idx, value: x.value })), colKey);
                        log(`match col: ${colKey}=>${idx}`);
                        if (idx >= 0) {
                            log(`Golem says ${row.id}=>${idx}`);
                            rowMap.set(row.id, idx);
                        }
                    }
                }
            }
        }
        for (const row of rows) {
            const cells = row.cells();
            if (cells.some(x => x.isSelected())) {
                continue;
            }
            if (rowMap.has(row.id)) {
                const idx = rowMap.get(row.id);
                const cell = row.cells()[idx];
                log(`Golem says we should select ${cell.cellId} (#${idx})`);
                await cell.click.invoke();
                return true;
            } else {
                log(`Golem has no information about ${row.id}, select random entry`);
                await getRandomEntry(cells).click.invoke();
                return true;
            }
        }
        return false;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.loaded(true);

    }




    public readonly shuffledRows = ko.pureComputed(() => {
        return this.data.rows.map(val => new VMRow(this, val));
    });

    public readonly shuffledColumns = ko.pureComputed(() => {
        return this.data.columns.map(val => new VMColumn(this, val));
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly clickAnswer = new UIAction<{ rowId: string, cellId: string }>(undefined, async (e, ids) => {
        const cellId = ids.cellId;
        await this.data.setAnswer({ cellId });
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import STABLE_STRINGIFY from 'json-stable-stringify';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { writeToClipboard } from '../../../clipboard';
import { dir, log } from '../../../debug';
import { AtoZ } from '../../../dxHelper/graphQLDataSource';
import { KoObservableObject, KoStore, UpdateObject } from '../../../dxHelper/koStore';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import * as HELPER from '../../../helper';
import { bounds, DONE, DONEENUM } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { IMultistepHotSpotEdit_UpsertAreaRect } from '../../../its-itembank-api.g';
import { KoMap } from '../../../komap';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { ServerConnection } from '../../../ui/RestAPI';
import { registerOnUpdateToolbar } from '../../../ui/toptoolbar.service';
import { UIAction } from '../../../ui/uiAction';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['multistephotspot_edit_data']>>;

const WIDGET_NAME = 'itemdefinition-multistephotspot-edit';

let TMP_ID = 0;
type ZONE = STEP['zones'][0];
type RECT = ZONE['rects'][0];
type CIRCLE = ZONE['circles'][0];
type STEP = Q['MultistepHotSpotEdit']['get']['steps'][0];
type ATTACHMENT = Q['documents']['get']['attachments'][0];

type mode = 'New' | 'Info' | 'SetCenter' | 'SetLeft' | 'SetTop' | 'SetRight' | 'SetBottom' | 'SetTopLeft' | 'SetBottomRight';

interface StepGridData {
    stepId: string;
    authorHint: string;
    isStartingStep: boolean;
}
interface ZoneGridData {
    zoneId: string;
    stepLinkId: string;
}
interface RectGridData {
    areaId: string;
    cX: number;
    cY: number;
    width: number;
    height: number;
}
interface ButtonGroupItem<T> extends DevExpress.ui.dxButtonGroupItem {
    key: T
}
type ModeButton = ButtonGroupItem<mode>;

function tmpId() {
    return 'TMP_' + (TMP_ID++);
}
interface SVGRect {
    type: 'rect';
    width: string;
    height: string;
    cssClass: string;
    x: string;
    y: string;
    id: string;
}
interface SVGCircle {
    type: 'circle';
    cx: string;
    cy: string;
    r: string;
    cssClass: string;
}
type SVGObj = SVGCircle | SVGRect;

function n(n: number) {
    return Math.round(n * 10) / 10;
}

class Rect implements KoObservableObject<RectGridData>{
    constructor(readonly model: ZoneVM, readonly iid: string) {

    }
    public cX = ko.observable(0);
    public cY = ko.observable(0);
    public width = ko.observable(0);
    public height = ko.observable(0);
    public areaId = ko.observable('');
    public zoneId = ko.pureComputed(() => this.model.zoneId());

    public fromData(data: RECT) {
        this.areaId(data.areaId);
        this.cX(data.centerX);
        this.cY(data.centerY);
        this.width(data.width);
        this.height(data.height);

    }
    public readonly upsertCode = ko.pureComputed(() => {
        const retVal: IMultistepHotSpotEdit_UpsertAreaRect = {
            areaId: this.areaId(),
        };
        retVal.centerX = this.cX();
        retVal.centerY = this.cY();
        retVal.width = this.width();
        retVal.height = this.height();
        return retVal;
    });

    public isFocused = ko.pureComputed(() => {
        log(`isFocused ${this.iid}===${this.model.focusedArea()}`);
        return this.model.isFocused() && this.iid === this.model.focusedArea();
    });

    hittest(rectA: { left: number, top: number, width: number, height: number }) {

        const rectB = {
            left: this.cX() - this.width() / 2,
            top: this.cY() - this.height() / 2,
            width: this.width(),
            height: this.height()
        };
        const rA = rectA.left + rectA.width; // Right side of rectA
        const rB = rectB.left + rectB.width; // Right side of rectB
        const bA = rectA.top + rectA.height; // Bottom of rectA
        const bB = rectB.top + rectB.height; // Bottom of rectB

        const hitX = rA > rectB.left && rectA.left < rB; // True if hit on x-axis
        const hitY = bA > rectB.top && rectA.top < bB; // True if hit on y-axis

        // Return true if hit on x and y axis
        return hitX && hitY;
    }

    public svgDeadZone = ko.pureComputed(() => {
        const mW = this.model.vm.markerWidth();
        const mH = this.model.vm.markerHeight();
        const w = this.width() + 2 * mW;
        const h = this.height() + 2 * mH;
        const selectedClass = this.isFocused() ? 'areaSelected' : '';
        return AS<SVGRect>({
            id: this.iid,
            type: 'rect',
            width: w + '%',
            height: h + '%',
            x: (this.cX() - w / 2) + '%',
            y: (this.cY() - h / 2) + '%',
            cssClass: `area-${this.zoneId()} restricted ${selectedClass}`,
        });
    });

    public svgShape = ko.pureComputed(() => {
        const w = this.width();
        const h = this.height();
        const selectedClass = this.isFocused() ? 'areaSelected' : '';
        return AS<SVGRect>({
            id: this.iid,
            type: 'rect',
            width: w + '%',
            height: h + '%',
            x: (this.cX() - w / 2) + '%',
            y: (this.cY() - h / 2) + '%',
            cssClass: `area-${this.zoneId()} area ${selectedClass}`,
        });
    });
}

function hittest_circle(circle: { x: number, y: number, r: number }, box: { left: number, top: number, width: number, height: number }) {
    const cx = circle.x;
    const cy = circle.y;
    const radius = circle.r;
    // temporary variables to set edges for testing
    let testX = cx;
    let testY = cy;

    // which edge is closest?
    if (cx < box.left) {
        testX = box.left;      // test left edge
    } else if (cx > box.left + box.width) {
        testX = box.left + box.width;   // right edge
    }
    if (cy < box.top) {
        testY = box.top;      // top edge
    } else if (cy > box.top + box.height) {
        testY = box.top + box.height;   // bottom edge
    }

    // get distance from closest edges
    const distX = cx - testX;
    const distY = cy - testY;
    const distance = Math.sqrt((distX * distX) + (distY * distY));

    // if the distance is less than the radius, collision!
    if (distance <= radius) {
        return true;
    }
    return false;
}

export class ZoneVM extends DxWidget implements KoObservableObject<ZoneGridData> {
    constructor(readonly vm: ViewModel, readonly stepVM: StepVM, readonly iid: string) {
        super();
    }

    public readonly zoneId = ko.observable('');
    public readonly stepLinkId = ko.observable('');
    public readonly sectionLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.multistephotspot.edit.AREAS_OF_STEP_STEPID_ZONE_ZONEID'], { stepId: this.stepVM.stepId(), zoneId: this.zoneId() });
    });

    public isFocused = ko.pureComputed(() => {
        return this.stepVM.isFocused() && this.iid === this.stepVM.focusedZoneIID();
    });

    public readonly upsertCode = ko.pureComputed(() => {
        const retVal: API.IMultistepHotSpotEdit_UpsertZone = {
            zoneId: this.zoneId(),
        };
        retVal.stepLinkId = this.stepLinkId();
        const setRects = this._areas.values().map(x => x.upsertCode());
        retVal.setRects = setRects;
        return retVal;
    });
    private readonly areaGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    public readonly _areas = new KoMap<Rect>();
    public readonly focusedArea = ko.observable('');
    public readonly areasStore = new KoStore<Rect, RectGridData, 'iid'>({
        key: 'iid',
        fetch: () => {
            return this._areas.values();
        },
        indexGenerator: AtoZ,
        onNewRow: async (data) => {
            data.areaId = AtoZ(new Set(this._areas.values().map(x => x.areaId())));
            data.cX = 0;
            data.cY = 0;
            data.height = 10;
            data.width = 10;
        },
        insert: async (values) => {
            const newOne = new Rect(this, tmpId());
            UpdateObject(newOne, values);
            this._areas.set(newOne.iid, newOne);
            return newOne;
        },
        update: async (key, values) => {
            dir({ ev: 'Areas Update', key, values });
            const obj = this._areas.get(key);
            return DONEENUM.DONE;
        },
        remove: async (key: string) => {
            this._areas.delete(key);
            return DONEENUM.DONE;
        },
    });
    public readonly areaGridOptions = ko.pureComputed(() => {
        const gridOptions = datagrid({
            WIDGET_NAME,
            discriminator: 'area_v1',
            widget: this,
            gridVar: this.areaGrid,
            config: {
                dataSource: {
                    store: this.areasStore.store
                },
                onInitNewRow: this.areasStore.initNewRow,
                autoNavigateToFocusedRow: true,
                editing: {
                    allowAdding: false,
                    allowUpdating: !this.vm.isReadOnly,
                    allowDeleting: !this.vm.isReadOnly,
                    mode: 'cell',
                },
                focusedRowEnabled: true,

                onFocusedRowChanged: e => {
                    const key = e.row && e.row.key;
                    log(`on focused row changed to ${key}`);
                    this.focusedArea(key);
                },

            }

        });

        gridOptions.columns.push({
            dataField: 'areaId',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.AREA_ID']),
            dataType: 'string',
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                maxLength: 1,
            }),
            validationRules: [{
                type: 'pattern',
                pattern: /^[A-Z]$/,
            }]
        });

        gridOptions.columns.push({
            dataField: 'cX',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.CENTERX']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
                showSpinButtons: true,
                mode: 'number',
            }),
        });
        gridOptions.columns.push({
            dataField: 'cY',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.CENTERY']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        });
        gridOptions.columns.push({
            dataField: 'width',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.WIDTH']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        });
        gridOptions.columns.push({
            dataField: 'height',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.HEIGHT']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        });

        return gridOptions;
    });

    public fromData(data: ZONE) {
        this.zoneId(data.zoneId);
        this.stepLinkId(data.stepLinkId);
        for (const rSrc of data.rects) {
            if (!this._areas.has(rSrc.id)) {
                this._areas.set(rSrc.id, new Rect(this, rSrc.id));
            }
            this._areas.get(rSrc.id).fromData(rSrc);
        }
        this._areas.keep(new Set(data.rects.map(x => x.id)));
    }
    public async clickImage(e: { offsetX: number, offsetY: number }, step: StepVM) {
        const mode = this.vm.clickMode();
        log(`clicked on image in mode ${mode} `);
        dir(e);


        const x = bounds(e.offsetX, 0, step.actualWidth());
        const y = bounds(e.offsetY, 0, step.actualHeight());


        const xP = x * 100 / step.actualWidth();
        const yP = y * 100 / step.actualHeight();

        const focusedKey = this.focusedArea();
        const focused = focusedKey && this._areas.get(focusedKey);
        log(`focused: ${focusedKey}`);

        switch (mode) {
            case 'New':
                const r = new Rect(this, tmpId());
                r.areaId(AtoZ(new Set(this._areas.values().map(x => x.areaId()))));
                r.cX(n(xP));
                r.cY(n(yP));
                r.width(n(focused && focused.width() || 5));
                r.height(n(focused && focused.height() || 5));
                this._areas.set(r.iid, r);
                if (this.areaGrid()) {
                    void this.areaGrid().refresh();
                }
                break;
            case 'Info':
                step.markerPos({ xP, yP });
                const hit = step.hit();
                if (hit && hit.zone === this.iid && this.areaGrid()) {
                    log(`navigate to ${hit.area}`);
                    this.areaGrid().navigateToRow(hit.area);
                    await this.areaGrid().selectRows([hit.area], false);
                    this.focusedArea(hit.area);
                }
                return;
            case 'SetCenter':
                if (focused) {
                    focused.cX(n(xP));
                    focused.cY(n(yP));
                }
                break;
            case 'SetLeft':
                if (focused) {
                    const left = xP;
                    const right = focused.cX() + focused.width() / 2;
                    const width = right - left;
                    focused.cX(n(left + width / 2));
                    focused.width(n(width));

                }
                break;
            case 'SetRight':
                if (focused) {
                    const left = focused.cX() - focused.width() / 2;
                    const right = xP;
                    const width = right - left;
                    focused.cX(n(left + width / 2));
                    focused.width(n(width));
                }
                break;

            case 'SetTop':
                if (focused) {
                    const top = yP;
                    const bottom = focused.cY() + focused.height() / 2;
                    const height = bottom - top;
                    focused.cY(n(top + height / 2));
                    focused.height(n(height));

                }
                break;
            case 'SetBottom':
                if (focused) {
                    const top = focused.cY() - focused.height() / 2;
                    const bottom = yP;
                    const height = bottom - top;
                    focused.cY(n(top + height / 2));
                    focused.height(n(height));
                }
                break;
            case 'SetTopLeft':
                if (focused) {
                    const brX = focused.cX() + focused.width() / 2;
                    const brY = focused.cY() + focused.height() / 2;
                    const w = bounds(brX - xP, 0.5, 100);
                    const h = bounds(brY - yP, 0.5, 100);
                    focused.cX(n(brX - w / 2));
                    focused.cY(n(brY - h / 2));
                    focused.width(n(w));
                    focused.height(n(h));
                }
                break;
            case 'SetBottomRight':
                if (focused) {
                    const tlX = focused.cX() - focused.width() / 2;
                    const tlY = focused.cY() - focused.height() / 2;
                    const w = bounds(xP - tlX, 0.5, 100);
                    const h = bounds(yP - tlY, 0.5, 100);
                    focused.cX(n(tlX + w / 2));
                    focused.cY(n(tlY + h / 2));
                    focused.width(n(w));
                    focused.height(n(h));


                }
                break;
        }
        //await refreshDx(this);

    }

}

export class StepVM extends DxWidget implements KoObservableObject<StepGridData> {
    public readonly stepId = ko.observable('');

    public isFocused = ko.pureComputed(() => {
        return this.iid === this.vm.focusedStepId();
    });

    public readonly zonesStore = new KoStore<ZoneVM, ZoneGridData, 'iid'>({
        key: 'iid',
        fetch: () => {
            return this._zones.values();
        },
        indexGenerator: AtoZ,
        onNewRow: async (data) => {
            data.zoneId = AtoZ(new Set(this._zones.values().map(x => x.zoneId())));

        },
        insert: async (values) => {
            const newOne = new ZoneVM(this.vm, this, tmpId());
            UpdateObject(newOne, values);
            this._zones.set(newOne.iid, newOne);
            return newOne;
        },
        update: async (key, values) => {
            dir({ ev: 'ZonesStore Update', key, values });
            const obj = this._zones.get(key);
            return DONEENUM.DONE;
        },
        remove: async (key: string) => {
            this._zones.delete(key);
            return DONEENUM.DONE;
        },
    });
    public readonly upsertCode = ko.pureComputed(() => {
        const retVal: API.IMultistepHotSpotEdit_UpsertStep = {
            stepId: this.stepId()
        };
        retVal.image = this.image();
        retVal.imageWidth = this.imageWidth();
        retVal.imageHeight = this.imageHeight();
        retVal.isStartingStep = this.isStartingStep();
        retVal.authorHint = this.authorHint();

        const setZones = this._zones.values().map(x => x.upsertCode());
        retVal.setZones = setZones;
        return retVal;
    });

    constructor(readonly vm: ViewModel, readonly iid: string) {
        super();
    }

    public readonly stepTitle = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.multistephotspot.edit.STEP_STEPID'], { stepId: this.stepId() });
    });

    public readonly sectionLabel = ko.pureComputed(() => i18n.t(['itemdefinition.multistephotspot.edit.ZONES_OF_STEP_STEPID'], { stepId: this.stepId() }));
    public readonly image = ko.observable('');
    public readonly imageWidth = ko.observable(0);
    public readonly imageHeight = ko.observable(0);
    public readonly isStartingStep = ko.observable(false);
    public readonly authorHint = ko.observable('');
    private readonly zoneGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    private readonly _zones = new KoMap<ZoneVM>();
    public readonly imgUrl = ko.pureComputed(() => {
        const att = this.vm._attachments.find(x => x.name === this.image());
        if (!att) {
            return undefined;
        }
        return ServerConnection.getDataUrl(att && att.hrefResolved);
    });

    public readonly naturalImageWidth = ko.pureComputed(() => {
        const att = this.vm._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.width || 100;
    });
    public readonly naturalImageHeight = ko.pureComputed(() => {
        const att = this.vm._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.height || 100;
    });
    public readonly imgValid = ko.pureComputed(() => {
        return !!this.imgUrl();
    });
    public readonly hasImage = ko.pureComputed(() => {
        return !!this.imgUrl();
    });

    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.vm.isReadOnly,
            formData: {
                image: this.image,
                imageWidth: this.imageWidth,
                imageHeight: this.imageHeight,
                isStartingStep: this.isStartingStep,
                authorHint: this.authorHint,
            },
            colCount: 3,
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'image',
            editorType: 'dxSelectBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.singlechoicehotspot.edit.IMAGE']),
            },
            colSpan: 2,
            editorOptions: this.imageOptions(),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageWidth',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.IMAGE_WIDTH']),
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'decimal',
                },

            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageHeight',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.IMAGE_HEIGHT']),
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'decimal',
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'isStartingStep',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.STARTING_STEP'])
            },
            colSpan: 2,
            editorType: 'dxCheckBox',
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'authorHint',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.AUTHOR_HINT']),
            },
            colSpan: 2,
            editorType: 'dxTextArea',
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                autoResizeEnabled: true,
            }),
        }));
        return retVal;
    });

    public readonly imageOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxSelectBox.Properties = {
            value: this.image,
            dataSource: this.vm._attachments,
            valueExpr: 'name',
            displayExpr: 'name',
        };
        return retVal;
    });

    private readonly allAreas = ko.pureComputed(() => {
        const retVal = [];
        for (const z of this._zones.values()) {
            for (const r of z._areas.values()) {
                retVal.push(r);
            }
        }
        return retVal;
    });

    public readonly deadzones = ko.pureComputed(() => {
        return this.allAreas().map(x => x.svgDeadZone());
    });

    public readonly rects = ko.pureComputed(() => {
        return this.allAreas().map(x => x.svgShape());
    });

    public readonly focusedZoneIID = ko.observable('');
    public readonly focusedZone = ko.pureComputed(() => {
        const iid = this.focusedZoneIID();
        if (!iid) {
            return undefined;
        }
        return this._zones.get(iid);
    });


    public readonly clickImage = new UIAction(undefined, async (_1, _2, e) => {
        const focusedZone = this.focusedZone();
        if (!focusedZone) {
            return;
        }
        await focusedZone.clickImage(e, this);

        //await refreshDx(this);
    });


    public readonly markerPos = ko.observable<{ xP: number; yP: number }>();

    public readonly hit = ko.pureComputed<{ area: string, zone: string }>(() => {
        const pos = this.markerPos();
        if (!pos) {
            return undefined;
        }
        const { xP, yP } = pos;
        const marker_w = this.vm.markerWidth();
        const marker_h = this.vm.markerHeight();

        const boundingBox = {
            left: (xP - (marker_w / 2)),
            top: (yP - (marker_h / 2)),
            width: marker_w,
            height: marker_h,
        };

        for (const z of this._zones.values()) {
            for (const r of z._areas.values()) {
                if (r.hittest(boundingBox)) {
                    return {
                        area: r.iid,
                        zone: z.iid,
                    };
                }
            }
        }

        return undefined;
    });

    public readonly marker = ko.pureComputed<SVGObj[]>(() => {
        const pos = this.markerPos();
        if (!pos) {
            return [];
        }
        const { xP, yP } = pos;
        const marker_r = this.vm.markerRadius();
        const marker_w = this.vm.markerWidth();
        const marker_h = this.vm.markerHeight();

        const boundingBox = {
            left: (xP - (marker_w / 2)),
            top: (yP - (marker_h / 2)),
            width: marker_w,
            height: marker_h,
        };

        let isHit = false;
        for (const rect of this.allAreas()) {
            if (rect.hittest(boundingBox)) {
                isHit = true;
            }
        }


        const marker: SVGObj[] = [];
        marker.push({
            type: 'rect',
            x: boundingBox.left + '%',
            y: boundingBox.top + '%',
            width: marker_w + '%',
            height: marker_h + '%',
            cssClass: isHit ? 'hit' : 'notHit',
            id: '',
        });
        marker.push({
            type: 'circle',
            cx: xP + '%',
            cy: yP + '%',
            r: marker_r + '%',
            cssClass: isHit ? 'hit' : 'notHit'
        });
        return marker;
    });

    public async fromData(d: STEP) {
        this.image(d.image && d.image.name);
        this.imageWidth(d.imageWidth || 0);
        this.imageHeight(d.imageHeight || 0);
        this.isStartingStep(d.isStartingStep || false);
        this.authorHint(d.authorHint || '');
        this.stepId(d.stepId || '');

        //this._zones.splice(0, this._zones.length, ...d.zones);

        for (const a of d.zones) {
            if (!this._zones.has(a.id)) {
                const newZone = new ZoneVM(this.vm, this, a.id);
                this._zones.set(a.id, newZone);
            }
            this._zones.get(a.id).fromData(a);
        }
        this._zones.keep(new Set(d.zones.map(x => x.id)));

        await refreshDx(this);
    }

    public readonly zoneGridOptions = ko.pureComputed(() => {
        const gridOptions = datagrid({
            WIDGET_NAME,
            discriminator: 'zone_v1',
            widget: this,
            gridVar: this.zoneGrid,
            config: {
                dataSource: {
                    store: this.zonesStore.store,
                },
                onInitNewRow: this.zonesStore.initNewRow,
                autoNavigateToFocusedRow: true,
                editing: {
                    allowAdding: true,
                    allowUpdating: !this.vm.isReadOnly,
                    allowDeleting: !this.vm.isReadOnly,
                    mode: 'cell',
                },
                focusedRowEnabled: true,

                onFocusedRowChanged: e => {
                    const key = e.row && e.row.key;
                    log(`on focused zone changed to ${key}`);
                    this.focusedZoneIID(key);
                },

            }

        });

        gridOptions.columns.push({
            dataField: 'zoneId',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.ZONE_ID']),
            dataType: 'string',
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                maxLength: 1,
            }),
            validationRules: [{
                type: 'pattern',
                pattern: /^[A-Z]$/,
            }]
        });

        gridOptions.columns.push({
            dataField: 'stepLinkId',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.LINK_TO_STEP']),
            dataType: 'string',
        });

        return gridOptions;
    });

    public readonly actualWidth = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).width;
    });
    public readonly actualHeight = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).height;
    });
}
export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);
    //public readonly answers = ko.observableArray();
    public readonly header = ko.observable('');
    public readonly question = ko.observable('');

    public readonly markerWidth = ko.observable(0);
    public readonly markerHeight = ko.observable(0);
    public readonly markerRadius = ko.observable(0);

    public readonly _attachments: ATTACHMENT[] = [];
    public readonly steps = ko.pureComputed<StepVM[]>(() => {
        return this._steps.values();
    });
    public readonly _steps = new KoMap<StepVM>();
    //public readonly steps =new Map<string,StepVM>();

    public readonly focusedZone = ko.pureComputed(() => {
        const step = this.focusedStep();
        if (!step) {
            return undefined;
        }
        return step.focusedZone();
    });

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }


    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.HEADER'])
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.multistephotspot.edit.ENTER_THE_HEADER_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.multistephotspot.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.multistephotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']),
        }));
        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                markerWidth: this.markerWidth,
                markerHeight: this.markerHeight,
                markerRadius: this.markerRadius,
            },
            colCount: 2,
            items: [],
        };

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'markerWidth',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.MARKER_WIDTH'])
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'markerHeight',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.MARKER_HEIGHT'])
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'markerRadius',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.MARKER_RADIUS'])
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        }));

        return retVal;
    });

    public readonly focusedStepId = ko.observable('');
    private readonly stepGrid = ko.observable<DevExpress.ui.dxDataGrid>();

    public readonly focusedStep = ko.pureComputed(() => {
        const focusedStep = this._steps.get(this.focusedStepId());
        return focusedStep;
    });

    public readonly stepsStore = new KoStore<StepVM, StepGridData, 'iid'>({
        key: 'iid',
        fetch: () => {
            return this._steps.values();
        },
        indexGenerator: AtoZ,
        onNewRow: async (data) => {
            data.stepId = AtoZ(new Set(this._steps.values().map(x => x.stepId())));
            data.isStartingStep = false;
            data.authorHint = '';
        },
        insert: async (values) => {
            const newOne = new StepVM(this, tmpId());
            UpdateObject(newOne, values);
            this._steps.set(newOne.iid, newOne);
            return newOne;
        },
        update: async (key, values) => {
            dir({ ev: 'StepsStore Update', key, values });
            const obj = this._steps.get(key);
            return DONEENUM.DONE;
        },
        remove: async (key: string) => {
            this._steps.delete(key);
            return DONEENUM.DONE;
        },
    });

    public readonly actionCopyUpsert = new UIAction(undefined, async (e, args) => {
        await writeToClipboard(this.upsertCodeJson());
    });

    public readonly stepGridOptions = ko.pureComputed(() => {
        const gridOptions = datagrid({
            WIDGET_NAME,
            discriminator: 'step_v1',
            widget: this,
            gridVar: this.stepGrid,
            config: {
                dataSource: {
                    store: this.stepsStore.store,
                },
                onInitNewRow: async (e) => {
                    this.stepsStore.initNewRow(e);
                },
                autoNavigateToFocusedRow: true,
                editing: {
                    allowAdding: !this.isReadOnly,
                    allowUpdating: !this.isReadOnly,
                    allowDeleting: !this.isReadOnly,
                    mode: 'cell',
                },
                focusedRowEnabled: true,

                onRowUpdated: e => {
                    log(`on row updated ${e.key}`);
                    //this.onAreasChanged();
                },
                onRowRemoved: e => {
                    log(`on row removed ${e.key}`);
                    //this._rectsDS.remove(x => x.id === e.key);
                    //this._rectsDS.valueHasMutated();
                    //this.onAreasChanged();
                },
                onRowInserted: e => {
                    //this.onAreasChanged();
                },
                onFocusedRowChanged: e => {
                    const key = e.row && e.row.key;
                    log(`on focused row changed to ${key}`);
                    this.focusedStepId(key);
                },

            }

        });

        gridOptions.columns.push({
            dataField: 'stepId',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.STEP_ID']),
            dataType: 'string',
            width: 30,
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                maxLength: 1,
            }),
            validationRules: [{
                type: 'pattern',
                pattern: /^[A-Z]$/,
            }]
        });


        gridOptions.columns.push({
            dataField: 'authorHint',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.AUTHOR_HINT']),
            dataType: 'string',
        });
        gridOptions.columns.push({
            dataField: 'isStartingStep',
            caption: i18n.t(['itemdefinition.multistephotspot.edit.IS_STARTING_STEP']),
            dataType: 'boolean',
        });

        return gridOptions;
    });


    public readonly sectionLabel = ko.pureComputed(() => i18n.t(['itemdefinition.multistephotspot.edit.STEPS']));

    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.multistephotspot_edit_data({
            itemId: this.itemId
        });
        const d = q.MultistepHotSpotEdit.get;
        this.header(d.header.value);
        this.question(d.question.value);
        this.markerWidth(d.markerWidth);
        this.markerHeight(d.markerHeight);
        this.markerRadius(d.markerRadius);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);

        const current = new Set<string>();
        for (const step of d.steps) {
            const stepId = step.stepId;
            current.add(stepId);
            if (!this._steps.has(stepId)) {
                this._steps.set(stepId, new StepVM(this, stepId));
            }
            await this._steps.get(stepId).fromData(step);
        }
        this._steps.keep(current);
        this.lastSaved(this.upsertCodeJson());

    }

    private readonly upsertCode = ko.pureComputed(() => {
        const retVal: API.IMultistepHotSpotEditUpsertInput = {
            itemId: this.itemId,
            markerHeight: this.markerHeight(),
            markerRadius: this.markerRadius(),
            markerWidth: this.markerWidth(),
            header: { xnone: this.header() },
            question: { xnone: this.question() },
        };
        const setSteps = this._steps.values().map(x => x.upsertCode());
        retVal.setSteps = setSteps;
        return retVal;
    });

    public readonly upsertCodeJson = ko.pureComputed(() => {
        const r = this.upsertCode();
        return STABLE_STRINGIFY(r, { space: 2 });
    });

    private readonly lastSaved = ko.observable('');
    public readonly needsSave = ko.pureComputed(() => {
        return this.lastSaved() !== this.upsertCodeJson();
    });

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItem>({
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'far fa-copy',
                text: i18n.t(['itemdefinition.multistephotspot.edit.COPY_DEFINITION_TO_CLIPBOARD']),
                onClick: this.actionCopyUpsert.click
            })
        }));
        return DONE;
    }

    public readonly clickMode = ko.observable<mode>('Info');

    public readonly imgToolbarOptions = ko.pureComputed<DevExpress.ui.dxToolbar.Properties>(() => {
        const retVal: DevExpress.ui.dxToolbar.Properties = {
            items: [],
        };

        retVal.items.unshift(AS<DevExpress.ui.dxToolbarItem>({
            location: 'before',
            locateInMenu: 'never',
            widget: 'dxButtonGroup',
            options: AS<DevExpress.ui.dxButtonGroup.Properties>({
                items: [
                    AS<ModeButton>(
                        {
                            key: 'Info',
                            icon: 'info',
                        }),
                    AS<ModeButton>(
                        {
                            key: 'New',
                            icon: 'plus',
                        }),
                    AS<ModeButton>({
                        key: 'SetTopLeft',
                        icon: 'set-top-left',
                    }),
                    AS<ModeButton>({
                        key: 'SetBottomRight',
                        icon: 'set-bottom-right',
                    }),
                    AS<ModeButton>({
                        key: 'SetCenter',
                        icon: 'set-center',
                    }),
                    AS<ModeButton>({
                        key: 'SetTop',
                        icon: 'set-top',
                    }),
                    AS<ModeButton>({
                        key: 'SetBottom',
                        icon: 'set-bottom',
                    }),
                    AS<ModeButton>({
                        key: 'SetLeft',
                        icon: 'set-left',
                    }),
                    AS<ModeButton>({
                        key: 'SetRight',
                        icon: 'set-right',
                    }),
                ],
                selectionMode: 'single',
                keyExpr: 'key',
                selectedItemKeys: [this.clickMode()],
                onSelectionChanged: e2 => {
                    log('button group selection changed');
                    dir(e2);
                    if (!e2.addedItems || !e2.addedItems.length) {
                        this.clickMode('Info');
                    } else {
                        const item: ModeButton = e2.addedItems[0];
                        this.clickMode(item.key);
                    }
                    log(`Clickmode: ${this.clickMode()}`);
                }
            })
        }));

        return retVal;
    });

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));

        this.onChange(this.upsertCode, `${WIDGET_NAME}/${this.itemId}/upsert`, async v => {
            if (this.needsSave()) {
                log(`save: ${v}`);
                await ServerConnection.api.multistephotspot_edit_update({
                    params: v
                });
            }
            return HELPER.DONE;
        });

        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

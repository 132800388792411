export const id = 'surveytextinput';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '678efb1f-7066-4a8b-af50-16c8416c0b4c',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`],
        ])
    },
});

import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-subjectcoordinator-examorder';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
}

export class ExamOrderRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.examorder.EXAM_ORDER']);
    });

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.subjectcoordinator.examorder.HERE_YOU_CAN_SEE_ALL_OPEN_EXAMS_FOR_THIS_SUBJECT_YOU_CAN_DEFINE_THE_POINTS_FOR_EACH_CATEGORY_AND_OBJECTIVE_FOR_EACH_EXAM_CLICK_ON_THE_EXAM_ID_TO_OPEN_IT']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.subjectcoordinator.examorder.HELP']),
                action: this.showHelp
            }];
    });

    constructor(readonly widgetParams: IParamsInner) {
    }

}

export const FACTORY = ROUTES.registerRoute<{ subject: string }>({
    route: '/subjectcoordinator/subject/:subject/examorder',
    toParams: map => ({
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('subject', p.subject);
    },
    create: async p => {
        const route = new ExamOrderRoute(
            {
                subjectDocRefId: p.subject,
            });
        return route;
    },
});

import * as ko from 'knockout';
import { log } from '../../../debug';
import { getGolemData, golemMatch, isStringMap } from '../../../Gremlin';
import { DONE } from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import * as ARRAYHELPER from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { FillInPoolData, FillInPoolData_Gap, FillInPoolData_PoolValue, FillInPoolData_Text } from '../../model/fillinpool/FillInPoolData';
import { translate } from '../../model/ItemDataModel';
import { GetSession } from '../../model/session';
import { htmlString } from './fillinpool.html.g';

const WIDGET_NAME = 'itemdefinition-lavak-fillinpool';

export type IParams = IItemDefinitionWidgetParams;

class Text implements IGapOrText {
    public readonly text: string;
    constructor(public readonly model: ViewModel, readonly data: FillInPoolData_Text) {
        this.text = data.text;
    }
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }
}

interface IGapOrText {
    readonly isText: () => this is Text;
    readonly isGap: () => this is Gap;
}
class Gap implements IGapOrText {
    public readonly gapId: string;
    constructor(public readonly model: ViewModel, readonly gap: FillInPoolData_Gap) {
        this.gapId = this.gap.gapId;
    }
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }

    public readonly letter = ko.pureComputed(() => {
        const val = this.getSelected();
        if (!val) {
            return '';
        }
        return val.latinId;
    });

    public readonly isGapEmpty = ko.pureComputed(() => {
        return !this.gap.selectedValue();
    });

    public readonly pressGap = new UIAction(undefined, async () => {
        this.model.selectedGap(this.gapId);
        await this.model.attemptAssign();
    });
    public readonly isSelected = ko.pureComputed(() => {
        return this.gapId === this.model.selectedGap();
    });
    public readonly gapCorrectText = ko.pureComputed(() => {
        const correctValue = this.model.getValue(this.gap.correctValue);
        if (!correctValue) {
            return undefined;
        }
        return correctValue.latinId;
    });
    public readonly isCorrect = ko.pureComputed(() => {
        return this.gap.correctValue === this.gap.selectedValue();
    });
    public readonly evaluationRemark = ko.pureComputed(() => {
        return this.gap.evaluationRemark;
    });


    private getSelected() {
        return this.model.getValue(this.gap.selectedValue());
    }

    public readonly gapText = ko.pureComputed(() => {
        const val = this.getSelected();
        if (!val) {
            return '';
        }
        return val.text();
    });
}

class Value {
    public readonly id: string;

    constructor(public readonly model: ViewModel, public readonly value: FillInPoolData_PoolValue) {
        this.id = value.id;
    }

    public readonly text = ko.pureComputed(() => {
        return this.value.text();
    });

    public readonly isAvailable = ko.pureComputed(() => this.value.isAvailable());
    public readonly isSelected = ko.pureComputed(() => {
        return this.id === this.model.selectedValue();
    });

    public readonly letter = ko.pureComputed(() => this.value.latinId);

    public readonly pressValue = new UIAction(undefined, async () => {
        this.model.selectedValue(this.id);
        await this.model.attemptAssign();
    });
}

export class ViewModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof FillInPoolData)) {
            throw new Error();
        }
        this.data = data;

    }

    public getValue(valueId: string) {
        if (!valueId) {
            return undefined;
        }
        return this.data.possibilities.find(val => val.id === valueId);
    }
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly data: FillInPoolData;

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        for (const g of this.fragments()) {
            if (!g.isGap()) {
                continue;
            }
            if (!g.isGapEmpty()) {
                continue;
            }
            await g.pressGap.invoke(undefined, true);
            const golemData = getGolemData(this.itemId);
            const avail = this.values().filter(x => x.isAvailable());
            let pick: Value;
            if (isStringMap(golemData)) {
                const values = avail.map(x => ({
                    key: x.id,
                    value: translate(x.value.value.value, this.params),
                }));
                const pickedId = golemMatch(values, golemData[g.gap.gap.gapId]);
                pick = avail.find(x => x.id === pickedId);
                log(`${this.itemId}: golem chose ${pick} for #${g.gap.gap.gapId}`);
            }
            if (!pick) {
                log(`Golem did not suggest a value. pick a random one`);
                pick = ARRAYHELPER.getRandomEntry(avail);
            }
            await pick.pressValue.invoke(undefined, true);
            return true;
        }
        return false;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly selectedGap = ko.observable('');
    public readonly selectedValue = ko.observable('');

    public async OnReset() {
        this.selectedGap('');
        this.selectedValue('');
        return DONE;
    }

    public async attemptAssign() {
        const gap = this.selectedGap();
        if (!gap) {
            return;
        }
        const value = this.selectedValue();
        if (!value) {
            return;
        }
        await this.data.assign({
            gapId: this.selectedGap(),
            valueId: this.selectedValue(),
        });

        this.selectedGap('');
        this.selectedValue('');
    }

    public readonly fragments = ko.pureComputed(() => this.data.fragments.map(x => {
        if (x instanceof FillInPoolData_Gap) {
            return new Gap(this, x);
        }
        if (x instanceof FillInPoolData_Text) {
            return new Text(this, x);
        }
        throw new Error();
    }));



    public readonly values = ko.pureComputed(() => {
        return this.data.possibilities.map(x => new Value(this, x));
    });

    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());
    public readonly evaluationRemark = ko.pureComputed(() => this.data.evaluationRemark);
}


export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { writeToClipboard } from '../../../clipboard';
import { error, log } from '../../../debug';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { datagrid, refreshDx, selectBoxDS } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import { UIAction } from '../../uiAction';
import * as DS from './../../../enums/datasource';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_launchtest_data']>>;
type GETEXAMS = Awaited<ReturnType<API.Sdk['ui_superuser_launchtest_getexams']>>;

interface DATA {
    examAlias: string;
    userAgentString: string;
    externalId: string;
    golemThinkTime: number;
    candidateId: string;
    candidateName: string;
    golemScript: string;
    scoreRoundingMode: API.ScoreRoundingModeEnum;
}
class MyFormBuilder extends FormBuilder<DATA, boolean> {
    constructor(readonly vm: {
        previewExam: UIAction<DATA>,
        newExternalId: () => string,
        _golemScripts: GOLEMSCRIPT[],
    }) {
        super();
    }
    protected onInit() {
        super.onInit();
        this.addTextBox(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.EXAM_ID_OR_ALIAS']),
            },
            dataField: 'examAlias'
        }, () => true);
        this.addTextBox(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.EXTERNAL_ID']),
            },
            dataField: 'externalId',
            editorOptions: {
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
                buttons: [
                    {
                        location: 'after',
                        name: 'refresh',
                        options: {
                            icon: 'refresh',
                            onClick: () => {
                                this.form.updateData('externalId', this.vm.newExternalId());
                            }
                        }
                    }
                ]
            },
        }, () => true);
        this.addTextArea(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.USER_AGENT']),
            },
            editorOptions: {
                autoResizeEnabled: true,
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
            },
            dataField: 'userAgentString',
        }, () => true);
        this.addSelectBox(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.GOLEM_SCRIPT'])
            },
            dataField: 'golemScript',
            editorOptions: {
                dataSource: this.vm._golemScripts,
                displayExpr: (x: GOLEMSCRIPT) => {
                    if (!x) {
                        return '';
                    }
                    return `${x.golemScriptId} ${x.title}`;
                },
                valueExpr: 'docReferenceId',
                showClearButton: true,
            }
        }, () => true);
        this.addNumberBox(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.GOLEM_THINK_TIME']),
            },
            editorOptions: {
                min: 0,
                format: "#0.##\"",
                showSpinButtons: true,
            },
            dataField: 'golemThinkTime',
        }, () => true);
        this.addTextBox(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.CANDIDATE_ID'])
            },
            dataField: 'candidateId'
        }, () => true);
        this.addTextBox(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.CANDIDATE_NAME'])
            },
            dataField: 'candidateName'
        }, () => true);
        this.addSelectBox<API.ScoreRoundingModeEnum>(this.formOptions, {
            label: {
                text: i18next.t(['ui.superuser.launchtest.SCORE_ROUNDING_MODE']),
            },
            editorOptions: Object.assign(selectBoxDS(DS.ScoreRoundingMode()), {
                searchEnabled: false,
            }),
            dataField: 'scoreRoundingMode',
        }, () => true);
        this.addButton(this.formOptions, {
            buttonOptions: {
                text: i18next.t(['ui.superuser.launchtest.LAUNCH']),
                onClick: () => this.vm.previewExam.click(this.formOptions.formData),
            }
        });
    }
}

type EXAMINATION = GETEXAMS['examination']['get'][0];

type GOLEMSCRIPT = Q['golemScripts']['all'][0];

export class ViewModel extends DxWidget {
    public readonly formData: DATA = {
        examAlias: '',
        externalId: '',
        candidateId: '',
        candidateName: '',
        golemScript: '',
        golemThinkTime: 0,
        userAgentString: '',
        scoreRoundingMode: API.ScoreRoundingModeEnum.Default,
    };
    private readonly _examinations: EXAMINATION[] = [];
    public readonly _golemScripts: GOLEMSCRIPT[] = [];

    private readonly username = ko.observable('');
    public readonly error_message = ko.observable('');
    public readonly href = ko.observable('');
    public readonly sessionId = ko.observable('');

    public readonly form = new MyFormBuilder(this);

    public readonly sessionResultHeader = ko.pureComputed(() => i18n.t(['ui.superuser.launchtest.SESSION_RESULT']));
    public readonly sessionResultHelp = ko.pureComputed(() => i18n.t(['ui.superuser.launchtest.WHEN_THE_TEST_IS_COMPLETED_YOU_CAN_EASILY_GO_TO_THE_RESULT_VIEW']));

    public newExternalId() {
        return ['preview', this.username(), ... new Date().toISOString().replace(/[-T:]/g, '.').split('.')].join('.')
    }
    public readonly previewExam = new UIAction<DATA>(undefined, async (e, args) => {
        if (!args.examAlias) {
            alert(i18next.t(['ui.superuser.launchtest.YOU_HAVE_TO_ENTER_AN_EXAM_ID_OR_ALIAS']));
            return;
        }
        this.error_message('');
        this.href('');
        this.sessionId('');

        const exams = await ServerConnection.api.ui_superuser_launchtest_getexams({
            userAgentString: args.userAgentString,
            filter: {
                alias: [args.examAlias],
                includeObsolete: true
            }
        });
        this._examinations.splice(0, this._examinations.length, ...exams.examination.get.map(x => {
            return Object.assign(x, { enabled: !x.obsolete });
        }));

        //DefaultWindowManager.prepareOpenWindow('');
        const examId = args.examAlias;
        const externalId = args.externalId;
        try {
            const d = await ServerConnection.api.ui_superuser_launchtest({
                examId,
                externalId,
                userAgentString: args.userAgentString,
                candidateId: args.candidateId,
                candidateName: args.candidateName,
                golemScript: args.golemScript || '',
                scoreRoundingMode: args.scoreRoundingMode,
            });
            const queryArgs: { [key: string]: string } = {};
            if (this.formData.golemScript || this.formData.golemThinkTime > 0) {
                const thinkTime = this.formData.golemThinkTime || 1;
                queryArgs['gremlins'] = thinkTime.toString();
            }

            this.href(ServerConnection.getDataUrl(d.newExamSession.href, queryArgs));
            this.sessionId(d.newExamSession.sessionId);
        } catch (e) {
            log('unable to start exam', e);
            this.error_message(e.message || e.toString());
        }
        await refreshDx(this);
        //DefaultWindowManager.openWindow(d.data.newExamSession.href, '');
        //DefaultWindowManager.openWindow(d.data.newExamSession.href, '');
    });

    public readonly goButtonOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18next.t(['ui.superuser.launchtest.GO']),
            onClick: () => {
                location.href = this.href();
            },
        };
        return retVal;
    });
    public readonly goITSR3ButtonOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18n.t(['ui.superuser.launchtest.OPEN_ITSR3_CLIENT']),
            onClick: () => {
                try {
                    location.href = 'itsr3:' + this.href();
                } catch (e) {
                    error(e);
                }
            },
        };
        return retVal;
    });
    public readonly goPopupButtonOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18n.t(['ui.superuser.launchtest.OPEN_IN_SEPARATE_WINDOW']),
            onClick: () => {
                window.open(this.href(), '');
            },
        };
        return retVal;
    });
    public readonly printPdfButtonOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18n.t(['ui.superuser.launchtest.PRINT_PDF']),
            onClick: async () => {
                const resp = await ServerConnection.api.launchtest_printPdf({ sessionId: this.sessionId() });
                const href = ServerConnection.getDataUrl(resp.examSession.resultPdf.hrefResolved);
                location.href = href;
            },
        };
        return retVal;
    });
    public readonly printWindowButtonOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18n.t(['ui.superuser.launchtest.PRINT_WINDOW']),
            onClick: async () => {
                const wnd = window.open();
                const resp = await ServerConnection.api.launchtest_print({ sessionId: this.sessionId() });
                const href = ServerConnection.getDataUrl(resp.printSession.href);
                wnd.location.href = href;
            },
        };
        return retVal;
    });
    public readonly copyButtonOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18next.t(['ui.superuser.launchtest.COPY_URL']),
            onClick: () => {
                const n = window.navigator;
                void writeToClipboard(this.href());
            }
        };
        return retVal;
    });

    constructor(readonly params: IParams) {
        super();
        this.formData.userAgentString = window.navigator.userAgent;
        this.formData.externalId = 'abc';
        this.formData.golemThinkTime = 0;
        this.formData.candidateId = '';
        this.formData.candidateName = '';
        this.formData.golemScript = '';

        this.form.init(this.formData, this.forms);
        this.form.formOptions.onFieldDataChanged = x => {
            return true;
        };

    }

    public async OnRefresh() {
        const r = await ServerConnection.api.ui_superuser_launchtest_data({
        });
        this._golemScripts.splice(0, this._golemScripts.length, ...r.golemScripts.all);
        this.username(r.whoAmI.user.userId);
        this.formData.externalId = this.newExternalId();
        await refreshDx(this);
    }

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                editing: {
                    allowAdding: false,
                    allowDeleting: false,
                    allowUpdating: false,
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._examinations;
                        },
                        byKey: async key => {
                            return this._examinations.find(x => x.docReferenceId === key);
                        },
                    })
                }

            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.launchtest.EXAM_ID']),
            dataField: 'examinationId',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.launchtest.PASSES_RESTRICTIONS']),
            dataType: 'boolean',
            dataField: 'passesRestrictions',

        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.launchtest.ALTERNATIONS']),
            dataType: 'string',
            calculateCellValue: (rowData: EXAMINATION) => {
                return rowData.alternationRestrictions.map(x => x.alternation.taxon).join(' ');
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.launchtest.ENABLED']),
            dataField: 'enabled',
            dataType: 'boolean',
            width: 40,
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.launchtest.ID']),
            dataField: 'docReferenceId',
            dataType: 'string',
            visible: false,
        });

        return retVal;
    });
    public readonly loaded = ko.observable(false);
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});


export interface ITokenType<TType extends string> {
    type: TType;
    splitRegex: RegExp;
}

export interface IToken {
    value: string;
    start: number;
    end: number;
    type: TokenType['type'];
}

export type ImageTokenType = IParsableTokenType<'image', ImageTokenParams>;
export type TextTokenType = ITokenType<'text'>;
export type GapTokenType = IParsableTokenType<'gap', GapTokenParams>;
export type TokenType = ImageTokenType | TextTokenType | GapTokenType;

export interface IParsableTokenType<TType extends string, T> extends ITokenType<TType> {
    parse(token: string): T;
    compile(params: T): string;
}

export const IMAGE_TOKEN: ImageTokenType = {
    type: 'image',
    splitRegex: /(\$IMG\([^)]*\))/,
    parse: tag => parseImageTag(tag),
    compile: p => compileImageTag(p)
};

export const TEXT_TOKEN: TextTokenType = {
    type: 'text',
    splitRegex: null
};

export const GAP_TOKEN: GapTokenType = {
    type: 'gap',
    splitRegex: /(\$\(gap:[^)]+\))/,
    parse: str => parseGapToken(str),
    compile: tag => compileGapToken(tag)
};

export interface GapTokenParams {
    id: string;
}
export function compileGapToken(tag: GapTokenParams) {
    if (!tag || !tag.id) {
        return undefined;
    }
    if (typeof tag.id !== 'string') {
        return undefined;
    }
    if (tag.id.indexOf(')') >= 0) {
        return undefined;
    }
    return '$(gap:' + tag.id + ')';
}
export function parseGapToken(str: string): GapTokenParams {
    if (!str) {
        return undefined;
    }
    if (typeof str !== 'string') {
        return undefined;
    }
    const regEx = /^\$\(gap:([^)]+)\)$/;
    const result = str.match(regEx);
    if (!result) {
        return undefined;
    }
    return {
        id: result[1]
    };
}

export interface ImageTokenParams {
    filename: string;
    width?: number;
    height?: number;
    offsetY?: number;
    zoom?: boolean;
}

export function compileImageTag(tag: ImageTokenParams) {
    if (!tag) {
        return undefined;
    }
    if (!tag.filename) {
        return undefined;
    }
    if (typeof tag.filename !== 'string') {
        return undefined;
    }
    if (!/^[_+A-Z0-9a-z.]+$/.test(tag.filename)) {
        return undefined;
    }
    const hasWidth = tag.width > 0;
    const hasHeight = tag.height > 0;
    const hasSize = hasWidth && hasHeight;
    const hasZoom = !!tag.zoom;
    const hasOffset = tag.offsetY < 0 || tag.offsetY > 0;
    let fn = tag.filename;
    if (!hasSize) {
        if (hasWidth || hasHeight || hasZoom || hasOffset) {
            return undefined;
        }
        return `$IMG(${fn})`;
    }
    const wh = `${tag.width}x${tag.height}`;
    const zoom = hasZoom ? ' zoom' : '';
    const offset = (!hasOffset) ? '' : (tag.offsetY > 0 ? `+${tag.offsetY}` : `-${-tag.offsetY}`);
    return `$IMG(${fn} ${wh}${offset}${zoom})`;
}


export function parseImageTag(tag: string): ImageTokenParams {
    if (!tag) {
        return undefined;
    }
    if (typeof tag !== 'string') {
        return undefined;
    }
    {
        const syntax1 = tag.match(/^\$IMG\(([_+A-Z0-9a-z.]+)\)$/);
        if (syntax1) {
            return {
                filename: syntax1[1],
                width: undefined,
                height: undefined,
                offsetY: undefined,
                zoom: undefined,
            };
        }
    }
    {
        const syntax2 = tag.match(/^\$IMG\(([_+A-Z0-9a-z.]+)\s+([0-9]+)x([0-9]+)(?<zoom>\s+zoom)?\)$/);
        if (syntax2) {
            return {
                filename: syntax2[1],
                width: parseInt(syntax2[2], 10),
                height: parseInt(syntax2[3], 10),
                offsetY: undefined,
                zoom: syntax2.groups?.zoom && true,
            };
        }
    }
    {
        const syntax3 = tag.match(/^\$IMG\(([_+A-Z0-9a-z.]+)\s+([0-9]+)x([0-9]+)([+-])([0-9]+)(?<zoom>\s+zoom)?\)$/);
        if (syntax3) {
            return {
                filename: syntax3[1],
                width: parseInt(syntax3[2], 10),
                height: parseInt(syntax3[3], 10),
                offsetY: parseInt(syntax3[4] + syntax3[5], 10),
                zoom: syntax3.groups?.zoom && true,
            };
        }
    }
    return undefined;
}

export function tokenize(text: string, tokens: TokenType[]) {
    const retVal: Array<IToken> = [];
    retVal.push({
        value: text,
        start: 0,
        end: text.length,
        type: TEXT_TOKEN.type
    });
    if (!tokens) {
        return retVal;
    }
    for (const token of tokens) {
        if (!token.splitRegex) {
            continue;
        }
        for (let nText = retVal.length - 1; nText >= 0; --nText) {
            const cur = retVal[nText];
            if (cur.type !== TEXT_TOKEN.type) {
                continue;
            }
            const t = cur.value.split(token.splitRegex);
            const toInsert: IToken[] = [];
            let nPos = cur.start;
            let tokenPos = -1;
            for (let i = 0; i < t.length; ++i) {
                const f = t[i];
                const newPos = f.length + nPos;
                if (f) {
                    if (i % 2 == 0) {
                        toInsert.push({
                            type: 'text',
                            start: nPos,
                            end: newPos,
                            value: f
                        });
                    } else {
                        toInsert.push({
                            type: token.type,
                            start: nPos,
                            end: newPos,
                            value: f
                        });
                    }
                }
                nPos = newPos;
            }
            retVal.splice(nText, 1, ...toInsert);
        }
    }

    return retVal;
}

export function insertToken(text: string, tokens: TokenType[], newToken: string, pos: number) {
    if (!text) {
        text = '';
    }
    if (!newToken) {
        newToken = '';
    }
    if (pos <= 0) {
        return {
            text: newToken + text,
            startPos: 0,
            endPos: newToken.length
        };
    }
    if (pos >= text.length) {
        return {
            text: text + newToken,
            startPos: text.length,
            endPos: text.length + newToken.length
        };
    }
    const t = tokenize(text, tokens);
    let tokenPos = 0;
    for (let i = 0; i < t.length; ++i) {
        const f = t[i];
        if (f.end < pos) {
            continue;
        }

        switch (f.type) {
            case 'text':

                const splitPos = pos - f.start;
                const before = f.value.substr(0, splitPos);
                const after = f.value.substr(splitPos);
                t.splice(i, 1,
                    {
                        type: 'text',
                        start: undefined,
                        end: undefined,
                        value: before
                    },
                    {
                        type: 'image',
                        start: undefined,
                        end: undefined,
                        value: newToken
                    },
                    {
                        type: 'text',
                        start: undefined,
                        end: undefined,
                        value: after
                    });
                tokenPos = i + 1;
                break;
            default:
                t.splice(i + 1, 0,
                    {
                        type: 'text',
                        start: undefined,
                        end: undefined,
                        value: ''
                    },
                    {
                        type: 'image',
                        start: undefined,
                        end: undefined,
                        value: newToken
                    });
                tokenPos = i + 2;
        }
        break;
    }
    return {
        text: t.map(x => x.value).join(''),
        startPos: t.slice(0, tokenPos).reduce((p, c) => p + c.value.length, 0),
        endPos: t.slice(0, tokenPos + 1).reduce((p, c) => p + c.value.length, 0)
    };
}

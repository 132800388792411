import * as ko from 'knockout';
import { AbstractWidget } from '../AbstractWidget';
import { error } from '../debug';
import * as query from '../getquery';
import * as GREMLIN from '../Gremlin';
import { BUILD_INFO } from '../grow_version.g';
import { currentLanguageTag, initLangSubSystem } from '../i18n/data';
import * as API from '../its-itembank-api.g';
import { IAppOptions } from './base';
import { connectionLostTime } from './docmanager';
import { focusManager } from './focusmanager/index';
import { isNoNetwork } from './getAmbientBrowserSessionId';
import { ITSR2_External } from './itsr2_external';
import { ServerConnection } from './RestAPI';
import { sendScormExit } from './service/participation/scorm';
import { UIAction } from './uiAction';

type Q = Awaited<ReturnType<API.Sdk['ui_base_getconfig']>>;
type ONLINEHELP = Q['config']['onlineHelp'][0];

export class EntryPoint {
    constructor(readonly name: string, readonly mainWidget: string) {

    }
}

export class ItemHostBase extends AbstractWidget {
    public readonly focusManager = focusManager;
    public browserSessionId: string;
    public readonly appTitleSection = ko.observable('');
    public readonly appTitle = ko.pureComputed(() => {
        const instanceName = this.instanceName();
        const section = this.appTitleSection();
        if (!section) {
            return instanceName;
        }
        return `${instanceName} [${section}]`;
    });
    public readonly loginId = ko.observable('');
    public readonly itemBankVersion = BUILD_INFO.semVer;
    public readonly serverVersion = ko.observable('');
    public readonly embeddedMode: boolean;
    private readonly appOptions: IAppOptions;
    constructor(appOptions?: Partial<IAppOptions>) {
        super();
        this.appOptions = {
            noNetwork: appOptions && appOptions.noNetwork || false,
            allowAnonymousAccess: appOptions && (appOptions.noNetwork || appOptions.allowAnonymousAccess)
        };
        if (this.appOptions.noNetwork !== isNoNetwork()) {
            throw new Error('Programmers fault: noNetwork is set on main widget, but isNoNetwork did not detect that');
        }
        if (typeof ITSR2_External === 'object') {
            this.embeddedMode = true;
        }
        else {
            this.embeddedMode = false;
        }
    }
    public readonly hasCloseButton = ko.pureComputed(() => {
        const closeMode = query.params.get('onclose') || 'close';
        return closeMode !== 'none';
    });
    protected closeWindow() {
        let closeMode = query.params.get('onclose') || 'close';
        sendScormExit({ message: 'scorm_exit' });
        if (closeMode.startsWith('redirect:')) {
            window.location.replace(closeMode.substring('redirect:'.length));
            return;
        }
        if (closeMode.startsWith('text:')) {
            document.body.innerText = closeMode.substring('text:'.length);
            return;
        }
        if (closeMode === 'close') {
            try {
                window.close();
                return;
            }
            catch (e) {
            }
            closeMode = 'blank';
        }
        if (closeMode === 'back') {
            try {
                window.history.back();
                return;
            }
            catch (e) {
            }
            closeMode = 'blank';
        }
        if (closeMode === 'blank') {
            window.location.replace(ServerConnection.getDataUrl(query.params.get('blank')));
        }
        if (closeMode === 'none') {
            //do nothing
        }
    }
    protected async logout() {
        window.name = '';
    }
    public readonly loggedin = ko.observable(false);
    public readonly loaded = ko.observable(false);
    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly currentLocalization = ko.observable<Q['whoAmI']['localization']>();

    protected async onLogIn() {
    }
    protected async onLogOut() {
    }
    protected async onInitialize() {
    }
    public async initialize() {
        try {
            await super.initialize();
            if (!this.appOptions.noNetwork) {
                const allowAnonymous = this.appOptions.allowAnonymousAccess;
                if (ServerConnection.isAnonymous && !allowAnonymous) {
                    window.location.replace(ServerConnection.getDataUrl('ui/login.html'));
                    return;
                }
                await this.initLanguage();
                await this.onLogIn();
                this.loggedin(true);
            }
            await this.onInitialize();
            document.title = this.appTitle();
            this.disposables.addDiposable(this.appTitle.subscribe(x => document.title = x));
            this.loaded(true);
            if (this.baseConfig.config.golemEnabled) {
                void GREMLIN.startContinuousAttack();
            }
            if (ITSR2_External && ITSR2_External.ready) {
                setTimeout(() => {
                    void ITSR2_External.ready();
                }, 10 * 1000);
            }
        } catch (e) {
            error(e);
            alert(e.message || 'Unknown Error');
        }
    }
    public readonly instanceName = ko.observable('ItemBank');
    public readonly onlineHelp = ko.observable<ONLINEHELP[]>();
    public readonly roles = new Set<API.Privilege>();

    protected baseConfig: Q;
    protected async initLanguage() {
        //$LANG.setCurrentContentLanguageByLangCode(lang);
        const config = await ServerConnection.api.ui_base_getconfig({});

        const forceLang = (
            (config.whoAmI.type === API.BrowserSessionType.Candidate) ||
            (config.whoAmI.type === API.BrowserSessionType.Review)
        );

        await initLangSubSystem(config.whoAmI.isoLanguages, forceLang);
        this.baseConfig = config;
        this.instanceName(config.config.instanceName);
        connectionLostTime(config.config.connectionLostTime);
        this.loginId(config.whoAmI.loginId);
        this.serverVersion(config.config.serverVersion);
        this.onlineHelp(config.config.onlineHelp);
        this.currentLocalization(config.whoAmI.localization);
        currentLanguageTag(config.whoAmI?.localization?.id || '');
        for (const role of config.whoAmI.user.privileges) {
            this.roles.add(role.privilege);
        }
    }
    public readonly isSuperUser = ko.pureComputed(() => {
        return this.roles.has(API.Privilege.Superuser);
    });
    public readonly isAdmin = ko.pureComputed(() => {
        return this.roles.has(API.Privilege.Admin);
    });
    public readonly isSubjectCoordinator = ko.pureComputed(() => {
        return this.roles.has(API.Privilege.SubjectcoordinatorSubjects);
    });
    public readonly isAuthor = ko.pureComputed(() => {
        return this.roles.has(API.Privilege.AuthorSubjects);
    });
    public readonly isEvaluator = ko.pureComputed(() => {
        return this.roles.has(API.Privilege.EvaluatorSubjects);
    });
    public readonly isTranslator = ko.pureComputed(() => {
        return this.roles.has(API.Privilege.TranslatorSubjects);
    });

    protected assertBrowserSession() {
        if (!this.browserSessionId) {
            throw new Error('Unable to determine browser session!');
        }
    }
    public readonly actionLogout = new UIAction('ActionLogout', async () => {
        if (!this.loggedin()) {
            return;
        }
        if (query.params.get('keepsession')) {
            return;
        }
        await ServerConnection.api.ui_logout({});
        sessionStorage.removeItem('browserSessionId');
        let canCloseWindow = false;
        try {
            canCloseWindow = !!window.opener;
        }
        catch (e) {
        }
        if (canCloseWindow) {
            window.close();
        }
        else {
            window.location.reload();
        }
    });
}

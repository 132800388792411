import * as ko from 'knockout';
import { formatNumber } from '../../resultscreens/default/formatNumber';
import type { ObjectiveFieldPartTemplate } from './ObjectiveFieldPartTemplate';
import type { ObjectiveOverviewBodyField } from "./ObjectiveOverviewBodyField";

export class ObjectiveFieldPart {
    constructor(readonly templatePart: ObjectiveFieldPartTemplate, readonly field: ObjectiveOverviewBodyField) {
    }
    public readonly cssClass = ko.pureComputed(() => {
        if (this.templatePart.fieldType == '') {
            return '';
        }
        return `FieldType_${this.templatePart.fieldType}`;
    });

    public readonly isScore = ko.pureComputed(() => this.templatePart.fieldType === 'score');
    public readonly isValue = ko.pureComputed(() => !this.isScore());
    public readonly value = ko.pureComputed(() => {
        const p = this.field.objective.params;
        const score = p;
        switch (this.templatePart.fieldType) {
            case '':
                return this.templatePart.text;
            case 'id':
                return p.objectiveId || '';
            case 'title':
                return p.description.value;
            case 'pointsPercent':
                return formatNumber(score.scoreGained * 100 / score.scoreMax);
            case 'pointsGained':
                return formatNumber(score.scoreGained);
            case 'pointsPending':
                return formatNumber(score.scorePending);
            case 'pointsLost':
                return formatNumber(score.scoreLost);
            case 'pointsMax':
                return formatNumber(score.scoreMax);
            default:
                return `{${this.templatePart.fieldType}}`;
        }
    });

    public readonly score = ko.pureComputed(() => {
        const score = this.field.objective.params;
        return {
            pointsGained: score.scoreGained,
            pointsPending: score.scorePending,
            pointsLost: score.scoreLost,
            pointsMax: score.scoreMax
        };
    });
}

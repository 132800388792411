import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput, MultistepHotSpot_CorrectState } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { ServerConnection } from '../../../ui/RestAPI';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'MultistepHotSpot_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type STEP = DATA['mshsSteps'][0];
type ZONE = STEP['mshsZones'][0];
type AREA = ZONE['mshsAreas'][0];
type GIVEN = DATA['mshsGiven'];

export class MultistepHotspotData_Answer {
    constructor(private readonly model: MultistepHotspotData) {
    }
    public fromJS(rawData: GIVEN) {
        this.id(rawData.id);
        this.steps(rawData.steps);
        this.x(rawData.x);
        this.y(rawData.y);
        this.correct(rawData.correct);
    }
    public readonly id = ko.observable('');
    public readonly steps = ko.observable<string[]>([]);
    public readonly x = ko.observable<number>();
    public readonly y = ko.observable<number>();
    public readonly correct = ko.observable<MultistepHotSpot_CorrectState>();

    public canStepBack = ko.pureComputed(() => {
        const steps = this.steps();
        return steps.length > 1;
    });

    public stepBack() {
        const steps = this.steps();
        if (steps.length <= 1) {
            return;
        }
        steps.pop();
        this.steps(steps);
        this.x(undefined);
        this.y(undefined);
    }
    public stepForward({ stepId }: { stepId: string }) {
        const steps = this.steps();
        steps.push(stepId);
        this.steps(steps);
        this.x(undefined);
        this.y(undefined);
    }

    public setMarker({ x, y }: { x: number, y: number }) {
        this.x(x);
        this.y(y);
    }

    //public readonly isSelected = ko.pureComputed(() => this.id === this.model.selectedAnswer());
    //public readonly isCorrectAnswer = ko.pureComputed(() => this.id === this.model.correctAnswer);
}

export class AreaRectModel {
    public readonly areaId: string = '';
    public readonly left: number = 0;
    public readonly top: number = 0;
    public readonly width: number = 0;
    public readonly height: number = 0;
    constructor(readonly parent: ZoneModel, data: AREA) {
        this.areaId = data.areaId;
        this.left = data.left;
        this.top = data.top;
        this.width = data.width;
        this.height = data.height;
    }
}

export class ZoneModel {
    public readonly stepLinkId: string = '';
    public readonly zoneId: string = '';
    constructor(readonly parent: StepModel, data: ZONE) {
        this.zoneId = data.zoneId;
        this.stepLinkId = data.stepLinkId;
        for (const area of data.mshsAreas) {
            this.areas.push(new AreaRectModel(this, area));
        }
    }
    public readonly areas: AreaRectModel[] = [];
}
export class StepModel {
    public readonly imgUrl: string;
    public readonly imageWidth: number;
    public readonly imageHeight: number;
    public readonly stepId: string;
    public readonly zones: ZoneModel[];

    constructor(readonly parent: MultistepHotspotData, data: STEP) {
        const imgAtt = parent.meta.getAttachment(data.mshsImage.id);
        this.imgUrl = ServerConnection.getDataUrl(imgAtt?.hrefResolved);
        this.imageWidth = data.imageWidth;
        this.imageHeight = data.imageHeight;
        this.stepId = data.stepId;
        this.zones = [];
        if (data.mshsZones) {
            for (const rect of data.mshsZones) {
                const zone = new ZoneModel(this, rect);
                this.zones.push(zone);
            }
        }
    }
}
export class MultistepHotspotData extends ItemDataModel {
    public readonly steps: StepModel[];
    public readonly initialStepId: string = '';
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.initialStepId = data.initialStepId;
        //this.correctAnswer = data.correctAnswer;
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.markerRadius = data.markerRadius;

        this.steps = data.mshsSteps.map(x => new StepModel(this, x));

        this.fromJS(data);
    }

    public readonly currentStep = ko.pureComputed(() => {
        const steps = this.given.steps();
        if (!steps || !steps.length) {
            return this.initialStepId;
        }
        return steps[steps.length - 1];
    });

    public fromJS(data: DATA) {
        if (data.__typename !== 'MultistepHotSpot_QueryItemSessionData') {
            throw new Error();
        }
        this.given.fromJS(data.mshsGiven);
    }
    public readonly questionHTML: string;
    public readonly markerRadius: number;
    public readonly given = new MultistepHotspotData_Answer(this);
    public readonly headerText: string;
    public IsInteractionStarted() {
        if (typeof this.given.x() === 'number') {
            return true;
        }
        if (typeof this.given.y() === 'number') {
            return true;
        }
        if (this.given.canStepBack()) {
            return true;
        }
        return false;
        //return this.answers().length > 0;
    }
    public IsInteractionComplete() {
        if (typeof this.given.x() === 'number') {
            return true;
        }
        if (typeof this.given.y() === 'number') {
            return true;
        }
        return false;
        //const nAnswers = this.answers().length;
        //return nAnswers >= this.minAnswers && nAnswers <= this.maxAnswers;
    }
    public async reset() {
        this.given.x(undefined);
        this.given.y(undefined);
        this.given.steps([this.initialStepId]);
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            MultistepHotSpot: {
                steps: this.given.steps(),
                x: this.given.x(),
                y: this.given.y(),
            },
        };
        return retVal;
    }

}

export const id = 'lavak-linetextentry';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '6c46d592-c39b-471f-8288-94cf9a280f76',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`],
            ['GRADING', `itemdefinition-${id}-grading`]
        ])
    },
    grading: 'BULK',
});

import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-subjectcoordinator-translationedit';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
    //itemOrderDocRefIds?: string[],
    examOrderDocRefIds: string[],
    //itemDocRefIds?: string[],
    isoCodes: string[]
}

export class TranslationEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.translationedit.TRANSLATION']);
    });

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.subjectcoordinator.translationedit.THE_TEXTS_CAN_BE_TRANSLATED_DIRECTLY_IN_THE_PROGRAM_OR_IN_AN_EXPORTED_EXCEL_TABLE_ENTRIES_LIKE_GAP_1_MARK_THE_GAP_IN_THE_TEXT_AND_HAVE_TO_BE_INSERTED_AT_THE_POSITION_IN_THE_TRANSLATED_TEXT_WHERE_THE_GAP_SHOULD_BE_THE_TRANSLATION_CAN_BE_PREVIEWED_IMMEDIATELY_GOING_TO_ITEM_REVIEW_AND_CHANGING_THE_CONTENT_LANGUAGE_TO_THE_LANGUAGE_OF_THE_TRANSLATION']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.subjectcoordinator.translationedit.HELP']),
                action: this.showHelp
            }];
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}


export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/subjectcoordinator/subject/:subject/translationedit/:examorders/:isocodes',
    toParams: map => ({
        subjectDocRefId: map.get('subject'),
        examOrderDocRefIds: map.get('examorders').split(' '),
        isoCodes: map.get('isocodes').split(' '),
    }),
    fromParams: (p, map) => {
        map.set('subject', p.subjectDocRefId);
        map.set('examorders', p.examOrderDocRefIds.join(' '));
        map.set('isocodes', p.isoCodes.join(' '));
    },
    create: async p => {
        const route = new TranslationEditRoute(p);
        return route;
    },
});

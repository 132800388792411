import * as ko from 'knockout';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';

export const WIDGET_NAME = 'ui-superuser-testaliasedit';

export interface IParamsInner {
    docRef: string;
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class TestAliasEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;

    public static async CREATE(params: IParamsInner) {
        const d = await ServerConnection.api.ui_superuser_testaliasedit_route({
            profile: params.docRef
        });
        const route = new TestAliasEditRoute(params, d.TestAlias.byId.alias);
        return route;

    }
    public readonly title = ko.pureComputed(() => {
        return this._title;
    });


    constructor(readonly widgetParams: IParamsInner, readonly _title: string) {
    }
}


export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/superuser/testalias/:id',
    toParams: map => ({
        docRef: map.get('id'),
    }),
    fromParams: (p, map) => {
        map.set('id', p.docRef);
    },
    create: async p => {
        return TestAliasEditRoute.CREATE(p);

    },
});

import { encodeHtml } from '../helper';
import { ImageTokenParams } from '../tokenizer';
import { ServerConnection } from '../ui/RestAPI';
import * as _internal from './_internal';

export interface IProcessOptions {
    inline?: boolean;
    html: string;
    convertLFtoBR?: 'always' | 'auto' | 'never';
    attachments?: Array<{
        name: string;
        hrefResolved: string;
        altText: {
            value: string;
        }
        imageMetadata?: {
            width: number;
            height: number;
        }
        videoMetadata?: {
            width: number;
            height: number;
        }
    }>
}

const LF_REGEX = /\r?\n/g;

const VIDEO_EXT = /(\.mp4)$/;

function getAttachment(data: IProcessOptions, name: string) {
    if (!data?.attachments) {
        return undefined;
    }
    const normalized = name.toLowerCase();
    const att = data.attachments.find(x => x.name.toLowerCase() === normalized);
    if (!att) {
        return undefined;
    }
    return {
        href: ServerConnection.getDataUrl(att.hrefResolved),
        isVideo: !!att.videoMetadata,
        isImage: !!att.imageMetadata,
        width: att.videoMetadata?.width ?? att.imageMetadata?.width,
        height: att.videoMetadata?.height ?? att.imageMetadata?.height,
        altText: att.altText?.value ?? '',
    };
}

export function process(data: IProcessOptions) {
    const retval = new Array<string>();
    if (data.inline) {
        retval.push('<span class="itsr3-richtext" style="');
    } else {
        retval.push('<div class="itsr3-richtext" style="');
    }
    retval.push('"');
    retval.push('>');

    const containsHtmlTags = /\<[a-z]+.*\>/.test(data.html);
    let html = _internal.processImageTags(data.html, ({ filename: img, width, height, offsetY, zoom }: ImageTokenParams) => {
        const att = getAttachment(data, img);
        const imgHref = att?.href;
        if (!imgHref) {
            return `<span class="itsr3-missing-image">'${img}' missing</span>`;
        }
        let offsetYStyle = offsetY ? `vertical-align:${-offsetY}px;` : '';

        if (!width || !height) {
            width = att.width;
            height = att.height;
        }
        if (att.isVideo) {
            return `<video-player zoom="${zoom ? 'yes' : 'no'}" width="${width}" height="${height}" style="${offsetYStyle}" source="${imgHref}"></video-player>`;
        }
        const unzoomed = `<img title="${encodeHtml(att.altText)}" alt="${encodeHtml(att.altText)}" src="${imgHref}" style="width:${width}px;height:${height}px;${offsetYStyle}" />`;
        if (!zoom) {
            return unzoomed;
        }
        const retval = new Array<string>();
        retval.push(`<div class="itsr3-zoomable" onclick="ImagePopup.show('${imgHref}')">`);
        retval.push(unzoomed);
        retval.push(`<div class="itsr3-magnifier"><i class="fas fa-search"></i></div>`);
        retval.push(`</div>`);
        return retval.join('');
    });
    const convertLFtoBR = data.convertLFtoBR || 'always';
    switch (convertLFtoBR) {
        case 'always':
            html = html.replace(LF_REGEX, '<br />');
            break;
        case 'never':
            break;
        case 'auto':
            if (!containsHtmlTags) {
                html = html.replace(LF_REGEX, '<br />');
            }
            break;
    }
    retval.push(html);
    if (data.inline) {
        retval.push('</span>');
    } else {
        retval.push('</div>');
    }
    const ret = retval.join('');
    console.log(ret);
    return ret;
}

﻿import * as ko from 'knockout';
import { log } from '../../../debug';
import { getGolemData, golemMatch } from '../../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry } from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { SingleChoiceMatrixData, SingleChoiceMatrixData_Cell, SingleChoiceMatrixData_Column, SingleChoiceMatrixData_Row } from '../../model/singlechoicematrix/SingleChoiceMatrixData';
import { htmlString } from './singlechoicematrix.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-singlechoicematrix';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export class VMCell {
    public readonly cellId: string;
    public readonly isSelected = ko.pureComputed(() => this.data.isSelected());

    public readonly isAnswer: boolean;
    constructor(readonly model: MyModel, public row: VMRow, public data: SingleChoiceMatrixData_Cell) {
        this.cellId = data.id;
        this.isAnswer = data.isCorrect;
    }

    public readonly click = new UIAction(undefined, async () => {
        await this.model.clickAnswer.intent({
            cellId: this.cellId
        });
    });

    public readonly answerStatus = ko.pureComputed(() => {
        if (this.isSelected()) {
            if (this.isAnswer) {
                return 'Correct';
            } else {
                return 'Wrong';
            }
        }
        return undefined;
    });

    public readonly cssClass = ko.pureComputed(() => {
        if (this.model.mode() === 'RESULT' || this.model.mode() === 'PRINT') {
            if (this.isSelected()) {
                if (this.isAnswer) {
                    return 'Checked CorrectChecked Correct';
                } else {
                    return 'Checked WrongChecked Wrong';
                }
            } else {
                if (this.isAnswer) {
                    return 'Correct';
                } else {
                    return '';
                }
            }
        }
        return '';
    });
}

export class VMRow {
    public readonly id: string;
    constructor(readonly model: MyModel, readonly data: SingleChoiceMatrixData_Row) {
        this.id = data.id;
    }

    public readonly text = ko.pureComputed(() => this.data.text);

    public readonly cells = ko.pureComputed(() => {
        return this.data.cells.map(cell => new VMCell(this.model, this, cell));
    });
}

export class VMColumn {
    public readonly value: string;
    constructor(readonly model: MyModel, readonly data: SingleChoiceMatrixData_Column) {
        this.value = data.value;
    }
}

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SingleChoiceMatrixData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: SingleChoiceMatrixData;

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        const rows = this.shuffledRows();
        const rowMap = new Map<string, number>();
        const golemData = getGolemData(this.itemId);
        log(`Golem data: ${JSON.stringify(golemData)}`);
        if (typeof golemData === 'object' && !Array.isArray(golemData)) {
            for (const rowKey of Object.keys(golemData)) {
                const rowId = golemMatch(rows.map(x => ({ key: x.id, value: x.text() })), rowKey);
                const row = rows.find(x => x.id === rowId);
                log(`match row: ${rowKey}=>${row && row.id}`);
                if (row) {
                    const colKey = golemData[rowKey];
                    log(`col key: ${colKey}`);
                    if (typeof colKey === 'string') {
                        const idx = golemMatch(this.shuffledColumns().map((x, idx) => ({ key: idx, value: x.value })), colKey);
                        log(`match col: ${colKey}=>${idx}`);
                        if (idx >= 0) {
                            log(`Golem says ${row.id}=>${idx}`);
                            rowMap.set(row.id, idx);
                        }
                    }
                }
            }
        }
        for (const row of rows) {
            const cells = row.cells();
            if (cells.some(x => x.isSelected())) {
                continue;
            }
            if (rowMap.has(row.id)) {
                const idx = rowMap.get(row.id);
                const cell = row.cells()[idx];
                log(`Golem says we should select ${cell.cellId} (#${idx})`);
                await cell.click.invoke();
                return true;
            } else {
                log(`Golem has no information about ${row.id}, select random entry`);
                await getRandomEntry(cells).click.invoke();
                return true;
            }
        }
        return false;
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.loaded(true);

    }

    public readonly shuffledRows = ko.pureComputed(() => {
        return this.data.rows.map(val => new VMRow(this, val));
    });

    public readonly shuffledColumns = ko.pureComputed(() => {
        return this.data.columns.map(val => new VMColumn(this, val));
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);


    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly clickAnswer = new UIAction<{ cellId: string }>(undefined, async (e, ids) => {
        await this.data.setAnswer({
            cellId: ids.cellId
        });
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

/* tslint:disable:max-func-body-length */
import $ from 'jquery';
import * as ko from 'knockout';
import Snap from 'snapsvg';
import { dir, error, log } from '../../../debug';
import { golemMatch } from '../../../golem';
import { getGolemData, isStringMap } from '../../../Gremlin';
import { assertNever, DONE } from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import * as arrayHelper from '../../../new_array';
import { getRandomEntry } from '../../../new_array';
import { multiline } from '../../../plugins/snapsvg/multiline';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { MatchingImageData, MatchingImageData_Answer, MatchingImageData_Image } from '../../model/matchingimage/MatchingImageData';
import { GetSession } from '../../model/session';
import { htmlString } from './matchingimage.html.g';



const WIDGET_NAME = 'itemdefinition-kosovo-matchingimage';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

const TEXT_HEIGHT = 30;
const START_TOP = 250;
const TOTAL_WIDTH = 890;
const TOTAL_HEIGHT = 300;
const LETTER_BOX_WIDTH = 20;
const LETTER_BOX_HEIGHT = 20;

export type IParams = IItemDefinitionWidgetParams;

interface IPoint {
    x: number;
    y: number;
}


class CurAnswer {

    public updateConnector() {
        let end: IPoint = null;
        const connectedTo = this.connectedTo();
        this.vm.svg_images.forEach((image) => {
            dir({ imageId: image.id, connectedTo, end: image.connectorPos });
            if (image.id === connectedTo) {
                end = image.connectorPos;
                image.svgElem.toggleClass(this.answerClass, true);
            } else {
                image.svgElem.toggleClass(this.answerClass, false);
            }
        });

        if (!end) {
            this.userConnector.svgPath.attr({ visibility: 'hidden' });
            this.userConnector.svgEnd.attr({ visibility: 'hidden' });
            this.svgElem.toggleClass(this.answerClass, false);
        } else {
            const path = getConnectorPath(this.connectorPos, end);
            this.svgElem.toggleClass(this.answerClass, true);
            this.userConnector.svgPath.attr({
                d: path,
                visibility: 'visible'
            });
            this.userConnector.svgEnd.attr({
                cx: end.x,
                cy: end.y,
                visibility: 'visible'
            });
            if (this.vm.mode() === 'RESULT' || this.vm.mode() === 'PRINT') {
                const isCorrectAnswer = this.vm.data.answers.find(ans => ans.id === this.id).correctConnectTo === this.connectedTo();
                this.svgElem.toggleClass('Correct', isCorrectAnswer);
                const img = this.vm.svg_images.filter((e) => { return e.id === this.connectedTo(); });
                if (img.length > 0) {
                    img[0].svgElem.toggleClass('Correct', isCorrectAnswer);
                }
                this.innersvgElem.toggleClass(
                    'Correct',
                    isCorrectAnswer);
                this.userConnector.svgPath.toggleClass(
                    'Correct',
                    isCorrectAnswer);
                this.userConnector.svgPath.toggleClass(
                    'UnCorrect',
                    !isCorrectAnswer);
            }
        }
    }

    constructor(readonly vm: ViewModel, readonly model: MatchingImageData_Answer) {
        const nAnswers = vm.data.answers.length;
        this.connectedTo = model.connectedTo;

        const PER_ANSWER_WIDTH = TOTAL_WIDTH / (nAnswers + 1);
        const PER_ANSWER_GAP = TOTAL_WIDTH / nAnswers;

        const startX = PER_ANSWER_GAP * model.nAnswer;
        const rect = vm.snap.rect(startX, START_TOP, PER_ANSWER_WIDTH, TEXT_HEIGHT, 5, 5);
        this.id = model.id;
        this.svgElem = rect;
        const rectBBox = rect.getBBox();
        this.connectorPos = {
            x: (rectBBox.x2 + rectBBox.x) / 2,
            y: rectBBox.y
        };
        const y = START_TOP + TEXT_HEIGHT / 2;
        const text = multiline(vm.snap, startX + PER_ANSWER_WIDTH / 2, y, model.text);
        text.attr({ 'text-anchor': 'middle' });
        if (text.node.childElementCount > 1) {
            rect.attr({
                height: text.node.childElementCount * TEXT_HEIGHT
            });
            $(vm.svgElement).attr({
                height: TOTAL_HEIGHT + text.node.childElementCount * TEXT_HEIGHT
            });
        }
        const bbox = text.getBBox();
        const realY = START_TOP + (TEXT_HEIGHT * text.node.childElementCount - bbox.height) / 2;
        text.attr({ y: y - bbox.y + realY });

        this.answerClass = 'Answer' + model.nAnswer;
        this.userConnector = {
            svgPath: null,
            svgEnd: null
        };
        this.userConnector.svgPath = this.vm.snap.path('').addClass('Connector').addClass('Answer' + this.model.nAnswer);
        this.userConnector.svgEnd = this.vm.snap.circle(0, 0, 3).addClass('ConnectorEnd').attr({ visibility: 'hidden' });

        this.rightConnector = {
            svgPath: vm.snap.path('').addClass('ConnectorResult').addClass('Result_Answer' + this.model.nAnswer),
            svgEnd: vm.snap.circle(0, 0, 3).addClass('ConnectorEndResult').attr({
                visibility: 'hidden'
            })
        };

        if (vm.mode() === 'RESULT' || vm.mode() === 'PRINT') {
            const correctImageId = arrayHelper.greekToLatin(model.correctConnectTo.substr(-1));
            const innerrect = vm.snap.rect(this.connectorPos.x - LETTER_BOX_WIDTH / 2, this.connectorPos.y - LETTER_BOX_HEIGHT / 2 - 3, LETTER_BOX_WIDTH, LETTER_BOX_HEIGHT, 5, 5);
            vm.snap.text(this.connectorPos.x - LETTER_BOX_WIDTH / 2 + 5, this.connectorPos.y + 4, correctImageId);
            rect.addClass('CorrectAnswer_' + correctImageId);
            innerrect.addClass('CorrectAnswer_' + correctImageId);
            this.innersvgElem = innerrect;
        }

        vm.disposables.addDiposable(this.connectedTo.subscribe((imageId) => {
            this.updateConnector();
        }));

        this.svgElem.addClass('Selectable');
        this.svgElem.click(() => {
            if (this.vm.mode() === 'INTERACTIVE') {
                this.vm.selectAnswer.click(this);
            }
        });

        //this.updateConnector();

    }
    public readonly connectedTo: ko.Observable<string>;
    public readonly id: string;
    public readonly svgElem: Snap.Element;
    private readonly innersvgElem: Snap.Element;
    private readonly answerClass: string;
    private readonly connectorPos: IPoint;
    private readonly userConnector: {
        svgPath: Snap.Element,
        svgEnd: Snap.Element
    };
    private readonly rightConnector: {
        svgPath: Snap.Element,
        svgEnd: Snap.Element
    };

}


function getConnectorPath(start: IPoint, end: IPoint) {
    const r = Math.round;
    return ['M', r(start.x), ',', r(start.y), 'Q', start.x, ',',
        r(start.y), ' ', r(start.x), ',', r(start.y), 'T', r(end.x), ',', r(end.y)].join('');
}

class CurImage {
    constructor(readonly vm: ViewModel, readonly model: MatchingImageData_Image) {
        this.id = model.id;
    }

    public async load() {
        const nImages = this.vm.data.images.length;

        const IMG_HEIGHT = 200;
        const PER_ANSWER_WIDTH = TOTAL_WIDTH / (nImages + 1);
        const PER_ANSWER_GAP = TOTAL_WIDTH / (nImages);

        const startX = PER_ANSWER_GAP * this.model.nImage;
        const rect = this.vm.snap.rect(startX, 0, PER_ANSWER_WIDTH, IMG_HEIGHT, 5, 5);
        this.svgElem = rect;
        const rectBBox = rect.getBBox();
        this.connectorPos = { x: (rectBBox.x2 + rectBBox.x) / 2, y: rectBBox.y2 };

        const url = this.model.href;
        let { width, height } = this.model;
        const maxWidth = rectBBox.width - 4;
        if (width > maxWidth) {
            height = height * (maxWidth / width);  // Scale height based on ratio
            width = maxWidth;    // Reset width to match scaled image
        }
        const maxHeight = rectBBox.height - 4; // Check if current height is larger than max
        if (height > maxHeight) {
            width = width * (maxHeight / height);    // Reset width to match scaled image
            height = maxHeight;    // Reset height to match scaled image
        }
        const snapImage = this.vm.snap.image(url, rectBBox.x + (rectBBox.width - width) / 2, rectBBox.y + (rectBBox.height - height) / 2,
            width, height);
        snapImage.attr({ alt: this.model.alt });
        if (this.vm.mode() === 'RESULT' || this.vm.mode() === 'PRINT') {
            const innerrect = this.vm.snap.rect(this.connectorPos.x - LETTER_BOX_WIDTH / 2, this.connectorPos.y - LETTER_BOX_HEIGHT / 2, LETTER_BOX_WIDTH, LETTER_BOX_HEIGHT, 5, 5);
            this.vm.snap.text(this.connectorPos.x - LETTER_BOX_WIDTH / 2 + 5, this.connectorPos.y + 7, this.model.latinId);
            rect.addClass('CorrectAnswer_' + this.model.latinId);
            innerrect.addClass('CorrectAnswer_' + this.model.latinId);
            this.innersvgElem = innerrect;
        }
        this.svgElem.addClass('Selectable');
        this.svgElem.click(() => {
            if (this.vm.mode() === 'INTERACTIVE') {
                this.vm.selectImage.click(this);
            }
        });

    }

    public readonly id: string;
    public svgElem: Snap.Element;
    private innersvgElem: Snap.Element;
    public connectorPos: IPoint;

}
export class ViewModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly data: MatchingImageData;

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.mode(params.mode || 'INTERACTIVE');

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof MatchingImageData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });
    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                assertNever(mode);
                throw new Error();

        }
    });


    public svg_images: Array<CurImage> = [];
    public svg_answers: Array<CurAnswer> = [];
    public svgElement: SVGSVGElement;
    public snap: Snap.Paper;

    private updateSelectionStatus() {
        this.svg_answers.forEach((xAnswer) => {
            xAnswer.svgElem.toggleClass('Selected', this.selectedAnswerId() === xAnswer.id);
        });
        this.svg_images.forEach((xImage) => {
            xImage.svgElem.toggleClass('Selected', this.selectedImageId() === xImage.id);
        });
    }
    private readonly selectedAnswerId = ko.observable('');
    private readonly selectedImageId = ko.observable('');

    private async tryConnect() {
        this.updateSelectionStatus();
        if (!this.selectedAnswerId() || !this.selectedImageId()) {
            return;
        }
        await this.connect.intent({
            answerId: this.selectedAnswerId(),
            imageId: this.selectedImageId()
        });
        this.selectedAnswerId(null);
        this.selectedImageId(null);
        this.updateSelectionStatus();
    }

    public readonly selectAnswer = new UIAction<CurAnswer>(undefined, async (e, answer) => {
        this.selectedAnswerId(answer.id);
        await this.tryConnect();
    });
    public readonly selectImage = new UIAction<CurImage>(undefined, async (e, image) => {
        this.selectedImageId(image.id);
        await this.tryConnect();
    });

    private async initSVG() {
        const element = $(this.componentInfo.element).find('.question-content');
        const htmlElement = element.get(0);

        //const subscriptions: Array<HELPER.IDisposeable> = [];
        for (let i = htmlElement.childNodes.length - 1; i >= 0; i--) {
            ko.removeNode(htmlElement.childNodes[i]);
        }
        //tslint:disable-next-line:no-http-string
        this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        $(this.svgElement).attr({
            width: TOTAL_WIDTH,
            height: TOTAL_HEIGHT
        });
        htmlElement.appendChild(this.svgElement);
        this.snap = <Snap.Paper>Snap(this.svgElement);

        this.svg_answers = this.data.answers.map(x => new CurAnswer(this, x));
        this.svg_images = this.data.images.map(x => new CurImage(this, x));
        await Promise.all(this.svg_images.map(x => x.load()));

        this.updateConnectors();
    }

    private updateConnectors() {
        this.svg_answers.forEach((answer: CurAnswer, nAnswer: number) => {
            answer.updateConnector();
        });
    }

    private golemState: Map<string, string>;

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }

                if (!this.golemState) {
                    this.golemState = new Map<string, string>();
                    const answers = this.svg_answers.map(x => ({
                        key: x.id,
                        value: x.model.text,
                    }));
                    const images = this.svg_images.map(x => ({
                        key: x.id,
                        value: x.model.imageName,
                    }));
                    const usedImages = new Set<string>();
                    const golemData = getGolemData(this.itemId);
                    if (isStringMap(golemData)) {

                        for (const key of Object.keys(golemData)) {
                            const answerId = golemMatch(answers, key);
                            if (this.golemState.has(answerId)) {
                                error(`Golem for ${this.itemId} has duplicate match for '${key}'`);
                                continue;
                            }
                            if (answerId) {
                                const imageId = golemMatch(images, golemData[key]);
                                if (!imageId) {
                                    error(`Golem for ${this.itemId} answer ${answerId} has no matching image ${golemData[key]}`);
                                    continue;
                                }
                                if (usedImages.has(imageId)) {
                                    error(`Golem for ${this.itemId} uses image ${imageId} more than once`);
                                    continue;
                                }
                                this.golemState.set(answerId, imageId);
                            }
                        }
                    }
                    for (const ans of answers) {
                        if (this.golemState.has(ans.key)) {
                            continue;
                        }
                        const randomImage = getRandomEntry(images.filter(x => !usedImages.has(x.key)));
                        log(`Golem has no suggestion for ${this.itemId}/${ans.key} - picking ${randomImage.key}`);
                        usedImages.add(randomImage.key);
                        this.golemState.set(ans.key, randomImage.key);
                    }
                    log(JSON.stringify({
                        golemData,
                        itemId: this.itemId,
                        golemState: Array.from(this.golemState.entries())
                    }));
                }

                const data = {
                    svg_answers: this.svg_answers.map(x => ({
                        id: x.id,
                        connectedTo: x.connectedTo()
                    })),
                    svg_images: this.svg_images.map(x => ({
                        id: x.id,
                        isConnected: !!this.svg_answers.find(y => y.connectedTo() == x.id)
                    }))
                };
                for (const a of this.svg_answers) {
                    if (!a.connectedTo()) {
                        const imageId = this.golemState.get(a.id);
                        const image = this.svg_images.find(x => x.id === imageId);
                        if (!image) {
                            log(`Gremlin ${this.itemId} - no image for ${a.id}->${imageId}`);
                            return false;
                        }
                        await this.connect.invoke({
                            answerId: a.id,
                            imageId: image.id,
                        });
                        return true;
                    }
                }
                return false;
            }
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        await this.initSVG();
        this.loaded(true);
    }


    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly connect = new UIAction<{ answerId: string, imageId: string }>(undefined, async (e, args) => {
        const answerId = args.answerId;
        const imageId = args.imageId;
        if (!answerId || !imageId) {
            return;
        }
        await this.data.connect({ answerId, imageId });
    });

    public async OnReset() {
        this.selectedAnswerId(undefined);
        this.selectedImageId(undefined);
        this.updateSelectionStatus();
        return DONE;
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import * as API from '../../../its-itembank-api.g';
import { ObjectiveOverviewBodyField } from './ObjectiveOverviewBodyField';
import { ObjectiveOverviewBodyFieldTemplate } from './ObjectiveOverviewBodyFieldTemplate';
type Q_OBJ = Awaited<ReturnType<API.Sdk['ui_resultscreens_default_objectives']>>;
type OBJECTIVE = Q_OBJ['examSession']['objectives'][0];

export class Objective {
    public readonly depth: number;
    constructor(readonly model: {
        objectiveBodyFields: ko.MaybeSubscribable<ObjectiveOverviewBodyFieldTemplate[]>
    }, readonly params: OBJECTIVE) {
        this.depth = params.objectiveId.split('.').length;
    }

    public readonly fields = ko.pureComputed(() => {
        return ko.unwrap(this.model.objectiveBodyFields).map(x => new ObjectiveOverviewBodyField(x, this));
    });


}

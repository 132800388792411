import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18n from './../../../i18n/i18n';
export const WIDGET_NAME = 'ui-translator-translateexamorder';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    examOrderDocRefId: string,
}

export class TranslateExamOrderRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18n.t(['ui.translator.translateexamorder.TRANSLATE_EXAM_ORDER']);
    });


    constructor(readonly widgetParams: IParamsInner) {
    }

    static async CREATE(widgetParams: IParamsInner) {
        return new TranslateExamOrderRoute(widgetParams);
    }
}

export const FACTORY = ROUTES.registerRoute<{ examorder: string }>({
    route: '/translator/examorder/:examorder',
    toParams: map => ({
        examorder: map.get('examorder'),
    }),
    fromParams: (p, map) => {
        map.set('examorder', p.examorder);
    },
    create: async p => {
        return TranslateExamOrderRoute.CREATE({
            examOrderDocRefId: p.examorder,
        });
    },
});

// tslint:disable:object-literal-key-quotes
import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { log } from '../../../debug';
import { datagrid, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import { BRtoLF, joinStrings, LFtoBR } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { TriStateEnum } from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_ITEMREVIEW from '../itemreview/route';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './itemreviewselection.html.g';
import { IParams, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_itemreviewselection_items']>>;
export type usesType = 'pilot' | 'matura' | 'sample' | 'provisioned' | 'all';

type ITEM = Q['subjectById']['itemsForSubjectCoordinator'][0];

export class ViewModel extends DxWidget {
    private _keywords: Array<{ text: string, value: string }> = [];
    private _objectives: Array<{ text: string, value: string }> = [];
    private _categories: Array<{ text: string, value: string }> = [];
    private _audiences: Array<{ text: string, value: string }> = [];

    public readonly boxOptions = {
        direction: 'row',
        width: '100%',
        height: '90%'
    };

    private readonly _items: ITEM[] = [];

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                wordWrapEnabled: true,
                columnChooser: {
                    enabled: true,
                },
                paging: {
                    pageSize: 10,
                },
                pager: {
                    showPageSizeSelector: true,
                    allowedPageSizes: [10, 25, 50, 100],
                },
                export: {
                    enabled: true,
                    allowExportSelectedData: true,
                    fileName: i18next.t(['ui.subjectcoordinator.itemreviewselection.ITEM_BERSICHT']),
                    customizeExcelCell: o => {
                        if (!o.gridCell) {
                            return;
                        }
                        if (o.gridCell.rowType !== 'data') {
                            return;
                        }
                        if (typeof o.value === 'string') {
                            o.gridCell.value = BRtoLF(o.value);
                        }
                        o.wrapTextEnabled = true;
                    }
                },
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true,
                },
                filterRow: {
                    visible: true,
                },
                headerFilter: {
                    visible: true,
                },
                filterPanel: {
                    visible: true,
                },
                selection: {
                    mode: 'multiple',
                    selectAllMode: 'page',
                    allowSelectAll: true,
                    showCheckBoxesMode: 'always',
                },
                groupPanel: {
                    visible: false,
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'docReferenceId',
                        load: async () => {
                            return this._items;
                        },
                        byKey: async key => {
                            return this._items.find(x => x.docReferenceId === key);
                        }
                    }),
                },
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.ITEM_ID']),
            dataField: 'itemId',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
            width: 100,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.TITLE']),
            dataField: 'metaData.title',
            dataType: 'string',
            width: 100,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.EDITED_BY']),
            dataType: 'string',
            dataField: 'workflow.currentAuthor.displayName',
            width: 100,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.CATEGORY']),
            dataType: 'string',
            name: 'objective',
            width: 100,
            headerFilter: {
                dataSource: this._objectives
            },
            calculateFilterExpression: (value, operation, target) => {
                log(value);
                if (value) {
                    const selector = (data: ITEM) => {
                        return !!data.accumulatedObjectives.find(x => x.taxon === value);
                    };
                    return [selector, operation || '=', true];
                } else {
                    return undefined;
                }
            },

            calculateDisplayValue: x => {
                const queryRow: ITEM = x;
                return joinStrings(', ', ...queryRow.accumulatedObjectives.map(x => x.taxon).sort());
            },
            calculateCellValue: x => {
                const queryRow: ITEM = x;
                return queryRow.accumulatedObjectives.map(x => x.taxon);
            },
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.OBJECTIVE']),
            name: 'category',
            dataType: 'string',
            width: 100,

            headerFilter: {
                dataSource: this._categories,
            },
            calculateFilterExpression: (value, operation, target) => {
                log(value);
                if (value) {
                    const selector = (data: ITEM) => {
                        return !!data.accumulatedCategories.find(x => x.taxon === value);
                    };
                    return [selector, operation || '=', true];
                } else {
                    return undefined;
                }
            },
            calculateCellValue: (x: ITEM) => {
                return x.accumulatedObjectives.map(x => x.taxon);
            },

            calculateDisplayValue: (x: ITEM) => {
                return x.accumulatedCategories.map(x => x.taxon).join(', ');
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.SCORE']),
            dataType: 'string',
            width: 100,
            dataField: 'accumulatedScoreMax'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.ITEM_TYPE']),
            dataType: 'string',
            width: 100,
            dataField: 'itemType.title.value'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.AUDIENCE']),
            name: 'audience',
            width: 100,
            dataType: 'string',
            headerFilter: {
                dataSource: this._audiences
            },
            calculateFilterExpression: (value, operation, target) => {
                log(value);
                if (value) {
                    const selector = (data: ITEM) => {
                        return !!data.metaData.audience.find(x => x.audienceId === value);
                    };
                    return [selector, operation || '=', true];
                } else {
                    return undefined;
                }
            },

            calculateDisplayValue: (x: ITEM) => {
                return x.metaData.audience.map(x => x.audienceId).sort().join(', ');
            },
            calculateCellValue: (x: ITEM) => {
                return x.metaData.audience.map(x => x.audienceId).sort();
            },
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.DIFFICULTY']),
            width: 100,
            dataField: 'metaData.itemDifficultyIndex'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.STATUS']),
            dataType: 'string',
            width: 100,
            dataField: 'workflow.authoringStatus',
            calculateCellValue: x => {
                const queryRow: ITEM = x;
                return i18next.t(TRANSLATIONS.AuthoringStatus[queryRow.workflow.authoringStatus]) || '';
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.NOTE']),
            width: 100,
            dataField: 'workflow.noteForAuthor'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.VISIBLE_TEXT']),
            dataField: 'visibleText',
            customizeText: (cellInfo) => {
                return LFtoBR(cellInfo.valueText);
            },
            encodeHtml: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.KEYWORDS']),
            dataField: 'keywords',
            width: 100,
            headerFilter: {
                dataSource: this._keywords
            },
            calculateDisplayValue: (data: ITEM) => {
                return joinStrings(', ', ...data.keywords.sort());
            },
            calculateFilterExpression: (value, operation, target) => {
                log(value);
                if (value) {
                    const selector = (data: ITEM) => {
                        return data.keywords.indexOf(value) >= 0;
                    };
                    return [selector, operation || '=', true];
                } else {
                    return undefined;
                }
            },
        });

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.OBSOLETE']),
            dataField: 'workflow.obsolete',
            dataType: 'boolean',
            width: 100,
        });

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.ACTIONS']),

            width: '80',
            cellTemplate: (elem, info) => {
                const retVal = $('<a />');
                retVal.text(i18next.t(['ui.subjectcoordinator.itemreviewselection.OPEN']));
                retVal.css({ cursor: 'pointer' });
                retVal.on('click', () => {
                    const user: ITEM = info.data;
                    void this.actionOpen.intent(user.docReferenceId);

                });
                return retVal;
            }
        });
        retVal.onSelectionChanged = e => {
            this.selectedKeys(e.selectedRowKeys);
        };
        retVal.filterValue = ['workflow.obsolete', '=', false];
        return retVal;
    });

    public async gotoItemReview(itemDocRefIds: string[]) {
        const subjectDocRefId = this.params.currentRoute.widgetParams.subjectDocRefId;

        await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMREVIEW.FACTORY.href(
            {
                subject: subjectDocRefId,
                items: itemDocRefIds
            }
        ));
    }

    public readonly actionOpen = new UIAction<string>(undefined, async (e, itemDocRefId) => {
        await this.gotoItemReview([itemDocRefId]);
    });

    constructor(readonly params: IParams) {
        super();
    }



    public readonly filterKeys = ko.observableArray<string>();
    public readonly selectedKeys = ko.observableArray<string>();


    public readonly hasNoSelection = ko.pureComputed(() => {
        const selectedKeys = this.selectedKeys() || [];
        return !selectedKeys.length;
    });

    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            colCount: 8,
            minColWidth: 50,
            formData: {
            },
            items: [
                {
                    itemType: 'button',
                    buttonOptions: {
                        text: i18next.t(['ui.subjectcoordinator.itemreviewselection.REVIEW_SELECTED_ITEMS']),
                        onClick: () => {
                            this.itemReviewAction.click();
                        },
                        disabled: this.hasNoSelection(),
                        width: 250
                    }
                },
            ]
        };
        return retVal;
    });

    public readonly itemReviewAction = new UIAction(undefined, async (e) => {
        await this.gotoItemReview(this.selectedKeys());
    });

    public readonly loaded = ko.observable(false);

    public async OnRefresh() {
        const subjectDocRefId = this.params.currentRoute.widgetParams.subjectDocRefId;
        const result = await ServerConnection.api.ui_subjectcoordinator_itemreviewselection_items({
            subjectId: subjectDocRefId,
            filter: {
                showDeleted: TriStateEnum.No,
                supportsSubItems: TriStateEnum.All,
                topItems: TriStateEnum.Yes,
                obsolete: TriStateEnum.All
            },
        });

        this._items.splice(0, this._items.length, ...result.subjectById.itemsForSubjectCoordinator);

        const keywords = new Set<string>();
        const objectives = new Set<string>();
        const categories = new Set<string>();
        const audiences = new Map<string, string>();
        for (const item of this._items) {
            for (const kw of item.keywords) {
                keywords.add(kw);
            }
            for (const o of item.accumulatedObjectives) {
                objectives.add(o.taxon);
            }
            for (const c of item.accumulatedCategories) {
                categories.add(c.taxon);
            }
            for (const a of item.metaData.audience) {
                audiences.set(a.audienceId, a.title);
            }
        }
        this._audiences.splice(0, this._audiences.length, ...Array.from(audiences.keys()).sort().map(x => ({ text: x, value: x })));
        this._keywords.splice(0, this._keywords.length, ...Array.from(keywords).sort().map(x => ({ text: x, value: x })));
        this._objectives.splice(0, this._objectives.length, ...Array.from(objectives).sort().map(x => ({ text: x, value: x })));
        this._categories.splice(0, this._categories.length, ...Array.from(categories).sort().map(x => ({ text: x, value: x })));

        await refreshDx(this);
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

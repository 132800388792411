//const digitTest = /^\d+$/;
//const keyBreaker = /([^\[\]]+)|(\[\])/g;
const plus = /\+/g;
const paramTest = /([^?#]*)(#.*)?$/;

export function deparam(params: string): Map<string, string> {
    const retVal = new Map<string, string>();
    if (!params || !paramTest.test(params)) {
        return retVal;
    }
    const pairs = params.split('&');
    for (let i = 0; i < pairs.length; i++) {
        if (!pairs[i]) {
            continue;
        }
        let pair = pairs[i].split('=');
        // if we find foo=1+1=2
        if (pair.length !== 2) {
            pair = [pair[0], pair.slice(1).join('=')];
        }
        const key = decodeURIComponent(pair[0].replace(plus, ' '));
        const value = decodeURIComponent(pair[1].replace(plus, ' '));
        retVal.set(key, value);
    }
    return retVal;
}


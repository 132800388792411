import * as ko from 'knockout';
import * as i18n from '../../../i18n/i18n';
import * as ROUTES from '../../routes';

export const WIDGET_NAME = 'ui-login-login';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class LoginRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly languageSwitcher = true;
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18n.t(['ui.superuser.login.login.LOGIN']);
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/login',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return new LoginRoute(undefined);

    },
});

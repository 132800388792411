import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as R from '../selectitem/route';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './selectsubject.html.g';

const WIDGET_PARENT_NAME = 'ui-bulkgrading';


export class ViewModel extends AbstractWidget {
    public readonly select = new UIAction<string>(undefined, async (e, subjectDocRefId) => {
        await ROUTES.routeManager.navigateToHREF(R.FACTORY.href({
            subject: subjectDocRefId
        }));
    });

    constructor(readonly params: IParams) {
        super();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

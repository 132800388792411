import * as ko from 'knockout';
import * as query from '../getquery';
import { ServerConnection } from './RestAPI';

export class WindowManager {
    public readonly hasOpenWindow = ko.observable(false);
    constructor() {
        setInterval(() => {
            const wnd = this.popupWindow();
            if (!wnd) {
                return;
            }
            try {
                if (!wnd.closed) {
                    return;
                }
            }
            catch (err) {
                return;
            }
            this.popupWindow(undefined);
            this.hasOpenWindow(false);
        }, 1000);
    }
    private readonly popupWindow = ko.observable<Window>();
    public prepareOpenWindow(options: string) {
        if (this.popupWindow()) {
            throw new Error('There is already an open window');
        }
        const blankUrl = ServerConnection.getDataUrl(query.params.get('blank'));
        this.popupWindow(window.open(blankUrl, '', options));
    }
    public openWindow(url: string, name: string) {
        const wnd = this.popupWindow();
        if (!wnd) {
            throw new Error('Window was closed prematurly (or prepareOpenWindow() was not called)');
        }
        wnd.name = name;
        url = ServerConnection.getDataUrl(url);
        wnd.location.replace(url);
        this.hasOpenWindow(true);
    }
    public downloadFile(url: string) {
        window.location.assign(url);
    }
}

import { makeDS } from '../i18n/data';
import * as API from '../its-itembank-api.g';
import { Organisation_NoteType, ScoreRoundingModeEnum } from '../its-itembank-api.g';
import * as TRANSLATIONS from './translations';

export const ORGANISATION_NOTETYPE = makeDS(TRANSLATIONS.Organisation_NoteType, [
    Organisation_NoteType.Email,
    Organisation_NoteType.Letter,
    Organisation_NoteType.Telephone,
    Organisation_NoteType.Voicemail,
    Organisation_NoteType.Fax,
    Organisation_NoteType.Note,
]);



export const AUTHORING_STATUS = makeDS(TRANSLATIONS.AuthoringStatus, [
    'open',
    'inprogress',
    'done',
    'reopened']);
export const TRANSLATION_STATUS = makeDS(TRANSLATIONS.TranslationStatus, [
    'open',
    'inprogress',
    'done',
    'reopened']);

export const LevelOfSecrecyString = makeDS(TRANSLATIONS.LevelOfSecrecyString, [
    API.LevelOfSecrecy.Topsecret,
    API.LevelOfSecrecy.Secret,
    API.LevelOfSecrecy.Restricted,
    API.LevelOfSecrecy.Public
]);
export const USER_TEXT_ENTRY = makeDS(TRANSLATIONS.UserTextEntry, [
    'no',
    'yes'
]);

export const TESTSESSION_MODE = makeDS(TRANSLATIONS.TESTSESSION_MODE, [
    'New',
    'AvailableForParticipation',
    'Participating',
    'Participated',
    'AvailableForGrading',
    'Grading',
    'Graded',
    'AvailableForExport',
    'Exported',
    'Archived']);

export const ResetOnNavigate = makeDS(TRANSLATIONS.ResetOnNavigate, [
    API.ResetOnNavigateEnum.Default,
    API.ResetOnNavigateEnum.Always,
    API.ResetOnNavigateEnum.Never,
]);

export const ItemHost = makeDS(TRANSLATIONS.ItemHost, [
    API.ItemHostEnum.Hierarchical,
    API.ItemHostEnum.Singleitem,
]);

export const NavigationStyle = makeDS(TRANSLATIONS.NavigationStyle, [
    API.NavigationStyleEnum.SubmitPostpone,
    API.NavigationStyleEnum.PreviousNext,
    API.NavigationStyleEnum.SubmitPostponeAutoFocus,
]);

export const ScormTokenStatus = makeDS(TRANSLATIONS.ScormTokenStatus, [
    API.ScormTokenStatus.Active,
    API.ScormTokenStatus.Closing,
    API.ScormTokenStatus.Inactive,
]);

export const ScormTokenTrackability = makeDS(TRANSLATIONS.ScormTokenTrackability, [
    API.ScormTokenTrackability.Verbatim,
    API.ScormTokenTrackability.Anonymous,
]);


export const ItemOrderStrategy = makeDS(TRANSLATIONS.ItemOrderStrategy, [
    API.ItemOrderStrategy.Default,
    API.ItemOrderStrategy.Randomized,
    API.ItemOrderStrategy.Fixed,
    API.ItemOrderStrategy.RandomizedHeavyFirst,
    API.ItemOrderStrategy.RandomizedLightFirst,
]);

export const ScoreRoundingMode = makeDS(TRANSLATIONS.ScoreRoundingMode, [
    ScoreRoundingModeEnum.Default,
    ScoreRoundingModeEnum.NoRounding,
    ScoreRoundingModeEnum.IntegerHalfUp,
    ScoreRoundingModeEnum.IntegerUp,
]);

export const ExaminationOrderDefineScore = makeDS(TRANSLATIONS.ExaminationOrderDefineScore, [
    API.ExaminationOrderDefineScore.Any,
    API.ExaminationOrderDefineScore.Superuser,
    API.ExaminationOrderDefineScore.Subjectcoordinator,
]);

export const InvisibleHotSpotEdit_Scoring = makeDS(TRANSLATIONS.InvisibleHotSpotEdit_Scoring, [
    API.InvisibleHotSpotEdit_Scoring.AllOrNothing,
    API.InvisibleHotSpotEdit_Scoring.PerAnswer,
]);

export const TimeDisplay = makeDS(TRANSLATIONS.TimeDisplay, [
    API.DisplayTime.Default,
    API.DisplayTime.Passed,
    API.DisplayTime.Remaining,
    API.DisplayTime.No,
]);

export const ProgressDisplay = makeDS(TRANSLATIONS.ProgressDisplay, [
    API.DisplayProgress.Default,
    API.DisplayProgress.Passed,
    API.DisplayProgress.Remaining,
    API.DisplayProgress.No,
]);

export const ItemEditVisibility = makeDS(TRANSLATIONS.ItemEditVisibility, [
    API.ItemEditVisibility.AllAuthors,
    API.ItemEditVisibility.AssignedAuthors,
]);

export const DisplayItemId = makeDS(TRANSLATIONS.DisplayItemId, [
    API.DisplayItemIdEnum.Default,
    API.DisplayItemIdEnum.Yes,
    API.DisplayItemIdEnum.No,
]);

export const DisplayObjectiveId = makeDS(TRANSLATIONS.DisplayObjectiveId, [
    API.DisplayObjectiveIdEnum.Default,
    API.DisplayObjectiveIdEnum.Yes,
    API.DisplayObjectiveIdEnum.No,
]);

export const ButtonVisibility = makeDS(TRANSLATIONS.ButtonVisibility, [
    API.ButtonVisibility.Default,
    API.ButtonVisibility.Show,
    API.ButtonVisibility.Hide,
]);

export const ShowItemMaxScore = makeDS(TRANSLATIONS.ShowItemMaxScore, [
    API.ShowItemMaxScore.Default,
    API.ShowItemMaxScore.Yes,
    API.ShowItemMaxScore.No,
]);
export const ShowImmediateResult = makeDS(TRANSLATIONS.ShowImmediateResult, [
    API.ShowImmediateResult.Never,
    API.ShowImmediateResult.Scored,
]);

export const ResultPagePDF = makeDS(TRANSLATIONS.ResultPagePDF, [
    'no',
    'yes',
]);

export const ResultPageIncludeItems = makeDS(TRANSLATIONS.ResultPageIncludeItems, [
    API.PdfReportSettingsIncludeItems.No,
    API.PdfReportSettingsIncludeItems.Yes,
    API.PdfReportSettingsIncludeItems.WhatCandidateSaw,
]);

export const ResultPageEMail = makeDS(TRANSLATIONS.ResultPageEMail, [
    'no',
    'optional',
    'required',
]);

export const ContinuationModeString = makeDS(TRANSLATIONS.ContinuationModeString, [
    API.ContinuationModeEnum.Admin,
    API.ContinuationModeEnum.System,
    API.ContinuationModeEnum.Takeover,
]);

export const ContinueWithString = makeDS(TRANSLATIONS.ContinueWithString, [
    API.ContinueWithEnum.Item,
    API.ContinueWithEnum.Overview,
]);

export const StatusString = makeDS(TRANSLATIONS.StatusString, [
    'open',
    'populating',
    'ready',
    'published',
]);

export const SubjectCoordinatorStatusString = makeDS(TRANSLATIONS.SubjectCoordinatorStatusString, [
    'open',
    'finished',
]);

export const ExaminationTypeString = makeDS(TRANSLATIONS.ExaminationTypeString, [
    API.ExaminationTypeEnum.Demo,
    API.ExaminationTypeEnum.Certification,
    API.ExaminationTypeEnum.Assessment,
    API.ExaminationTypeEnum.Selfassessment,
    API.ExaminationTypeEnum.Qa,
    API.ExaminationTypeEnum.Showcase,
    API.ExaminationTypeEnum.Adhoc,
]);

export const UserRoleString = makeDS(TRANSLATIONS.UserRoleString, [
    API.Privilege.AuthorSubjects,
    API.Privilege.Admin,
    API.Privilege.SubjectcoordinatorSubjects,
    API.Privilege.Superuser,
    API.Privilege.EvaluatorSubjects,
    API.Privilege.TranslatorSubjects,
    API.Privilege.TranslatorLocalizations,
]);


import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import dxButton from 'devextreme/ui/button';
import $ from 'jquery';
import { dir } from '../../../../debug';
import { AS } from '../../../../dx_helper';
import * as API from '../../../../its-itembank-api.g';
import { stringCompare } from '../../../../tree';
import * as i18n from './../../../../i18n/i18n';

export type Q = Awaited<ReturnType<API.Sdk['inapplication_edit_data']>>;
export type PREPERATOR = Q['handsOnEdit']['get']['preparators'][0];
export type INVESTIGATOR = Q['handsOnEdit']['get']['investigators'][0];
export type PROPERTY = PREPERATOR['properties'][0];
export type FILE = Q['handsOnEdit']['get']['filestructure'][0];
export type ATTACHMENT = Q['documents']['get']['attachments'][0];
export type EVALUATION = Q['handsOnEdit']['get']['evaluations'][0];
export type ALL_FILE = Q['item']['getFileStructure'][0];

export function getLabel(property: PROPERTY) {
    if (!property.info) {
        return {
            text: `INVALID ${property.propertyId}`,
            showColon: true
        };
    }
    return {
        text: `${property.propertyId} (${property.info.type})`,
        showColon: true
    };
}


function propertyDirectory(options: DevExpress.ui.dxForm.Properties, property: PROPERTY, allFiles: ALL_FILE[]) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        itemType: 'simple',
        editorType: 'dxLookup',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxLookup.Properties>({
            valueExpr: 'path',
            displayExpr: 'path',
            showClearButton: true,
            dataSource: {
                store: new CustomStore({
                    loadMode: 'raw',
                    key: 'path',
                    byKey: async key => {
                        if (!allFiles) {
                            return undefined;
                        }
                        return allFiles.find(x => x.path === key);
                    },
                    load: async () => {
                        return (allFiles || []).filter(x => x.path && x.path.endsWith('/'));
                    }
                })
            }
        }),
        validationRules: []


    };

    switch (property.info.validationRule) {
        case API.ValidationRuleEnum.None:
            break;
        case API.ValidationRuleEnum.Hasvalue:
            item.isRequired = true;
            item.validationRules.push({ type: 'required' });
            break;
    }

    options.items.push(item);
}
function propertyFile(options: DevExpress.ui.dxForm.Properties, property: PROPERTY, allFiles: ALL_FILE[]) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        itemType: 'simple',
        editorType: 'dxLookup',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxLookup.Properties>({
            valueExpr: 'path',
            displayExpr: 'path',
            showClearButton: true,
            dataSource: {
                store: new CustomStore({
                    loadMode: 'raw',
                    key: 'path',
                    byKey: async key => {
                        if (!allFiles) {
                            return undefined;
                        }
                        return allFiles.find(x => x.path === key);
                    },
                    load: async () => {
                        return (allFiles || []).filter(x => x.path && !x.path.endsWith('/'));
                    }
                })
            }
        }),
        validationRules: []

    };
    switch (property.info.validationRule) {
        case API.ValidationRuleEnum.None:
            break;
        case API.ValidationRuleEnum.Hasvalue:
            item.isRequired = true;
            item.validationRules.push({ type: 'required' });
            break;
    }

    options.items.push(item);
}
function propertyBool(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        isRequired: true,
        //helpText: 'Help Text',
        itemType: 'simple',
        editorType: 'dxLookup',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxLookup.Properties>({
            valueExpr: 'value',
            displayExpr: 'display',
            dataSource: [
                {
                    value: 'true',
                    display: 'True'
                },
                {
                    value: 'false',
                    display: 'False'
                }
            ]


        }),
        validationRules: [
            {
                type: 'required'
            }
        ]
    };
    options.items.push(item);

}

function propertyOptionalBool(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        isRequired: false,
        //helpText: '(Help Text)',
        itemType: 'simple',
        editorType: 'dxLookup',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxLookup.Properties>({
            showClearButton: true,
            valueExpr: 'value',
            displayExpr: 'display',
            dataSource: {
                store: [
/*                    {
                        value: undefined,
                        display: ''
                    },
*/                    {
                        value: 'true',
                        display: 'True'
                    },
                    {
                        value: 'false',
                        display: 'False'
                    },
                ]
            },
        })
    };
    options.items.push(item);
}

function propertyOptionalEnum(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        isRequired: false,
        //helpText: '(Help Text)',
        itemType: 'simple',
        editorType: 'dxLookup',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxLookup.Properties>({
            showClearButton: true,
            valueExpr: 'id',
            displayExpr: 'id',
            dataSource: property.info.enumType.values
        })
    };
    options.items.push(item);

}

function propertyEnum(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        isRequired: true,
        //helpText: '(Help Text)',
        itemType: 'simple',
        editorType: 'dxLookup',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxLookup.Properties>({
            showClearButton: false,
            valueExpr: 'id',
            displayExpr: 'id',
            dataSource: property.info.enumType.values
        })
    };
    options.items.push(item);

}

function propertyInt(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        isRequired: true,
        //helpText: '(Help Text)',
        itemType: 'simple',
        editorType: 'dxNumberBox',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
            showSpinButtons: true
        }),
        validationRules: [
            {
                type: 'custom',
                message: i18n.t(['itemdefinition.kosovo.handson.edit.formbuilder.PLEASE_ENTER_ONLY_WHOLE_NUMBERS']),
                validationCallback: e => {
                    const val = e.value;
                    if (!val) {
                        return true;
                    }
                    if (typeof e.value === 'string') {
                        return parseFloat(e.value) % 1 === 0;
                    }
                    if (typeof e.value === 'number') {
                        return e.value % 1 === 0;
                    }
                    return false;
                }

            }]
    };
    switch (property.info.validationRule) {
        case API.ValidationRuleEnum.None:
            break;
        case API.ValidationRuleEnum.Hasvalue:
            item.isRequired = true;
            item.validationRules.push({
                type: 'required'
            });
            break;
    }
    options.items.push(item);

}


function propertyOptionalInt(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        itemType: 'simple',
        editorType: 'dxNumberBox',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
            showSpinButtons: true
        }),
        validationRules: [{
            type: 'custom',
            message: i18n.t(['itemdefinition.kosovo.handson.edit.formbuilder.PLEASE_ENTER_ONLY_WHOLE_NUMBERS']),

            validationCallback: e => {
                if (!e.value) {
                    return true;
                }
                if (typeof e.value === 'string') {
                    return parseFloat(e.value) % 1 === 0;
                }
                if (typeof e.value === 'number') {
                    return e.value % 1 === 0;
                }
                return false;
            }

        }]
    };
    options.items.push(item);

}
function propertyInvalid(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        itemType: 'simple',
        editorType: 'dxTextBox',
        label: getLabel(property),
        dataField: property.propertyId,
        validationRules: [{
            type: 'custom',
            message: i18n.t(['itemdefinition.kosovo.handson.edit.formbuilder.THE_PROPERTY_DOES_NOT_EXIST']),

            validationCallback: e => {
                if (!e.value) {
                    return true;
                }
                return false;
            }

        }]
    };
    options.items.push(item);

}
function propertyString(options: DevExpress.ui.dxForm.Properties, property: PROPERTY) {
    const item: DevExpress.ui.dxForm.SimpleItem = {
        isRequired: false,
        //helpText: '(Help Text)',
        itemType: 'simple',
        editorType: 'dxTextBox',
        label: getLabel(property),
        dataField: property.propertyId,
        editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({

        }),
        validationRules: []
    };
    switch (property.info.validationRule) {
        case API.ValidationRuleEnum.None:
            break;
        case API.ValidationRuleEnum.Hasvalue:
            item.isRequired = true;
            item.validationRules.push({
                type: 'required'
            });
            break;
    }
    dir({
        for: property,
        textbox: item,
    });
    options.items.push(item);
}


export function buildPropertyForm(options: DevExpress.ui.dxForm.Properties, properties: PROPERTY[], onSave: () => void, onCancel: () => void, allFiles: ALL_FILE[]) {
    const initial: any = {};
    const sortedProps = properties.slice().sort((a, b) => stringCompare(a.propertyId, b.propertyId));
    for (const property of sortedProps) {
        options.formData[property.propertyId] = property.value;
        initial[property.propertyId] = property.value;
        if (!property.info) {
            propertyInvalid(options, property);
            continue;
        }
        switch (property.info.type) {
            case API.EnrichmentParamTypeEnum.OptionalInt:
                propertyOptionalInt(options, property);
                break;
            case API.EnrichmentParamTypeEnum.Int:
                propertyInt(options, property);
                break;
            case API.EnrichmentParamTypeEnum.Bool:
                propertyBool(options, property);
                break;
            case API.EnrichmentParamTypeEnum.String:
                propertyString(options, property);
                break;
            case API.EnrichmentParamTypeEnum.OptionalBool:
                propertyOptionalBool(options, property);
                break;
            case API.EnrichmentParamTypeEnum.Directory:
                propertyDirectory(options, property, allFiles);
                break;
            case API.EnrichmentParamTypeEnum.File:
                propertyFile(options, property, allFiles);
                break;
            case API.EnrichmentParamTypeEnum.Enum:
                propertyEnum(options, property);
                break;
            case API.EnrichmentParamTypeEnum.OptionalEnum:
                propertyOptionalEnum(options, property);
                break;
            default:
                options.items.push({
                    label: getLabel(property),
                    itemType: 'simple',
                    editorType: 'dxTextBox',
                    dataField: property.propertyId
                });
                break;
        }
    }
    dir(options.formData);
    let form: DevExpress.ui.dxForm;

    options.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
        itemType: 'simple',
        label: {
            text: ' ',
            showColon: false
        },
        template: (data, itemElement) => {
            const okBtn: any = $('<div/>').css('margin-right', '10px');
            new dxButton(okBtn, {
                text: i18n.t(['itemdefinition.kosovo.handson.edit.formbuilder.SAVE']),

                onClick: e => {
                    const validationResult = form.validate();
                    if (typeof validationResult === 'undefined') {
                        onSave();
                    } else if (validationResult.isValid) {
                        onSave();
                    }
                }
            });
            okBtn.appendTo(itemElement);

            const cancelBtn: any = $('<div/>');
            new dxButton(cancelBtn, {
                text: i18n.t(['itemdefinition.kosovo.handson.edit.formbuilder.CANCEL']),
                onClick: e => {
                    form.option('formData', { ...initial });
                    onCancel();
                }
            });
            cancelBtn.appendTo(itemElement);
        }
    }));

    options.onInitialized = e => {
        form = e.component;
    };
    options.onDisposing = () => {
        form = undefined;
    };
}
import $ from 'jquery';
import * as ko from 'knockout';
import { Terminal } from 'xterm';
import { AttachAddon } from 'xterm-addon-attach';
import { FitAddon } from 'xterm-addon-fit';
import { AbstractWidget } from '../../AbstractWidget';
import { log } from '../../debug';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'widgets-terminal';
const WIDGET_PARENT_NAME = 'widgets';

export interface IParams {
    socket: ko.Computed<string> | ko.Observable<string>;
    socketClosed?: ko.Computed<boolean> | ko.Observable<boolean>;
    clearSocketOnClose?: boolean;
    onSocketClosed?: () => void;
}

export class ViewModel extends AbstractWidget {
    private terminal: Terminal;
    private socket: WebSocket;
    private attachAddOn: AttachAddon;
    private fitAddOn: FitAddon;

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.terminal = new Terminal();
        this.fitAddOn = new FitAddon();
        this.terminal.loadAddon(this.fitAddOn);
    }
    async initialize() {
        await super.initialize();
        const elem = <HTMLElement>this.componentInfo.element;
        const markerElem = $(elem).find('[data-marker=\'terminal\']');
        this.terminal.open(markerElem.get(0));
        this.fitAddOn.fit();
        this.terminal.writeln(`connecting to ${this.params.socket()}`);
        this.socket = new WebSocket(this.params.socket());
        this.socket.onclose = (e) => {
            log('socket was closed');
            if (this.params.clearSocketOnClose) {
                this.params.socket(undefined);
            }
            if (this.params.socketClosed) {
                this.params.socketClosed(true);
            }
            if (this.params.onSocketClosed) {
                this.params.onSocketClosed();
            }
        };
        this.attachAddOn = new AttachAddon(this.socket, { bidirectional: false });

        this.terminal.loadAddon(this.attachAddOn);
    }
    dispose() {
        if (this.socket) {
            try {
                this.socket.close();
            } catch (e) {

            }
            this.socket = null;
        }
        if (this.attachAddOn) {
            this.attachAddOn.dispose();
        }
        this.terminal.dispose();
        super.dispose();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

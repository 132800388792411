import DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, datagrid, lookupDS, refreshDx, selectBoxDS } from '../../../dx_helper';
import { ScormTokenStatus, ScormTokenTrackability } from '../../../enums/datasource';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { KoMap } from '../../../komap';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import { superuser } from '../../ui-routes';
import { UIAction } from '../../uiAction';
import * as i18n from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_scormtokenoverview_data']>>;
type SCORMTOKEN = Q['scormTokens']['all'][0];

export class ViewModel extends DxWidget {
    public readonly actionEdit = new UIAction<SCORMTOKEN>(undefined, async (e, args) => {
        await ROUTES.routeManager.navigateToHREF(superuser.SchoolTypeEdit.href({
            audienceDocRefId: args.docReferenceId
        }));
    });

    private addTokenOptions = {
        examAlias: '',
        name: '',
        downloadCode: '',
        trackability: API.ScormTokenTrackability.Verbatim,
    }
    private form: DevExpress.ui.dxForm;
    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: this.addTokenOptions,
            items: [],
        };
        retVal.onInitialized = e => this.form = e.component;
        retVal.onDisposing = e => this.form = undefined;

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'examAlias',
            editorType: 'dxTextBox',
            validationRules: [{
                type: 'required',
            }],
            label: {
                text: i18n.t(['ui.superuser.scormtokenoverview.newscormtoken.EXAM_ALIAS']),
            },
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'downloadCode',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['ui.superuser.scormtokenoverview.newscormtoken.DOWNLOAD_CODE']),
            },
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'name',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['ui.superuser.scormtokenoverview.newscormtoken.NAME']),
            },
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'trackability',
            label: {
                text: i18n.t(['ui.superuser.scormtokenoverview.newscormtoken.TRACKABILITY']),
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS(ScormTokenTrackability()),
        }));


        return retVal;
    });

    public readonly addPopupVisible = ko.observable(false);
    public readonly addPopupOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxPopup.Properties = {
            width: 400,
            height: 'auto',
            title: i18n.t(['ui.superuser.scormtokenoverview.ADD_SCORM_TOKEN']),
            contentTemplate: () => {
                const x = $(`<div data-bind="dxForm:formOptions"></div>`);
                ko.applyBindings({
                    formOptions: this.formOptions(),
                }, x.get(0));
                return x;
            },
            toolbarItems: [],
            visible: <any>this.addPopupVisible,
        };
        retVal.toolbarItems.push({
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after',
            options: AS<DevExpress.ui.dxButton.Properties>({
                onClick: this.actionAdd.click,
                text: i18n.t(['ui.superuser.scormtokenoverview.newscormtoken.ADD']),
                icon: 'add',
            }),
        })
        return retVal;
    });

    public readonly actionAdd = new UIAction(undefined, async () => {
        const valid = this.form.validate();
        await valid.complete;
        if (!valid.isValid) {
            return;
        }
        const args: API.INewScormTokenArgs = {
            examAlias: this.addTokenOptions.examAlias,
            downloadCode: this.addTokenOptions.downloadCode,
            trackability: this.addTokenOptions.trackability,
            name: {
                xnone: this.addTokenOptions.name,
            }
        };
        const resp = await ServerConnection.api.ui_superuser_scormtokenoverview_new({ args });
        await this.OnRefresh();
        await this.grid().selectRows([resp.scormTokens.newScormToken.docReferenceId], false);
        this.addPopupVisible(false);
    });

    private readonly grid = ko.observable<DevExpress.ui.dxDataGrid>();

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.grid,
            config: {
                allowColumnResizing: true,
                columnResizingMode: 'nextColumn',
                columnChooser: {
                    enabled: true,
                    mode: 'select',
                },
                sorting: {
                    mode: 'single',
                },
                export: {
                    enabled: true,
                    allowExportSelectedData: true,
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'multiple',
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'docReferenceId',
                        load: async () => {
                            return this._scormTokens;
                        },
                        byKey: async key => {
                            return this._scormTokens.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.NAME']),
            dataField: 'name.value',
            sortOrder: 'asc',
            sortIndex: 0,
            cssClass: 'itsr3-select-all',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.EXAM_ALIAS']),
            dataField: 'examAlias',
            sortOrder: 'asc',
            cssClass: 'itsr3-select-all',
            sortIndex: 1,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.CREATED_AT']),
            dataField: 'auditCreated',
            sortOrder: 'asc',
            sortIndex: 2,
            dataType: 'datetime',
            cssClass: 'itsr3-select-all',
        });


        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.STATUS']),
            dataField: 'status',
            dataType: 'string',
            lookup: lookupDS(ScormTokenStatus(), {
                allowClearing: false,
            }),
            cssClass: 'itsr3-select-all',
        });

        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.TRACKABILITY']),
            dataField: 'trackability',
            dataType: 'string',
            lookup: lookupDS(ScormTokenTrackability(), {
                allowClearing: false,
            }),
            cssClass: 'itsr3-select-all',
        });

        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.DOWNLOAD_CODE']),
            dataField: 'downloadCode',
            dataType: 'string',
            cssClass: 'itsr3-select-all',
            visible: false,
        });

        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.PUBLIC_DOWNLOAD_URL']),
            dataField: 'publicDownloadUrl',
            dataType: 'string',
            cssClass: 'itsr3-select-all',
        });


        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.SCORM_TOKEN_ID']),
            dataField: 'scormTokenId',
            dataType: 'string',
            cssClass: 'itsr3-select-all',
        });


        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.DOCREFERENCE_ID']),
            dataField: 'docReferenceId',
            dataType: 'string',
            cssClass: 'itsr3-select-all',
            visible: false,
        });



        retVal.columns.push({
            caption: i18n.t(['ui.superuser.scormtokenoverview.DELETED']),
            dataField: 'isDeleted',
            dataType: 'boolean',
            visible: false,
        });

        retVal.filterValue = ['isDeleted', '=', false];
        return retVal;
    });

    private readonly _scormTokens: SCORMTOKEN[] = [];
    public readonly loaded = ko.observable(false);


    public async OnRefresh() {
        await super.OnRefresh();
        const result = await ServerConnection.api.ui_superuser_scormtokenoverview_data({});
        this._scormTokens.splice(0, this._scormTokens.length, ...result.scormTokens.all);

        await refreshDx(this);
    }

    constructor(readonly params: IParams) {
        super();
    }

    public readonly actionAddToken = new UIAction(undefined, async () => {
        this.addPopupVisible(true);
    });

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'plus',
                text: i18n.t(['ui.superuser.scormtokenoverview.NEW']),
                onClick: this.actionAddToken.click
            })
        }));
        return DONE;
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }

}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});


export const id = 'kosovo-singlechoiceimage';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'd55a5a08-9b61-49ad-a5bc-cfd64d9fdd6f',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

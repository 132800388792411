import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { datagrid } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './itemtypeoverview.html.g';
import { IParams, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_itemtypeoverview_data']>>;

export class ViewModel extends DxWidget {

    private _itemTypes: Q['itemtypes'] = [];

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                export: {
                    enabled: true,
                },
                paging: {
                    enabled: false,
                },
                editing: {
                    allowUpdating: true,
                    mode: 'row'
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'id',
                        load: async () => {
                            return this._itemTypes;
                        },
                        update: async (key, values) => {
                            const v = this._itemTypes.find(x => x.id === key);
                            Object.assign(v, values);
                        },
                        byKey: async key => {
                            return this._itemTypes.find(x => x.id === key);
                        }
                    })
                }
            }
        });
        retVal.onRowUpdating = e => {
            if (typeof e.newData.enabled === 'boolean') {
                if (e.newData.enabled) {
                    e.cancel = (async () => {
                        await ServerConnection.api.ui_superuser_itemtypeoverview_enable({
                            itemtype: e.key
                        });
                    })();
                } else {
                    e.cancel = (async () => {
                        await ServerConnection.api.ui_superuser_itemtypeoverview_disable({
                            itemtype: e.key
                        });
                    })();
                }
            }
        };

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.itemtypeoverview.ITEM_TYPE']),
            dataField: 'title.value',
            sortOrder: 'asc',
            sortIndex: 0,
            width: 180,
            dataType: 'string',
            allowEditing: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.itemtypeoverview.DESCRIPTION']),
            dataType: 'string',
            dataField: 'description.value',
            allowEditing: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.itemtypeoverview.AVAILABLE']),
            dataType: 'boolean',
            dataField: 'enabled',
            width: 80,
            allowEditing: true,
        });
        retVal.columns.push({
            caption: i18n.t(['ui.superuser.itemtypeoverview.TRANSLATABLE']),
            dataType: 'boolean',
            dataField: 'supportsTranslations',
            width: 80,
            allowEditing: false,
        });

        return retVal;
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const result = await ServerConnection.api.ui_superuser_itemtypeoverview_data({
        });
        this._itemTypes.splice(0, this._itemTypes.length, ...result.itemtypes);
    }

    constructor(readonly params: IParams) {
        super();
    }
    public readonly loaded = ko.observable(false);

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './route.query.graphql.g';

export const WIDGET_NAME = 'ui-superuser-useredit';

export interface IParamsInner {
    userDocRefId: string;
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class UserEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;

    public static async CREATE(widgetParams: IParamsInner) {
        const q = await ServerConnection.api.ui_superuser_useredit_route({
            userDocRef: widgetParams.userDocRefId
        });
        const route = new UserEditRoute(widgetParams, q.getUserById.userId);
        return route;
    }

    public readonly title = ko.pureComputed(() => this._title);

    private constructor(readonly widgetParams: IParamsInner, private readonly _title: string) {
    }

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.superuser.useredit.HELP']),
                action: this.showHelp
            }];
    });
    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.useredit.USERNAME_AND_SHORTCUT_ARE_MANDATORY_THE_USER_NEEDS_A_USERNAME_AND_A_PASSWORD_TO_LOG_INTO_THE_PROGRAM_BEFORE_YOU_CAN_CHOOSE_A_SUBJECT_FOR_A_USER_YOU_FIRST_HAVE_TO_CREATE_IT_HOWEVER_YOU_CAN_ACTIVATE_THE_ROLE_FOR_A_USER_AND_SELECT_THE_SUBJECT_AFTERWARDS']);

        toastService.info(msg);
    });
}

export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/superuser/user/:id',
    toParams: map => ({
        userDocRefId: map.get('id'),
    }),
    fromParams: (p, map) => {
        map.set('id', p.userDocRefId);
    },
    create: async p => {
        const route = await UserEditRoute.CREATE(p);
        return route;
    },
});

import { LangObj } from '../i18n/data';
import * as API from '../its-itembank-api.g';
import * as i18n from './../i18n/i18n';

export type ExaminationOrderTypeString = 'matura' | 'pilot' | 'sample';
export type StatusString = 'open' | 'populating' | 'ready' | 'published';
export type SubjectCoordinatorStatusString = 'open' | 'finished';
export type BrowserSessionTimeOutActionString = 'suspend' | 'close';
export type ItemHostString = 'hierarchical' | 'singleitem';
export type EMailOptionString = 'required' | 'optional' | 'no';
export type PDFOptionString = 'no' | 'yes';
export type UserTextEntryString = 'no' | 'yes';
export type TimeDisplayString = 'default' | 'remaining' | 'passed' | 'no';
export type DummyItemModeString = 'guess' | 'guessright' | 'guesswrong';

export type ExamTypeString = 'OTHER' | 'MATURA' | 'DEMO';

export type WorkflowStatusEnumString = 'open' | 'inprogress' | 'done' | 'reopened';

export type ModesString = 'New' | 'AvailableForParticipation' | 'Participating' | 'Participated' |
    'AvailableForGrading' | 'Grading' | 'Graded' | 'AvailableForExport' | 'Exported' | 'Archived';

export type BrowserSessionTypesString = 'anonymous' |
    'participating' |
    'reviewing' |
    'bulkgrading' |
    'manualgrading' |
    'admin' |
    'authoring' |
    'subjectcoordinating' |
    'superuser' |
    'system' |
    'donkey';

export type AuthoringStatusString = 'open' | 'inprogress' | 'done' | 'reopened' | 'locked';
export type TranslationStatusString = 'open' | 'inprogress' | 'done' | 'reopened';

export const AuthoringStatus: LangObj<AuthoringStatusString> = {
    'open': i18n.KEY(['enums.translations.AUTHORINGSTATUS_OPEN']),
    'inprogress': i18n.KEY(['enums.translations.AUTHORINGSTATUS_INPROGRESS']),
    'done': i18n.KEY(['enums.translations.AUTHORINGSTATUS_DONE']),
    'reopened': i18n.KEY(['enums.translations.AUTHORINGSTATUS_REOPENED']),
    'locked': i18n.KEY(['enums.translations.LOCKED']),
};


export const Organisation_NoteType: LangObj<API.Organisation_NoteType> = {
    'email': i18n.KEY(['enums.translations.EMAIL']),
    'telephone': i18n.KEY(['enums.translations.TELEPHONE']),
    'fax': i18n.KEY(['enums.translations.FAX']),
    'voicemail': i18n.KEY(['enums.translations.VOICEMAIL']),
    'letter': i18n.KEY(['enums.translations.LETTER']),
    'note': i18n.KEY(['enums.translations.NOTE'])
};

export const TranslationStatus: LangObj<TranslationStatusString> = {
    'open': i18n.KEY(['enums.translations.TRANSLATIONSTATUS_OPEN']),
    'inprogress': i18n.KEY(['enums.translations.TRANSLATIONSTATUS_INPROGRESS']),
    'done': i18n.KEY(['enums.translations.TRANSLATIONSTATUS_DONE']),
    'reopened': i18n.KEY(['enums.translations.TRANSLATIONSTATUS_REOPENED']),
};

export const LevelOfSecrecyString: LangObj<API.LevelOfSecrecy> = {
    'topsecret': i18n.KEY(['enums.translations.LEVELOFSECRECY_TOPSECRET']),
    'secret': i18n.KEY(['enums.translations.LEVELOFSECRECY_SECRET']),
    'restricted': i18n.KEY(['enums.translations.LEVELOFSECRECY_RESTRICTED']),
    'public': i18n.KEY(['enums.translations.LEVELOFSECRECY_PUBLIC'])
};


export const TESTSESSION_MODE: LangObj<ModesString> = {
    'New': i18n.KEY(['enums.translations.TESTSESSIONMODE_NEW']),
    'AvailableForParticipation': i18n.KEY(['enums.translations.TESTSESSIONMODE_AVAILABLEFORPARTICIPATION']),
    'Participating': i18n.KEY(['enums.translations.TESTSESSIONMODE_PARTICIPATING']),
    'Participated': i18n.KEY(['enums.translations.TESTSESSIONMODE_PARTICIPATED']),
    'AvailableForGrading': i18n.KEY(['enums.translations.TESTSESSIONMODE_AVAILABLEFORGRADING']),
    'Grading': i18n.KEY(['enums.translations.TESTSESSIONMODE_GRADING']),
    'Graded': i18n.KEY(['enums.translations.TESTSESSIONMODE_GRADED']),
    'AvailableForExport': i18n.KEY(['enums.translations.TESTSESSIONMODE_AVAILABLEFOREXPORT']),
    'Exported': i18n.KEY(['enums.translations.TESTSESSIONMODE_EXPORTED']),
    'Archived': i18n.KEY(['enums.translations.TESTSESSIONMODE_ARCHIVED'])
};

export const ResetOnNavigate: LangObj<API.ResetOnNavigateEnum> = {
    'always': i18n.KEY(['enums.translations.RESETONNAVIGATE_ALWAYS']),
    'never': i18n.KEY(['enums.translations.RESETONNAVIGATE_NEVER']),
    'default': i18n.KEY(['enums.translations.RESETONNAVIGATE_DEFAULT'])
};


export const ItemHost: LangObj<API.ItemHostEnum> = {
    'hierarchical': i18n.KEY(['enums.translations.ITEMHOST_HIERARCHICAL']),
    'singleitem': i18n.KEY(['enums.translations.ITEMHOST_SINGLEITEM'])
};

export const NavigationStyle: LangObj<API.NavigationStyleEnum> = {
    'SubmitPostpone': i18n.KEY(['enums.translations.NAVIGATIONSTYLE_SUBMITPOSTPONE']),
    'SubmitPostponeAutoFocus': i18n.KEY(['enums.translations.NAVIGATIONSTYLE_SUBMITPOSTPONEAUTOFOCUS']),
    'PreviousNext': i18n.KEY(['enums.translations.NAVIGATIONSTYLE_PREVIOUSNEXT'])
};

export const ScormTokenStatus: LangObj<API.ScormTokenStatus> = {
    'active': i18n.KEY(['enums.translations.ACTIVE']),
    'closing': i18n.KEY(['enums.translations.CLOSING']),
    'inactive': i18n.KEY(['enums.translations.INACTIVE']),
};

export const ScormTokenTrackability: LangObj<API.ScormTokenTrackability> = {
    'anonymous': i18n.KEY(['enums.translations.ANONYMOUS']),
    'verbatim': i18n.KEY(['enums.translations.VERBATIM']),
};

export const ItemOrderStrategy: LangObj<API.ItemOrderStrategy> = {
    'default': i18n.KEY(['enums.translations.ITEMORDERSTRATEGY_DEFAULT']),
    'randomized': i18n.KEY(['enums.translations.ITEMORDERSTRATEGY_RANDOMIZED']),
    'fixed': i18n.KEY(['enums.translations.ITEMORDERSTRATEGY_FIXED']),
    'randomizedHeavyFirst': i18n.KEY(['enums.translations.ITEMORDERSTRATEGY_RANDOMIZEDHEAVYFIRST']),
    'randomizedLightFirst': i18n.KEY(['enums.translations.ITEMORDERSTRATEGY_RANDOMIZEDLIGHTFIRST']),
};
export const ScoreRoundingMode: LangObj<API.ScoreRoundingModeEnum> = {
    'Default': i18n.KEY(['enums.translations.SCORE_ROUNING_MODE_DEFAULT']),
    'IntegerHalfUp': i18n.KEY(['enums.translations.SCORE_ROUNING_MODE_INTEGER_HALF_UP']),
    'IntegerUp': i18n.KEY(['enums.translations.SCORE_ROUNING_MODE_INTEGER_UP']),
    'NoRounding': i18n.KEY(['enums.translations.SCORE_ROUNING_MODE_INTEGER_NO_ROUNDING']),
}
export const ExaminationOrderDefineScore: LangObj<API.ExaminationOrderDefineScore> = {
    'any': i18n.KEY(['enums.translations.EXAMINATIONORDERDEFINESCORE_ANY']),
    'superuser': i18n.KEY(['enums.translations.EXAMINATIONORDERDEFINESCORE_SUPERUSER']),
    'subjectcoordinator': i18n.KEY(['enums.translations.EXAMINATIONORDERDEFINESCORE_SUBJECTCOORDINATOR']),
};

export const InvisibleHotSpotEdit_Scoring: LangObj<API.InvisibleHotSpotEdit_Scoring> = {
    'AllOrNothing': i18n.KEY(['enums.translations.INVISIBLEHOTSPOTEDIT_SCORING_ALLORNOTHING']),
    'PerAnswer': i18n.KEY(['enums.translations.INVISIBLEHOTSPOTEDIT_SCORING_PERANSWER']),
};
export const TimeDisplay: LangObj<API.DisplayTime> = {
    'default': i18n.KEY(['enums.translations.TIMEDISPLAY_DEFAULT']),
    'passed': i18n.KEY(['enums.translations.TIMEDISPLAY_PASSED']),
    'remaining': i18n.KEY(['enums.translations.TIMEDISPLAY_REMAINING']),
    'no': i18n.KEY(['enums.translations.TIMEDISPLAY_NO'])
};

export const ProgressDisplay: LangObj<API.DisplayProgress> = {
    'default': i18n.KEY(['enums.translations.PROGRESSDISPLAY_DEFAULT']),
    'passed': i18n.KEY(['enums.translations.PROGRESSDISPLAY_PASSED']),
    'remaining': i18n.KEY(['enums.translations.PROGRESSDISPLAY_REMAINING']),
    'no': i18n.KEY(['enums.translations.PROGRESSDISPLAY_NO'])
};

export const ItemEditVisibility: LangObj<API.ItemEditVisibility> = {
    'allAuthors': i18n.KEY(['enums.translations.ITEMEDITVISIBILITY_ALLAUTHORS']),
    'assignedAuthors': i18n.KEY(['enums.translations.ITEMEDITVISIBILITY_ASSIGNEDAUTHORS']),
};
export const DisplayItemId: LangObj<API.DisplayItemIdEnum> = {
    'default': i18n.KEY(['enums.translations.DEFAULT']),
    'yes': i18n.KEY(['enums.translations.YES']),
    'no': i18n.KEY(['enums.translations.NO']),
};

export const DisplayObjectiveId: LangObj<API.DisplayObjectiveIdEnum> = {
    'default': i18n.KEY(['enums.translations.DEFAULT']),
    'yes': i18n.KEY(['enums.translations.YES']),
    'no': i18n.KEY(['enums.translations.NO']),
};

export const ButtonVisibility: LangObj<API.ButtonVisibility> = {
    'default': i18n.KEY(['enums.translations.BUTTONVISIBILITY_DEFAULT']),
    'show': i18n.KEY(['enums.translations.BUTTONVISIBILITY_SHOW']),
    'hide': i18n.KEY(['enums.translations.BUTTONVISIBILITY_HIDE']),
};

export const ShowImmediateResult: LangObj<API.ShowImmediateResult> = {
    'never': i18n.KEY(['enums.translations.SHOWIMMEDIATERESULT_NEVER']),
    'scored': i18n.KEY(['enums.translations.SHOWIMMEDIATERESULT_SCORED']),
};

export const ShowItemMaxScore: LangObj<API.ShowItemMaxScore> = {
    'default': i18n.KEY(['enums.translations.SHOWITEMMAXSCORE_DEFAULT']),
    'no': i18n.KEY(['enums.translations.SHOWITEMMAXSCORE_NO']),
    'yes': i18n.KEY(['enums.translations.SHOWITEMMAXSCORE_YES']),
};
export const ResultPagePDF: LangObj<PDFOptionString> = {
    'no': i18n.KEY(['enums.translations.RESULTPAGEPDF_NO']),
    'yes': i18n.KEY(['enums.translations.RESULTPAGEPDF_YES']),
};
export const UserTextEntry: LangObj<UserTextEntryString> = {
    'no': i18n.KEY(['enums.translations.USERTEXTENTRY_NO']),
    'yes': i18n.KEY(['enums.translations.USERTEXTENTRY_YES']),
};

export const ResultPageIncludeItems: LangObj<API.PdfReportSettingsIncludeItems> = {
    'no': i18n.KEY(['enums.translations.RESULTPAGEINCLUDEITEMS_NO']),
    'yes': i18n.KEY(['enums.translations.RESULTPAGEINCLUDEITEMS_EXAMORDER']),
    'whatCandidateSaw': i18n.KEY(['enums.translations.RESULTPAGEINCLUDEITEMS_CANDIDATE']),
};

export const ResultPageEMail: LangObj<EMailOptionString> = {
    'no': i18n.KEY(['enums.translations.RESULTPAGEEMAIL']),
    'required': i18n.KEY(['enums.translations.RESULTPAGEEMAIL_REQUIRED']),
    'optional': i18n.KEY(['enums.translations.RESULTPAGEEMAIL_OPTIONAL']),
};

export const ContinuationModeString: LangObj<API.ContinuationModeEnum> = {
    'admin': i18n.KEY(['enums.translations.CONTINUATIONMODE_ADMIN']),
    'system': i18n.KEY(['enums.translations.CONTINUATIONMODE_SYSTEM']),
    'takeover': i18n.KEY(['enums.translations.CONTINUATIONMODE_TAKEOVER']),
};

export const ContinueWithString: LangObj<API.ContinueWithEnum> = {
    'item': i18n.KEY(['enums.translations.CONTINUEWITH_ITEM']),
    'overview': i18n.KEY(['enums.translations.CONTINUEWITH_OVERVIEW']),
};

export const StatusString: LangObj<StatusString> = {
    'open': i18n.KEY(['enums.translations.EXAMORDERSTATUS_OPEN']),
    'populating': i18n.KEY(['enums.translations.EXAMORDERSTATUS_POPULATING']),
    'ready': i18n.KEY(['enums.translations.EXAMORDERSTATUS_READY']),
    'published': i18n.KEY(['enums.translations.EXAMORDERSTATUS_PUBLISHED']),
};

export const SubjectCoordinatorStatusString: LangObj<SubjectCoordinatorStatusString> = {
    'open': i18n.KEY(['enums.translations.SUBJECTCOORDINATORSTATUS_OPEN']),
    'finished': i18n.KEY(['enums.translations.SUBJECTCOORDINATORSTATUS_FINISHED']),
};


export const ExaminationTypeString: LangObj<API.ExaminationTypeEnum> = {
    'demo': i18n.KEY(['enums.translations.EXAMINATIONTYPE_DEMO']),
    'certification': i18n.KEY(['enums.translations.EXAMINATIONTYPE_CERTIFICATION']),
    'assessment': i18n.KEY(['enums.translations.EXAMINATIONTYPE_ASSESSMENT']),
    'selfassessment': i18n.KEY(['enums.translations.EXAMINATIONTYPE_SELFASSESSMENT']),
    'qa': i18n.KEY(['enums.translations.EXAMINATIONTYPE_QA']),
    'showcase': i18n.KEY(['enums.translations.EXAMINATIONTYPE_SHOWCASE']),
    'adhoc': i18n.KEY(['enums.translations.EXAMINATIONTYPE_ADHOC'])
};

export const UserRoleString: LangObj<API.Privilege> = {
    'author_subjects': i18n.KEY(['enums.translations.USERROLE_AUTHOR']),
    'admin': i18n.KEY(['enums.translations.USERROLE_ADMIN']),
    'subjectcoordinator_subjects': i18n.KEY(['enums.translations.USERROLE_SUBJECTCOORDINATOR']),
    'superuser': i18n.KEY(['enums.translations.USERROLE_SUPERUSER']),
    'evaluator_subjects': i18n.KEY(['enums.translations.USERROLE_EVALUATOR']),
    'translator_subjects': i18n.KEY(['enums.translations.USERROLE_TRANSLATOR_SUBJECTS']),
    'translator_localizations': i18n.KEY(['enums.translations.USERROLE_TRANSLATOR_LOCALIZATIONS'])
};

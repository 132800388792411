import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoSingleChoiceMatrix_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type ROW = DATA['rows'][0];
type COLUMN = DATA['columns'][0];
type CELL = ROW['cells'][0];

export class SingleChoiceMatrixData_Row {
    public readonly id: string;
    constructor(readonly model: SingleChoiceMatrixData, readonly data: ROW) {
        this.id = data.id;
        this.text = translate(data.value, {});
        this.correctAnswer = data.correctAnswer;
        this.cells = data.cells.map(x => new SingleChoiceMatrixData_Cell(model, this, x));
        this.selectedAnswer(data.selectedAnswer);

    }
    public readonly correctAnswer: string;
    public readonly selectedAnswer = ko.observable<string>();
    public readonly text: string;

    public readonly cells: SingleChoiceMatrixData_Cell[];

}
export class SingleChoiceMatrixData_Column {
    constructor(readonly model: SingleChoiceMatrixData, readonly data: COLUMN) {
        this.value = translate(data.value, {});
    }
    public readonly value: string;
}
export class SingleChoiceMatrixData_Cell {
    public readonly id: string;
    public readonly isCorrect: boolean;
    public readonly isSelected = ko.pureComputed(() => this.row.selectedAnswer() === this.id);

    constructor(readonly model: SingleChoiceMatrixData, readonly row: SingleChoiceMatrixData_Row, readonly data: CELL) {
        this.id = data.id;
        this.isCorrect = data.id === row.correctAnswer;
    }

}
export class SingleChoiceMatrixData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();

        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.rows = data.rows.map(x => new SingleChoiceMatrixData_Row(this, x));
        this.columns = data.columns.map(x => new SingleChoiceMatrixData_Column(this, x));
        this.cells = [];
        for (const row of this.rows) {
            this.cells.push(...row.cells);
        }
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'KosovoSingleChoiceMatrix_QueryItemSessionData') {
            throw new Error();
        }
    }
    public readonly questionHTML: string;
    public readonly rows: SingleChoiceMatrixData_Row[];
    public readonly columns: SingleChoiceMatrixData_Column[];
    public readonly cells: SingleChoiceMatrixData_Cell[];
    public readonly headerText: string;

    public async setAnswer({ cellId }: { cellId: string }) {
        const cell = this.cells.find(x => x.id === cellId);
        cell.row.selectedAnswer(cell.id);
    }

    public IsInteractionStarted() {
        return this.rows.some(x => !!x.selectedAnswer());
    }
    public IsInteractionComplete() {
        return this.rows.every(x => !!x.selectedAnswer());
    }
    public async reset() {
        for (const row of this.rows) {
            row.selectedAnswer(undefined);
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SingleChoiceMatrix: {
                rows: this.rows.map(x => ({
                    rowId: x.id,
                    selected: x.selectedAnswer(),
                })),
            }
        };
        return retVal;
    }

}

export const id = 'kosovo-singlechoicetext';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';

itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '88fffee1-5af5-4ad0-ab36-d6e5ad027d71',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});
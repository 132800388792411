import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { error, log } from '../../../debug';
import { sleep } from '../../../helper';
import { IItemPresenterWidgetParams } from '../../../model/interfaces';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';
import { ConnectionStatus } from '../../RestAPIBase';
import type DevExpress from 'devextreme';

const WIDGET_NAME = 'ui-widgets-connectionlost';
let IDX = 0;

export type IParams = IItemPresenterWidgetParams;

const RETRY_FAST_LIMIT = 20;
const RETRY_SLOW_LIMIT = 100;
const RETRY_FAST_DELAY = 2000;
const RETRY_SLOW_DELAY = 5000;

export class ViewModel extends AbstractWidget {
    private readonly id = `${WIDGET_NAME}#${IDX++}`;

    public readonly loginButtonOptions = ko.pureComputed<DevExpress.ui.dxButton.Properties>(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            text: i18next.t(['ui.widgets.connectionlost.relogin_caption']),
            onClick: ()=>{                                
                location.reload();
            }
        };
        return retVal;
    });

    private readonly retry = ko.observable(0);
    public readonly isFast = ko.pureComputed(() => this.retry() < RETRY_FAST_LIMIT);
    public readonly displayRelogin = ko.observable(false);
    private async _retryThread() {
        while (!this.disposed) {
            const status = ServerConnection.connectionStatus();
            if (status === ConnectionStatus.DisconnectedLoginRequired) {
                this.displayRelogin(true);
                return;
            }
            if (status === ConnectionStatus.Connected) {
                return;
            }
            try {
                this.retry(this.retry() + 1);
                const ok = await ServerConnection.api.ui_widgets_connectionlost_retry();
                if (ok.config.serverVersion) {
                    return;
                }
            } catch {

            }
            if (this.retry() > RETRY_SLOW_LIMIT) {
                error(`Unable to recover after ${RETRY_SLOW_LIMIT}. Stop Auto-Reestablish connection`);

                return;
            }
            await sleep(this.isFast() ? RETRY_FAST_DELAY : RETRY_SLOW_DELAY);

        }
    }

    public readonly message = ko.pureComputed(() => {
        const retry = this.retry();
        if (this.displayRelogin()) {
            return i18next.t(['ui.widgets.connectionlost.relogin_required']);
        }
        if (this.isFast()) {
            return i18next.t(['ui.widgets.connectionlost.auto_retry'], { retry });
        }
        if (typeof window.itsr3 !== 'undefined') {
            return i18next.t(['ui.widgets.connectionlost.restart_itsclient'], { retry });
        } else {
            return i18next.t(['ui.widgets.connectionlost.restart_browser'], { retry });
        }
    });
    private disposed = false;
    public dispose() {
        log(`${this.id}: disposing`);
        this.disposed = true;
        super.dispose();
    }

    constructor(readonly params: IItemPresenterWidgetParams) {
        super();
    }


    public async initialize() {
        await super.initialize();
        void this._retryThread();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

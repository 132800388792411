import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'HandsOn_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

export class HandsOnData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly _data: DATA) {
        super();
        this.headerText = translate(_data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(_data.question, {}),
            attachments: this.meta.attachments,
        });


        this.fromJS(_data);
    }
    public readonly investigatorResults = new Map<string, string>();
    public fromJS(data: DATA) {
        this.isDone(data && data.isDone || false);
        this.investigatorResults.clear();
        for (const invResult of data.investigatorResults) {
            this.investigatorResults.set(invResult.investigatorId, invResult.serializedValue);
        }
    }
    public readonly isDone = ko.observable(false);
    public readonly headerText: string;
    public readonly questionHTML: string;

    public async setDone(isDone: boolean) {
        this.isDone(isDone);
    }

    public IsInteractionStarted() {
        return this.isDone();
    }
    public IsInteractionComplete() {
        return this.isDone();
    }

    public async reset() {
        this.isDone(false);
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            HandsOn: {
                isDone: this.isDone(),
                investigatorResults: Array.from(this.investigatorResults.entries()).map(x => ({
                    investigatorId: x[0],
                    serializedValue: x[1],
                })),
            }
        };
        return retVal;
    }

}
import * as i18n from './../../../i18n/i18n';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { UIAction } from '../../uiAction';
import type DevExpress from 'devextreme/bundles/dx.all';
import { AS } from '../../../dx_helper';

export interface D {
    laxExternalId: string;
    examAlias: string;
    sessionStartTimeMin: string;
    sessionStartTimeMax: string;
    limit: number;
    skip: number;
}
export type U = Partial<D>;

export function xnone(data: string) {
    if (typeof data !== 'string') {
        return undefined;
    }
    return {
        'xnone': data
    };
}

//type Omit<T, K>=Pick<T, Exclude<keyof T, K>>;
export class MyFormBuilder extends FormBuilder<D, U> {
    constructor(readonly vm: {
        actionSearch: UIAction<undefined>
    }) {
        super();
    }
    protected onInit() {
        super.onInit();
        this.formOptions.colCount = 7;
        this.initSystem();
        this.initUpdate();
    }
    private initSystem() {
        const g = this.formOptions;
        this.addTextBox(g, {
            dataField: 'laxExternalId',
            label: {
                location: 'top',
                text: i18n.t(['ui.superuser.sessions.myformbuilder.EXTERNAL_ID']),
            }
        }, v => ({}));

        this.addTextBox(g, {
            dataField: 'examAlias',
            label: {
                location: 'top',
                text: i18n.t(['ui.superuser.sessions.myformbuilder.EXAM_ALIAS']),
            }
        }, v => ({}));

        this.addSimpleItem(g, {
            label: {
                location: 'top',
                text: i18n.t(['ui.superuser.sessions.myformbuilder.SESSION_START_AFTER']),
            },
            editorType: 'dxDateBox',
            dataField: 'sessionStartTimeMin',
            editorOptions: AS<DevExpress.ui.dxDateBox.Properties>({
                showClearButton: true,
                calendarOptions: {
                    showTodayButton: true,
                },
                showAnalogClock: false,
                type: 'datetime',
            })
        }, v => ({}));
        this.addSimpleItem(g, {
            label: {
                location: 'top',
                text: i18n.t(['ui.superuser.sessions.myformbuilder.SESSION_START_BEFORE']),
            },
            editorType: 'dxDateBox',
            dataField: 'sessionStartTimeMax',
            editorOptions: AS<DevExpress.ui.dxDateBox.Properties>({
                showClearButton: true,
                calendarOptions: {
                    showTodayButton: true,

                },
                type: 'datetime',
            })
        }, v => ({}));

        this.addNumberBox(g, {
            label: {
                location: 'top',
                text: i18n.t(['ui.superuser.sessions.myformbuilder.LIMIT']),
            },
            dataField: 'limit',
            editorOptions: {
                min: 0,
                max: 10000
            }
        }, v => ({}));

        this.addNumberBox(g, {
            label: {
                location: 'top',
                text: i18n.t(['ui.superuser.sessions.myformbuilder.SKIP']),
            },
            dataField: 'skip',
            editorOptions: {
                min: 0,
            }
        }, v => ({}));
    }
    private initUpdate() {
        const g = this.formOptions;
        this.addButton(g, {
            buttonOptions: {
                text: i18n.t(['ui.superuser.sessions.myformbuilder.SEARCH']),
                onClick: this.vm.actionSearch.click
            }
        });
    }

}

export function AddMissingAttachments(attachments: Array<{ name: string }>, used: string[]) {
    for (const u of used) {
        if (!u) {
            continue;
        }
        if (attachments.find(x => x.name === u)) {
            continue;
        }
        attachments.push({ name: u });
    }
}
import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import { superuser } from '../../ui-routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './schooltypeoverview.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_audienceedit_data']>>;
type AUDIENCE = Q['audiences']['all'][0];

export class ViewModel extends DxWidget {
    public readonly actionEdit = new UIAction<AUDIENCE>(undefined, async (e, args) => {
        await ROUTES.routeManager.navigateToHREF(superuser.SchoolTypeEdit.href({
            audienceDocRefId: args.docReferenceId
        }));
    });

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._audiences;
                        },
                        byKey: async key => {
                            return this._audiences.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.schooltypeoverview.NAME_OF_AUDIENCE']),
            dataField: 'title',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.schooltypeoverview.SHORTCUT']),
            dataField: 'audienceId',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.schooltypeoverview.SUBJECTS']),
            dataType: 'string',
            calculateDisplayValue: x => {
                const queryRow: AUDIENCE = x;
                return queryRow.subjects.map(x => x.subjectId).sort().join(', ');
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.schooltypeoverview.DEACTIVATED']),
            dataField: 'isDeleted',
            dataType: 'boolean',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.schooltypeoverview.ACTIONS']),

            width: '80',
            cellTemplate: (elem, info) => {
                const retVal = $('<a />');
                retVal.text(i18next.t(['ui.superuser.schooltypeoverview.EDIT']));
                retVal.css({ cursor: 'pointer' });
                retVal.on('click', () => {
                    const user: AUDIENCE = info.data;
                    void this.actionEdit.intent(user);

                });
                return retVal;
            }
        });
        retVal.filterValue = ['isDeleted', '=', false];
        return retVal;
    });

    private readonly _audiences: AUDIENCE[] = [];
    public readonly loaded = ko.observable(false);

    public async OnRefresh() {
        await super.OnRefresh();
        const result = await ServerConnection.api.ui_superuser_audienceedit_data({});
        this._audiences.splice(0, this._audiences.length, ...result.audiences.all);

        await refreshDx(this);
    }

    constructor(readonly params: IParams) {
        super();
    }

    public readonly actionNewSchoolType = new UIAction(undefined, async () => {
        const audience = await ServerConnection.api.ui_superuser_audienceoverview_new({});
        const audienceDocRefId = audience.audiences.newAudience.docReferenceId;
        await ROUTES.routeManager.navigateToHREF(superuser.SchoolTypeEdit.href(
            {
                audienceDocRefId,
            }));
    });

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'plus',
                text: i18next.t(['ui.superuser.schooltypeoverview.NEW']),
                onClick: this.actionNewSchoolType.click
            })
        }));
        return DONE;
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }

}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

// tslint:disable:object-literal-key-quotes
import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir } from '../../../debug';
import { AS, dxAddTab_Component, refreshDx } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import * as ADV from '../../../ui/widgets/advanceditempresenter/widget';
import { ServerConnection } from '../../RestAPI';
import { routeManager } from '../../routes';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './itemreview.html.g';
import * as META from './metadata/widget';
import { IParams, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_itemreview_init']>>;

type ITEM = Q['subjectById']['itemsForSubjectCoordinator'][0] & {
    selected?: boolean;
};

export class ViewModel extends DxWidget {
    public readonly boxOptions = {
        direction: 'row',
        width: '100%',
        height: '90%'
    };


    private readonly _items: ITEM[] = [];
    private readonly itemsStore = new CustomStore({
        loadMode: 'raw',
        key: 'docReferenceId',
        load: async () => {
            return this._items;
        },
        byKey: async key => {
            return this._items.find(x => x.docReferenceId === key);
        }

    });
    private itemsTreeView: DevExpress.ui.dxTreeView;
    public readonly itemsTreeViewOptions = ko.pureComputed(() => {
        const selected = this._items.length && this._items[0].docReferenceId;

        const retVal: DevExpress.ui.dxTreeView.Properties = {
            dataSource: {
                store: this.itemsStore
            },
            selectionMode: 'single',
            selectedItemKeys: [selected],
            //onItemClick: (e) => this.treeViewClick.click(e.node),
            onItemSelectionChanged: e => {
                const node = e.node;
                if (node) {
                    this.selectedItemDocRefId(e.node.key);
                }
            },
            dataStructure: 'plain',
            keyExpr: 'itemId',
            parentIdExpr: 'parentItemId',
            focusStateEnabled: true,
            displayExpr: 'itemId',
            selectByClick: true,
            height: '100%',
            onInitialized: e => {
                this.widgets.add(e.component);
                this.itemsTreeView = e.component;
            },
            onDisposing: e => {
                this.widgets.delete(e.component);
                this.itemsTreeView = undefined;
            }
        };
        return retVal;
    });

    public readonly tabs = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxTabPanel.Properties = {
            deferRendering: true,
            height: '100%',
        };
        retVal.onSelectionChanged = e => {
            if (!e.addedItems || !e.addedItems.length) {
                this.selectedLabel('');
            } else {
                const item: DevExpress.ui.dxTabPanelItemTemplate = e.addedItems[0];
                this.selectedLabel(item.title);
            }
        };
        retVal.onInitialized = e => {
            const tab = e.component.option('selectedItem');
            dir(tab);
        };

        dxAddTab_Component<ADV.IParams>({
            widget: this,
            selectedTabLabel: this.selectedLabel,
            tab: {
                title: this.inspectLabel(),
            },
            tabPanel: retVal,
            component: ADV.WIDGET_NAME,
            componentParams: ko.pureComputed(() => AS<ADV.IParams>({
                item: this.selectedItemDocRefId(),
                mode: 'INSPECT'
            })),
        });

        dxAddTab_Component<META.IParams>({
            widget: this,
            selectedTabLabel: this.selectedLabel,
            tab: {
                title: this.metadataLabel(),
            },
            tabPanel: retVal,
            component: META.WIDGET_NAME,
            componentParams: ko.pureComputed(() => AS<META.IParams>({
                item: this.selectedItemDocRefId(),
            })),
        });

        dxAddTab_Component<ADV.IParams>({
            widget: this,
            selectedTabLabel: this.selectedLabel,
            tab: {
                title: this.interactiveLabel(),
            },
            tabPanel: retVal,
            component: ADV.WIDGET_NAME,
            componentParams: ko.pureComputed(() => AS<ADV.IParams>({
                item: this.topItem(),
                mode: 'INTERACTIVE'
            })),
        });

        dxAddTab_Component<ADV.IParams>({
            widget: this,
            selectedTabLabel: this.selectedLabel,
            tab: {
                title: this.resultLabel(),
            },
            tabPanel: retVal,
            component: ADV.WIDGET_NAME,
            componentParams: ko.pureComputed(() => AS<ADV.IParams>({
                item: this.topItem(),
                mode: 'RESULT'
            })),
        });

        return retVal;
    });

    public readonly selectedLabel = ko.observable('');
    constructor(readonly params: IParams) {
        super();
    }


    public readonly topItem = ko.pureComputed(() => {
        const selected = this._items.find(x => x.docReferenceId === this.selectedItemDocRefId());
        if (!selected) {
            return undefined;
        }
        return selected.topItem.docReferenceId;
    });

    public readonly selectedItemDocRefId = ko.observable<string>();


    public readonly interactiveLabel = ko.pureComputed(() => i18next.t(['ui.subjectcoordinator.itemreview.PREVIEW']));
    public readonly resultLabel = ko.pureComputed(() => i18next.t(['ui.subjectcoordinator.itemreview.RESULT']));
    public readonly metadataLabel = ko.pureComputed(() => i18next.t(['ui.subjectcoordinator.itemreview.METADATA']));
    public readonly inspectLabel = ko.pureComputed(() => i18next.t(['ui.subjectcoordinator.itemreview.INSPECT']));

    public readonly loaded = ko.observable(false);

    public async OnRefresh() {
        await super.OnRefresh();
        const subjectDocRefId = this.params.currentRoute.widgetParams.subjectDocRefId;
        const r = await ServerConnection.api.ui_subjectcoordinator_itemreview_init({
            subjectDocRef: subjectDocRefId,
            topItems: this.params.currentRoute.widgetParams.itemDocRefIds
        });
        this._items.splice(0, this._items.length, ...r.subjectById.itemsForSubjectCoordinator);
        if (!this._items.length) {
            await routeManager.back();
            return;
        }
        if (!this._items.find(x => x.docReferenceId === this.selectedItemDocRefId())) {
            this.selectedItemDocRefId(this._items[0].docReferenceId);

        }
        await refreshDx(this);
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        if (this._items.length) {
            this._items[0].selected = true;
        }
        this.loaded(true);
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import { AtoZ, GqlDS } from '../../../dxHelper/graphQLDataSource';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../../ui/RestAPI';
type Q = Awaited<ReturnType<API.Sdk['surveycomparision_edit_data']>>;

export type COLUMN = Q['SurveyComparisionEdit']['get']['columns'][0];

export class ColumnDS extends GqlDS<COLUMN, 'index'> {
    constructor({ itemId, fetch }: {
        itemId: string;
        fetch: () => COLUMN[];
    }) {
        super({
            fetch,
            key: 'index',
            indexGenerator: AtoZ,
            onNewRow: row => {
                row.caption = { value: `Column ${row.index}` };
            },
            remove: async (index) => {
                await ServerConnection.api.surveycomparision_edit_update({
                    params: {
                        itemId,
                        removeColumns: [index]
                    }
                });
                return DONE;
            },
            upsert: async (index, data) => {
                await ServerConnection.api.surveycomparision_edit_update({
                    params: {
                        itemId,
                        upsertColumns: [{
                            index,
                            caption: data.caption && { xnone: data.caption.value },
                            objectiveId: data.objectiveId,
                        }]
                    }
                });
                return DONE;
            }
        });
    }
}

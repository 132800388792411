import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import { legacyPushPull, queueApiCall } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18next from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';
type Q = Awaited<ReturnType<API.Sdk['orderingwords_edit_data']>>;
const WIDGET_NAME = 'itemdefinition-kosovo-orderingwords-edit';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

class AnswerItem {
    constructor(readonly model: ViewModel,
        readonly answerItem: string,
        readonly answerIndex: number,
        readonly answer: Q['OrderingWordsEdit']['get']['answers'][0]) {
        this.canEdit = model.canEdit;
        this._value(answer.value.value);
    }

    public readonly canEdit: boolean;


    private readonly _value = ko.observable('');

    public readonly select = new UIAction(undefined, async () => {
        this.model.selectedAnswer(this.answer.index);
    });
    public readonly isItemSelected = ko.pureComputed(() => {
        return this.model.selectedAnswer() === this.answer.index;
    });
    public value = ko.pureComputed({
        read: () => {
            return this._value();
        },
        write: newVal => {
            this._value(newVal);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertAnswer/${this.answer.index}`, () => ServerConnection.api.orderingwords_edit_update({
                params: {
                    itemId: this.model.itemId,
                    upsertAnswers: [{
                        index: this.answer.index,
                        value: xnone(newVal)
                    }]
                }
            }));
        }
    });
    public readonly id = ko.pureComputed(() => {
        return this.answer.index;
    });
    public readonly index = ko.pureComputed(() => {
        return this.answerIndex + 1;
    });

    public readonly answerDelete = new UIAction(undefined, async () => {
        if (this.model.answers().length <= 2) {
            toastService.error(i18next.t(['itemdefinition.kosovo.orderingwords.edit.THIS_TEXT_BOX_CANNOT_BE_DELETED_YOU_NEED_AT_LEAST_2_TEXT_BOXES']));
            return;
        }
        const message = i18next.t(['itemdefinition.kosovo.orderingwords.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_TEXTBOX']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await legacyPushPull(async () => {
            await ServerConnection.api.orderingwords_edit_update({
                params: {
                    itemId: this.model.itemId,
                    removeAnswers: [this.answer.index]
                }
            });
        });

    });
}

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly loaded = ko.observable(false);
    public readonly selectedAnswer = ko.observable('');

    public readonly orderingWordsAddWordLabel = i18next.t(['itemdefinition.kosovo.orderingwords.edit.ADD_NEW_WORDS']);

    public readonly orderingWordsDeleteAnswerButtonLabel = i18next.t(['itemdefinition.kosovo.orderingwords.edit.DELETE']);

    private selectedItemIndex() {
        const s = this.selectedAnswer();
        return (this.correctOrder() || []).findIndex(x => x === s);
    }
    public readonly correctOrder = ko.observable<string[]>();

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.orderingwords.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.kosovo.orderingwords.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.orderingwords.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.kosovo.orderingwords.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        return retVal;
    });
    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.orderingwords.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly correctOrderText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.orderingwords.edit.CORRECT_ORDER']);
    });

    public readonly correctOrderHTML = ko.pureComputed(() => {
        const order = this.correctOrder();
        if (!order) {
            return '';
        }
        return order.join(' ');
    });

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.orderingwords_edit_data({
            itemId: this.itemId
        });
        const d = q.OrderingWordsEdit.get;
        this.answers(d.correctOrder.map((answerIndex, index) => new AnswerItem(this, answerIndex, index, d.answers.find(x => x.index == answerIndex))));
        this.question(d.question.value);
        this.header(d.header.value);
        this.correctOrder(d.correctOrder);
    }

    public async initialize() {
        await super.initialize();

        await this.OnRefresh();

        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.orderingwords_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)

                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.orderingwords_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val)
                }
            });
            return DONE;
        });

        this.loaded(true);
    }

    public readonly answers = ko.observable<AnswerItem[]>();



    public answerAdd = new UIAction(undefined, async () => {
        const id = this.findUnusedAnswerID();
        if (!id) {
            toastService.error(i18next.t(['itemdefinition.kosovo.orderingwords.edit.YOU_CANNOT_ADD_MORE_THAN_26_TEXTBOXES'])
            );
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.orderingwords_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertAnswers: [{
                        index: id,
                        value: {
                            'xnone': ''
                        }
                    }]
                }
            });
        });
    });

    private findUnusedAnswerID() {
        const existing: { [key: string]: boolean } = {};
        const answers = this.answers();

        if (answers.length >= 26) {
            return undefined;
        }

        for (let i = 0; i < answers.length; ++i) {
            existing[answers[i].id()] = true;
        }
        for (let i = 0; i < 26; ++i) {
            const letter = String.fromCharCode(65 + i);
            if (!(letter in existing)) {
                return letter;
            }
        }
        return undefined;
    }

    public readonly isItemMovableLeft = ko.pureComputed(() => {
        return this.selectedItemIndex() > 0;
    });
    public readonly answerMoveRight = new UIAction(undefined, async () => {
        const selectedIndex = this.selectedItemIndex();
        if (selectedIndex < 0) {
            return;
        }
        if (selectedIndex >= this.answers().length - 1) {
            return;
        }
        const correctAnswer = this.correctOrder();
        correctAnswer.splice(selectedIndex, 2, correctAnswer[selectedIndex + 1], correctAnswer[selectedIndex]);
        await legacyPushPull(async () => {
            await ServerConnection.api.orderingwords_edit_update({
                params: {
                    itemId: this.itemId,
                    setCorrectOrder: correctAnswer
                }
            });
        });
    });

    public readonly isItemMovableRight = ko.pureComputed(() => {
        return this.selectedItemIndex() > -1 && this.selectedItemIndex() < this.answers().length - 1;
    });
    public readonly answerMoveLeft = new UIAction(undefined, async () => {
        const selectedIndex = this.selectedItemIndex();
        if (selectedIndex < 1) {
            return;
        }
        const correctAnswer = this.correctOrder();
        correctAnswer.splice(selectedIndex - 1, 2, correctAnswer[selectedIndex], correctAnswer[selectedIndex - 1]);
        await legacyPushPull(async () => {
            await ServerConnection.api.orderingwords_edit_update({
                params: {
                    itemId: this.itemId,
                    setCorrectOrder: correctAnswer
                }
            });
        });
    });
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.orderingwords.edit.ENTER_THE_TEXT_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

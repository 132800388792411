// tslint:disable:object-literal-key-quotes
import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-subjectcoordinator-itemreview';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
    itemDocRefIds: string[];
}

export class ItemReviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.itemreview.ITEM_REVIEW']);
    });

    constructor(readonly widgetParams: IParamsInner) {
    }

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.subjectcoordinator.itemreview.HELP']),
                action: this.showHelp
            }];
    });
    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.subjectcoordinator.itemreview.YOU_CAN_SWITCH_BETWEEN_THE_SELECTED_ITEMS_EDIT_THEIR_METADATA_AND_PREVIEW_THEM_THE_ITEM_ID_LIST_AND_THE_METADATA_HAVE_AN_OWN_THIN_SCROLLBAR_YOU_HAVE_TO_USE_THIS_ONE_TO_SEE_ALL_DATA']);

        toastService.info(msg);
    });
}

export const FACTORY = ROUTES.registerRoute<{ subject: string, items: string[] }>({
    route: '/subjectcoordinator/subject/:subject/item/:items',
    toParams: map => ({
        items: (map.get('items') || '').split(','),
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('items', p.items.join(','));
        map.set('subject', p.subject);
    },
    create: async p => {

        const subjectDocRefId = p.subject;
        const route = new ItemReviewRoute({
            subjectDocRefId,
            itemDocRefIds: p.items
        });
        return route;
    },
});

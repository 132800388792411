import type DevExpress from 'devextreme/bundles/dx.all';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxFilterBuilder from 'devextreme/ui/filter_builder';
import dxForm from 'devextreme/ui/form';
import dxTextArea from 'devextreme/ui/text_area';
import dxTextBox from 'devextreme/ui/text_box';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { log, warn } from '../../../../debug';
import { addTextArea, AS, datagrid, refreshDx } from '../../../../dx_helper';
import * as HELPER from '../../../../helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { KoMap } from '../../../../komap';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import * as RESULTVIEWER from '../../../../widgets/serializedresultviewer/widget';
import * as i18n from './../../../../i18n/i18n';
import { ALL_FILE, ATTACHMENT, buildPropertyForm, EVALUATION, FILE, INVESTIGATOR, PREPERATOR, PROPERTY } from './formbuilder';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-handson-edit';

export type IParams = IItemDefinitionWidgetParams;
type EVAL = Awaited<ReturnType<API.Sdk['inapplication_edit_eval']>>;

interface IPlainTreeEntry {
    id: string;
    parentId?: string;
    text: string;
    uri?: string;
    name?: string;
    description?: string;
    serviceProviderId: string;
    rootGroupId: string;
    partialId: string;
}

let initOnce: Promise<void>;

function formatSpaces(html: string) {
    return (html || '').replace(/ /g, '<span class="space">·</span>');
}

function formatEvalResult(r: EVAL['handsOnEdit']['validateFormulas'][0]) {
    const retVal = [];
    if (r.error) {
        retVal.push(`${i18n.t(['itemdefinition.kosovo.handson.edit.INVALID_FORMULA'])} ${r.error}`);
        retVal.push(r.formula);
    } else {
        retVal.push('<div class="dx-filterbuilder dx-widget">');
        retVal.push('<div class="dx-filterbuilder-group">');
        retVal.push('<div class="dx-filterbuilder-group-content">');

        _formatEvalPart(retVal, r.allResults, r.rootResult.id, false);
        retVal.push('</div>');
        retVal.push('</div>');
        retVal.push('</div>');
    }
    return retVal.join('');
}

function _formatEvalPart(retVal: string[], all: EVAL['handsOnEdit']['validateFormulas'][0]['allResults'], cur: string, not: boolean): void {
    const obj = all.find(x => x.id === cur);
    if (!obj) {
        return;
    }
    const errTitle = !obj.error ? '' : `<div class="dx-widget tooltip">${HELPER.encodeHtml(obj.error)}</div>`;
    const cssIcon = obj.error ? `dx-icon-isblank ${WIDGET_NAME}-error` : (obj.result ? `dx-icon-isnotblank ${WIDGET_NAME}-true` : `dx-icon-isblank ${WIDGET_NAME}-false`);
    if (obj.__typename === 'BinaryFunctionResult') {
        retVal.push('<div class="dx-filterbuilder-group">');
        retVal.push('<div class="dx-filterbuilder-group-item">');


        retVal.push(`<div class="dx-filterbuilder-action-icon ${cssIcon} dx-filterbuilder-action" tabindex="0">${errTitle}</div>`);
        retVal.push(`<div class="dx-filterbuilder-text dx-filterbuilder-item-field" tabindex="0">${obj.fieldId}</div>`);

        let binOp: string = obj.binOp;
        switch (obj.binOp) {
            case API.BinaryFunctionOperator.Between:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.BETWEEN']);
                break;
            case API.BinaryFunctionOperator.Contains:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.CONTAINS']);
                break;
            case API.BinaryFunctionOperator.Diff:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.DIFF']);
                break;
            case API.BinaryFunctionOperator.Eq:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.EQ']);
                break;
            case API.BinaryFunctionOperator.Ge:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.GE']);
                break;
            case API.BinaryFunctionOperator.Gt:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.GT']);
                break;
            case API.BinaryFunctionOperator.Le:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.LE']);
                break;
            case API.BinaryFunctionOperator.Lt:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.LT']);
                break;
            case API.BinaryFunctionOperator.Issamearray:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.ISSAMEARRAY']);
                break;
            case API.BinaryFunctionOperator.Matches:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.MATCHES']);
                break;
            case API.BinaryFunctionOperator.Notcontains:
                binOp = i18n.t(['itemdefinition.kosovo.handson.edit.NOTCONTAINS']);
                break;
        }
        retVal.push(`<div class="dx-filterbuilder-text dx-filterbuilder-item-operation" tabindex="0">${binOp}</div>`);
        retVal.push(`<div class="dx-filterbuilder-text dx-filterbuilder-item-value">`);
        retVal.push(`<div class="dx-filterbuilder-item-value-text" tabindex="0">`);
        if (typeof obj.argsNumber === 'number') {
            retVal.push(obj.argsNumber.toString());
        } else if (typeof obj.argsStr === 'string') {
            retVal.push(formatSpaces(HELPER.encodeHtml(obj.argsStr)));
        } else if (typeof obj.argsBoolean === 'boolean') {
            retVal.push(obj.argsBoolean ? i18n.t(['itemdefinition.kosovo.handson.edit.TRUE']) : i18n.t(['itemdefinition.kosovo.handson.edit.FALSE']));
        } else if (obj.argsStrArr) {
            const sep = '<span class="dx-filterbuilder-text-separator dx-filterbuilder-text-separator-empty">–</span>';
            retVal.push(obj.argsStrArr.map(x => formatSpaces(HELPER.encodeHtml(x))).map(x => `<span class="dx-filterbuilder-text-part">${x}</span>`).join(sep));
        } else if (obj.argsNumberArray) {
            const sep = '<span class="dx-filterbuilder-text-separator dx-filterbuilder-text-separator-empty">–</span>';
            retVal.push(obj.argsStrArr.map(x => `<span class="dx-filterbuilder-text-part">${x}</span>`).join(sep));
        }
        retVal.push('</div>');
        retVal.push(`</div>`);

        retVal.push('</div>');
        retVal.push('</div>');
        return;
    }
    if (obj.__typename === 'ConditionResult') {
        let op = '';
        switch (obj.conOp) {
            case 'AND':
                if (not) {
                    op = i18n.t(['itemdefinition.kosovo.handson.edit.NOT_AND']);
                } else {
                    op = i18n.t(['itemdefinition.kosovo.handson.edit.AND']);
                }
                break;
            case 'OR':
                if (not) {
                    op = i18n.t(['itemdefinition.kosovo.handson.edit.NOT_OR']);
                } else {
                    op = i18n.t(['itemdefinition.kosovo.handson.edit.OR']);
                }
                break;
            default:
                op = obj.conOp;
                break;
        }
        retVal.push('<div class="dx-filterbuilder-group">');
        retVal.push('<div class="dx-filterbuilder-group-item">');
        retVal.push(`<div class="dx-filterbuilder-action-icon ${cssIcon} dx-filterbuilder-action" tabindex="0">${errTitle}</div>`);
        retVal.push(`<div class="dx-filterbuilder-text dx-filterbuilder-group-operation" tabindex="0">${op}</div>`);
        retVal.push('</div>');
        retVal.push('<div class="dx-filterbuilder-group-content">');
        for (const arg of obj.args) {
            _formatEvalPart(retVal, all, arg.id, false);
        }
        retVal.push('</div>');
        retVal.push('</div>');
        return;
    }
    if (obj.__typename === 'UnaryFunctionResult') {
        switch (obj.unOp) {
            case 'NOT':
                _formatEvalPart(retVal, all, obj.arg.id, true);
                return;
            default:
                return;
        }
    }
    return;
}
function initInvestigators() {
    if (initOnce) {
        return initOnce;
    }
    const fn = async () => {
        const investigators = await ServerConnection.api.inapplication_edit_investigators({
        });
        allEnrichmentServiceProviders(investigators.enrichment.status.serviceProviders);
    };
    initOnce = fn();
    return initOnce;
}
const allEnrichmentServiceProviders = ko.observable<Awaited<ReturnType<API.Sdk['inapplication_edit_investigators']>>['enrichment']['status']['serviceProviders']>();

export class ViewModel extends DxWidget {
    private readonly _files: FILE[] = [];
    private readonly _allFiles: ALL_FILE[] = [];
    private readonly _investigators: INVESTIGATOR[] = [];
    private readonly _preparators: PREPERATOR[] = [];
    private readonly _evaluations: EVALUATION[] = [];
    private readonly _attachments: ATTACHMENT[] = [];

    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly loaded = ko.observable(false);
    //private readonly $callbacks = $.Callbacks();
    public readonly validationGroupId = HELPER.htmlId();

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }


    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.handson.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.handson.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.handson.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.handson.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        return retVal;
    });

    public getEvaluationScoreOptions(evaluationId: string): DevExpress.ui.dxTextBox.Properties {
        const evalData = this._evaluations.find(x => x.evaluationId === evaluationId);
        const retVal: DevExpress.ui.dxTextBox.Properties = {
            value: (evalData.score || 0).toString(),
            onValueChanged: e => {
                const scoreOrNaN = parseInt(e.value);
                const score = isNaN(scoreOrNaN) ? undefined : scoreOrNaN;
                const grid = this.evaluationsGrid();
                const rowIndex = grid.getRowIndexByKey(evaluationId);
                grid.editCell(rowIndex, 'score');
                grid.cellValue(rowIndex, 'score', score);
                grid.closeEditCell();
            }
        };
        return retVal;
    }
    public getEvaluationFormulaOptions(evaluationId: string): DevExpress.ui.dxFilterBuilder.Properties {
        const retVal: DevExpress.ui.dxFilterBuilder.Properties = {
            allowHierarchicalFields: false,
            fields: [],
            customOperations: [
                {
                    caption: i18n.t(['itemdefinition.kosovo.handson.edit.MATCHES']),
                    dataTypes: ['string'],
                    hasValue: true,
                    name: 'matches'
                },
                {
                    caption: i18n.t(['itemdefinition.kosovo.handson.edit.ISSAMEARRAY']),
                    hasValue: true,
                    name: 'issamearray',
                    editorTemplate: (conditionInfo, container) => {
                        const retVal = $('<div>');
                        const ta = new dxTextBox(retVal, {
                            value: JSON.stringify(conditionInfo.value),
                            onEnterKey: (e: any) => {
                                log('on enter:' + e.component.option('value'));
                            },
                            onChange: (e: any) => {
                                log('on change:' + e.component.option('value'));
                            },
                            onInput: (e: any) => {
                                log('on input:' + e.component.option('value'));
                            },
                            onValueChanged: (e: any) => {
                                log('on value changed:' + e.component.option('value'));
                                try {
                                    const val = JSON.parse(e.value);
                                    if (!Array.isArray(val)) {
                                        alert('no an array');
                                        return;
                                    }
                                    conditionInfo.setValue(val);
                                } catch (e) {
                                    alert(e.message);
                                }
                            }
                        });
                        retVal.appendTo(container);
                        return retVal;
                    },
                }
            ]
        };
        for (const inv of this._investigators) {
            if (!inv.info) {
                continue;
            }
            if (inv.info.resultIsArray) {
                switch (inv.info.resultType) {
                    case 'string':
                        retVal.fields.push({
                            dataField: inv.investigatorId,
                            dataType: 'string',
                            filterOperations: ['matches', 'contains', 'isblank', 'isnotblank', 'issamearray'],

                        });
                        break;
                    default:
                        retVal.fields.push({
                            dataField: inv.investigatorId,
                            filterOperations: ['issamearray', 'contains', 'isblank', 'isnotblank'],
                        });
                        break;
                }
                continue;
            }
            switch (inv.info.resultType) {
                case 'string':
                    retVal.fields.push({
                        dataField: inv.investigatorId,
                        dataType: 'string'
                    });
                    break;
                case 'int':
                    retVal.fields.push({
                        dataField: inv.investigatorId,
                        dataType: 'number',
                        format: 'decimal'
                    });
                    break;
                case 'double':
                    retVal.fields.push({
                        dataField: inv.investigatorId,
                        dataType: 'number',
                        format: 'decimal'
                    });
                    break;
                case 'enum':
                    retVal.fields.push({
                        dataField: inv.investigatorId,
                        dataType: 'string',
                        lookup: {
                            displayExpr: 'id',
                            valueExpr: 'id',
                            dataSource: inv.info.resultEnumType.values
                        }
                    });
                    break;
                case 'bool':
                    retVal.fields.push({
                        dataField: inv.investigatorId,
                        dataType: 'boolean',
                    });
                    break;
                default:
                    warn(`Unhandled field type ${inv.info.resultType
                        } for investigator ${inv.investigatorUri}.Assuming object.`);
                    retVal.fields.push({
                        dataField: inv.investigatorId,
                        dataType: 'object'
                    });
            }
        }
        return retVal;
    }

    public readonly investigatorDataSource = ko.pureComputed(() => {
        const all = allEnrichmentServiceProviders();
        const ds: IPlainTreeEntry[] = [];
        const branchIds = new Set<string>();
        for (const sp of all) {
            for (const inv of sp.investigatorDescriptions) {
                const parts = inv.id.split('.');
                const ids = [];
                for (let l = 1; l <= parts.length; ++l) {
                    ids.push(sp.id + '|' + parts.slice(0, l).join('.'));
                }
                const parentIds = [undefined, ...ids];
                for (let l = 0; l < parts.length - 1; ++l) {
                    if (branchIds.has(ids[l])) {
                        continue;
                    }
                    branchIds.add(ids[l]);
                    ds.push({
                        id: ids[l],
                        parentId: parentIds[l],
                        serviceProviderId: sp.id,
                        rootGroupId: parts[0],
                        text: parts[l],
                        partialId: '',
                    });
                }
                ds.push({
                    id: ids[ids.length - 1],
                    text: parts[parts.length - 1],
                    serviceProviderId: sp.id,
                    rootGroupId: parts[0],
                    parentId: parentIds[ids.length - 1],
                    uri: inv.id,
                    name: inv.name.value,
                    description: inv.description.value,
                    partialId: parts.slice(1).join('.'),
                });
            }
        }
        return ds;
    });
    public readonly preparatorDataSource = ko.pureComputed(() => {
        const all = allEnrichmentServiceProviders();
        const ds: IPlainTreeEntry[] = [];
        const branchIds = new Set<string>();

        for (const sp of all) {
            for (const prep of sp.preparatorDescriptions) {
                const parts = prep.id.split('.');
                const ids = [];
                for (let l = 1; l <= parts.length; ++l) {
                    ids.push(sp.id + '|' + parts.slice(0, l).join('.'));
                }
                const parentIds = [undefined, ...ids];
                for (let l = 0; l < parts.length - 1; ++l) {
                    if (branchIds.has(ids[l])) {
                        continue;
                    }
                    branchIds.add(ids[l]);
                    ds.push({
                        id: ids[l],
                        parentId: parentIds[l],
                        rootGroupId: parts[0],
                        serviceProviderId: sp.id,
                        text: parts[l],
                        partialId: '',
                    });
                }
                ds.push({
                    id: ids[ids.length - 1],
                    text: parts[parts.length - 1],
                    rootGroupId: parts[0],
                    serviceProviderId: sp.id,
                    parentId: parentIds[ids.length - 1],
                    name: prep.name.value,
                    description: prep.description.value,
                    uri: prep.id,
                    partialId: parts.slice(1).join('.'),
                });
            }
        }
        return ds;
    });
    public readonly validationGroupOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxValidationGroup.Properties = {

        };
        return retVal;
    });
    public readonly validationSummaryOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxValidationSummary.Properties = {
            validationGroup: this.validationGroupId
        };
        return retVal;
    });
    public async initialize() {
        await super.initialize();
        await initInvestigators();
        await this.OnRefresh();

        this.onChange(this.evaluationInfo, `${WIDGET_NAME} /${this.itemId}/evaluationInfo`, async () => {
            await ServerConnection.api.inapplicatoin_edit_update({
                params: {
                    itemId: this.itemId,
                    evaluationInfo: xnone(this.evaluationInfo())
                }
            });
            return DONE;
        }, {
            triggerRefresh: false
        });
        this.onChange(this.question, `${WIDGET_NAME} /${this.itemId}/question`, async () => {
            await ServerConnection.api.inapplicatoin_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(this.question())
                }
            });
            return DONE;
        }, {
            triggerRefresh: false,
        });
        this.onChange(this.header, `${WIDGET_NAME} /${this.itemId}/header`, async () => {
            await ServerConnection.api.inapplicatoin_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(this.header())
                }
            });
            return DONE;
        }, {
            triggerRefresh: false
        });
        //validationEngine.validateGroup(this.validationGroupId);
        this.loaded(true);
    }

    public readonly evaluationInfo = ko.observable('');

    public readonly newPreparatorId = ko.observable('');
    public readonly newInvestigatorId = ko.observable('');

    public readonly newInvestigatorTreeListOptions = ko.pureComputed(() => {
        if (!this.loaded()) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxTreeList.Properties = {
            dataSource: this.investigatorDataSource(),
            dataStructure: 'plain',
            columns: [],
            height: '100%',
            autoExpandAll: false,
            allowColumnResizing: true,
            columnAutoWidth: true,
            wordWrapEnabled: true,
            columnHidingEnabled: false,
            filterRow: {
                visible: true
            },
            headerFilter: {
                visible: true,
            },
            sorting: {
                mode: 'single'
            },
            selection: {
                mode: 'single',
                allowSelectAll: false,
            },

            onSelectionChanged: (e) => {
                if (!e.selectedRowsData || !e.selectedRowsData.length) {
                    return;
                }
                const data: IPlainTreeEntry = e.selectedRowsData[0];
                if (data.uri) {
                    this.newInvestigatorId(e.selectedRowKeys[0]);
                } else {
                    e.component.deselectAll();
                }
            },
        };
        retVal.columns.push({
            dataField: 'rootGroupId',
            caption: i18n.t(['itemdefinition.kosovo.handson.edit.CATEGORY']),
            sortIndex: 0,
            sortOrder: 'asc',
            width: 200,
        });
        retVal.columns.push({
            dataField: 'partialId',
            caption: i18n.t(['itemdefinition.kosovo.handson.edit.INVESTIGATOR']),
            width: 350,
        });
        retVal.columns.push({
            dataField: 'description',
            caption: i18n.t(['itemdefinition.kosovo.handson.edit.DESCRIPTION'])
        });
        return retVal;
    });
    public readonly newPreparatorTreeListOptions = ko.pureComputed(() => {
        if (!this.loaded()) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxTreeList.Properties = {
            dataSource: this.preparatorDataSource(),
            dataStructure: 'plain',
            autoExpandAll: false,
            height: '100%',
            allowColumnResizing: true,
            columnAutoWidth: true,
            columnHidingEnabled: false,
            wordWrapEnabled: true,
            filterRow: {
                visible: true
            },
            headerFilter: {
                visible: true,
            },
            sorting: {
                mode: 'single'
            },
            selection: {
                mode: 'single',
                allowSelectAll: false,
            },
            columns: [],
            onSelectionChanged: (e) => {
                if (!e.selectedRowsData || !e.selectedRowsData.length) {
                    return;
                }
                const data: IPlainTreeEntry = e.selectedRowsData[0];
                if (data.uri) {
                    this.newPreparatorId(e.selectedRowKeys[0]);
                } else {
                    e.component.deselectAll();
                }

            }
        };
        retVal.columns.push({
            dataField: 'rootGroupId',
            caption: i18n.t(['itemdefinition.kosovo.handson.edit.CATEGORY']),
            sortIndex: 0,
            sortOrder: 'asc',
        });
        retVal.columns.push({
            dataField: 'partialId',
            caption: i18n.t(['itemdefinition.kosovo.handson.edit.PREPARATOR'])
        });
        retVal.columns.push({
            dataField: 'description',
            caption: i18n.t(['itemdefinition.kosovo.handson.edit.DESCRIPTION'])
        });
        return retVal;
    });

    public readonly accordionOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxAccordion.Properties = {
            dataSource: [
                {
                    title: i18n.t(['itemdefinition.kosovo.handson.edit.EVALUATION_HINT']),
                    template: () => {
                        const retVal = $(`<div data-bind="dxTextArea: {value:evaluationInfo,readOnly:isReadOnly, placeholder:evalInfoPlaceholder}"></div>`);
                        ko.applyBindings(this, retVal.get(0));
                        return retVal;
                    }
                },
                {
                    title: i18n.t(['itemdefinition.kosovo.handson.edit.FILE_STRUCTURE']),
                    template: () => {
                        const retVal: any = $('<div />');
                        const x = new dxDataGrid(retVal, this.fileGridOptions());
                        return retVal;
                    },
                },
                {
                    title: i18n.t(['itemdefinition.kosovo.handson.edit.PREPARATORS']),
                    template: () => {
                        const retVal: any = $('<div />');
                        const x = new dxDataGrid(retVal, this.preparatorGridOptions());
                        return retVal;
                    },
                },
                {
                    title: i18n.t(['itemdefinition.kosovo.handson.edit.INVESTIGATORS']),
                    template: () => {
                        const retVal: any = $('<div />');
                        const x = new dxDataGrid(retVal, this.investigatorGridOptions());
                        return retVal;

                    },
                },
                {
                    title: i18n.t(['itemdefinition.kosovo.handson.edit.EVALUATIONS']),
                    template: () => {
                        const retVal: any = $('<div />');
                        const x = new dxDataGrid(retVal, this.evaluationGridOptions());
                        return retVal;

                    },
                }

            ],
            multiple: true,
            collapsible: true,
            deferRendering: true,
        };
        return retVal;
    });


    public readonly newPreparatorPopupVisible = ko.observable(false);
    public readonly newInvestigatorPopupVisible = ko.observable(false);
    public readonly newPreparatorPopup = ko.pureComputed(() => {
        if (!this.loaded()) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxPopup.Properties = {
            title: i18n.t(['itemdefinition.kosovo.handson.edit.ADD_PREPARATOR']),
            visible: <any>this.newPreparatorPopupVisible,
            toolbarItems: [],
            closeOnOutsideClick: true,
        };
        retVal.toolbarItems.push({
            widget: 'dxButton',
            location: 'after',
            options: {
                text: 'Ok',
                onClick: this.addPreparator.click
            }
        });
        return retVal;
    });

    public readonly newInvestigatorPopup = ko.pureComputed(() => {
        if (!this.loaded()) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxPopup.Properties = {
            title: i18n.t(['itemdefinition.kosovo.handson.edit.ADD_INVESTIGATOR']),
            visible: <any>this.newInvestigatorPopupVisible,
            toolbarItems: [],
            closeOnOutsideClick: true,
        };
        retVal.toolbarItems.push({
            widget: 'dxButton',
            location: 'after',
            options: {
                text: 'Ok',
                onClick: this.addInvestigator.click
            }
        });
        return retVal;
    });


    prepareInvestigatorToolbar(e: { model?: ViewModel, toolbarOptions?: DevExpress.ui.dxToolbar.Properties }) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    icon: 'add',
                    disabled: this.isReadOnly,
                    onClick: () => {
                        this.newInvestigatorPopupVisible(true);
                    }
                }),
            }
        );
    }

    preparePreparatorToolbar(e: { model?: ViewModel, toolbarOptions?: DevExpress.ui.dxToolbar.Properties }) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    icon: 'add',
                    disabled: this.isReadOnly,
                    onClick: () => {
                        this.newPreparatorPopupVisible(true);
                        //this.addPreparator.click
                    }
                }),
            }
        );
    }
    prepareFilesToolbar(e: { model?: ViewModel, toolbarOptions?: DevExpress.ui.dxToolbar.Properties }) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    icon: 'add',
                    disabled: this.isReadOnly,
                    onClick: this.addFile.click
                }),
            }
        );
    }
    prepareEvaluationsToolbar(e: { model?: ViewModel, toolbarOptions?: DevExpress.ui.dxToolbar.Properties }) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    icon: 'runner',
                    onClick: this.evaluate.click
                }
            }
        );
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    icon: 'add',
                    disabled: this.isReadOnly,
                    onClick: this.addEvaluation.click
                })
            }
        );
    }

    public readonly addInvestigator = new UIAction(undefined, async () => {
        this.newInvestigatorPopupVisible(false);
        const newInvestigatorId = this.newInvestigatorId();
        if (!newInvestigatorId) {
            return;
        }
        const [spId, uri] = newInvestigatorId.split('|');

        let key: string;
        let currentKeys = new Set(this._investigators.map(x => x.investigatorId));
        for (let i = 1; ; ++i) {
            key = `INV${i}`;
            if (!currentKeys.has(key)) {
                break;
            }
        }
        await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
            params: {
                itemId: this.itemId,
                upsertInvestigators: [{
                    investigatorId: key,
                    investigatorUri: uri,
                    serviceProviderId: spId
                }]
            }
        }));

        /*
        const item = this.item();
    
        const currentKeys = new Set<string>(item.investigators.keys());
        let key: string;
        const inv = this.item().investigators.getCreate(key, () => new ITEMDATA.Investigator());
    
        inv.id(key);
        inv.serviceProviderId(sp.id);
        inv.investigatorUri(eSrc.id);
        inv.resultEnumType(eSrc.resultEnumType && eSrc.resultEnumType.id || '');
        inv.resultIsArray(eSrc.resultIsArray || false);
        inv.resultType(eSrc.resultType);
        for (const pSrc of eSrc.properties) {
            const pTgt = inv.properties.getCreate(pSrc.id, () => new ITEMDATA.Property());
            pTgt.id(pSrc.id);
            pTgt.type(pSrc.type);
        }
        */
    });

    public readonly addFile = new UIAction(undefined, async () => {
        const currentKeys = new Set<string>(this._files.map(x => x.fileId));
        let key: string;
        for (let i = 1; ; ++i) {
            key = `F${i}`;
            if (!currentKeys.has(key)) {
                break;
            }
        }
        await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
            params: {
                itemId: this.itemId,
                upsertFiles: [{
                    fileId: key,
                    userCreated: false,
                    path: ''
                }]
            }
        }));
    });

    public readonly addEvaluation = new UIAction(undefined, async () => {
        const currentKeys = new Set<string>(this._evaluations.map(x => x.evaluationId));
        let key: string;
        for (let i = 1; ; ++i) {
            key = `E${i}`;
            if (!currentKeys.has(key)) {
                break;
            }
        }
        await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
            params: {
                itemId: this.itemId,
                upsertEvaluations: [{
                    evaluationId: key,
                    formula: '',
                    score: 1
                }]
            }
        }));
    });

    public readonly addPreparator = new UIAction(undefined, async () => {
        this.newPreparatorPopupVisible(false);
        const newPrepId = this.newPreparatorId();
        if (!newPrepId) {
            return;
        }
        const [provider, uri] = newPrepId.split('|');

        const sp = allEnrichmentServiceProviders().find(x => x.id === provider);
        if (!sp) {
            throw new Error(`How?`);
        }
        const eSrc = sp.preparatorDescriptions.find(x => x.id === uri);
        if (!eSrc) {
            throw new Error(`How?`);
        }

        const currentKeys = new Set<string>(this._preparators.map(x => x.preparatorId));
        let key: string;
        for (let i = 1; ; ++i) {
            key = `PREP${i}`;
            if (!currentKeys.has(key)) {
                break;
            }
        }
        await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
            params: {
                itemId: this.itemId,
                upsertPreparators: [{
                    preparatorId: key,
                    preparatorUri: uri,
                    serviceProviderId: provider
                }]
            }
        }));
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.inapplication_edit_data({
            itemId: this.params.itemId,
        });

        const d = data.handsOnEdit.get;

        this._allFiles.splice(0, this._allFiles.length, ...data.item.getFileStructure);
        this._files.splice(0, this._files.length, ...d.filestructure);
        this._evaluations.splice(0, this._evaluations.length, ...d.evaluations);
        this._investigators.splice(0, this._investigators.length, ...d.investigators);
        this._preparators.splice(0, this._preparators.length, ...d.preparators);
        this._attachments.splice(0, this._attachments.length, ...data.documents.get.attachments);
        this.evaluationInfo(d.evaluationInfo.value);
        this.question(d.question.value);
        this.header(d.header.value);

        await this.evaluateNoRefresh();

        await refreshDx(this);

    }
    private async refreshGrid() {
        await refreshDx(this);
    }


    private buildForm(options: DevExpress.ui.dxForm.Properties, properties: PROPERTY[], onSave: () => void, onCancel: () => void) {
        buildPropertyForm(options, properties, onSave, onCancel, this._allFiles);
    }
    private encodeValue(value: any): string {
        if (typeof value === 'undefined') {
            return '';
        }
        if (typeof value === 'object') {
            if (value === null) {
                return '';
            }
        }
        if (typeof value === 'boolean') {
            return value.toString();
        }
        if (typeof value === 'number') {
            return value.toString();
        }
        if (typeof value === 'string') {
            return value;
        }

        throw new Error(`Unable to encode value ${value} of type ${typeof value}`);
    }
    private toUpsertProperty(formData: any) {
        return Object.keys(formData).filter(propertyId => propertyId !== 'tempResult').map(propertyId => {
            const value = this.encodeValue(formData[propertyId]);
            return {
                propertyId, value
            };
        });
    }
    public getFormTesterOptions(invData: INVESTIGATOR): DevExpress.ui.dxForm.Properties {
        const props: any = {};
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: props,
            items: [],
        };

        const curTempResult = invData.temporaryResult && invData.temporaryResult.serializedValue || '';
        props['tempResult'] = this.InvValues.get(invData.id) || curTempResult;

        const collapse = async () => {
            const grid = this.investigatorGrid();
            if (grid) {
                const key = invData.investigatorId;
                await grid.collapseRow(key);
            }
        };
        this.buildForm(retVal, [], async () => {
            const newTempResult = props['tempResult'];
            if (newTempResult !== curTempResult) {
                log(`Update temp result of inv ${invData.investigatorId} to ${newTempResult}`);
                this.InvValues.set(invData.id, newTempResult);
            }
            await collapse();
        }, collapse);
        addTextArea(retVal, {
            label: {
                text: 'Example Result'
            },
            dataField: 'tempResult'
        }, {
            autoResizeEnabled: true
        });

        return retVal;
    }
    public getInvestigatorDetailOptions(invData: INVESTIGATOR): DevExpress.ui.dxForm.Properties {
        const props: any = {};
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: props,
            items: [],
        };

        const collapse = async () => {
            const grid = this.investigatorGrid();
            if (grid) {
                const key = invData.investigatorId;
                await grid.collapseRow(key);
            }
        };
        this.buildForm(retVal, invData.properties, async () => {
            await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertInvestigators: [{
                        investigatorId: invData.investigatorId,
                        investigatorUri: invData.investigatorUri,
                        serviceProviderId: invData.serviceProviderId,
                        upsertProperties: this.toUpsertProperty(props),
                    }]
                }
            }));
            await collapse();
        }, collapse);

        return retVal;
    }
    public getPreparatorDetailOptions(prepData: PREPERATOR): DevExpress.ui.dxForm.Properties {
        const props: any = {};

        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: props,
            items: []
        };
        const collapse = async () => {
            const grid = this.preparatorGrid();
            if (!grid) {
                return;
            }
            const key = prepData.preparatorId;
            await grid.collapseRow(key);
        };
        this.buildForm(retVal, prepData.properties, async () => {
            await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertPreparators: [{
                        preparatorId: prepData.preparatorId,
                        preparatorUri: prepData.preparatorUri,
                        serviceProviderId: prepData.serviceProviderId,
                        upsertProperties: this.toUpsertProperty(props),
                    }]
                }

            }));
            await collapse();
        }, collapse);
        return retVal;
    }
    private readonly investigatorGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    private readonly filesGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    private readonly preparatorGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    private readonly evaluationsGrid = ko.observable<DevExpress.ui.dxDataGrid>();

    public readonly investigatorGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.investigatorGrid,
            discriminator: 'investigators',
            config: {
                noDataText: i18n.t(['itemdefinition.kosovo.handson.edit.THERE_ARE_NO_INVESTIGATORS_YET']),
                keyExpr: 'investigatorId',
                dataSource: this._investigators,
                allowColumnResizing: true,
                editing: {
                    allowAdding: false,
                    allowDeleting: this.canEdit,
                    allowUpdating: false,
                    mode: 'popup',
                },
                onToolbarPreparing: (e) => this.prepareInvestigatorToolbar(e),
                onEditorPreparing: e => {
                    if (e.dataField == 'tempResult') {
                        e.editorName = 'dxTextArea';
                    }
                }
            }

        });
        retVal.columns.push(
            {
                dataField: 'investigatorId',
                width: 100,
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.ID'])
            });
        retVal.columns.push(
            {
                dataField: 'investigatorUri',
                allowEditing: false,
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.INVESTIGATOR']),
                calculateDisplayValue: (rowData: INVESTIGATOR) => {
                    const sp = allEnrichmentServiceProviders().find(x => x.id === rowData.serviceProviderId);
                    if (!sp) {
                        return `${rowData.serviceProviderId} ${rowData.investigatorUri}`;
                    }
                    const i = sp.investigatorDescriptions.find(x => x.id === rowData.investigatorUri);
                    if (!i) {
                        return `${rowData.serviceProviderId} ${rowData.investigatorUri}`;
                    }
                    return `${i.name.value} (${i.id})`;
                }
            });
        retVal.columns.push(
            {
                dataField: 'tempResult',
                width: 400,
                allowEditing: true,
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.EDIT_MODE_RESULT']),
                calculateCellValue: (rowData: INVESTIGATOR) => {
                    const r = rowData.temporaryResult;
                    if (!r) {
                        return '';
                    }
                    if (this.InvValues.has(rowData.id)) {
                        return this.InvValues.get(rowData.id);
                    }
                    if (r.status === API.HandsOnEditQuery_TemporaryResults_ResultStatusEnum.Available) {
                        return r.serializedValue;
                    }
                    return '';
                },

            });
        retVal.onRowRemoving = (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeInvestigators: [e.key]
                    }
                }));
            })();
        };
        retVal.masterDetail = {
            enabled: true,
            template: (elem, data) => {
                const retVal = $('<div />');

                const formElem = $('<div />');
                new dxForm(formElem, this.getInvestigatorDetailOptions(data.data));
                formElem.appendTo(retVal);

                const invData: INVESTIGATOR = data.data;
                const tempResultTester = $(`<div data-bind="component: {name: '${RESULTVIEWER.WIDGET_NAME}', params:{raw:getTempResult('${invData.id}')}}"></div>`);
                ko.applyBindings(this, tempResultTester.get(0));
                tempResultTester.appendTo(retVal);
                return retVal;
            }
        };

        return retVal;

    });

    public getTempResult(id: string) {
        const invData = this._investigators.find(x => x.id === id);
        if (!invData) {
            return undefined;
        }
        const val = ko.pureComputed({
            read: () => {
                const curTempResult = invData.temporaryResult && invData.temporaryResult.serializedValue || '';
                return this.InvValues.get(invData.id) || curTempResult;
            },
            write: (val) => {
                if (!val) {
                    this.InvValues.delete(invData.id);
                } else {
                    this.InvValues.set(invData.id, val);
                }
            },
        });
        return val;
    }

    private readonly InvValues = new KoMap<string>();
    private readonly evalResults = new Map<string, EVAL['handsOnEdit']['validateFormulas'][0]>();

    private async evaluateNoRefresh() {
        this.evalResults.clear();
        const fields: API.IFormulaFieldValueInput[] = [];
        const formulas = new Set<string>();

        for (const inv of this._investigators) {
            const invId = inv.id;
            if (this.InvValues.has(invId)) {
                fields.push({
                    id: inv.investigatorId,
                    serializedValue: this.InvValues.get(invId)
                });
            } else {
                const serializedValue = inv.temporaryResult && inv.temporaryResult.serializedValue;
                if (serializedValue) {
                    fields.push({
                        id: inv.investigatorId,
                        serializedValue
                    });
                }
            }
        }

        for (const e of this._evaluations) {
            formulas.add(e.formula);
        }

        const result = await ServerConnection.api.inapplication_edit_eval({
            fields,
            formulas: Array.from(formulas)
        });
        for (const r of result.handsOnEdit.validateFormulas) {
            this.evalResults.set(r.formula, r);
        }

    }
    private readonly evaluate = new UIAction(undefined, async () => {
        await this.evaluateNoRefresh();
        await this.refreshGrid();
    });

    public readonly evaluationGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            discriminator: 'evaluations',
            widget: this,
            gridVar: this.evaluationsGrid,
            config: {
                wordWrapEnabled: true,
                noDataText: i18n.t(['itemdefinition.kosovo.handson.edit.THERE_ARE_NO_EVALUATIONS_YET']),
                allowColumnResizing: true,
                keyExpr: 'evaluationId',
                dataSource: this._evaluations,
                onRowUpdating: e => {
                    e.cancel = (async () => {
                        await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                            params: {
                                itemId: this.itemId,
                                upsertEvaluations: [{
                                    evaluationId: e.key,
                                    score: e.newData.score,
                                    formula: e.newData.formula
                                }]
                            }
                        }));
                    })();
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',

                },

                onToolbarPreparing: (e) => this.prepareEvaluationsToolbar(e),
            }
        });
        retVal.columns.push(
            {
                dataField: 'evaluationId',
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.ID']),
                allowEditing: false,
                width: 100
            });
        retVal.columns.push(
            {
                dataField: 'score',
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.SCORE']),
                width: 50,
            });
        retVal.columns.push(
            {
                dataField: 'formula',
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.FORMULA']),
                editCellTemplate: (container, cellInfo) => {
                    try {
                        const options = this.getEvaluationFormulaOptions(cellInfo.row.key);
                        const parsedFormula = JSON.parse(cellInfo.value || '[]');
                        options.value = parsedFormula;
                        options.onValueChanged = (e) => {
                            const newFormula = JSON.stringify(e.value, null, 2);
                            cellInfo.setValue(newFormula);
                        };
                        const div: any = $('<div/>');
                        const x = new dxFilterBuilder(div, options);
                        div.appendTo(container);
                    } catch (e) {
                        const div = $('<div/>');
                        const x = new dxTextArea(div, {
                            value: cellInfo.value,
                            autoResizeEnabled: true,
                            onValueChanged: e => {
                                cellInfo.setValue(e.value);
                            }
                        });
                        div.appendTo(container);
                    }
                },
                calculateDisplayValue: (x: EVALUATION) => {
                    try {
                        if (!x.formula) {
                            return '';
                        }
                        const val = this.evalResults.get(x.formula);
                        if (!val) {
                            return 'Not evaluated yet';
                        } else {
                            return formatEvalResult(val);
                        }
                    } catch (e) {
                        return e.message;
                    }
                },
                encodeHtml: false,
            });
        retVal.onRowRemoving = (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeEvaluations: [e.key]
                    }
                }));
            })();
        };

        return retVal;
    });


    public readonly fileGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            discriminator: 'files',
            widget: this,
            gridVar: this.filesGrid,
            config: {
                noDataText: i18n.t(['itemdefinition.kosovo.handson.edit.THERE_ARE_NO_FILES_YET']),
                allowColumnResizing: true,
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'fileId',
                        data: this._files
                    }
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
                onToolbarPreparing: (e) => this.prepareFilesToolbar(e),
            }
        });
        retVal.columns.push(
            {
                dataField: 'fileId',
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.ID']),
                width: 100,
                allowEditing: false
            });
        retVal.columns.push(
            {
                dataField: 'path',
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.PATH']),
                editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                    hint: i18n.t(['itemdefinition.kosovo.handson.edit.PATH_IN_FORM_OF_H_FOLDER_SUBFOLDER_FILE_TXT']),
                    placeholder: 'H:/file.jpg'
                }),

                validationRules: [{
                    type: 'pattern',
                    pattern: /^[Hh][:][/][-_a-zA-Z0-9./]+/,
                    message: i18n.t(['itemdefinition.kosovo.handson.edit.PATH_MUST_START_WITH_H_AND_ONLY_CONTAIN_LETTERS_AND_NUMBERS'])
                }],
                allowEditing: true,
            });
        retVal.columns.push(
            {
                dataField: 'resourceName',
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.RESOURCE_NAME']),
                allowEditing: true,
                lookup: {
                    dataSource: this._attachments,
                    valueExpr: 'name',
                    displayExpr: 'name',
                    allowClearing: true,
                },
            });
        retVal.columns.push(
            {
                dataField: 'userCreated',
                width: 100,
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.USER_CREATED']),
                allowEditing: true
            });
        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertFiles: [{
                            fileId: e.key,
                            resourceName: HELPER.emptyStringWhenSet(e.newData, 'resourceName'),
                            path: e.newData.path,
                            userCreated: e.newData.userCreated
                        }]
                    }
                }));
            })();
        };
        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeFiles: [e.key]
                    }
                }));
            })();
        };

        return retVal;
    });

    public readonly preparatorGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            discriminator: 'preparators',
            widget: this,
            gridVar: this.preparatorGrid,
            config: {
                noDataText: i18n.t(['itemdefinition.kosovo.handson.edit.THERE_ARE_NO_PREPARATORS_YET']),
                allowColumnResizing: true,
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'preparatorId',
                        data: this._preparators
                    }
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: this.canEdit,
                    allowUpdating: false,
                    mode: 'popup',
                },
                onToolbarPreparing: (e: any) => this.preparePreparatorToolbar(e),
            }
        });
        retVal.columns.push(
            {
                dataField: 'preparatorId',
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.ID']),
                width: 100,
            });
        retVal.columns.push(
            {
                dataField: 'preparatorUri',
                allowEditing: false,
                caption: i18n.t(['itemdefinition.kosovo.handson.edit.PREPARATOR']),
                calculateDisplayValue: (e: PREPERATOR) => {
                    const sp = allEnrichmentServiceProviders().find(x => x.id === e.serviceProviderId);
                    if (!sp) {
                        return e.preparatorUri;
                    }
                    const i = sp.preparatorDescriptions.find(x => x.id === e.preparatorUri);
                    if (!i) {
                        return e.preparatorUri;
                    }
                    return `${i.name.value} (${i.id})`;
                }
            });
        retVal.onRowRemoving = (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.inapplicatoin_edit_update({
                    params: {
                        itemId: this.itemId,
                        removePreparators: [e.key]
                    }
                }));
            })();
        };
        retVal.masterDetail = {
            enabled: true,
            template: (elem, info) => {
                const retVal: any = $('<div />');
                new dxForm(retVal, this.getPreparatorDetailOptions(info.data));
                return retVal;
            }
        };

        return retVal;
    });
    public readonly header = ko.observable('');

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.handson.edit.ENTER_THE_TITLE_HERE_OPTIONAL']);
    });
    public readonly question = ko.observable('');

    public readonly evalInfoPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.handson.edit.DEFINE_THE_EVALUATION_CRITERIA_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

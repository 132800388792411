import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'Instruction_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

export class InstructionData extends ItemDataModel {
    public readonly headerText: string;
    public readonly questionHTML: string;
    constructor(readonly meta: ItemModel, readonly data: DATA) {
        super();
        this.headerText = data.header.value;
        this.questionHTML = RICHTEXTHTML.process({
            html: data.instruction.value,
            attachments: this.meta.attachments,
        });

    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'Instruction_QueryItemSessionData') {
            throw new Error();
        }

    }

    public readonly isDone = ko.observable(false);
    public async setDone() {
        this.isDone(true);
    }

    public IsInteractionStarted() {
        return this.isDone();
    }
    public IsInteractionComplete() {
        return this.isDone();
    }
    public IsInitialData() {
        return true;
    }

    public async reset() {
        this.isDone(false);
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            Instruction: {
                isDone: this.isDone(),
            }
        };
        return retVal;
    }
}

import { ObjectiveFieldPartTemplate } from './ObjectiveFieldPartTemplate';

export class ObjectiveOverviewBodyFieldTemplate {
    public readonly parts: ObjectiveFieldPartTemplate[];
    constructor(readonly template: string, readonly cssClass: string) {
        const parts = template.split(/\{([a-zA-Z]+)\}/g);
        this.parts = parts.map((v, index) => {
            if (index % 2 == 0) {
                if (!v) {
                    return undefined;
                }
                return new ObjectiveFieldPartTemplate('', v);
            }
            switch (v) {
                case 'id':
                case 'title':
                case 'pointsGained':
                case 'pointsPending':
                case 'pointsLost':
                case 'pointsMax':
                case 'pointsPercent':
                case 'score':
                    return new ObjectiveFieldPartTemplate(v, '');
                default:
                    return new ObjectiveFieldPartTemplate('', v);
            }
        }).filter(x => !!x);
    }
}

import * as ko from 'knockout';
import { DONE } from '../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { AbstractItemDefinition } from '../base_itemdefinition';
import * as WIDGET_HEADER from '../kosovo/widgets/header/widget';
import { InstructionData } from '../model/instruction/InstructionData';
import { GetSession } from '../model/session';
import { htmlString } from './widget.html.g';

export type IParams = IItemDefinitionWidgetParams;

const WIDGET_NAME = 'itemdefinition-instruction';

export class MyModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly data: InstructionData;

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;

        const item = GetSession(this.params.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof InstructionData)) {
            throw new Error();
        }
        this.data = data;
        this.mode(params.mode);

    }

    public async OnReset() {
        await this.data.setDone();
        return DONE;
    }
    public async initialize() {
        await super.initialize();
        await this.data.setDone();
    }

    public readonly headerParams = ko.pureComputed(() => {
        const retVal: WIDGET_HEADER.IParams = {
            questionHTML: this.questionHTML(),
            headerText: this.headerText(),
            mode: this.mode()
        };
        return retVal;
    });
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly mode = ko.observable<ItemMode>();

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { assertNever } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { Privilege } from '../../../its-itembank-api.g';
import { stringCompare } from '../../../tree';
import { ServerConnection } from '../../RestAPI';
import { Intent, UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './selectsubject.html.g';
type Q = Awaited<ReturnType<API.Sdk['ui_selectsubject_init']>>;
type SUBJECT = Q['subjects'][0];

const WIDGET_NAME = 'ui-widgets-selectsubject';
const WIDGET_PARENT_NAME = 'ui-widgets';

export type ROLE = 'admin' | 'subjectcoordinator' | 'author' | 'superuser' | 'evaluator' | 'translator';

function ROLEtoSCHEMA(role: ROLE): Privilege {
    switch (role) {
        case 'admin':
            return Privilege.Admin;
        case 'author':
            return Privilege.AuthorSubjects;
        case 'superuser':
            return Privilege.Superuser;
        case 'subjectcoordinator':
            return Privilege.SubjectcoordinatorSubjects;
        case 'evaluator':
            return Privilege.EvaluatorSubjects;
        case 'translator':
            return Privilege.TranslatorSubjects;
        default:
            assertNever(role);
            throw new Error();
    }
}

export interface IParams {
    intent: Intent<string>;
    role: ROLE;
    itemsForManualGrading?: boolean,
    itemsForBulkGrading?: boolean,
}
class VMSubject {
    constructor(readonly model: ViewModel, readonly info: SUBJECT) {
    }
    public readonly select = new UIAction(undefined, async () => {
        await this.model.params.intent(
            this.info.docReferenceId
        );
    });
    public readonly imgUrl = ko.pureComputed(() => {
        return ServerConnection.getDataUrl(this.info.image && this.info.image.hrefResolved);
    });
    public readonly color = ko.pureComputed(() => {
        return this.info.color;
    });


    public readonly subjectCoordinatorText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.selectsubject.SUBJECT_COORDINATOR']);
    });
    public readonly authorText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.selectsubject.AUTHORS']);
    });

    public readonly hasNote = ko.pureComputed(() => !!this.note());
    public readonly note = ko.pureComputed(() => {
        if (this.model.params.itemsForBulkGrading) {
            if (this.info.itemsForBulkGrading && this.info.itemsForBulkGrading.length > 0) {
                return i18next.t(['ui.widgets.selectsubject.THERE_ARE_ITEMS_THAT_NEED_TO_BE_EVALUATED']);
            }
        }
        if (this.model.params.itemsForManualGrading) {
            if (this.info.itemsForManualGrading && this.info.itemsForManualGrading.length > 0) {
                return i18next.t(['ui.widgets.selectsubject.THERE_ARE_SESIONS_THAT_NEED_TO_BE_EVALUATED']);
            }
        }
        return '';
    });

}
export class ViewModel extends AbstractWidget {
    private readonly subjectData = ko.observable<VMSubject[]>();
    constructor(readonly params: IParams) {
        super();

    }

    public readonly noSubjectsAvailableText = ko.pureComputed(() => {
        return i18next.t(['ui.widgets.selectsubject.THERE_ARE_NO_SUBJECTS_AVAILABLE']);
    });


    public readonly loaded = ko.observable(false);
    public readonly loading = ko.pureComputed(() => !this.loaded());
    public async initialize() {
        await super.initialize();

        const r = await ServerConnection.api.ui_selectsubject_init({
            role: ROLEtoSCHEMA(this.params.role),
            itemsForManualGrading: this.params.itemsForManualGrading || false,
            itemsForBulkGrading: this.params.itemsForBulkGrading || false,
        });
        const subjects = r.subjects.map(x => new VMSubject(this, x));
        subjects.sort((a, b) => stringCompare(a.info.title, b.info.title));
        this.subjectData(subjects);

        this.loaded(true);
    }
    public readonly subjects = ko.pureComputed(() => {
        return /*this.filteredSubjects() ||*/ this.subjectData();
    });

    public readonly noSubjects = ko.pureComputed(() => {
        const subjects = this.subjects();
        if (!subjects || subjects.length === 0) {
            return true;
        }
        return false;
    });

    /*
    public readonly shouldFilterSubjects = ko.observable(!!this.params.documentReferenceIds);
    public readonly filteredSubjects = ko.pureComputed(() => {
        if (!this.shouldFilterSubjects()) {
            return undefined;
        }
        return this.subjectData().filter(sub => {
            if (this.params.documentReferenceIds.indexOf(sub.info.docReferenceId) >= 0) {
                return true;
            }
            return false;
        });
    });
    */
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { getGolemData } from '../../Gremlin';
import * as HELPER from '../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { getRandomEntry } from '../../new_array';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { GetSession } from '../model/session';
import { SurveyTextInputData } from '../model/surveytextinput/SurveyTextInputData';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const GREMLIN = {
    Quotes: [
        'There are no secrets to success. It is the result of preparation, hard work, and learning from failure.',
        'Education is not just about going to school and getting a degree. It\'s about widening your knowledge and absorbing the truth about life.'
    ]
};


const WIDGET_NAME = 'itemdefinition-surveytextinput';
const WIDGET_PARENT_NAME = 'itemdefinition';

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SurveyTextInputData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: SurveyTextInputData;

    public readonly surveyOptionText = ko.pureComputed(() => {
        switch (this.data.option) {
            case 'Required':
                return '';
            case 'Optional':
                return i18next.t(['itemdefinition.surveytextinput.YOU_DO_NOT_NEED_TO_ANSWER_THIS_ITEM']);
            default:
                return '';
        }
    });

    private gremlinState: {
        autoText: String;
    };

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        const currentText = this.text() || '';
        if (!this.gremlinState) {
            const data = getGolemData(this.itemId);
            if (typeof data === 'string') {
                this.gremlinState = {
                    autoText: data
                };
            } else {
                this.gremlinState = {
                    autoText: getRandomEntry(GREMLIN.Quotes)
                };
            }
        }
        const text = this.gremlinState.autoText;
        if (currentText.length < text.length) {
            this.text(text.substr(0, currentText.length + 5));
            return true;
        }
        return false;

    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT' || mode === 'GRADING') {
            throw new Error(`${WIDGET_NAME} - no edit or grading mode supported!`);
        }

        await this.OnRefresh();

        //Init _text
        this._text(this.data.essayText());
        if (this.data.option === 'Optional') {
            this.data.done(true);
        }
        this.onChange(this._text, `${WIDGET_NAME}/${this.itemId}/text`, async val => {
            await this.data.setAnswer(val);
            return HELPER.DONE;
        }, {
            triggerRefresh: false
        });

        this.loaded(true);
    }

    public readonly essayPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveytextinput.ENTER_THE_TEXT_HERE']);
    });

    private readonly _text = ko.observable<string>().extend({ rateLimit: { method: 'notifyWhenChangesStop', timeout: 500 } });

    public readonly text = ko.pureComputed({
        read: () => {
            return this._text();
        },
        write: newValue => {
            this._text(newValue);
        },
        owner: this
    });

    public readonly isTextAreaDisabled = ko.pureComputed(() => {
        const mode = this.mode();

        if (mode !== 'INTERACTIVE') {
            return true;
        }
        return false;
    });

    public readonly wordCount = ko.pureComputed(() => {
        const text = this._text();
        if (!text) { return 0; }
        return text.trim().split(/\s+/).length;
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

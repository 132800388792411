import * as ko from 'knockout';
import { log } from '../../debug';
import * as HELPER from '../../helper';
import { bounds } from '../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { UIAction } from '../../ui/uiAction';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { AreaRectModel, MultistepHotspotData, MultistepHotspotData_Answer } from '../model/multistephotspot/MultistepHotspotData';
import { GetSession } from '../model/session';
import * as i18n from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-multistephotspot';

export type IParams = IItemDefinitionWidgetParams;

export class Rect {
    constructor(readonly model: MyModel, readonly rect: AreaRectModel) {

    }
    public actionSelect(data: unknown, ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this._actionSelect.click();
    }
    public readonly attr = ko.pureComputed(() => {
        const area = this.rect;
        return {
            x: `${area.left}%`,
            y: `${area.top}%`,
            width: `${area.width}%`,
            height: `${area.height}%`,
        };
    });
    public readonly cssClass = ko.pureComputed(() => {
        const zone = this.rect.parent;
        const classes = [`zone-${zone.zoneId}`];
        if (this.rect.parent.stepLinkId) {
            classes.push('linkedZone');
        } else {
            classes.push('area');
        }
        if (this.model.mode() == 'INTERACTIVE') {
            classes.push('interactive');
        }

        return classes.join(' ');
    });
    private readonly _actionSelect = new UIAction(undefined, async () => {
        if (this.model.mode() !== 'INTERACTIVE') {
            return;
        }
        if (!this.rect.parent.stepLinkId) {
            throw new Error("Stupid programmer");
        }
        this.model.data.given.stepForward({ stepId: this.rect.parent.stepLinkId });
    });
}
export class Marker {
    constructor(readonly model: MyModel, readonly answer: MultistepHotspotData_Answer) {

    }
    public readonly cssClass = ko.pureComputed(() => {
        if (this.model.mode() === 'INTERACTIVE') {
            return '';
        }
        const correct = this.answer.correct();
        if (!correct) {
            return '';
        }
        switch (correct) {
            case 'correct':
            case 'incorrect':
                return correct;
        }
        return '';
    });

    public readonly cx = ko.pureComputed(() => {
        return this.answer.x() + '%';

    });
    public readonly cy = ko.pureComputed(() => {
        return this.answer.y() + '%';

    });
    public readonly r = ko.pureComputed(() => {
        return this.model.data.markerRadius + '%';
    });
    public actionRemove(data: unknown, ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this._actionRemove.click();
    }
    private readonly _actionRemove = new UIAction(undefined, async () => {
        if (this.model.mode() !== 'INTERACTIVE') {
            return;
        }
        this.model.data.given.x(undefined);
        this.model.data.given.y(undefined);
    });
}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    public readonly currentStep = ko.pureComputed(() => {
        const stepId = this.data.currentStep();
        if (!stepId) {
            return undefined;
        }
        return this.data.steps.find(x => x.stepId === stepId);
    });

    public readonly givenAnswer = ko.pureComputed(() => {
        const n = (n: number | undefined) => {
            if (typeof n !== 'number') {
                return '-';
            }
            const p = Math.round(n * 10) / 10;
            return `${p}%`;
        };
        const params = {
            x: n(this.data.given.x()),
            y: n(this.data.given.y()),
            steps: this.data.given.steps().join(','),
        };
        return i18n.t(['itemdefinition.multistephotspot.MARKER_POSITION_X_Y_STEPS_STEPS'], params);
    });

    public readonly actionReset = new UIAction(undefined, async () => {
        if (this.mode() !== 'INTERACTIVE') {
            return;
        }
        await this.data.reset();
    });
    public readonly actionStepBack = new UIAction(undefined, async () => {
        if (this.mode() !== 'INTERACTIVE') {
            return;
        }
        this.data.given.stepBack();
    }, {
        isEnabled: () => this.data.given.canStepBack()
    });
    public readonly actionStepBackLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.multistephotspot.ONE_STEP_BACK']);
    });
    public readonly actionResetLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.multistephotspot.RESET']);
    });
    public rects = ko.pureComputed(() => {
        const retVal: Rect[] = [];
        const isInteractive = this.mode() === 'INTERACTIVE';
        const step = this.currentStep();
        if (step) {
            for (const zone of step.zones) {
                if (!zone.stepLinkId) {
                    if (isInteractive) {
                        continue;
                    }
                }
                for (const area of zone.areas) {
                    retVal.push(new Rect(this, area));
                }
            }
        }
        return retVal;
    });

    public readonly marker = ko.pureComputed(() => {
        const retVal = [];
        if (this.data.given.x() && this.data.given.y()) {
            retVal.push(new Marker(this, this.data.given));
        }
        return retVal;
    });

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof MultistepHotspotData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly hint = ko.pureComputed(() => {
        const hintText: string[] = [];
        return hintText.join('\n');
    });
    public clickImage(data: unknown, event: MouseEvent) {
        if (this.mode() !== 'INTERACTIVE') {
            return;
        }
        const w = this.imageWidth();
        const h = this.imageHeight();
        const x = bounds(event.offsetX, 0, w) * 100 / w;
        const y = bounds(event.offsetY, 0, h) * 100 / h;
        this.data.given.setMarker({ x, y });
    }
    public readonly data: MultistepHotspotData;

    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                HELPER.assertNever(mode);
                throw new Error();

        }
    });

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });



    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        return false;
        /*
        if (this.shapes.some(x => x.answer.isSelected())) {
            return false;
        }
        const golemData = getGolemData(this.itemId);
        let pickedId: string;
        if (typeof golemData === 'string') {
            pickedId = golemMatch(this.shapes.map(x => ({
                key: x.answer.id,
                value: x.answer.shape
            })), golemData);

        }
        if (!pickedId) {
            log(`Golem has no suggestion for ${this.itemId}. Pick a random one.`);
            pickedId = getRandomEntry(this.shapes).answer.id;
        }
        const pick = this.shapes.find(x => x.answer.id === pickedId);
        await pick.select.invoke(undefined, true);
        */
        return true;
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();

        this.loaded(true);
    }

    public readonly imgUrl = ko.pureComputed(() => {
        const step = this.currentStep();
        return step && step.imgUrl;
    });
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());


    public readonly imageWidth = ko.pureComputed(() => {
        const step = this.currentStep();
        if (!step) {
            return undefined;
        }
        return step.imageWidth;
    });
    public readonly imageHeight = ko.pureComputed(() => {
        const step = this.currentStep();
        if (!step) {
            return undefined;
        }
        return step.imageHeight;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME),
});

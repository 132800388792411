import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as YAML from 'js-yaml';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { writeToClipboard } from '../../../clipboard';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import * as API from '../../../its-itembank-api.g';
import { ExaminationTypeEnum } from '../../../its-itembank-api.g';
import { confirmYesNo } from '../../../modal';
import { DefaultWindowManager } from '../../base';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import { UIAction } from '../../uiAction';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './examinationoverview.html.g';
import { IParams, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_examinationoverview_data']>>;

const WIDGET_PARENT_NAME = 'ui-superuser';

function examTypeName(type: ExaminationTypeEnum) {
    return i18next.t(TRANSLATIONS.ExaminationTypeString[type]);
}

type DATA = Q['examination']['all'][0] & { enabled: boolean };

export class ViewModel extends DxWidget {
    private readonly _examinations: DATA[] = [];

    public readonly userAgentString = ko.observable('');
    public readonly launchDemoExam = new UIAction<DATA>(undefined, async (e, args) => {
        const url = args.demoUrl;
        DefaultWindowManager.prepareOpenWindow('');
        DefaultWindowManager.openWindow(url, args.examinationId);
    });
    public readonly exportExam = new UIAction<DATA>(undefined, async (e, args) => {
        const url = ServerConnection.getExportUrl('examination', args.docReferenceId);
        DefaultWindowManager.downloadFile(url);
        return null;
    });
    public readonly previewExam = new UIAction<DATA>(undefined, async (e, args) => {
        if (args.obsolete) {
            alert(i18next.t(['ui.superuser.examinationoverview.THE_EXAM_IS_NOT_ACTIVE']));
            return;
        }
        if (!args.passesRestrictions) {
            alert(i18next.t(['ui.superuser.examinationoverview.YOUR_COMPUTER_BROWSER_DOES_NOT_FULLFILL_THE_REQUIREMENTS']));
            return;
        }
        DefaultWindowManager.prepareOpenWindow('');
        const examId = args.docReferenceId;
        const externalId = ['preview', this.username(), ... new Date().toISOString().replace(/[-T:]/g, '.').split('.')].join('.');
        const d = await ServerConnection.api.ui_superuser_examinationoverview_preview({
            examId,
            externalId,
            userAgentString: window.navigator.userAgent,
        });
        DefaultWindowManager.openWindow(d.newExamSession.href, '');
    });

    public readonly actionCopyUrl = new UIAction<DATA>(undefined, async (e, args) => {
        const user = args;
        const demoUrl = ServerConnection.getDataUrl(user.demoUrl, undefined, false);
        await writeToClipboard(demoUrl);
    });
    public readonly actionEnable = new UIAction<DATA>(undefined, async (e, args) => {
        const user = args;
        if (!await confirmYesNo(i18next.t(['ui.superuser.examinationoverview.DO_YOU_WANT_TO_ENABLE_THE_EXAM']))) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.ui_superuser_examinationoverview_setobsolete({
                examIds: [user.docReferenceId],
                obsolete: false,
            });
        });
    });
    public readonly actionDisable = new UIAction<DATA>(undefined, async (e, args) => {
        if (!await confirmYesNo(i18next.t(['ui.superuser.examinationoverview.DO_YOU_WANT_TO_DISABLE_THE_EXAM']))) {
            return;
        }
        const user = args;
        await legacyPushPull(async () => {
            await ServerConnection.api.ui_superuser_examinationoverview_setobsolete({
                examIds: [user.docReferenceId],
                obsolete: true,
            });
        });
    });
    public readonly actionJSON = new UIAction<DATA>(undefined, async (e, args) => {
        const x = await ServerConnection.api.ui_superuser_examinationoverview_json({
            id: args.docReferenceId
        });
        const exam = x.examination.byId;
        const jsonString = exam && exam.json;
        await writeToClipboard(jsonString);
    });

    public readonly actionGolem = new UIAction<DATA>(undefined, async (e, args) => {
        const x = await ServerConnection.api.ui_superuser_examinationoverview_golem({
            id: [args.docReferenceId]
        });
        const exam = x.examination.get[0];
        const jsonString = exam && exam.golemScript;
        await writeToClipboard(jsonString);
    });

    private readonly grid = ko.observable<DevExpress.ui.dxDataGrid>();

    public readonly actionStatisticYaml = new UIAction(undefined, async (e, args) => {
        const grid = this.grid();
        if (!grid) {
            return;
        }
        const selected: DATA[] = await grid.getSelectedRowsData();
        const examOrAlias = selected.map(x => x.docReferenceId);
        const x = await ServerConnection.api.ui_superuser_examinationoverview_statisticyaml({
            options: {
                includeAccumulatedScore: true,
                includeItemRaw: true,
                includeItemScore: true,
                examOrAlias,
            }
        });
        await writeToClipboard(x.statisticexportconfig.getYamlConfig);
    });

    public readonly actionGolems = new UIAction(undefined, async (e, args) => {
        const grid = this.grid();
        if (!grid) {
            return;
        }
        const selected: DATA[] = await grid.getSelectedRowsData();
        const all = {};
        const x = await ServerConnection.api.ui_superuser_examinationoverview_golem({
            id: selected.map(x => x.docReferenceId)
        });
        for (const exam of x.examination.get) {
            const jsonString = exam && exam.golemScript;
            const j = YAML.load(jsonString);
            //const j = JSON.parse(jsonString);
            Object.assign(all, j);
        }
        //const jsonString = JSON.stringify(all, null, 2);
        const jsonString = YAML.dump(all, {});
        await writeToClipboard(jsonString);
    });

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.grid,
            config: {

                allowColumnResizing: true,
                columnChooser: {
                    enabled: true,
                },
                export: {
                    enabled: true,
                    fileName: 'exams'
                },
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true,
                },
                filterPanel: {
                    visible: true,
                },
                headerFilter: {
                    visible: true,
                },
                selection: {
                    mode: 'multiple',
                },
                groupPanel: {
                    visible: false
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: false,
                    allowUpdating: false,
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._examinations;
                        },
                        byKey: async key => {
                            return this._examinations.find(x => x.docReferenceId === key);
                        },
                    })
                }
            }
        });

        retVal.onToolbarPreparing = e => {
            const items = e.toolbarOptions.items;
            items.unshift(AS<DevExpress.ui.dxToolbar.Item>({
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    hint: i18next.t(['ui.superuser.examinationoverview.SET_CURRENT_USER_AGENT_AS_FILTER']),
                    icon: 'globe',
                    onClick: () => this.userAgentString(window.navigator.userAgent)
                })
            }));
            items.push(AS<DevExpress.ui.dxToolbar.Item>({
                widget: 'dxTextBox',
                location: 'before',
                visible: true,
                locateInMenu: 'auto',
                options: AS<DevExpress.ui.dxTextBox.Properties>({
                    placeholder: i18next.t(['ui.superuser.examinationoverview.USER_AGENT_STRING']),
                    value: <any>this.userAgentString,
                })
            }));
            items.push(AS<DevExpress.ui.dxToolbar.Item>({
                widget: 'dxButton',
                location: 'after',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    hint: i18next.t(['ui.superuser.examinationoverview.EXPORT_GOLEM_SCRIPT']),
                    icon: 'fas fa-robot',
                    onClick: this.actionGolems.click,
                }),
            }));
            items.push(AS<DevExpress.ui.dxToolbar.Item>({
                widget: 'dxButton',
                location: 'after',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    hint: i18n.t(['ui.superuser.examinationoverview.YAML_FOR_STATISTIC_EXPORT']),
                    icon: 'fas fa-blender',
                    onClick: this.actionStatisticYaml.click,
                }),
            }));

        };
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.EXAM_ID']),
            dataField: 'examinationId',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
            encodeHtml: false,
            calculateCellValue: data => {
                const x: DATA = data;
                return `<span class=select-all>${x.examinationId}</span>`;
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.EXAM_ALIAS']),
            dataType: 'string',
            encodeHtml: false,
            calculateCellValue: data => {
                const x: DATA = data;
                return x.examAliases.map(y => `<span class=select-all>${y}</span>`).join(', ');
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.EXAM_TYPE']),
            dataType: 'string',
            dataField: 'type',
            allowFiltering: true,
            allowHeaderFiltering: true,
            calculateDisplayValue: data => {
                const x: DATA = data;
                return examTypeName(x.type);
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.EXAM_ORDER']),
            dataType: 'string',
            dataField: 'examOrder.title',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.TOTAL_SCORE']),
            dataType: 'string',
            dataField: 'maxScore',
            width: 100,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.CREATION_DATE']),
            dataType: 'datetime',
            dataField: 'dateCreated',
            cssClass: 'itsr3-uitest-hide',
            width: 100,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.EXAM_URL_TYPE_PUBLIC']),
            dataType: 'string',
            dataField: 'demoUrl',

        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.PASSES_RESTRICTIONS']),
            dataType: 'boolean',
            dataField: 'passesRestrictions',

        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.ALTERNATIONS']),
            dataType: 'string',
            calculateCellValue: (rowData: DATA) => {
                return rowData.alternationRestrictions.map(x => x.alternation.taxon).join(' ');
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.ENABLED']),
            dataField: 'enabled',
            dataType: 'boolean',
            width: 40,
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examinationoverview.ID']),
            dataField: 'docReferenceId',
            dataType: 'string',
            visible: false,
        });


        retVal.columns.push({
            allowExporting: false,
            type: 'buttons',
            caption: i18next.t(['ui.superuser.examinationoverview.ACTIONS']),

            //width: 200,
            buttons: [
                {
                    text: i18next.t(['ui.superuser.examinationoverview.DISABLE']),
                    onClick: e => this.actionDisable.intent(e.row.data),
                    visible: e => !!e.row.data.enabled,
                },
                {
                    text: i18next.t(['ui.superuser.examinationoverview.ENABLE']),
                    onClick: e => this.actionEnable.intent(e.row.data),
                    visible: e => !e.row.data.enabled,
                },
                {
                    text: i18next.t(['ui.superuser.examinationoverview.EXPORT']),
                    visible: e => {
                        const data: DATA = e.row.data;
                        return data && data.type !== ExaminationTypeEnum.Adhoc;
                    },
                    onClick: e => this.exportExam.intent(e.row.data),
                },
                {
                    text: i18next.t(['ui.superuser.examinationoverview.PREVIEW']),
                    onClick: e => this.previewExam.intent(e.row.data),
                },
                {
                    text: i18next.t(['ui.superuser.examinationoverview.COPY_URL']),
                    onClick: e => this.actionCopyUrl.intent(e.row.data),
                    visible: e => !!e.row.data.demoUrl,
                    icon: 'far fa-copy',
                },
                {
                    text: i18next.t(['ui.superuser.examinationoverview.JSON']),
                    onClick: e => this.actionJSON.intent(e.row.data),
                },
                {
                    text: i18next.t(['ui.superuser.examinationoverview.CHEAT_SHEET']),
                    onClick: e => this.actionGolem.intent(e.row.data),
                    icon: 'fas fa-robot',
                },
            ],
        });
        retVal.filterValue = [['enabled', '=', true]];
        return retVal;
    });




    constructor(readonly params: IParams) {
        super();
    }

    public async OnRefresh() {
        await super.OnRefresh();
        const exams = await ServerConnection.api.ui_superuser_examinationoverview_data({
            userAgentString: this.userAgentString()
        });
        this._examinations.splice(0, this._examinations.length, ...exams.examination.all.map(x => {
            return Object.assign(x, { enabled: !x.obsolete });
        }));
        this.username(exams.whoAmI.user.userId);

        await refreshDx(this);

    }
    public readonly username = ko.observable('');

    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

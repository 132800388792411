import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxForm from 'devextreme/ui/form';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, datagrid, refreshDx, selectBoxDS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import { formatMessage } from '../../../../i18n/data';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { legacyPushPull, queueApiCall } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AddMissingAttachments } from '../../../helper';
import * as i18n from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['container_edit_data']>>;
type FILE = Q['containerEdit']['get']['filestructure'][0];
type ATTACHMENT = Q['documents']['get']['attachments'][0];
const WIDGET_NAME = 'itemdefinition-kosovo-container-edit';

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly itemDocId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly loaded = ko.observable(false);

    private readonly _files: FILE[] = [];
    private readonly _attachments: ATTACHMENT[] = [];

    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.container_edit_data({
            itemId: this.params.itemId
        });
        const d = data.containerEdit.get;
        this.data.handson = d;
        this._files.splice(0, this._files.length, ...d.filestructure);
        if (this._files.length > 0) { //if there are already files defined, we need to show them regardless the itemtype setting - even if it is just to delete it.
            this.supportsFileStructure(true);
        }
        this._attachments.splice(0, this._attachments.length, ...data.documents.get.attachments);

        AddMissingAttachments(this._attachments, [
            ...this._files.map(x => x.resourceName),
            d.attachmentFile1,
            d.attachmentFile2,
            d.attachmentFile3,
            d.attachmentFile4,
            d.attachmentFile5,
            d.image,
            d.audioFile,
            d.videoFile
        ]);
        this.instruction(d.instruction.value);
        this.header(d.header.value);

        await refreshDx(this);
        if (this.form) {
            this.form.repaint();
        }
    }

    private readonly filesGrid = ko.observable<DevExpress.ui.dxDataGrid>();

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.edit.ENTER_THE_TITLE_HERE_OPTIONAL']);
    });
    public readonly instruction = ko.observable('');
    public readonly header = ko.observable('');

    public readonly addFile = new UIAction(undefined, async () => {
        const currentKeys = new Set<string>(this._files.map(x => x.fileId));
        let key: string;
        for (let i = 1; ; ++i) {
            key = `F${i}`;
            if (!currentKeys.has(key)) {
                break;
            }
        }
        await legacyPushPull(() => ServerConnection.api.container_edit_update({
            params: {
                itemId: this.itemId,
                upsertFiles: [{
                    fileId: key,
                    userCreated: false,
                    path: ''
                }]
            }
        }));
    });
    prepareFilesToolbar(e: { model?: ViewModel, toolbarOptions?: DevExpress.ui.dxToolbar.Properties }) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    icon: 'add',
                    disabled: this.isReadOnly,
                    onClick: this.addFile.click
                })
            }
        );
    }

    public readonly fileGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            discriminator: 'files',
            gridVar: this.filesGrid,
            widget: this,
            config: {
                noDataText: i18n.t(['itemdefinition.kosovo.container.edit.THERE_ARE_NO_FILES_YET']),
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'fileId',
                        data: this._files
                    }
                },
                editing: {
                    allowAdding: false,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
                onToolbarPreparing: (e) => this.prepareFilesToolbar(e),
            }
        });
        retVal.columns.push(
            {
                dataField: 'fileId',
                caption: i18n.t(['itemdefinition.kosovo.container.edit.ID']),
                width: 100,
                allowEditing: false
            });

        retVal.columns.push(
            {
                dataField: 'path',
                caption: i18n.t(['itemdefinition.kosovo.container.edit.PATH']),
                editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                    hint: i18n.t(['itemdefinition.kosovo.container.edit.PATH_IN_FORM_OF_H_FOLDER_SUBFOLDER_FILE_TXT']),
                    placeholder: 'H:/file.jpg'
                }),

                validationRules: [{
                    type: 'pattern',
                    pattern: /^[Hh][:][/][-_a-zA-Z0-9./]+/,
                    message: i18n.t(['itemdefinition.kosovo.container.edit.PATH_MUST_START_WITH_H_AND_ONLY_CONTAIN_LETTERS_AND_NUMBERS'])
                }],
                allowEditing: true,
            });
        retVal.columns.push(
            {
                dataField: 'resourceName',
                caption: i18n.t(['itemdefinition.kosovo.container.edit.ASSET_NAME']),
                allowEditing: true,
                lookup: {
                    dataSource: {
                        store: this.attachmentsStore,
                    },
                    valueExpr: 'name',
                    displayExpr: 'name'
                },
            });
        retVal.columns.push(
            {
                dataField: 'userCreated',
                width: 100,
                caption: i18n.t(['itemdefinition.kosovo.container.edit.USER_CREATED']),
                allowEditing: true
            });
        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.container_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeFiles: [e.key]
                    }
                }));
            })();
        };

        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.container_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertFiles: [{
                            fileId: e.key,
                            resourceName: e.newData.resourceName,
                            path: e.newData.path,
                            userCreated: e.newData.userCreated
                        }]
                    }
                }));
            })();
        };
        return retVal;
    });

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.itemDocId = 'item,' + this.itemId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public async initialize() {
        this.attachmentsStore = new CustomStore({
            loadMode: 'raw',
            key: 'name',
            byKey: async key => {
                if (!this._attachments) {
                    return undefined;
                }
                return this._attachments.find(x => x.name === key);
            },
            load: async (options) => {
                return this._attachments;
            }
        });
        await super.initialize();
        await this.initToolTypes();
        await this.OnRefresh();

        this.onChange(this.instruction, `${WIDGET_NAME}/${this.itemId}/instruction`, async val => {
            await ServerConnection.api.container_edit_update({
                params: {
                    itemId: this.itemId,
                    instruction: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.container_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val),
                }
            });
            return DONE;
        });



        this.loaded(true);
    }

    public readonly supportsText = ko.observable(false);
    public readonly supportsFileStructure = ko.observable(false);
    public readonly supportsImage = ko.observable(false);
    public readonly supportsAudio = ko.observable(false);
    public readonly supportsVideo = ko.observable(false);
    public readonly supportsAttachments = ko.observable(false);

    private readonly orig: {
        handson?: Q['containerEdit']['get'],
    } = {};
    private readonly data: {
        handson?: Q['containerEdit']['get'],
    } = {};

    private form: DevExpress.ui.dxForm;

    private attachmentsStore: DevExpress.data.CustomStore;

    public readonly sections = ko.pureComputed(() => {
        const dataSource: DevExpress.ui.dxAccordionItemTemplate[] = [];
        const retVal: DevExpress.ui.dxAccordion.Properties = {
            collapsible: true,
            multiple: true,
            dataSource
        };
        dataSource.push({
            title: i18n.t(['itemdefinition.kosovo.container.edit.ASSET_TYPES']),
            template: () => {
                const retVal: any = $('<div />');
                new dxForm(retVal, this.formOptions());
                return retVal;
            }
        });
        if (this.supportsFileStructure()) {
            dataSource.push({
                title: i18n.t(['itemdefinition.kosovo.container.edit.FILE_STRUCTURE']),
                template: () => {
                    const retVal: any = $('<div />');
                    new dxDataGrid(retVal, this.fileGridOptions());
                    return retVal;
                }
            });
        }
        return retVal;
    });

    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            items: [],
            formData: this.data,
            onInitialized: e => {
                this.form = e.component;
            },
            onDisposing: e => {
                this.form = undefined;
            },
            onFieldDataChanged: e => {
                const params: API.IContainerEditUpsertInput = {
                    itemId: this.itemId
                };
                switch (e.dataField) {
                    case 'handson.textBlock.value':
                        params.textBlock = xnone(<string>e.value);
                        break;
                    case 'handson.textBlockFormat':
                        params.textBlockFormat = e.value;
                        break;
                    case 'handson.onlineVideoLink':
                        params.onlineVideoLink = e.value;
                        break;
                    case 'handson.image':
                        params.image = <string>e.value || '';
                        break;
                    case 'handson.audioFile':
                        params.audioFile = <string>e.value || '';
                        break;
                    case 'handson.videoFile':
                        params.videoFile = <string>e.value || '';
                        break;
                    case 'handson.attachmentFile1':
                        params.attachmentFile1 = <string>e.value || '';
                        break;
                    case 'handson.attachmentFile2':
                        params.attachmentFile2 = <string>e.value || '';
                        break;
                    case 'handson.attachmentFile3':
                        params.attachmentFile3 = <string>e.value || '';
                        break;
                    case 'handson.attachmentFile4':
                        params.attachmentFile4 = <string>e.value || '';
                        break;
                    case 'handson.attachmentFile5':
                        params.attachmentFile5 = <string>e.value || '';
                        break;
                    default:
                        throw new Error(`Unhandled field ${e.dataField}`);
                }
                queueApiCall(`${WIDGET_NAME}/${this.itemId}/${e.dataField}`, () => ServerConnection.api.container_edit_update({
                    params
                }), true);
            }
        };

        const tools = AS<DevExpress.ui.dxFormTabbedItem>({
            itemType: 'tabbed',
            tabs: []
        });
        retVal.items.push(tools);

        if (this.supportsText()) {
            const items: DevExpress.ui.dxForm.SimpleItem[] = [];
            tools.tabs.push({
                title: i18n.t(['itemdefinition.kosovo.container.edit.TEXT']),
                items
            });
            items.push({
                dataField: 'handson.textBlockFormat',
                label: {
                    text: i18n.t(['itemdefinition.kosovo.container.edit.FORMAT'])
                },
                editorType: 'dxSelectBox',
                editorOptions: selectBoxDS([
                    {
                        key: API.KosovoContainer_TextBlockFormat.Html,
                        value: i18n.t(['itemdefinition.kosovo.container.edit.HTML'])
                    },
                    {
                        key: API.KosovoContainer_TextBlockFormat.PlainText,
                        value: i18n.t(['itemdefinition.kosovo.container.edit.TEXT_NO_FORMATTING'])
                    },
                    {
                        key: API.KosovoContainer_TextBlockFormat.PythonSource,
                        value: i18n.t(['itemdefinition.kosovo.container.edit.PYTHON_SOURCE_CODE'])
                    },
                    {
                        key: API.KosovoContainer_TextBlockFormat.HtmlSource,
                        value: i18n.t(['itemdefinition.kosovo.container.edit.HTML_SOURCE_CODE'])
                    },
                    {
                        key: API.KosovoContainer_TextBlockFormat.CssSource,
                        value: i18n.t(['itemdefinition.kosovo.container.edit.CSS_SOURCE_CODE'])
                    },
                    {
                        key: API.KosovoContainer_TextBlockFormat.CLikeSource,
                        value: i18n.t(['itemdefinition.kosovo.container.edit.C_LIKE_SOURCE_CODE'])
                    },
                    {
                        key: API.KosovoContainer_TextBlockFormat.JavaScriptSource,
                        value: i18n.t(['itemdefinition.kosovo.container.edit.JAVASCRIPT_SOURCE_CODE'])
                    },
                ])
            });
            items.push({
                dataField: 'handson.textBlock.value',
                label: {
                    location: 'top',
                    text: i18n.t(['itemdefinition.kosovo.container.edit.TEXT'])
                },
                editorType: 'dxTextArea',
                editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                    autoResizeEnabled: true,
                })
            });
        }


        const attachmentOptions: DevExpress.ui.dxSelectBox.Properties = {
            dataSource: {
                store: this.attachmentsStore,
            },
            valueExpr: 'name',
            displayExpr: 'name',
            showClearButton: true,
        };


        if (this.supportsImage()) {
            const items: DevExpress.ui.dxForm.SimpleItem[] = [];
            tools.tabs.push({
                title: i18n.t(['itemdefinition.kosovo.container.edit.IMAGE']),
                items
            });
            items.push({
                label: {
                    text: i18n.t(['itemdefinition.kosovo.container.edit.IMAGE'])
                },
                dataField: 'handson.image',
                editorType: 'dxSelectBox',
                editorOptions: attachmentOptions,
            });
        }

        if (this.supportsAudio()) {
            const items: DevExpress.ui.dxForm.SimpleItem[] = [];
            tools.tabs.push({
                title: i18n.t(['itemdefinition.kosovo.container.edit.AUDIO']),
                items
            });
            items.push({
                label: {
                    text: i18n.t(['itemdefinition.kosovo.container.edit.AUDIO'])
                },
                dataField: 'handson.audioFile',
                editorType: 'dxSelectBox',
                editorOptions: attachmentOptions,
            });

        }
        if (this.supportsVideo()) {
            const items: DevExpress.ui.dxForm.SimpleItem[] = [];
            tools.tabs.push({
                title: i18n.t(['itemdefinition.kosovo.container.edit.VIDEO']),
                items
            });
            items.push({
                label: {
                    text: i18n.t(['itemdefinition.kosovo.container.edit.VIDEO_LINK_YOUTUBE']),
                },
                dataField: 'handson.onlineVideoLink',
                editorType: 'dxTextBox',
                editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                }),
                validationRules: [{
                    type: 'pattern',
                    pattern: /^https:\/\/www\.youtube\.com\/watch\?v=\S+$/,
                    message: i18n.t(['itemdefinition.kosovo.container.edit.YOU_NEED_TO_COPY_THE_YOUTUBE_EMBED_LINK_E_G_HTTPS_WWW_YOUTUBE_COM_WATCH_V_ABCDEF']),
                }],
            });
            items.push({
                label: {
                    text: i18n.t(['itemdefinition.kosovo.container.edit.VIDEO'])
                },
                dataField: 'handson.videoFile',
                editorType: 'dxSelectBox',
                editorOptions: attachmentOptions,
            });
        }

        if (this.supportsAttachments()) {
            const items: DevExpress.ui.dxForm.SimpleItem[] = [];
            tools.tabs.push({
                title: i18n.t(['itemdefinition.kosovo.container.edit.ATTACHMENTS']),
                items
            });

            for (let i = 1; i <= 5; ++i) {
                items.push({
                    label: {
                        text: formatMessage(i18n.t(['itemdefinition.kosovo.container.edit.ATTACHMENT_NR']), { nr: i })
                    },
                    dataField: `handson.attachmentFile${i}`,
                    editorType: 'dxSelectBox',
                    editorOptions: attachmentOptions,
                });
            }
        }
        return retVal;
    });

    private async initToolTypes() {
        const settings = await ServerConnection.api.container_settings({});
        this.supportsText(settings.itemtype.kosovo.container.supportsText);
        this.supportsFileStructure(settings.itemtype.kosovo.container.supportsFileStructure);
        this.supportsImage(settings.itemtype.kosovo.container.supportsImage);
        this.supportsAudio(settings.itemtype.kosovo.container.supportsAudio);
        this.supportsVideo(settings.itemtype.kosovo.container.supportsVideo);
        this.supportsAttachments(settings.itemtype.kosovo.container.supportsAttachments);
    }

    public async confirmSwitch(targetTool: string) {
        const message = formatMessage(i18n.t(['itemdefinition.kosovo.container.edit.ASSET_IS_CHANGED_TO_TARGETTOOL_ARE_YOU_SURE']), { targetTool });
        return await Modal.confirmYesNo(message);
    }



    public readonly imageDragLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.edit.DRAG_IMAGE_HERE']);
    });
    public readonly imageFileSizeWarning = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.edit.MAXIMUM_IMAGE_FILE_SIZE_1MB']);
    });

    public readonly videoDragLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.edit.DRAG_VIDEO_FILE_HERE']);
    });
    public readonly videoFileSizeWarning = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.edit.MAXIMUM_VIDEO_FILE_SIZE_5MB']);
    });

    public readonly audioDragLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.edit.DRAG_AUDIO_FILE_HERE']);
    });
    public readonly audioFileSizeWarning = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.edit.MAXIMUM_AUDIO_FILE_SIZE_3MB']);
    });

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                instruction: this.instruction,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.container.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.container.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.container.edit.INSTRUCTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'instruction',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.container.edit.ENTER_THE_QUESTION_INSTRUCTION_TEXT_HERE'])
        }));
        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import jquery from 'jquery';
import * as ko from 'knockout';
import './browser.import.g';
import './libs';
import './index.html.g';
import { EntryApp } from "./ui/EntryApp";
import { disableNavigation } from './ui/routes';

//MESSL, 2022-02-02 - Used for esbuild and some weird dependency issues. Maybe we can get rid of jQuery altogether
(<any>window).ko = ko;
(<any>window).jQuery = jquery;
(<any>window).$ = jquery;

disableNavigation();

const map = new Map<string, string>();
map.set('ui-superuser', 'ui-app');
map.set('ui-subjectcoordinator', 'ui-app');
map.set('ui-author', 'ui-app');
map.set('ui-bulkgrading', 'ui-app');
map.set('ui-manualgrading', 'ui-app');

const retVal = new EntryApp(map);
void retVal.run();

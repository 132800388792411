import * as ko from 'knockout';
import { formatMessage } from '../../../../i18n/data';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as i18next from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as SETANSWER from './setanswer.mutation.graphql.g';
import * as API from '../../../../its-itembank-api.g';

export type Q = Awaited<ReturnType<API.Sdk['textentry_manual_grading_data']>>;
export type GAP = Q['TextEntryManualGrading']['get']['gaps'][0];
export type GIVEN = GAP['given'][0];
interface ViewModel {
    itemId: string;
}

export class GradingGap {
    constructor(readonly model: ViewModel, readonly gap: GAP) { }

    public readonly gapId = ko.pureComputed(() => {
        return this.gap.gapId;
    });

    public readonly displayText = ko.pureComputed(() => {
        return formatMessage(i18next.t(['itemdefinition.kosovo.textentry_manual.grading.GAP_GAPID']), { gapId: this.gapId() });
    });
    public readonly values = ko.pureComputed(() => {
        return this.gap.given.map(val => new GapValue(this.model, this.gap, val));
    });
}

export class GapValue {
    constructor(readonly model: ViewModel, readonly gap: GAP, readonly gapValue: GIVEN) {
        this._correct(gapValue.correct);
    }
    private readonly _correct = ko.observable<boolean>();
    public readonly answer = ko.pureComputed(() => {
        return this.gapValue.value;
    });
    public readonly count = ko.pureComputed(() => {
        return this.gapValue.count;
    });
    public readonly submitted = ko.pureComputed(() => {
        return this.gapValue.submitted;
    });
    public readonly isCorrect = ko.pureComputed(() => {
        switch (this._correct()) {
            case true:
                return i18next.t(['itemdefinition.kosovo.textentry_manual.grading.CORRECT']);
            case false:
                return i18next.t(['itemdefinition.kosovo.textentry_manual.grading.INCORRECT']);
            default:
                return i18next.t(['itemdefinition.kosovo.textentry_manual.grading.NOT_GRADED']);
        }
    });

    public readonly selectCorrect = new UIAction(undefined, async () => {
        if (this.submitted()) {
            return;
        }
        await ServerConnection.api.textentry_manual_grading_setanswer({
            itemId: this.model.itemId,
            correct: true,
            gapId: this.gap.gapId,
            value: this.gapValue.value,
        });
        this._correct(true);
    });
    public readonly selectIncorrect = new UIAction(undefined, async () => {
        if (this.submitted()) {
            return;
        }
        await ServerConnection.api.textentry_manual_grading_setanswer({
            itemId: this.model.itemId,
            correct: false,
            gapId: this.gap.gapId,
            value: this.gapValue.value,
        });
        this._correct(false);
    });
}

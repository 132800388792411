import * as ko from 'knockout';
import { DONE, isSame } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoOrderingWords_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type ANSWER = DATA['owAnswers'][0];

export class OrderingWordsData_Answer {
    constructor(private readonly model: OrderingWordsData, public readonly rawData: ANSWER) {
        this.id = rawData.id;
        this.value = translate(rawData.value, {});
    }
    public readonly id: string;
    public readonly value: string;
    public readonly index = ko.pureComputed(() => {
        const data = this.model.answerOrder();
        return data.indexOf(this.id);
    });
}

export class OrderingWordsData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly rawData: DATA) {
        super();
        this.answers = rawData.owAnswers.map(a => new OrderingWordsData_Answer(this, a));
        this.headerText = translate(rawData.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(rawData.question, {}),
            attachments: this.meta.attachments,
        });
        this.fromJS(rawData);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'KosovoOrderingWords_QueryItemSessionData') {
            throw new Error();
        }
        this.answerOrder(data.owAnswers.map(x => x.id));
    }

    public readonly questionHTML: string;
    public readonly answers: OrderingWordsData_Answer[];
    public readonly answerOrder = ko.observable<string[]>();
    public readonly correctAnswer: string;
    public readonly headerText: string;
    /*
    public async setAnswer(answerId: string) {
        const result = await ServerConnection.mutate(UPDATE_SC, {
            answer: {
                answerId: answerId,
                sessionDocRefId: this.meta.session.sessionId,
                itemDocRefId: this.meta.itemId
            }
        });
        this.selectedAnswer(answerId);
    }
    */

    public async moveLeft(answerId: string) {
        if (!answerId) {
            return;
        }
        const answer = this.answers.find(x => x.id === answerId);

        const selectedAnswerIndex = answer.index();
        if (selectedAnswerIndex === -1) {
            return;
        }

        const order = this.answerOrder();
        order.splice(selectedAnswerIndex - 1, 0, ...order.splice(selectedAnswerIndex, 1));
        this.answerOrder(order);
    }
    public async moveRight(answerId: string) {
        if (!answerId) {
            return;
        }
        const answer = this.answers.find(x => x.id === answerId);

        const selectedAnswerIndex = answer.index();
        if (selectedAnswerIndex > (this.answers.length - 1)) {
            return;
        }

        const order = this.answerOrder();
        order.splice(selectedAnswerIndex + 1, 0, ...order.splice(selectedAnswerIndex, 1));
        this.answerOrder(order);
    }

    IsInteractionStarted() {
        return !isSame(this.rawData.owInitialOrder.map(x => x.id), this.answerOrder());
    }
    IsInteractionComplete() {
        return this.IsInteractionStarted();
    }
    public async reset() {
        this.answerOrder(this.rawData.owInitialOrder.map(x => x.id));
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            OrderingWords: {
                order: this.answerOrder(),
            }
        };
        return retVal;
    }

}

import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import * as TREE from '../../tree';
import { Intent, UIAction } from '../../ui/uiAction';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'widgets-popupsingleselect';
const WIDGET_PARENT_NAME = 'widgets';

let htmlId = 0;

export interface IViewItem {
    id: string;
    title?: ko.MaybeSubscribable<string>;
    displayText: ko.MaybeSubscribable<string>;
}


export interface IParams {
    items: ko.MaybeSubscribable<IViewItem[]>;
    onClose?: Intent<undefined>;
    onOk: Intent<string>;
    selected?: string;
    visible: ko.Computed<boolean> | ko.Observable<boolean>;
}

class Item {
    public readonly htmlId: string;
    constructor(readonly viewModel: ViewModel, readonly item: IViewItem) {
        this.htmlId = `${WIDGET_NAME}-item-${htmlId++}`;
    }

    public readonly title = ko.pureComputed(() => {
        return ko.unwrap(this.item.title);
    });

    public readonly displayText = ko.pureComputed(() => {
        return ko.unwrap(this.item.displayText);
    });
    public readonly checked = ko.pureComputed({
        read: () => {
            return this.item.id === this.viewModel.selected();
        },
        write: newVal => {
            if (newVal) {
                this.viewModel.selected(this.item.id);
            } else {
                //this.viewModel.selected(undefined);

            }
        }
    });
}
export class ViewModel extends AbstractWidget {
    public readonly okText = ko.pureComputed(() => {
        return i18next.t(['widgets.popupsingleselect.OK']);
    });
    public readonly cancelText = ko.pureComputed(() => {
        return i18next.t(['widgets.popupsingleselect.CANCEL']);
    });

    public readonly selected = ko.observable<string>();
    public readonly visible = ko.pureComputed({
        read: () => this.params.visible(),
        write: newVal => this.params.visible(newVal)
    });

    public readonly actionOk = new UIAction(undefined, async () => {
        await this.params.onOk(this.selected());
        this.visible(false);
    });

    public readonly actionClose = new UIAction(undefined, async () => {
        if (this.params.onClose) {
            await this.params.onClose(undefined);
        }
        this.visible(false);
    });
    public readonly actionCheckAll = new UIAction(undefined, async () => {
        this.items().forEach(item => {
            item.checked(true);
        });
    });
    public readonly actionUncheckAll = new UIAction(undefined, async () => {
        this.items().forEach(item => {
            item.checked(false);
        });
    });
    public readonly items = ko.pureComputed(() => {
        const items = ko.unwrap(this.params.items) || [];
        const retVal = items.map(x => new Item(this, x));
        retVal.sort((a, b) => TREE.stringCompare(a.displayText(), b.displayText()));
        return retVal;
    });

    constructor(readonly params: IParams) {
        super();
        this.selected(params.selected);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

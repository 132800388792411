import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput, SurveySingleChoiceText_QueryItemSessionData_Option } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { ServerConnection } from '../../../ui/RestAPI';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'SurveySingleChoiceText_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type ANSWER = DATA['ssctAnswers'][0];

export class SurveySingleChoiceTextData_Answer {
    constructor(private readonly model: SurveySingleChoiceTextData, public readonly rawData: ANSWER) {
        this.id = rawData.id;
        this.value = RICHTEXTHTML.process({
            html: translate(this.rawData.text, {}),
            attachments: this.model.meta.attachments,
        });
        this.hasTextEntry = rawData.id === model.userTextAnswer;
    }
    public readonly id: string;
    public readonly value: string;
    public readonly isSelected = ko.pureComputed(() => this.id === this.model.selectedAnswer());
    public readonly hasTextEntry: boolean;
}

export class SurveySingleChoiceTextData extends ItemDataModel {
    public readonly userTextAnswer: string;
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        const imgAtt = meta.getAttachment(data.ssctImage?.id);
        this.imgUrl = ServerConnection.getDataUrl(imgAtt?.hrefResolved);
        this.imgAlt = imgAtt?.altText?.value ?? '';
        this.hasImage = !!this.imgUrl;
        this.userTextAnswer = data.userTextAnswer && data.userTextAnswer.id;
        this.answers = data.ssctAnswers.map(a => new SurveySingleChoiceTextData_Answer(this, a));

        this.headerText = translate(data.header, {});
        this.option = data.ssctOption;
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'SurveySingleChoiceText_QueryItemSessionData') {
            throw new Error();
        }
        this.selectedAnswer(data.ssctSelectedAnswer && data.ssctSelectedAnswer.id);
        this.userText(data.userText || '');
    }
    public readonly questionHTML: string;
    public readonly userText = ko.observable('');
    public readonly answers: SurveySingleChoiceTextData_Answer[];
    public readonly selectedAnswer = ko.observable<string>();
    public readonly imgUrl: string;
    public readonly imgAlt: string;
    public readonly hasImage: boolean;
    public readonly headerText: string;
    public readonly option: SurveySingleChoiceText_QueryItemSessionData_Option;
    public async setAnswer(answerId: string) {
        this.selectedAnswer(answerId);
    }

    public IsInteractionStarted() {
        return !!this.selectedAnswer();
    }
    public IsInteractionComplete() {
        return !!this.selectedAnswer();
    }
    public async reset() {
        this.selectedAnswer(undefined);
        this.userText('');
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SurveySingleChoiceText: {
                selected: this.selectedAnswer(),
                userText: this.userText(),
            }
        };
        return retVal;
    }

}

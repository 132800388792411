import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { log } from '../../debug';
import { AS } from '../../dx_helper';
import { toastService } from '../../toastService';
import { UIAction } from '../uiAction';
import * as ITEMPRESENTER from '../widgets/itempresenter/widget';
import * as i18next from './../../i18n/i18n';
import { focusManager, normalWindowMode } from './index';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'ui-focusmanager';

export interface IButtonOptions {
    text: string;
    icon: string;
    action: UIAction<any>;
    type?: 'normal' | 'default';
}

class Button {
    constructor(readonly data: IButtonOptions) {

    }
    public readonly icon = ko.pureComputed(() => {
        const d = this.data;
        return d && d.icon || '';
    });

    public readonly text = ko.pureComputed(() => {
        const d = this.data;
        return d && d.text || '';
    });
    public readonly actionClick = new UIAction(undefined, async () => {
        const d = this.data;
        if (!d.action) {
            toastService.NYI();
        }
        return d.action.invoke(undefined, true);
    });
    public readonly css = ko.pureComputed(() => {
        if (this.data.type === 'default') {
            return `${WIDGET_NAME}-green-button`;
        } else {
            return `${WIDGET_NAME}-white-button`;
        }
        return '';
    });
}
export class ViewModel extends AbstractWidget {
    public readonly focused = ko.pureComputed(() => focusManager.focused());
    public readonly topItemFocus = ko.pureComputed(() => focusManager.topItemFocus());

    public readonly buttons: Button[] = [];
    public readonly bars: Bar[] = [];

    public readonly enterFocusText = ko.pureComputed(() => i18next.t(['ui.focusmanager.ENTERING_FOCUS']));


    public readonly leaveFocusText = ko.pureComputed(() => i18next.t(['ui.focusmanager.LEAVING_FOCUS']));


    constructor(readonly params: IParams) {
        super();
        log(`initialize ${WIDGET_NAME} with params`, params);
        if (params && params.progress_percent) {
            this.bars.push(new Bar({
                percent: params.progress_percent,
                label: params.progress_label,
                icon: 'icon_percentprogress',
                color: '#528fb5'
            }));
        }
        if (params && params.time_percent) {
            this.bars.push(new Bar({
                percent: params.time_percent,
                label: params.time_label,
                icon: 'icon_hourglass',
                color: '#7d5329'
            }));
        }
        if (params && params.buttons && params.buttons.length) {
            this.buttons.push(...params.buttons.map(x => new Button(x)));
        } else {
            this.buttons.push(new Button({
                icon: 'icon_check',
                text: i18next.t(['ui.focusmanager.LEAVE_FOCUS_MODE']),
                action: new UIAction(undefined, async () => {
                    await focusManager.leaveFocus();
                    normalWindowMode();
                }),
            }));
        }
    }

    public async OnRefresh() {
        await super.OnRefresh();
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME}`,
            action: async () => {
                if (this.focused() === 'entering') {
                    return true;
                }
                if (this.focused() === 'leaving') {
                    return true;
                }
                return false;
            }
        });
        await super.initialize();
    }

    public itempresenterParams = ko.pureComputed(() => {
        const topItemFocus = this.topItemFocus();
        if (!topItemFocus) {
            return undefined;
        }
        return AS<ITEMPRESENTER.IParams>({
            //focusId: topItemFocus.focusId,
            sessionId: topItemFocus.sessionId,
            itemType: topItemFocus.itemType,
            isFocusModeTarget: true,
            isTopItem: true,
            item: topItemFocus.itemId,
            mode: topItemFocus.mode
        });
    });
}

interface IBarOptions {
    icon: string;
    color: string;
    percent: ko.Subscribable<number>;
    label?: ko.Subscribable<string>;
}

class Bar {
    constructor(readonly options: IBarOptions) {

    }
    public readonly icon = ko.pureComputed(() => {
        return `${WIDGET_NAME}-icon ${this.options.icon}`;
    });
    public readonly gauge = ko.pureComputed(() => {
        const width = Math.floor(this.options.percent());
        return {
            backgroundColor: this.options.color,
            width: `${width}%`
        };
    });
    public readonly hasLabel = ko.pureComputed(() => {
        return !!this.options.label;
    });
    public readonly label = ko.pureComputed(() => {
        if (!this.options.label) {
            return '';
        }
        return this.options.label();
    });
}
export interface IParams {
    buttons?: IButtonOptions[];
    progress_percent?: ko.Subscribable<number>;
    time_percent?: ko.Subscribable<number>;
    progress_label?: ko.Subscribable<string>;
    time_label?: ko.Subscribable<string>;
}


export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

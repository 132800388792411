import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-bulkgrading-grading';

export type IParamsInner = {
    subjectDocRefId: string;
    itemDocRefId: string;
};
export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export class GradingRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return 'Grading ' + this.widgetParams.itemDocRefId;
    });
    constructor(readonly widgetParams: IParamsInner) {
    }

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.bulkgrading.grading.TOGGLE_INFO_OF_AUTHOR_YOU_SEE_THE_EVALUATION_CRITERIA_GIVEN_BY_THE_AUTHOR_TOGGLE_REASON_FOR_EVALUATION_HERE_YOU_DESCRIBE_HOW_YOU_EVALUATED_THE_ITEM_AND_HOW_IT_CAME_TO_THE_FINAL_POINTS']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.bulkgrading.grading.HELP']),
                action: this.showHelp
            }];
    });
}


export const FACTORY = ROUTES.registerRoute<{ subject: string, item: string }>({
    route: '/bulkgrading/subject/:subject/item/:item',
    toParams: map => ({
        item: map.get('item'),
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('item', p.item);
        map.set('subject', p.subject);
    },
    create: async p => {
        const route = new GradingRoute(
            {
                subjectDocRefId: p.subject,
                itemDocRefId: p.item,
            });
        return route;
    },
});

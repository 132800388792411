import CustomStore from 'devextreme/data/custom_store';
import { error, log } from '../debug';
import { DONEENUM } from '../helper';

export type IndexGenerator<T, TKey extends keyof T> = (keys: Set<T[TKey]>) => T[TKey];

const _aToZ = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
export function AtoZ(values: Set<string>): string {
    for (const letter of _aToZ) {
        if (values.has(letter)) {
            continue;
        }
        return letter;
    }
    throw new Error('Out of letters. Sorry');
}
export function OnePlus(values: Set<string>): string {
    for (let nr = 1; ; ++nr) {
        const id = '' + nr;
        if (values.has(id)) {
            continue;
        }
        return id;
    }
}

export class GqlDS<T, TKey extends keyof T> {
    readonly data: T[] = [];
    readonly store: CustomStore;
    readonly state = {
        fetched: false,
    };

    getKey(record: T): T[TKey] {
        const x = record[this.options.key];
        if (typeof x !== 'string') {
            throw new Error(`data['${String(this.options.key)}'] is not a string`);
        }
        return x;
    }
    constructor(readonly options: {
        fetch: () => T[],
        key: TKey,
        indexGenerator: IndexGenerator<T, TKey>,
        onNewRow: (row: T) => void,
        upsert: (key: T[TKey], row: Partial<T>) => Promise<DONEENUM>,
        remove: (key: T[TKey]) => Promise<DONEENUM>
    }) {
        this.store = new CustomStore({
            loadMode: 'raw',
            key: <string>options.key,
            byKey: async key => {
                return this.data.find(x => this.getKey(x) === key);
            },
            load: async (options) => {
                log(`Load DS`);
                if (!this.state.fetched) {
                    this.data.splice(0, this.data.length, ...this.options.fetch());
                    this.state.fetched = true;
                }
                return this.data;
            },
            update: async (key, values: T) => {
                log(`Update ${key} to ${JSON.stringify(values)}`);
                const existing = this.data.find(x => this.getKey(x) === key);
                Object.assign(existing, values);
                if (this.options.upsert) {
                    await this.options.upsert(key, values);
                }
            },
            errorHandler: (err: Error) => {
                error(err.message);
            },
            remove: async (key: T[TKey]) => {
                log(`Delete ${key}`);
                const existingIndex = this.data.findIndex(x => this.getKey(x) === key);
                if (existingIndex !== -1) {
                    this.data.splice(existingIndex, 1);
                }
                if (this.options.remove) {
                    await this.options.remove(key);
                }
            },
            insert: async (values: T) => {
                const key = this.getKey(values);
                const existing = this.data.find(x => this.getKey(x) === key);
                if (existing) {
                    throw new Error('Key already exists!');
                }
                log(`Insert ${key} with values ${JSON.stringify(values)}`);
                this.data.push(values);
                if (this.options.upsert) {
                    await this.options.upsert(key, values);
                }
                return values;
            },
        });
    }
    initNewRow = (e: { data: T }) => {
        const keys = new Set<T[TKey]>(this.data.map(x => this.getKey(x)));
        const retVal: T = <any>{};
        retVal[this.options.key] = <any>this.options.indexGenerator(keys);
        if (this.options.onNewRow) {
            this.options.onNewRow(retVal);
        }
        e.data = retVal;
        return;
    }
}


import { DocumentNode } from 'graphql';
import { dir, error, log } from '../../debug';
import { getGolemData, isStringMap } from '../../Gremlin';
import { HandsOnData } from '../../itemdefinition/model/handson/HandsOnData';
import { HandsOnDataRaw } from '../../itemdefinition/model/ItemModel';
import { GetSession } from '../../itemdefinition/model/session';
import { CustomFieldType, IClient_TopItemDataInput, IUi_Focusmanager_Client_CacheMutation, IUi_Focusmanager_Client_CacheMutationVariables, IUi_Focusmanager_Client_CleanupMutation, IUi_Focusmanager_Client_CleanupMutationVariables, IUi_Focusmanager_Client_InitMutation, IUi_Focusmanager_Client_InitMutationVariables, IUi_Focusmanager_Client_InvestigateMutation, IUi_Focusmanager_Client_InvestigateMutationVariables, IUi_Focusmanager_Client_ResetMutation, IUi_Focusmanager_Client_ResetMutationVariables, IUi_Focusmanager_Client_SetupMutation, IUi_Focusmanager_Client_SetupMutationVariables, Ui_Focusmanager_Client_CacheDocument, Ui_Focusmanager_Client_CleanupDocument, Ui_Focusmanager_Client_InitDocument, Ui_Focusmanager_Client_InvestigateDocument, Ui_Focusmanager_Client_ResetDocument, Ui_Focusmanager_Client_SetupDocument } from '../../its-itembank-api.g';
import { USERFIELD_DRIVE_H } from '../../magic';
import { ServerConnection } from '../RestAPI';
import { OnceManager } from './OnceManager';

interface FetchResult<TData> {
    data?: TData;
    errors?: {
        message: string;
    }[];
}



class DonkeyAPI {
    constructor(public readonly browserSessionId: string) {

    }

    public async ClientCleanup(variables: IUi_Focusmanager_Client_CleanupMutationVariables) {
        return await this.mutate<IUi_Focusmanager_Client_CleanupMutation>(variables, Ui_Focusmanager_Client_CleanupDocument);
    }
    public async ClientCache(variables: IUi_Focusmanager_Client_CacheMutationVariables) {
        return await this.mutate<IUi_Focusmanager_Client_CacheMutation>(variables, Ui_Focusmanager_Client_CacheDocument);
    }
    public async ClientInit(variables: IUi_Focusmanager_Client_InitMutationVariables) {
        return await this.mutate<IUi_Focusmanager_Client_InitMutation>(variables, Ui_Focusmanager_Client_InitDocument);
    }
    public async ClientInvestigate(variables: IUi_Focusmanager_Client_InvestigateMutationVariables) {
        return await this.mutate<IUi_Focusmanager_Client_InvestigateMutation>(variables, Ui_Focusmanager_Client_InvestigateDocument);
    }
    public async ClientReset(variables: IUi_Focusmanager_Client_ResetMutationVariables) {
        return await this.mutate<IUi_Focusmanager_Client_ResetMutation>(variables, Ui_Focusmanager_Client_ResetDocument);
    }
    public async ClientSetup(variables: IUi_Focusmanager_Client_SetupMutationVariables) {
        return await this.mutate<IUi_Focusmanager_Client_SetupMutation>(variables, Ui_Focusmanager_Client_SetupDocument);
    }
    private async mutate<Result>(variables: unknown, query: DocumentNode): Promise<Result> {
        const request = {
            query: query.loc.source.body,
            variables,
        };

        const response = await window.itsr3.postDonkey(this.browserSessionId, JSON.stringify(request));
        log(`received ${response}`);
        const result = JSON.parse(response);
        if (!result.data) {
            error(`mutate did not return data`);
            if (result.errors) {
                dir(result.errors);
            }
            throw new Error(`mutate did not return data`);
        }
        const data: Result = result.data;
        return data;
    }
}


export class LocalDonkeyConnection {
    private _client: DonkeyAPI;
    private readonly _once = new OnceManager();
    private sessionId: string;

    normalize(x: HandsOnDataRaw): IClient_TopItemDataInput {
        const retval: IClient_TopItemDataInput = {
            id: x.id,
            topItemId: x.topItemId,
            itemNr: x.itemNr,
            sessionId: this.sessionId,
            files: x.files.map(y => ({
                id: y.id,
                path: y.path,
                userCreated: y.userCreated,
                resource: y.resource ? {
                    id: y.resource.id,
                    size: y.resource.size,
                    hrefResolved: ServerConnection.getDataUrl(y.resource.hrefResolved),
                    sha256: y.resource.sha256,
                    etag: y.resource.etag,
                    uniqueId: y.resource.uniqueId,
                } : undefined,
            })),
            investigators: x.investigators.map(y => ({
                id: y.id,
                investigatorUri: y.investigatorUri,
                serviceProviderId: y.serviceProviderId,
                properties: y.properties.map(z => ({
                    propertyId: z.propertyId,
                    value: z.value,
                })),
            })),
            preparators: x.preparators.map(y => ({
                id: y.id,
                preparatorUri: y.preparatorUri,
                serviceProviderId: y.serviceProviderId,
                properties: y.properties.map(z => ({
                    propertyId: z.propertyId,
                    value: z.value,
                })),
            })),
        };
        return retval;
    }


    public async reset(sessionId: string, itemId: string) {
        const data = this.getData({ sessionId, itemId });
        log(`reset item ${itemId}`);
        const result = await this._client.ClientReset({
            data
        });
        log(`reset item ${itemId}: ${JSON.stringify(result)}`);
    }
    public async cleanup({ sessionId, itemId }: {
        sessionId: string;
        itemId: string;
    }) {
        const data = this.getData({ sessionId, itemId });
        log(`cleanup item ${itemId}`);
        const result = await this._client.ClientCleanup({
            data
        });
        log(`cleanup item ${itemId}: ${JSON.stringify(result)}`);
    }
    public async investigate({ sessionId, itemId }: {
        sessionId: string;
        itemId: string;
    }) {
        const data = this.getData({ sessionId, itemId });
        log(`investigate item ${itemId}`);
        const result = await this._client.ClientInvestigate({
            data
        });
        log(`investigate item ${itemId} (from donkey): ${JSON.stringify(result)}`);
        const results = new Map<string, string>();
        for (const x of result.itsr3client.investigate) {
            results.set(x.investigatorId, x.serializedValue);
        }
        const session = GetSession(this.sessionId);
        for (const inv of data.investigators) {
            const [itemId, invId] = inv.id.split('/');
            const golemData = getGolemData(itemId);
            if (isStringMap(golemData)) {
                const golemSerializedValue = golemData[invId];
                if (typeof golemSerializedValue === 'string') {
                    results.set(inv.id, golemSerializedValue);
                }
            }
        }
        for (const fullId of Array.from(results.keys())) {
            const [itemId, invId] = fullId.split('/');
            const itemData = session.getItemData(itemId);
            if (!(itemData instanceof HandsOnData)) {
                throw new Error(`${itemId} is not of type HandsOn`);
            }
            itemData.investigatorResults.set(invId, results.get(fullId));
        }
    }
    public getData({ sessionId, itemId }: {
        sessionId: string;
        itemId: string;
    }) {
        if (!this._client) {
            throw new Error('Connect to donkey not called');
        }
        if (this.sessionId !== sessionId) {
            throw new Error('SessionId does not match');
        }
        const session = GetSession(this.sessionId);
        const item = session.GetItemModel(itemId);
        if (!item) {
            throw new Error(`ItemId ${itemId} not in session`);
        }
        return this.normalize(item.handsonData);
    }
    public async setup({ sessionId, itemId }: {
        sessionId: string;
        itemId: string;
    }) {
        log(`Setup item ${itemId}`);
        const data = this.getData({ sessionId, itemId });
        dir(data);
        const result = await this._client.ClientSetup({
            data
        });
        log(`setup item ${itemId}: ${JSON.stringify(result)}`);
    }
    public getCompactModeBounds({ sessionId, itemId }: {
        sessionId: string;
        itemId: string;
    }) {
        if (this.sessionId !== sessionId) {
            throw new Error('Programmers fault: connectToDonkey was not called');
        }
        const session = GetSession(this.sessionId);
        const item = session.GetItemModel(itemId);
        if (!item) {
            throw new Error('Programmers fault: item is not in session');
        }
        return {
            minWidth: item.minWidth,
            minHeight: item.minHeight,
        };
    }

    public async connectToDonkey(sessionId: string) {
        if (this.sessionId !== sessionId) {
            await this.Disconnect();
        }
        const url = await window.itsr3.newDonkey(ServerConnection.apiKeyValue, undefined);
        log(`Local donkey available at URL ${url}`);
        this._client = new DonkeyAPI(ServerConnection.apiKeyValue);

        log(`init ${sessionId}`);
        const result = await this._client.ClientInit({
            data: {
                sessionId
            }
        });
        log(`init ${sessionId}: ${JSON.stringify(result)}`);
        await this._once.do('ui_focusmanager_setuserfields', async () => {
            await ServerConnection.api.ui_focusmanager_setuserfields({
                examSessionParams: {
                    sessionDocRefId: sessionId,
                },
                params: [{
                    name: USERFIELD_DRIVE_H,
                    serialized: result.itsr3client.init.driveH,
                    type: CustomFieldType.String,
                }]
            });
        });
        this.sessionId = sessionId;
        return true;
    }

    public async Disconnect() {
        this.sessionId = undefined;
        this._once.clear();
        if (this._client) {
            this._client = undefined;
        }
    }
}
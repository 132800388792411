﻿import * as ko from 'knockout';
import { AS } from '../../dx_helper';
import * as query from '../../getquery';
import { InitSession } from '../../itemdefinition/model/session';
import * as API from '../../its-itembank-api.g';
import { PdfReportSettingsIncludeItems } from '../../its-itembank-api.g';
import { ItemHostBase } from "../ItemHostBase";
import { ServerConnection } from '../RestAPI';
import { isTable } from '../resultscreens/default/tables';
import * as RESULTSCREEN_DEFAULT from '../resultscreens/default/widget';
import { Item } from '../widgets/itemoverviewtable/Item';
import { ItemOverviewBodyFieldTemplate } from '../widgets/itemoverviewtable/ItemOverviewBodyFieldTemplate';
import * as ITEM_TABLE from '../widgets/itemoverviewtable/widget';
//import * as Q from './data.query.graphql.g';
import { htmlString } from './print.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_print_data']>>;

const WIDGET_NAME = 'ui-print';

export class ViewModel extends ItemHostBase {
    public readonly currentSessionId = ko.observable('');
    public readonly currentSession = ko.observable<Q['examSession']>();
    public readonly currentExam = ko.pureComputed(() => {
        const d = this.currentSession();
        return d && d.examInfo;
    });

    public readonly includeItems = ko.pureComputed(() => {
        const s = this.currentSession();
        if (this._Items()?.length === 0) {
            return false;
        }
        const show = s?.examInfo?.pdfReportSettings?.includeItems;
        return show === PdfReportSettingsIncludeItems.Yes || show === PdfReportSettingsIncludeItems.WhatCandidateSaw;
    });

    public readonly _Items = ko.pureComputed(() => {
        const s = this.currentSession();
        if (!s || !s.items) {
            return undefined;
        }
        return s.items.filter(x => x.accumulatedScore?.pointsMax ?? 0 > 0).map(x => new Item(this, x));
    });

    public readonly items = ko.pureComputed(() => {
        const s = this.currentSession();
        if (!s || !s.items) {
            return undefined;
        }
        const template = s.examInfo.pdfReportSettings.itemDetailCaption.value || '{id}';
        return this._Items().map(x => {
            return {
                itemId: x.itemId,
                itemType: x.itemType,

                itemCaptionTable: ({
                    name: ITEM_TABLE.WIDGET_NAME,
                    params: AS<ITEM_TABLE.IParams>({
                        itemOverviewHeader: [],
                        items: [x],
                    })
                }),
            };
        });
    });

    public readonly resultScreenParams = ko.pureComputed(() => {

        const retVal: RESULTSCREEN_DEFAULT.IParams = {
            sessionId: this.currentSessionId(),
            mode: 'print',
        };
        return retVal;
    });

    protected async onInitialize() {
        await super.onInitialize();
        const sessionId = query.params.get('session');
        if (!sessionId) {
            throw new Error('Ungültiger Aufruf');
        }
        this.currentSessionId(sessionId);
        const data = await ServerConnection.api.ui_print_data({
            sessionDocRef: sessionId
        });
        const r = data.examSession;
        this.currentSession(r);

        const caption = r.examInfo.pdfReportSettings.itemDetailCaption.value || '{id}';
        if (isTable(caption)) {
            const r = ITEM_TABLE.parseItemTable(caption);

            this.itemBodyFields(r.bodyTemplateFields);
        } else {
            const field = new ItemOverviewBodyFieldTemplate(caption, '');
            this.itemBodyFields([field]);
        }
        await InitSession(sessionId, []);
    }
    public itemBodyFields = ko.observable<ItemOverviewBodyFieldTemplate[]>();
}

export type IParams = undefined;

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel();
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
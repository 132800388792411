import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import { formatMessage } from "devextreme/localization";
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { log } from '../../../debug';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import { UIAction } from '../../uiAction';
import * as WIDGET_USEREDIT from '../useredit/route';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './useroverview.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_useroverview_users']>>;
type USER = Q['users'][0];

export class ViewModel extends DxWidget {
    private readonly _users: USER[] = [];

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._users;
                        },
                        byKey: async key => {
                            return this._users.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.useroverview.NAME_OF_USER']),
            dataField: 'displayName',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.useroverview.SHORTCUT']),
            dataField: 'initials',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.useroverview.USER_NAME']),
            dataField: 'userId',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.useroverview.USER_ROLE']),
            dataField: 'roles',
            dataType: 'string',
            calculateDisplayValue: x => {
                const queryRow: USER = x;
                return queryRow.privileges.map(x => i18next.t(TRANSLATIONS.UserRoleString[x.privilege])).join(', ');
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.useroverview.DEACTIVATED']),
            dataField: 'isDeleted',
            dataType: 'boolean',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.useroverview.ACTIONS']),

            width: '80',
            cellTemplate: (elem, info) => {
                const retVal = $('<a />');
                retVal.text(i18next.t(['ui.superuser.useroverview.EDIT']));
                retVal.css({ cursor: 'pointer' });
                retVal.on('click', () => {
                    const user: USER = info.data;
                    void this.actionEdit.intent(user);

                });
                return retVal;
            }
        });
        retVal.filterValue = ['isDeleted', '=', false];
        return retVal;
    });




    public readonly actionEdit = new UIAction<USER>(undefined, async (e, args) => {
        await ROUTES.routeManager.navigateToHREF(WIDGET_USEREDIT.FACTORY.href(
            {
                userDocRefId: args.docReferenceId
            }));
    });

    constructor(readonly params: IParams) {
        super();
    }

    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.ui_superuser_useroverview_users({
            pager: {
                limit: null,
                showDeleted: true,
                since_docReferenceId: null
            }
        });
        this._users.splice(0, this._users.length, ...q.users);
        await refreshDx(this);
    }
    public readonly loaded = ko.observable(false);



    public readonly actionNewUser = new UIAction(undefined, async () => {
        const newUser = await ServerConnection.api.ui_superuser_useroverview_new({});

        await ROUTES.routeManager.navigateToHREF(WIDGET_USEREDIT.FACTORY.href({
            userDocRefId: newUser.users.newUser.docReferenceId
        }));
    });
    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'plus',
                text: i18next.t(['ui.superuser.useroverview.NEW']),
                onClick: this.actionNewUser.click
            })
        }));
        return DONE;
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    log(`${WIDGET_NAME} ui.superuser.home.HOME: ${i18next.t(['ui.superuser.home.HOME'])} Yes: ${formatMessage('Yes')} Search: ${formatMessage('dxDataGrid-searchPanelPlaceholder')}`);

    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

export const MAGIC = 7553;

export const MAX_QUERY_RETRY = 5;
export const MAX_MUTATE_RETRY = 5;

export const DEFAULT_SYLLABUS = 'SYLLABUS';
export const DEFAULT_CATEGORIES = 'CATEGORIES';
export const DEFAULT_LANGUAGES = 'LANGUAGES';
export const DEFAULT_ALTERNATIONS = 'ALTERNATIONS';
export const TAXONOMY_HANDSON = 'HANDSON';

export const IADONKEY = 'urn:ia-donkey';
export const EDIT_SESSION = 'EDIT';

export const REVISABLE_ITEMS_THRESHOLD = 100;

export const MAX_DELAY_TIME = 30 * 60;

export const DEFAULT_COMPACT_MIN_WIDTH = 800;
export const DEFAULT_COMPACT_MIN_HEIGHT = 200;

export const DEFAULT_IMMEDIATE_RESULT_SECONDS = 5;

export const USERFIELD_DRIVE_H = '.well-known:driveh';
export const DEUS = '듯';

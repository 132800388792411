import * as ko from 'knockout';
import { error, log } from '../debug';
import { registerGremlin } from '../Gremlin';
import { toastService } from '../toastService';
import * as i18next from './../i18n/i18n';
import { IDetailedOKResponse, IOKResponse } from './api_interfaces';
import { Timer } from './timer';

export interface IUIActionFunction {
    (args?: {}, triggerAlways?: boolean): Promise<void>;
    Name: string;
}

export type UIActionListener = (data: UIActionInvocation) => Promise<void>;

export const isUIWorking = ko.observable(false);
export const listenersWorking = ko.observable(false);

let clicked = 0;

const listeners = new Array<UIActionListener>();
const actions = new Map<string, UIAction<{}>>();
let counter: number = 0;

const timer = new Timer();
timer.setTimeLimit('P1Y');

export type ClickFunction<TArgs> = (this: void, e: UIActionInvocation, args?: TArgs, ev?: MouseEvent) => Promise<void>;
export type FocusFunction = (this: void, e: FocusEvent) => void;
export type IsEnabledFunction = (this: void) => boolean;

export const options = {
    successTime: 5000,
    errorTime: 5000
};
export async function showOK(resp: IOKResponse) {
    if (resp && resp.ok) {
        toastService.success(
            i18next.t(['ui.uiaction.OK']));
    } else {
        toastService.error(
            i18next.t(['ui.uiaction.SOMETHING_WENT_WRONG_PLEASE_RESTART_THE_BROWSER_IF_THIS_MESSAGE_APPEARS_AGAIN_PLEASE_CONTACT_THE_SUPPORT'])
        );
    }
}

export async function showDetailed(resp: IDetailedOKResponse) {
    if (!resp || !resp.messages || !resp.messages.length) {
        await showOK(resp);
        return;
    }

    const message = resp.messages.map(x => x.message).join('<br/>');
    if (message.indexOf('[RELOAD]') != -1) {
        log('We received a RELOAD message');
        try {
            window.location.reload();
        } catch (err) {
            error(err);
        }
        return;
    }

    const hasErrors = resp.messages.some(x => x.messageType === 'error');
    const hasWarnings = resp.messages.some(x => x.messageType === 'warning');

    if (resp.ok) {
        toastService.success(message);
    } else if (hasErrors) {
        toastService.error(message);
    } else if (hasWarnings) {
        toastService.warning(message);
    } else {
        toastService.info(message);
    }
}

export interface IUIActionOptions {
    focusin?: FocusFunction;
    isEnabled?: IsEnabledFunction;
    ignoreErrors?: boolean;
}

export type Intent<T> = (this: void, params: T) => Promise<void>;

export class UIActionInvocation {
    constructor(readonly verb: string, readonly relativeTime: number, readonly isAnonymousAction: boolean) {

    }
    public static Dummy() {
        return new UIActionInvocation('dummy', 0, false);
    }
    public object: {
        sessionId?: string | null;
        sectionId?: string | null;
        itemId?: string | null;
    } = null;
    public error?: {
        message?: string;
        stack?: string
    } = null;
    public success = true;
    public duration = 0;
}

export class UIAction<TArgs> {
    public readonly name: string;

    public static readonly showOK = showOK;
    public static readonly showDetailed = showDetailed;

    public readonly isEnabled = ko.pureComputed(() => {
        if (!this.options.isEnabled) {
            return true;
        }
        return this.options.isEnabled();
    });

    public get intent(): Intent<TArgs> {
        return async (args: TArgs) => {
            await this.invoke(args, true);
        };
    }
    public get koClick() {
        return (vm: unknown, e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            void this.invoke(undefined, false, e);
        };
    }
    public get click() {
        return (args?: TArgs) => {
            void this.invoke(args);
            return;
        };
    }
    public get focusin() {
        return (tgt: Node, evt: FocusEvent) => {
            this.onfocusin(tgt, evt);
        };
    }

    protected onfocusin(tgt: Node, evt: FocusEvent) {

        if (this.options.focusin) {
            this.options.focusin(evt);
        }
    }

    public async invoke(params?: TArgs, triggerAlways?: boolean, ev?: MouseEvent) {
        if (!triggerAlways && clicked) {
            log('ignored click on ' + name);
            return;
        }

        timer.start();
        const data = new UIActionInvocation(this.name, timer.getTimePassed(), this.isAnonymousAction);

        const startTimeMS = timer.getTimePassed();
        clicked++;
        log(`Clicked: ${clicked}`);
        try {
            log('CLICK: ' + name + ' (clicked: ' + clicked + ')');
            isUIWorking(true);
            await this.callback(data, params, ev);
        } catch (err) {
            data.error = this.errToJSON(err);
            data.success = false;
            log(err);
            if (!this.options?.ignoreErrors) {
                await showDetailed({
                    ok: false,
                    messages: [{
                        messageType: 'error',
                        message: err.message || err.responseText || 'Some Error occured'
                    }]
                });
            }
        } finally {
            clicked--;
            log(`Clicked: ${clicked}`);
        }

        const endTimeMS = timer.getTimePassed();
        data.duration = endTimeMS - startTimeMS;
        log('DONE: ' + name + ' in ' + data.duration + 'ms');

        if (!clicked) {
            isUIWorking(false);
        }
        await UIAction.callListeners(data);
    }

    public asAction(args: TArgs) {
        return new UIAction(undefined, () => this.invoke(args, true));
    }
    public readonly isAnonymousAction: boolean;
    constructor(name: string, readonly callback: ClickFunction<TArgs>, readonly options?: IUIActionOptions) {
        if (!options) {
            this.options = {};
        }
        const isTemporary = !name;
        if (!name) {
            this.isAnonymousAction = true;
            name = 'uiAction' + (++counter);
        } else {
            this.isAnonymousAction = false;
        }
        this.name = name;
        if (!isTemporary) {
            actions.set(name, this);
        }
    }

    private errToJSON(err: { message?: string, stack?: string }) {
        return {
            message: err.message || 'Unknown error',
            stack: err.stack || ''
        };
    }

    private static async callListeners(data: UIActionInvocation) {
        listenersWorking(true);
        for (const listener of listeners) {
            try {
                await listener(data);
            } catch (e) {
                log(e);
            }
        }
        listenersWorking(false);
    }
}

export function addListener(cb: UIActionListener) {
    timer.start();
    listeners.push(cb);
}

export function trigger(name: string, params: {}) {
    const action = actions.get(name);
    if (!action) {
        throw new Error('Unable to trigger UI action \'' + name + '\' - action not known');
    }
    return action.invoke(params, true);
}

registerGremlin({
    name: `UIAction`,
    action: async () => {
        //don't let gremlins attack when the UI is blocked.
        if (isUIWorking()) {
            return true;
        }
        return false;
    },
    priority: -1
});

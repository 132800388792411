import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';


export const WIDGET_NAME = 'ui-superuser-subjectedit';

export interface IParamsInner {
    subjectDocRefId: string;
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class SubjectEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;

    public static async CREATE(params: IParamsInner) {
        const d = await ServerConnection.api.ui_superuser_subjectedit_route({
            subjectDocRef: params.subjectDocRefId
        });

        const route = new SubjectEditRoute(params, d.subjectById.subjectId);
        return route;

    }
    public readonly title = ko.pureComputed(() => {
        return this._title;
    });

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.subjectedit.CHOOSE_THE_LANGUAGE_FOR_THE_ITEM_CREATION_CHOOSE_DEFAULT_AS_LANGUAGE_FOR_FOREIGN_LANGUAGE_SUBJECTS_WHICH_ARE_NOT_TRANSLATED_THERE_ARE_ALSO_ITEM_TYPES_WHICH_CANNOT_BE_TRANSLATED_CROSSOUT_ACTIVATE_THE_CHECK_BOX_TO_ALLOW_ALSO_THESE_ITEM_TYPES_THEY_ARE_ONLY_USEFULL_FOR_LANGUAGE_SUBJECTS']);

        toastService.info(msg);
    });

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.superuser.subjectedit.HELP']),
                action: this.showHelp
            }];
    });

    constructor(readonly widgetParams: IParamsInner, readonly _title: string) {
    }
}

export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/superuser/subject/:id',
    toParams: map => ({
        subjectDocRefId: map.get('id'),
    }),
    fromParams: (p, map) => {
        map.set('id', p.subjectDocRefId);
    },
    create: async p => {
        const route = await SubjectEditRoute.CREATE(p);
        return route;
    },
});

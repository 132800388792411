import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import { UIAction } from '../../uiAction';
import * as WIDGET_EDIT from '../testaliasedit/route';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './init.query.graphql.g';
//import * as NEW from './new.mutation.graphql.g';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_testaliasoverview_init']>>;
type ALIAS = Q['TestAlias']['all'][0];

export class ViewModel extends DxWidget {
    public readonly loaded = ko.observable(false);

    public readonly actionEdit = new UIAction<ALIAS>(undefined, async (e, args) => {
        await ROUTES.routeManager.navigateToHREF(WIDGET_EDIT.FACTORY.href({
            docRef: args.docReferenceId
        }));
    });

    constructor(readonly params: IParams) {
        super();
    }

    private readonly _subjects: ALIAS[] = [];

    public async OnRefresh() {
        await super.OnRefresh();
        const r = await ServerConnection.api.ui_superuser_testaliasoverview_init({});
        this._subjects.splice(0, this._subjects.length, ...r.TestAlias.all);
        await refreshDx(this);
    }

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'docReferenceId',
                        load: async () => {
                            return this._subjects;
                        },
                        byKey: async key => {
                            return this._subjects.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.testaliasoverview.DOCREF']),
            dataField: 'docReferenceId',
            width: 100,
            dataType: 'string',
            cssClass: 'itsr3-uitest-hide',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.testaliasoverview.ALIAS']),
            width: 300,
            dataField: 'alias',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.testaliasoverview.RESOLVES_TO']),
            dataField: 'resolvesTo',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.testaliasoverview.DEACTIVATED']),
            width: 100,
            dataField: 'isDeleted',
            dataType: 'boolean',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.testaliasoverview.ACTIONS']),

            width: 100,
            cellTemplate: (elem, info) => {
                const retVal = $('<a />');
                retVal.text(i18next.t(['ui.superuser.testaliasoverview.EDIT']));
                retVal.css({ cursor: 'pointer' });
                retVal.on('click', () => {
                    const user: ALIAS = info.data;
                    void this.actionEdit.intent(user);

                });
                return retVal;
            }
        });
        retVal.filterValue = ['isDeleted', '=', false];
        return retVal;
    });

    public readonly actionNewSubject = new UIAction(undefined, async () => {
        const r = await ServerConnection.api.ui_superuser_testaliasoverview_new({});

        await ROUTES.routeManager.navigateToHREF(WIDGET_EDIT.FACTORY.href({
            docRef: r.TestAlias.new.docReferenceId
        }));
    });

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'plus',
                text: i18next.t(['ui.superuser.testaliasoverview.NEW']),
                onClick: this.actionNewSubject.click
            })
        }));
        return DONE;
    }


    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

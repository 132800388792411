import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import * as API from '../../../../its-itembank-api.g';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, datagrid, refreshDx, selectBoxDS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import * as i18next from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-lavak-multiplechoice-edit';

export type IParams = IItemDefinitionWidgetParams;
type Q = Awaited<ReturnType<API.Sdk['multiplechoice_edit_data']>>;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);

    private readonly _answers: Q['MultipleChoiceEdit']['get']['answers'] = [];

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.lavak.multiplechoice.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly scoring = ko.observable<API.MultipleChoiceEdit_Scoring>();
    public readonly shuffle = ko.observable<API.MultipleChoiceEdit_Shuffle>();
    public readonly minSelectionLimit = ko.observable(0);
    public readonly maxSelectionLimit = ko.observable(0);

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'index',
                        data: this._answers
                    }
                },
                editing: {
                    allowAdding: this.canEdit,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
            }
        });
        retVal.columns.push(
            {
                dataField: 'index',
                caption: i18next.t(['itemdefinition.lavak.multiplechoice.edit.ID']),
                width: 40,
                allowEditing: false
            });
        retVal.columns.push(
            {
                dataField: 'correct',
                caption: i18next.t(['itemdefinition.lavak.multiplechoice.edit.CORRECT']),
                dataType: 'boolean',
                width: 80
            });
        retVal.columns.push(
            {
                dataField: 'score',
                caption: i18next.t(['itemdefinition.lavak.multiplechoice.edit.SCORE']),
                width: 80,
                dataType: 'number'
            });
        retVal.columns.push(
            {
                dataField: 'value.value',
                caption: i18next.t(['itemdefinition.lavak.multiplechoice.edit.TEXT']),
            });

        retVal.onInitNewRow = e => {
            let index = '';
            for (let i = 0; i < 26; ++i) {
                index = String.fromCharCode(65 + i);
                if (!this._answers.some(x => x.index === index)) {
                    break;
                }
            }
            if (!index) {
                index = 'A';
            }
            e.data = {
                index,
                value: {
                    value: i18next.t(['itemdefinition.lavak.multiplechoice.edit.NEW'])
                },
                correct: true,
                score: 0
            };
        };
        retVal.onRowInserting = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.multiplechoice_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.data.index,
                            value: xnone(e.data.value && e.data.value.value),
                            correct: e.data.correct,
                            score: e.data.score,
                        }]
                    }
                }));
            })();
        };
        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.multiplechoice_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeAnswers: [e.key]
                    }
                }));
            })();
        };

        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.multiplechoice_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.key,
                            correct: e.newData.correct,
                            score: e.newData.score,
                            value: xnone(e.newData.value && e.newData.value.value),
                        }]
                    }
                }));
            })();
        };
        return retVal;
    });

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.multiplechoice_edit_data({
            itemId: this.itemId
        });
        const d = q.MultipleChoiceEdit.get;
        this._answers.splice(0, this._answers.length, ...d.answers);
        this.question(d.question.value);
        this.header(d.header.value);
        this.minSelectionLimit(d.minSelectionLimit);
        this.maxSelectionLimit(d.maxSelectionLimit);
        this.scoring(d.scoring);
        this.shuffle(d.shuffle);
        await refreshDx(this);
    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.lavak.multiplechoice.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.lavak.multiplechoice.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.lavak.multiplechoice.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.lavak.multiplechoice.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));

        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                scoring: this.scoring,
                shuffle: this.shuffle,
                minSelectionLimit: this.minSelectionLimit,
                maxSelectionLimit: this.maxSelectionLimit,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'scoring',
            label: {
                text: i18next.t(['itemdefinition.lavak.multiplechoice.edit.POINTS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.MultipleChoiceEdit_Scoring.AllOrNothing,
                    value: i18next.t(['itemdefinition.lavak.multiplechoice.edit.FOR_ITEM']),
                },
                {
                    key: API.MultipleChoiceEdit_Scoring.PerAnswer,
                    value: i18next.t(['itemdefinition.lavak.multiplechoice.edit.PER_ANSWER']),
                }
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'shuffle',
            label: {
                text: i18next.t(['itemdefinition.lavak.multiplechoice.edit.SHUFFLE_MODE'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.MultipleChoiceEdit_Shuffle.Shuffled,
                    value: i18next.t(['itemdefinition.lavak.multiplechoice.edit.SHUFFLED']),
                },
                {
                    key: API.MultipleChoiceEdit_Shuffle.Ordered,
                    value: i18next.t(['itemdefinition.lavak.multiplechoice.edit.FIXED']),
                },
                {
                    key: API.MultipleChoiceEdit_Shuffle.ShuffledExceptLast,
                    value: i18next.t(['itemdefinition.lavak.multiplechoice.edit.LAST_LINE_FIXED']),
                },
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'minSelectionLimit',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.lavak.multiplechoice.edit.SELECTION_LIMIT_MIN'])
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'maxSelectionLimit',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.lavak.multiplechoice.edit.SELECTION_LIMIT_MAX'])
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
            }),
        }));


        return retVal;
    });

    public async initialize() {
        await super.initialize();

        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async v => {
            await ServerConnection.api.multiplechoice_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.multiplechoice_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.minSelectionLimit, `${WIDGET_NAME}/${this.itemId}/minSelectionLimit`, async v => {
            await ServerConnection.api.multiplechoice_edit_update({
                params: {
                    itemId: this.itemId,
                    minSelectionLimit: v
                }
            });
            return DONE;
        });
        this.onChange(this.maxSelectionLimit, `${WIDGET_NAME}/${this.itemId}/maxSelectionLimit`, async v => {
            await ServerConnection.api.multiplechoice_edit_update({
                params: {
                    itemId: this.itemId,
                    maxSelectionLimit: v
                }
            });
            return DONE;
        });
        this.onChange(this.scoring, `${WIDGET_NAME}/${this.itemId}/scoring`, async v => {
            await ServerConnection.api.multiplechoice_edit_update({
                params: {
                    itemId: this.itemId,
                    scoring: v
                }
            });
            return DONE;
        });
        this.onChange(this.shuffle, `${WIDGET_NAME}/${this.itemId}/shuffle`, async v => {
            await ServerConnection.api.multiplechoice_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffle: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }

    public readonly answerAdd = new UIAction(undefined, async () => {
        const id = this.findUnusedAnswerId();
        if (!id) {
            toastService.error(i18next.t(['itemdefinition.lavak.multiplechoice.edit.YOU_CANNOT_ADD_MORE_THAN_26_SENTENCES']));
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.multiplechoice_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertAnswers: [{
                        index: id,
                        value: {
                            'xnone': ''
                        }
                    }]
                }
            });
        });
    });

    public findUnusedAnswerId() {
        const existing: { [key: string]: boolean } = {};
        const answers = this._answers;
        if (answers.length >= 26) {
            return undefined;
        }

        for (let i = 0; i < answers.length; ++i) {
            existing[answers[i].index] = true;
        }
        for (let i = 0; i < 26; ++i) {
            const letter = String.fromCharCode(65 + i);
            if (!(letter in existing)) {
                return letter;
            }
        }
        return undefined;
    }

    public readonly question = ko.observable('');
    public readonly header = ko.observable('');

    public readonly enterSentencePlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.lavak.multiplechoice.edit.ENTER_ONE_SENTENCE_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

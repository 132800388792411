import STABLE_STRINGIFY from 'json-stable-stringify';
import * as ko from 'knockout';

interface IDifferentOptions {
    ignoreNull: boolean
}
//Compares to Stable-Stringified objects and only notifies subscribers about a change when the value has changed
ko.extenders['different'] = (target: ko.Observable<any>, options: IDifferentOptions) => {
    // let initialized = false;
    var result = ko.pureComputed({
        read: target,
        write: (newVal) => {
            const current = STABLE_STRINGIFY(ko.unwrap(target));
            const val = ko.unwrap(newVal);
            if (!val) {
                if (options && !options.ignoreNull) { return; }
                target(null);
                return;
            }
            const newStable = STABLE_STRINGIFY(ko.unwrap(val));
            if (current !== newStable) {
                target(val);
                // initialized = true;
            }
        }
    });
    result(target);
    return result;
};
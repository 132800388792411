import * as ko from 'knockout';
import { log } from '../../../debug';
import { AS } from '../../../dx_helper';
import { formatMessage } from '../../../i18n/data';
import { SurveyCombo_ShowSubItems } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import * as WIDGET_HEADER from '../../kosovo/widgets/header/widget';
import { GetSession } from '../../model/session';
import { SurveyComboData, SurveyComboData_SubItem } from '../../model/surveycombo/SurveyComboData';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveycombo-result';

export type IParams = IItemDefinitionWidgetParams;


interface Btn {
    isSelected: boolean;
    value: string;
}

class SubItem {
    public readonly itemDocRefId: string;

    constructor(readonly model: MyModel, readonly _data: SurveyComboData_SubItem) {
        const nr = this._data.index + 1;
        const title = formatMessage(i18n.t(['itemdefinition.surveycombo.result.PART_NR']), { nr });
        this.title(title);
        this.itemType(_data.itemType);
        this.itemDocRefId = _data.itemDocRefId;
    }

    public readonly title = ko.observable('');
    public readonly itemType = ko.observable('');

    public readonly isDone = ko.pureComputed(() => {
        return this.model.data.meta.session.getItemData(this._data.itemDocRefId).IsInteractionComplete();
    });

    public readonly cssClass = ko.pureComputed(() => {
        return 'NOTATTEMPTED';
    });
}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly data: SurveyComboData;


    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        this.itemDocId = 'item,' + this.itemId;

        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SurveyComboData)) {
            throw new Error();
        }
        this.data = data;
        this.subItems = this.data.subItemsVisible() && data.subItems.map(x => new SubItem(this, x));

    }


    public readonly buttons = ko.pureComputed(() => {
        if (this.data.order === 'None') {
            return undefined;
        }
        const retVal: Btn[] = [];
        const show = AS<Btn>({
            value: this.data.showCaption,
            isSelected: this.data.showSubItems() === SurveyCombo_ShowSubItems.Yes,
        });
        const hide = AS<Btn>({
            value: this.data.hideCaption,
            isSelected: this.data.showSubItems() === SurveyCombo_ShowSubItems.No,
        });
        if (this.data.order === 'ShowHide') {
            return [show, hide];
        }
        if (this.data.order === 'HideShow') {
            return [hide, show];
        }
        throw new Error();
    });

    public readonly headerParams = ko.pureComputed(() => {
        const retVal: WIDGET_HEADER.IParams = {
            questionHTML: this.questionHTML(),
            headerText: this.headerText(),
            mode: this.mode()
        };
        return retVal;
    });

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        return false;
    }


    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });
        await super.initialize();
        const mode = this.mode();

        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);


        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly noSubItemsText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.surveycombo.result.NO_SUBITEMS_HAVE_BEEN_ADDED_YET']);
    });

    public readonly hasSubItems = ko.pureComputed(() => {
        return this.subItems && this.subItems.length > 0;
    });

    public readonly subItems: SubItem[];

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir, log } from '../../../debug';
import { AS, datagrid, refreshDx, selectBoxDS } from '../../../dx_helper';
import * as HELPER from '../../../helper';
import { bounds, DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { indexToLatin } from '../../../new_array';
import { queueApiCall } from '../../../ui/docmanager';
import { ServerConnection } from '../../../ui/RestAPI';
import { UIAction } from '../../../ui/uiAction';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as DS from './../../../enums/datasource';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-invisiblehotspot-edit';
type Q = Awaited<ReturnType<API.Sdk['invisiblehotspot_edit_data']>>;
let TMP_ID = 0;
type ANSWER = Q['InvisibleHotSpotEdit']['get']['answers'][0];
type RECT = ANSWER['rects'][0];
type CIRCLE = ANSWER['circles'][0];

type mode = 'New' | 'Info' | 'SetCenter' | 'SetLeft' | 'SetTop' | 'SetRight' | 'SetBottom' | 'SetTopLeft' | 'SetBottomRight';

interface ButtonGroupItem<T> extends DevExpress.ui.dxButtonGroup.Item {
    key: T
}
type ModeButton = ButtonGroupItem<mode>;

function tmpId() {
    return 'TMP_' + (TMP_ID++);
}
interface SVGRect {
    type: 'rect';
    width: string;
    height: string;
    cssClass: string;
    x: string;
    y: string;
    id: string;
}
interface SVGCircle {
    type: 'circle';
    cx: string;
    cy: string;
    r: string;
    cssClass: string;
}
type SVGObj = SVGCircle | SVGRect;

function n(n: number) {
    return Math.round(n * 10) / 10;
}

class Rect {
    constructor(readonly model: ViewModel, readonly id: string) {

    }
    public cX = ko.observable(0);
    public cY = ko.observable(0);
    public width = ko.observable(0);
    public height = ko.observable(0);
    public answer = ko.observable('A');

    public isFocused = ko.pureComputed(() => {
        log(`isFocused ${this.id}===${this.model.focusedShape()}`);
        return this.id === this.model.focusedShape();
    });

    hittest(rectA: { left: number, top: number, width: number, height: number }) {

        const rectB = {
            left: this.cX() - this.width() / 2,
            top: this.cY() - this.height() / 2,
            width: this.width(),
            height: this.height()
        };
        const rA = rectA.left + rectA.width; // Right side of rectA
        const rB = rectB.left + rectB.width; // Right side of rectB
        const bA = rectA.top + rectA.height; // Bottom of rectA
        const bB = rectB.top + rectB.height; // Bottom of rectB

        const hitX = rA > rectB.left && rectA.left < rB; // True if hit on x-axis
        const hitY = bA > rectB.top && rectA.top < bB; // True if hit on y-axis

        // Return true if hit on x and y axis
        return hitX && hitY;
    }

    public svgDeadZone = ko.pureComputed(() => {
        const mW = this.model.markerWidth();
        const mH = this.model.markerHeight();
        const w = this.width() + 2 * mW;
        const h = this.height() + 2 * mH;
        const selectedClass = this.isFocused() ? 'areaSelected' : '';
        return AS<SVGRect>({
            id: this.id,
            type: 'rect',
            width: w + '%',
            height: h + '%',
            x: (this.cX() - w / 2) + '%',
            y: (this.cY() - h / 2) + '%',
            cssClass: `area-${this.answer()} restricted ${selectedClass}`,
        });
    });

    public svgShape = ko.pureComputed(() => {
        const w = this.width();
        const h = this.height();
        const selectedClass = this.isFocused() ? 'areaSelected' : '';
        return AS<SVGRect>({
            id: this.id,
            type: 'rect',
            width: w + '%',
            height: h + '%',
            x: (this.cX() - w / 2) + '%',
            y: (this.cY() - h / 2) + '%',
            cssClass: `area-${this.answer()} area ${selectedClass}`,
        });
    });
}

function hittest_circle(circle: { x: number, y: number, r: number }, box: { left: number, top: number, width: number, height: number }) {
    const cx = circle.x;
    const cy = circle.y;
    const radius = circle.r;
    // temporary variables to set edges for testing
    let testX = cx;
    let testY = cy;

    // which edge is closest?
    if (cx < box.left) {
        testX = box.left;      // test left edge
    } else if (cx > box.left + box.width) {
        testX = box.left + box.width;   // right edge
    }
    if (cy < box.top) {
        testY = box.top;      // top edge
    } else if (cy > box.top + box.height) {
        testY = box.top + box.height;   // bottom edge
    }

    // get distance from closest edges
    const distX = cx - testX;
    const distY = cy - testY;
    const distance = Math.sqrt((distX * distX) + (distY * distY));

    // if the distance is less than the radius, collision!
    if (distance <= radius) {
        return true;
    }
    return false;
}


export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly focusedShape = ko.observable('');
    public readonly loaded = ko.observable(false);
    //public readonly answers = ko.observableArray();
    public readonly header = ko.observable('');
    public readonly question = ko.observable('');

    public readonly scoringMode = ko.observable<API.InvisibleHotSpotEdit_Scoring>(API.InvisibleHotSpotEdit_Scoring.AllOrNothing);
    public readonly perAnswerScore = ko.observable(0);
    public readonly minAnswers = ko.observable(0);
    public readonly maxAnswers = ko.observable(0);
    public readonly image = ko.observable('');
    public readonly imageWidth = ko.observable(0);
    public readonly imageHeight = ko.observable(0);
    public readonly markerWidth = ko.observable(0);
    public readonly markerHeight = ko.observable(0);
    public readonly markerRadius = ko.observable(0);
    public readonly hasImage = ko.pureComputed(() => {
        return !!this.imgUrl();
    });

    private readonly grid = ko.observable<DevExpress.ui.dxDataGrid>();
    private readonly _answers: ANSWER[] = [];
    private readonly _rectsDS = ko.observableArray<Rect>([]);


    private readonly _attachments: Q['documents']['get']['attachments'] = [];

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }


    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.HEADER'])
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.invisiblehotspot.edit.ENTER_THE_HEADER_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.invisiblehotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']),
        }));
        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                minAnswers: this.minAnswers,
                maxAnswers: this.maxAnswers,
                image: this.image,
                imageWidth: this.imageWidth,
                imageHeight: this.imageHeight,
                markerWidth: this.markerWidth,
                markerHeight: this.markerHeight,
                markerRadius: this.markerRadius,
                perAnswerScore: this.perAnswerScore,
                scoringMode: this.scoringMode,
            },
            colCount: 3,
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'image',
            editorType: 'dxSelectBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.singlechoicehotspot.edit.IMAGE']),
            },
            editorOptions: this.imageOptions(),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageWidth',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.IMAGE_WIDTH']),
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'decimal',
                },

            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageHeight',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.IMAGE_HEIGHT']),
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'decimal',
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'markerWidth',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.MARKER_WIDTH'])
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'markerHeight',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.MARKER_HEIGHT'])
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'markerRadius',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.MARKER_RADIUS'])
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'minAnswers',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.MINIMUM_ANSWERS']),
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'decimal',
                },
            }
            ),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'maxAnswers',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.MAXIMUM_ANSWERS']),
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: -1,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'decimal',
                },
            }
            ),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            label: {
                text: ''
            },
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'perAnswerScore',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.PER_ANSWER_SCORE']),
            },
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'scoringMode',
            label: {
                text: i18n.t(['itemdefinition.invisiblehotspot.edit.SCORING_MODE']),
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS(DS.InvisibleHotSpotEdit_Scoring()),
        }));

        return retVal;
    });

    public readonly imageOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxSelectBox.Properties = {
            value: this.image,
            dataSource: this._attachments,
            valueExpr: 'name',
            displayExpr: 'name',
        };
        return retVal;
    });

    public readonly deadzones = ko.pureComputed(() => {
        return this._rectsDS().map(x => x.svgDeadZone());
    });

    public readonly rects = ko.pureComputed(() => {
        return this._rectsDS().map(x => x.svgShape());
    });

    public readonly markerPos = ko.observable<{ xP: number; yP: number }>();

    public readonly hit = ko.pureComputed<string>(() => {
        const pos = this.markerPos();
        if (!pos) {
            return '';
        }
        const { xP, yP } = pos;
        const marker_w = this.markerWidth();
        const marker_h = this.markerHeight();

        const boundingBox = {
            left: (xP - (marker_w / 2)),
            top: (yP - (marker_h / 2)),
            width: marker_w,
            height: marker_h,
        };

        for (const rect of this._rectsDS()) {
            if (rect.hittest(boundingBox)) {
                return rect.id;
            }
        }
        return '';
    });

    public readonly marker = ko.pureComputed<SVGObj[]>(() => {
        const pos = this.markerPos();
        if (!pos) {
            return [];
        }
        const { xP, yP } = pos;
        const marker_r = this.markerRadius();
        const marker_w = this.markerWidth();
        const marker_h = this.markerHeight();

        const boundingBox = {
            left: (xP - (marker_w / 2)),
            top: (yP - (marker_h / 2)),
            width: marker_w,
            height: marker_h,
        };

        let isHit = false;
        for (const rect of this._rectsDS()) {
            if (rect.hittest(boundingBox)) {
                isHit = true;
            }
        }


        const marker: SVGObj[] = [];
        marker.push({
            type: 'rect',
            x: boundingBox.left + '%',
            y: boundingBox.top + '%',
            width: marker_w + '%',
            height: marker_h + '%',
            cssClass: isHit ? 'hit' : 'notHit',
            id: '',
        });
        marker.push({
            type: 'circle',
            cx: xP + '%',
            cy: yP + '%',
            r: marker_r + '%',
            cssClass: isHit ? 'hit' : 'notHit'
        });
        return marker;
    });

    private onAreasChanged() {

        const areas = Array.from(new Set(this._rectsDS().map(x => x.answer()))).sort();
        this._answers.splice(0, this._answers.length, ...areas.map(answer => {
            return AS<ANSWER>({
                id: answer,
                index: answer,
                circles: [],
                rects: this._rectsDS().filter(x => x.answer() === answer).map((x, idx) => {
                    const index = indexToLatin(idx);
                    return AS<RECT>({
                        left: x.cX() - x.width() / 2,
                        top: x.cY() - x.height() / 2,
                        width: x.width(),
                        height: x.height(),
                        id: `${answer}/${index}`,
                        index
                    });
                })
            });
        }));


        queueApiCall(`${WIDGET_NAME}/${this.itemId}/areas`, async () => {
            return ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    setAnswers: this._answers.map(x => ({
                        index: x.index,
                        setRects: x.rects.map(y => ({
                            index: y.index,
                            left: y.left,
                            top: y.top,
                            width: y.width,
                            height: y.height,
                        })),
                    })),
                }
            });
        });

    }




    public readonly clickImage = new UIAction(undefined, async (_1, _2, e) => {
        const mode = this.clickMode();
        log(`clicked on image in mode ${mode} `);
        dir(e);


        const x = bounds(e.offsetX, 0, this.actualWidth());
        const y = bounds(e.offsetY, 0, this.actualHeight());


        const xP = x * 100 / this.actualWidth();
        const yP = y * 100 / this.actualHeight();

        const focusedKey = this.focusedShape();
        const focused = focusedKey && this._rectsDS().find(x => x.id === focusedKey);
        log(`focused: ${focusedKey}`);

        switch (mode) {
            case 'New':
                const r = new Rect(this, tmpId());
                r.cX(n(xP));
                r.cY(n(yP));
                r.width(n(focused && focused.width() || 5));
                r.height(n(focused && focused.height() || 5));
                r.answer(focused && focused.answer() || 'A');
                this._rectsDS.push(r);
                if (this.grid()) {
                    void this.grid().refresh();
                }
                break;
            case 'Info':
                this.markerPos({ xP, yP });
                const hit = this.hit();
                if (hit && this.grid()) {
                    log(`navigate to ${hit}`);
                    this.grid().navigateToRow(hit);
                    await this.grid().selectRows([hit], false);
                    this.focusedShape(hit);
                }
                return;
            case 'SetCenter':
                if (focused) {
                    focused.cX(n(xP));
                    focused.cY(n(yP));
                }
                break;
            case 'SetLeft':
                if (focused) {
                    const left = xP;
                    const right = focused.cX() + focused.width() / 2;
                    const width = right - left;
                    focused.cX(n(left + width / 2));
                    focused.width(n(width));

                }
                break;
            case 'SetRight':
                if (focused) {
                    const left = focused.cX() - focused.width() / 2;
                    const right = xP;
                    const width = right - left;
                    focused.cX(n(left + width / 2));
                    focused.width(n(width));
                }
                break;

            case 'SetTop':
                if (focused) {
                    const top = yP;
                    const bottom = focused.cY() + focused.height() / 2;
                    const height = bottom - top;
                    focused.cY(n(top + height / 2));
                    focused.height(n(height));

                }
                break;
            case 'SetBottom':
                if (focused) {
                    const top = focused.cY() - focused.height() / 2;
                    const bottom = yP;
                    const height = bottom - top;
                    focused.cY(n(top + height / 2));
                    focused.height(n(height));
                }
                break;
            case 'SetTopLeft':
                if (focused) {
                    const brX = focused.cX() + focused.width() / 2;
                    const brY = focused.cY() + focused.height() / 2;
                    const w = bounds(brX - xP, 0.5, 100);
                    const h = bounds(brY - yP, 0.5, 100);
                    focused.cX(n(brX - w / 2));
                    focused.cY(n(brY - h / 2));
                    focused.width(n(w));
                    focused.height(n(h));
                }
                break;
            case 'SetBottomRight':
                if (focused) {
                    const tlX = focused.cX() - focused.width() / 2;
                    const tlY = focused.cY() - focused.height() / 2;
                    const w = bounds(xP - tlX, 0.5, 100);
                    const h = bounds(yP - tlY, 0.5, 100);
                    focused.cX(n(tlX + w / 2));
                    focused.cY(n(tlY + h / 2));
                    focused.width(n(w));
                    focused.height(n(h));


                }
                break;
        }
        //await refreshDx(this);
        this.onAreasChanged();

    });

    public readonly clickMode = ko.observable<mode>('Info');

    public readonly gridOptions = ko.pureComputed(() => {
        const gridOptions = datagrid({
            WIDGET_NAME,
            discriminator: 'main_v1',
            widget: this,
            gridVar: this.grid,
            config: {
                dataSource: this._rectsDS,
                keyExpr: 'id',
                autoNavigateToFocusedRow: true,
                editing: {
                    allowAdding: false,
                    allowUpdating: !this.isReadOnly,
                    allowDeleting: !this.isReadOnly,
                    mode: 'cell',
                },
                focusedRowEnabled: true,

                onRowUpdated: e => {
                    log(`on row updated ${e.key}`);
                    this.onAreasChanged();
                },
                onRowRemoved: e => {
                    log(`on row removed ${e.key}`);
                    //this._rectsDS.remove(x => x.id === e.key);
                    this._rectsDS.valueHasMutated();
                    this.onAreasChanged();
                },
                onRowInserted: e => {
                    this.onAreasChanged();
                },
                onFocusedRowChanged: e => {
                    const key = e.row && e.row.key;
                    log(`on focused row changed to ${key}`);
                    this.focusedShape(key);
                },

            }

        });
        gridOptions.onToolbarPreparing = e => {
            e.toolbarOptions.items.unshift(AS<DevExpress.ui.dxToolbarItem>({
                location: 'before',
                locateInMenu: 'never',
                widget: 'dxButtonGroup',
                options: AS<DevExpress.ui.dxButtonGroup.Properties>({
                    items: [
                        AS<ModeButton>(
                            {
                                key: 'Info',
                                icon: 'info',
                            }),
                        AS<ModeButton>(
                            {
                                key: 'New',
                                icon: 'plus',
                            }),
                        AS<ModeButton>({
                            key: 'SetTopLeft',
                            icon: 'set-top-left',
                        }),
                        AS<ModeButton>({
                            key: 'SetBottomRight',
                            icon: 'set-bottom-right',
                        }),
                        AS<ModeButton>({
                            key: 'SetCenter',
                            icon: 'set-center',
                        }),
                        AS<ModeButton>({
                            key: 'SetTop',
                            icon: 'set-top',
                        }),
                        AS<ModeButton>({
                            key: 'SetBottom',
                            icon: 'set-bottom',
                        }),
                        AS<ModeButton>({
                            key: 'SetLeft',
                            icon: 'set-left',
                        }),
                        AS<ModeButton>({
                            key: 'SetRight',
                            icon: 'set-right',
                        }),
                    ],
                    selectionMode: 'single',
                    keyExpr: 'key',
                    selectedItemKeys: [this.clickMode()],
                    onSelectionChanged: e2 => {
                        log('button group selection changed');
                        dir(e2);
                        if (!e2.addedItems || !e2.addedItems.length) {
                            this.clickMode('Info');
                        } else {
                            const item: ModeButton = e2.addedItems[0];
                            this.clickMode(item.key);
                        }
                        log(`Clickmode: ${this.clickMode()}`);
                    }
                })
            }));
        };

        gridOptions.columns.push({
            dataField: 'answer',
            caption: i18n.t(['itemdefinition.invisiblehotspot.edit.ANSWER']),
            dataType: 'string',
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                maxLength: 1,
            }),
            validationRules: [{
                type: 'pattern',
                pattern: /^[A-Z]$/,
            }]
        });

        gridOptions.columns.push({
            dataField: 'cX',
            caption: i18n.t(['itemdefinition.invisiblehotspot.edit.CENTERX']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
                showSpinButtons: true,
                mode: 'number',
            }),
        });
        gridOptions.columns.push({
            dataField: 'cY',
            caption: i18n.t(['itemdefinition.invisiblehotspot.edit.CENTERY']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        });
        gridOptions.columns.push({
            dataField: 'width',
            caption: i18n.t(['itemdefinition.invisiblehotspot.edit.WIDTH']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        });
        gridOptions.columns.push({
            dataField: 'height',
            caption: i18n.t(['itemdefinition.invisiblehotspot.edit.HEIGHT']),
            dataType: 'number',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
                showSpinButtons: true,
                mode: 'number',
                format: {
                    type: 'fixedPoint',
                    precision: 1,
                },
            }),
        });

        return gridOptions;
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.invisiblehotspot_edit_data({
            itemId: this.itemId
        });
        const d = q.InvisibleHotSpotEdit.get;
        this.header(d.header.value);
        this.question(d.question.value);
        this.minAnswers(d.minAnswers);
        this.maxAnswers(d.maxAnswers);
        this.image(d.image && d.image.name);
        this.imageWidth(d.imageWidth || 0);
        this.imageHeight(d.imageHeight);
        this.markerWidth(d.markerWidth);
        this.markerHeight(d.markerHeight);
        this.markerRadius(d.markerRadius);
        this.perAnswerScore(d.perAnswerScore);
        this.scoringMode(d.scoringMode);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);

        this._answers.splice(0, this._answers.length, ...d.answers);

        const rects: Rect[] = [];
        let aIdx = 0;
        for (const a of this._answers) {
            aIdx++;
            let rIdx = 0;
            for (const rSrc of a.rects) {
                rIdx++;
                const vmRect = new Rect(this, rSrc.id);
                vmRect.answer(a.index);
                vmRect.cX(rSrc.left + rSrc.width / 2);
                vmRect.cY(rSrc.top + rSrc.height / 2);
                vmRect.width(rSrc.width);
                vmRect.height(rSrc.height);
                rects.push(vmRect);
            }
        }
        this._rectsDS(rects);


        await refreshDx(this);

    }


    public readonly imgValid = ko.pureComputed(() => {
        return !!this.imgUrl();
    });

    public readonly imgUrl = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        if (!att) {
            return undefined;
        }
        return ServerConnection.getDataUrl(att && att.hrefResolved);
    });

    public readonly naturalImageWidth = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.width || 100;
    });
    public readonly naturalImageHeight = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.height || 100;
    });

    public readonly actualWidth = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).width;
    });
    public readonly actualHeight = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).height;
    });

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();



        this.onChange(this.scoringMode, `${WIDGET_NAME}/${this.itemId}/scoringMode`, async v => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    scoringMode: v,
                }
            });
            return DONE;
        });
        this.onChange(this.perAnswerScore, `${WIDGET_NAME}/${this.itemId}/perAnswerScore`, async v => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    perAnswerScore: v,
                }
            });
            return DONE;
        });
        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async v => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.minAnswers, `${WIDGET_NAME}/${this.itemId}/minAnswers`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    minAnswers: v
                }
            });
            return DONE;
        });
        this.onChange(this.maxAnswers, `${WIDGET_NAME}/${this.itemId}/maxAnswers`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    maxAnswers: v
                }
            });
            return DONE;
        });
        this.onChange(this.markerWidth, `${WIDGET_NAME}/${this.itemId}/markerWidth`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    markerWidth: v,
                }
            });
            return DONE;
        });
        this.onChange(this.markerHeight, `${WIDGET_NAME}/${this.itemId}/markerHeight`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    markerHeight: v,
                }
            });
            return DONE;
        });
        this.onChange(this.markerRadius, `${WIDGET_NAME}/${this.itemId}/markerRadius`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    markerRadius: v,
                }
            });
            return DONE;
        });
        this.onChange(this.image, `${WIDGET_NAME}/${this.itemId}/image`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    image: v
                }
            });
            return DONE;
        });
        this.onChange(this.imageWidth, `${WIDGET_NAME}/${this.itemId}/imageWidth`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    imageWidth: v
                }
            });
            return DONE;
        });
        this.onChange(this.imageHeight, `${WIDGET_NAME}/${this.itemId}/imageHeight`, async (v) => {
            await ServerConnection.api.invisiblehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    imageHeight: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }



}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import $ from 'jquery';
import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { dir } from '../../../debug';
import { AS } from '../../../dx_helper';
import * as helper from '../../../helper';
import { DONE } from '../../../helper';
import * as $LANG from '../../../i18n/data';
import { formatMessage } from '../../../i18n/data';
import * as API from '../../../its-itembank-api.g';
import { GradingMode } from '../../../its-itembank-api.g';
import * as Modal from '../../../modal';
import { ItemMode } from '../../../model/interfaces';
import { toastService } from '../../../toastService';
import { DefaultWindowManager } from '../../base';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import { UIAction } from '../../uiAction';
import * as WIDGET_MANAGEITEM from '../manageitem/route';
import * as WIDGET_NEWITEM from '../newitem/route';
import * as i18next from './../../../i18n/i18n';
//import * as M_ADHOC from './adhocExam.mutation.graphql.g';
//import * as Q from './getConfig.query.graphql.g';
import { htmlString } from './itemedit.html.g';
import * as METADATA from './itemmetadatablock/widget';
import { deleteItem } from './operations';
import * as ITEM_EDIT_ROUTE from './route';
import { IParams, WIDGET_NAME } from './route';
//import * as ACTION_SETTODONE from './setToDone.mutation.graphql.g';
import * as TRANSLATION from './translation/widget';
//import * as ACTION_VALIDATE from './validate.mutation.graphql.g';
type Q = Awaited<ReturnType<API.Sdk['ui_author_itmeedit_getconfig']>>;
type PROFILE = Q['TestDefinitionProfile']['all'][0];

export class ViewModel extends AbstractWidget {

    public readonly selectedItemId = ko.observable<string>();

    public readonly topItemDocRefId = ko.observable('');

    public readonly _validationMessage = ko.observable('');
    public readonly validationMessage = ko.pureComputed(() => {
        const msg = this._validationMessage();
        if (!msg) {
            return '';
        }
        return msg;
    });

    constructor(readonly params: IParams) {
        super();
    }

    public readonly isSubItem = ko.observable(false);
    public readonly editAllowed = ko.observable(false);
    public readonly actionSetToDone = new UIAction(undefined, async () => {
        const itemId = this.params.currentRoute.widgetParams.itemDocRefId;
        const v = await legacyPushPull(() => ServerConnection.api.ui_author_itemedit_validate({
            itemId,
        }));
        if (!v.item.validate.ok) {
            toastService.info(v.item.validate.message.value);
            return;
        }

        const message = i18next.t(['ui.author.itemedit.DO_YOU_REALLY_WANT_TO_SET_THE_STATUS_OF_THIS_ITEM_TO_DONE_BR_REMEMBER_THAT_AN_ITEM_WITH_THE_STATUS_DONE_CAN_NO_LONGER_BE_CHANGED_BR_ITEMS_WITH_THE_STATUS_DONE_CAN_ONLY_BE_DISPLAYED_IN_THE_PREVIEW_MODE']);


        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        const m = await legacyPushPull(() => ServerConnection.api.ui_author_itemedit_settodone({
            itemId
        }));
        if (m.item.setToDone.ok) {
            await ROUTES.routeManager.back();
            return;
        }
        toastService.error(m.item.setToDone.message.value);
    });

    private readonly gradingMode = ko.observable<GradingMode>(GradingMode.Atonce);
    public async OnRefresh() {
        await super.OnRefresh();
        const itemId = this.selectedItemId();
        const r = await ServerConnection.api.ui_author_itmeedit_getconfig({
            itemId,
        });

        const d = r.item.get;
        this.isGotoParentAvailable(d.metaData.isSubItem);
        this._validationMessage(d.basicValidationMessage && d.basicValidationMessage.value || '');
        this.isSubItem(d.metaData.isSubItem);
        this.subjectId(d.metaData.subject.subjectId);
        this.subjectDocRefId(d.metaData.subject.docReferenceId);
        this.parentItemId(d.parentItemId);
        this.topItemDocRefId(d.topItem.docReferenceId);
        this.gradingMode(d.itemType.gradingMode);

        this.editAllowed(d.workflow.editableByYou);
        this.translationsAllowed(r.config.itemTranslationEnabled && d.itemType.supportsTranslations);
        this.supportsSubItems(r.item.get.itemType.supportsSubItems);
        this.isActionDeleteAvailable(d.workflow.deleteAllowed);
        this.isActionSetToDoneAvailable(d.workflow.setToDoneAllowed);
        helper.update_observable(this.profiles, r.TestDefinitionProfile.all);
    }

    public readonly back = new UIAction(undefined, async () => {
        await ROUTES.routeManager.back();
    });

    public readonly noteForAuthor = ko.observable('');
    public readonly forAuthorVisible = ko.observable(false);

    public readonly actionShowNoteForAuthor = new UIAction(undefined, async () => {
        this.forAuthorVisible(true);
    });
    public readonly actionHideNoteForAuthor = new UIAction(undefined, async () => {
        this.forAuthorVisible(false);
    });


    public readonly subjectId = ko.observable('');
    public readonly subjectDocRefId = ko.observable('');
    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);

    public readonly previewLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.PREVIEW']);
    });
    public readonly resultLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.RESULT']);
    });
    public readonly gradingLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.GRADING']);
    });
    public readonly editLabel = ko.pureComputed(() => {
        if (this.editAllowed()) {
            return i18next.t(['ui.author.itemedit.EDIT']);
        } else {
            return i18next.t(['ui.author.itemedit.INSPECT']);
        }
    });
    public readonly assetsLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.ASSETS']);
    });
    public readonly metadataLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.METADATA']);
    });
    public readonly translationLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.TRANSLATION']);
    });
    public readonly selectedLabel = ko.observable('');
    public readonly isResult = ko.pureComputed(() => {
        return this.selectedLabel() === this.resultLabel();
    });
    public readonly isGrading = ko.pureComputed(() => {
        return this.selectedLabel() === this.gradingLabel();
    });
    public readonly isPreview = ko.pureComputed(() => {
        return this.selectedLabel() === this.previewLabel();
    });
    public readonly isEdit = ko.pureComputed(() => {
        return this.selectedLabel() === this.editLabel();
    });
    public readonly isAssets = ko.pureComputed(() => {
        return this.selectedLabel() === this.assetsLabel();
    });
    public readonly isMetadata = ko.pureComputed(() => {
        return this.selectedLabel() === this.metadataLabel();
    });
    public readonly isTranslation = ko.pureComputed(() => {
        return this.selectedLabel() === this.translationLabel();
    });


    public readonly edit_mode = ko.pureComputed<ItemMode>(() => {
        if (this.editAllowed()) {
            return 'EDIT';
        } else {
            return 'INSPECT';
        }
    });

    public tabs() {
        const items: DevExpress.ui.dxTabPanel.Item[] = [];
        const retVal: DevExpress.ui.dxTabPanel.Properties = {
            dataSource: items,
            deferRendering: true,

        };
        retVal.onSelectionChanged = e => {
            if (!e.addedItems || !e.addedItems.length) {
                this.selectedLabel('');
            } else {
                const item: DevExpress.ui.dxTabPanel.Item = e.addedItems[0];
                this.selectedLabel(item.title);
            }
        };
        retVal.onInitialized = e => {
            const tab = e.component.option('selectedItem');
            dir(tab);
        };
        items.push({
            title: this.editLabel(),
            visible: true,
            template: () => {
                const x = $(`
                    <div data-bind="if:isEdit" class="tab-wrapper">
                        <div data-bind="component:{name:'ui-widgets-advanceditempresenter', params:{ item: selectedItemId(), mode:edit_mode()}}"></div>
                    </div>
                    `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: this.assetsLabel(),
            visible: this.editAllowed(),
            template: () => {
                const x = $(`                
                <div data-bind="if:isAssets"  class="tab-wrapper">
                    <div data-bind="component:{name:'widgets-assetmanager',params:{docRef:selectedItemId(),docType:'item'}}"></div>
                </div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: this.previewLabel(),
            visible: true,
            template: () => {
                //the "if:isPreview" is necessary to force a reload of the item presenter. interactive mode does not aggressivly reload the data, as items are locked in an examination
                const x = $(`
                <div data-bind="if:isPreview" class="tab-wrapper">
                    <div data-bind="component:{name: 'ui-widgets-advanceditempresenter', params:{ item: topItemDocRefId(), mode: 'INTERACTIVE'}}" style="margin:10px"></div>
                </div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: this.gradingLabel(),
            visible: this.gradingAllowed(),
            template: () => {
                //the "if:isResult" is necessary to force a reload of the item presenter. result mode does not aggressivly reload the data, as items should are locked in an examination
                const x = $(`
            <div data-bind="if:isGrading" class="tab-wrapper">
                <div data-bind="component:{name: 'ui-widgets-advanceditempresenter', params:{ item: topItemDocRefId(),  mode: 'GRADING'}}" style="margin:10px"></div>
            </div>
            `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });

        items.push({
            title: this.resultLabel(),
            visible: true,
            template: () => {
                //the "if:isResult" is necessary to force a reload of the item presenter. result mode does not aggressivly reload the data, as items should are locked in an examination
                const x = $(`
                <div data-bind="if:isResult"  class="tab-wrapper">
                    <div data-bind="component:{name: 'ui-widgets-advanceditempresenter', params:{ item: topItemDocRefId(), mode: 'RESULT'}}" style="margin:10px"></div>
                </div>
                `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: this.metadataLabel(),
            visible: true,
            template: () => {
                const x = $(`
                <div data-bind="if:isMetadata"  class="tab-wrapper">
                <div data-bind="component:{name: '${METADATA.WIDGET_NAME}', params:{itemId:selectedItemId()} } "></div>
                </div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });

        items.push({

            title: i18next.t(['ui.author.itemedit.TRANSLATION']),
            visible: this.translationsAllowed(),
            template: () => {
                const x = $(`
                <div data-bind="if:isTranslation"  class="tab-wrapper">
                <div data-bind="component:{name: '${TRANSLATION.WIDGET_NAME}', params:{itemId:selectedItemId()} } "></div>
                </div>
                `);
                ko.applyBindings(this, x.get(0));
                return x;
            }
        });

        const item = items.find(x => ko.unwrap(x.visible));
        retVal.selectedItem = item;
        this.selectedLabel(item.title);
        return retVal;
    }

    private readonly supportsSubItems = ko.observable(false);
    private readonly translationsAllowed = ko.observable(false);
    private readonly gradingAllowed = ko.pureComputed(() => {
        const mode = this.gradingMode();
        switch (mode) {
            case GradingMode.Atonce:
            case GradingMode.None:
                return false;
            case GradingMode.Later:
            case GradingMode.Manual:
                return true;
            default:
                helper.assertNever(mode);
                throw new Error();
        }
    });
    private readonly isActionDeleteAvailable = ko.observable(false);
    private readonly isActionSetToDoneAvailable = ko.observable(false);
    private readonly isGotoParentAvailable = ko.observable(false);

    public readonly deleteItem = new UIAction(undefined, async () => {
        if (!await deleteItem(this.params.currentRoute.widgetParams.itemDocRefId)) {
            return;
        }
        await ROUTES.routeManager.back();
    });
    private readonly parentItemId = ko.observable<string[]>([]);
    public readonly goToParent = new UIAction(undefined, async () => {
        const parents = this.parentItemId() || [];
        if (!parents.length) {
            return;
        }
        const parent = parents[parents.length - 1];

        await ROUTES.routeManager.navigateToHREF(ITEM_EDIT_ROUTE.FACTORY.href({
            subjectDocRefId: this.subjectDocRefId(),
            itemDocRefId: parent,
        }));

    });
    private readonly profiles = ko.observable<PROFILE[]>();

    public readonly addSubItem = new UIAction(undefined, async () => {
        const subjectDocRefId = this.subjectDocRefId();
        const parentItemDocRefId = this.params.currentRoute.widgetParams.itemDocRefId;
        await ROUTES.routeManager.navigateToHREF(WIDGET_NEWITEM.FACTORY.href({
            subjectDocRefId,
            parentItemDocRefId,
        }));

    });

    public readonly manageItem = new UIAction(undefined, async () => {
        const subjectDocRefId = this.params.currentRoute.widgetParams.subjectDocRefId;
        const itemDocRefId = this.params.currentRoute.widgetParams.itemDocRefId;
        await ROUTES.routeManager.navigateToHREF(WIDGET_MANAGEITEM.FACTORY.href({
            subjectDocRefId,
            itemDocRefId
        }));

    });


    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'always',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'trash',
                text: i18next.t(['ui.author.itemedit.DELETE']),
                visible: <any>this.isActionDeleteAvailable,
                onClick: this.deleteItem.click
            }),
        }));
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'always',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'check',
                text: i18next.t(['ui.author.itemedit.FINISH']),
                visible: <any>this.isActionSetToDoneAvailable,
                onClick: this.actionSetToDone.click
            })
        }));
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'auto',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'arrowup',
                text: i18next.t(['ui.author.itemedit.PARENT_ITEM']),
                visible: <any>this.isGotoParentAvailable,
                onClick: this.goToParent.click
            })
        }));
        for (const profile of this.profiles()) {
            if (profile.examinationType === 'adhoc') {
                const title = profile.title;
                toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
                    location: 'after',
                    locateInMenu: 'always',
                    widget: 'dxButton',
                    options: AS<DevExpress.ui.dxButton.Properties>({
                        icon: 'fas fa-external-link-alt',
                        text: formatMessage(i18next.t(['ui.author.itemedit.TRY_IT_OUT_TITLE']), {
                            title
                        }),
                        onClick: () => {
                            void this.createAdHocExam.intent(profile);
                        }
                    })
                }));
            }
        }
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'always',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'far fa-clone',
                text: i18next.t(['ui.author.itemedit.COPY']),
                onClick: this.manageItem.click
            })
        }));
        if (this.supportsSubItems()) {
            toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
                location: 'after',
                widget: 'dxButton',
                visible: <any>this.editAllowed,
                locateInMenu: 'auto',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    icon: 'far fa-clone',
                    text: i18next.t(['ui.author.itemedit.ADD_SUBITEM']),
                    onClick: this.addSubItem.click
                })
            }));

        }
        return DONE;
    }

    public readonly createAdHocExam = new UIAction<PROFILE>(undefined, async (e, args) => {
        DefaultWindowManager.prepareOpenWindow('');
        const lang = $LANG.currentLanguageTag();
        const itemId = this.params.currentRoute.widgetParams.itemDocRefId;
        const result = await legacyPushPull(() => ServerConnection.api.ui_author_itemedit_createadhocexam({
            testDefinitionProfileDocRef: args.docReferenceId,
            itemDocRef: itemId,
            language: lang,
            userAgentString: window.navigator.userAgent,
        }));
        const r = result.author.createAdHocExam;
        if (r) {
            const s = r.sessions && r.sessions[0];
            DefaultWindowManager.openWindow(s.launchUrl, '');
        }
    });

    public async initialize() {
        await super.initialize();

        const itemDocRefId = this.params.currentRoute.widgetParams.itemDocRefId;
        this.selectedItemId(itemDocRefId);
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));


        this.loaded(true);
    }

    public readonly itemTitlePlaceholder = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.ENTER_THE_ITEM_TITLE_HERE_REQUIRED_INFORMATION_FOR_AUTHORS_AND_SUBJECT_COORDINATORS']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

﻿import * as ko from 'knockout';
import * as iso8601 from '../iso8601';
import { IDisposeable } from '../helper';

export class Timer implements IDisposeable {
    private totalTimeLeftMS = 0;
    private lastTickAtMS: number = null;
    private startAtMS: number = null;
    private msPassed = 0;
    private hasTimeLimit = false;

    public readonly timeLeftSeconds = ko.observable<number>(null);
    public readonly timePassedSeconds = ko.observable(0);
    public readonly timedOut = ko.observable(false);
    public readonly started = ko.observable(false);
    
    private iv: number;
    
    constructor() {
    }
    
    public dispose() {
        this.stop();
    }
    public getTimePassed() {
        //returns the milliseconds that were passed since first start()
        let retVal = this.msPassed;
        if (this.lastTickAtMS && this.startAtMS) {
            retVal += (this.lastTickAtMS - this.startAtMS);
        }
        return retVal;
    }

    public setTimeLimit(iso8601TimeLimit: string) {
        if (!iso8601TimeLimit) {
            return;
        }
        this.hasTimeLimit = true;
        this.totalTimeLeftMS = iso8601.isoDurationToCentisec(iso8601TimeLimit) * 10;
        this.timeLeftSeconds(Math.round(this.totalTimeLeftMS / 1000));
        this.timedOut(this.totalTimeLeftMS <= 0);
    }

    public start() {
        if (this.iv) {
            return;
        }
        if (this.hasTimeLimit) {
            if (!this.totalTimeLeftMS) {
                return;
            }
            if ((this.totalTimeLeftMS - this.msPassed) <= 0) {
                this.timedOut(true);
                return;
            }
        }
        this.lastTickAtMS = new Date().getTime();
        this.startAtMS = this.lastTickAtMS;
        this.started(true);
        this.iv = window.setInterval(
            () => {
                this.tick();
            },
            1000);
    }
    private tick() {
        const curTickAtMS = new Date().getTime();
        if (curTickAtMS < this.lastTickAtMS) {
            throw new Error('Someone changed the time!');
            //TODO: emit event instead
        }

        this.lastTickAtMS = curTickAtMS;
        this.updateTimePassed();
        if (this.hasTimeLimit) {

            let timeLeftMS = this.totalTimeLeftMS - this.msPassed - (this.lastTickAtMS - this.startAtMS);
            if (timeLeftMS <= 0) {
                timeLeftMS = 0;
                this.stop();
                this.timeLeftSeconds(0);
                this.timedOut(true);
            } else {
                //TODO: emit event
                this.timeLeftSeconds(Math.round(timeLeftMS / 1000));
            }
        }
    }

    public reset() {
        if (this.iv) {
            clearInterval(this.iv);
            this.iv = null;
        }
        this.hasTimeLimit = false;
        this.totalTimeLeftMS = 0;
        this.lastTickAtMS = null;
        this.startAtMS = null;
        this.msPassed = 0;

        this.timeLeftSeconds(null);
        this.timedOut(false);
        this.started(false);
        this.updateTimePassed();
    }

    private updateTimePassed() {
        this.timePassedSeconds(Math.round(this.getTimePassed() / 1000));
    }

    public stop() {
        if (this.iv) {
            clearInterval(this.iv);
            this.iv = null;
        }
        if (this.lastTickAtMS && this.startAtMS) {
            this.msPassed += (this.lastTickAtMS - this.startAtMS);
            this.lastTickAtMS = null;
            this.startAtMS = null;
        }
        this.started(false);
    }
}

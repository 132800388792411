export const id = 'kosovo-choicetext';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';

itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '7d5189f8-d3db-4385-8d3f-213e6242ae78',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});
import $ from 'jquery';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { error } from '../../debug';
import * as HELPER from '../../helper';
import { htmlString } from './audioplayer.html.g';

const WIDGET_NAME = 'widgets-audioplayer';
const WIDGET_PARENT_NAME = 'widgets';
const subscriptions = new Array<HELPER.IDisposeable>();

function addParam(url: string, paramName: string, paramValue: string) {
    if (url.indexOf('?') === -1) {
        url += '?';
    }
    url += '&';
    url += encodeURIComponent(paramName);
    url += '=';
    url += encodeURIComponent(paramValue);
    return url;
}

HELPER.registerBindingHandler('jPlayerAudio', {
    preprocess: (value, name, addBindingCallback) => {
        addBindingCallback('uniqueName', true);
        return value;
    },
    init: (element, valueAccessor) => {
        const setMedia = <IJPlayerSetMediaOptions>{};
        const $el = $('.jp-jplayer', element);
        element.id = element.name;
        const elementId = element.id;
        if (ko.isObservable(valueAccessor())) {
            subscriptions.push(valueAccessor().subscribe((newVal: string) => {
                let suppliedFormats = '';
                $el.jPlayer('destroy');
                const startStr = newVal.substr(0, 50);
                if (startStr.indexOf('data:') === 0) {
                    if (startStr.indexOf('audio/ogg') !== -1) {
                        setMedia.oga = newVal;
                        suppliedFormats = 'oga';
                    } else if (startStr.indexOf('audio/mp3') !== -1 || startStr.indexOf('audio/mpeg') !== -1) {
                        setMedia.mp3 = newVal;
                        suppliedFormats = 'mp3';
                    }
                } else if (newVal.indexOf('.mp3') !== -1) {
                    setMedia.mp3 = newVal;
                    setMedia.oga = addParam(newVal, 'convertTo', 'audio/ogg');
                    suppliedFormats = 'mp3,oga';
                } else if (newVal.indexOf('.oga') !== -1) {
                    setMedia.oga = newVal;
                    setMedia.mp3 = addParam(newVal, 'convertTo', 'audio/mpeg');
                    suppliedFormats = 'oga,mp3';
                }
                $el.jPlayer({
                    ready: () => {
                        if (suppliedFormats) {
                            $el.jPlayer('setMedia', setMedia);
                        }
                    },
                    cssSelectorAncestor: '#' + elementId + ' .jp-audio',
                    preload: 'auto',
                    solution: 'html,flash',
                    supplied: suppliedFormats,
                    useStateClassSkin: true,
                    autoBlur: false,
                    smoothPlayBar: true,
                    keyEnabled: true,
                    remainingDuration: true,
                    toggleDuration: true
                });
                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    // This will be called when the element is removed by Knockout or
                    // if some other part of your code calls ko.removeNode(element)
                    $el.jPlayer('destroy');
                });
            }));
        }
        const params = ko.unwrap(valueAccessor());

        let suppliedFormats = '';
        if (typeof params === 'string') {
            const startStr = params.substr(0, 50);
            if (startStr.indexOf('data:') === 0) {
                if (startStr.indexOf('audio/ogg') !== -1) {
                    setMedia.oga = params;
                    suppliedFormats = 'oga';
                } else if (startStr.indexOf('audio/mp3') !== -1 || startStr.indexOf('audio/mpeg') !== -1) {
                    setMedia.mp3 = params;
                    suppliedFormats = 'mp3';
                }
            } else if (params.indexOf('.mp3') !== -1) {
                setMedia.mp3 = params;
                setMedia.oga = addParam(params, 'convertTo', 'audio/ogg');
                suppliedFormats = 'mp3,oga';
            } else if (params.indexOf('.oga') !== -1) {
                setMedia.oga = params;
                setMedia.mp3 = addParam(params, 'convertTo', 'audio/mpeg');
                suppliedFormats = 'oga,mp3';
            }
            if (!suppliedFormats) {
                error('Audio player does not support audio-format \'' + params + '\'');
            }
            $el.jPlayer({
                ready: () => {
                    if (suppliedFormats) {
                        $el.jPlayer('setMedia', setMedia);
                    }
                },
                cssSelectorAncestor: '#' + element.id + ' .jp-audio',
                preload: 'auto',
                solution: 'html,flash',
                supplied: suppliedFormats,
                useStateClassSkin: true,
                autoBlur: false,
                smoothPlayBar: true,
                keyEnabled: true,
                remainingDuration: true,
                toggleDuration: true
            });
            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                // This will be called when the element is removed by Knockout or
                // if some other part of your code calls ko.removeNode(element)
                $el.jPlayer('destroy');
            });
        }
    }
});

export interface IParams {
    url: string;
}

export class MyModel extends AbstractWidget {
    public readonly url = ko.observable('');
    constructor(readonly params?: IParams) {
        super();
        this.url(params && params.url || '');
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

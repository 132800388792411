import * as i18next from './../../../../i18n/i18n';
﻿import * as ko from 'knockout';
import { AbstractWidget } from '../../../../AbstractWidget';
import * as $LANG from '../../../../i18n/data';
import * as INTERFACES from '../../../../model/interfaces';
import { htmlString } from './header.html.g';


const WIDGET_NAME = 'itemdefinition-kosovo-widgets-header';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo-widgets';

export interface IParams {
    mode: INTERFACES.ItemMode;
    headerText: string;
    questionHTML: string;
    additionalHeaderText?: string;
}

export class MyModel extends AbstractWidget {
    public readonly loaded = ko.observable(false);
    public readonly mode = ko.observable<INTERFACES.ItemMode>();
    public readonly headerText: string;
    public readonly questionHTML: string;
    public readonly additionalHeaderText: string;

    constructor(params: IParams) {
        super();
        this.mode(params.mode);
        this.additionalHeaderText = params.additionalHeaderText || '';
        this.headerText = params.headerText;
        this.questionHTML = params.questionHTML;
        this.loaded(true);
    }

    public readonly headerTextDisplay = ko.pureComputed(() => {
        let text = '';
        text += this.headerText + ' ';

        if (this.additionalHeaderText) {
            text += this.additionalHeaderText;
        }

        return text;
    });

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.widgets.header.ENTER_THE_INSTRUCTION_TEXT_HERE']);
    });

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.widgets.header.ENTER_THE_QUESTION_TEXT_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS, selectBoxDS } from '../../../dx_helper';
import { DONE, normalizeNumber } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { SurveySingleChoiceMatrixEdit_Option, SurveySingleChoiceMatrixEdit_ShuffleColumn, SurveySingleChoiceMatrixEdit_ShuffleRow } from '../../../its-itembank-api.g';
import * as Modal from '../../../modal';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { toastService } from '../../../toastService';
import { legacyPushPull, queueApiCall } from '../../../ui/docmanager';
import { ServerConnection } from '../../../ui/RestAPI';
import { UIAction } from '../../../ui/uiAction';
import * as BTN from '../../../widgets/button/widget';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveysinglechoicematrix-edit';
type Q = Awaited<ReturnType<API.Sdk['surveysinglechoicematrix_edit_data']>>;
type ROW = Q['SurveySingleChoiceMatrixEdit']['get']['rows'][0];
type COLUMN = Q['SurveySingleChoiceMatrixEdit']['get']['columns'][0];

export class VMRow {
    readonly index: string;
    constructor(readonly model: ViewModel, data: ROW) {
        this.index = data.index;
        this.isReadOnly = model.isReadOnly;
        this.canEdit = model.canEdit;
    }
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public update(data: ROW) {
        this._value(data.value.value);
    }
    private readonly _value = ko.observable('');

    public readonly value = ko.pureComputed({
        read: () => {
            return this._value();
        },
        write: newVal => {
            this._value(newVal);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertRowValue/${this.index}`, () => ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.model.itemId,
                    upsertRows: [{
                        index: this.index,
                        value: xnone(newVal)
                    }]
                }
            }), false);
        }
    });
    public readonly id = ko.pureComputed(() => this.index);
    public readonly rowId = ko.pureComputed(() => this.index);

    public readonly deleteButtonOptions = ko.pureComputed(() => {
        const retVal: BTN.IParams = {
            'click': this.rowDelete.click,
            'icon': 'remove',
            'css': 'actionGrayTiny',
            /*
            'text': i18next.t(['itemdefinition.surveysinglechoicematrix.edit.DELETE_ROW'])
            */
        };
        return retVal;
    });
    public readonly rowDelete = new UIAction(undefined, async () => {
        if (this.model.orderedRows().length <= 1) {
            toastService.error(i18n.t(['itemdefinition.surveysinglechoicematrix.edit.YOU_CANNOT_DELETE_THIS_ROW_THE_ITEM_MUST_HAVE_AT_LEAST_ONE_ROW']));
            return;
        }
        const message = i18next.t(['itemdefinition.surveysinglechoicematrix.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ROW']);

        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await legacyPushPull(async () => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.model.itemId,
                    removeRows: [this.index]
                }
            });
        });

    });
}

export class VMColumn {
    readonly index: string;
    constructor(readonly model: ViewModel, col: COLUMN) {
        this.index = col.index;
        this.isReadOnly = model.isReadOnly;
        this.canEdit = model.canEdit;
    }
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public update(col: COLUMN) {
        this._value(col.value.value);
        this._score(col.score);
    }
    private readonly _value = ko.observable('');
    private readonly _score = ko.observable(0);

    public readonly score = ko.pureComputed<string | number>({
        read: () => {
            return this._score();
        },
        write: newVal => {
            const score = normalizeNumber(newVal);
            this._score(score);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertColScore/${this.index}`, () =>
                ServerConnection.api.surveysinglecohicematrix_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        upsertColumns: [{
                            index: this.index,
                            score,
                        }]
                    }
                }), false);
        }
    });

    public readonly id = ko.pureComputed(() => {
        return this.index;
    });
    public readonly value = ko.pureComputed({
        read: () => {
            return this._value();
        },
        write: newVal => {
            this._value(newVal);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertColValue/${this.index}`, () =>
                ServerConnection.api.surveysinglecohicematrix_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        upsertColumns: [{
                            index: this.index,
                            value: xnone(newVal)
                        }]
                    }
                }), false);
        }
    });
    public readonly scorePlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveysinglechoicematrix.edit.POINTS']);
    });


    public readonly deleteColumnButtonOptions = ko.pureComputed(() => {
        const retVal: BTN.IParams = {
            'click': this.columnDelete.click,
            'icon': 'remove',
            'css': 'actionGrayTiny',
            /*
            'text': i18next.t(['itemdefinition.surveysinglechoicematrix.edit.DELETE_COLUMN'])
            */
        };
        return retVal;
    });

    public readonly columnDelete = new UIAction(undefined, async () => {
        if (this.model.orderedColumns().length <= 2) {
            toastService.error(i18next.t(['itemdefinition.surveysinglechoicematrix.edit.YOU_CANNOT_DELETE_THIS_COLUMN_THE_ITEM_MUST_HAVE_AT_LEAST_2_COLUMNS']));
            return;
        }
        const message = i18next.t(['itemdefinition.surveysinglechoicematrix.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_COLUMN']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await legacyPushPull(async () => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.model.itemId,
                    removeColumns: [this.index]
                }
            });
        });


    });
}

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);
    public readonly answers = ko.observableArray();
    public readonly header = ko.observable('');
    public readonly question = ko.observable('');

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly neutralScore = ko.observable(0);

    public readonly option = ko.observable<SurveySingleChoiceMatrixEdit_Option>();
    public readonly colMode = ko.observable<SurveySingleChoiceMatrixEdit_ShuffleColumn>();
    public readonly rowMode = ko.observable<SurveySingleChoiceMatrixEdit_ShuffleRow>();

    public readonly totalScore = ko.pureComputed(() => {
        const maxScore = this.orderedColumns().map(x => normalizeNumber(x.score())).reduce((prev, cur) => cur > prev ? cur : prev);
        return this.orderedRows().length * maxScore;
    });

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));

        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                rowMode: this.rowMode,
                colMode: this.colMode,
                totalScore: this.totalScore,
                neutralScore: this.neutralScore,
                option: this.option
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'rowMode',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.ROWS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: SurveySingleChoiceMatrixEdit_ShuffleRow.Shuffled,
                    value: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.SHUFFLED']),
                },
                {
                    key: SurveySingleChoiceMatrixEdit_ShuffleRow.Ordered,
                    value: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.FIXED']),
                }
            ]),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'colMode',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.COLUMNS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: SurveySingleChoiceMatrixEdit_ShuffleColumn.Shuffled,
                    value: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.SHUFFLED']),
                },
                {
                    key: SurveySingleChoiceMatrixEdit_ShuffleColumn.Ordered,
                    value: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.FIXED'])
                },
                {
                    key: SurveySingleChoiceMatrixEdit_ShuffleColumn.ShuffledExceptLast,
                    value: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.LAST_COLUMN_FIXED'])
                },
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'totalScore',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.POINTS'])
            },

            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                readOnly: true,
                min: 0,
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'neutralScore',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.NEUTRAL_SCORE'])
            },

            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'option',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.SURVEY_OPTION'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: SurveySingleChoiceMatrixEdit_Option.Required,
                    value: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.REQUIRED']),
                },
                {
                    key: SurveySingleChoiceMatrixEdit_Option.Optional,
                    value: i18next.t(['itemdefinition.surveysinglechoicematrix.edit.OPTIONAL']),
                },
            ]),
        }));

        return retVal;
    });


    public readonly addRowButtonOptions = ko.pureComputed(() => {
        const retVal: BTN.IParams = {
            'click': this.rowAdd.click,
            'icon': 'add',
            'css': 'actionGrayTiny',
            /*
            'text': i18next.t(['itemdefinition.surveysinglechoicematrix.edit.ADD_ROW'])
            */
        };
        return retVal;
    });
    public readonly rowAdd = new UIAction('rowAdd', async () => {
        const findUnusedID = () => {
            for (let id = 1; ; ++id) {
                const existEd = this.orderedRows().filter(el => el.id() === id.toString());
                if (existEd.length === 0) {
                    return id.toString();
                }
            }
        };
        await legacyPushPull(async () => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertRows: [{
                        index: findUnusedID(),
                    }]
                }
            });
        });

    });


    public readonly addColumnButtonOptions = ko.pureComputed(() => {
        const retVal: BTN.IParams = {
            'click': this.columnAdd.click,
            'icon': 'add',
            'css': 'actionGrayTiny',
            /*
            'text': i18next.t(['itemdefinition.surveysinglechoicematrix.edit.ADD_COLUMN'])
            */
        };
        return retVal;

    });
    public readonly columnAdd = new UIAction('columnAdd', async () => {

        const findUnusedID = () => {
            for (let id = 1; ; ++id) {
                const existEd = this.orderedColumns().filter(el => el.id() === String.fromCharCode(65 + id));
                if (existEd.length === 0) {
                    return String.fromCharCode(65 + id);
                }
            }
        };
        await legacyPushPull(async () => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertColumns: [{
                        index: findUnusedID(),
                    }]
                }
            });
        });
    });

    private readonly rows = new Map<string, VMRow>();
    private readonly cols = new Map<string, VMColumn>();

    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.surveysinglechoicematrix_edit_data({
            itemId: this.itemId
        });
        const d = q.SurveySingleChoiceMatrixEdit.get;
        this.header(d.header.value);
        this.question(d.question.value);
        for (const row of d.rows) {
            if (!this.rows.has(row.id)) {
                this.rows.set(row.id, new VMRow(this, row));
            }
            this.rows.get(row.id).update(row);
        }
        for (const col of d.columns) {
            if (!this.cols.has(col.id)) {
                this.cols.set(col.id, new VMColumn(this, col));
            }
            this.cols.get(col.id).update(col);
        }
        this.orderedRows(d.rows.map(x => this.rows.get(x.id)));
        this.orderedColumns(d.columns.map(x => this.cols.get(x.id)));
        this.option(d.option);
        this.neutralScore(d.neutralScore);
        this.rowMode(d.shuffleRow);
        this.colMode(d.shuffleColumn);
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async v => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.option, `${WIDGET_NAME}/${this.itemId}/option`, async (v) => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    option: v
                }
            });
            return DONE;
        });
        this.onChange(this.neutralScore, `${WIDGET_NAME}/${this.itemId}/neutralScore`, async (v) => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    neutralScore: v
                }
            });
            return DONE;
        });

        this.onChange(this.rowMode, `${WIDGET_NAME}/${this.itemId}/shuffleRow`, async (v) => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleRow: v
                }
            });
            return DONE;
        });
        this.onChange(this.colMode, `${WIDGET_NAME}/${this.itemId}/shuffleColumn`, async (v) => {
            await ServerConnection.api.surveysinglecohicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleColumn: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }



    public readonly pointsText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveysinglechoicematrix.edit.POINTS']);
    });

    public readonly orderedColumns = ko.observable<VMColumn[]>();
    public readonly orderedRows = ko.observable<VMRow[]>();

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveysinglechoicematrix.edit.ENTER_THE_TEXT_HERE']);
    });
    public readonly enterColTitlePlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveysinglechoicematrix.edit.COLUMN_TITLE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { log } from '../../../debug';
import { datagrid, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import { BRtoLF, joinStrings, LFtoBR } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_ITEMEDIT from '../itemedit/route';
import * as i18next from './../../../i18n/i18n';
import { IParams, IParamsInner, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_author_itemoverview_data']>>;
type ITEM_FOR_AUTHOR = Q['subjectById']['itemsForAuthor'][0];
type ITEM = ITEM_FOR_AUTHOR & {
    keywords_display: string
};

export class ViewModel extends DxWidget {


    public readonly loaded = ko.observable(false);

    readonly params: IParamsInner;

    constructor(params: IParams) {
        super();
        this.params = params.currentRoute.widgetParams;
    }


    private _keywords: Array<{ text: string, value: string }> = [];

    public async OnRefresh() {
        await super.OnRefresh();

        const x = await ServerConnection.api.ui_author_itemoverview_data({
            subjectId: this.params.subjectDocRefId,
        });


        this._descendentItems.splice(0, this._descendentItems.length, ...x.subjectById.itemsForAuthor.map(x => (Object.assign(x, {
            keywords_display: joinStrings(', ', ...Array.from(x.keywords || []).sort())
        }))));
        const keywords = new Set<string>();
        for (const item of this._descendentItems) {
            for (const kw of item.keywords) {
                keywords.add(kw);
            }
        }
        this._keywords.splice(0, this._keywords.length, ...Array.from(keywords).sort().map(x => ({ text: x, value: x })));

        await refreshDx(this);

    }
    private readonly _descendentItems: ITEM[] = [];


    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly gotoAction = new UIAction<string>(undefined, async (e, itemDocRefId) => {
        await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMEDIT.FACTORY.href({
            subjectDocRefId: this.params.subjectDocRefId,
            itemDocRefId
        }));
    });



    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            discriminator: 'main_v2',
            config: {
                allowColumnResizing: true,
                focusedRowEnabled: true,
                columnChooser: {
                    enabled: true,
                },
                paging: {
                    pageSize: 10
                },
                export: {
                    enabled: true,
                    customizeExcelCell: o => {
                        if (!o.gridCell) {
                            return;
                        }
                        if (o.gridCell.rowType !== 'data') {
                            return;
                        }
                        if (typeof o.value === 'string') {
                            o.gridCell.value = BRtoLF(o.value);
                        }
                        o.wrapTextEnabled = true;
                    }
                },
                wordWrapEnabled: true,
                pager: {
                    showPageSizeSelector: true,
                    allowedPageSizes: [10, 25, 50, 100]
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                headerFilter: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: true
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'itemId',
                        load: async () => {
                            return this._descendentItems;
                        },
                        byKey: async key => {
                            return this._descendentItems.find(x => x.itemId === key);
                        }
                    })
                }
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.ITEM_ID']),
            dataField: 'itemId',
            width: 100,
            cssClass: 'itsr3-uitest-hide',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.TITLE']),
            dataField: 'metaData.title'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.EDITED_BY']),
            dataType: 'string',
            dataField: 'workflow.currentAuthor.displayName',
            width: 100,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.CATEGORY']),
            allowGrouping: true,
            allowSorting: true,
            allowFiltering: true,
            width: 100,
            name: 'category',
            calculateCellValue: (row: ITEM_FOR_AUTHOR) => {
                return row.metaData.objectives.map(x => x.objectiveId);
            },
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.OBJECTIVE']),
            width: 100,
            dataField: 'metaData.category.taxon'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.SCORE']),
            dataType: 'number',
            dataField: 'accumulatedScoreMax',
            width: 100,
            calculateCellValue: (row: ITEM_FOR_AUTHOR) => {
                return row.metaData.objectives.map(x => x.scoreMax).reduce((a, b) => a + b, 0);
            },
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.ITEM_TYPE']),
            width: 100,
            dataField: 'itemType.title.value'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.AUDIENCE']),
            allowGrouping: true,
            allowSorting: true,
            allowFiltering: true,
            width: 100,
            name: 'audience',
            calculateCellValue: (row: ITEM_FOR_AUTHOR) => {
                return row.metaData.audience.map(x => x.audienceId).join(' ');
            },
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.DIFFICULTY']),
            dataField: 'metaData.itemDifficultyIndex'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.NOTE']),
            dataField: 'workflow.noteForAuthor'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.VISIBLE_TEXT']),
            dataField: 'visibleText',
            customizeText: (cellInfo) => {
                return LFtoBR(cellInfo.valueText);
            },
            encodeHtml: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.KEYWORDS']),
            dataField: 'keywords_display',
            allowSearch: false,
            allowFiltering: true,
            dataType: 'string',
            headerFilter: {
                dataSource: this._keywords,
                allowSearch: true,
                searchMode: 'contains'
            },
            calculateFilterExpression: (value, operation, target) => {
                log(value);
                if (value) {
                    const selector = (data: ITEM) => {
                        return data.keywords.indexOf(value) >= 0;
                    };
                    return [selector, operation || '=', true];
                } else {
                    return undefined;
                }
            },
        });

        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.STATUS']),
            allowGrouping: true,
            allowSorting: true,
            allowFiltering: true,
            dataType: 'string',
            dataField: 'workflow.authoringStatus',
            calculateCellValue: (row: ITEM_FOR_AUTHOR): string => {
                const x = i18next.t(TRANSLATIONS.AuthoringStatus[row.workflow.authoringStatus]);
                return x;
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.OBSOLETE']),
            dataField: 'workflow.obsolete',
            dataType: 'boolean',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemoverview.ACTIONS']),

            columns: [
                {
                    allowEditing: false,
                    allowExporting: false,
                    allowFiltering: false,
                    width: '40',
                    cellTemplate: (elem, info) => {
                        const retVal = $('<div />');
                        retVal.dxButton({
                            icon: 'far fa-folder-open',
                            onClick: async () => {
                                await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMEDIT.FACTORY.href({
                                    subjectDocRefId: this.params.subjectDocRefId,
                                    itemDocRefId: info.data.itemId,
                                }));
                            }
                        });
                        return retVal;
                    }
                },
            ],
        });
        return retVal;
    });

}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import $ from 'jquery';
import * as ko from 'knockout';
import Snap from 'snapsvg';
import { log } from '../../../debug';
import { golemMatch } from '../../../golem';
import { getGolemData } from '../../../Gremlin';
import { assertNever, DONE } from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import * as ARRAYHELPER from '../../../new_array';
import { getRandomEntry } from '../../../new_array';
import { multiline } from '../../../plugins/snapsvg/multiline';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { MatchingTextData } from '../../model/matchingtext/MatchingTextData';
import { GetSession } from '../../model/session';
import { htmlString } from './matchingtext.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-matchingtext';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

interface IPoint {
    x: number;
    y: number;
}
export type IParams = IItemDefinitionWidgetParams;

const START_RIGHT = 500;
const TEXT_WIDTH = 375;
const TOTAL_WIDTH = 890;
const TOTAL_HEIGHT = 300;

const LETTER_BOX_WIDTH = 20;
const LETTER_BOX_HEIGHT = 20;

const MARGIN_TOP = 20;
const MARGIN_BOTTOM = 15;
const BOX_GAP = 30;

class LeftTile {

    public updateConnector() {
        let end: IPoint = null;
        const connectedTo = this.connectedTo();
        const isAnswerCorrect = this.connectedTo() && this.correctConnectTo() === this.connectedTo();
        this.vm.right_tiles.forEach((text) => {
            if (text.id === connectedTo) {
                end = text.connectorPos;
                text.svgElem.toggleClass(this.answerClass, true);

                if (this.vm.mode() === 'RESULT' || this.vm.mode() === 'PRINT') {
                    text.svgElem.toggleClass('Correct', isAnswerCorrect);
                    this.svgElem.toggleClass('Correct', isAnswerCorrect);
                }
            } else {
                text.svgElem.toggleClass(this.answerClass, false);
            }
        });
        if (!end) {
            this.userConnector.svgPath.attr({ visibility: 'hidden' });
            this.userConnector.svgEnd.attr({ visibility: 'hidden' });
            this.svgElem.toggleClass(this.answerClass, false);
        } else {
            const path = this.getConnectorPath(this.connectorPos, end);
            this.svgElem.toggleClass(this.answerClass, true);
            this.userConnector.svgPath.attr({ d: path, visibility: 'visible' });
            this.userConnector.svgEnd.attr({
                cx: this.connectorPos.x,
                cy: this.connectorPos.y,
                visibility: 'visible'
            });
            if (this.vm.mode() === 'RESULT' || this.vm.mode() === 'PRINT') {
                this.svgElem.toggleClass('Correct', isAnswerCorrect);
                this.userConnector.svgPath.toggleClass('Correct', isAnswerCorrect);
                this.userConnector.svgPath.toggleClass('UnCorrect', !isAnswerCorrect);
            }
        }

    }

    getConnectorPath(start: IPoint, end: IPoint) {
        const r = Math.round;
        //draw only the stright line
        return ['M', r(start.x), ',', r(start.y), 'Q', start.x, ',', r(start.y), ' ',
            r(start.x), ',', r(start.y), 'T', r(end.x), ',', r(end.y)].join('');
    }

    private getData() {
        const itemAnswers = this.vm.data.texts;
        const answer = itemAnswers[this.nAnswer];
        return answer;
    }
    public readonly text: string;
    public readonly height: number;

    constructor(readonly vm: ViewModel, readonly nAnswer: number, readonly startY: number) {
        const answer = this.getData();
        this.text = answer.text;

        const itemAnswers = this.vm.data.texts;
        //const PER_ANSWER_HEIGHT = TOTAL_HEIGHT / (itemAnswers.length + 1);

        const rect = vm.snap.rect(0, startY, TEXT_WIDTH, 10, 5, 5);

        const y = startY;
        const text = multiline(vm.snap, TEXT_WIDTH / 2, y + MARGIN_TOP, this.text);

        text.attr({
            'text-anchor': 'middle',
            'alignment-baseline': 'hanging',
            'dominant-baseline': 'hanging',
        });
        const bbox = text.getBBox();
        rect.attr({
            height: bbox.height + MARGIN_TOP + MARGIN_BOTTOM
        });


        const rectBBox = rect.getBBox();
        this.connectorPos = {
            x: rectBBox.x2,
            y: rectBBox.y + rectBBox.height / 2
        };

        //const realY = startY + (PER_ANSWER_HEIGHT - bbox.height) / 2;
        //text.attr({ y: y - bbox.y + realY });

        this.height = rectBBox.height;

        this.id = ko.unwrap(answer.id);
        this.svgElem = rect;
        this.answerClass = 'Answer' + nAnswer;
        this.userConnector = {
            svgPath: null,
            svgEnd: null
        };
        this.userConnector.svgPath = this.vm.snap.path('').addClass('Connector').addClass('Answer' + this.nAnswer);
        this.userConnector.svgEnd = this.vm.snap.circle(0, 0, 3).addClass('ConnectorEnd').attr({ visibility: 'hidden' });

        this.rightConnector = {
            svgPath: vm.snap.path('').addClass('ConnectorResult').addClass('Result_Answer' + nAnswer),
            svgEnd: vm.snap.circle(0, 0, 3).addClass('ConnectorEndResult').attr({
                visibility: 'hidden'
            })
        };

        if (vm.mode() === 'RESULT' || vm.mode() === 'PRINT') {
            const letter = 'ABCDEFGH'[nAnswer];
            const innerrect = vm.snap.rect(this.connectorPos.x - LETTER_BOX_WIDTH / 2, this.connectorPos.y - LETTER_BOX_HEIGHT / 2, LETTER_BOX_WIDTH, LETTER_BOX_HEIGHT, 5, 5);
            const innertext = vm.snap.text(this.connectorPos.x - LETTER_BOX_WIDTH / 2 + 5, this.connectorPos.y + 5, letter);
            rect.addClass('CorrectAnswer_' + ARRAYHELPER.greekToLatin(answer.id.substr(-1)));
            innerrect.addClass('CorrectAnswer_' + ARRAYHELPER.greekToLatin(answer.id.substr(-1)));

        }

        vm.disposables.addDiposable(this.connectedTo.subscribe((imageId) => {
            this.updateConnector();
        }));

        this.svgElem.addClass('Selectable');
        this.svgElem.click(() => {
            if (this.vm.mode() === 'INTERACTIVE') {
                this.vm.selectAnswer.click(this);
            }
        });

        this.updateConnector();

    }
    public readonly connectedTo = ko.pureComputed(() => {

        const leftData = this.getData();
        const leftId = leftData.id;

        const rightData = this.vm.data.answers.find(x => x.connectedTo() === leftId);
        return rightData && rightData.id;
    });
    public readonly correctConnectTo = ko.pureComputed(() => {

        const leftData = this.getData();
        const leftId = leftData.id;

        const rightData = this.vm.data.answers.find(x => x.correctConnectTo === leftId);
        return rightData && rightData.id;
    });

    public readonly id: string;
    public readonly svgElem: Snap.Element;
    private readonly innersvgElem: Snap.Element;
    private readonly answerClass: string;
    private readonly connectorPos: IPoint;
    private readonly userConnector: {
        svgPath: Snap.Element,
        svgEnd: Snap.Element
    };
    private readonly rightConnector: {
        svgPath: Snap.Element,
        svgEnd: Snap.Element
    };

}


class RightTile {
    private getData() {
        const itemTexts = this.vm.data.answers;
        const text = itemTexts[this.nText];
        return text;
    }
    public readonly text: string;
    public readonly height: number;
    constructor(readonly vm: ViewModel, readonly nText: number, startY: number) {

        const itemTexts = this.vm.data.answers;
        const text = itemTexts[nText];
        this.text = text.text;

        //const PER_ANSWER_HEIGHT = TOTAL_HEIGHT / (itemTexts.length);
        //const PER_ANSWER_GAP = TOTAL_HEIGHT / (itemTexts.length + 1);


        const rect = vm.snap.rect(START_RIGHT, startY, TEXT_WIDTH, 10, 5, 5);
        const y = startY + MARGIN_TOP;
        const textElem = multiline(vm.snap, START_RIGHT + TEXT_WIDTH / 2, y, this.text);


        textElem.attr({
            'text-anchor': 'middle',
            'alignment-baseline': 'hanging',
            'dominant-baseline': 'hanging',
        });
        const bbox = textElem.getBBox();
        rect.attr({
            height: bbox.height + MARGIN_TOP + MARGIN_BOTTOM,
        });

        const rectBBox = rect.getBBox();
        this.connectorPos = {
            x: rectBBox.x,
            y: rectBBox.y + rectBBox.height / 2
        };

        if (vm.mode() === 'RESULT' || vm.mode() === 'PRINT') {
            const innerrect = vm.snap.rect(this.connectorPos.x - LETTER_BOX_WIDTH / 2, this.connectorPos.y - LETTER_BOX_HEIGHT / 2, LETTER_BOX_WIDTH, LETTER_BOX_HEIGHT, 5, 5);
            const correctTextBox = vm.data.texts.find(x => x.id === text.correctConnectTo);
            const correctIndex = vm.data.texts.indexOf(correctTextBox);
            const letter = 'ABCDEFGH'[correctIndex];
            const innertext = vm.snap.text(this.connectorPos.x - LETTER_BOX_WIDTH / 2 + 5, this.connectorPos.y + 5, letter);
            /*innertext.attr({
                y: y - bbox.y + realY
            });
            */
            rect.addClass('CorrectAnswer_' + ARRAYHELPER.greekToLatin(text.correctConnectTo.substr(-1)));
            innerrect.addClass('CorrectAnswer_' + ARRAYHELPER.greekToLatin(text.correctConnectTo.substr(-1)));
        }

        this.height = rectBBox.height;
        this.id = ko.unwrap(text.id);
        this.svgElem = rect;
        //this.innersvgElem = innerrect;
        this.svgElem.addClass('Selectable');
        this.svgElem.click(() => {
            if (vm.mode() === 'INTERACTIVE') {
                vm.selectText.click(this);
            }
        });

    }

    public readonly connectedTo = ko.pureComputed(() => {
        const data = this.getData();
        return data && data.connectedTo();
    });

    public readonly id: string;
    public readonly svgElem: Snap.Element;
    private readonly innersvgElem: Snap.Element;
    public readonly connectorPos: IPoint;

}

export class ViewModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public mode = ko.observable<ItemMode>();
    public loaded = ko.observable(false);

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.mode(params.mode || 'INTERACTIVE');

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof MatchingTextData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: MatchingTextData;

    public svgElement: SVGSVGElement;
    public snap: Snap.Paper;
    readonly right_tiles: Array<RightTile> = [];
    readonly left_tiles: Array<LeftTile> = [];

    private initSVG() {
        const element = $(this.componentInfo.element).find('.question-content');
        const htmlElement = element.get(0);


        //const subscriptions: Array<HELPER.IDisposeable> = [];
        for (let i = htmlElement.childNodes.length - 1; i >= 0; i--) {
            ko.removeNode(htmlElement.childNodes[i]);
        }
        //tslint:disable-next-line:no-http-string
        this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        $(this.svgElement).attr({
            width: TOTAL_WIDTH,
            height: TOTAL_HEIGHT,
        });
        htmlElement.appendChild(this.svgElement);
        this.snap = <Snap.Paper>Snap(this.svgElement);

        const itemAnswers = this.data.texts;
        const PER_ANSWER_GAP = TOTAL_HEIGHT / (itemAnswers.length);

        let startLeftY = 0;
        for (let nAnswer = 0; nAnswer < this.data.answers.length; ++nAnswer) {
            const cur = new LeftTile(this, nAnswer, startLeftY);
            this.left_tiles.push(cur);
            log(`Matching Text: ${nAnswer}: height ${cur.height} (startY: ${startLeftY}, PER_ANSWER_GAP: ${PER_ANSWER_GAP})`);
            startLeftY += cur.height + BOX_GAP;
        }
        let startRightY = 0;
        for (let nImage = 0; nImage < this.data.texts.length; ++nImage) {
            const cur = new RightTile(this, nImage, startRightY);
            this.right_tiles.push(cur);
            log(`Matching Text: ${nImage}: height ${cur.height} (startY: ${startRightY}, PER_ANSWER_GAP: ${PER_ANSWER_GAP})`);
            startRightY += cur.height + BOX_GAP;
        }
        $(this.svgElement).attr({
            width: TOTAL_WIDTH,
            height: Math.max(startLeftY, startRightY),
        });

        this.updateConnectors();
    }

    private updateConnectors() {
        this.left_tiles.forEach((answer: LeftTile, nAnswer: number) => {
            answer.updateConnector();
        });
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                const data = {
                    left: this.left_tiles.map(x => ({
                        id: x.id,
                        connectedTo: x.connectedTo()
                    })),
                    right: this.right_tiles.map(x => ({
                        id: x.id,
                        connectedTo: x.connectedTo()
                    }))
                };

                const availableLeft = data.left.filter(x => !x.connectedTo);
                const availableRight = data.right.filter(x => !x.connectedTo);
                if (!availableLeft.length) {
                    return false;
                }
                if (!availableRight.length) {
                    return false;
                }

                const golemMapping = new Map<string, string>();
                const golemData = getGolemData(this.itemId);
                log(`golemData=${JSON.stringify(golemData)}`);
                if (typeof golemData === 'object' && !Array.isArray(golemData)) {
                    const left = this.left_tiles.map(x => ({ key: x.id, value: x.text }));
                    const right = this.right_tiles.map(x => ({ key: x.id, value: x.text }));
                    for (const leftKey of Object.keys(golemData)) {
                        log(`leftKey: ${leftKey}`);
                        const leftId = golemMatch(left, leftKey);
                        if (leftId) {
                            const rightKey = golemData[leftKey];
                            log(`rightKey: ${rightKey}`);
                            if (typeof rightKey === 'string') {
                                const rightId = golemMatch(right, rightKey);
                                if (rightId) {
                                    log(`golem found match: ${leftId}=>${rightId}`);
                                    golemMapping.set(leftId, rightId);
                                }
                            }
                        }
                    }
                }
                const left = getRandomEntry(availableLeft);
                const right = availableRight.find(x => x.id === golemMapping.get(left.id)) || getRandomEntry(availableRight);
                await this.connect.invoke({
                    leftId: left.id,
                    rightId: right.id
                });
                return true;
            }
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.initSVG();
        this.updateConnectors();
        this.loaded(true);
    }


    public readonly answerTitle = ko.pureComputed(() => this.data.answerTitle);
    public readonly textTitle = ko.pureComputed(() => this.data.textTitle);

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public async OnReset() {
        this.selectedLeftId(undefined);
        this.selectedRightId(undefined);
        this.updateSelectionStatus();
        return DONE;
    }
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());
    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });
    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                assertNever(mode);
                throw new Error();

        }
    });


    public readonly connect = new UIAction<{ rightId: string, leftId: string }>(undefined, async (e, args) => {
        const answerId = args.rightId;
        const textId = args.leftId;
        if (!answerId || !textId) {
            return;
        }
        await this.data.connect({ answerId, textId });
    });

    public readonly selectedLeftId = ko.observable('');
    public readonly selectedRightId = ko.observable('');
    public readonly selectAnswer = new UIAction<LeftTile>(undefined, async (e, answer) => {
        this.selectedLeftId(answer.id);
        await this.tryConnect();
    });
    public readonly selectText = new UIAction<RightTile>(undefined, async (e, text) => {
        this.selectedRightId(text.id);
        await this.tryConnect();
    });

    updateSelectionStatus() {
        const selectedLeftId = this.selectedLeftId();
        const selectedRightId = this.selectedRightId();
        this.left_tiles.forEach((leftTile) => {
            leftTile.svgElem.toggleClass('Selected', selectedLeftId === leftTile.id);
        });
        this.right_tiles.forEach((rightTile) => {
            rightTile.svgElem.toggleClass('Selected', selectedRightId === rightTile.id);
        });
    }

    async tryConnect() {
        this.updateSelectionStatus();
        if (!this.selectedLeftId() || !this.selectedRightId()) {
            return;
        }
        await this.connect.intent({
            leftId: this.selectedLeftId(),
            rightId: this.selectedRightId(),
        });
        this.selectedRightId(undefined);
        this.selectedLeftId(undefined);
        this.updateSelectionStatus();
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

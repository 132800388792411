export const id = 'kosovo-container';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '4923baa0-a5d6-4f54-9359-46bc8b04cc34',
    widgetNames: {
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ]),
        fallback: 'itemdefinition-' + id
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, datagrid, refreshDx, selectBoxDS } from '../../../dx_helper';
import { USER_TEXT_ENTRY } from '../../../enums/datasource';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { SurveySingleChoiceTextEdit_Option, SurveySingleChoiceTextEdit_Shuffle, SurveySingleChoiceTextEdit_UserTextEntry } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { legacyPushPull } from '../../../ui/docmanager';
import { ServerConnection } from '../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveysinglechoicetext-edit';

export type IParams = IItemDefinitionWidgetParams;
type Q = Awaited<ReturnType<API.Sdk['surveysinglechoicetext_edit_data']>>;
type ANSWER = Q['SurveySingleChoiceTextEdit']['get']['answers'][0];
type ATTACHMENT = Q['documents']['get']['attachments'][0];

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    private readonly _answers: ANSWER[] = [];
    public readonly loaded = ko.observable(false);
    public readonly shuffleMode = ko.observable<SurveySingleChoiceTextEdit_Shuffle>();
    public readonly userTextEntry = ko.observable<SurveySingleChoiceTextEdit_UserTextEntry>();
    public readonly option = ko.observable<SurveySingleChoiceTextEdit_Option>();
    public readonly image = ko.observable('');
    private readonly _attachments: ATTACHMENT[] = [];


    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                image: this.image,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicetext.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.surveysinglechoicetext.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.surveysinglechoicetext.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.surveysinglechoicetext.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'image',
            editorType: 'dxSelectBox',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicetext.edit.IMAGE']),
            },
            editorOptions: this.imageOptions(),
        }));

        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                neutralScore: this.neutralScore,
                option: this.option,
                shuffleMode: this.shuffleMode,
                userTextEntry: this.userTextEntry,
            },
            items: [],
        };


        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'shuffleMode',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicetext.edit.MODE_FOR_ANSWER_OPTIONS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: SurveySingleChoiceTextEdit_Shuffle.Shuffled,
                    value: i18next.t(['itemdefinition.surveysinglechoicetext.edit.SHUFFLED']),
                },
                {
                    key: SurveySingleChoiceTextEdit_Shuffle.Ordered,
                    value: i18next.t(['itemdefinition.surveysinglechoicetext.edit.FIXED']),
                },
                {
                    key: SurveySingleChoiceTextEdit_Shuffle.ShuffledExceptLast,
                    value: i18next.t(['itemdefinition.surveysinglechoicetext.edit.LAST_LINE_FIXED']),
                },
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'userTextEntry',
            editorType: 'dxSelectBox',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicetext.edit.USER_TEXT_ENTRY']),
            },
            editorOptions: selectBoxDS(USER_TEXT_ENTRY()),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'neutralScore',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicetext.edit.NEUTRAL_SCORE'])
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'option',
            label: {
                text: i18next.t(['itemdefinition.surveysinglechoicetext.edit.SURVEY_OPTION'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: SurveySingleChoiceTextEdit_Option.Required,
                    value: i18next.t(['itemdefinition.surveysinglechoicetext.edit.REQUIRED']),
                },
                {
                    key: SurveySingleChoiceTextEdit_Option.Optional,
                    value: i18next.t(['itemdefinition.surveysinglechoicetext.edit.OPTIONAL']),
                },
            ]),
        }));

        return retVal;
    });

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveysinglechoicetext.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });


    public readonly imageOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxSelectBox.Properties = {
            value: this.image,
            dataSource: this._attachments,
            valueExpr: 'name',
            displayExpr: 'name',
            showClearButton: true,
        };
        return retVal;
    });


    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'index',
                        data: this._answers
                    }
                },
                editing: {
                    allowAdding: this.canEdit,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
            }
        });
        retVal.columns.push(
            {
                dataField: 'index',
                caption: i18next.t(['itemdefinition.surveysinglechoicetext.edit.ID']),
                width: 40,
                allowEditing: false
            });
        retVal.columns.push(
            {
                dataField: 'score',
                caption: i18next.t(['itemdefinition.surveysinglechoicetext.edit.SCORE']),
                dataType: 'number',
                width: 80,
            });
        retVal.columns.push(
            {
                dataField: 'text.value',
                caption: i18next.t(['itemdefinition.surveysinglechoicetext.edit.TEXT']),
            });

        retVal.onInitNewRow = e => {
            let index = '';
            for (let i = 0; i < 26; ++i) {
                index = String.fromCharCode(65 + i);
                if (!this._answers.some(x => x.index === index)) {
                    break;
                }
            }
            if (!index) {
                index = 'A';
            }
            e.data = {
                index,
                text: {
                    value: i18next.t(['itemdefinition.surveysinglechoicetext.edit.NEW'])
                },
                correct: true,
                score: 0
            };
        };
        retVal.onRowInserting = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.surveysinglechoicetext_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.data.index,
                            text: xnone(e.data.text && e.data.text.value),
                            score: e.data.score,
                        }]
                    }
                }));
            })();
        };
        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.surveysinglechoicetext_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeAnswers: [e.key]
                    }
                }));
            })();
        };

        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.surveysinglechoicetext_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.key,
                            score: e.newData.score,
                            text: xnone(e.newData.text && e.newData.text.value),
                        }]
                    }
                }));
            })();
        };
        return retVal;
    });

    public readonly neutralScore = ko.observable(0);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.surveysinglechoicetext_edit_data({
            itemId: this.itemId
        });
        const d = q.SurveySingleChoiceTextEdit.get;
        this._answers.splice(0, this._answers.length, ...d.answers);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        this.question(d.question.value);
        this.header(d.header.value);
        this.image(d.image && d.image.name);
        this.neutralScore(d.neutralScore || 0);
        this.shuffleMode(d.shuffleMode);
        this.userTextEntry(d.userTextEntry);
        this.option(d.option);
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();

        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.surveysinglechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.userTextEntry, `${WIDGET_NAME}/${this.itemId}/userTextEntry`, async val => {
            await ServerConnection.api.surveysinglechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    userTextEntry: val,
                }
            });
            return DONE;
        });
        this.onChange(this.image, `${WIDGET_NAME}/${this.itemId}/image`, async val => {
            await ServerConnection.api.surveysinglechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    image: val || '',
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.surveysinglechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val),
                }
            });
            return DONE;
        });
        this.onChange(this.option, `${WIDGET_NAME}/${this.itemId}/option`, async v => {
            await ServerConnection.api.surveysinglechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    option: v,
                }
            });
            return DONE;
        });
        this.onChange(this.shuffleMode, `${WIDGET_NAME}/${this.itemId}/shuffleMode`, async v => {
            await ServerConnection.api.surveysinglechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleMode: v,
                }
            });
            return DONE;
        });
        this.onChange(this.neutralScore, `${WIDGET_NAME}/${this.itemId}/neutralScore`, async v => {
            await ServerConnection.api.surveysinglechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    neutralScore: v,
                }
            });
            return DONE;
        });

        this.loaded(true);
    }

    public readonly imgUrl = ko.observable('');

    public readonly question = ko.observable('');
    public readonly header = ko.observable('');
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

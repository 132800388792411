import { GqlDS, OnePlus } from '../../../dxHelper/graphQLDataSource';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../../ui/RestAPI';
type Q = Awaited<ReturnType<API.Sdk['surveycomparision_edit_data']>>;

export type ROW = Q['SurveyComparisionEdit']['get']['rows'][0];


export class RowDS extends GqlDS<ROW, 'index'> {
    constructor({ itemId, fetch }: {
        itemId: string;
        fetch: () => ROW[];
    }) {
        super({
            fetch,
            key: 'index',
            indexGenerator: OnePlus,
            onNewRow: row => {
                row.caption = { value: `Row ${row.index}` };
            },
            remove: async (index) => {
                await ServerConnection.api.surveycomparision_edit_update({
                    params: {
                        itemId,
                        removeRows: [index],
                    }
                });
                return DONE;
            },
            upsert: async (index, data) => {
                await ServerConnection.api.surveycomparision_edit_update({
                    params: {
                        itemId,
                        upsertRows: [{
                            index,
                            caption: data.caption && { xnone: data.caption.value },
                        }]
                    }
                });
                return DONE;
            }
        });
    }
}

import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { parseTable } from '../../resultscreens/default/tables';
import { Objective } from './Objective';
import { ObjectiveOverviewBodyFieldTemplate } from './ObjectiveOverviewBodyFieldTemplate';
import { ObjectiveOverviewHeaderField } from './ObjectiveOverviewHeaderField';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'ui-widgets-objectiveoverviewtable';

export interface IParams {
    objectives: ko.MaybeSubscribable<Objective[]>;
    objectiveOverviewHeader: ko.MaybeSubscribable<ObjectiveOverviewHeaderField[]>

}
export class ObjectiveRow {
    constructor(readonly vm: ViewModel, readonly objective: Objective) {

    }
    public readonly objectiveClass = ko.pureComputed(() => {
        const depth = this.objective.depth;
        const maxDepth = this.vm.maxDepth();
        return `objective-${depth}of${maxDepth}`;
    });
    public readonly fields = ko.pureComputed(() => {
        return this.objective.fields();
    });
}

export class ViewModel extends AbstractWidget {

    constructor(readonly params: IParams) {
        super();
    }
    public readonly maxDepth = ko.pureComputed(() => {
        return Math.max(...this.objectives().map(x => x.depth));
    });
    public readonly objectiveOverviewHeader = ko.pureComputed(() => {
        return ko.unwrap(this.params.objectiveOverviewHeader);
    });
    public readonly objectives = ko.pureComputed(() => {
        return ko.unwrap(this.params.objectives);
    });
    public readonly objectiveRows = ko.pureComputed(() => {
        return this.objectives().map(x => new ObjectiveRow(this, x));
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

export function parseObjectiveTable(template: string, replace: (field: string) => string) {
    return parseTable(template, replace, field => new ObjectiveOverviewHeaderField(field), field => new ObjectiveOverviewBodyFieldTemplate(field, ''));
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});


import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { log } from '../../debug';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { GetSession } from '../model/session';
import { Column, Row, SurveyComparisionData } from '../model/surveycomparision/SurveyComparisionData';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveycomparision';

export type IParams = IItemDefinitionWidgetParams;

class VMCell {
    constructor(readonly vm: MyModel, readonly row: VMRow, readonly col: Column) {
    }
    public readonly sliderOptions = ko.pureComputed(() => {
        const cellId = this.vm.data.getCellId(this.col, this.row.row);
        const cell = this.vm.data.cells.get(cellId);
        const scale = this.vm.data.scale;
        const hints = scale.map(x => x.hint);
        const ids = scale.map(x => x.data.id);
        let value = ids.indexOf(cell.selected());
        if (value < 0) {
            value = 0;
        }
        const sliderOptions: DevExpress.ui.dxSlider.Properties = {

        };
        sliderOptions.label = {
            position: 'bottom',
            visible: true,
            format: (val: unknown) => typeof val === 'number' && hints[val] || '',
        };
        sliderOptions.readOnly = this.vm.mode() !== 'INTERACTIVE';
        sliderOptions.min = 0;
        sliderOptions.max = this.vm.data.scale.length - 1;
        sliderOptions.step = 1;
        sliderOptions.keyStep = 1;
        sliderOptions.tooltip = {
            enabled: true,
            position: 'top',
            format: (val: unknown) => typeof val === 'number' && hints[val] || '',
            showMode: 'always',
        };
        sliderOptions.value = value;
        sliderOptions.onValueChanged = o => {
            const scaleId = ids[o.value || 0];
            log(`Update to ${scaleId}`);
            cell.selected(scaleId);
        };
        return sliderOptions;
    });
}

class VMRow {
    constructor(readonly vm: MyModel, readonly row: Row) {
        this.caption = row.caption;
        this.cells = vm.data.columns.map(x => new VMCell(vm, this, x));
    }
    readonly cells: VMCell[];
    readonly caption: string;
}
export class MyModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly mode = ko.observable<ItemMode>();

    public readonly loaded = ko.observable(false);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());
    public readonly displayScore = ko.pureComputed(() => {
        return this.mode() === 'RESULT' || this.mode() === 'PRINT';
    });

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SurveyComparisionData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly rows = ko.pureComputed(() => {
        return this.data.rows.map(x => new VMRow(this, x));
    });


    public readonly columns = ko.pureComputed(() => {
        return this.data.columns;
    });
    public readonly data: SurveyComparisionData;

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                /*
                const answers = this.shuffledAnswers();
                const golemData = getGolemData(this.itemId);
                if (!answers.some(x => x.isSelected())) {
                    if (typeof golemData === 'string') {
                        const answerId = golemMatch(answers.map(x => ({
                            key: x.id(),
                            value: x.data.rawData.text.value
                        })), golemData);
                        if (answerId) {
                            log(`Golem says we should use answer ${answerId}`);
                            const a = answers.find(x => x.id() === answerId);
                            await a.select.invoke();
                            return true;
                        }
                    }
                    await getRandomEntry(answers).select.invoke();
                    return true;
                }
                */
                return false;
            }
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.loaded(true);
    }


    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);


}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

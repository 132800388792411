import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-author-newitem';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
    parentItemDocRefId: string;
}

export class NewItemRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.author.newitem.NEW_ITEM']);
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/author/subject/:subjectId/newitem/:parentItemId',
    toParams: map => ({
        parentItemDocRefId: map.get('parentItemId'),
        subjectDocRefId: map.get('subjectId'),
    }),
    fromParams: (p, map) => {
        map.set('subjectId', p.subjectDocRefId);
        map.set('parentItemId', p.parentItemDocRefId);
    },
    create: async p => {
        const route = new NewItemRoute(p);
        return route;
    },
});




import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { windowToggle } from 'rxjs';
import { AbstractWidget } from '../../../AbstractWidget';
import { AS } from '../../../dx_helper';
import * as $LANG from '../../../i18n/data';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import { routeManager } from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
//import * as M from './login.mutation.graphql.g';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

export class ViewModel extends AbstractWidget {
    constructor(readonly params: IParams) {
        super();
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly getRolesAction = new UIAction(undefined, async () => {
        const lang = $LANG.currentLanguageTag();
        const response = await legacyPushPull(() => ServerConnection.api.ui_login({
            username: this.userId(),
            password: this.userPassword(),
            lang,
        }));
        const result = response.login;
        if (!result) {
            await UIAction.showDetailed({
                ok: false,
                messages: [{
                    messageType: 'error',
                    message: i18next.t(['ui.login.login.THERE_ARE_NO_ROLES_AVAILABLE_FOR_THIS_USER'])
                }]
            });
            return;
        }
        sessionStorage.setItem('browserSessionId', result.browserSessionId);
        //window.location.reload();
        window.location.hash = '';
        window.location.reload();
        //await routeManager.navigateToHREF('');
        //window.location.href = ServerConnection.getDataUrl(result.href);
    });

    public readonly dxBoxItems = ko.pureComputed(() => {
        return [
            {
                ratio: 1,
                baseSize: 10,
                template: 'login'
            },
        ];
    });

    public readonly userId = ko.observable('');
    public readonly userPassword = ko.observable('');
    public useridTest: string = '';
    public userpassTest: string = '';
    public readonly data = ko.pureComputed(() => {
        return {
            username: this.userId,
            password: this.userPassword
        };
    });

    private focusSet = false;
    private tbUsername: DevExpress.ui.dxTextBox;
    private tbPassword: DevExpress.ui.dxTextBox;

    public readonly dxFormOptions = ko.pureComputed(() => {
        const s1: DevExpress.ui.dxForm.SimpleItem = {
            dataField: 'data.username',
            cssClass: 'light',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['ui.login.login.USERNAME']),
                location: 'top'
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                onInitialized: e => this.tbUsername = e.component
            }),
        };
        const s2: DevExpress.ui.dxForm.SimpleItem = {
            dataField: 'data.password',
            cssClass: 'light',
            editorType: 'dxTextBox',
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                mode: 'password',
                onInitialized: e => this.tbPassword = e.component

                /*
                onEnterKey: e => {
                    e.component.blur();
                    this.getRolesAction.click();
                }
                */
            }),
            label: {
                text: i18next.t(['ui.login.login.PASSWORD']),
                location: 'top'
            }

        };
        const s3: DevExpress.ui.dxForm.ButtonItem = {
            itemType: 'button',
            cssClass: 'buttonAlign',
            buttonOptions: {
                text: i18next.t(['ui.login.login.LOGIN']),
                onClick: () => {
                    this.getRolesAction.click();
                }
            }

        };
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: {
                data: this.data()
            },
            colCount: 1,
            width: 300,
            items: [s1, s2, s3],
            onEditorEnterKey: () => this.getRolesAction.click(),
            onInitialized: x => {
                setTimeout(() => this.setInitialFocus(), 100);
            },
            //onFieldDataChanged: () => this.test
        };
        return retVal;
    });

    private setInitialFocus() {
        if (this.focusSet) {
            return;
        }
        if (!this.tbUsername) {
            return;
        }
        this.tbUsername.focus();
        this.focusSet = true;
    }
    public readonly loaded = ko.observable(false);
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

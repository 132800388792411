﻿import * as ko from 'knockout';
import { dir, log } from '../../../debug';
import { golemMatch } from '../../../golem';
import { getGolemData, isStringMap } from '../../../Gremlin';
import * as HELPER from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import * as ARRAYHELPER from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { ChoiceTextData, ChoiceTextData_Gap, ChoiceTextData_PoolValue, ChoiceTextData_Text } from '../../model/choicetext/ChoiceTextData';
import { translate } from '../../model/ItemDataModel';
import { GetSession } from '../../model/session';
import { htmlString } from './choicetext.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-choicetext';

class PoolValue {
    public readonly latinId: string;
    constructor(readonly model: MyModel, readonly _model: ChoiceTextData_PoolValue) {
        this.latinId = _model.latinId;
    }

    public readonly pressValue = new UIAction(undefined, async () => {
        await this.model.assign.intent({
            gapId: this._model.gap.gap.id,
            valueId: this._model.value.id
        });
    });

    public readonly letter = ko.pureComputed(() => {
        return this.latinId;
    });


    public text = ko.pureComputed(() => {
        return this._model.text();
    });

    public readonly isAvailable = ko.pureComputed(() => {
        return this._model.isAvailable();
    });
    public readonly isCorrect = ko.pureComputed(() => {
        return this._model.isCorrect();
    });
}

export type IParams = IItemDefinitionWidgetParams;

class GapOrText {
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }
}

class Gap extends GapOrText {
    constructor(readonly model: MyModel, readonly _model: ChoiceTextData_Gap) {
        super();
        this.selectedValue = _model.selectedValue;
    }
    public readonly selectedValue: ko.Observable<string>;

    public readonly possibilities = ko.pureComputed(() => {
        return this._model.possibilities().map((value, index) => {
            return new PoolValue(this.model, value);
        });
    });
}

class Text extends GapOrText {
    constructor(readonly model: MyModel, readonly _model: ChoiceTextData_Text) {
        super();
        this.text = _model.text;
    }
    public readonly text: string;
}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly data: ChoiceTextData;

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof ChoiceTextData)) {
            throw new Error();
        }
        this.data = data;

    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                const fragments = this.fragments();
                const golemData = getGolemData(this.itemId);

                for (const f of fragments) {
                    if (f.isGap()) {

                        if (!f.possibilities().every(x => x.isAvailable())) {
                            continue;
                        }
                        const selectable = f.possibilities().map(x => ({
                            key: x._model.value.id,
                            value: translate(x._model.value.value, this.params),
                        }));
                        let id: string;
                        dir({
                            selectable,
                            golemData,
                            isStringMap: isStringMap(golemData),
                            gapId: f._model.gap.gapId
                        });
                        if (isStringMap(golemData)) {
                            id = golemMatch(selectable, golemData[f._model.gap.gapId]);
                        }
                        if (!id) {
                            id = ARRAYHELPER.getRandomEntry(selectable).key;
                        }
                        const p = f.possibilities().find(x => x._model.value.id === id);
                        await p.pressValue.invoke();
                        return true;

                    }
                }
                return false;
            }
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly assign = new UIAction<{ gapId: string, valueId: string }>(undefined, async (e, args) => {
        await this.data.assign({
            gapId: args.gapId,
            valueId: args.valueId
        });
    });

    public readonly fragments = ko.pureComputed(() => {
        const retVal: Array<Text | Gap> = this.data.fragments.map(x => {
            if (x instanceof ChoiceTextData_Text) {
                return new Text(this, x);
            } else if (x instanceof ChoiceTextData_Gap) {
                return new Gap(this, x);
            } else {
                throw new Error();
            }
        });

        return retVal;
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => {
        return this.data.meta.accumulatedScore();
    });

    public handlePaste(data: {}, event: ClipboardEvent) {
        HELPER.handlePasteEvent(event);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { log } from '../../debug';
import * as HELPER from '../../helper';
import { bounds } from '../../helper';
import { InvisibleHotSpotEdit_Scoring } from '../../its-itembank-api.g';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { UIAction } from '../../ui/uiAction';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { InvisibleHotspotData, InvisibleHotspotData_Answer } from '../model/invisiblehotspot/InvisibleHotspotData';
import { GetSession } from '../model/session';
import * as i18n from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-invisiblehotspot';

export type IParams = IItemDefinitionWidgetParams;

export class Marker {
    constructor(readonly model: MyModel, readonly answer: InvisibleHotspotData_Answer) {

    }
    public readonly cssClass = ko.pureComputed(() => {
        if (this.model.mode() === 'INTERACTIVE') {
            return '';
        }
        if (!this.answer.correct) {
            return '';
        }
        switch (this.answer.correct) {
            case 'correct':
            case 'incorrect':
            case 'duplicate':
                return this.answer.correct;
        }
        return '';
    });

    public readonly cx = ko.pureComputed(() => {
        return this.answer.x + '%';

    });
    public readonly cy = ko.pureComputed(() => {
        return this.answer.y + '%';

    });
    public readonly r = ko.pureComputed(() => {
        return this.model.data.markerRadius + '%';
    });
    public actionRemove(data: unknown, ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this._actionRemove.click();
    }
    private readonly _actionRemove = new UIAction(undefined, async () => {
        if (this.model.mode() !== 'INTERACTIVE') {
            return;
        }
        this.model.data.answers.remove(this.answer);
    });
}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    public rects = ko.pureComputed(() => {
        if (this.mode() === 'INTERACTIVE') {
            return [];
        }
        return this.data.zoneRects.map(x => ({
            x: `${x.left}%`,
            y: `${x.top}%`,
            width: `${x.width}%`,
            height: `${x.height}%`,
            cssClass: `area area-${x.group}`
        }));
    });

    public readonly marker = ko.pureComputed(() => {
        return this.data.answers().map(x => new Marker(this, x));
    });
    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof InvisibleHotspotData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly hint = ko.pureComputed(() => {
        const min = this.data.minAnswers;
        const max = this.data.maxAnswers;
        const hintText = [];
        if (max < 1000) {
            if (min === max) {
                if (max === 1) {
                    hintText.push(i18n.t(['itemdefinition.invisiblehotspot.YOU_HAVE_TO_MARK_ONE_POSITION']));
                } else {
                    hintText.push(i18n.t(['itemdefinition.invisiblehotspot.YOU_HAVE_TO_MARK_MAX_POSITIONS'], { min, max }));
                }
            } else {
                hintText.push(i18n.t(['itemdefinition.invisiblehotspot.YOU_HAVE_TO_MARK_MIN_MAX_POSITIONS'], { min, max }));
            }
        }
        if (this.data.scoringMode == InvisibleHotSpotEdit_Scoring.PerAnswer) {
            hintText.push(i18n.t(['itemdefinition.invisiblehotspot.SELECTING_WRONG_AREAS_WILL_REDUCE_THE_SCORE']));
        }
        return hintText.join('\n');
    });
    public clickImage(data: unknown, event: MouseEvent) {
        if (this.mode() !== 'INTERACTIVE') {
            return;
        }
        const w = this.imageWidth();
        const h = this.imageHeight();
        const x = bounds(event.offsetX, 0, w) * 100 / w;
        const y = bounds(event.offsetY, 0, h) * 100 / h;
        this.data.addAnswer({ x, y });
    }
    public readonly data: InvisibleHotspotData;

    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                HELPER.assertNever(mode);
                throw new Error();

        }
    });

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });



    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        return false;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();


        this.imageWidth(this.data.imageWidth);
        this.imageHeight(this.data.imageHeight);

        this.loaded(true);
    }

    public readonly imgUrl = ko.pureComputed(() => this.data.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.data.imgAlt);
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());


    public readonly imageWidth = ko.observable(0);
    public readonly imageHeight = ko.observable(0);



}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME),
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';




const WIDGET_NAME = 'ui-widgets-terminaldialog';
const WIDGET_PARENT_NAME = 'ui';

export interface IParams {
    url: ko.Observable<string> | ko.Computed<string>;
}

export class ViewModel extends AbstractWidget {
    public readonly itemDocRefId: string;

    public readonly sessionId: string;
    private updateVisibility() {
        const u = this.params.url();
        //        if (u) {
        //            $(this.element).show();
        //        } else {
        //            $(this.element).hide();
        //        }
    }
    constructor(readonly params: IParams, readonly element: Node) {
        super();

        this.disposables.addDiposable(this.params.url.subscribe(newValue => {
            this.updateVisibility();
        }));
        this.updateVisibility();
    }

    public readonly terminalSocketClosed = ko.observable(false);
    public readonly actionCloseTerminal = new UIAction(undefined, async () => {
        this.params.url(undefined);
        this.terminalSocketClosed(false);
    });

    public closeButtonOptions() {
        const retVal: DevExpress.ui.dxButton.Properties = {
            icon: 'close',
            text: i18next.t(['ui.widgets.terminaldialog.CLOSE']),
            onClick: () => this.actionCloseTerminal.click()
        };
        return retVal;
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo.element);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import { formatMessage, loadMessages, locale } from "devextreme/localization";
import * as ko from 'knockout';
import { GetDefaultLanguage, GetEnabledLanguages, GetTranslatePrefix } from '../config';
import { info, log } from '../debug';
import { DONE } from '../helper';
import * as i18n from './i18n';
import { loadComplete } from './i18n';

export * from '.';


export type DataSource = Array<{ key: string, value: string }>;
export type LangDataSource<T extends string> = Array<{ key: T, value: string }>;
export type LangObj<T extends string> = { [key in T]: i18n.TranslateKey };

export function makeDS<T extends string>(strings: LangObj<T>, order: T[]): () => LangDataSource<T> {
    return () => {
        return order.map(key => ({
            key: key,
            value: i18n.t(strings[key]),
        }));
    };
}
export function translateDataSource(src: LangDataSource<string>): DataSource {
    return src.map(x => ({
        key: x.key,
        value: x.value,
    }));
}

export const currentLanguageTag = ko.observable('');

export async function initLangSubSystem(isoCodes: string[], forceFirst: boolean) {
    if (!isoCodes) {
        isoCodes = [];
    }
    isoCodes.push(GetDefaultLanguage());
    isoCodes = isoCodes.map(x => x.toLowerCase());
    const enabledLanguages = new Set(GetEnabledLanguages().map(x => x.toLowerCase()));
    if (forceFirst) {
        enabledLanguages.add(isoCodes[0]);
    }
    const selectedIso = isoCodes.find(x => enabledLanguages.has(x));

    const url = `${GetTranslatePrefix()}${selectedIso}.json`;
    info(`Load language file ${selectedIso} from language server ${url}`);

    const langData = await fetch(url, { mode: 'cors' });
    if (!langData.ok) {
        throw new Error(`Unable to load language resources`);
    }
    const data = await langData.json();

    if (!isoCodes || !data) {
        throw new Error(`Preloaded messages are not available`);
    }

    if (data.DevExtreme) {
        const messages: { [key: string]: unknown } = {};
        messages[selectedIso] = data.DevExtreme;
        messages['en'] = data.DevExtreme;
        messages[locale()] = data.DevExtreme;
        console.log(`Loading DevExtreme Messages %o`, messages);
        loadMessages(messages);
    }
    locale(selectedIso);


    const resources: i18n.Resource = {};
    resources[selectedIso] = { translation: data };

    await i18n.init({
        lowerCaseLng: true,
        fallbackLng: [selectedIso],
        lng: selectedIso,
        resources,
    });


    loadComplete();
    log(`ui.superuser.home.HOME: ${i18n.t(['ui.superuser.home.HOME'])} Yes: ${formatMessage('Yes')} Search: ${formatMessage('dxDataGrid-searchPanelPlaceholder')}`);
    return DONE;
}


import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { log } from '../../../debug';
import { DONE } from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { focusManager } from '../../../ui/focusmanager';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { HandsOnData } from '../../model/handson/HandsOnData';
import { AccumulatedScore } from '../../model/ItemModel';
import { GetSession } from '../../model/session';
import * as i18next from './../../../i18n/i18n';
import * as FACTORY from './factory';
import { htmlString } from './handson.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-handson';

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemType = FACTORY.id;
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    private readonly data: HandsOnData;

    //public readonly focusId: string;
    private readonly hasReadyCheckbox = ko.pureComputed(() => {
        if (this._isTopItem()) {
            return false;
        }
        if (this._isOnlyChild()) {
            return false;
        }
        return true;
    });
    public readonly showReadyCheckbox = ko.pureComputed(() => {
        if (!this.isFocused()) {
            return false;
        }
        return this.hasReadyCheckbox();
    });

    public readonly isFocused = ko.pureComputed(() => {
        if (!focusManager.isFocused()) {
            return false;
        }
        const topItemFocus = focusManager.topItemFocus();
        if (!topItemFocus) {
            return false;
        }
        return true;
    });

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        //this.focusId = focusManager.newFocusId();

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof HandsOnData)) {
            throw new Error();
        }
        this.data = data;
        this.isDone = data.isDone;

        this.readyOptions = {
            value: this.isDone(),
            text: i18next.t(['itemdefinition.kosovo.handson.DONE']),
            onValueChanged: e => {
                this.clickDone.click(!!e.value);
            }
        };
        this.score(this.data.meta.accumulatedScore());
        this._isTopItem(this.data.meta.isTopItem);
        this._hasFocusMode(this.data.meta.hasFocusMode);
        this._isOnlyChild(this.data.meta.isOnlyChild);

    }


    public readonly hasFocusMode = ko.pureComputed(() => {
        return this._isTopItem() && this._hasFocusMode() && this.mode() === 'INTERACTIVE' && !focusManager.topItemFocus();
    });

    public readonly isDone: ko.Observable<boolean>;
    private readonly _isOnlyChild = ko.observable(false);
    private readonly _isTopItem = ko.observable(false);
    private readonly _hasFocusMode = ko.observable(false);

    public readonly readyOptions: DevExpress.ui.dxCheckBox.Properties;

    public readonly clickDone = new UIAction<boolean>(undefined, async (e, args) => {
        const isDone = !!args;
        if (this.isDone() !== isDone) {
            await this.data.setDone(isDone);
        }
    });

    public readonly score = ko.observable<AccumulatedScore>();

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });



    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                if (!this.isFocused()) {
                    return false;
                }
                if (this.isDone()) {
                    return false;
                }
                await this.clickDone.invoke(true, true);
                return true;
            }
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT' || mode === 'GRADING') {
            throw new Error(`${WIDGET_NAME} - no edit or grading mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);


        if (this.mode() === 'INTERACTIVE') {
            if (!this.hasReadyCheckbox()) {
                await this.clickDone.invoke(true, true);
            }
        }

        await this.OnRefresh();
        this.loaded(true);
    }

    async OnReset() {
        if (this.mode() === 'INTERACTIVE') {
            if (!this.hasReadyCheckbox()) {
                await this.clickDone.invoke(true, true);
            }
        }
        return DONE;
    }
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, refreshDx } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_TRANSLATIONEDIT from '../translationedit/route';
import * as i18next from './../../../i18n/i18n';
import { IParams, IParamsInner, WIDGET_NAME } from './route';
import { htmlString } from './translation.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_translation_data']>>;

export class ViewModel extends DxWidget {
    public readonly innerParams: IParamsInner;

    constructor(readonly params: IParams) {
        super();
        this.innerParams = params.currentRoute.widgetParams;
    }

    private readonly _examOrders: Q['subjectById']['openExamOrders'] = [];

    private readonly _languages: Q['config']['contentLanguages'] = [];

    public readonly selectedExamOrders = ko.observableArray<string>();
    public readonly selectedLanguages = ko.observableArray<string>();
    public readonly dxFormOptions = ko.pureComputed(() => {

        const s1: DevExpress.ui.dxForm.SimpleItem = {
            editorType: 'dxTagBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.translation.EXAM_ORDER']),
                location: 'top'
            },
            dataField: 'selectedExamOrders',
            editorOptions: AS<DevExpress.ui.dxTagBox.Properties>({
                dataSource: this._examOrders,
                displayExpr: 'examinationOrderId',
                valueExpr: 'docReferenceId',
                showSelectionControls: true,
                applyValueMode: 'useButtons',
                searchEnabled: true,
                searchExpr: ['title']
            })

        };

        const s2: DevExpress.ui.dxForm.SimpleItem = {
            editorType: 'dxTagBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.translation.LANGUAGE']),
                location: 'top',
            },
            dataField: 'selectedLanguages',
            editorOptions: AS<DevExpress.ui.dxTagBox.Properties>({
                dataSource: this._languages,
                displayExpr: 'name.value',
                valueExpr: 'id',
                showSelectionControls: true,
                applyValueMode: 'useButtons',
                searchEnabled: true,
                searchExpr: ['name.value']
            })
        };

        const s3: DevExpress.ui.dxForm.ButtonItem = {
            itemType: 'button',
            buttonOptions: {
                text: i18next.t(['ui.subjectcoordinator.translation.OPEN']),
                onClick: this.click.click
            }
        };

        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: {
                selectedExamOrders: this.selectedExamOrders,
                selectedLanguages: this.selectedLanguages
            },
            colCount: 4,
            items: [s1, s2, s3]
        };
        retVal.onInitialized = e => {
            this.forms.add(e.component);
        };
        retVal.onDisposing = e => {
            this.forms.delete(e.component);
        };
        return retVal;
    });




    private readonly availableLanguages = ko.pureComputed(() => {
        const langs = this.config().config.contentLanguages.map(x => x.id);
        return langs;
    });

    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);

    private readonly config = ko.observable<Q>();

    public async OnRefresh() {
        await super.OnRefresh();
        const config = await ServerConnection.api.ui_subjectcoordinator_translation_data({
            subjectId: this.params.currentRoute.widgetParams.subjectDocRefId,
        });
        this.config(config);
        this._examOrders.splice(0, this._examOrders.length, ...config.subjectById.openExamOrders);
        this._languages.splice(0, this._languages.length, ...config.config.contentLanguages);
        await refreshDx(this);

    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly click = new UIAction(undefined, async () => {
        const subjectDocRefId = this.params.currentRoute.widgetParams.subjectDocRefId;

        const selectedLanguages = this.selectedLanguages();
        const examOrderDocRefIds = this.selectedExamOrders();
        const isoCodes = selectedLanguages;
        if (!isoCodes.length) {
            return;
        }
        if (!examOrderDocRefIds.length) {
            return;
        }

        await ROUTES.routeManager.navigateToHREF(WIDGET_TRANSLATIONEDIT.FACTORY.href(
            {
                subjectDocRefId,
                examOrderDocRefIds,
                isoCodes,
            }
        ));
    });
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-statisticexport';

type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class StatisticExportRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;

    public static async CREATE(params: IParamsInner) {

        const route = new StatisticExportRoute(params);
        return route;

    }
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.statisticexport.STATISTIC_EXPORT']);
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/statisticexport',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return StatisticExportRoute.CREATE(undefined);
    },
});


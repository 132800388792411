import { log } from '../debug';
import * as query from '../getquery';
import { ITSR2_External } from './itsr2_external';

export function isNoNetwork() {
    const main = query.params.get('main');
    if (main && main.startsWith('ui-splash-')) {
        return true;
    }
    return false;
}

export function getAmbientBrowserSessionId(): { id: string | undefined, source: 'none' | 'query' | 'its-client' | 'session-storage' } {
    if (query.params.has('browserSessionId')) {
        const id = query.params.get('browserSessionId');
        if (id) {
            log(`Browser session id (from query #1): ${id}`);
            return { id, source: 'query' };
        }
    }
    if (query.params.has('browserSession')) {
        const id = query.params.get('browserSession');
        if (id) {
            log(`Browser session id (from query #2): ${id}`);
            return { id, source: 'query' };
        }
    }
    if (typeof ITSR2_External === 'object') {
        const id = ITSR2_External.browserSessionID;
        if (id) {
            log(`Browser session id (from ITSR2_External.browserSessionID): ${id}`);
            return { id, source: 'its-client' };
        }
    }
    const fromSessionStorage = sessionStorage.getItem('browserSessionId');
    if (fromSessionStorage) {
        log(`Browser session id (from session Storage): ${fromSessionStorage}`);
        return { id: fromSessionStorage, source: 'session-storage' };
    }
    log(`No ambient browser session id! window.location.search=${window.location.search}`);
    return { id: undefined, source: 'none' };
}

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS, selectBoxDS } from '../../../../dx_helper';
import { DONE, normalizeNumber } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import { legacyPushPull, queueApiCall } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18next from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['singlechoicematrix_edit_data']>>;
const WIDGET_NAME = 'itemdefinition-kosovo-singlechoicematrix-edit';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';
type ROW = Q['SingleChoiceMatrixEdit']['get']['rows'][0];
type COLUMN = Q['SingleChoiceMatrixEdit']['get']['columns'][0];
export class VMRow {
    constructor(readonly model: ViewModel, readonly data: ROW) {
        this._correct(data.correct);
        this._value(data.value.value);
        this._score(data.score || 0);
        this.canEdit = model.canEdit;
    }
    public readonly canEdit: boolean;

    private readonly _value = ko.observable('');
    private readonly _correct = ko.observable('');
    private readonly _score = ko.observable(0);
    public readonly correct = ko.pureComputed({
        read: () => {
            return this._correct();
        },
        write: newVal => {
            this._correct(newVal);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertRowCorrect/${this.data.index}`, () =>
                ServerConnection.api.singlechoicematrix_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        upsertRows: [{
                            index: this.data.index,
                            correct: newVal
                        }]
                    }
                }));
        }
    });
    public setCorrect(id: string) {
        if (this.model.isReadOnly) {
            return;
        }
        this.correct(id);
    }
    public readonly value = ko.pureComputed({
        read: () => {
            return this._value();
        },
        write: newVal => {
            this._value(newVal);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertRowValue/${this.data.index}`, () =>
                ServerConnection.api.singlechoicematrix_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        upsertRows: [{
                            index: this.data.index,
                            value: xnone(newVal)
                        }]
                    }
                }));
        }
    });
    public readonly score = ko.pureComputed({
        read: () => {
            return this._score();
        },
        write: newVal => {
            const score = normalizeNumber(newVal);
            this._score(score);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertRowScore/${this.data.index}`, () =>
                ServerConnection.api.singlechoicematrix_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        upsertRows: [{
                            index: this.data.index,
                            score,
                        }]
                    }
                }));
        }
    });
    public readonly id = ko.pureComputed(() => this.data.index);
    public readonly rowId = ko.pureComputed(() => this.data.index);

    public readonly rowDelete = new UIAction(undefined, async () => {
        if (this.model.orderedRows().length <= 2) {
            toastService.error(i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.YOU_CANNOT_DELETE_THIS_ROW_THE_ITEM_MUST_HAVE_AT_LEAST_2_ROWS']));
            return;
        }
        const message = i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ROW']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await legacyPushPull(async () => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.model.itemId,
                    removeRows: [this.data.index]
                }
            });
        });
    });
}

export class VMColumn {
    constructor(readonly model: ViewModel, readonly col: COLUMN) {
        this._value(col.value.value);
    }
    private readonly _value = ko.observable('');

    public readonly id = ko.pureComputed(() => {
        return this.col.index;
    });
    public readonly value = ko.pureComputed({
        read: () => {
            return this._value();
        },
        write: newVal => {
            this._value(newVal);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertColValue/${this.col.index}`, () =>
                ServerConnection.api.singlechoicematrix_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        upsertColumns: [{
                            index: this.col.index,
                            value: xnone(newVal)
                        }]
                    }
                }));
        }
    });
    public readonly columnDelete = new UIAction(undefined, async () => {
        if (this.model.orderedColumns().length <= 2) {
            toastService.error(i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.YOU_CANNOT_DELETE_THIS_COLUMN_THE_ITEM_MUST_HAVE_AT_LEAST_2_COLUMNS']));
            return;
        }
        const message = i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_COLUMN']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }


        await legacyPushPull(async () => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.model.itemId,
                    removeColumns: [this.col.index]
                }
            });
        });


    });
}

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly answers = ko.observableArray();


    public readonly SingleChoiceMatrixDeleteColumnButtonLabel = i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.DELETE_COLUMN']);

    public readonly SingleChoiceMatrixAddRowButtonLabel = i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.ADD_ROW']);
    public readonly SingleChoiceMatrixDeleteRowButtonLabel = i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.DELETE_ROW']);

    public readonly SingleChoiceMatrixAddColumnButtonLabel = i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.ADD_COLUMN']);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly header = ko.observable('');
    public readonly question = ko.observable('');

    public readonly scoringMode = ko.observable<API.SingleChoiceMatrixEdit_Scoring>();

    public readonly colMode = ko.observable<API.SingleChoiceMatrixEdit_ShuffleColumn>();
    public readonly rowMode = ko.observable<API.SingleChoiceMatrixEdit_ShuffleRow>();

    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                rowMode: this.rowMode,
                colMode: this.colMode,
                scoringMode: this.scoringMode,
            },
            items: [],
        };

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'rowMode',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.ROWS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.SingleChoiceMatrixEdit_ShuffleRow.Shuffled,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.SHUFFLED']),
                },
                {
                    key: API.SingleChoiceMatrixEdit_ShuffleRow.Ordered,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.FIXED']),
                },
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'colMode',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.COLUMNS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.SingleChoiceMatrixEdit_ShuffleColumn.Shuffled,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.SHUFFLED'])
                },
                {
                    key: API.SingleChoiceMatrixEdit_ShuffleColumn.Ordered,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.FIXED'])
                },
                {
                    key: API.SingleChoiceMatrixEdit_ShuffleColumn.ShuffledExceptLast,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.LAST_COLUMN_FIXED'])
                },
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'scoringMode',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.POINTS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.SingleChoiceMatrixEdit_Scoring.AllOrNothing,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.FOR_ITEM'])
                },
                {
                    key: API.SingleChoiceMatrixEdit_Scoring.Individual,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.PER_ROW'])
                },
            ]),
        }));

        return retVal;
    });

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));

        return retVal;
    });
    public readonly totalScore = ko.pureComputed(() => {
        let count = 0;
        this.orderedRows().forEach((itemRow) => {
            count += 1 * itemRow.score();
        });
        return count;
    });
    public readonly pointsText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.POINTS']);
    });

    public readonly hideScore = ko.pureComputed(() => {
        return this.scoringMode() === API.SingleChoiceMatrixEdit_Scoring.AllOrNothing;
    });

    public rowAdd = new UIAction('rowAdd', async () => {
        const findUnusedID = () => {
            for (let id = 1; ; ++id) {
                const existEd = this.orderedRows().filter(el => el.id() === id.toString());
                if (existEd.length === 0) {
                    return id.toString();
                }
            }
        };
        await legacyPushPull(async () => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertRows: [{
                        index: findUnusedID(),
                    }]
                }
            });
        });
    });
    public columnAdd = new UIAction('columnAdd', async () => {

        const findUnusedID = () => {
            for (let id = 1; ; ++id) {
                const existEd = this.orderedColumns().filter(el => el.id() === String.fromCharCode(65 + id));
                if (existEd.length === 0) {
                    return String.fromCharCode(65 + id);
                }
            }
        };
        await legacyPushPull(async () => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertColumns: [{
                        index: findUnusedID(),
                    }]
                }
            });
        });
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.singlechoicematrix_edit_data({
            itemId: this.itemId
        });
        const d = q.SingleChoiceMatrixEdit.get;
        this.header(d.header.value);
        this.question(d.question.value);
        this.orderedRows(d.rows.map(x => new VMRow(this, x)));
        this.orderedColumns(d.columns.map(x => new VMColumn(this, x)));
        this.scoringMode(d.scoring);
        this.rowMode(d.shuffleRow);
        this.colMode(d.shuffleColumn);
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async v => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.scoringMode, `${WIDGET_NAME}/${this.itemId}/scoring`, async (v) => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    scoring: v
                }
            });
            return DONE;
        });

        this.onChange(this.rowMode, `${WIDGET_NAME}/${this.itemId}/shuffleRow`, async (v) => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleRow: v
                }
            });
            return DONE;
        });
        this.onChange(this.colMode, `${WIDGET_NAME}/${this.itemId}/shuffleColumn`, async (v) => {
            await ServerConnection.api.singlechoicematrix_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleColumn: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly orderedColumns = ko.observable<VMColumn[]>();
    public readonly orderedRows = ko.observable<VMRow[]>();

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.ENTER_THE_TEXT_HERE']);
    });
    public readonly enterColTitlePlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicematrix.edit.COLUMN_TITLE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

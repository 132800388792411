export const id = 'kosovo-orderingselection';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';


itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '29312924-e668-4d12-8833-a9d2daa7268e',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS, selectBoxDS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18n from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['crossout_edit_data']>>;

const WIDGET_NAME = 'itemdefinition-kosovo-crossout-edit';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

class EditLine {
    public lineId: string;
    public readonly editLineMode = ko.observable(false);
    public readonly lineText = ko.observable('');
    constructor(readonly model: ViewModel, readonly lineData: Q['CrossoutEdit']['get']['lines'][0]) {
        this.lineId = lineData.index;
        this.lineText(undefined);
        this.isReadOnly = model.isReadOnly;
        this.canEdit = model.canEdit;
    }
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly isLineCorrect = ko.pureComputed(() => {
        return !this.lineData.incorrect;
    });

    public readonly actionCancel = new UIAction(undefined, async () => {
        this.lineText(undefined);
        this.editLineMode(false);
    });

    public readonly actionOkLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.crossout.edit.OK']);
    });
    public readonly actionCancelLabel = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.crossout.edit.CANCEL']);
    });
    public readonly actionOk = new UIAction(undefined, async () => {

        const templateText = this.lineText();

        const p = {
            params: {
                itemId: this.model.itemId,
                upsertLines: [{
                    index: this.lineId,
                    annotatedText: templateText
                }]
            }
        };
        await legacyPushPull(async () => {
            await ServerConnection.api.crossout_edit_update(p);
        });

        this.editLineMode(false);
    });
    public readonly setLineCorrected = new UIAction(undefined, async () => {
        if (this.model.isReadOnly) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.crossout_edit_update({
                params: {
                    itemId: this.model.itemId,
                    upsertLines: [{
                        index: this.lineId,
                        incorrect: ''
                    }]
                }
            });
        });
    });

    public readonly editLine = new UIAction(undefined, async () => {
        this.lineText(this.lineData.annotatedText);
        this.editLineMode(true);
    });
    public readonly deleteLine = new UIAction(undefined, async () => {
        if (this.model.editlines().length <= 1) {
            toastService.error(i18n.t(['itemdefinition.kosovo.crossout.edit.YOU_CANNOT_DELETE_THIS_SENTENCE_THE_ITEM_MUST_HAVE_A_MINIMUM_OF_1_CORRIGIBLE_SENTENCE'])
            );
        } else {
            const message = i18n.t(['itemdefinition.kosovo.crossout.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_SENTENCE']);
            if (!await Modal.confirmYesNo(message)) {
                return;
            }
            await legacyPushPull(async () => {
                await ServerConnection.api.crossout_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        removeLines: [this.lineId]
                    }
                });
            });
        }
    });

    public readonly fragments = ko.pureComputed(() => {
        return this.lineData.fragments.map(fragment => {
            return new EditFragment(this.model, this, fragment);
        });
    });
}

class EditFragment {
    public value = ko.pureComputed(() => {
        return this.fragment.value;
    });
    public id = ko.pureComputed(() => {
        return this.fragment.index;
    });
    constructor(readonly model: ViewModel, readonly line: EditLine, readonly fragment: Q['CrossoutEdit']['get']['lines'][0]['fragments'][0]) {
    }

    public readonly isCorrect = ko.pureComputed(() => {
        const incorrect = this.line.lineData.incorrect && this.line.lineData.incorrect.index;
        if (!incorrect) {
            return false;
        }
        return incorrect === this.fragment.index;
    });
    public readonly setCorrected = new UIAction(undefined, async () => {
        if (this.model.isReadOnly) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.crossout_edit_update({
                params: {
                    itemId: this.model.itemId,
                    upsertLines: [{
                        index: this.line.lineId,
                        incorrect: this.id(),
                    }]
                }
            });
        });
    });

}
export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly loaded = ko.observable(false);
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly question = ko.observable('');
    public readonly header = ko.observable('');
    public readonly scoringModeString = ko.observable<API.CrossoutScoringMode>(API.CrossoutScoringMode.AllOrNothing);
    public readonly shuffleModeString = ko.observable<API.CrossoutShuffleMode>(API.CrossoutShuffleMode.Ordered);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;

    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.crossout.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.crossout.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.crossout.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.crossout.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));

        return retVal;
    });

    public readonly addNewLineLabel = i18n.t(['itemdefinition.kosovo.crossout.edit.ADD_SENTENCE']);
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                scoringModeString: this.scoringModeString,
                shuffleModeString: this.shuffleModeString,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'scoringModeString',
            label: {
                text: i18n.t(['itemdefinition.kosovo.crossout.edit.POINTS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.CrossoutScoringMode.AllOrNothing,
                    value: i18n.t(['itemdefinition.kosovo.crossout.edit.FOR_ITEM'])
                },
                {
                    key: API.CrossoutScoringMode.PerLine,
                    value: i18n.t(['itemdefinition.kosovo.crossout.edit.PER_LINE'])
                }
            ]),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'shuffleModeString',
            label: {
                text: i18n.t(['itemdefinition.kosovo.crossout.edit.MODE_FOR_LINES'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.CrossoutShuffleMode.Shuffled,
                    value: i18n.t(['itemdefinition.kosovo.crossout.edit.SHUFFLED'])
                },
                {
                    key: API.CrossoutShuffleMode.Ordered,
                    value: i18n.t(['itemdefinition.kosovo.crossout.edit.FIXED'])
                },
            ]),
        }));

        return retVal;
    });

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.crossout_edit_data({
            itemId: this.itemId
        });
        const d = q.CrossoutEdit.get;
        this.scoringModeString(d.scoringMode);
        this.shuffleModeString(d.shuffleMode);
        this.header(d.header.value);
        this.question(d.question.value);
        this.editlines(d.lines.map(x => new EditLine(this, x)));

    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.crossout_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.crossout_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val),
                }
            });
            return DONE;
        });
        this.onChange(this.scoringModeString, `${WIDGET_NAME}/${this.itemId}/scoring`, async val => {
            await ServerConnection.api.crossout_edit_update({
                params: {
                    itemId: this.itemId,
                    scoringMode: val
                }
            });
            return DONE;
        });
        this.onChange(this.shuffleModeString, `${WIDGET_NAME}/${this.itemId}/shuffleMode`, async val => {
            await ServerConnection.api.crossout_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleMode: val
                }
            });
            return DONE;
        });
        this.loaded(true);
    }


    public readonly editlines = ko.observable<EditLine[]>([]);

    public addNewline = new UIAction(undefined, async () => {
        const idLine = this.findUnusedLineID();
        await legacyPushPull(async () => {
            await ServerConnection.api.crossout_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertLines: [{
                        index: idLine,
                        annotatedText: i18n.t(['itemdefinition.kosovo.crossout.edit.THIS_IS_A_LAINE'])
                    }]
                }
            });
        });
    });

    private findUnusedLineID() {
        const existing = new Set<string>();
        for (const line of this.editlines()) {
            existing.add(line.lineId);
        }
        for (let nr = 1; ; ++nr) {
            const id = nr.toString();
            if (!existing.has(id)) {
                return id;
            }
        }
    }



    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.crossout.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.crossout.edit.ENTER_THE_TEXT_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

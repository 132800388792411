export const id = 'kosovo-matchingimage';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '95bf6308-42fa-49c5-a436-fe5c60609593',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { log } from '../../../../debug';
import { AS, datagrid, refreshDx } from '../../../../dx_helper';
import { TRANSLATION_STATUS } from '../../../../enums/datasource';
import { update_observable } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { TranslationStatusEnum } from '../../../../its-itembank-api.g';
import { stringCompare } from '../../../../tree';
import { legacyPushPull } from '../../../docmanager';
import { ServerConnection } from '../../../RestAPI';
import { UIAction } from '../../../uiAction';
import * as i18next from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type INIT = Awaited<ReturnType<API.Sdk['ui_author_itemedit_translation_init']>>;
type Q = Awaited<ReturnType<API.Sdk['ui_author_itemedit_translation_data']>>;
export const WIDGET_NAME = 'ui-author-itemedit-translation';

interface IDXOnRowUpdate {
    data: IItemTranslationEntry,
    key: IItemTranslationEntry
}

interface IItemTranslationEntry {
    itemDocRefId: string,
    key: string,
    id: string,
    defaultLanguage: string,
    data: { [key: string]: string }
}

export interface IParams {
    itemId: string;
    localizations?: string[];
}

class Localization {
    public readonly name: string;
    public readonly status: string;
    public readonly setToDone: UIAction<undefined>;
    constructor(readonly model: ViewModel, readonly data: INIT['item']['get']['workflow']['translationStatus'][0]) {
        this.name = data.localization.name.value;
        this.status = TRANSLATION_STATUS().find(x => x.key === data.status).value;
        if (data.status !== 'done' && data.translateableByYou) {
            this.setToDone = new UIAction(undefined, async () => {
                await legacyPushPull(() => ServerConnection.api.UpdateStatus({
                    params: {
                        itemId: this.model.params.itemId,
                        updateTranslationStatus: [
                            {
                                localizationId: this.data.localization.localizationId,
                                status: TranslationStatusEnum.Done,
                            }
                        ]
                    }
                }));
            });
        }
    }
    public readonly buttonOptions = ko.pureComputed(() => {
        if (this.setToDone) {
            return AS<DevExpress.ui.dxButton.Properties>({
                text: i18next.t(['ui.author.itemedit.translation.SET_TO_DONE']),
                onClick: this.setToDone.click,
            });
        }
        return undefined;
    });

}
export class ViewModel extends DxWidget {

    constructor(readonly params: IParams) {
        super();
    }

    private readonly serverStrings = new Map<string, string>();
    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);

    private lastInit: INIT = undefined;

    public readonly localizations = ko.observable<Localization[]>();

    public readonly isoCodes = ko.observable<string[]>();

    public async OnRefresh() {
        await super.OnRefresh();
        const init = await ServerConnection.api.ui_author_itemedit_translation_init({
            itemId: this.params.itemId,
        });
        this.lastInit = init;
        const codes = new Set<string>();
        codes.add('x-none');
        const limitLocalizations = new Set(this.params.localizations || []);
        const translationStatus = this.lastInit.item.get.workflow.translationStatus.filter(x => {
            if (!this.params.localizations) {
                return true;
            }
            return limitLocalizations.has(x.localization.localizationId);
        });
        for (const x of translationStatus) {
            if (x.translateableByYou) {
                for (const l of x.localization.isoLanguages) {
                    codes.add(l.isoCode);
                }
            }
        }
        update_observable(this.isoCodes, Array.from(codes));
        this.localizations(
            translationStatus
                .map(x => new Localization(this, x))
                .sort((a, b) => stringCompare(a.name, b.name)));
        const query = await ServerConnection.api.ui_author_itemedit_translation_data({
            params: {
                itemDocRefIds: [this.params.itemId],
                isoCodes: this.isoCodes(),
                subjectDocRefId: this.lastInit.item.get.subject.docReferenceId
            }
        });
        this.itemListRaw(query);
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly itemListRaw = ko.observable<Q>();

    public readonly data = ko.pureComputed(() => {
        const retVal: IItemTranslationEntry[] = [];
        const rawData = this.itemListRaw();
        if (!rawData) {
            return undefined;
        }
        for (const item of rawData.getItemTranslations) {
            if (!item.entries) {
                continue;
            }
            const iso = item.isoCode;
            for (const entry of item.entries) {
                for (const translation of entry.translations) {
                    let itemEntry: IItemTranslationEntry;
                    itemEntry = retVal.find(item => {
                        if (item.itemDocRefId === entry.itemDocRefId && item.key === translation.key) {
                            return true;
                        }
                        return false;
                    });
                    if (!itemEntry) {
                        itemEntry = {
                            id: translation.id,
                            itemDocRefId: entry.itemDocRefId,
                            key: translation.key,
                            defaultLanguage: entry.defaultLanguage,
                            data: {}
                        };
                        itemEntry.data[iso] = translation.value;
                        retVal.push(itemEntry);
                    } else {
                        itemEntry.data[iso] = translation.value || '';
                    }
                }
            }
        }
        return retVal;
    });

    public readonly dxDataGridOptions = ko.pureComputed(() => {
        const data = this.data() || [];
        if (!data) {
            return undefined;
        }
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: data,
                editing: {
                    mode: 'cell',
                    allowUpdating: true
                },
                filterRow: {
                    visible: true,
                },
                columnChooser: {
                    enabled: this.localizations()?.length > 6,
                    mode: 'select',
                    allowSearch: true,
                },
                searchPanel: {
                    visible: true,
                },
                columns: [
                    {
                        dataField: 'itemDocRefId',
                        allowEditing: false,
                        caption: i18next.t(['ui.author.itemedit.translation.ITEM_ID'])
                    },
                    {
                        dataField: 'key',
                        caption: i18next.t(['ui.author.itemedit.translation.KEY']),
                        allowEditing: false
                    }
                ],
                allowColumnResizing: true,
                wordWrapEnabled: true,
                export: {
                    enabled: true,
                    fileName: 'Translations',
                    allowExportSelectedData: false
                },
                hoverStateEnabled: true,
            }
        });
        const codes = new Map<string, { readOnly: boolean, caption: string }>();
        const limitLocalizations = new Set(this.params.localizations || []);
        for (const translationStatus of this.lastInit.item.get.workflow.translationStatus) {
            if (this.params.localizations) {
                if (!limitLocalizations.has(translationStatus.localization.localizationId)) {
                    continue;
                }
            }
            for (const isoLanguage of translationStatus.localization.isoLanguages) {
                const isoCode = isoLanguage.isoCode;
                if (!codes.has(isoCode)) {
                    codes.set(isoCode, {
                        readOnly: true,
                        caption: isoLanguage.name.value,
                    });
                }
                if (translationStatus.translateableByYou) {
                    codes.get(isoCode).readOnly = false;
                }
            }
        }
        codes.delete('x-none');
        retVal.columns.push({
            dataField: 'data.x-none',
            dataType: 'string',
            caption: i18next.t(['ui.author.itemedit.translation.DEFAULT']),
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                readOnly: !this.lastInit.item.get.workflow.editableByYou,
            })
        });

        for (const key of Array.from(codes.keys()).sort()) {
            const colName = codes.get(key).caption;
            retVal.columns.push({
                dataField: 'data.' + key,
                caption: colName,
                dataType: 'string',
                editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                    readOnly: codes.get(key).readOnly,
                    height: 'auto',
                })
            });
        }
        retVal.onEditorPreparing = e => {
            if (!e.dataField) {
                return;
            }
            if (e.parentType !== 'dataRow') {
                return;
            }
            if (e.dataField.startsWith('data.')) {
                e.editorName = 'dxTextArea';
                e.editorOptions.height = 300;
            }
        };

        retVal.onRowUpdated = (e: IDXOnRowUpdate) => this.updateTranslation(e);

        return retVal;
    });

    public updateTranslation(e: IDXOnRowUpdate) {
        log(e);
        for (const key of Object.keys(e.data.data)) {
            const isoCode = key;
            const id = `${e.key.itemDocRefId}:${isoCode}:${e.key.key}`;
            void ServerConnection.api.ui_author_itemedit_translation_update(
                {
                    params: {
                        translation: {
                            id: id,
                            key: e.key.key,
                            value: e.data.data[isoCode]
                        }
                    }
                }
            );
        }
    }
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-author-manageitem';
export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    itemDocRefId: string;
    subjectDocRefId: string;
}

export class ManageItemRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.author.manageitem.COPY']);
    });
    public readonly routeActions = ko.pureComputed(() => {
        const actions: ROUTES.IRouteAction[] = [];
        return actions;
    });



    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{ subjectDocRefId: string, itemDocRefId: string }>({
    route: '/author/managitem/:subjectDocRefId/:itemDocRefId',
    toParams: (map) => ({
        subjectDocRefId: map.get('subjectDocRefId'),
        itemDocRefId: map.get('itemDocRefId')
    }),
    fromParams: (p, map) => {
        map.set('subjectDocRefId', p.subjectDocRefId);
        map.set('itemDocRefId', p.itemDocRefId);
    },
    create: async p => {
        const route = new ManageItemRoute(
            {
                subjectDocRefId: p.subjectDocRefId,
                itemDocRefId: p.itemDocRefId
            });
        return route;
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir } from '../../../debug';
import { refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import { AddMissingAttachments } from '../../../itemdefinition/helper';
import { IUpsertSubjectInput } from '../../../its-itembank-api.g';
import { colorService } from '../../colorservice';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
import { DATA, MyFormBuilder, Q } from './MyFormBuilder';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './subjectedit.html.g';

export class ViewModel extends DxWidget {
    private readonly formData: DATA = <any>{};
    public attachmentsStore: DevExpress.data.CustomStore;
    public languagesStore: DevExpress.data.CustomStore;

    public readonly form = new MyFormBuilder(this);

    public readonly loaded = ko.observable(false);

    private readonly subjectDocRef: string;

    constructor(readonly params: IParams) {
        super();
        this.subjectDocRef = this.params.currentRoute.widgetParams.subjectDocRefId;
    }
    public readonly imgUrl = ko.observable<string>();

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        this.form.formOptions.onFieldDataChanged = x => {
            void this.updateDatabase(x.dataField, x.value);
        };
    }

    private readonly _languages: Q['config']['localizations'] = [];

    public readonly tabs = ko.pureComputed(() => {
        const items: DevExpress.ui.dxTabPanel.Item[] = [];
        const retVal: DevExpress.ui.dxTabPanel.Properties = {
            dataSource: items,
            deferRendering: true,
        };
        items.push({
            title: i18next.t(['ui.superuser.subjectedit.DATA']),
            template: () => {
                const x = $(`
                <div data-bind="dxForm:form.formOptions">
            </div>
                `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: i18next.t(['ui.superuser.subjectedit.ASSETS']),
            template: () => {
                const x = $(`<div data-bind="component:{name:'widgets-assetmanager',params:{docRef:subjectDocRef,docType:'subject'}}"></div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            }
        });

        return retVal;
    });
    private async updateDatabase(fieldName: string, value: any) {
        const subjectDocRef = this.subjectDocRef;
        const subjectId = this.formData.subjectId;
        const up = this.form.fields.get(fieldName);
        if (up) {
            const params: IUpsertSubjectInput = {
                subjectId,
                subjectDocRef,
                ...up(value),
            };
            await ServerConnection.api.ui_superuser_subjectedit_update({
                params
            });
            return DONE;
        }
        return DONE;
    }
    public async OnRefresh() {
        await super.OnRefresh();
        const config = await ServerConnection.api.ui_superuser_subjectedit_data({
            subjectDocRef: this.subjectDocRef,
        });
        const subjectData = config.subjectById;
        this._attachments.splice(0, this._attachments.length, ...config.documents.get.attachments);
        this._languages.splice(0, this._languages.length, ...config.config.localizations);
        Object.assign(this.formData, subjectData);
        this.formData.colorRGBA = subjectData.color && colorService.getColor(subjectData.color) || '';
        this.formData.alternateColorRGBA = subjectData.alternateColor && colorService.getColor(subjectData.alternateColor) || '';
        AddMissingAttachments(this._attachments, [
            this.formData.image && this.formData.image.name
        ]);
        this.imgUrl(ServerConnection.getDataUrl(this.formData.image && this.formData.image.hrefResolved));
        dir(this.formData);
        await refreshDx(this);
    }
    private readonly _attachments: Q['documents']['get']['attachments'] = [];

    public async initialize() {
        this.languagesStore = new CustomStore({
            loadMode: 'raw',
            key: 'id',
            byKey: async key => {
                if (!this._languages) {
                    return undefined;
                }
                return this._languages.find(x => x.id === key);
            },
            load: async (options) => {
                return this._languages;
            }
        });

        this.attachmentsStore = new CustomStore({
            loadMode: 'raw',
            key: 'name',
            byKey: async key => {
                if (!this._attachments) {
                    return undefined;
                }
                return this._attachments.find(x => x.name === key);
            },
            load: async (options) => {
                return this._attachments;
            }
        });

        await super.initialize();
        this.initFormOptions();
        await this.OnRefresh();
        this.loaded(true);
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
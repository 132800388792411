import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { AS, selectBoxDS } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { ServerConnection } from '../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as i18n from './../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['surveycombo_edit_data']>>;

let initOnce: Promise<void>;

const WIDGET_NAME = 'itemdefinition-surveycombo-edit';


export type IParams = IItemDefinitionWidgetParams;


export class ViewModel extends AbstractWidget {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly loaded = ko.observable(false);

    private readonly isReadOnly: boolean;
    private readonly canEdit: boolean;
    private readonly _attachments: Q['documents']['get']['attachments'] = [];

    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.surveycombo_edit_data({
            itemId: this.params.itemId,
        });
        const d = data.SurveyComboEdit.get;
        this.data.handson = d;
        this._attachments.splice(0, this._attachments.length, ...data.documents.get.attachments);

        this.text(d.text.value);
        this.showCaption(d.showCaption.value);
        this.hideCaption(d.hideCaption.value);
        this.showHideButtonOrder(d.showHideButtonOrder);
        this.header(d.header.value);

        //await this.refreshGrid();
        if (this.form) {
            this.form.repaint();
        }
    }



    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.surveycombo.edit.ENTER_THE_TITLE_HERE_OPTIONAL']);
    });
    public readonly showCaption = ko.observable('');
    public readonly hideCaption = ko.observable('');
    public readonly showHideButtonOrder = ko.observable<API.SurveyCombo_ShowHideButtonOrder>(API.SurveyCombo_ShowHideButtonOrder.None);
    public readonly text = ko.observable('');
    public readonly header = ko.observable('');

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.itemDocId = 'item,' + this.itemId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public async initialize() {

        await super.initialize();
        await this.OnRefresh();

        this.onChange(this.showCaption, `${WIDGET_NAME}/${this.itemId}/showCaption`, async val => {
            await ServerConnection.api.surveycombo_edit_update({
                params: {
                    itemId: this.itemId,
                    showCaption: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.showHideButtonOrder, `${WIDGET_NAME}/${this.itemId}/showHideButtonOrder`, async val => {
            await ServerConnection.api.surveycombo_edit_update({
                params: {
                    itemId: this.itemId,
                    showHideButtonOrder: val
                }
            });
            return DONE;
        });
        this.onChange(this.hideCaption, `${WIDGET_NAME}/${this.itemId}/hideCaption`, async val => {
            await ServerConnection.api.surveycombo_edit_update({
                params: {
                    itemId: this.itemId,
                    hideCaption: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.surveycombo_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val),
                }
            });
            return DONE;
        });
        this.onChange(this.text, `${WIDGET_NAME}/${this.itemId}/text`, async val => {
            await ServerConnection.api.surveycombo_edit_update({
                params: {
                    itemId: this.itemId,
                    text: xnone(val),
                }
            });
            return DONE;
        });



        this.loaded(true);
    }

    private readonly data: {
        handson?: Q['SurveyComboEdit']['get'],
    } = {};

    private form: DevExpress.ui.dxForm;



    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: {
                header: this.header,
                isReadOnly: this.isReadOnly,
                showCaption: this.showCaption,
                hideCaption: this.hideCaption,
                showHideButtonOrder: this.showHideButtonOrder,
                text: this.text,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.surveycombo.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.surveycombo.edit.ENTER_THE_HEADER_HERE']),
                readOnly: this.isReadOnly,
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.surveycombo.edit.TEXT']),
            },
            readOnly: this.isReadOnly,
            dataField: 'text',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'showHideButtonOrder',
            label: {
                text: i18n.t(['itemdefinition.surveycombo.edit.SHOW_HIDE_BUTTON_ORDER']),
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.SurveyCombo_ShowHideButtonOrder.None,
                    value: i18n.t(['itemdefinition.surveycombo.edit.NO_BUTTONS']),
                },
                {
                    key: API.SurveyCombo_ShowHideButtonOrder.ShowHide,
                    value: i18n.t(['itemdefinition.surveycombo.edit.SHOW_HIDE']),
                },
                {
                    key: API.SurveyCombo_ShowHideButtonOrder.HideShow,
                    value: i18n.t(['itemdefinition.surveycombo.edit.HIDE_SHOW']),
                },
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'showCaption',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.surveycombo.edit.SHOW_BUTTON_CAPTION']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                readOnly: this.isReadOnly,
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'hideCaption',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.surveycombo.edit.HIDE_BUTTON_CAPTION']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                readOnly: this.isReadOnly,
            }),
        }));
        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

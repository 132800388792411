import * as i18next from './../../i18n/i18n';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import * as $LANG from '../../i18n/data';
import { focusManager } from '../../ui/focusmanager/index';
import { UIAction } from '../../ui/uiAction';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'widgets-leavefocusmodebutton';

export type IParams = undefined;

export class ViewModel extends AbstractWidget {
    constructor(readonly params: IParams) {
        super();
    }
    public readonly visible = ko.pureComputed(() => {
        return focusManager.isFocused();
    });

    public readonly actionEnter = new UIAction(undefined, async () => {
        await focusManager.leaveFocus();
    });
    public readonly text = ko.pureComputed(() => {
        return i18next.t(['widgets.leavefocusmodebutton.LEAVE_FOCUS_MODE']);
    });

    public async initialize() {
        await super.initialize();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

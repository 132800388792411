import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import * as i18n from '../../i18n/i18n';
import { htmlString } from './widget.html.g';


const WIDGET_NAME = 'widgets-loadpanel';
const WIDGET_PARENT_NAME = 'widgets';

export interface IParams {
    loading?: ko.Subscribable<boolean>;
}

export class ViewModel extends AbstractWidget {
    constructor(readonly params: IParams) {
        super();
    }

    public readonly dxLoadPanelOptions = ko.pureComputed(() => {
        const loading = this.params.loading ? this.params.loading() : true;
        const message = i18n.t(['widgets.loadpanel.LOADING DATA']);

        return <DevExpress.ui.dxLoadPanel.Properties>{
            closeOnOutsideClick: false,
            visible: loading,
            message: message,
            height: 150,
            width: 400,
        };
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

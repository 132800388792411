import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { datagrid, datagridButton, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import { assertNever, DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ExaminationOrderStatus } from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { registerOnUpdateToolbar } from '../../toptoolbar.service';
import { UIAction } from '../../uiAction';
import * as TranslateExamOrderRoute from '../translateexamorder/route';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';
type Q = Awaited<ReturnType<API.Sdk['ui_translator_selectexamorder_data']>>;
type EXAMORDER = Q['examOrder']['list'][0];

function convertStatus(x: ExaminationOrderStatus): TRANSLATIONS.StatusString {
    switch (x) {
        case ExaminationOrderStatus.Open:
            return 'open';
        case ExaminationOrderStatus.Populating:
            return 'populating';
        case ExaminationOrderStatus.Published:
            return 'published';
        case ExaminationOrderStatus.Ready:
            return 'ready';
        default:
            assertNever(x);
            throw new Error();
    }

}

export class ViewModel extends DxWidget {
    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                columns: [],
                allowColumnResizing: true,
                allowColumnReordering: true,
                columnChooser: {
                    enabled: true,
                    mode: 'select'
                },
                sorting: {
                    mode: 'single',
                },

                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                headerFilter: {
                    visible: true,
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'docReferenceId',
                        load: async () => {
                            return this._examorder;
                        },
                        byKey: async key => {
                            return this._examorder.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.NAME_OF_EXAM_ORDER']),
            dataField: 'title',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.ID']),
            dataField: 'examinationOrderId',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.STATUS']),
            dataType: 'string',
            dataField: 'status',
            calculateCellValue: data => {
                const x: EXAMORDER = data;
                return i18next.t(TRANSLATIONS.StatusString[convertStatus(x.status)]);
            }
        });
        retVal.columns.push({
            visible: false,
            caption: i18next.t(['ui.superuser.examorderoverview.TYPE']),
            dataType: 'string',
            dataField: 'type',
            calculateCellValue: data => {
                const x: EXAMORDER = data;
                return i18next.t(TRANSLATIONS.LevelOfSecrecyString[x.levelOfSecrecy]);
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.PROFILE']),
            dataType: 'string',
            dataField: 'profile.title',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.POINTS']),
            width: 80,
            dataType: 'number',
            dataField: 'scoreMax',
        });
        retVal.columns.push({
            visible: false,
            caption: i18next.t(['ui.superuser.examorderoverview.DATE_OF_EXAM']),
            dataType: 'string',
            dataField: 'examDate',
        });
        retVal.columns.push({
            visible: false,
            caption: i18next.t(['ui.superuser.examorderoverview.START_TIME']),
            dataType: 'string',
            dataField: 'examTime',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.LANGUAGES']),
            dataType: 'string',
            calculateDisplayValue: x => {
                const queryRow: EXAMORDER = x;
                return queryRow.localizations.map(x => x.name.value).sort().join(', ');
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.CREATED_AT']),
            dataType: 'datetime',
            dataField: 'dateCreated',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.superuser.examorderoverview.DEACTIVATED']),
            width: 80,
            dataField: 'isDeleted',
            dataType: 'boolean',
        });

        retVal.columns.push({
            type: 'buttons',
            caption: i18n.t(['ui.translator.selectexamorder.ACTIONS']),
            buttons: [
                datagridButton({
                    text: i18n.t(['ui.translator.selectexamorder.OPEN']),
                    action: this.actionEdit,
                }),

            ]
        });
        retVal.filterValue = ['isDeleted', '=', false];
        return retVal;
    });


    public readonly actionEdit = new UIAction<EXAMORDER>(undefined, async (e, args) => {
        await ROUTES.routeManager.navigateToHREF(TranslateExamOrderRoute.FACTORY.href({
            examorder: args.docReferenceId
        }));
    });

    public readonly _examorder: EXAMORDER[] = [];

    public async OnRefresh() {
        await super.OnRefresh();
        const result = await ServerConnection.api.ui_translator_selectexamorder_data({
        });
        const d = result.examOrder.list;
        this._examorder.splice(0, this._examorder.length, ...d);

        await refreshDx(this);
    }


    constructor(readonly params: IParams) {
        super();
    }
    public readonly loaded = ko.observable(false);
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.loaded(true);
    }

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        return DONE;
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

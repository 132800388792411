import type DevExpress from 'devextreme/bundles/dx.all';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir, log } from '../../../debug';
import { refreshDx } from '../../../dx_helper';
import { assertNever } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { TranslationStatusEnum } from '../../../its-itembank-api.g';
import { ItemMode } from '../../../model/interfaces';
import { stringCompare } from '../../../tree';
import * as TRANSLATION from '../../author/itemedit/translation/widget';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_translator_translateexamorder_data']>>;
type EXAMORDER = Q['examOrder']['byId'];
type SUBJECT = EXAMORDER['subjects'][0];
type Localization = ReturnType<typeof getTranslatorRole>['localizations'][0];
type ITEM = EXAMORDER['items'][0];
type ItemData = ITEM['item']['descendantItems'][0];
type Status = '' | 'open' | 'done';

function getTranslatorRole(user: Q['whoAmI']['user']) {
    const p = user?.translatorRole;
    if (!p) {
        throw new Error();
    }
    if (p.__typename !== 'LocalizationPrivilege') {
        throw new Error();
    }
    return p;
}

interface ItemEntry {
    id: string;
    type: 'item';
    display: string;
    parentId: string;
    itemId: string;
    topItemId: string;
    subjectId: string;
    title: string;
    translateableByYou: { [key: string]: Status };
}


interface SubjectEntry {
    id: string;
    type: 'subject';
    display: string;
    parentId: string;
    subjectId: string;
    title: string;
    translateableByYou: { [key: string]: Status };
}

interface ExamOrderEntry {
    id: string;
    type: 'examorder';
    display: string;
    parentId: string;
    title: string;
    translateableByYou: { [key: string]: Status };
}
type Entry = ItemEntry | SubjectEntry | ExamOrderEntry;

export class ViewModel extends DxWidget {

    public readonly selectedItem = ko.observable<Entry>();
    public readonly limitLocalizations = ko.pureComputed<string[]>(() => {
        return (this.localizations || []).map(x => x.localizationId);
    });
    public readonly selectedExamOrder = ko.pureComputed(() => {
        const sel = this.selectedItem();
        if (!sel) {
            return undefined;
        }
        if (sel.type !== 'examorder') {
            return undefined;
        }
        return true;
    });
    public readonly examOrder = ko.pureComputed(() => {
        return this.params.currentRoute.widgetParams.examOrderDocRefId;
    });

    public readonly selectedItemId = ko.pureComputed(() => {
        const sel = this.selectedItem();
        if (!sel) {
            return undefined;
        }
        if (sel.type !== 'item') {
            return undefined;
        }
        return sel.itemId;
    });

    private toItemEntry(subject: SUBJECT, data: ItemData, parent?: ItemData): ItemEntry {
        const subjectId = subject.subject.subjectId || '';
        const retVal: ItemEntry = {
            id: data.itemId,
            type: 'item',
            display: parent ? data.itemId.substr(parent.itemId.length) : data.itemId,
            parentId: parent && parent.itemId || subjectId,
            topItemId: parent && parent.itemId || data.itemId || '',
            subjectId,
            itemId: data.itemId,
            title: data.metaData.title,
            translateableByYou: {}
        };
        for (const loc of this.localizations) {
            retVal.translateableByYou[loc.localizationId] = '';
            const x = data.workflow.translationStatus.find(x => x.localization.localizationId == loc.localizationId);
            if (x) {
                if (x.translateableByYou) {
                    switch (x.status) {
                        case TranslationStatusEnum.Done:
                            retVal.translateableByYou[loc.localizationId] = 'done';
                            break;
                        default:
                            retVal.translateableByYou[loc.localizationId] = 'open';
                            break;
                    }
                }
            }
        }
        return retVal;
    }
    public readonly topItemDocRefId = ko.pureComputed(() => {
        const sel = this.selectedItem();
        if (!sel) {
            return undefined;
        }
        if (sel.type !== 'item') {
            return undefined;
        }
        return sel.topItemId;
    });

    constructor(readonly params: IParams) {
        super();
    }

    public readonly editAllowed = ko.observable(false);

    private readonly Items: Entry[] = [];
    public readonly itemsOptions = ko.pureComputed(() => {
        if (!this.loaded()) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxTreeList.Properties = {
            dataSource: this.Items,
            keyExpr: 'id',
            focusedRowEnabled: true,
            height: '100%',
            rootValue: '',
            columns: [],
            allowColumnResizing: true,
            autoExpandAll: true,
        };
        retVal.onInitialized = e => {
            this.widgets.add(e.component);
        };
        retVal.onDisposing = e => {
            this.widgets.delete(e.component);
        };
        retVal.onFocusedRowChanged = e => {
            let newItem: Entry;
            if (e.row && e.row.rowType === 'data' && e.row.node) {
                const item: Entry = e.row.node.data;
                newItem = item;
            }
            log(`Switching to item ${newItem && newItem.id}`);
            this.selectedItem(newItem);
        };
        retVal.columns.push({
            caption: i18n.t(['ui.translator.translateexamorder.ITEM_ID']),
            dataField: 'display',
            dataType: 'string',
            width: 100,
        });
        /*
        retVal.columns.push({
            caption: i18n.t(['ui.translator.translateexamorder.SUBJECT_ID']),
            dataField: 'subjectId',
            dataType: 'string',
        });
        */
        retVal.columns.push({
            caption: i18n.t(['ui.translator.translateexamorder.TITLE']),
            dataField: 'title',
            dataType: 'string',
        });

        for (const loc of this.localizations) {

            retVal.columns.push({
                caption: loc.name.value,
                dataField: `translateableByYou.${loc.localizationId}`,
                dataType: 'string',
                encodeHtml: false,
                calculateDisplayValue: (e: ItemEntry) => {
                    const val = e.translateableByYou[loc.localizationId];
                    if (!val) {
                        return '';
                    }
                    switch (val) {
                        case 'open':
                            const openText = i18n.t(['ui.translator.translateexamorder.OPEN']);
                            return `<span class='open'>${openText}</span>`;
                        case 'done':
                            const doneText = i18n.t(['ui.translator.translateexamorder.DONE']);
                            return `<span class='done'>${doneText}</span>`;
                        default:
                            assertNever(val);
                    }
                }
            });
        }

        return retVal;
    });

    private readonly localizations: Localization[] = [];
    public async OnRefresh() {
        await super.OnRefresh();
        const r = await ServerConnection.api.ui_translator_translateexamorder_data({
            examOrder: this.params.currentRoute.widgetParams.examOrderDocRefId,
        });
        const examOrder = r.examOrder.byId;
        const user = r.whoAmI.user;
        const locFilter = new Set<string>();
        for (const subj of examOrder.subjects) {
            for (const loc of subj.localizations) {
                locFilter.add(loc.localizationId);
            }
        }
        const translatorRole = user.translatorRole;
        if (translatorRole?.__typename !== 'LocalizationPrivilege') {
            throw new Error();
        }
        this.localizations.splice(0, this.localizations.length,
            ...translatorRole.localizations
                .filter(x => locFilter.has(x.localizationId))
                .sort((a, b) => stringCompare(a.name.value, b.name.value)));
        this.Items.splice(0, this.Items.length);
        this.Items.push({
            id: 'examorder',
            type: 'examorder',
            display: i18n.t(['ui.translator.translateexamorder.EXAM_ORDER']),
            parentId: '',
            title: '',
            translateableByYou: {},
        });

        const subjects = new Map<string, SUBJECT>();
        for (const s of examOrder.subjects) {
            subjects.set(s.subject._id, s);
            const subjectId = s.subject.subjectId;
            this.Items.push({
                id: subjectId,
                type: 'subject',
                display: subjectId,
                parentId: '',
                title: s.subject.title,
                subjectId,
                translateableByYou: {},
            });
        }
        for (const topItem of examOrder.items) {
            const s = subjects.get(topItem.item.metaData.subject._id);
            if (!topItem.item.itemType.supportsTranslations) {
                continue;
            }
            this.Items.push(this.toItemEntry(s, topItem.item));
            for (const subItem of topItem.item.descendantItems) {
                if (!subItem.itemType.supportsTranslations) {
                    continue;
                }
                this.Items.push(this.toItemEntry(s, subItem, topItem.item));
            }
        }
        await refreshDx(this);
    }

    public readonly back = new UIAction(undefined, async () => {
        await ROUTES.routeManager.back();
    });

    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);

    private readonly previewLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.PREVIEW']);
    });
    public readonly resultLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.RESULT']);
    });
    public readonly gradingLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.GRADING']);
    });
    public readonly editLabel = ko.pureComputed(() => {
        if (this.editAllowed()) {
            return i18next.t(['ui.author.itemedit.EDIT']);
        } else {
            return i18next.t(['ui.author.itemedit.INSPECT']);
        }
    });
    public readonly assetsLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.ASSETS']);
    });
    public readonly metadataLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.METADATA']);
    });
    public readonly translationLabel = ko.pureComputed(() => {
        return i18next.t(['ui.author.itemedit.TRANSLATION']);
    });
    public readonly selectedLabel = ko.observable('');
    public readonly isResult = ko.pureComputed(() => {
        return this.selectedLabel() === this.resultLabel();
    });
    public readonly isGrading = ko.pureComputed(() => {
        return this.selectedLabel() === this.gradingLabel();
    });
    public readonly isEdit = ko.pureComputed(() => {
        return this.selectedLabel() === this.editLabel();
    });
    public readonly isAssets = ko.pureComputed(() => {
        return this.selectedLabel() === this.assetsLabel();
    });
    public readonly isMetadata = ko.pureComputed(() => {
        return this.selectedLabel() === this.metadataLabel();
    });
    public readonly isTranslation = ko.pureComputed(() => {
        return this.selectedLabel() === this.translationLabel();
    });


    public readonly edit_mode = ko.pureComputed<ItemMode>(() => {
        return <ItemMode>'INSPECT';
    });

    public tabs() {
        const items: DevExpress.ui.dxTabPanelItem[] = [];
        const retVal: DevExpress.ui.dxTabPanel.Properties = {
            dataSource: items,
            deferRendering: true,

        };
        retVal.onSelectionChanged = e => {
            if (!e.addedItems || !e.addedItems.length) {
                this.selectedLabel('');
            } else {
                const item: DevExpress.ui.dxTabPanelItem = e.addedItems[0];
                this.selectedLabel(item.title);
            }
        };
        retVal.onInitialized = e => {
            const tab = e.component.option('selectedItem');
            dir(tab);
        };
        items.push({
            title: this.editLabel(),
            visible: true,
            template: () => {
                const x = $(`
                    <div data-bind="if:isEdit">
                    <div data-bind="component:{name:'ui-widgets-advanceditempresenter', params:{ item: selectedItemId(), mode:edit_mode()}}" style="margin:10px"></div>
                    </div>
                    `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: this.assetsLabel(),
            visible: this.editAllowed(),
            template: () => {
                const x = $(`                
                <div data-bind="if:isAssets">
                <div data-bind="component:{name:'widgets-assetmanager',params:{docRef:selectedItemId(),docType:'item'}}"></div>
                </div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });

        const loc = [
            {
                label: this.previewLabel(),
                localizationId: 'DEFAULT'
            }, ... this.localizations.map(x => ({
                label: this.previewLabel() + ' ' + x.name.value,
                localizationId: x.localizationId
            }))];
        for (const x of loc) {
            const label = x.label;
            const localizationId = x.localizationId;
            items.push({
                title: label,
                visible: true,
                template: () => {
                    //the "if:isPreview" is necessary to force a reload of the item presenter. interactive mode does not aggressivly reload the data, as items are locked in an examination
                    const x = $(`
                <div data-bind="if:selectedLabel()==='${label}'">
                    <div data-bind="component:{name: 'ui-widgets-advanceditempresenter', params:{ localizationId: '${localizationId}', item: topItemDocRefId(), mode: 'INTERACTIVE'}}" style="margin:10px"></div>
                </div>`);
                    ko.applyBindings(this, x.get(0));
                    return x;
                },
            });
        }

        items.push({
            title: this.gradingLabel(),
            visible: this.gradingAllowed(),
            template: () => {
                //the "if:isResult" is necessary to force a reload of the item presenter. result mode does not aggressivly reload the data, as items should are locked in an examination
                const x = $(`
            <div data-bind="if:isGrading">
                <div data-bind="component:{name: 'ui-widgets-advanceditempresenter', params:{ item: topItemDocRefId(),  mode: 'GRADING'}}" style="margin:10px"></div>
            </div>
            `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });

        items.push({
            title: this.resultLabel(),
            visible: true,
            template: () => {
                //the "if:isResult" is necessary to force a reload of the item presenter. result mode does not aggressivly reload the data, as items should are locked in an examination
                const x = $(`
                <div data-bind="if:isResult">
                    <div data-bind="component:{name: 'ui-widgets-advanceditempresenter', params:{ item: topItemDocRefId(), mode: 'RESULT'}}" style="margin:10px"></div>
                </div>
                `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        /*
        items.push({
            title: this.metadataLabel(),
            visible: true,
            template: () => {
                const x = $(`
                <div data-bind="if:isMetadata">
                <div data-bind="component:{name: '${METADATA.WIDGET_NAME}', params:{itemId:selectedItemId()} } "></div>
                </div>`);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        */

        items.push({

            title: i18next.t(['ui.author.itemedit.TRANSLATION']),
            visible: this.translationsAllowed(),
            template: () => {
                const x = $(`
                <div data-bind="if:isTranslation">
                <div data-bind="component:{name: '${TRANSLATION.WIDGET_NAME}', params:{itemId:selectedItemId(),localizations:limitLocalizations()} } "></div>
                </div>
                `);
                ko.applyBindings(this, x.get(0));
                return x;
            }
        });

        const item = items.find(x => ko.unwrap(x.visible));
        retVal.selectedItem = item;
        this.selectedLabel(item.title);
        return retVal;
    }

    private readonly translationsAllowed = ko.pureComputed(() => {
        const sel = this.selectedItem();
        if (!sel) {
            return false;
        }
        return Object.keys(sel.translateableByYou).some(x => sel.translateableByYou[x] !== '');
    });
    private readonly gradingAllowed = ko.pureComputed(() => {
        return false;
    });

    public async initialize() {
        await super.initialize();

        await this.OnRefresh();
        this.loaded(true);
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

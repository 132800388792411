import * as ko from 'knockout';
import { dir, error, log } from '../../debug';
import { DONE, DONEENUM, forceUIUpdate } from '../../helper';
import { ItemMode } from '../../model/interfaces';
import { toastService } from '../../toastService';
import { runQueue } from '../docmanager';
import * as i18next from './../../i18n/i18n';
import { LocalDonkeyConnection } from './LocalDonkeyConnection';

export type FocusTarget = 'item';
export type Focused = 'no' | 'entering' | 'focused' | 'leaving';

interface ITopItemFocus {
    //focusId: string;
    sessionId: string;
    itemId: string;
    itemType: string;
    mode: ItemMode;
}

export type WindowMode = 'normal' | 'compact';
export const CurrentWindowMode = ko.observable<WindowMode>();

export function compactWindowMode(bounds: { minWidth: number, minHeight: number }) {
    const minWidth = bounds.minWidth < 200 ? 200 : bounds.minWidth;
    const minHeight = bounds.minHeight < 100 ? 100 : bounds.minHeight;
    log(`Switching to compact window mode ${minWidth}x${minHeight}`);
    try {
        if (window.opener) {
            log(`Compact window mode - resizing current window (we are already a popup window)`);
            window.moveTo(0, 0);
            window.resizeTo(minWidth, minHeight);
        } else if (window.itsr3) {
            log(`Compact window mode - delegating to ITSR3 Client`);
            window.itsr3.setWindowMode('compact', minWidth, minHeight);
        } else {
            log(`Compact window mode - not available`);
        }
        CurrentWindowMode('compact');
    } catch (e) {
        error(`unable to set window to compact mode ${e.message}`);
    }
}
export function normalWindowMode() {
    log(`Switching to normal window mode`);
    try {
        if (window.opener) {
            log(`Normal window mode - resizing current window (we are already a popup window)`);
            window.moveTo(0, 0);
            window.resizeTo(1024, 768);
        } else if (window.itsr3) {
            log(`Normal window mode - delegating to ITSR3 Client`);
            window.itsr3.setWindowMode('normal');
        } else {
            log(`Normal window mode - not available`);
        }
        CurrentWindowMode('normal');
    } catch (e) {
        error(`unable to set window to normal mode ${e.message}`);
    }
}

export async function initITSR3(): Promise<DONEENUM> {
    if (typeof CefSharp !== 'undefined') {
        log('We are running in an CefSharp Browser. Get the ITSR3 object');
        if (!window.itsr3) {
            const r = await CefSharp.BindObjectAsync('itsr3');
            dir(r);
        }
    }
    return DONE;
}

class FocusManager {
    public readonly isFocused = ko.pureComputed(() => this.focused() !== 'no');
    public readonly focusTarget = ko.observable<FocusTarget>();
    public readonly focused = ko.observable<Focused>('no');

    public readonly topItemFocus = ko.observable<ITopItemFocus>();

    private donkeyConnection: LocalDonkeyConnection;

    public async disconnectDonkey() {
        log(`Disconnect from Donkey`);
        if (this.focused() !== 'no') {
            this.focused('leaving');
            try {
                await this.leaveTopItemFocus();
            } catch (e) {
                log(`exception when leaving focus mode: ${e.message}`);
                dir(e);
            }
        }
        normalWindowMode();
        this.focused('no');
        this.focusTarget(undefined);
        if (this.donkeyConnection) {
            await this.donkeyConnection.Disconnect();
        }
    }

    async connectToDonkey(sessionId: string) {
        if (!this.donkeyConnection) {
            log(`typeof itsr3.newDonkey: ${typeof window.itsr3.newDonkey}`);
            if (typeof window.itsr3.newDonkey !== 'undefined') {
                this.donkeyConnection = new LocalDonkeyConnection();
            } else {
                throw new Error(`You need a newer ITS Client (with local donkey connection)`);
            }
        }
        return this.donkeyConnection.connectToDonkey(sessionId);
    }

    async reset(sessionId: string, itemId: string) {
        if (this.focused() !== 'focused') {
            return;
        }
        if (this.donkeyConnection) {
            await this.donkeyConnection.reset(sessionId, itemId);
        }
    }
    async enterTopItemFocus({ sessionId, itemId, itemType, mode }: ITopItemFocus) {
        if (this.focused() !== 'no') {
            log(`Leaving focus first`);
            await this.leaveFocus();
        }
        this.focused('entering');
        ko.tasks.runEarly();
        log(`Entering focus on ${itemId}`);
        await forceUIUpdate();
        try {
            if (!await this.connectToDonkey(sessionId)) {
                this.focused('no');
                throw new Error('Unable to connect to donkey??');
            }
            await runQueue({ noRefresh: true });
            await this.donkeyConnection.setup({ sessionId, itemId });
            const bounds = this.donkeyConnection.getCompactModeBounds({ sessionId, itemId });
            this.focusTarget('item');
            log(`Setting top item focus to ${itemId}`);
            this.topItemFocus({
                //focusId,
                sessionId,
                itemId,
                itemType,
                mode,
            });
            compactWindowMode(bounds);
            this.focused('focused');
            log(`focused on ${itemId}`);
            await forceUIUpdate();
        } catch (err) {
            error(`Unable to enter focus mode: ${err.message}`);
            toastService.error(i18next.t(['ui.focusmanager.index.UNABLE_TO_ENTER_FOCUS_MODE']));
            //normalWindowMode();
            this.focused('no');
        }
    }

    private async leaveTopItemFocus() {
        const topItemFocus = this.topItemFocus();
        if (!topItemFocus) {
            return;
        }
        try {
            await runQueue({ noRefresh: true });
            const itemId = topItemFocus.itemId;
            const sessionId = topItemFocus.sessionId;
            await this.donkeyConnection.investigate({ itemId, sessionId });
            await this.donkeyConnection.cleanup({ itemId, sessionId });
        } catch (e) {
            const message = e && e.message || '';
            if (message.indexOf('[RELOAD]') >= 0) {
                log(`session is not active anymore, ignore the error`);
            } else {
                dir(e);
                throw e;
            }
        }
        this.topItemFocus(undefined);
    }

    async leaveFocus() {
        if (this.focused() === 'no') {
            return;
        }
        this.focused('leaving');
        try {
            await this.leaveTopItemFocus();
        } catch (e) {
            dir(e);
            toastService.error(i18next.t(['ui.focusmanager.index.UNABLE_TO_LEAVE_FOCUS_MODE']));
            return;
        }
        //normalWindowMode();
        this.focused('no');
        this.focusTarget(undefined);
    }

    /*
        nextFocusId = 0;
        newFocusId() {
            return `focus${this.nextFocusId++}`;
        }
        */
}

export const focusManager = new FocusManager();

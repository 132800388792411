import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-schooltypeedit';

export interface IParamsInner {
    audienceDocRefId: string;
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class AudienceEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return this._title;
    });

    constructor(readonly widgetParams: IParamsInner, readonly _title: string) {
    }

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.superuser.schooltypeedit.HELP']),
                action: this.showHelp
            }];
    });
    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.schooltypeedit.THE_SHORTCUT_IS_MANDATORY_AND_SHOULD_CONSIST_OF_CAPITAL_LETTERS_AND_DIGITS_ONLY_IF_YOU_WANT_TO_SEE_THE_FULL_NAME_OF_THE_SUBJECTS_GO_TO_SUBJECT']);

        toastService.info(msg);
    });
}




export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/superuser/audience/:id',
    toParams: map => ({
        audienceDocRefId: map.get('id'),
    }),
    fromParams: (p, map) => {
        map.set('id', p.audienceDocRefId);
    },
    create: async p => {
        const q = await ServerConnection.api.ui_superuser_audienceedit_route({
            docRef: p.audienceDocRefId
        });
        const route = new AudienceEditRoute(p, q.audiences.byId.audienceId);
        return route;
    },
});

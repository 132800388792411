
export type Compare<T> = (a: T, b: T) => -1 | 0 | 1;
export type GetValueAsString<T> = (a: T) => string;

export interface ITree<T> {
    readonly items: T[];
    readonly children: Array<ITree<T>>;
}

export function applyDirection<T>(comparer: Compare<T>, direction: number) {
    return (a: T, b: T) => {
        return comparer(a, b) * direction;
    };
}

function newStringComparer<T>(getValueAsString: GetValueAsString<T>) {
    const retVal: Compare<T> = (a, b) => {
        return stringCompare(getValueAsString(a), getValueAsString(b));
    };
    return retVal;
}
type BranchOrLeaf<T> = Branch<T> | Leaf<T>;

class Leaf<T> implements ITree<T> {
    public readonly items = new Array<T>();
    public readonly children = new Array<BranchOrLeaf<T>>();
    public firstRow: T;
    constructor(readonly options: IOptions<T>) {

    }

    public pushRow(row: T) {
        if (!this.items.length) {
            this.firstRow = row;
        }
        this.items.push(row);
    }
}

class Branch<T> implements ITree<T> {
    public readonly items = new Array<T>();
    public readonly children = new Array<BranchOrLeaf<T>>();
    public readonly isLastLevel: boolean;
    private readonly compare: Compare<T>;
    private readonly level: ILevelCreator<T>;
    public readonly name: string;
    public firstRow: T;

    constructor(private readonly options: IOptions<T>, readonly depth: number) {
        this.level = options.levels[depth];
        this.name = this.level.name;
        this.isLastLevel = depth === options.levels.length - 1;
        this.compare = getComparer(this.level);
    }

    public pushRow(row: T) {
        const prevRow = this.items.length && this.items[this.items.length - 1];
        if (!this.items.length) {
            this.firstRow = row;
        }
        this.items.push(row);
        const pushNewRow = 0 !== this.compare(prevRow, row);
        if (pushNewRow) {
            if (this.isLastLevel) {
                this.children.push(new Leaf<T>(this.options));
            } else {
                this.children.push(new Branch<T>(this.options, this.depth + 1));
            }
        }
        this.children[this.children.length - 1].pushRow(row);
    }
}

export interface ILevelCreator<T> {
    readonly name: string;
    readonly getValueAsString: (row: T) => string;
    readonly compare?: Compare<T>;
}
function getComparer<T>(level: ILevelCreator<T>) {
    if (level.compare) {
        return level.compare;
    }
    return newStringComparer(level.getValueAsString);
}

export interface IOptions<T> {
    levels: Array<ILevelCreator<T>>;
}
export function listToTree<T>(listData: T[], options: IOptions<T>) {
    const root = new Branch<T>(options, 0);
    const orderedData = Array.from(listData);
    orderedData.sort((a, b) => multiCompare(a, b, options.levels.map(getComparer)));

    for (const row of orderedData) {
        root.pushRow(row);
    }
    return root;
}
export function multiCompare<T>(a: T, b: T, comparer: Array<Compare<T>>) {
    for (const current of comparer) {
        const compResult = current(a, b);
        if (compResult < 0) {
            return -1;
        }
        if (compResult > 0) {
            return 1;
        }
    }
    return 0;
}

export function reverse(x: number) {
    if (x > 0) {
        return -1;
    }
    if (x < 0) {
        return 1;
    }
    return 0;
}
export function numberCompare(a: number, b: number) {
    if (!a && !b) {
        return 0;
    }
    const diff = a - b;
    if (diff < 0) {
        return -1;
    }
    if (diff > 0) {
        return 1;
    }
    return 0;
}

export function stringCompare(a: string, b: string) {
    if (!a && !b) {
        return 0;
    }
    if (!a && b) {
        return -1;
    }
    if (a && !b) {
        return 1;
    }
    const compResult = a.localeCompare(b);
    if (compResult < 0) {
        return -1;
    }
    if (compResult > 0) {
        return 1;
    }
    return 0;
}

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { sleep } from '../../helper';
import { focusManager } from '../../ui/focusmanager/index';
import { UIAction } from '../../ui/uiAction';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'widgets-enterfocusmodebutton';

export interface IParams {
    //    focusId: string;
    itemId: string;
    itemType: string;
    sessionId: string;
}

export class ViewModel extends AbstractWidget {
    constructor(readonly params: IParams) {
        super();
    }

    public readonly visible = ko.pureComputed(() => {
        const topItemFocus = focusManager.topItemFocus();
        if (!topItemFocus) {
            return true;
        }
        return false;
    });

    public readonly actionEnter = new UIAction(undefined, async () => {

        await focusManager.enterTopItemFocus({
            sessionId: this.params.sessionId,
            itemId: this.params.itemId,
            itemType: this.params.itemType,
            mode: 'INTERACTIVE'
        });
    });
    public readonly text = ko.pureComputed(() => {
        return i18next.t(['widgets.enterfocusmodebutton.ENTER_FOCUS_MODE']);
    });
    public readonly buttonOptions: DevExpress.ui.dxButton.Properties = {
        icon: 'far fa-edit',
        onClick: this.actionEnter.click,

    };



    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.params.itemId}`,
            action: async () => {
                await this.actionEnter.invoke();
                await sleep(5000);
                return true;
            }
        });
        await super.initialize();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

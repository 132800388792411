import * as ko from 'knockout';
import { AbstractWidget } from '../../../../AbstractWidget';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { KoMap } from '../../../../komap';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../../model/interfaces';
import * as RICHTEXTHTML from '../../../../richtext/html';
import { toastService } from '../../../../toastService';
import { legacyPushPull, queueApiCall } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as i18n from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['essay_grading_data']>>;

const WIDGET_NAME = 'itemdefinition-kosovo-essay-grading';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

export class GradingObjective {
    constructor(
        private readonly model: ViewModel,
        public readonly objectiveId: string
    ) {
    }

    private _score_on_server = 0;
    public update(src: Q['essayGrading']['get']['objectives'][0]) {
        this._score_on_server = src.score;
        this._score(src.score);
        this.maxScore(src.maxScore);
    }
    private readonly _score = ko.observable(0);

    public readonly maxScoreText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.essay.grading.MAXIMUM_SCORE']);
    });
    public readonly givenScoreText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.essay.grading.ACHIEVED_POINTS']);
    });

    public readonly score = ko.pureComputed({
        read: () => {
            return this._score();
        },
        write: newVal => {
            const v = +newVal;
            if (v >= 0 && v <= this.maxScore()) {
                this._score(v);
                if (this._score_on_server !== v) {
                    queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/${this.objectiveId}`, () => ServerConnection.api.essay_grading_setscore({
                        itemId: this.model.itemId,
                        objectiveId: this.objectiveId,
                        sessionId: this.model.sessionId,
                        score: v
                    }));
                }
            }
        }, owner: this
    });
    public readonly maxScore = ko.observable(0);

    public validateScore() {
        const maxScore = this.maxScore();
        const score = this.score();
        if (score < 0 || score > maxScore) {
            return false;
        }
        return true;
    }
}

export class ViewModel extends AbstractWidget {
    public itemId: string;
    public sessionId: string;
    public readonly loaded = ko.observable(false);
    public readonly mode = ko.observable<ItemMode>('GRADING');

    public readonly submitGradingButtonLabel = i18n.t(['itemdefinition.kosovo.essay.grading.SUBMIT']);

    public readonly toggleGradingReasonButtonLabel = i18n.t(['itemdefinition.kosovo.essay.grading.TOGGLE_REASON_FOR_EVALUATION']);

    public readonly toggleInfoButtonLabel = i18n.t(['itemdefinition.kosovo.essay.grading.TOGGLE_INFO_OF_AUTHOR']);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
    }


    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.essay_grading_data({
            itemId: this.itemId,
            sessionId: this.sessionId,
        });
        const d = q.essayGrading.get;

        this.text(d.essayText);

        this.questionHTML(RICHTEXTHTML.process({
            html: d.question.value,
            attachments: d.attachments,
        }));
        this._headerText(d.header.value);
        this.gradingReason(d.reason);
        this.evaluationInfo(d.evalInfo.value);

        for (const g of d.objectives) {
            if (!this._objectives.has(g.objectiveId)) {
                this._objectives.set(g.objectiveId, new GradingObjective(this, g.objectiveId));
            }
            const obj = this._objectives.get(g.objectiveId);
            obj.update(g);
        }
    }
    public async initialize() {
        await super.initialize();

        await this.OnRefresh();
        this.onChange(this.gradingReason, `${WIDGET_NAME}/${this.itemId}/reason`, async val => {
            await ServerConnection.api.essay_grading_setreason({
                itemId: this.itemId,
                sessionId: this.sessionId,
                reason: val
            });
            return DONE;
        });
        this.loaded(true);
    }

    public readonly text = ko.observable('');

    public readonly questionHTML = ko.observable('');
    public readonly _headerText = ko.observable('');
    public readonly headerText = ko.pureComputed(() => {
        return this._headerText();
    });
    public readonly wordCountText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.essay.grading.WORDS']);
    });


    public readonly wordCount = ko.pureComputed(() => {
        const text = this.text();
        if (!text) {
            return 0;
        }
        return text.trim().split(/\s+/).length;
    });

    public readonly evaluationInfo = ko.observable('');

    public readonly showInfo = ko.observable(false);
    public readonly showReason = ko.observable(false);
    public readonly toggleInfo = new UIAction(undefined, async () => {
        this.showReason(false);
        this.showInfo(!this.showInfo());
    });
    public readonly toggleReason = new UIAction(undefined, async () => {
        this.showInfo(false);
        this.showReason(!this.showReason());
    });
    public readonly gradingReason = ko.observable('');
    public readonly submitGradingAction = new UIAction(undefined, async () => {
        const message = i18n.t(['itemdefinition.kosovo.essay.grading.ARE_YOU_SURE_THAT_YOU_ARE_DONE_WITH_GRADING_BR_AFTER_SUBMITTING_THE_EVALUATION_NO_MORE_CHANGES_ARE_POSSIBLE']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }
        await this.submit();
    });
    private async submit() {
        if (await this.validate()) {
            await legacyPushPull(() => ServerConnection.api.essaygrading_submit({
                itemId: this.itemId,
                sessionId: this.sessionId
            }));
        }
    }

    private async validate() {
        const scoreInvalid = this.gradingObjectives().some(val => !val.validateScore());
        if (scoreInvalid) {
            toastService.error(i18n.t(['itemdefinition.kosovo.essay.grading.THE_GIVEN_SCORE_IS_INVALID']));
            return false;
        }
        return true;
    }
    private readonly _objectives = new KoMap<GradingObjective>();

    public readonly gradingObjectives = ko.pureComputed(() => {
        return this._objectives.keys().sort().map(x => this._objectives.get(x));
    });


}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

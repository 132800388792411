import type DevExpress from 'devextreme/bundles/dx.all';
import STABLE_STRINGIFY from 'json-stable-stringify';
import * as ko from 'knockout';
import { writeToClipboard } from '../../../clipboard';
import { log } from '../../../debug';
import { AS } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { ServerConnection } from '../../../ui/RestAPI';
import { registerOnUpdateToolbar } from '../../../ui/toptoolbar.service';
import { UIAction } from '../../../ui/uiAction';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'itemdefinition-instruction-edit';

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly instruction = ko.observable('');
    public readonly header = ko.observable('');

    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly loaded = ko.observable(false);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
    }


    private readonly upsertCode = ko.pureComputed(() => {
        const retVal: API.IInstructionEditUpsertInput = {
            itemId: this.itemId,
            header: { xnone: this.header() },
            instruction: { xnone: this.instruction() },
        };
        return retVal;
    });

    public readonly actionCopyUpsert = new UIAction(undefined, async (e, args) => {
        await writeToClipboard(this.upsertCodeJson());
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.instruction_edit_data({
            itemId: this.itemId
        });

        const d = data?.InstructionEdit?.get;
        if (!d) {
            return;
        }
        this.instruction(d.instruction.value);
        this.header(d.header.value);
        this.lastSaved(this.upsertCodeJson());

    }

    public readonly upsertCodeJson = ko.pureComputed(() => {
        const r = this.upsertCode();
        return STABLE_STRINGIFY(r, { space: 2 });
    });

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItem>({
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'far fa-copy',
                text: i18n.t(['itemdefinition.multistephotspot.edit.COPY_DEFINITION_TO_CLIPBOARD']),
                onClick: this.actionCopyUpsert.click
            })
        }));
        return DONE;
    }
    private readonly lastSaved = ko.observable('');
    public readonly needsSave = ko.pureComputed(() => {
        return this.lastSaved() !== this.upsertCodeJson();
    });

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                instruction: this.instruction,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.multistephotspot.edit.HEADER'])
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.multistephotspot.edit.ENTER_THE_HEADER_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.multistephotspot.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'instruction',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.multistephotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']),
        }));
        return retVal;
    });

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.onChange(this.upsertCode, `${WIDGET_NAME}/${this.itemId}/upsert`, async v => {
            if (this.needsSave()) {
                log(`save: ${v}`);
                await ServerConnection.api.instruction_edit_update({
                    params: v
                });
            }
            return DONE;
        });

        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

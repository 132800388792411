﻿
export function xnone(data: string) {
    if (typeof data !== 'string') {
        return undefined;
    }
    return {
        'xnone': data
    };
}




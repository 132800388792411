import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-bulkgrading-selectitem';

export type IParamsInner = {
    subjectDocRefId: string
};
export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export class SelectItemRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.bulkgrading.selectitem.ITEMS']);
    });
    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{ subject: string }>({
    route: '/bulkgrading/subject/:subject',
    toParams: map => ({
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('subject', p.subject);
    },
    create: async p => {
        const route = new SelectItemRoute(
            {
                subjectDocRefId: p.subject,
            }
        );
        return route;
    },
});

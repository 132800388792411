import * as i18next from './i18n/i18n';
import { toastService } from './toastService';


export async function writeToClipboard(text: string) {
    if (!text) {
        return;
    }
    const n: any = navigator;
    if (n && n.clipboard && n.clipboard.writeText) {
        n.clipboard.writeText(text);
        toastService.info(i18next.t(['ui.superuser.examinationoverview.COPIED']));
    } else {
        toastService.error(i18next.t(['ui.superuser.examinationoverview.YOUR_BROWSER_DOES_NOT_SUPPORT_THE_CLIPBOARD_API']));
        const win = window.open();
        if (win) {
            win.document.write('<iframe src="data:text/plain;base64,' + window.btoa(text) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        }
    }

}
import { log } from './debug';
import { multiCompare, numberCompare } from './tree';

export function golemMatch<TKey>(data: Array<{ key: TKey, value: string }>, match: string): TKey {
    if (!match) {
        return undefined;
    }
    const prepare = data.map(it => {
        const normalized = (it.value + ' ').replace(/\s+/g, ' ');
        return {
            key: it.key,
            value: it.value,
            idx: normalized.indexOf(match + ' '),
            len: it.value.length,
        };
    });
    const sorted = prepare
        .sort((a, b) => multiCompare(a, b, [
            (a, b) => numberCompare(a.idx, b.idx),
            (a, b) => numberCompare(a.len, b.len)]));
    log(`Golem: Looking for '${match}' in ${JSON.stringify(sorted)}`);

    const matching = sorted.filter(it => it.idx !== -1);
    log(`Golem: matching '${match}' in ${JSON.stringify(matching)}`);
    if (matching.length == 0) {
        log(`Golem: no match found`);
        return undefined;
    }
    log(`Return key for '${matching[0].value}'`);
    return matching[0].key;
}

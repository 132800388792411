import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { assertNever, encodeHtml, formatSpaces } from '../../helper';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'widgets-serializedresultviewer';

export interface IParams {
    raw: ko.Subscribable<string>
}



interface SerializedResultInt {
    _: 'i';
    i?: number;
    err?: string;
}
interface SerializedResultBoolean {
    _: 'b';
    b?: boolean;
    err?: string;
}
interface SerializedResultDouble {
    _: 'n';
    n?: number;
    err?: string;
}

interface SerializedResultString {
    _: 's';
    s?: string;
    err?: string;
}

interface SerializedResultStringArray {
    _: 'ss';
    ss?: string[];
    err?: string;
}
interface SerializedResultIntArray {
    _: 'is';
    is?: number[];
    err?: string;
}
interface SerializedResultDoubleArray {
    _: 'ns';
    ns?: number[];
    err?: string;
}
interface SerializedResultEnumArray {
    _: 'es';
    es?: string[];
    err?: string;
}

interface SerializedResultStringEnum {
    _: 'e';
    e?: string;
    err?: string;
}

type SerializedResultValue = SerializedResultInt |
    SerializedResultString |
    SerializedResultDouble |
    SerializedResultBoolean |
    SerializedResultStringEnum;

type SerializedResult = SerializedResultInt |
    SerializedResultString |
    SerializedResultDouble |
    SerializedResultBoolean |
    SerializedResultStringArray |
    SerializedResultDoubleArray |
    SerializedResultEnumArray |
    SerializedResultStringEnum |
    SerializedResultIntArray;

function deserialize(serializedResult: string) {
    const v = JSON.parse(serializedResult) as SerializedResult;
    return v;
}


class Item {
    constructor(readonly vm: { regEx: ko.Computed<RegExp> }, readonly index: number, readonly val: SerializedResultValue) {

    }
    public readonly error = ko.pureComputed(() => {
        if (this.val.err) {
            return this.val.err;
        }
        return undefined;
    });

    private displayHtml(s: string): string {
        const regEx = this.vm.regEx();
        if (regEx) {
            s = s.replace(regEx, (match) => {
                return 'C23AECA0F3E611E9AAEF0800200C9A66' + match + 'D15BE900F3E611E9AAEF0800200C9A66';
            });
        }
        s = formatSpaces(encodeHtml(s));
        s = s.replace(/C23AECA0F3E611E9AAEF0800200C9A66/g, '<span class=match>')
            .replace(/D15BE900F3E611E9AAEF0800200C9A66/g, '</span>');
        return s;
    }
    public readonly formattedValue = ko.pureComputed(() => {
        if (this.val.err) {
            return undefined;
        }
        switch (this.val._) {
            case 'b':
                return this.val.b ? i18next.t(['widgets.serializedresultviewer.TRUE']) : i18next.t(['widgets.serializedresultviewer.FALSE']);
            case 'n':
                return `${this.val.n}`;
            case 'i':
                return `${this.val.i}`;
            case 's':
                return this.displayHtml(this.val.s);
            case 'e':
                return this.displayHtml(this.val.e);

            default:
                assertNever(this.val);
                return undefined;
        }
    });
}

export class ViewModel extends AbstractWidget {
    public readonly raw = ko.observable('');
    public readonly regexStr = ko.observable('');

    constructor(readonly params: IParams) {
        super();
        this.raw(params.raw() || '');
    }

    parseRegEx() {
        const value = this.regexStr();
        if (!value) {
            return undefined;
        }
        let regExpStr: string = undefined;
        let regExpOpt: string = undefined;
        if (value.startsWith('/') && value.endsWith('/i')) {
            regExpStr = value.substr(1, value.length - 3);
            regExpOpt = 'i';
        } else if (value.startsWith('/') && value.endsWith('/')) {
            regExpStr = value.substr(1, value.length - 2);
            regExpOpt = '';
        } else {
            throw new Error(`Invalid regular expression '${value}'`);
        }
        if (!regExpStr) {
            throw new Error(`Invalid regular expression '${value}'`);
        }
        return new RegExp(regExpStr, regExpOpt);
    }

    public readonly regEx = ko.pureComputed(() => {
        try {
            const x = this.parseRegEx();
            return x;
        } catch (e) {
            return undefined;
        }
    });

    public readonly regexError = ko.pureComputed(() => {
        try {
            const x = this.parseRegEx();
        } catch (e) {
            return e.message || e.toString();
        }
    });

    public readonly regExLabel = ko.pureComputed(() => {
        return i18next.t(['widgets.serializedresultviewer.REGULAR_EXPRESSION']);
    });
    public readonly rawValueLabel = ko.pureComputed(() => {
        return i18next.t(['widgets.serializedresultviewer.RAW_VALUE']);
    });

    public regexOptions(): DevExpress.ui.dxTextBox.Properties {
        const retVal: DevExpress.ui.dxTextBox.Properties = {
            value: <any>this.regexStr,
            valueChangeEvent: 'keyup',
        };
        return retVal;
    }
    public rawOptions(): DevExpress.ui.dxTextArea.Properties {
        const retVal: DevExpress.ui.dxTextArea.Properties = {
            value: <any>this.raw,
            valueChangeEvent: 'keyup',
            autoResizeEnabled: true,
            onValueChanged: (e) => {
                const v = e.value;
                const cur = this.params.raw();
                if (cur !== v) {
                    if (!v) {
                        this.params.raw(v);
                    }
                    try {
                        deserialize(v);
                        this.params.raw(v);
                    } catch {

                    }
                }
            }
        };
        return retVal;
    }


    public readonly error = ko.pureComputed(() => {
        const raw = this.raw();
        try {
            deserialize(raw);
            return undefined;
        } catch (e) {
            return e.message || e.toString();
        }
    });

    private itemsValues(): SerializedResultValue[] {
        const x = this.serializedResult();
        if (!x) {
            return [];
        }
        switch (x._) {
            case 'b':
            case 's':
            case 'i':
            case 'e':
            case 'n':
                return [x];
            case 'is':
                return (x.is || []).map(i => ({ _: 'i', i }));

            case 'ss':
                return (x.ss || []).map(s => ({ _: 's', s }));

            case 'ns':
                return (x.ns || []).map(n => ({ _: 'n', n }));

            case 'es':
                return (x.es || []).map(e => ({ _: 'e', e }));
            default:
                assertNever(x);
                return [];
        }
    }
    public readonly items = ko.pureComputed(() => {
        return this.itemsValues().map((x, idx) => new Item(this, idx + 1, x));
    });

    public readonly type = ko.pureComputed(() => {
        const x = this.serializedResult();
        if (!x) {
            return undefined;
        }
        switch (x._) {
            case 'b':
                return i18next.t(['widgets.serializedresultviewer.BOOLEAN']);
            case 'e':
                return i18next.t(['widgets.serializedresultviewer.ENUM']);
            case 'es':
                return i18next.t(['widgets.serializedresultviewer.ENUM_ARRAY']);
            case 'i':
                return i18next.t(['widgets.serializedresultviewer.INTEGER']);
            case 'is':
                return i18next.t(['widgets.serializedresultviewer.INTEGER_ARRAY']);
            case 'n':
                return i18next.t(['widgets.serializedresultviewer.NUMBER']);
            case 'ns':
                return i18next.t(['widgets.serializedresultviewer.NUMBER_ARRAY']);
            case 's':
                return i18next.t(['widgets.serializedresultviewer.STRING']);
            case 'ss':
                return i18next.t(['widgets.serializedresultviewer.STRING_ARRAY']);
            default:
                assertNever(x);
                return '?';
        }
    });

    private readonly serializedResult = ko.pureComputed(() => {
        const raw = this.raw();
        try {
            return deserialize(raw);
        } catch (e) {
            return undefined;
        }
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

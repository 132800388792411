import CustomStore from 'devextreme/data/custom_store';
import { dir, error, log } from '../../../../debug';
import { ServerConnection } from '../../../RestAPI';

interface IEntry {
    objectiveId: string;
    objectiveScore: number;
}
export class ObjectiveStore extends CustomStore {
    constructor(readonly itemId: string) {
        super({
            loadMode: 'raw',
            key: 'objectiveId',
            byKey: async (key) => {
                return this._items.find(x => x.objectiveId === key);
            },
            load: async () => {
                const data = await ServerConnection.api.ui_author_itemedit_itemmetadatablock_objectives({
                    itemId: this.itemId
                });
                const itemData = data.item.get;
                this._items.splice(0, this._items.length);
                for (const obj of itemData.metaData.objectives) {
                    this._items.push({
                        objectiveId: obj.objectiveId,
                        objectiveScore: obj.scoreMax,
                    });
                }
                dir({ 'objective store items': this._items });
                return this._items;

            },
            update: async (key: string, values: Partial<IEntry>) => {
                log(`Update ObjectiveStore key: ${key}, values: ${JSON.stringify(values)}`);
                const entry = this._items.find(x => x.objectiveId === key);
                if (values.objectiveId) {
                    entry.objectiveId = values.objectiveId;
                }
                if (values.objectiveScore) {
                    entry.objectiveScore = values.objectiveScore;
                }
                await this.sendUpdate();
            },
            remove: async (key: string) => {
                log(`Remove ObjectiveStore key: ${key}`);
                const entry = this._items.findIndex(x => x.objectiveId === key);
                if (entry !== -1) {
                    this._items.splice(entry, 1);
                }
                await this.sendUpdate();
            },
            insert: async (values: Partial<IEntry>) => {
                log(`Insert ObjectiveStore: ${values}`);
                if (!values.objectiveId) {
                    error(`No objective id`);
                    return;
                }
                const idx = this._items.findIndex(x => x.objectiveId === values.objectiveId);
                if (idx !== -1) {
                    error(`Already in list`);
                    return;
                }
                this._items.push({
                    objectiveId: values.objectiveId,
                    objectiveScore: values.objectiveScore || 0,
                });
                await this.sendUpdate();
            },
        });
    }
    private async sendUpdate() {
        await ServerConnection.api.ui_author_itemedit_itemmetadatablock_update({
            params: {
                itemId: this.itemId,
                setObjectives: this._items.map(x => ({
                    objectiveId: x.objectiveId,
                    score: x.objectiveScore,
                }))
            }
        });
    }
    public getDisplay(key: string) {
        const obj = this._items.find(x => x.objectiveId === key);
        if (!obj) {
            return '?';
        }
        return obj.objectiveId;
    }
    private readonly _items: IEntry[] = [];
}

import * as ko from 'knockout';
import { formatNumber } from '../../resultscreens/default/formatNumber';
import type { ItemFieldPartTemplate } from './ItemFieldPartTemplate';
import type { ItemOverviewBodyField } from "./ItemOverviewBodyField";

export class ItemFieldPart {
    constructor(readonly templatePart: ItemFieldPartTemplate, readonly field: ItemOverviewBodyField) {
    }
    public readonly cssClass = ko.pureComputed(() => {
        if (this.templatePart.fieldType == '') {
            return '';
        }
        return `FieldType_${this.templatePart.fieldType}`;
    });

    public readonly isScore = ko.pureComputed(() => this.templatePart.fieldType === 'score');
    public readonly isValue = ko.pureComputed(() => !this.isScore());
    public readonly value = ko.pureComputed(() => {
        const p = this.field.item.params;
        const score = p.accumulatedScore;
        switch (this.templatePart.fieldType) {
            case '':
                return this.templatePart.text;
            case 'id':
                return p.itemId || '';
            case 'index':
                return '' + p.index;
            case 'title':
                return '' + p.title.value;
            case 'pointsPercent':
                return formatNumber(score && score.pointsPercentage);
            case 'pointsGained':
                return formatNumber(score && score.pointsGained);
            case 'pointsPending':
                return formatNumber(score && score.pointsPending);
            case 'pointsLost':
                return formatNumber(score && score.pointsLost);
            case 'pointsMax':
                return formatNumber(score && score.pointsMax);
            default:
                return `{${this.templatePart.fieldType}}`;
        }
    });

    public readonly score = ko.pureComputed(() => {
        const score = this.field.item.params.accumulatedScore;
        return score;
    });
}

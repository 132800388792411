import type DevExpress from 'devextreme/bundles/dx.all';
import { IDisposable } from 'xterm';
import { DONE, DONEENUM } from '../helper';
import * as ko from 'knockout';

export type Callback = (options: DevExpress.ui.dxToolbar.Properties) => DONEENUM;

interface IListenerInternal {
    cb: Callback
}

export function updateToolbar() {
    toolbarChanged(toolbarChanged() + 1);
}

const toolbarChanged = ko.observable(0);
const toolbarListener = new Map<string, IListenerInternal>();

export function prepareToolbar(options: DevExpress.ui.dxToolbar.Properties): DONEENUM {
    //if this function is called in an computed, that computed will get reevaluated when the toolbar items change 
    {
        const dummy = toolbarChanged();
        dummy;
    }

    for (const { cb } of Array.from(toolbarListener.values())) {
        cb(options);
    }
    return DONE;
}
let counter = 0;
export function registerOnUpdateToolbar(cb: Callback): IDisposable {
    counter++;
    const id = `${counter}`;
    toolbarListener.set(id, { cb });
    updateToolbar();
    return {
        dispose() {
            toolbarListener.delete(id);
            updateToolbar();
        }
    };

}
import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { error } from '../../../debug';
import { extractEndUserError } from '../../../helper';
import { itemDefinitionFactoryProvider } from '../../../itemdefinition/factoryProvider';
import { ClearSession, GetSession, HasSession, InitSession } from '../../../itemdefinition/model/session';
import { EDIT_SESSION } from '../../../magic';
import { ItemMode } from '../../../model/interfaces';
import { runQueue } from '../../docmanager';
import { focusManager } from '../../focusmanager';
import { ServerConnection } from '../../RestAPI';
import * as IP from '../itempresenter/widget';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'ui-widgets-advanceditempresenter';
let IDX = 0;

export interface IParams {
    readonly item: string;
    readonly mode: ItemMode;
    readonly localizationId?: string;
}

export class ViewModel extends AbstractWidget {
    private readonly id = `${WIDGET_NAME}#${IDX++}`;

    public readonly itemDocRefId: string;
    public readonly mode: ItemMode;
    public readonly sessionId = EDIT_SESSION;
    public readonly localizationId: string;
    //public readonly focusId: string;

    public readonly showItem = ko.pureComputed(() => !!this.data());
    public readonly msg = ko.observable('');
    public readonly data = ko.observable<IP.IParams>();

    constructor(readonly params: IParams) {
        super();

        this.mode = params.mode || 'INTERACTIVE';
        this.itemDocRefId = params.item;
        this.localizationId = params.localizationId || '';
    }

    public dispose() {
        super.dispose();
    }
    public async initialize() {
        await super.initialize();
        if (!this.itemDocRefId) {
            error(`${this.id}: parameter 'item' was not set`);
            return;
        }
        await runQueue({ noRefresh: true });
        if (this.mode === 'INTERACTIVE') {
            await focusManager.disconnectDonkey();
        }
        if (HasSession(this.sessionId)) {
            const result = await ServerConnection.api.ui_advanceditempresenter_ticktock({
                session: this.sessionId,
                timePassedMS: 0,
                updateExamState: GetSession(this.sessionId).GetExamState(),
            });
        }
        ClearSession();
        try {
            let initSession = true;
            let params: IP.IParams | undefined = undefined;
            if (this.mode === 'EDIT' || this.mode === 'GRADING' || this.mode == 'INSPECT') {
                const result = await ServerConnection.api.ui_advanceditempresenter_item({
                    itemDocRef: this.itemDocRefId,
                });
                if (!result.item.get) {
                    this.msg(`Unable to load item ${this.itemDocRefId}`);
                    this.data(undefined);
                    return;
                }
                params = {
                    mode: this.mode,
                    isTopItem: result.item.get.isTopItem,
                    sessionId: this.sessionId,
                    itemType: result.item.get.itemType.id,
                    item: this.itemDocRefId,
                    localizationId: this.localizationId,
                };
                if (this.mode == 'GRADING') {
                    if (itemDefinitionFactoryProvider.getFactory(result.item.get.itemType.id).grading == 'BULK') {
                        initSession = false;
                    }
                } else {
                    initSession = false;
                }
            }
            if (initSession) {
                const result = await ServerConnection.api.ui_advanceditempresenter_additem(
                    {
                        sessionDocRef: this.sessionId,
                        itemDocRef: this.itemDocRefId,
                    });
                if (!result.examSession.setSessionItems) {
                    this.msg(`Unable to load item ${this.itemDocRefId}`);
                    this.data(undefined);
                    return;
                }
                await InitSession(this.sessionId, [this.localizationId]);
                params = {
                    mode: this.mode,
                    isTopItem: result.examSession.setSessionItems[0].isTopItem,
                    sessionId: this.sessionId,
                    itemType: result.examSession.setSessionItems[0].itemType,
                    item: this.itemDocRefId,
                    localizationId: this.localizationId,
                };
            }
            this.data(params);
        } catch (e) {
            const msg = extractEndUserError(e.message);
            this.msg(msg && msg.message || e.message);
            this.data(undefined);
            return;
        }
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export const WIDGET_NAME = 'ui-superuser-launchtest';
export class LaunchTestRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.launchtest.LAUNCH_TEST']);
    });
    constructor(readonly widgetParams: IParamsInner) {
    }
}


export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/launchtest',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return new LaunchTestRoute(undefined);
    },
});

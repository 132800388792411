import * as ko from 'knockout';
import { log } from '../../../debug';
import { getGolemData, golemMatch } from '../../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry } from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { SingleChoiceImageData, SingleChoiceImageData_Answer } from '../../model/singlechoiceimage/SingleChoiceImageData';
import { htmlString } from './singlechoiceimage.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-singlechoiceimage';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

class VMAnswer {
    constructor(private readonly model: MyModel, public readonly answer: SingleChoiceImageData_Answer) {
        this.width = model.data.imgWidth;
        this.height = model.data.imgHeight;
    }
    public readonly isCorrectAnswer = ko.pureComputed(() => this.answer.isCorrectAnswer());
    public readonly id = ko.pureComputed(() => this.answer.id);

    public readonly imgUrl = ko.pureComputed(() => this.answer.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.answer.imgAlt);

    public readonly isSelected = ko.pureComputed(() => this.answer.isSelected());

    public readonly width: number;
    public readonly height: number;

    public readonly select = new UIAction(undefined, async () => {
        await this.model.clickAnswer(this.id());
    });
}
export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SingleChoiceImageData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: SingleChoiceImageData;

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        const sa = this.shuffledAnswers();
        if (sa.some(x => x.answer.isSelected())) {
            return false;
        }
        const golemData = getGolemData(this.itemId);
        let pickedId: string;
        if (typeof golemData === 'string') {
            pickedId = golemMatch(sa.map(x => ({
                key: x.answer.id,
                value: this.data.meta.getAttachment(x.answer.rawData.sciImage?.id)?.name,
            })), golemData);

        }
        if (!pickedId) {
            log(`Golem has no suggestion for ${this.itemId}. Pick a random one.`);
            pickedId = getRandomEntry(sa).answer.id;
        }
        const pick = sa.find(x => x.answer.id === pickedId);
        await pick.select.invoke(undefined, true);
        return true;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly shuffledAnswers = ko.pureComputed(() => {
        return this.data.answers.map(x => new VMAnswer(this, x));
    });
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public async clickAnswer(answerId: string) {
        await this.data.setAnswer(answerId);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

export const id = 'surveycomparision';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'b8a8e96ac-6f5b-42b6-b2d0-61b39ac58522',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});
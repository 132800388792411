import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import * as API from '../../../../its-itembank-api.g';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, datagrid, selectBoxDS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import * as i18next from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';


const WIDGET_NAME = 'itemdefinition-kosovo-singlechoicetext-edit';

export type IParams = IItemDefinitionWidgetParams;
type Q = Awaited<ReturnType<API.Sdk['singlechoicetext_edit_data']>>;
type ATTACHMENT = Q['documents']['get']['attachments'][0];
type ANSWER = Q['SingleChoiceTextEdit']['get']['answers'][0];

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly loaded = ko.observable(false);
    public readonly image = ko.observable('');
    private readonly _attachments: ATTACHMENT[] = [];

    private readonly _answers: ANSWER[] = [];

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                image: this.image,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'image',
            editorType: 'dxSelectBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.IMAGE']),
            },
            editorOptions: this.imageOptions(),
        }));

        return retVal;
    });

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'index',
                        data: this._answers
                    }
                },
                editing: {
                    allowAdding: this.canEdit,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
            }
        });
        retVal.columns.push(
            {
                dataField: 'index',
                caption: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.ID']),
                width: 40,
                allowEditing: false
            });
        retVal.columns.push(
            {
                dataField: 'correct',
                caption: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.CORRECT']),
                allowEditing: true,
                dataType: 'boolean',
                width: 80,
            });

        retVal.columns.push(
            {
                dataField: 'text.value',
                caption: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.TEXT']),
                allowEditing: true,
                dataType: 'string',
            });

        retVal.onInitNewRow = e => {
            let index = '';
            for (let i = 0; i < 26; ++i) {
                index = String.fromCharCode(65 + i);
                if (!this._answers.some(x => x.index === index)) {
                    break;
                }
            }
            if (!index) {
                index = 'A';
            }
            e.data = {
                index,
                correct: false,
                text: {
                    value: ''
                }
            };
        };

        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.singlechoicetext_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeAnsers: [e.key]
                    }
                }));
            })();
        };

        retVal.onRowInserting = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.singlechoicetext_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.data.index,
                            text: xnone(e.data.text && e.data.text.value),
                            correct: e.data.correct
                        }]
                    }
                }));
            })();
        };
        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.singlechoicetext_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.key,
                            text: xnone(e.newData.text && e.newData.text.value),
                            correct: e.newData.correct
                        }]
                    }
                }));
            })();
        };
        return retVal;
    });

    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                shuffleMode: this.shuffleMode,
            },
            items: [],
        };

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'shuffleMode',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.MODE_FOR_ANSWER_OPTIONS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.SingleChoiceTextEdit_Shuffle.Shuffled,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.SHUFFLED'])
                },
                {
                    key: API.SingleChoiceTextEdit_Shuffle.Ordered,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.FIXED'])
                },
                {
                    key: API.SingleChoiceTextEdit_Shuffle.ShuffledExceptLast,
                    value: i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.LAST_LINE_FIXED'])
                },
            ]),
        }));

        return retVal;
    });


    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();



        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async newVal => {
            await ServerConnection.api.singlechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(newVal)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async newVal => {
            await ServerConnection.api.singlechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(newVal)
                }
            });
            return DONE;
        });
        this.onChange(this.image, `${WIDGET_NAME}/${this.itemId}/image`, async newVal => {
            await ServerConnection.api.singlechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    image: newVal || ''
                }
            });
            return DONE;
        });
        this.onChange(this.shuffleMode, `${WIDGET_NAME}/${this.itemId}/shuffleMode`, async newVal => {
            await ServerConnection.api.singlechoicetext_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleMode: newVal
                }
            });
            return DONE;
        });
        this.loaded(true);
    }

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.singlechoicetext_edit_data({
            itemId: this.itemId
        });
        const d = q.SingleChoiceTextEdit.get;

        this.question(d.question.value);
        this.header(d.header.value);
        this.image(d.image && d.image.name);
        this.imgUrl(ServerConnection.getDataUrl(d.image && d.image.hrefResolved));
        this.shuffleMode(d.shuffleMode);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        this._answers.splice(0, this._answers.length, ...d.answers);
    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly header = ko.observable('');
    public readonly question = ko.observable('');
    public readonly imgUrl = ko.observable('');
    public readonly shuffleMode = ko.observable<API.SingleChoiceTextEdit_Shuffle>();

    public readonly imageOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxSelectBox.Properties = {
            value: this.image,
            dataSource: this._attachments,
            valueExpr: 'name',
            displayExpr: 'name',
            showClearButton: true,
        };
        return retVal;
    });

    public readonly answerPossibilityPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicetext.edit.ENTER_AN_ANSWER_OPTION_HERE_IF_THIS_IS_THE_CORRECT_ANSWER_CLICK_ON_THE_CIRCLE']);
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

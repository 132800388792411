import * as ko from 'knockout';
import { dir, error, log } from '../debug';
import { UIAction } from './uiAction';


export type ANYROUTE = IRoute<{}>;

export interface IRoutableParams<T> {
    readonly currentRoute: IRoute<T>;
}


export interface IRouteAction {
    readonly caption: ko.MaybeSubscribable<string>;
    readonly icon: ko.MaybeSubscribable<string>;
    readonly action: UIAction<undefined>;
}

export interface IRoute<TParams> {
    //readonly template: string;
    readonly widgetName: string;
    readonly widgetParams: TParams;

    readonly languageSwitcher?: boolean;
    readonly routeActions?: ko.MaybeSubscribable<IRouteAction[]>;
    readonly title: ko.Subscribable<string>;
    readonly topTitle?: ko.MaybeSubscribable<string>

    route?: NewRoute<TParams>;
    path?: string;
    parentPaths?: string[];
}

class Routes {
    public readonly current = ko.observable<ANYROUTE>();

    public async navigateToHREF(path: string, replace?: boolean) {
        const r = matchRoute(path);
        if (!r) {
            error(`No route matches ${path}`);
            throw new Error(`Invalid route: unmatched`);
        }
        enableNavigation();
        const url = new URL(initialHref);
        url.hash = path;
        if (replace) {
            history.replaceState({ path }, document.title, url.toString());
        } else {
            history.pushState({ path }, document.title, url.toString());
        }
        await asyncHashChange(path);
    }
    public async navigateTo(newTarget: ANYROUTE) {
        this.current(newTarget);
    }
    public async back() {
        history.back();
    }
}
export const routeManager = new Routes();

function splitParts(x: string): string[] {
    if (!x) {
        return [];
    }
    return x.split('/').map(x => decodeURIComponent(x));
}

class NewRoute<T> {
    public readonly id: string;
    private readonly template: string[];
    private readonly templateParts: string[];
    constructor(readonly info: IRouteInfo<T>) {
        this.template = splitParts(info.route);
        this.templateParts = this.template.map(x => x.startsWith(':') ? '*' : x);
        this.id = this.templateParts.join('/');
    }
    public matches(route: string[]) {
        const def = this.template;
        const retVal = new Map<string, string>();
        if (route.length != def.length) {
            return undefined;
        }
        for (let i = 0; i < def.length; ++i) {
            if (def[i].startsWith(':')) {
                retVal.set(def[i].substr(1), route[i]);
            } else {
                if (def[i] !== route[i]) {
                    return undefined;
                }
            }
        }
        return retVal;
    }
    public href(params: T) {
        const map = new Map<string, string>();
        this.info.fromParams(params, map);
        return this.hrefFromMap(map);
    }
    private buildHref(map: Map<string, string>, template: string[]) {
        const parts = [];
        for (const p of template) {
            if (p.startsWith(':')) {
                const pName = p.substr(1);
                if (!map.has(pName)) {
                    throw new Error(`Parameter '${pName}' missing in hrefFromMap`);
                }
                const val = map.get(pName);
                parts.push(encodeURIComponent(val));
            } else {
                parts.push(encodeURIComponent(p));
            }
        }
        return parts.join('/');

    }
    public hrefFromMap(map: Map<string, string>) {
        return this.buildHref(map, this.template);
    }
    public async create(params: T) {
        const retVal = await this.info.create(params);
        retVal.route = this;

        const map = new Map<string, string>();
        this.info.fromParams(params, map);
        const t = [...this.template];
        const paths: string[] = [];
        while (t.length) {
            const templateId = this.templateParts.slice(0, t.length).join('/');
            if (routeMap.has(templateId)) {
                const href = this.buildHref(map, t);
                paths.push(href);
            }
            t.pop();
        }
        retVal.path = paths.shift();
        retVal.parentPaths = paths;

        return retVal;
    }
    public createFromMap(map: Map<string, string>) {
        const params = this.info.toParams(map);
        return this.create(params);
    }
}

const routes: NewRoute<unknown>[] = [];
const routeMap = new Map<string, NewRoute<unknown>>();

interface IRouteInfo<TParams> {
    route: string;
    fromParams: (params: TParams, map: Map<string, string>) => void;
    toParams: (map: Map<string, string>) => TParams;
    create: (params: TParams) => Promise<ANYROUTE>;
}
export function registerRoute<TParams>(route: IRouteInfo<TParams>) {
    const r = new NewRoute(route);
    routes.push(r);
    log(`Registered route ${r.id}`);
    if (routeMap.has(r.id)) {
        error(`Route ${r.id} already registered`);
    }
    routeMap.set(r.id, r);
    return r;
}

const initialHref = location.href;
// disable back button
let href = initialHref;

async function asyncHashChange(path: string) {
    path = path.replace(/^#/, '');
    log(`ROUTER: Changing to hash ${path}`);
    const r = matchRoute(path);
    if (!r) {
        error(`No route matches ${path}`);
        throw new Error(`Invalid route: unmatched`);
    }

    const anyRoute = await r.factory.createFromMap(r.map);
    await routeManager.navigateTo(anyRoute);
}

/*
window.addEventListener('hashchange', ev => {
    log(`Hash changed to ${location.hash} - ${ev.newURL}`);

    void asyncHashChange(location.hash);

});
*/

function popState(event: PopStateEvent) {
    log(`ROUTER popState`);
    if (navigationEnabled) {
        dir({ state: event.state, hash: location.hash });
        void asyncHashChange(location.hash);
    } else {
        log(`back-fix: push ${href} again`);
        history.pushState(null, document.title, href);
    }
}

export function disableNavigation() {
    history.pushState(null, document.title, href);
    log(`back-fix: Disable backbutton on url ${href}`);
    window.addEventListener('popstate', popState);
}

log(`ROUTER: initial history length: ${history.length}`);
let navigationEnabled = false;
export function enableNavigation() {
    navigationEnabled = true;
}

export function matchRoute(path: string) {
    const parts = splitParts(path);

    const matches = routes
        .map(factory => ({
            factory,
            map: factory.matches(parts)
        }))
        .filter(x => !!x.map);

    if (!matches.length) {
        return undefined;
    }

    if (matches.length > 1) {
        error(`${matches.length} routes match href ${href}`);
        throw new Error(`Invalid route: multiple matches`);
    }
    return matches[0];
}
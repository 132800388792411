import * as ko from 'knockout';
import { error, log } from '../../../debug';
import { dxAlert } from '../../../dx_helper';
import { setGolemScript } from '../../../Gremlin';
import * as HELPER from '../../../helper';
import { Disposables } from '../../../helper';
import { formatMessage } from '../../../i18n/data';
import { CallOnItemWidgets } from '../../../itemdefinition/base_itemdefinition';
import { GetSession, InitSession } from '../../../itemdefinition/model/session';
import * as API from '../../../its-itembank-api.g';
import { ButtonVisibility, DisplayProgress, DisplayTime, ItemHostEnum, ItemNavigationStatus, NavigationStyleEnum, SessionModesEnum, SessionStartModesEnum } from '../../../its-itembank-api.g';
import { DEFAULT_IMMEDIATE_RESULT_SECONDS } from '../../../magic';
import * as Modal from '../../../modal';
import { findInRingBuffer, RingBufferSearchOption } from '../../../new_array';
import { toastService } from '../../../toastService';
import { legacyPushPull } from '../../docmanager';
import { compactWindowMode, CurrentWindowMode, focusManager, normalWindowMode } from '../../focusmanager';
import * as FOCUSMANAGER from '../../focusmanager/widget';
import { ServerConnection, uiUrl } from '../../RestAPI';
import { splashHtml } from '../../splash';
import { addListener, UIAction, UIActionInvocation } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
import { ExamTickTock } from './examtimer';
import * as SPLASH from './splash.html.g';

type INIT = Awaited<ReturnType<API.Sdk['ui_service_participation_init']>>;

let IDX = 0;

const WIDGET_NAME = 'participationcontext';

export enum Pages {
    StartPage,
    ExecutionPage,
    FinishPage,
    PausePage,
    AbortedPage
}

export type SectionRaw = INIT['examSession']['sections'][0];
export type ItemRaw = SectionRaw['items'][0];

export class ParticipationContext {
    private readonly id = `ParticipationContext#${IDX++}`;
    private readonly disposables = new Disposables();
    public dispose() {
        this.disposables.dispose();
    }
    private sessionDocRefId: string;
    public getSession() {
        return GetSession(this.sessionDocRefId);
    }
    private readonly itemInfo = new Map<string, ItemRaw>();
    public readonly initData = ko.observable<INIT>();

    public readonly appTitleSection = ko.pureComputed(() => {
        const itemId = this.currentItem() || '';
        const init = this.initData();
        const candidate = init && init.examSession.candidate;
        const candidateId = candidate && candidate.candidateId || '';
        const candidateDisplay = candidate && candidate.display || '';
        const retVal = i18next.t(['ui.service.participation.participationcontext.APPTITLESECTION'], {
            itemId,
            candidateId,
            candidateDisplay,
        }).replace(/\s+/g, ' ').trim();
        log(`${this.id}: AppTitle is ${retVal}`);
        return retVal;
    });

    public getAccumulatedScore(topItemId: string) {
        const itemData = this.tickTock.itemsData.get(topItemId);
        return itemData && itemData.accumulatedScore;
    }
    public getSectionItemInfo(sectionId: string) {
        const sectionData = this.tickTock.sectionsData.get(sectionId);
        return sectionData && sectionData.sectionInfo.itemInfo;
    }
    public getSessionItemInfo() {
        const sessionData = this.tickTock.sessionData;
        return sessionData && sessionData.itemInfo;
    }
    public isPreviousNext() {
        const session = this.initData().examSession;
        if (!session) {
            return undefined;
        }
        return session.examInfo.navigationStyle === 'PreviousNext';
    }

    public isTopItemDone(topItemId: string) {
        const itemInfo = this.getTopItemItemInfo(topItemId);
        if (this.isPreviousNext()) {
            const data = this.getSession().GetItemModel(topItemId);
            if (data.data.IsInteractionComplete()) {
                return true;
            }
        }
        const navStatus = this.getNavigationStatus(topItemId);
        if (navStatus === ItemNavigationStatus.Submitted ||
            navStatus === ItemNavigationStatus.Revisable) {
            return true;
        }
        if (itemInfo.locked) {
            return true;
        }
        return false;

    }
    public getTopItemItemInfo(topItemId: string) {
        const itemData = this.tickTock.itemsData.get(topItemId);
        return itemData && itemData.itemInfo;

    }
    public getNavigationStatus(topItemId: string) {
        if (this.currentItem() === topItemId) {
            return ItemNavigationStatus.Attempting;
        }
        const fromOtherSrc = () => {
            if (this.navigationStatus.has(topItemId)) {
                return this.navigationStatus.get(topItemId);
            }
            const itemData = this.tickTock.itemsData.get(topItemId);
            return itemData && itemData.navigationStatus;
        };
        const status = fromOtherSrc();
        if (!status) {
            return ItemNavigationStatus.Notattempted;
        }
        if (status === ItemNavigationStatus.Attempting) {
            return ItemNavigationStatus.Postponed;
        }
        return status;
    }

    public readonly currentItem = ko.pureComputed(() => {
        return this.tickTock.currentItem();
    });

    public readonly currentItemIndex = ko.pureComputed(() => {
        const itemId = this.currentItem();
        if (!itemId) {
            return undefined;
        }
        const itemInfo = this.itemInfo.get(itemId);
        if (!itemInfo) {
            return 0;
        }
        return itemInfo.index;
    });

    public readonly currentItemType = ko.pureComputed(() => {
        const itemId = this.currentItem();
        if (!itemId) {
            return undefined;
        }
        const itemInfo = this.itemInfo.get(itemId);
        if (!itemInfo) {
            return undefined;
        }
        return itemInfo.itemType;
    });

    public readonly navigationStatus = new Map<string, ItemNavigationStatus>();


    public readonly currentPage = ko.pureComputed(() => {
        if (this.timerFinished()) {
            return Pages.FinishPage;
        }
        const mode = this.tickTock.mode();
        switch (mode) {
            case SessionModesEnum.New:
            case SessionModesEnum.Participating:
                break;
            default:
                return Pages.FinishPage;
        }
        const startMode = this.tickTock.startMode();
        switch (startMode) {
            case SessionStartModesEnum.NotYetStarted:
                return Pages.StartPage;
            case SessionStartModesEnum.Started:
                return Pages.ExecutionPage;
            case SessionStartModesEnum.Paused:
                return Pages.PausePage;
            case SessionStartModesEnum.Aborted:
                return Pages.AbortedPage;
            case SessionStartModesEnum.Finished:
                return Pages.FinishPage;
            default:
                error(`unhandled start mode: ${startMode}`);
                return Pages.StartPage;

        }
    });
    public readonly isAvailable = ko.pureComputed(() => true);
    public readonly isStartPage = ko.pureComputed(() => this.currentPage() === Pages.StartPage);
    public readonly isExecutionPage = ko.pureComputed(() => this.currentPage() === Pages.ExecutionPage);
    public readonly isPausePage = ko.pureComputed(() => this.currentPage() === Pages.PausePage);
    public readonly isAbortedPage = ko.pureComputed(() => this.currentPage() === Pages.AbortedPage);
    public readonly isFinishPage = ko.pureComputed(() => this.currentPage() === Pages.FinishPage);

    private readonly isCurrentItemAutoFocused = ko.pureComputed(() => {
        if (!this.isAutoFocusEnabled()) {
            return false;
        }
        const ci = this.ci();
        return ci && ci.hasFocusMode;
    });
    private readonly isAutoFocusEnabled = ko.pureComputed(() => {
        const ei = this.ei();
        return ei && ei.navigationStyle === NavigationStyleEnum.SubmitPostponeAutoFocus;
    });
    private readonly ci = ko.pureComputed(() => {
        const data = this.initData();
        if (!data) {
            return undefined;
        }
        const itemId = this.currentItem();
        if (!itemId) {
            return undefined;
        }
        for (const section of data.examSession.sections) {
            const ci = section.items.find(x => x.itemDocRefId === itemId);
            if (ci) {
                return ci;
            }
        }
        return undefined;
    });
    private readonly timerFinished = ko.observable(false);
    public readonly focusedItem = ko.pureComputed(() => {
        if (this.showOverview()) {
            log(`focusedItem - no: showing overview`);
            return undefined;
        }
        if (!this.isCurrentItemAutoFocused()) {
            log(`focusedItem - no: item is not autofocused`);
            return undefined;
        }
        log(`focusedItem - ${this.currentItem()}`);
        return this.currentItem();
    });
    public async GotoItem({ itemId }: { itemId: string }, e?: UIActionInvocation) {
        e = e || UIActionInvocation.Dummy();
        const currentItem = this.currentItem();
        if (itemId === this.currentItem()) {
            this.forceOverview(false);
            return;
        }
        if (currentItem && this.resetOnNavigate()) {
            e.object = { itemId };
            const msg = i18next.t(['ui.service.participation.participationcontext.BY_POSTPONING_THIS_QUESTION_YOUR_GIVEN_ANSWERS_ARE_REMOVED_DO_YOU_REALLY_WANT_TO_POSTPONE_THIS_QUESTION']);

            if (!await Modal.confirmYesNo(msg)) {
                e.object = { itemId };
                e.error = { message: 'User Cancelled' };
                e.success = false;
                return;
            }
            await GetSession(this.sessionDocRefId).getItemData(currentItem).reset();
        }
        if (currentItem) {
            this.navigationStatus.set(currentItem, ItemNavigationStatus.Postponed);
        }
        log(`${this.id}: GOTO ${itemId}`);
        await this.tickTock.send();
        this.goto({ itemId });
        log(`${this.id}: setting forceOverview to false`);
        this.forceOverview(false);
    }
    public async gotoSection(sectionId: string, e?: UIActionInvocation) {
        e = e || UIActionInvocation.Dummy();
        e.object = {
            sectionId: sectionId
        };

        const currentSection = this.currentSection();

        try {
            if ((currentSection && currentSection.id) === sectionId) {
                return;
            }

            await this.tickTock.send();
            const section = this.initData().examSession.sections.find(x => x.id === sectionId);
            const item = this.findItem(section.items.map(x => x.itemDocRefId), undefined, 'any');
            this.goto({ itemId: item });
        }
        finally {
            this.forceOverview(false);
        }
    }

    private async onFocusedItemChanged(curTopItem: string) {
        if (!curTopItem) {
            log(`${this.id}: onFocusedItemChanged: no curTopItem`);
            return;
        }
        const topItem = focusManager.topItemFocus();
        if (!topItem) {
            log(`${this.id} calling enter top item focus for ${curTopItem}`);
            void focusManager.enterTopItemFocus({
                sessionId: this.sessionDocRefId,
                itemId: curTopItem,
                itemType: this.getItemType(curTopItem),
                mode: 'INTERACTIVE'
            });
            return;
        }
        if ((topItem.itemId !== curTopItem) ||
            (topItem.sessionId !== this.sessionDocRefId) ||
            (topItem.mode != 'INTERACTIVE')) {
            error(`${this.id}: Already focused on ${topItem.itemId}`, 'new:', {
                sessionId: this.sessionDocRefId,
                itemDocRefId: curTopItem,
                mode: this.mode
            }, 'current:', topItem);
        }
    }
    public readonly wasTopItemPresented = new Set<string>();

    public getItemType(itemId: string) {
        const itemInfo = this.itemInfo.get(itemId);
        if (!itemInfo) {
            return undefined;
        }
        return itemInfo.itemType;
    }
    public async SetSession(options: {
        session: string;
        stayInSection: boolean;
        hasOverviewPage: boolean;
    }) {
        if (this.sessionDocRefId === options.session) {
            return;
        }
        if (this.sessionDocRefId) {
            throw new Error(`Switching sessions is not implemented`);
        }
        this.sessionDocRefId = options.session;
        this.stayInSection(options.stayInSection);
        this.hasOverviewPage(options.hasOverviewPage);
        await this.tickTock.init(this.sessionDocRefId);
        this.disposables.addDiposable(focusManager.focused.subscribe(newVal => {
            switch (newVal) {
                case 'entering':
                case 'leaving':
                    log(`${this.id}: Pause exam timer`);
                    void this.tickTock.stop();
                    break;
                case 'focused':
                case 'no':
                    if (this.currentItem()) {
                        log(`${this.id}: Resume exam timer`);
                        void this.tickTock.start();
                    }
                    break;
                default:
                    HELPER.assertNever(newVal);
                    break;
            }
        }));
        log(`${this.id}: start mode: ${this.tickTock.startMode()}, mode: ${this.tickTock.mode()}, time left: ${this.tickTock.timeleftMinutes()}`);
        this.disposables.addDiposable(this.focusedItem.subscribe(curTopItem => {
            log(`${this.id}: focusedItem changed to ${curTopItem}`);
            void this.onFocusedItemChanged(curTopItem);
        }));
        this.disposables.addDiposable(this.tickTock.currentItem.subscribe(x => {
            const ci = this.ci();
            const curTopItem = this.currentItem();
            log(`${this.id}: currentItem changed to ${curTopItem}`);
            this.wasTopItemPresented.add(curTopItem);
            const autoFocus = this.isCurrentItemAutoFocused();
            if (autoFocus) {
                log(`${this.id}: switch to compact window mode (${x}/${curTopItem})`);
                compactWindowMode(ci);
            } else {
                log(`${this.id}: switch to normal window mode (${x}/${curTopItem}})`);
                normalWindowMode();
            }
        }));
        this.disposables.addDiposable(this.tickTock.timeleftMinutes.subscribe(x => {
            const retVal = this.tickTock.timeleftMinutes() == 0;
            log(`${this.id}: timer finished: ${retVal}`);
            HELPER.update_observable(this.timerFinished, retVal);
        }));
        this.disposables.addDiposable(this.tickTock.currentItem.subscribe(x => {
            if (x) {
                log(`${this.id}: start timer (${x})`);
                this.tickTock.start();
            }
        }));
        this.disposables.addDiposable(this.tickTock);
        this.disposables.onChange(this.tickTock.mode, async x => {
            log(`${this.id}: Mode changed to ${x}`);
            switch (x) {
                case SessionModesEnum.Participating:
                case SessionModesEnum.New:
                    return x;
            }
            log(`${this.id}: Not in participation any more. leave focus and disconnect from donkey`);
            await focusManager.disconnectDonkey();
            return x;
        });

        const init = await ServerConnection.api.ui_service_participation_init({
            params: {
                sessionDocRefId: this.sessionDocRefId
            },
            userAgent: window.navigator.userAgent,
        });
        this.itemInfo.clear();
        for (const section of init.examSession.sections) {
            for (const item of section.items) {
                this.itemInfo.set(item.itemDocRefId, item);
            }
        }
        this.initData(init);
        const gs = init.examSession.golemScript;
        if (gs && gs.scriptJson) {
            setGolemScript(gs.scriptJson);
        }
        if (init.examSession.examInfo.hasInApplication) {
            if (init.examSession.mode === SessionModesEnum.Participating) {
                await focusManager.connectToDonkey(this.sessionDocRefId);
            }
        }
        await InitSession(options.session, []);

        const ei = this.ei();
        const continueWith = ei.continueWith;
        switch (continueWith) {
            case 'overview':
                this.forceOverview(true);
                break;
            case 'item':
                this.forceOverview(false);
                break;
        }

        if (!ei.passesRestrictions) {
            const restrictions = ei.restrictions;
            const msg = formatMessage(i18next.t(['ui.service.participation.participationcontext.UNABLE_TO_START_EXAM_BECAUSE_THE_REQUIREMENTS_WERE_NOT_MET_RESTRICTIONS']), {
                restrictions
            });
            error(msg);
            throw new Error(msg);
        }
        addListener(data => this.logUIAction(data));
        log(`current page: ${this.currentPage()}`);
        if (!this.isFinishPage()) {
            if (this.autoStartExam()) {
                log(`calling start exam`);
                await this.startExam(false);
            }
            await this.onFocusedItemChanged(this.focusedItem());
        }
    }

    public readonly autoStartExam = ko.pureComputed(() => {
        return !this.hasWelcomePage();
    });

    public readonly hasWelcomePage = ko.pureComputed(() => {
        const ei = this.ei();
        if (!ei) {
            return undefined;
        }
        switch (ei.itemHost) {
            case ItemHostEnum.Singleitem:
                return true;
            case ItemHostEnum.Hierarchical:
                return false;
            default:
                throw new Error();
        }
    });

    public readonly time_percent = ko.pureComputed(() => {
        const ep = this.ep();
        const x = ep && ep.displayTime || DisplayTime.Default;
        switch (x) {
            case DisplayTime.No:
                return undefined;
            case DisplayTime.Passed:
                return this.tickTock.timePassedPercent();
            case DisplayTime.Default:
            case DisplayTime.Remaining:
            default:
                return this.tickTock.timeleftPercent();
        }
    });

    public readonly hasOverviewButton = ko.pureComputed(() => {
        const ep = this.ep();
        switch (ep.overviewButton.visibility || ButtonVisibility.Default) {
            case ButtonVisibility.Hide:
                return false;
            case ButtonVisibility.Show:
                return true;
        }
        return this.hasOverviewPage();
    });

    private readonly ei = ko.pureComputed(() => {
        const d = this.initData();
        return d && d.examSession && d.examSession.examInfo;
    });

    private readonly wp = ko.pureComputed(() => {
        const ei = this.ei();
        const wp = ei && ei.welcomePage;
        return wp;
    });

    private readonly ep = ko.pureComputed(() => {
        const ei = this.ei();
        const ep = ei && ei.executionPage;
        return ep;
    });

    public readonly isTimeLeftEnabled = ko.pureComputed(() => {
        if (!this.isTimeEnabled()) {
            return false;
        }
        const d = this.initData().examSession.examInfo.executionPage.displayTime;
        return d === DisplayTime.Remaining || d === DisplayTime.Default;
    });

    public readonly isTimePassedEnabled = ko.pureComputed(() => {
        if (!this.isTimeEnabled()) {
            return false;
        }
        const d = this.initData().examSession.examInfo.executionPage.displayTime;
        return d === DisplayTime.Passed;
    });

    private readonly default_time_label = ko.pureComputed(() => {
        const ep = this.ep();
        const x = ep && ep.displayTime || DisplayTime.Default;
        switch (x) {
            case DisplayTime.No:
                return '';

            case DisplayTime.Passed:
                return i18next.t(['ui.service.participation.participationcontext.MINUTESPASSED_PASSED']);

            case DisplayTime.Default:
            case DisplayTime.Remaining:
            default:
                return i18next.t(['ui.service.participation.participationcontext.MINUTESLEFT_LEFT']);
        }
    });
    private readonly default_progress_label = ko.pureComputed(() => {
        const ep = this.ep();
        const x = ep && ep.displayTime || DisplayTime.Default;
        switch (x) {
            case DisplayTime.No:
                return '';

            case DisplayTime.Passed:
                return i18next.t(['ui.service.participation.participationcontext.ITEMSCOMPLETED_ITEMS_DONE']);

            case DisplayTime.Default:
            case DisplayTime.Remaining:
            default:
                return i18next.t(['ui.service.participation.participationcontext.ITEMSPENDING_ITEMS_OPEN']);
        }
    });
    public readonly time_label = ko.pureComputed(() => {
        const ep = this.ep();
        const label = ep && ep.timeLabel.value || this.default_time_label() || '';
        return label
            .replace(/\{minutesPassed\}/g, '' + this.tickTock.timePassedMinutes())
            .replace(/\{minutesLeft\}/g, '' + this.tickTock.timeleftMinutes());
    });

    public readonly itemsTotal = ko.pureComputed(() => {
        return this.tickTock.items_total();
    });

    public readonly itemsPending = ko.pureComputed(() => {
        const ei = this.ei();
        if (!ei) {
            return undefined;
        }
        switch (ei.navigationStyle) {
            case 'SubmitPostpone':
            case 'SubmitPostponeAutoFocus':
                return this.tickTock.items_pending();
            case 'PreviousNext':
            default:
                return this.tickTock.items_total() - this.tickTock.items_completed();
        }
    });

    public readonly displayTime = ko.pureComputed(() => {
        const sessionData = this.initData().examSession;
        if (!sessionData) {
            return undefined;
        }
        const x = sessionData.examInfo.executionPage.displayTime;
        switch (x) {
            case 'remaining':
            case 'passed':
                return true;
            case 'no':
                return false;
            case 'default':
                break;
        }
        return sessionData.totalTime !== '∞';
    });
    public readonly displayProgress = ko.pureComputed(() => {
        const sessionData = this.initData().examSession;
        if (!sessionData) {
            return undefined;
        }
        const x = sessionData.examInfo.executionPage.displayProgress;
        switch (x) {
            case 'remaining':
            case 'passed':
                return true;
            case 'no':
                return false;
            case 'default':
                break;
        }
        return true;
    });

    public readonly progress_label = ko.pureComputed(() => {
        const ep = this.ep();
        const label = ep && ep.progressLabel.value || this.default_progress_label() || '';
        return label
            .replace(/\{itemsCompleted\}/g, '' + this.tickTock.items_completed())
            .replace(/\{itemsPending\}/g, '' + this.tickTock.items_pending())
            .replace(/\{itemsTotal\}/g, '' + this.tickTock.items_total());
    });
    public readonly progress_percent = ko.pureComputed(() => {
        const ep = this.ep();
        const x = ep && ep.displayProgress || DisplayProgress.Default;
        switch (x) {
            case DisplayProgress.No:
                return undefined;
            case DisplayProgress.Passed:
                return Math.floor(this.tickTock.items_completed() * 100 / this.tickTock.items_total());
            case DisplayProgress.Remaining:
            case DisplayProgress.Default:
            default:
                return Math.floor(this.tickTock.items_pending() * 100 / this.tickTock.items_total());
        }
    });
    public readonly focusManagerParams = ko.pureComputed(() => {
        const retVal: FOCUSMANAGER.IParams = {
            buttons: []
        };
        const ep = this.ep();
        if (ep.displayTime !== DisplayTime.No) {
            retVal.time_percent = this.time_percent;
            retVal.time_label = this.time_label;
        }
        if (ep.displayProgress !== DisplayProgress.No) {
            retVal.progress_percent = this.progress_percent;
            retVal.progress_label = this.progress_label;
        }

        if (this.hasResetButton()) {
            retVal.buttons.push({
                icon: 'icon_refresh',
                text: this.resetButtonLabel(),
                action: this.actionReset
            });
        }
        if (this.hasOverviewButton()) {
            retVal.buttons.push({
                icon: 'icon_overview',
                text: this.overviewButtonLabel(),
                action: this.actionOverview,
            });
        }
        if (this.hasPostponeButton()) {
            retVal.buttons.push({
                icon: 'icon_pause',
                text: this.postponeButtonLabel(),
                action: this.actionPostpone,
            });
        }
        if (this.hasSubmitButton()) {
            retVal.buttons.push({
                icon: 'icon_check',
                text: this.submitButtonLabel(),
                action: this.actionSubmit,
                type: 'default',
            });
        }
        log(`${this.id}: focusManagerParams`, retVal);
        return retVal;
    });

    public readonly resetButtonLabel = ko.pureComputed(() => {
        return this.ep().resetButton.label.value || i18next.t(['ui.service.participation.participationcontext.RESET']);
    });
    public readonly abortButtonLabel = ko.pureComputed(() => {
        return this.wp().cancelButton.label.value || i18next.t(['ui.service.participation.participationcontext.CANCEL']);
    });
    public readonly startButtonLabel = ko.pureComputed(() => {
        return this.wp().startButton.label.value || i18next.t(['ui.service.participation.participationcontext.START']);
    });

    public readonly postponeButtonLabel = ko.pureComputed(() => {
        return this.ep().postponeButton.label.value || i18next.t(['ui.service.participation.participationcontext.POSTPONE_QUESTION']);
    });

    public readonly submitButtonLabel = ko.pureComputed(() => {
        return this.ep().submitButton.label.value ||
            i18next.t(['ui.service.participation.participationcontext.SUBMIT_ANSWER']);
    });
    public readonly prevButtonLabel = ko.pureComputed(() => {
        return this.ep().previousButton.label.value || i18next.t(['ui.service.participation.participationcontext.PREVIOUS']);
    });

    public readonly nextButtonLabel = ko.pureComputed(() => {
        return this.ep().nextButton.label.value || i18next.t(['ui.service.participation.participationcontext.NEXT']);
    });

    public readonly currentScoreMax = ko.pureComputed(() => {
        const ci = this.ci();
        if (!ci) {
            return undefined;
        }
        return ci.accumulatedScore && ci.accumulatedScore.pointsMax;
    });

    public sectionProgress(sectionId: string) {
        const init = this.initData();
        const section = init.examSession.sections.find(x => x.id === sectionId);
        if (!section) {
            return undefined;
        }
        const itemIds = section.items.map(x => x.itemDocRefId);
        const total = itemIds.length;
        const done = itemIds.filter(x => this.isTopItemDone(x)).length;
        const pending = total - done;
        return {
            total,
            pending,
            done
        };
    }
    public examProgress() {
        const init = this.initData();
        const itemIds = [];
        for (const section of init.examSession.sections) {
            itemIds.push(...section.items.map(x => x.itemDocRefId));
        }
        const total = itemIds.length;
        const done = itemIds.filter(x => this.isTopItemDone(x)).length;
        const pending = total - done;
        return {
            total,
            pending,
            done
        };
    }
    public readonly currentSection = ko.pureComputed(() => {
        const init = this.initData();
        const itemId = this.currentItem();
        if (!itemId) {
            return undefined;
        }

        const section = init.examSession.sections.find(x => x.items.some(x => x.itemDocRefId === itemId));
        return section;
    });

    public readonly showItemMaxScore = ko.pureComputed(() => {
        const init = this.initData();
        const showItemMaxScore = init.examSession.examInfo.executionPage.showItemMaxScore;
        switch (showItemMaxScore) {
            case 'default':
            case 'yes':
                return true;
            case 'no':
                return false;
            default:
                throw new Error();

        }
    });

    public readonly currentSectionColor = ko.pureComputed(() => {
        const currentSection = this.currentSection();
        if (!currentSection) {
            return undefined;
        }
        return currentSection.sectionInfo.color || '0';
    });
    public readonly currentSectionAlternateColor = ko.pureComputed(() => {
        const currentSection = this.currentSection();
        if (!currentSection) {
            return undefined;
        }
        return currentSection.sectionInfo.alternateColor || currentSection.sectionInfo.color || '0';
    });

    public readonly currentSectionTitle = ko.pureComputed(() => {
        const currentSection = this.currentSection();
        if (!currentSection) {
            return undefined;
        }
        return currentSection.sectionInfo.title;
    });

    public readonly customlogo1 = ko.pureComputed(() => {
        const sd = this.initData();
        return sd && sd.config && sd.config.customlogo1;
    });
    public readonly customlogo2 = ko.pureComputed(() => {
        const sd = this.initData();
        return sd && sd.config && sd.config.customlogo2;
    });

    public readonly overviewButtonLabel = ko.pureComputed(() => {
        const sectionTitle = this.currentSectionTitle() || '';
        return this.ep().overviewButton.label.value || formatMessage(i18next.t(['ui.service.participation.participationcontext.SECTIONTITLE_STATUS']), { sectionTitle });
    });

    private readonly showResetConfirm = ko.observable(true);
    public readonly actionPostpone = new UIAction('ActionPostpone', async (e) => {
        try {
            const itemId = this.currentItem();
            e.object = {
                itemId
            };
            if (this.resetOnNavigate()) {
                const message = i18next.t(['ui.service.participation.participationcontext.BY_POSTPONING_THIS_QUESTION_YOUR_GIVEN_ANSWERS_ARE_REMOVED_DO_YOU_REALLY_WANT_TO_POSTPONE_THIS_QUESTION']);
                if (!await Modal.confirmYesNo(message)) {
                    e.error = { message: 'User Cancelled' };
                    e.success = false;
                    return;
                }
                this.showLoadPanel();
            }
            if (this.isLastItem()) {
                await this.doReset({ itemId });
            } else {
                await this.doPostpone({ itemId });
            }

        }
        catch (err) {
            throw err;
        }
        finally {
            this.hideLoadPanel();
        }
    }, { ignoreErrors: true });
    private goto({ itemId }: { itemId: string }) {
        this.tickTock.currentItem(itemId);
        this.tickTock.queueNavigatedTo(itemId);
        window.scrollTo({ top: 0, left: 0 });

    }
    private async doPostpone({ itemId }: { itemId: string }) {
        await focusManager.leaveFocus();

        if (this.resetOnNavigate()) {
            await GetSession(this.sessionDocRefId).getItemData(itemId).reset();
        }
        await this.tickTock.send();
        this.navigationStatus.set(itemId, ItemNavigationStatus.Postponed);
        const nextItem = this.findNextItem(itemId);
        this.goto({ itemId: nextItem });
    }
    public readonly hasSubmitButton = ko.pureComputed(() => {
        const ei = this.ei();
        if (!ei) {
            return undefined;
        }
        switch (ei.navigationStyle) {
            case NavigationStyleEnum.SubmitPostpone:
            case NavigationStyleEnum.SubmitPostponeAutoFocus:
                return true;
            default:
                return false;
        }
    });
    public readonly mode = ko.pureComputed(() => {
        return this.tickTock.startMode();
    });
    public readonly hasPreviousButton = ko.pureComputed(() => {
        const ei = this.ei();
        if (!ei) {
            return undefined;
        }
        return ei.navigationStyle === NavigationStyleEnum.PreviousNext;
    });
    public readonly hasNextButton = ko.pureComputed(() => {
        const ei = this.ei();
        if (!ei) {
            return undefined;
        }
        return ei.navigationStyle === NavigationStyleEnum.PreviousNext;
    });
    public async startExam(selectFirstItem: boolean) {
        const result = await legacyPushPull(() => ServerConnection.api.ui_service_participation_startexam({
            examSessionParams: {
                sessionDocRefId: this.sessionDocRefId
            },
            startExamParams: {
                selectFirstItem,
            }
        }));
        await this.tickTock.send();
        await legacyPushPull();
        const currentItemServer = result?.examSession?.startExam?.currentItem?.itemDocRefId;
        if (!this.currentItem() && currentItemServer) {
            log(`Goto first item ${currentItemServer}`);
            this.goto({ itemId: currentItemServer });
        }
    }
    private async logUIAction(data: UIActionInvocation) {
        if (data.isAnonymousAction) {
            return;
        }
        this.tickTock.queueInteraction({
            duration: data.duration,
            error: {
                message: data.error && data.error.message || '',
                stack: data.error && data.error.stack || ''
            },
            object: data.object,
            relativeTime: data.relativeTime,
            success: data.success,
            verb: data.verb
        });
    }
    public readonly isNotFocusMode = ko.pureComputed(() => {
        return CurrentWindowMode() !== 'compact';
    });
    private async confirmFinishExam() {
        const message = i18next.t(['ui.service.participation.participationcontext.THIS_IS_THE_LAST_QUESTION_OF_THE_EXAM_THE_EXAM_ENDS_AFTER_SUBMITTING_THIS_QUESTION_YOU_WON_T_BE_ABLE_TO_EDIT_PREVIOUS_QUESTIONS_ANYMORE_DO_YOU_WANT_TO_PROCEED']);
        return await Modal.confirmYesNo(message);
    }
    private async confirmNextItem(message: string) {
        const br = '<br />';
        const msg = message + br + i18next.t(['ui.service.participation.participationcontext.DO_YOU_REALLY_WANT_TO_CONTINUE']);
        return await Modal.confirmYesNo(msg);
    }
    public readonly resetOnNavigate = ko.pureComputed(() => {
        const ei = this.ei();
        if (!ei) {
            return undefined;
        }
        return ei.resetOnNavigate;
    });
    public readonly hasOverviewPage = ko.observable(true);
    public readonly forceOverview = ko.observable(true);
    public readonly showOverview = ko.pureComputed(() => {
        return this.forceOverview() || !(this.currentItem());
    });
    public readonly actionOverview = new UIAction<{}>('ActionOverview', async (e) => {
        if (focusManager.isFocused()) {
            try {
                e.object = {
                    itemId: this.currentItem()
                };
                await focusManager.leaveFocus();
                normalWindowMode();
            }
            catch (err) {
                throw err;
            }
            finally {
                this.hideLoadPanel();
            }
        }
        this.forceOverview(true);
    });
    public readonly stayInSection = ko.observable(true);
    public readonly hasResetButton = ko.pureComputed(() => {
        const ep = this.ep();
        const ei = this.ei();
        switch (ep.resetButton.visibility || ButtonVisibility.Default) {
            case ButtonVisibility.Hide:
                return false;
            case ButtonVisibility.Show:
                return true;
        }
        switch (ei.navigationStyle) {
            case NavigationStyleEnum.SubmitPostponeAutoFocus:
                return true;
            case NavigationStyleEnum.SubmitPostpone:
            case NavigationStyleEnum.PreviousNext:
            default:
                return false;
        }
    });
    public readonly hasPostponeButton = ko.pureComputed(() => {
        const ep = this.ep();
        switch (ep.postponeButton.visibility || ButtonVisibility.Default) {
            case ButtonVisibility.Hide:
                return false;
            case ButtonVisibility.Show:
                return true;
        }

        const ei = this.ei();
        switch (ei.navigationStyle) {
            case NavigationStyleEnum.SubmitPostpone:
            case NavigationStyleEnum.SubmitPostponeAutoFocus:
                return true;
            default:
                return false;
        }
    });
    public readonly isTimeEnabled = ko.pureComputed(() => {
        const sd = this.initData();
        if (!sd) {
            return undefined;
        }
        return sd.examSession.totalTime !== '∞';
    });
    public readonly canSubmit = ko.observable(true);
    public setSubmitEnableTime() {
        this.canSubmit(false);
        const initData = this.initData();
        const submitEnableTime = initData && initData.config.submitEnableTime || 0;
        setTimeout(() => {
            this.canSubmit(true);
        }, submitEnableTime);
    }
    findItem(items: string[], currentItem: string, direction: RingBufferSearchOption) {
        const validState = (x: string) => {
            const state = this.getNavigationStatus(x);
            switch (state) {
                case ItemNavigationStatus.Notattempted:
                case ItemNavigationStatus.Postponed:
                    return true;
            }
            return false;
        };
        const nextItem = findInRingBuffer(items, items.indexOf(currentItem), validState, direction);
        if (nextItem) {
            return nextItem;
        }
        return undefined;

    }
    findNextItem(currentItem: string) {
        const items = [];
        const currentSection = this.currentSection();
        if (currentSection && this.stayInSection()) {
            items.push(...currentSection.items.map(x => x.itemDocRefId));
        } else {
            for (const section of this.initData().examSession.sections) {
                items.push(...section.items.map(x => x.itemDocRefId));
            }
        }
        const nextItem = this.findItem(items, currentItem, 'any');
        return nextItem;
    }

    private async submit() {
        this.showLoadPanel();
        const currentItem = this.currentItem();
        await this.tickTock.send({
            submitted: [currentItem]
        });
        this.navigationStatus.set(currentItem, ItemNavigationStatus.Submitted);
        const score = this.getAccumulatedScore(currentItem);
        const epSettings = this.ep();
        if (score) {
            if (epSettings.showImmediateResult === 'scored' && score.pointsPending === 0) {
                const o = {
                    itemId: currentItem,
                    pointsGained: score.pointsGained,
                    pointsMax: score.pointsMax,
                };
                if (!splashHtml({
                    html: getSplashHtml(o, epSettings),
                    seconds: epSettings.immediateResultSeconds || DEFAULT_IMMEDIATE_RESULT_SECONDS,
                })) {
                    await dxAlert(getImmediateResultText(o, epSettings));

                }
            }
        }
        const nextItem = this.findNextItem(currentItem);
        this.goto({ itemId: nextItem });
    }

    public readonly isExamComplete = ko.pureComputed(() => {
        if (this.ei().navigationStyle === NavigationStyleEnum.PreviousNext) {
            const session = this.getSession();
            for (const section of this.initData().examSession.sections) {
                for (const item of section.items) {
                    if (!session.getItemData(item.itemDocRefId).IsInteractionComplete()) {
                        return false;
                    }
                }
            }
            return true;
        }
        return this.tickTock.items_completed() === this.tickTock.items_total();
    });

    private async confirmFinishPartialExam() {
        const message = i18next.t(['ui.service.participation.participationcontext.DO_YOU_REALLY_WANT_TO_CONTINUE_AFTERWARDS_YOU_CANNOT_CHANGE_YOUR_GIVEN_ANSWERS_ANY_MORE']);

        return await Modal.confirmYesNo(message);
    }

    private async confirmFinishCompleteExam() {
        if (!this.isExamComplete()) {
            toastService.error(i18next.t(['ui.service.participation.participationcontext.THERE_ARE_UNANSWERED_QUESTIONS_IT_IS_NOT_POSSIBLE_TO_FINISH_YET']));
            return undefined;
        }
        const message = i18next.t(['ui.service.participation.participationcontext.DO_YOU_REALLY_WANT_TO_CONTINUE_AFTERWARDS_YOU_CANNOT_CHANGE_YOUR_GIVEN_ANSWERS_ANY_MORE']);

        return await Modal.confirmYesNo(message);
    }

    public async finishCompleteExam() {
        this.showLoadPanel();
        try {
            if (!await this.confirmFinishCompleteExam()) {
                return;
            }
            await this.finishExam();
        } finally {
            this.hideLoadPanel();
        }
    }

    public async finishPartialExam() {
        this.showLoadPanel();
        try {
            if (!await this.confirmFinishPartialExam()) {
                return;
            }
            await this.finishExam();
        } finally {
            this.hideLoadPanel();
        }
    }

    private async finishExam() {
        this.showLoadPanel();
        try {
            await this.tickTock.stop();
            const result = await legacyPushPull(() => ServerConnection.api.ui_service_participation_finishexam({
                examSessionParams: {
                    sessionDocRefId: this.sessionDocRefId
                }
            }));
            const d = result.examSession.finishExam;
            if (!d.ok) {
                const msg = d.message;
                if (!msg) {
                    return;
                }
                await dxAlert(msg.value);
            }
            await this.tickTock.send();
        }
        finally {
            this.hideLoadPanel();
        }
    }
    private showLoadPanel() {
        //this.loadPanelVisible(true);
    }
    private hideLoadPanel() {
        this.loadPanelVisible(false);
    }
    public readonly loadPanelVisible = ko.observable(false);
    public readonly tickTock = new ExamTickTock();
    public readonly actionPrevious = new UIAction('ActionPrevious', async (e) => {
        try {
            e.object = {
                itemId: this.currentItem()
            };
            const currentSection = this.currentSection();
            const items = currentSection.items.map(x => x.itemDocRefId);
            const nextItem = findInRingBuffer(items, items.indexOf(this.currentItem()), x => true, 'previous');
            if (!nextItem) {
                await this.actionOverview.invoke({}, true);
                return;
            }
            this.setSubmitEnableTime();
            this.goto({ itemId: nextItem });
            await this.tickTock.send();
        }
        catch (err) {
            throw err;
        }
        finally {
            this.hideLoadPanel();
        }
    }, { ignoreErrors: true });
    public readonly actionNext = new UIAction('ActionNext', async (e) => {
        try {
            e.object = {
                itemId: this.currentItem()
            };
            const session = GetSession(this.sessionDocRefId);
            const itemModel = session.GetItemModel(this.currentItem());

            if (itemModel.data.IsInteractionStarted()) {
                if (!itemModel.data.IsInteractionComplete()) {
                    //server complete message
                    const msg = i18next.t(['ui.service.participation.participationcontext.THE_QUESTION_WAS_NOT_FILLED_OUT_COMPLETELY']);
                    if (!await this.confirmNextItem(msg)) {
                        return;
                    }
                }
            }
            const currentSection = this.currentSection();
            const items = currentSection.items.map(x => x.itemDocRefId);
            const nextItem = findInRingBuffer(items, items.indexOf(this.currentItem()), x => true, 'next');
            this.setSubmitEnableTime();
            if (nextItem) {
                this.goto({ itemId: nextItem });
            }
            await this.tickTock.send();
            if (!nextItem) {
                await this.actionOverview.invoke({}, true);
                return;
            }
        }
        catch (err) {
            throw err;
        }
        finally {
            this.hideLoadPanel();
        }
    }, { ignoreErrors: true });

    private async doReset({ itemId }: { itemId: string }) {
        await focusManager.reset(this.sessionDocRefId, itemId);
        /*
        await legacyPushPull(() => ServerConnection.mutate(RESET, {
            examSessionParams: {
                sessionDocRefId: this.sessionDocRefId
            },
            reset: {
                itemDocRefId: itemId,
            }
        }));
        */
        await GetSession(this.sessionDocRefId).getItemData(itemId).reset();
        await Promise.all(CallOnItemWidgets(items => {
            return items.map(x => x.OnReset());
        }));
        await this.tickTock.send();
    }
    public readonly actionReset = new UIAction('ActionReset', async (e) => {
        try {
            const itemId = this.currentItem();
            e.object = {
                itemId
            };
            if (this.showResetConfirm()) {
                const msg = i18next.t(['ui.service.participation.participationcontext.BY_RESETTING_THIS_QUESTION_YOUR_GIVEN_ANSWERS_ARE_REMOVED_DO_YOU_REALLY_WANT_TO_RESET_THIS_QUESTION']);
                if (!await Modal.confirmYesNo(msg)) {
                    e.error = { message: 'User Cancelled' };
                    e.success = false;
                    return;
                }
                this.showLoadPanel();
            }
            await this.doReset({ itemId });

        }
        catch (err) {
            throw err;
        }
        finally {
            this.hideLoadPanel();
        }
    }, { ignoreErrors: true });

    public readonly actionSubmit = new UIAction('ActionSubmit', async (e) => {
        try {
            e.object = {
                itemId: this.currentItem()
            };
            this.setSubmitEnableTime();
            const session = GetSession(this.sessionDocRefId);
            const itemModel = session.GetItemModel(this.currentItem());
            const isComplete = itemModel.data.IsInteractionComplete();
            if (!isComplete) {
                if (!await Modal.confirmYesNo(i18next.t(['ui.service.participation.participationcontext.YOU_HAVE_TO_COMPLETE_THE_ITEM_FIRST']))) {
                    return;
                }
            }
            const isLastItem = this.isLastItem();
            if (isLastItem) {
                if (!await this.confirmFinishExam()) {
                    return;
                }
            }
            await focusManager.leaveFocus();
            await this.submit();
            if (isLastItem) {
                await this.finishExam();
            }

        }
        catch (err) {
            throw err;
        }
        finally {
            this.hideLoadPanel();
        }
    }, { ignoreErrors: true });
    public readonly isLastItem = ko.pureComputed(() => {
        const currentItem = this.currentItem();
        if (!currentItem) {
            return false;
        }
        return this.tickTock.items_locked() === this.tickTock.items_total() - 1;
    });
}

function getImmediateResultText(options: { pointsGained: number, pointsMax: number, itemId: string }, config: INIT['examSession']['examInfo']['executionPage']) {
    const textTemplate = config.immediateResultText.value || i18next.t(['ui.service.participation.participationcontext.IMMEDIATE_RESULT_TEXT']);
    const text = textTemplate
        .replace(/\{itemId\}/g, options.itemId)
        .replace(/\{pointsMax\}/g, options.pointsMax.toString())
        .replace(/\{pointsGained\}/g, options.pointsGained.toString());
    return text;
}
function getSvg(options: { pointsGained: number, pointsMax: number }) {
    if (options.pointsGained === options.pointsMax) {
        return uiUrl + 'item_correct.svg';
    }
    if (options.pointsGained > 0) {
        return uiUrl + 'item_partiallycorrect.svg';
    }
    return uiUrl + 'item_incorrect.svg';
}
function getSplashHtml(options: { pointsGained: number, pointsMax: number, itemId: string }, config: INIT['examSession']['examInfo']['executionPage']) {
    const text = getImmediateResultText(options, config);
    const img = getSvg(options);
    return SPLASH.htmlString.replace(/\{text\}/g, text)
        .replace(/\{img\}/g, img);
}
const standardColorNames: Record<string, string> = {
    'aliceblue': 'f0f8ff',
    'antiquewhite': 'faebd7',
    'aqua': '00ffff',
    'aquamarine': '7fffd4',
    'azure': 'f0ffff',
    'beige': 'f5f5dc',
    'bisque': 'ffe4c4',
    'black': '000000',
    'blanchedalmond': 'ffebcd',
    'blue': '0000ff',
    'blueviolet': '8a2be2',
    'brown': 'a52a2a',
    'burlywood': 'deb887',
    'cadetblue': '5f9ea0',
    'chartreuse': '7fff00',
    'chocolate': 'd2691e',
    'coral': 'ff7f50',
    'cornflowerblue': '6495ed',
    'cornsilk': 'fff8dc',
    'crimson': 'dc143c',
    'cyan': '00ffff',
    'darkblue': '00008b',
    'darkcyan': '008b8b',
    'darkgoldenrod': 'b8860b',
    'darkgray': 'a9a9a9',
    'darkgreen': '006400',
    'darkkhaki': 'bdb76b',
    'darkmagenta': '8b008b',
    'darkolivegreen': '556b2f',
    'darkorange': 'ff8c00',
    'darkorchid': '9932cc',
    'darkred': '8b0000',
    'darksalmon': 'e9967a',
    'darkseagreen': '8fbc8f',
    'darkslateblue': '483d8b',
    'darkslategray': '2f4f4f',
    'darkturquoise': '00ced1',
    'darkviolet': '9400d3',
    'deeppink': 'ff1493',
    'deepskyblue': '00bfff',
    'dimgray': '696969',
    'dodgerblue': '1e90ff',
    'feldspar': 'd19275',
    'firebrick': 'b22222',
    'floralwhite': 'fffaf0',
    'forestgreen': '228b22',
    'fuchsia': 'ff00ff',
    'gainsboro': 'dcdcdc',
    'ghostwhite': 'f8f8ff',
    'gold': 'ffd700',
    'goldenrod': 'daa520',
    'gray': '808080',
    'green': '008000',
    'greenyellow': 'adff2f',
    'honeydew': 'f0fff0',
    'hotpink': 'ff69b4',
    'indianred': 'cd5c5c',
    'indigo': '4b0082',
    'ivory': 'fffff0',
    'khaki': 'f0e68c',
    'lavender': 'e6e6fa',
    'lavenderblush': 'fff0f5',
    'lawngreen': '7cfc00',
    'lemonchiffon': 'fffacd',
    'lightblue': 'add8e6',
    'lightcoral': 'f08080',
    'lightcyan': 'e0ffff',
    'lightgoldenrodyellow': 'fafad2',
    'lightgrey': 'd3d3d3',
    'lightgreen': '90ee90',
    'lightpink': 'ffb6c1',
    'lightsalmon': 'ffa07a',
    'lightseagreen': '20b2aa',
    'lightskyblue': '87cefa',
    'lightslateblue': '8470ff',
    'lightslategray': '778899',
    'lightsteelblue': 'b0c4de',
    'lightyellow': 'ffffe0',
    'lime': '00ff00',
    'limegreen': '32cd32',
    'linen': 'faf0e6',
    'magenta': 'ff00ff',
    'maroon': '800000',
    'mediumaquamarine': '66cdaa',
    'mediumblue': '0000cd',
    'mediumorchid': 'ba55d3',
    'mediumpurple': '9370d8',
    'mediumseagreen': '3cb371',
    'mediumslateblue': '7b68ee',
    'mediumspringgreen': '00fa9a',
    'mediumturquoise': '48d1cc',
    'mediumvioletred': 'c71585',
    'midnightblue': '191970',
    'mintcream': 'f5fffa',
    'mistyrose': 'ffe4e1',
    'moccasin': 'ffe4b5',
    'navajowhite': 'ffdead',
    'navy': '000080',
    'oldlace': 'fdf5e6',
    'olive': '808000',
    'olivedrab': '6b8e23',
    'orange': 'ffa500',
    'orangered': 'ff4500',
    'orchid': 'da70d6',
    'palegoldenrod': 'eee8aa',
    'palegreen': '98fb98',
    'paleturquoise': 'afeeee',
    'palevioletred': 'd87093',
    'papayawhip': 'ffefd5',
    'peachpuff': 'ffdab9',
    'peru': 'cd853f',
    'pink': 'ffc0cb',
    'plum': 'dda0dd',
    'powderblue': 'b0e0e6',
    'purple': '800080',
    'rebeccapurple': '663399',
    'red': 'ff0000',
    'rosybrown': 'bc8f8f',
    'royalblue': '4169e1',
    'saddlebrown': '8b4513',
    'salmon': 'fa8072',
    'sandybrown': 'f4a460',
    'seagreen': '2e8b57',
    'seashell': 'fff5ee',
    'sienna': 'a0522d',
    'silver': 'c0c0c0',
    'skyblue': '87ceeb',
    'slateblue': '6a5acd',
    'slategray': '708090',
    'snow': 'fffafa',
    'springgreen': '00ff7f',
    'steelblue': '4682b4',
    'tan': 'd2b48c',
    'teal': '008080',
    'thistle': 'd8bfd8',
    'tomato': 'ff6347',
    'turquoise': '40e0d0',
    'violet': 'ee82ee',
    'violetred': 'd02090',
    'wheat': 'f5deb3',
    'white': 'ffffff',
    'whitesmoke': 'f5f5f5',
    'yellow': 'ffff00',
    'yellowgreen': '9acd32'
};

type COLOR_TYPE = { r: number, g: number, b: number, a?: number, hsv?: { h: number, s: number, v: number }, hsl?: { h: number, s: number, l: number } };

// array of color definition objects
const standardColorTypes = [
    {
        re: /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
        process: function (colorString: RegExpExecArray): COLOR_TYPE {
            return {
                r: parseInt(colorString[1], 10),
                g: parseInt(colorString[2], 10),
                b: parseInt(colorString[3], 10)
            };
        }
    },
    {
        re: /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*\.*\d+)\)$/,
        process: function (colorString: RegExpExecArray): COLOR_TYPE {
            return {
                r: parseInt(colorString[1], 10),
                g: parseInt(colorString[2], 10),
                b: parseInt(colorString[3], 10),
                a: parseFloat(colorString[4]),
            };
        }
    },
    {
        re: /^#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$/,
        process: function (colorString: RegExpExecArray): COLOR_TYPE {
            return {
                r: parseInt(colorString[1], 16),
                g: parseInt(colorString[2], 16),
                b: parseInt(colorString[3], 16)
            };
        }
    },
    {
        re: /^#([a-f0-9]{1})([a-f0-9]{1})([a-f0-9]{1})$/,
        process: function (colorString: RegExpExecArray): COLOR_TYPE {
            return {
                r: parseInt(colorString[1] + colorString[1], 16),
                g: parseInt(colorString[2] + colorString[2], 16),
                b: parseInt(colorString[3] + colorString[3], 16)
            };
        }
    },
    {
        re: /^hsv\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
        process: function (colorString: RegExpExecArray): COLOR_TYPE {
            const h = parseInt(colorString[1], 10);
            const s = parseInt(colorString[2], 10);
            const v = parseInt(colorString[3], 10);
            const rgb = hsvToRgb(h, s, v);

            return {
                r: rgb[0],
                g: rgb[1],
                b: rgb[2],
                a: 1,
                hsv: { h, s, v },
            };
        }
    },
    {
        re: /^hsl\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
        process: function (colorString: RegExpExecArray): COLOR_TYPE {
            const h = parseInt(colorString[1], 10);
            const s = parseInt(colorString[2], 10);
            const l = parseInt(colorString[3], 10);
            const rgb = hslToRgb(h, s, l);

            return {
                r: rgb[0],
                g: rgb[1],
                b: rgb[2],
                a: 1,
                hsl: { h, s, l },
            };
        }
    }
];

const _round = Math.round;

export class Color {
    baseColor: string;
    colorIsInvalid: boolean;
    r: number;
    g: number;
    b: number;
    a: number;
    hsv: { h: number, s: number, v: number };
    hsl: { h: number, s: number, l: number };

    constructor(value?: string) {
        this.baseColor = value;
        let color: COLOR_TYPE | undefined = undefined;
        if (value) {
            let colorStr = String(value).toLowerCase().replace(/ /g, '');
            colorStr = standardColorNames[colorStr] ? '#' + standardColorNames[colorStr] : colorStr;
            color = parseColor(colorStr);
        }
        if (!color) {
            this.colorIsInvalid = true;
        }

        color = color || { r: 0, g: 0, b: 0 };
        this.r = normalize(color.r);
        this.g = normalize(color.g);
        this.b = normalize(color.b);
        this.a = normalize(color.a, 1, 1);
        if (color.hsv) {
            this.hsv = { h: color.hsv.h, s: color.hsv.s, v: color.hsv.v };
        } else {
            this.hsv = toHsvFromRgb(this.r, this.g, this.b);
        }
        if (color.hsl) {
            this.hsl = { h: color.hsl.h, s: color.hsl.s, l: color.hsl.l };
        } else {
            this.hsl = toHslFromRgb(this.r, this.g, this.b);
        }
    }


    highlight(step?: number) {
        step = step || 10;
        return this.alter(step).toHex();

    }

    darken(step?: number) {
        step = step || 10;
        return this.alter(-step).toHex();
    }

    alter(step: number) {
        const result = new Color();
        result.r = normalize(this.r + step);
        result.g = normalize(this.g + step);
        result.b = normalize(this.b + step);
        return result;
    }

    blend(blendColor: string | Color, opacity: number) {
        const other = blendColor instanceof Color ? blendColor : new Color(blendColor);
        const result = new Color();
        result.r = normalize(_round(this.r * (1 - opacity) + other.r * opacity));
        result.g = normalize(_round(this.g * (1 - opacity) + other.g * opacity));
        result.b = normalize(_round(this.b * (1 - opacity) + other.b * opacity));
        return result;
    }

    toHex() {
        return toHexFromRgb(this.r, this.g, this.b);
    }

    getPureColor() {
        const rgb = hsvToRgb(this.hsv.h, 100, 100);
        return new Color('rgb(' + rgb.join(',') + ')');
    }

    static isValidHex(hex: string) {
        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex);
    }

    static isValidRGB(r: number, g: number, b: number) {
        if (!isIntegerBetweenMinAndMax(r) || !isIntegerBetweenMinAndMax(g) || !isIntegerBetweenMinAndMax(b)) {
            return false;
        }
        return true;
    }

    static isValidAlpha(a: number) {
        if (isNaN(a) || a < 0 || a > 1 || typeof a !== 'number') {
            return false;
        }
        return true;
    }


    static fromHSL(hsl: { h: number, s: number, l: number }) {
        const color = new Color();
        const { h, s, l } = hsl;
        const [r, g, b] = hslToRgb(h, s, l);

        color.r = r;
        color.g = g;
        color.b = b;
        color.hsl = { h, s, l };
        color.hsv = toHsvFromRgb(r, g, b);

        return color;
    }

    public lighten(amount: number) {
        const { h, s } = this.hsl;
        const l = normalize(this.hsl.l + amount, 0, 100);
        return Color.fromHSL({ h, s, l });
    }
}
function parseColor(color: string): COLOR_TYPE {
    if (color === 'transparent') {
        return { r: 0, g: 0, b: 0, a: 0 };
    }

    let i = 0;
    const ii = standardColorTypes.length;
    let str;
    for (; i < ii; ++i) {
        str = standardColorTypes[i].re.exec(color);
        if (str) {
            return standardColorTypes[i].process(str);
        }
    }
    return null;
}

function normalize(colorComponent: number, def?: number, max?: number) {
    def = def || 0;
    max = max || 255;
    return (colorComponent < 0 || isNaN(colorComponent)) ? def : ((colorComponent > max) ? max : colorComponent);
}

function toHexFromRgb(r: number, g: number, b: number) {
    return '#' + (0X01000000 | (r << 16) | (g << 8) | b).toString(16).slice(1);
}

function toHsvFromRgb(r: number, g: number, b: number) {
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;
    let H;
    let S;
    let V = max;
    S = (max === 0 ? 0 : 1 - min / max);

    if (max === min) {
        H = 0;
    } else {
        switch (max) {
            case r:
                H = 60 * ((g - b) / delta);
                if (g < b) {
                    H = H + 360;
                }
                break;
            case g:
                H = 60 * ((b - r) / delta) + 120;
                break;
            case b:
                H = 60 * ((r - g) / delta) + 240;
                break;
        }
    }

    S *= 100;
    V *= 100 / 255;

    return {
        h: Math.round(H),
        s: Math.round(S),
        v: Math.round(V)
    };
}

function hsvToRgb(h: number, s: number, v: number) {
    const index = Math.floor((h % 360) / 60);
    const vMin = ((100 - s) * v) / 100;
    const a = (v - vMin) * ((h % 60) / 60);
    const vInc = vMin + a;
    const vDec = v - a;

    let r;
    let g;
    let b;

    switch (index) {
        /* eslint-disable no-multi-spaces */
        case 0: r = v; g = vInc; b = vMin; break;
        case 1: r = vDec; g = v; b = vMin; break;
        case 2: r = vMin; g = v; b = vInc; break;
        case 3: r = vMin; g = vDec; b = v; break;
        case 4: r = vInc; g = vMin; b = v; break;
        case 5: r = v; g = vMin; b = vDec; break;
        /* eslint-enable no-multi-spaces */
    }

    return [Math.round(r * 2.55), Math.round(g * 2.55), Math.round(b * 2.55)];
}

function calculateHue(r: number, g: number, b: number, delta: number) {
    const max = Math.max(r, g, b);
    switch (max) {
        case r:
            return (g - b) / delta + (g < b ? 6 : 0);
        case g:
            return (b - r) / delta + 2;
        case b:
            return (r - g) / delta + 4;
        default:
            throw new Error('NYI');
    }
}

function toHslFromRgb(r: number, g: number, b: number) {
    r = convertTo01Bounds(r, 255);
    g = convertTo01Bounds(g, 255);
    b = convertTo01Bounds(b, 255);

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const maxMinSum = max + min;
    let h;
    let s;
    const l = maxMinSum / 2;

    if (max === min) {
        h = s = 0;
    } else {
        const delta = max - min;

        if (l > 0.5) {
            s = delta / (2 - maxMinSum);
        } else {
            s = delta / maxMinSum;
        }

        h = calculateHue(r, g, b, delta);
        h /= 6;
    }

    return { h: _round(h * 360), s: _round(s * 100), l: _round(l * 100) };
}

function makeColorTint(colorPart: 'r' | 'g' | 'b', h: number) {
    let colorTint = h;
    if (colorPart === 'r') {
        colorTint = h + 1 / 3;
    }
    if (colorPart === 'b') {
        colorTint = h - 1 / 3;
    }

    return colorTint;
}

function modifyColorTint(colorTint: number) {
    if (colorTint < 0) {
        colorTint += 1;
    }
    if (colorTint > 1) {
        colorTint -= 1;
    }

    return colorTint;
}

function hueToRgb(p: number, q: number, colorTint: number) {
    colorTint = modifyColorTint(colorTint);
    if (colorTint < 1 / 6) {
        return p + (q - p) * 6 * colorTint;
    }
    if (colorTint < 1 / 2) {
        return q;
    }
    if (colorTint < 2 / 3) {
        return p + (q - p) * (2 / 3 - colorTint) * 6;
    }
    return p;
}

function hslToRgb(h: number, s: number, l: number) {
    let r;
    let g;
    let b;

    h = convertTo01Bounds(h, 360);
    s = convertTo01Bounds(s, 100);
    l = convertTo01Bounds(l, 100);

    if (s === 0) {
        r = g = b = l;
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hueToRgb(p, q, makeColorTint('r', h));
        g = hueToRgb(p, q, makeColorTint('g', h));
        b = hueToRgb(p, q, makeColorTint('b', h));
    }

    return [_round(r * 255), _round(g * 255), _round(b * 255)];
}

function convertTo01Bounds(n: number, max: number) {
    n = Math.min(max, Math.max(0, n));
    if ((Math.abs(n - max) < 0.000001)) {
        return 1;
    }
    return (n % max) / max;
}

function isIntegerBetweenMinAndMax(number: number, min?: number, max?: number) {
    min = min || 0;
    max = max || 255;

    if (number % 1 !== 0 ||
        number < min ||
        number > max ||
        typeof number !== 'number' ||
        isNaN(number)) {
        return false;
    }

    return true;
}



import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir } from '../../../debug';
import { AS, datagrid, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import { BRtoLF, joinStrings, LFtoBR, update_observable } from '../../../helper';
import { formatMessage } from '../../../i18n/data';
import * as API from '../../../its-itembank-api.g';
import { ExaminationOrderDefineScore, ExaminationOrderStatus, ExamOrderSubjectStatus, IUpsertExamOrderInput, IUpsertExamOrderSubjectEntryInput, IUpsertExamOrderSubjectInput, LevelOfSecrecy } from '../../../its-itembank-api.g';
import { confirmYesNo } from '../../../modal';
import * as ITEM_AUTHOR from '../../author/itemedit/route';
import { legacyPushPull, queueApiCall } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import { routeManager } from '../../routes';
import * as ITEM_TRANSLATOR from '../../translator/translateexamorder/route';
import { UIAction } from '../../uiAction';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './examorderedit.html.g';
import { IParams, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_examorderedit_data']>>;
type ITEM = Q['examOrder']['byId']['items'][0];
type SUBJECT = Q['examOrder']['byId']['subject'];
type ENTRY = SUBJECT['entries'][0];

interface IMatrixRow {
    objective: string;
    displayText: string;
    isLeaf: boolean;
    cat: { [key: string]: number };
}


export class ViewModel extends DxWidget {

    private readonly _matrixRows: IMatrixRow[] = [];
    private readonly _items: ITEM[] = [];

    public readonly isValid = ko.pureComputed(() => !this.validationMessageHtml());
    public readonly validationMessageHtml = ko.observable('');
    public readonly isEditable = ko.pureComputed(() => {

        const doc = this.config().examOrder.byId;
        if (!doc) {
            return undefined;
        }
        if (doc.status !== ExaminationOrderStatus.Populating) {
            return false;
        }
        return doc.subject.status === ExamOrderSubjectStatus.Open;
    });

    private readonly dataLabel = ko.pureComputed(() => i18next.t(['ui.subjectcoordinator.examorderedit.DATA']));

    private readonly itemsLabel = ko.pureComputed(() => i18next.t(['ui.subjectcoordinator.examorderedit.ITEMS']));

    private readonly cttLabel = ko.pureComputed(() => i18next.t(['ui.subjectcoordinator.examorderedit.CTT_POOL']));


    public tabs() {
        const items: DevExpress.ui.dxTabPanelItemTemplate[] = [];
        const retVal: DevExpress.ui.dxTabPanel.Properties = {
            dataSource: items,
            deferRendering: true,

        };
        retVal.onSelectionChanged = e => {
            if (!e.addedItems || !e.addedItems.length) {
                this.selectedLabel('');
            } else {
                const item: DevExpress.ui.dxTabPanelItemTemplate = e.addedItems[0];
                this.selectedLabel(item.title);
            }
        };
        retVal.onInitialized = e => {
            const tab = e.component.option('selectedItem');
            dir(tab);
        };
        items.push({
            title: this.dataLabel(),
            visible: true,
            template: () => {
                const x = $(`
                    <div data-bind="dxForm:formDataOptions"></div>
                    `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: this.itemsLabel(),
            visible: true,
            template: () => {
                const x = $(`
                    <div>
                        <div data-bind="dxForm:formItemsOptions"></div>
                        <div data-bind="dxDataGrid:itemGridOptions()"></div>
                    </div>
                    `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });
        items.push({
            title: this.cttLabel(),
            visible: true,
            template: () => {
                //the "if:isPreview" is necessary to force a reload of the item presenter. interactive mode does not aggressivly reload the data, as items are locked in an examination
                const x = $(`
                <div>
                    <div data-bind="dxDataGrid:gridOptions()"></div>
                    <div>
                        <span data-bind="text:totalScoreText"></span>
                        <span data-bind="text: targetScore"></span>
                        <span data-bind="text:definedScoreText"></span>
                        <span data-bind="text: actualScore"></span>
                        <span data-bind="text:totalAssignedScoreText"></span>
                        <span data-bind="text: assignedScore"></span>
                    </div>
                </div>
                `);
                ko.applyBindings(this, x.get(0));
                return x;
            },
        });

        const item = items.find(x => ko.unwrap(x.visible));
        retVal.selectedItem = item;
        this.selectedLabel(item.title);
        return retVal;
    }
    private readonly isTranslator = ko.observable(false);
    private readonly selectedLabel = ko.observable('');


    public readonly actionGotoTranslator = new UIAction(undefined, async () => {
        const link = ITEM_TRANSLATOR.FACTORY.href({
            examorder: this.params.currentRoute.widgetParams.examOrderDocRefId,
        });
        await routeManager.navigateToHREF(link);
    });

    public readonly actionUpdateToLatestVersion = new UIAction(undefined, async () => {
        await legacyPushPull(() => ServerConnection.api.ui_subjectcoordinator_examorderedit_updateitems({
            examOrderDocRef: this.params.currentRoute.widgetParams.examOrderDocRefId,
            subjectDocRef: this.params.currentRoute.widgetParams.subjectDocRefId,
        }));
    });

    public readonly totalScoreText = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.examorderedit.TOTAL_SCORE']);
    });
    public readonly definedScoreText = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.examorderedit.DEFINED_SCORE']);
    });
    public readonly totalAssignedScoreText = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.examorderedit.TOTAL_SCORE_OF_ASSIGNED_ITEMS']);
    });

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                editing: {
                    allowAdding: false,
                    allowDeleting: false,
                    allowUpdating: true,
                    mode: 'row',
                },

                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: false
                },
                filterRow: {
                    visible: false,
                },
                headerFilter: {
                    visible: false,
                },

                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'objective',
                        update: async (objectiveId, values) => {
                            const row = this._matrixRows.find(x => x.objective === objectiveId);
                            if (values.cat) {
                                Object.assign(row.cat, values.cat);
                            }
                            const addEntries: IUpsertExamOrderSubjectEntryInput[] = [];
                            for (const categoryId of Object.keys(values.cat)) {
                                addEntries.push({
                                    objectiveId,
                                    categoryId,
                                    targetScore: values.cat[categoryId] || 0
                                });
                            }
                            const params: IUpsertExamOrderInput = {
                                examOrderDocRef: this.params.currentRoute.widgetParams.examOrderDocRefId,
                                examOrderId: this.config().examOrder.byId.examinationOrderId,
                                addSubjects: [{
                                    subjectDocRef: this.params.currentRoute.widgetParams.subjectDocRefId,
                                    addEntries
                                }]
                            };
                            await ServerConnection.api.ui_subjectcoordinator_examorderedit_update({
                                params
                            });
                            this.updateComputed();
                        },
                        load: async () => {
                            return this._matrixRows;
                        },
                        byKey: async key => {
                            return this._matrixRows.find(x => x.objective === key);
                        }
                    })
                }
            }
        });
        retVal.onEditorPreparing = e => {
            const data: IMatrixRow = e.row.data;
            if (data.isLeaf) {
                return;
            }
            e.cancel = true;
        };
        const examOrderId = this.config().examOrder.byId.examinationOrderId;
        const exportFilename = formatMessage(i18next.t(['ui.subjectcoordinator.examorderedit.EXAM_ORDER_EXAMORDERID']), { examOrderId });
        retVal.export = {
            enabled: true,
            excelFilterEnabled: true,
            excelWrapTextEnabled: true,

            fileName: exportFilename
        };
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.ID']),
            dataField: 'objective',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
            allowEditing: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.DESCRIPTION']),
            dataField: 'displayText',
            dataType: 'string',
            allowEditing: false,
        });
        for (const cat of this.config().examOrder.byId.subject.dimensionCategories) {
            retVal.columns.push({
                caption: `${cat.taxon} ${cat.name.value}`,
                dataField: `cat.${cat.taxon}`,
                dataType: 'number',
                allowEditing: this.isEditable(),
                calculateDisplayValue: e => {
                    const data: IMatrixRow = e;
                    const entry = this.entryMapping.get(`${data.objective}/${cat.taxon}`);
                    const targetScore = data.cat[cat.taxon] || 0;
                    const assignedScore = entry && entry.assignedScore || 0;
                    if (!targetScore && !assignedScore) {
                        return '';
                    }
                    return i18next.t(['ui.subjectcoordinator.examorderedit.ASSIGNEDSCORE_OF_TARGETSCORE'], { assignedScore, targetScore });
                }
            });
        }


        return retVal;
    });

    private readonly itemsStore = new CustomStore({
        loadMode: 'raw',
        key: 'id',
        load: async () => {
            return this._items;
        },
        byKey: async key => {
            return this._items.find(x => x.id === key);
        },
        update: async (id, values) => {

        },
        remove: async id => {
            const item = this._items.find(x => x.id === id);
            if (!item) {
                return;
            }
            const examOrderDocRef = this.config().examOrder.byId.docReferenceId;
            const examOrderId = this.config().examOrder.byId.examinationOrderId;
            await ServerConnection.api.ui_subjectcoordinator_examorderedit_update({
                params: {
                    examOrderDocRef,
                    examOrderId,
                    removeItems: [item.item.docReferenceId]
                }
            });
            this._items.splice(this._items.indexOf(item), 1);
        }
    });

    public readonly actionKeepSelectedItems = new UIAction(undefined, async () => {
        const itemGrid = this.itemGrid();
        if (!itemGrid) {
            return;
        }
        if (!await confirmYesNo(i18next.t(['ui.subjectcoordinator.examorderedit.DO_YOU_WANT_TO_REMOVE_ALL_ITEMS_THAT_ARE_NOT_SELECTED']))) {
            return;
        }
        for (const row of itemGrid.getVisibleRows()) {
            if (row.rowType !== 'data') {
                continue;
            }
            if (row.isSelected) {
                continue;
            }
            await itemGrid.getDataSource().store().remove(row.key);
        }
        itemGrid.refresh();
    });
    public readonly actionRemoveSelectedItems = new UIAction(undefined, async () => {
        const itemGrid = this.itemGrid();
        if (!itemGrid) {
            return;
        }
        if (!await confirmYesNo(i18next.t(['ui.subjectcoordinator.examorderedit.DO_YOU_WANT_TO_REMOVE_ALL_ITEMS_THAT_ARE_SELECTED']))) {
            return;
        }
        const keys = itemGrid.getSelectedRowKeys();
        for (const key of keys) {
            await itemGrid.getDataSource().store().remove(key);
        }
        itemGrid.refresh();
    });

    public readonly actionItemAuthor = new UIAction<ITEM>(undefined, async (e, args) => {
        const link = ITEM_AUTHOR.FACTORY.href({
            subjectDocRefId: this.params.currentRoute.widgetParams.subjectDocRefId,
            itemDocRefId: args.item.docReferenceId,
        });
        await routeManager.navigateToHREF(link);
    });

    public readonly actionItemMoveUp = new UIAction<string>(undefined, async (e, args) => {
        const itemGrid = this.itemGrid();
        if (!itemGrid) {
            return;
        }

        const examOrderId = this.params.currentRoute.widgetParams.examOrderDocRefId;
        const item = this._items.find(x => x.id === args);
        if (!item) {
            return;
        }
        const otherIdx = Math.max(...this._items.filter(x => x.index < item.index).map(x => x.index));
        const other = this._items.find(x => x.index === otherIdx);
        if (!other) {
            return;
        }
        other.index = item.index;
        item.index = otherIdx;
        await ServerConnection.api.ui_subjectcoordinator_examorderedit_update({
            params: {
                examOrderId,
                orderItems: [item.item.itemId, other.item.itemId],
            }
        });
        await itemGrid.refresh(true);
    });
    public readonly actionItemMoveDown = new UIAction<string>(undefined, async (e, args) => {
        const itemGrid = this.itemGrid();
        if (!itemGrid) {
            return;
        }
        const examOrderId = this.params.currentRoute.widgetParams.examOrderDocRefId;
        const item = this._items.find(x => x.id === args);
        if (!item) {
            return;
        }
        const otherIdx = Math.min(...this._items.filter(x => x.index > item.index).map(x => x.index));
        const other = this._items.find(x => x.index === otherIdx);
        if (!other) {
            return;
        }
        other.index = item.index;
        item.index = otherIdx;
        await ServerConnection.api.ui_subjectcoordinator_examorderedit_update({
            params: {
                examOrderId,
                orderItems: [item.item.itemId, other.item.itemId],
            }
        });
        await itemGrid.refresh(true);
    });


    public readonly selectionInfo = ko.pureComputed(() => {
        const itemCount = this.selectedItemCount();
        const points = this.selectedItemPoints();
        return i18n.t(['ui.subjectcoordinator.examorderedit.SELECTED_ITEMS_ITEMCOUNT_SELECTED_POINTS_POINTS'], { points, itemCount });
    });

    private readonly selectedItemCount = ko.observable(0);
    private readonly selectedItemPoints = ko.observable(0);

    private readonly itemGrid = ko.observable<DevExpress.ui.dxDataGrid>();
    public readonly itemGridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            discriminator: 'items_v2',
            gridVar: this.itemGrid,
            widget: this,
            config: {
                wordWrapEnabled: true,
                allowColumnResizing: true,
                editing: {
                    allowAdding: false,
                    allowDeleting: true,
                    allowUpdating: false,
                    mode: 'row',
                },

                onSelectionChanged: (e) => {
                    const data: ITEM[] = e.selectedRowsData || [];
                    this.selectedItemCount(data.length);
                    this.selectedItemPoints(data.map(x => x.item.accumulatedScoreMax).reduce((a, b) => a + b, 0));
                },
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true
                },
                filterRow: {
                    visible: false,
                },
                headerFilter: {
                    visible: false,
                },

                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'multiple',
                    showCheckBoxesMode: 'always',
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    sort: 'index',
                    store: this.itemsStore,
                },
                export: {
                    enabled: true,
                    fileName: `examorder_${this.params.currentRoute.widgetParams.examOrderDocRefId}`,
                    customizeExcelCell: o => {
                        if (!o.gridCell) {
                            return;
                        }
                        if (o.gridCell.rowType !== 'data') {
                            return;
                        }
                        if (typeof o.value === 'string') {
                            o.gridCell.value = BRtoLF(o.value);
                        }
                        o.wrapTextEnabled = true;
                    }
                },
                pager: {
                    visible: true,
                    allowedPageSizes: [40, 80, 120],
                    showPageSizeSelector: true,
                    showInfo: true,
                    showNavigationButtons: true,
                },
            }
        });


        retVal.onToolbarPreparing = e => {
            e.toolbarOptions.items.unshift({
                location: 'center',
                widget: '',
                template: () => {
                    const retVal = $(`<div data-bind="html: selectionInfo"></div>`);
                    ko.applyBindings(this, retVal.get(0));
                    return retVal;
                }
            });
            e.toolbarOptions.items.unshift({
                location: 'before',
                widget: 'dxButton',
                options: {
                    text: i18n.t(['ui.subjectcoordinator.examorderedit.TRANSLATOR']),
                    onClick: this.actionGotoTranslator.click,
                    visible: this.isTranslator,
                },
            });
            e.toolbarOptions.items.unshift({
                location: 'before',
                widget: 'dxButton',
                options: {
                    icon: 'chevronup',
                    text: i18next.t(['ui.subjectcoordinator.examorderedit.UPDATE_ITEMS']),
                    onClick: this.actionUpdateToLatestVersion.click
                }
            });
            e.toolbarOptions.items.unshift({
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    text: i18next.t(['ui.subjectcoordinator.examorderedit.KEEP_SELECTED_ITEMS']),
                    onClick: this.actionKeepSelectedItems.click
                }),
            });
            // e.toolbarOptions.items.unshift({
            //     location: 'before',
            //     widget: 'dxButton',
            //     options: AS<DevExpress.ui.dxButton.Properties>({
            //         icon: 'fas fa-minus-circle',
            //         text: i18next.t(['ui.subjectcoordinator.examorderedit.REMOVE_SELECTED_ITEMS']),
            //         onClick: this.actionRemoveSelectedItems.click
            //     }),
            // });

        };

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.NR']),
            dataField: 'index',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'number',
            allowEditing: true,
            width: '40',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.ITEM_ID']),
            dataField: 'item.itemId',
            width: 120,
            dataType: 'string',
            allowEditing: false,
        });
        // retVal.columns.push({
        //     caption: i18next.t(['ui.subjectcoordinator.examorderedit.TITLE']),
        //     dataType: 'string',
        //     name: 'objective',
        //     width: 100,
        // });

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.CATEGORY']),
            dataType: 'string',
            name: 'objective',
            width: 120,
            calculateDisplayValue: x => {
                const queryRow: ITEM = x;
                return joinStrings(', ', ...queryRow.item.accumulatedObjectives.map(x => x.taxon).sort());
            },
            calculateCellValue: x => {
                const queryRow: ITEM = x;
                return queryRow.item.accumulatedObjectives.map(x => x.taxon);
            },
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.OBJECTIVE']),
            name: 'category',
            dataType: 'string',
            width: 120,

            calculateCellValue: (x: ITEM) => {
                return x.item.accumulatedCategories.map(x => x.taxon);
            },

            calculateDisplayValue: (x: ITEM) => {
                return x.item.accumulatedCategories.map(x => x.taxon).join(', ');
            }
        });


        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.MAX_SCORE']),
            dataField: 'item.accumulatedScoreMax',
            dataType: 'number',
            allowEditing: false,
            width: 100,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.VISIBLE_TEXT']),
            dataField: 'item.visibleText',
            dataType: 'string',
            allowEditing: false,
            name: 'visibleText',

            customizeText: (cellInfo) => {
                return LFtoBR(cellInfo.valueText);
            },

            encodeHtml: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.ITEM_TYPE']),
            dataField: 'item.itemType.title.value',
            dataType: 'string',
            width: 100,
            allowEditing: false,
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.CHANCE']),
            dataField: 'chance',
            format: {
                type: 'percent',
                precision: 2,
            },
            dataType: 'number',
            width: 100,
            allowEditing: false,
        });
        retVal.columns.push({
            type: 'buttons',
            width: 100,
            buttons: [
                {
                    icon: 'fas fa-arrow-up',
                    onClick: e => this.actionItemMoveUp.click(e.row.key),
                },
                {
                    icon: 'fas fa-arrow-down',
                    onClick: e => this.actionItemMoveDown.click(e.row.key),
                },
                {
                    text: i18n.t(['ui.subjectcoordinator.examorderedit.AUTHOR']),
                    onClick: e => this.actionItemAuthor.click(e.row.data),
                    visible: e => {
                        const data: ITEM = e.row && e.row.data;
                        return data && data.item.workflow.editableByYou;
                    },
                },
                'delete',
            ]
        });
        return retVal;
    });

    public readonly languages = ko.pureComputed(() => {
        const examOrder = this.config();
        if (!examOrder) {
            return undefined;
        }
        return examOrder.examOrder.byId.subject.localizations.map(x => x.id);
    });

    public readonly addItemsToSubject = new UIAction(undefined, async () => {
        const addItems = (this.addItems() || '').split(/[\s,]/g).filter(x => !!x);
        if (!addItems.length) {
            return;
        }
        const examOrderDocRef = this.params.currentRoute.widgetParams.examOrderDocRefId;
        const examOrderId = this.config().examOrder.byId.examinationOrderId;
        await legacyPushPull(async () => {
            await ServerConnection.api.ui_subjectcoordinator_examorderedit_update({
                params: {
                    examOrderDocRef,
                    examOrderId,
                    addItems
                }
            });
        });
        this.addItems('');
    });
    public readonly addItemsToCTTTable = new UIAction(undefined, async () => {
        if (!await confirmYesNo(i18n.t(['ui.subjectcoordinator.examorderedit.CONFIRMADDITEMSTOCTTTABLE']))) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.ui_subjectcoordinator_examorderedit_createctt({
                examOrderDocRef: this.params.currentRoute.widgetParams.examOrderDocRefId,
                subjectDocRef: this.params.currentRoute.widgetParams.subjectDocRefId,
            });
        });
    });

    public readonly addItems = ko.observable('');


    public readonly itemsCountAssigned = ko.observable(0);
    // tslint:disable-next-line:max-func-body-length
    public readonly formItemsOptions = ko.pureComputed(() => {
        const doc = this.config();
        if (!doc) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: {
                itemsCountAssigned: this.itemsCountAssigned,
                itemScore: this.itemScore,
                targetScore: this.targetScore,
                addItems: this.addItems,
            },
            colCount: 1,
            width: '100%',
            items: []
        };
        const g3: DevExpress.ui.dxFormGroupItem = {
            itemType: 'group',
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.ITEMS']),
            colCount: 3,
            items: []

        };
        retVal.items.push(g3);

        g3.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'targetScore',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.SCORE_FOR_SUBJECT'])
            },
            editorOptions: {
                readOnly: this.config().examOrder.byId.defineScore === ExaminationOrderDefineScore.Superuser,
                min: 1
            }
        }));
        g3.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'itemScore',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.TOTAL_SCORE_OF_ALL_ASSIGNED_ITEMS'])
            },
            editorOptions: {
                readOnly: true,
                min: 0
            }
        }));
        g3.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'itemsCountAssigned',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.AMOUNT_OF_ITEMS'])
            },
            editorType: 'dxNumberBox',
            editorOptions: {
                readOnly: true
            }
        }));

        g3.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            itemType: 'simple',
            editorType: 'dxTextBox',
            colSpan: 2,
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.ADD_ITEMS'])
            },
            dataField: 'addItems',
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                onEnterKey: e => {
                    e.component.blur();
                    this.addItemsToSubject.click();
                }
            })
        }));

        g3.items.push(AS<DevExpress.ui.dxForm.ButtonItem>({
            itemType: 'button',
            buttonOptions: {
                onClick: this.addItemsToSubject.click,
                text: i18next.t(['ui.subjectcoordinator.examorderedit.ADD_ITEMS']),
            }
        }));

        g3.items.push(AS<DevExpress.ui.dxForm.ButtonItem>({
            itemType: 'button',
            buttonOptions: {
                onClick: this.addItemsToCTTTable.click,
                text: i18next.t(['ui.subjectcoordinator.examorderedit.ADD_TO_TABLE'])

            }
        }));

        retVal.onFieldDataChanged = e => {
            const params: IUpsertExamOrderInput = {
                examOrderDocRef: this.params.currentRoute.widgetParams.examOrderDocRefId,
                examOrderId: this.config().examOrder.byId.examinationOrderId,
                addSubjects: []
            };
            const subject: IUpsertExamOrderSubjectInput = {
                subjectDocRef: this.params.currentRoute.widgetParams.subjectDocRefId,

            };
            const examOrder = this.config().examOrder.byId;
            const s = examOrder.subject;

            params.addSubjects.push(subject);
            switch (e.dataField) {
                case 'targetScore':
                    const value: number = ko.unwrap(e.value);
                    if (s.score === value) {
                        return;
                    }
                    subject.score = value;
                    break;
                default:
                    return;
            }
            queueApiCall(`${WIDGET_NAME}/${params.examOrderDocRef}/${e.dataField}`, () => ServerConnection.api.ui_subjectcoordinator_examorderedit_update({
                params
            }), true);
        };
        return retVal;
    });
    public readonly formDataOptions = ko.pureComputed(() => {
        const doc = this.config();
        if (!doc) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: {
                examOrder: doc.examOrder.byId,
                scStatus: this.SCstatusString,
                itemsCountAssigned: this.itemsCountAssigned,
                languages: this.languages,
                targetScore: this.targetScore
            },
            colCount: 1,
            width: '100%',
            items: []
        };
        const g1: DevExpress.ui.dxFormGroupItem = {
            itemType: 'group',
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.EXAM_ORDER_DATA']),
            colCount: 3,
            items: [],
        };
        retVal.items.push(g1);

        g1.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'examOrder.title',
            editorOptions: {
                readOnly: true
            },
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.NAME_OF_EXAM_ORDER'])
            }
        }));
        g1.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'examOrder.examinationOrderId',
            editorOptions: {
                readOnly: true
            },
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.SHORTCUT'])
            }
        }));
        g1.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'examOrder._id',
            editorOptions: {
                readOnly: true
            },
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.INTERNAL_ID'])
            }
        }));

        const g2: DevExpress.ui.dxFormGroupItem = {
            itemType: 'group',
            caption: i18next.t(['ui.subjectcoordinator.examorderedit.DETAILS']),
            colCount: 3,
            items: []

        };
        retVal.items.push(g2);

        if (this.config().config.levelOfSecrecyEnabled) {
            g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
                dataField: 'examOrder.levelOfSecrecy',
                editorType: 'dxLookup',
                editorOptions: {
                    readOnly: true,
                    searchEnabled: false,
                    showPopupTitle: false,
                    dataSource: [
                        {
                            id: LevelOfSecrecy.Topsecret,
                            caption: i18next.t(TRANSLATIONS.LevelOfSecrecyString['topsecret']),
                        },
                        {
                            id: LevelOfSecrecy.Secret,
                            caption: i18next.t(TRANSLATIONS.LevelOfSecrecyString['secret']),
                        },
                        {
                            id: LevelOfSecrecy.Restricted,
                            caption: i18next.t(TRANSLATIONS.LevelOfSecrecyString['restricted']),
                        },
                        {
                            id: LevelOfSecrecy.Public,
                            caption: i18next.t(TRANSLATIONS.LevelOfSecrecyString['public']),
                        }
                    ],
                    displayExpr: 'caption',
                    valueExpr: 'id'
                },
                label: {
                    text: i18next.t(['ui.subjectcoordinator.examorderedit.LEVEL_OF_SECRECY'])
                }
            }));
        }

        g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.PROFILE'])
            },
            editorOptions: {
                readOnly: true,
            },
            dataField: 'examOrder.profile.title',
        }));
        g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.AUDIENCE'])
            },
            editorOptions: {
                readOnly: true,
            },
            dataField: 'examOrder.audience.audienceId',
        }));
        g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'languages',
            editorType: 'dxTagBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.LANGUAGES'])
            },
            editorOptions: {
                readOnly: true,
                dataSource: this.allExamLanguages() || [],
                displayExpr: 'display',
                valueExpr: 'id',
                applyValueMode: 'useButtons',
                showSelectionControls: false
            }
        }));
        g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'examOrder.examDate',
            //editorType: 'dxDateBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.DATE_OF_EXAM'])
            },

            editorOptions: {
                readOnly: true
            }
        }));
        g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'examOrder.examTime',
            //editorType: 'dxDateBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.START_TIME'])
            },

            editorOptions: {
                readOnly: true
            }
        }));
        g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'examOrder.maxTimeAllowed',
            //editorType: 'dxDateBox',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.DURATION_OF_EXAM'])
            },
            editorOptions: {
                readOnly: true
            }
        }));
        g2.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'scStatus',
            label: {
                text: i18next.t(['ui.subjectcoordinator.examorderedit.STATUS'])
            },
            editorOptions: {
                readOnly: true,
            },
        }));
        return retVal;
    });
    public readonly SCstatusString = ko.pureComputed<string>(() => {
        const doc = this.config();
        if (!doc) {
            return undefined;
        }
        const status = doc.examOrder.byId.subject.status;
        switch (status) {
            case ExamOrderSubjectStatus.Open:
                return i18next.t(TRANSLATIONS.SubjectCoordinatorStatusString['open']);
            case ExamOrderSubjectStatus.Finished:
                return i18next.t(TRANSLATIONS.SubjectCoordinatorStatusString['finished']);
        }
    });

    public readonly allExamLanguages = ko.pureComputed(() => {
        const c = this.config();
        if (!c) {
            return [];
        }
        return c.config.examLanguages.map(x => {
            return {
                id: x.id,
                display: x.name.value
            };
        });
    });

    public readonly selectedSubjectDocRefId = ko.pureComputed(() => {
        return this.params.currentRoute.widgetParams.subjectDocRefId;
    });
    private readonly invCounter = ko.observable(0);
    private updateComputed() {
        this.invCounter(this.invCounter() + 1);
    }
    public readonly targetScore = ko.observable(0);

    public readonly itemScore = ko.observable(0);
    public readonly assignedScore = ko.pureComputed(() => {
        const doc = this.config();
        if (!doc) {
            return undefined;
        }
        let retVal = 0;
        for (const obj of doc.examOrder.byId.subject.entries) {
            retVal += obj.assignedScore;
        }
        return retVal;
    });

    public readonly actualScore = ko.pureComputed(() => {
        const doc = this.config();
        if (!doc) {
            return undefined;
        }
        const dummy = this.invCounter();

        let retVal = 0;
        for (const obj of this._matrixRows) {
            for (const catId of Object.keys(obj.cat)) {
                const score = obj.cat[catId] || 0;
                retVal += score;
            }
        }
        return retVal;
    });

    public readonly categoryDepth = ko.pureComputed(() => {
        const doc = this.config();
        if (!doc) {
            return undefined;
        }
        return doc.examOrder.byId.categoryDepth;
    });

    public readonly syllabusDepth = ko.pureComputed(() => {
        const doc = this.config();
        if (!doc) {
            return undefined;
        }
        return doc.examOrder.byId.syllabusDepth;
    });

    constructor(readonly params: IParams) {
        super();
    }
    public readonly loaded = ko.observable(false);

    private readonly config = ko.observable<Q>();

    private readonly entryMapping = new Map<string, ENTRY>();

    public async OnRefresh() {
        const config = await ServerConnection.api.ui_subjectcoordinator_examorderedit_data({
            examOrderDocRef: this.params.currentRoute.widgetParams.examOrderDocRefId,
            subjectDocRef: this.params.currentRoute.widgetParams.subjectDocRefId,
        });
        this.config(config);
        const examOrder = config.examOrder.byId;

        const transLang = config.whoAmI?.user?.translator;
        if (transLang?.__typename === 'LocalizationPrivilege') {
            const langs = new Set(transLang && transLang.localizations.map(x => x.localizationId));
            this.isTranslator(!!examOrder.subject.subject.isTranslator && examOrder.subject.localizations.some(x => langs.has(x.localizationId)));
        } else {
            this.isTranslator(false);
        }
        const s = examOrder.subject;
        this.entryMapping.clear();
        for (const e of s.entries) {
            this.entryMapping.set(`${e.objectiveId}/${e.categoryId}`, e);
        }
        const newRows: IMatrixRow[] = [];
        for (const obj of s.dimensionSyllabus) {
            const newRow: IMatrixRow = {
                objective: obj.taxon,
                displayText: obj.name.value,
                isLeaf: obj.isLeaf,
                cat: {},
            };
            for (const cat of s.dimensionCategories) {
                const entry = this.entryMapping.get(`${obj.taxon}/${cat.taxon}`);
                newRow.cat[cat.taxon] = entry && entry.targetScore;
            }
            newRows.push(newRow);
        }
        this._matrixRows.splice(0, this._matrixRows.length, ...newRows);
        this._items.splice(0, this._items.length, ...examOrder.items);
        update_observable(this.targetScore, s.score);
        update_observable(this.itemsCountAssigned, this._items.length);
        update_observable(this.itemScore, this._items.map(x => x.item.accumulatedScoreMax).reduce((a, b) => a + b, 0));
        if (s.validToFinish.ok) {
            this.validationMessageHtml('');
        } else {
            this.validationMessageHtml(s.validToFinish.validationMessages.map(x => x.value).join('<br />'));
        }
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }


    public isExamOrderReadyForCopy() {
        return true;
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

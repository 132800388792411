import { AtoZ, GqlDS } from '../../../dxHelper/graphQLDataSource';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../../ui/RestAPI';

type Q = Awaited<ReturnType<API.Sdk['surveycomparision_edit_data']>>;

export type SCALE = Q['SurveyComparisionEdit']['get']['scale'][0];


export class ScaleDS extends GqlDS<SCALE, 'index'> {
    constructor({ itemId, fetch }: {
        itemId: string;
        fetch: () => SCALE[];
    }) {
        super({
            fetch,
            key: 'index',
            indexGenerator: AtoZ,
            onNewRow: row => {
                row.hint = { value: `Hint ${row.index}` };
            },
            remove: async (key) => {
                await ServerConnection.api.surveycomparision_edit_update({
                    params: {
                        itemId,
                        removeScale: [key]
                    }
                });
                return DONE;
            },
            upsert: async (key, values) => {
                await ServerConnection.api.surveycomparision_edit_update({
                    params: {
                        itemId,
                        upsertScale: [{
                            index: key,
                            hint: values.hint && { xnone: values.hint.value },
                            value: values.value,
                            invalid: values.invalid,
                        }]
                    }
                });
                return DONE;
            }
        });
    }
}

import { DONEENUM } from '../../helper';
import * as API from '../../its-itembank-api.g';
export type INIT_BASE = Awaited<ReturnType<API.Sdk['item_model_init']>>;
export type INIT = INIT_BASE['examSession']['items'][0];

export interface ISupportsLocalization {
    localizationId?: string
}
export function translate(tgt: { localizations: Array<{ localizationId: string, value: string }> }, settings: ISupportsLocalization) {
    if (!tgt) {
        return '';
    }
    const localizationId = settings && settings.localizationId || '';
    if (!localizationId) {
        return tgt.localizations[0].value;
    }
    const v = tgt.localizations.find(x => x.localizationId === localizationId);
    if (v) {
        return v.value;
    }
    throw new Error(`Stupid Programmer: No translation for ${localizationId} loaded`);
}

export abstract class ItemDataModel {
    abstract fromJS(data: INIT['data']): void;
    abstract IsInteractionStarted(): boolean;
    abstract IsInteractionComplete(): boolean;
    abstract reset(): Promise<DONEENUM.DONE>;
    abstract getItemState(): API.IUpdateItemStateInput;

    IsInitialData() {
        return !this.IsInteractionStarted();
    }
}

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, datagrid, refreshDx } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import * as i18next from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['singlechoiceimage_edit_data']>>;
const WIDGET_NAME = 'itemdefinition-kosovo-singlechoiceimage-edit';
type ANSWER = Q['SingleChoiceImageEdit']['get']['answers'][0];
type ATTACHMENT = Q['documents']['get']['attachments'][0];

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');
    public readonly imgWidth = ko.observable(0);
    public readonly imgHeight = ko.observable(0);

    private readonly _answers: ANSWER[] = [];
    private attachmentsStore: DevExpress.data.CustomStore;
    private readonly _attachments: ATTACHMENT[] = [];

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                imgWidth: this.imgWidth,
                imgHeight: this.imgHeight,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imgWidth',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.WIDTH_OF_IMAGES_TILES']),
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 1000,
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imgHeight',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.HEIGHT_OF_IMAGES_TILES']),
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 1000,
            }),
        }));
        return retVal;
    });

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.singlechoiceimage_edit_data({
            itemId: this.itemId
        });
        const d = q.SingleChoiceImageEdit.get;
        this._answers.splice(0, this._answers.length, ...d.answers);
        this.question(d.question.value);
        this.header(d.header.value);
        this.imgWidth(d.imgWidth);
        this.imgHeight(d.imgHeight);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        await refreshDx(this);
    }


    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: {
                        type: 'array',
                        key: 'index',
                        data: this._answers
                    }
                },
                editing: {
                    allowAdding: this.canEdit,
                    allowDeleting: this.canEdit,
                    allowUpdating: this.canEdit,
                    mode: 'row',
                },
            }
        });
        retVal.columns.push(
            {
                dataField: 'index',
                caption: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.ID']),
                width: 40,
                allowEditing: false
            });
        retVal.columns.push(
            {
                dataField: 'correct',
                caption: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.CORRECT']),
                allowEditing: true,
                dataType: 'boolean',
                width: 80,
            });

        retVal.columns.push(
            {
                dataField: 'image.name',
                caption: i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.IMAGE']),
                allowEditing: true,
                lookup: {
                    dataSource: {
                        store: this.attachmentsStore,
                    },
                    valueExpr: 'name',
                    displayExpr: 'name'
                },
            });
        retVal.onInitNewRow = e => {
            let index = '';
            for (let i = 0; i < 26; ++i) {
                index = String.fromCharCode(65 + i);
                if (!this._answers.some(x => x.index === index)) {
                    break;
                }
            }
            if (!index) {
                index = 'A';
            }
            e.data = {
                index,
                correct: false,
                image: {
                    name: ''
                }
            };
        };
        retVal.onRowRemoving = async (e) => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.singlechoiceimage_edit_update({
                    params: {
                        itemId: this.itemId,
                        removeAnsers: [e.key]
                    }
                }));
            })();
        };
        retVal.onRowInserting = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.singlechoiceimage_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.data.index,
                            image: e.data.image && e.data.image.name,
                            correct: e.data.correct
                        }]
                    }
                }));
            })();
        };

        retVal.onRowUpdating = e => {
            e.cancel = (async () => {
                await legacyPushPull(() => ServerConnection.api.singlechoiceimage_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertAnswers: [{
                            index: e.key,
                            image: e.newData.image && e.newData.image.name,
                            correct: e.newData.correct
                        }]
                    }
                }));
            })();
        };
        return retVal;
    });

    public async initialize() {

        this.attachmentsStore = new CustomStore({
            loadMode: 'raw',
            key: 'name',
            byKey: async key => {
                if (!this._attachments) {
                    return undefined;
                }
                return this._attachments.find(x => x.name === key);
            },
            load: async (options) => {
                return this._attachments;
            }
        });

        await super.initialize();
        await this.OnRefresh();



        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async v => {
            await ServerConnection.api.singlechoiceimage_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.singlechoiceimage_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.imgWidth, `${WIDGET_NAME}/${this.itemId}/imgWidth`, async v => {
            await ServerConnection.api.singlechoiceimage_edit_update({
                params: {
                    itemId: this.itemId,
                    imgWidth: v
                }
            });
            return DONE;
        });
        this.onChange(this.imgHeight, `${WIDGET_NAME}/${this.itemId}/imgHeight`, async v => {
            await ServerConnection.api.singlechoiceimage_edit_update({
                params: {
                    itemId: this.itemId,
                    imgHeight: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }


    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoiceimage.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as i18n from 'i18next';
export { init, Resource } from 'i18next';

export class TranslateKey {
    constructor(readonly key: string[]) { }
}
export function KEY(key: string[]) {
    return new TranslateKey(key);
}

let loaded = false;
export function loadComplete() {
    loaded = true;
}

export function t(key: string[] | TranslateKey, replace?: { [key: string]: string | number }): string {
    if (!loaded) {
        throw new Error(`Translation subsystem not initialized. Are you trying to use i18n.t outside a function? Don't to that!`);
    }
    if (key instanceof TranslateKey) {
        return i18n.t(key.key, { replace });
    }
    return i18n.t(key, { replace });
}

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { log } from '../../../../debug';
import { AS, datagrid, refreshDx } from '../../../../dx_helper';
import * as TRANSLATIONS from '../../../../enums/translations';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { AuthoringStatusEnum } from '../../../../its-itembank-api.g';
import { legacyPushPull } from '../../../docmanager';
import { ServerConnection } from '../../../RestAPI';
import * as ROUTES from '../../../routes';
import { UIAction } from '../../../uiAction';
import * as WIDGET_NEWITEM from '../../newitem/route';
import { deleteItem } from '../operations';
import * as WIDGET_ITEMEDIT from '../route';
import * as i18next from './../../../../i18n/i18n';
import { AlternationsStore } from './AlternationsStore';
import { CategoriesStore } from './CategoriesStore';
import { D, MyFormBuilder } from './MyFormBuilder';
import { ObjectiveStore } from './ObjectiveStore';
import { SyllabusStore } from './SyllabusStore';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_author_itemedit_itemmetadatablock_get']>>;
type LOCALIZATION = Q['config']['localizations'][0];
type ALL_AUDIENCE = Q['item']['get']['metaData']['subject']['audiences'][0];
type CHILD_ITEM = Q['item']['get']['childItems'][0];

export const WIDGET_NAME = 'ui-author-itemedit-itemmetadatablock';

export interface IParams {
    itemId: string;
}

export class ViewModel extends DxWidget {

    public readonly allAudiences: ALL_AUDIENCE[] = [];

    public readonly itemEdit = new UIAction<string>(undefined, async (e, itemDocRefId) => {
        await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMEDIT.FACTORY.href({
            subjectDocRefId: this.subjectDocRefId(),
            itemDocRefId: itemDocRefId
        }));
    });

    public readonly moveDownAction = new UIAction<CHILD_ITEM>(undefined, async (e, args) => {
        const containedItems = this._descendentItems.map(x => x.item.docReferenceId);
        if (args.index >= (containedItems.length - 1)) {
            return;
        }
        containedItems.splice(args.index + 1, 0, ...containedItems.splice(args.index, 1));
        await legacyPushPull(() => ServerConnection.api.ui_author_itemedit_itemmetadatablock_update({
            params: {
                itemId: this.params.itemId,
                descendantItemOrder: containedItems
            },
        }));
    });

    public readonly deleteAction = new UIAction<CHILD_ITEM>(undefined, async (e, args) => {
        await deleteItem(args.item.docReferenceId);
    });

    public readonly moveUpAction = new UIAction<CHILD_ITEM>(undefined, async (e, args) => {
        if (args.index === 0) {
            return;
        }
        const containedItems = this._descendentItems.map(x => x.item.docReferenceId);
        containedItems.splice(args.index - 1, 0, ...containedItems.splice(args.index, 1));
        await legacyPushPull(() => ServerConnection.api.ui_author_itemedit_itemmetadatablock_update({
            params: {
                itemId: this.params.itemId,
                descendantItemOrder: containedItems,
            }
        }));
    });

    public readonly actionNewSubItem = new UIAction(undefined, async (e) => {
        const subjectDocRefId = this.subjectDocRefId();
        await ROUTES.routeManager.navigateToHREF(WIDGET_NEWITEM.FACTORY.href({
            subjectDocRefId,
            parentItemDocRefId: this.params.itemId
        }));
    });

    public isReadOnly: boolean;

    public readonly subjectId = ko.observable('');

    public readonly subjectDocRefId = ko.observable('');
    public readonly loaded = ko.observable(false);

    public readonly isSubItem = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.syllabusStore = new SyllabusStore(params.itemId);
        this.categoriesStore = new CategoriesStore(params.itemId);
        this.alternationsStore = new AlternationsStore(params.itemId);
        this.objectiveStore = new ObjectiveStore(params.itemId);
    }

    public readonly hasObjectives = ko.observable(false);

    private lastResult: Q;

    public async OnRefresh() {
        await super.OnRefresh();

        const x = await ServerConnection.api.ui_author_itemedit_itemmetadatablock_get({
            itemId: this.params.itemId
        });
        const raw = x.item.get;
        this.lastResult = x;

        const updatedAudiences = raw.metaData.audience.map(x => x.docReferenceId).sort();

        const updatedAlternations = raw.metaData.alternations.map(x => x.taxon).sort();

        this.hasObjectives(raw.itemType.supportsObjectives);
        const objectiveId = raw.itemType.supportsObjectives && [(raw.metaData.objectives.length && raw.metaData.objectives[0].objectiveId || '?')];
        const objectiveScore = raw.itemType.supportsObjectives && (raw.metaData.objectives.length && raw.metaData.objectives[0].scoreMax || 0);

        this._languages.splice(0, this._languages.length, ...x.config.localizations);

        const item: D = Object.assign(raw, {
            translatedAuthoringStatus: i18next.t(TRANSLATIONS.AuthoringStatus[raw.workflow.authoringStatus]),
            itemTypeDisplay: `${raw.itemType.title.value} (${raw.itemType.uuid})`,
            audiences: updatedAudiences,
            alternations: updatedAlternations,
            objectiveId,
            objectiveScore,
        });
        Object.assign(this.formData, item);
        this.isReadOnly = !item.workflow.editableByYou;
        log(`${WIDGET_NAME} ${this.params.itemId}:  ${this.isReadOnly ? 'readonly' : 'read/write'}`);
        this.subjectId(item && item.metaData.subject && item.metaData.subject.subjectId || '');
        this.subjectDocRefId(item && item.metaData.subject.docReferenceId);
        this.isContainer(item.itemType.supportsSubItems);
        this.displayCopiedFrom(!!(item.metaData.copyFrom && item.metaData.copyFrom.itemId));
        this.displayRevisionFrom(!!(item.metaData.revisionFrom && item.metaData.revisionFrom.itemId));
        this.isSubItem(item.parentItemId.length > 0);

        this.displayNoteForAuthor(!!item.workflow.noteForAuthor);

        this.allAudiences.splice(0, this.allAudiences.length, ...item.metaData.subject.audiences);
        this._descendentItems.splice(0, this._descendentItems.length, ...item.childItems);

        await refreshDx(this);

    }
    private readonly _descendentItems: CHILD_ITEM[] = [];

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        this.form.formOptions.readOnly = this.isReadOnly;
        this.form.formOptions.onFieldDataChanged = (x: any) => {
            const itemId = this.params.itemId;
            void this.updateDatabase(x.dataField, x.value);
        };
    }

    private async updateDatabase(field: string, value: any) {
        const itemId = this.params.itemId;
        const up = this.form.fields.get(field);

        if (up) {
            const params = up(value);
            if (params.objectiveId) {
                params.setObjectives = [{
                    objectiveId: params.objectiveId,
                    score: this.formData.objectiveScore
                }];
                params.objectiveId = undefined;
            }
            if (typeof params.objectiveScore === 'number') {
                params.setObjectives = [{
                    objectiveId: this.formData.objectiveId && this.formData.objectiveId[0],
                    score: this.formData.objectiveScore
                }];
                params.objectiveScore = undefined;
            }
            await ServerConnection.api.ui_author_itemedit_itemmetadatablock_update({
                params: Object.assign({
                    itemId,
                }, params)
            });
            return DONE;
        }
        return DONE;
    }

    public async initialize() {
        await super.initialize();
        await this.syllabusStore.load();
        await this.categoriesStore.load();
        await this.alternationsStore.load();

        this.languagesStore = new CustomStore({
            loadMode: 'raw',
            key: 'id',
            byKey: async key => {
                if (!this._languages) {
                    return undefined;
                }
                return this._languages.find(x => x.id === key);
            },
            load: async (options) => {
                return this._languages;
            }
        });

        await this.OnRefresh();
        this.initFormOptions();
        this.loaded(true);
    }

    public readonly WIDGET_NAME = WIDGET_NAME;
    public readonly form = new MyFormBuilder(this);

    public readonly isContainer = ko.observable(false);

    public readonly subItemEdit = new UIAction<string>(undefined, async (e, itemDocRefId) => {
        await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMEDIT.FACTORY.href({
            subjectDocRefId: this.subjectDocRefId(),
            itemDocRefId,
        }));
    });

    public readonly displayCopiedFrom = ko.observable(false);

    public readonly goToCopiedFrom = new UIAction(undefined, async () => {
        const copyItemId = this.formData.metaData.copyFrom && this.formData.metaData.copyFrom.itemId;
        if (!copyItemId) {
            return;
        }
        return this.itemEdit.intent(copyItemId);
    });
    public readonly displayNoteForAuthor = ko.observable(false);
    public readonly displayRevisionFrom = ko.observable(false);
    public readonly goToRevisionFrom = new UIAction(undefined, async () => {
        const reviseFrom = this.formData.metaData.revisionFrom && this.formData.metaData.revisionFrom.itemId;
        if (!reviseFrom) {
            return;
        }
        return this.itemEdit.intent(reviseFrom);
    });

    public readonly subItemGrid = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._descendentItems;
                        },
                        byKey: async key => {
                            return this._descendentItems.find(x => x.id === key);
                        }
                    })
                }
            }
        });

        retVal.onToolbarPreparing = e => {
            e.toolbarOptions.items.unshift({
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    icon: 'add',
                    onClick: this.actionNewSubItem.click,
                    disabled: this.isReadOnly,
                })
            });
        };
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.NR']),
            width: 20,
            dataField: 'index'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.ITEM_ID']),
            dataField: 'item.itemId'
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.TITLE']),
            dataField: 'item.metaData.title',
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.ITEM_TYPE']),
            dataField: 'item.itemType.title.value',
            dataType: 'string',

        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.STATUS']),
            dataField: 'item.workflow.authoringStatus',
            dataType: 'string',
            calculateDisplayValue: (e: CHILD_ITEM): string => {
                return i18next.t(TRANSLATIONS.AuthoringStatus[e.item.workflow.authoringStatus || AuthoringStatusEnum.Open]);
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.ACTIONS']),

            columns: [
                {
                    allowEditing: false,
                    allowExporting: false,
                    allowFiltering: false,
                    width: '40',
                    cellTemplate: (elem, info) => {
                        const retVal = $('<div />');
                        retVal.dxButton({
                            icon: 'fas fa-arrow-up',
                            visible: info.data.index > 0,
                            disabled: this.isReadOnly,
                            onClick: () => {
                                this.moveUpAction.click(info.data);
                            }
                        });
                        return retVal;
                    }
                },
                {
                    allowEditing: false,
                    allowExporting: false,
                    allowFiltering: false,
                    width: '40',
                    cellTemplate: (elem, info) => {
                        const retVal = $('<div />');
                        retVal.dxButton({
                            icon: 'fas fa-arrow-down',
                            visible: info.data.index < (this._descendentItems.length - 1),
                            disabled: this.isReadOnly,
                            onClick: () => {
                                this.moveDownAction.click(info.data);
                            }
                        });
                        return retVal;
                    }
                },
                {
                    allowEditing: false,
                    allowExporting: false,
                    allowFiltering: false,
                    width: '40',
                    cellTemplate: (elem, info) => {
                        const retVal = $('<div />');
                        retVal.dxButton({
                            icon: 'far fa-folder-open',
                            onClick: async () => {
                                await ROUTES.routeManager.navigateToHREF(WIDGET_ITEMEDIT.FACTORY.href({
                                    subjectDocRefId: this.subjectDocRefId(),
                                    itemDocRefId: info.data.item.itemId,
                                }));
                            }
                        });
                        return retVal;
                    }
                },
                {
                    allowEditing: false,
                    allowExporting: false,
                    allowFiltering: false,
                    width: '40',
                    cellTemplate: (elem, info) => {
                        const retVal = $('<div />');
                        retVal.dxButton({
                            icon: 'fas fa-trash',
                            disabled: this.isReadOnly,
                            onClick: () => {
                                this.deleteAction.click(info.data);
                            }
                        });

                        return retVal;
                    }
                }
            ],
        });
        return retVal;
    });

    private readonly _languages: LOCALIZATION[] = [];
    public languagesStore: DevExpress.data.CustomStore;

    public readonly objectiveStore: ObjectiveStore;
    public readonly syllabusStore: SyllabusStore;
    public readonly alternationsStore: AlternationsStore;
    public readonly categoriesStore: CategoriesStore;

    public readonly formData: D = <D>{};
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import { EDIT_SESSION } from '../../../magic';
import { ParticipationContext } from './ParticipationContext';

class ParticipationServiceImpl {
    private readonly ctx = new Map<string, ParticipationContext>();
    public GetSession(sessionId: string) {
        if (sessionId === EDIT_SESSION) {
            return undefined;
        }
        const existing = this.ctx.get(sessionId);
        if (!existing) {
            throw new Error(`ParticipationContext for session ${sessionId} does not exist yet`);
        }
        return existing;
    }
    public async InitSession(options: {
        session: string,
        stayInSection: boolean,
        hasOverviewPage: boolean,
    }) {
        const existing = this.ctx.get(options.session);
        if (existing) {
            return existing;
        }
        const newCtx = new ParticipationContext();
        await newCtx.SetSession(options);
        this.ctx.set(options.session, newCtx);
        return newCtx;
    }
}

export const ParticipationService = new ParticipationServiceImpl();

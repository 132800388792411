import * as ko from 'knockout';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';

export const WIDGET_NAME = 'ui-superuser-profileedit';

export interface IParamsInner {
    profile: string;
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class ProfileEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;

    public readonly title = ko.pureComputed(() => {
        return this._title;
    });


    constructor(readonly widgetParams: IParamsInner, readonly _title: string) {
    }
}


export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/superuser/profile/:id',
    toParams: map => ({
        profile: map.get('id'),
    }),
    fromParams: (p, map) => {
        map.set('id', p.profile);
    },
    create: async p => {
        const d = await ServerConnection.api.ui_superuser_profileedit_route({
            profile: p.profile
        });

        const route = new ProfileEditRoute(p, d.TestDefinitionProfile.byId.title);
        return route;
    },
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { InApplicationCombo_SubItem_StatusEnum, IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'InApplicationCombo_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

type SUBITEM = DATA['subItems'][0];

export class InAppComboData_SubItem {
    constructor(readonly parent: InAppComboData, data: SUBITEM, readonly index: number) {
        this.itemType = data.itemType;
        this.itemDocRefId = data.itemDocRefId;
        this.fromJS(data);
    }
    public fromJS(data: SUBITEM) {
        this.status(data.status);
    }
    public readonly itemDocRefId: string;
    public readonly itemType: string;
    public readonly status = ko.observable<InApplicationCombo_SubItem_StatusEnum>();

}

export class InAppComboData extends ItemDataModel {
    public readonly subItems: InAppComboData_SubItem[];
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.instruction, {}),
            attachments: this.meta.attachments,
        });
        this.subItems = data.subItems.map((d, index) => new InAppComboData_SubItem(this, d, index));
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        this.selectedSubItemId(data.selectedSubItem);
        for (let i = 0; i < data.subItems.length; ++i) {
            this.subItems[i].fromJS(data.subItems[i]);
        }
    }
    public readonly headerText: string;
    public readonly questionHTML: string;
    public readonly selectedSubItemId = ko.observable('');
    public async selectSubItem(itemId: string) {
        if (this.selectedSubItemId() === itemId) {
            return;
        }
        this.selectedSubItemId(itemId);
    }

    public IsInteractionStarted() {
        return this.subItems.some(x => this.meta.session.getItemData(x.itemDocRefId).IsInteractionStarted());
    }
    public IsInteractionComplete() {
        return this.subItems.every(x => this.meta.session.getItemData(x.itemDocRefId).IsInteractionComplete());
    }
    public async reset() {
        for (const subItem of this.subItems) {
            const subItemData = this.meta.session.getItemData(subItem.itemDocRefId);
            await subItemData.reset();
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            InApplicationCombo: {
                selectedSubItem: this.selectedSubItemId(),
            }
        };
        return retVal;
    }

}

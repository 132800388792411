import * as ko from 'knockout';
import type { ItemOverviewBodyFieldTemplate } from './ItemOverviewBodyFieldTemplate';
import { ItemFieldPart } from './ItemFieldPart';
import { Item } from "./Item";


export class ItemOverviewBodyField {
    constructor(readonly template: ItemOverviewBodyFieldTemplate, readonly item: Item) {
    }

    public readonly cssClass = ko.pureComputed(() => {
        return this.template.cssClass;
    });

    public readonly parts = ko.pureComputed(() => {
        return this.template.parts.map(x => new ItemFieldPart(x, this));
    });
}

import * as ko from "knockout";
import { log } from "../../debug";
import * as query from "../../getquery";
import { update_observable } from "../../helper";
import { DisplayProgress, DisplayTime } from "../../its-itembank-api.g";
import { colorService } from "../colorservice";
import { ItemHostBase } from "../ItemHostBase";
import * as RESULTSCREEN_DEFAULT from "../resultscreens/default/widget";
import { ParticipationContext } from "../service/participation/ParticipationContext";
import { ParticipationService } from "../service/participation/service";
import * as i18n from "./../../i18n/i18n";
import * as i18next from "./../../i18n/i18n";
import { htmlString } from "./participate.html.g";

const WIDGET_NAME = "ui-participate";
let IDX = 0;

export class App extends ItemHostBase {
  public readonly id = `${WIDGET_NAME}#${IDX++}`;

  // The order IS important - since the participationContext will be used afterwards, it must be defined first
  public readonly participationContext = ko.observable<ParticipationContext>();
  public readonly itemPresenterParams = ko.observable();
  public readonly sessionDocRefId = ko.observable<string>();

  public readonly displayProgressSetting = this.toCtx((ctx) => {
    const dt =
      ctx.initData().examSession.examInfo.executionPage.displayProgress;
    switch (dt) {
      case DisplayProgress.Remaining:
      case DisplayProgress.No:
        return dt;
      default:
        return DisplayProgress.Passed;
    }
  });

  private readonly diplayTime = this.toCtx((ctx) => {
    const dt = ctx.initData().examSession.examInfo.executionPage.displayTime;
    switch (dt) {
      case DisplayTime.No:
      case DisplayTime.Passed:
        return dt;
      case DisplayTime.Default:
      case DisplayTime.Remaining:
      default:
        return DisplayTime.Remaining;
    }
  });
  public readonly currentItemDocRefId = this.toCtx((ctx) => ctx.currentItem());
  public readonly currentItemType = this.toCtx((ctx) => ctx.currentItemType());
  public readonly currentItemIndex = this.toCtx((ctx) =>
    ctx.currentItemIndex()
  );
  public readonly isStartPage = this.toCtx(
    (ctx) => ctx.isStartPage(),
    "isStartPage"
  );
  public readonly isExecutionPage = this.toCtx(
    (ctx) => ctx.isExecutionPage(),
    "isExecutionPage"
  );
  public readonly isPausePage = this.toCtx(
    (ctx) => ctx.isPausePage(),
    "isPausePage"
  );
  public readonly isAbortedPage = this.toCtx(
    (ctx) => ctx.isAbortedPage(),
    "isAbortedPage"
  );
  public readonly isFinishPage = this.toCtx(
    (ctx) => ctx.isFinishPage(),
    "isFinishPage"
  );
  private readonly timePercent = this.toCtx((ctx) => {
    switch (this.diplayTime()) {
      case DisplayTime.Remaining:
        return ctx.tickTock.timeleftPercent();
      case DisplayTime.Passed:
        return ctx.tickTock.timePassedPercent();
      default:
        return undefined;
    }
  }, "timePercent");
  public readonly isAvailable = this.toCtx(
    (ctx) => ctx.isAvailable(),
    "isAvailable"
  );
  public readonly showOverview = this.toCtx(
    (ctx) => ctx.showOverview(),
    "showOverview"
  );
  public readonly loadPanelVisible = this.toCtx(
    (ctx) => ctx.loadPanelVisible(),
    "loadPanelVisible"
  );
  public readonly focusManagerParams = this.toCtx(
    (ctx) => ctx.focusManagerParams(),
    "focusManagerParams"
  );
  public readonly submitButtonLabel = this.toCtx(
    (ctx) => ctx.submitButtonLabel(),
    "submitButtonLabel"
  );
  public readonly prevButtonLabel = this.toCtx(
    (ctx) => ctx.prevButtonLabel(),
    "prevButtonLabel"
  );
  public readonly nextButtonLabel = this.toCtx(
    (ctx) => ctx.nextButtonLabel(),
    "nextButtonLabel"
  );
  public readonly postponeButtonLabel = this.toCtx(
    (ctx) => ctx.postponeButtonLabel(),
    "postponeButtonLabel"
  );
  public readonly resetButtonLabel = this.toCtx(
    (ctx) => ctx.resetButtonLabel(),
    "resetButtonLabel"
  );
  public readonly overviewButtonLabel = this.toCtx(
    (ctx) => ctx.overviewButtonLabel(),
    "overviewButtonLabel"
  );
  public readonly minutesPassedOrRemaining = this.toCtx((ctx) => {
    switch (this.diplayTime()) {
      case DisplayTime.Passed:
        return ctx.tickTock.timePassedMinutes();
      case DisplayTime.Remaining:
        return ctx.tickTock.timeleftMinutes();
      case DisplayTime.No:
      default:
        return undefined;
    }
  }, "minutesLeft");
  public readonly currentSectionTitle = this.toCtx((ctx) =>
    ctx.currentSectionTitle()
  );
  public readonly currentScoreMax = this.toCtx((ctx) => ctx.currentScoreMax());
  public readonly currentSectionColor = this.toCtx((ctx) =>
    ctx.currentSectionColor()
  );
  public readonly currentSectionAlternateColor = this.toCtx((ctx) =>
    ctx.currentSectionAlternateColor()
  );
  public readonly showItemMaxScore = this.toCtx((ctx) =>
    ctx.showItemMaxScore()
  );
  public readonly questionText = ko.pureComputed(() => {
    return i18next.t(["ui.participate.QUESTION"]);
  });
  public readonly pointsText = ko.pureComputed(() => {
    return i18next.t(["ui.participate.POINT_S"]);
  });
  public readonly fullScoreMaxText = ko.pureComputed(() => {
    const max = this.currentScoreMax();
    let key: string[];
    if (max === 0) {
      key = ["ui.participate.POINTS_MAX_0"];
    } else if (max === 1) {
      key = ["ui.participate.POINTS_MAX_1"];
    } else {
      key = ["ui.participate.POINTS_MAX_N"];
    }
    const rawText =i18next.t(key, { points: max });
    return rawText.replace(' ', '\u00A0');
  });
  public readonly minutesPassedOrRemainingText = this.toCtx((ctx) => {
    const label =
      ctx.initData().examSession.examInfo.executionPage.timeLabel.value;
    if (label) {
      return label;
    }
    const dt = ctx.initData().examSession.examInfo.executionPage.displayTime;
    switch (dt) {
      case DisplayTime.No:
        return "";
      case DisplayTime.Passed:
        return i18n.t(["ui.participate.TIME_PASSED"]);
      case DisplayTime.Default:
      case DisplayTime.Remaining:
      default:
        return i18next.t(["ui.participate.MINUTES_LEFT"]);
    }
  });
  public readonly progressText = this.toCtx(
    (ctx) =>
      ctx.initData().examSession.examInfo.executionPage.progressLabel.value ||
      i18next.t(["ui.participate.PROGRESS"])
  );
  public readonly loadPanelMessage = ko.pureComputed(() => {
    return i18next.t(["ui.participate.LOADING"]);
  });

  public readonly progressPercent = this.toCtx((ctx) => {
    const { total: itemsTotal, pending: itemsPending } = ctx.examProgress();
    switch (this.displayProgressSetting()) {
      case DisplayProgress.Passed:
        return Math.round(((itemsTotal - itemsPending) * 100) / itemsTotal);
      case DisplayProgress.Remaining:
        return Math.round((itemsPending * 100) / itemsTotal);
      default:
        return 100;
    }
  });

  public readonly circleProgressDegree = ko.pureComputed(() => {
    const p = Math.round((this.progressPercent() * 360) / 100);
    return `rotate(${p}deg)`;
  });

  public readonly circleProgressCSS = ko.pureComputed(() => {
    const percent = this.progressPercent();
    return percent > 50
      ? "progress-pie-chart completion-percentage gt-50"
      : "progress-pie-chart completion-percentage ";
  });

  public readonly circleTimeDegree = ko.pureComputed(() => {
    const p = Math.round((this.timePercent() * 360) / 100);
    return `rotate(${p}deg)`;
  });

  public readonly circleTimeCSS = ko.pureComputed(() => {
    const remainingTimePercent = this.timePercent();
    return remainingTimePercent > 50
      ? "progress-pie-chart completion-percentage gt-50"
      : "progress-pie-chart completion-percentage ";
  });

  public readonly style = ko.pureComputed(() => {
    const backgroundColor = colorService.getColor(this.currentSectionColor());
    return {
      backgroundColor,
    };
  });

  public readonly itemBackgroundStyle = ko.pureComputed(() => {
    const backgroundColor = colorService.getColor(
      this.currentSectionAlternateColor()
    );
    return {
      backgroundColor,
    };
  });

  public readonly previousButtonCSS = this.toCtx((ctx) => {
    const css = [];
    css.push(`SubmitButton`);
    if (ctx.canSubmit()) {
      css.push("green");
      css.push("white-text");
    } else {
      css.push("SubmitButtonDisabled");
    }
    return css.join(" ");
  }, "previousButtonCSS");

  public readonly nextButtonCSS = this.toCtx((ctx) => {
    const css = [];
    css.push(`SubmitButton`);
    if (ctx.canSubmit()) {
      css.push("green");
      css.push("white-text");
    } else {
      css.push("SubmitButtonDisabled");
    }
    return css.join(" ");
  }, "nextButtonCSS");

  public readonly submitButtonCSS = this.toCtx((ctx) => {
    const css = [];
    css.push(`SubmitButton`);
    if (ctx.canSubmit()) {
      css.push("green");
      css.push("white-text");
    } else {
      css.push("SubmitButtonDisabled");
    }
    return css.join(" ");
  }, "submitButtonCSS");

  public readonly postponeStyle = this.toCtx((ctx) => {
    if (ctx.isLastItem()) {
      return {
        pointerEvents: "none",
        cursor: "default",
        color: "#c0c0c0",
        backgroundColor: "#ffffff",
      };
    } else {
      return {
        color: "black",
        backgroundColor: "white",
      };
    }
  }, "postponeStyle");

  public readonly resetStyle = ko.pureComputed(() => {
    return {
      color: "black",
      backgroundColor: "white",
    };
  });

  private toCtx<T>(cb: (ctx: ParticipationContext) => T, debug_name?: string) {
    const obs = ko.pureComputed(() => {
      const ctx = this.participationContext();
      if (!ctx) {
        return undefined;
      }
      return cb(ctx);
    });
    this.disposables.addDiposable(
      obs.subscribe((x) => {
        log(`${this.id}: ${debug_name || "observable"} changed to '${x}'`);
      })
    );
    return obs;
  }

  public readonly isFocusMode = ko.pureComputed(() => {
    return this.focusManager.isFocused();
  });

  private async gremlins() {
    const ctx = this.participationContext();
    if (!ctx) {
      return true;
    }
    if (!this.loaded()) {
      return true;
    }
    if (this.isExecutionPage()) {
      log(`${this.id}: Gremlin: submit it!`);
      if (!ctx.showOverview()) {
        if (ctx.hasNextButton()) {
          log(`${this.id}: Next item!`);
          await ctx.actionNext.invoke();
          return true;
        }
        if (ctx.hasSubmitButton()) {
          log(`${this.id}: Submit item!`);
          await ctx.actionSubmit.invoke();
          return true;
        }
      }
    }
    return false;
  }

  protected async onInitialize() {
    this.registerGremlin({
      name: `${WIDGET_NAME}`,
      priority: 1000,
      action: () => this.gremlins(),
    });

    await super.onInitialize();
    const session = query.params.get("session");
    if (!session) {
      throw new Error(i18next.t(["ui.participate.INVALID_QUERY_PARAMETERS"]));
    }
    this.sessionDocRefId(session);
    const ctx = await ParticipationService.InitSession({
      session,
      stayInSection: true,
      hasOverviewPage: true,
    });
    this.participationContext(ctx);
    const msg = i18next.t([
      "ui.participate.YOU_ARE_IN_THE_MIDDLE_OF_A_TEST_DO_YOU_WANT_TO_LEAVE_THE_TEST",
    ]);
    if (window.itsr3 && window.itsr3.closeMessage) {
      window.itsr3.closeMessage(msg);
    } else {
      window.addEventListener("beforeunload", (ev) => {
        if (ctx.isFinishPage()) {
          return undefined;
        }
        ev.preventDefault();
        ev.returnValue = msg;
        return msg;
      });
    }
    this.disposables.addDiposable(
      ctx.isFinishPage.subscribe((x) => {
        if (x) {
          if (window.itsr3 && window.itsr3.closeMessage) {
            log("disable close message");
            window.itsr3.closeMessage("");
          }
        }
      })
    );

    this.disposables.addDiposable(
      ctx.currentItem.subscribe((x) => {
        const ctx = this.participationContext();
        if (!ctx || !ctx.currentItem()) {
          update_observable(this.itemPresenterParams, undefined);
        } else {
          const p = {
            sessionId: this.sessionDocRefId(),
            item: ctx.currentItem(),
            itemType: this.currentItemType(),
            isTopItem: true,
            mode: "INTERACTIVE",
          };
          update_observable(this.itemPresenterParams, p);
        }
      })
    );
    this.appTitleSection(ctx.appTitleSection());
    this.disposables.addDiposable(
      ctx.appTitleSection.subscribe((x) => {
        log(`App Title Section changed to ${x}`);
        this.appTitleSection(x);
      })
    );
  }

  public readonly resultScreenParams = ko.pureComputed(() => {
    const retVal: RESULTSCREEN_DEFAULT.IParams = {
      closeWindow: this.hasCloseButton()
        ? async () => this.closeWindow()
        : undefined,
      sessionId: this.sessionDocRefId(),
      mode: "screen",
    };
    return retVal;
  });
}

export type IParams = undefined;

export function create(
  params: IParams,
  componentInfo: ko.components.ComponentInfo
) {
  const retVal = new App();
  retVal.DoInit({ WIDGET_NAME });
  return retVal;
}

ko.components.register(WIDGET_NAME, {
  viewModel: {
    createViewModel: create,
  },
  template: htmlString.replace(/@@@/g, WIDGET_NAME),
});

import { error, info, warn } from './debug';
import { BUILD_INFO } from './grow_version.g';

declare global {
    interface Window {
        __CONFIG?: {
            API_URL?: string;
            GROW_SUPPORTED_LOCALES?: string;
            GROW_DEFAULT_LOCALE?: string;
            GROW_TRANSLATE_URL?: string;
            FRAME_SETTINGS?: string;
        };
    }
}

export function GetTranslatePrefix() {
    const configJs = window.__CONFIG?.GROW_TRANSLATE_URL ?? '';
    if (configJs) {
        return configJs;
    }
    return `${BUILD_INFO.semVer}/i18n/`;
}

export function GetEnabledLanguages() {
    const configJs = window.__CONFIG?.GROW_SUPPORTED_LOCALES ?? '';
    const retVal = configJs.split(',').map(x => x.trim()).filter(x => !!x);
    if (!retVal.length) {
        //MESSL, 2022-02-04 - this list should be generated in build.sh based on i18n/ folder
        retVal.push('en', 'cs', 'de', 'el', 'en', 'es', 'fi', 'fr', 'it', 'ja', 'nl', 'pt', 'ru', 'sl', 'sv', 'zh');
    }
    return retVal;
}

export function GetDefaultLanguage() {
    const configJs = window.__CONFIG?.GROW_DEFAULT_LOCALE ?? '';
    const enabled = GetEnabledLanguages();
    if (configJs) {
        const found = enabled.find(x => x.toLowerCase() == configJs.toLowerCase());
        if (found) {
            return found;
        } else {
            warn(`Unable to find GROW_DEFAULT_LOCALE ${configJs} in GROW_SUPPORTED_LOCALES ${enabled.join(',')}`);
        }
    }
    return enabled[0];
}

function appendGraphQL(url: string) {
    if (!url) {
        return url;
    }
    if (url.endsWith('/graphql')) {
        return url;
    }
    return new URL('graphql', url).toString();
}
function baseUrl() {
    const pathname = window.location.pathname;
    const uiPos = pathname.lastIndexOf('/ui/');
    return window.location.origin + pathname.substring(0, uiPos + 1);
}

export const baseApiUrl = window.__CONFIG?.API_URL || baseUrl();
export const apiUrl = appendGraphQL(baseApiUrl);
if (!baseApiUrl || !apiUrl) {
    error(`window.__CONFIG.API_URL not set`);
}

info(`Sending API requests to ${apiUrl}`);

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import * as API from '../../../../its-itembank-api.g';
import { AS } from '../../../../dx_helper';
import * as HELPER from '../../../../helper';
import { DONE } from '../../../../helper';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { legacyPushPull, queueApiCall } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18n from './../../../../i18n/i18n';
//import * as ADDCIRCLE from './addcircle.mutation.graphql.g';
//import * as CONNECT from './connect.mutation.graphql.g';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';
type Q = Awaited<ReturnType<API.Sdk['matchinghotspot_edit_data']>>;

const WIDGET_NAME = 'itemdefinition-kosovo-matchinghotspot-edit';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

const SHAPES = [{
    id: 'circle',
    regEx: /^Circle\(x=([0-9]+)%,y=([0-9]+)%,r=([0-9]+)%\)$/i
}];

class Circle {
    public readonly connectId = ko.observable('');

    constructor(
        readonly model: ViewModel,
        readonly x: number,
        readonly y: number,
        readonly r: number,
        readonly id: string
    ) {
        this.isReadOnly = model.isReadOnly;
        this.canEdit = model.canEdit;
    }
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly circleClick = new UIAction(undefined, async () => {
        await this.model.circleClick(this);
    });
}

class Answer {
    constructor(readonly model: ViewModel, readonly answer: Q['MatchingHotspotEdit']['get']['answers'][0], readonly index: number) {
        this._value(answer.value.value);
        this.isReadOnly = model.isReadOnly;
        this.canEdit = model.canEdit;
    }
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly id = ko.pureComputed(() => {
        return this.answer.index;
    });

    private readonly _value = ko.observable('');
    public readonly value = ko.pureComputed<string>({
        read: () => this._value(),
        write: val => {
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/${this.answer.index}`, () => ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.model.itemId,
                    upsertAnswers: [{
                        index: this.answer.index,
                        value: xnone(val)
                    }]
                }
            }));
            this._value(val);
        }
    });

    public readonly click = new UIAction(undefined, async () => {
        await this.model.click.intent(this.index);
    });

    public readonly isItemSelected = ko.pureComputed(() => {
        return this.model.isItemSelected(this.index);
    });
}

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);

    public readonly MatchingHotSpotDeleteCircleButtonLabel = i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.DELETE_CIRCLE']);

    public readonly MatchingHotSpotConnectAreasButtonLabel = i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.CONNECT_AREAS']);

    public readonly MatchingHotSpotAddCircleButtonLabel = i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.ADD_CIRCLE']);
    public readonly radius = ko.observable(5);
    //modes of shapes editing
    public readonly circleDrawMode = ko.observable(false);
    public readonly circleDeleteMode = ko.observable(false);
    public readonly connectAreasMode = ko.observable(false);
    public readonly selectedItemIndex = ko.observable(-1);
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');
    public readonly scoringModeString = ko.observable<API.MatchingHotspotEdit_Scoring>(API.MatchingHotspotEdit_Scoring.AllOrNothing);
    public readonly shuffleModeString = ko.observable<API.MatchingHotspotEdit_ShuffleMode>(API.MatchingHotspotEdit_ShuffleMode.Ordered);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }


    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                image: this.image,
                imageWidth: this.imageWidth,
                imageHeight: this.imageHeight,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'image',
            editorType: 'dxSelectBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.IMAGE']),
            },
            editorOptions: this.imageOptions(),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageWidth',
            editorType: 'dxNumberBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.IMAGE_WIDTH']),
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                showSpinButtons: true,
                min: 0,
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageHeight',
            editorType: 'dxNumberBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.IMAGE_HEIGHT']),
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                showSpinButtons: true,
                min: 0,
            }),
        }));

        return retVal;
    });

    public readonly naturalImageWidth = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.width || 100;
    });
    public readonly naturalImageHeight = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.height || 100;
    });

    public readonly width = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).width;
    });

    public readonly height = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).height;
    });

    public readonly imgUrl = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        if (!att) {
            return undefined;
        }
        return ServerConnection.getDataUrl(att && att.hrefResolved);
    });

    public readonly circles = ko.pureComputed(() => {
        const imgWidth = this.width();
        const imgHeight = this.height();

        const shapes = this._shapes() || [];
        return shapes.map(shape => {
            for (const shapeFormat of SHAPES) {
                const regEx = shapeFormat.regEx.exec(shape.shape);
                if (!regEx) {
                    continue;
                }
                switch (shapeFormat.id) {
                    case 'circle':
                        const x = parseFloat(regEx[1]);
                        const y = parseFloat(regEx[2]);
                        const r = parseFloat(regEx[3]);
                        const circle = new Circle(this, imgWidth * x / 100, imgHeight * y / 100, imgWidth * r / 100, shape.index);
                        circle.connectId(shape.correct && shape.correct.index);
                        return circle;
                }
            }
            throw new Error(`Unhandled shape format ${shape.shape} (format not detected)`);
        });

    });

    private readonly _shapes = ko.observable<Q['MatchingHotspotEdit']['get']['shapes']>();

    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.matchinghotspot_edit_data({
            itemId: this.itemId,
        });
        const d = q.MatchingHotspotEdit.get;
        this.imageWidth(d.imageWidth || 0);
        this.imageHeight(d.imageHeight || 0);
        this.question(d.question.value);
        this.header(d.header.value);
        this.image(d.image?.name);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        this._shapes(d.shapes);
        this.editAnswers(d.answers.map((ans, index) => new Answer(this, ans, index)));
    }

    public async initialize() {
        await super.initialize();

        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.image, `${WIDGET_NAME}/${this.itemId}/image`, async val => {
            await ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    image: val
                }
            });
            return DONE;
        }, { triggerRefresh: true });
        this.onChange(this.scoringModeString, `${WIDGET_NAME}/${this.itemId}/scoring`, async val => {
            await ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    scoring: val
                }
            });
            return DONE;
        });
        this.onChange(this.shuffleModeString, `${WIDGET_NAME}/${this.itemId}/shuffleMode`, async val => {
            await ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    shuffleMode: val
                }
            });
            return DONE;
        });
        this.onChange(this.imageWidth, `${WIDGET_NAME}/${this.itemId}/imageWidth`, async val => {
            await ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    imageWidth: val
                }
            });
            return DONE;
        });
        this.onChange(this.imageHeight, `${WIDGET_NAME}/${this.itemId}/imageHeight`, async val => {
            await ServerConnection.api.matchinghotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    imageHeight: val
                }
            });
            return DONE;
        });
        this.loaded(true);
    }

    public readonly imageHeight = ko.observable(0);
    public readonly imageWidth = ko.observable(0);

    public readonly image = ko.observable('');
    private readonly _attachments: Q['documents']['get']['attachments'] = [];
    public readonly imageOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxSelectBox.Properties = {
            value: this.image,
            dataSource: this._attachments,
            valueExpr: 'name',
            displayExpr: 'name',
        };
        return retVal;
    });
    public readonly hasImage = ko.pureComputed(() => {
        return !!this.imgUrl();
    });

    public async circleClick(elem: Circle) {//Edit mode click on circle shape handler
        if (this.circleDeleteMode()) {//delete answer
            await legacyPushPull(async () => {
                await ServerConnection.api.matchinghotspot_edit_update(
                    {
                        params: {
                            itemId: this.itemId,
                            removeShapes: [elem.id]
                        }
                    });
            });
        }
        if (this.connectAreasMode()) {
            //connectAreas
            const index = this.selectedItemIndex();
            if (index >= 0) {
                this.selectedItemIndex(-1);
                const answer = this.editAnswers()[index];
                await legacyPushPull(async () => {
                    await ServerConnection.api.matchinghotspot_edit_connect(
                        {
                            itemId: this.itemId,
                            answer: answer.id(),
                            shape: elem.id
                        });
                });
            }
        }
    }

    public readonly connectAreas = new UIAction(undefined, async () => {
        this.connectAreasMode(!this.connectAreasMode());
        this.selectedItemIndex(-1);
        this.circleDrawMode(false);
        this.circleDeleteMode(false);
    });
    public drawShape(ths: void, e: MouseEvent) {
        if (this.circleDrawMode()) {
            const tgt = <SVGSVGElement>e.target;
            const targ = tgt.getBoundingClientRect();
            const offsetX = e.offsetX === undefined ? e.clientX - targ.left : e.offsetX;
            const offsetY = e.offsetY === undefined ? e.clientY - targ.top : e.offsetY;
            const x = Math.round(offsetX * 100 / tgt.width.baseVal.value);
            const y = Math.round(offsetY * 100 / tgt.height.baseVal.value);
            const radius = Math.round(this.radius());
            void legacyPushPull(async () => {
                await ServerConnection.api.matchinghotspot_edit_addcircle({
                    itemId: this.itemId,
                    x, y, radius
                });
            });
        }
    }
    public isItemSelected(index: number) {
        return this.selectedItemIndex() === index;
    }
    public readonly click = new UIAction<number>(undefined, async (e, index) => {
        if (this.connectAreasMode()) {
            this.selectedItemIndex(index);
        }
    });

    public readonly defineAnswerAreasText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.DEFINE_ANSWER_AREAS']);
    });

    public readonly radiusText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.RADIUS']);
    });
    public readonly drawHint = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.CLICK_ON_THE_DESIRED_AREA_TO_ADD_A_CIRCLE']);
    });
    public readonly deleteHint = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.CLICK_ON_THE_CIRCLE_YOU_WANT_TO_DELETE']);
    });
    public readonly connectHint = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.CLICK_ON_THE_TEXT_BOX_AND_THEN_ON_THE_CORRESPONDING_AREA_IN_THE_IMAGE_TO_PLACE_THE_CORRECT_CONNECTION']);
    });

    public readonly addCircle = new UIAction(undefined, async () => {
        this.circleDrawMode(!this.circleDrawMode());
        if (this.circleDrawMode()) {
            this.circleDeleteMode(false);
            this.connectAreasMode(false);
        }
    });
    public readonly deleteCircle = new UIAction(undefined, async () => {
        this.circleDeleteMode(!this.circleDeleteMode());
        if (this.circleDeleteMode()) {
            this.circleDrawMode(false);
            this.connectAreasMode(false);
        }
    });

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.ENTER_THE_TEXT_HERE']);
    });

    public readonly editAnswers = ko.observable<Answer[]>();

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.matchinghotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });


}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, refreshDx, selectBoxDS } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { SurveyTextInputEdit_Option } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { xnone } from '../../../model/languagemap';
import { ServerConnection } from '../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveytextinput-edit';
type Q = Awaited<ReturnType<API.Sdk['surveytextinput_edit_data']>>;

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends DxWidget {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;

    public readonly loaded = ko.observable(false);
    public readonly option = ko.observable<SurveyTextInputEdit_Option>();

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.surveytextinput.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
    }

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.surveytextinput_edit_data({
            itemId: this.itemId
        });
        const d = q.SurveyTextInputEdit.get;
        this.question(d.question.value);
        this.header(d.header.value);
        this.option(d.option);
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();

        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.surveytextinput_edit_upsert({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.surveytextinput_edit_upsert({
                params: {
                    itemId: this.itemId,
                    header: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.option, `${WIDGET_NAME}/${this.itemId}/option`, async v => {
            await ServerConnection.api.surveytextinput_edit_upsert({
                params: {
                    itemId: this.itemId,
                    option: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }

    public readonly imgUrl = ko.observable('');

    public readonly question = ko.observable('');
    public readonly header = ko.observable('');

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.surveytextinput.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.surveytextinput.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.surveytextinput.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.surveytextinput.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));

        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                option: this.option
            },
            items: [],
        };

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'option',
            label: {
                text: i18next.t(['itemdefinition.surveytextinput.edit.SURVEY_OPTION'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: SurveyTextInputEdit_Option.Required,
                    value: i18next.t(['itemdefinition.surveytextinput.edit.REQUIRED']),
                },
                {
                    key: SurveyTextInputEdit_Option.Optional,
                    value: i18next.t(['itemdefinition.surveytextinput.edit.OPTIONAL']),
                },
            ]),
        }));

        return retVal;
    });


}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

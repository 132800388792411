import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
//import * as INIT from '../init.query.graphql.g';
import { INIT, ItemDataModel } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'Placeholder_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

export class PlaceHolderData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly rawData: DATA) {
        super();
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'Placeholder_QueryItemSessionData') {
            throw new Error();
        }

    }

    public readonly isDone = ko.observable(false);
    public async setDone() {
        this.isDone(true);
    }

    public IsInteractionStarted() {
        return this.isDone();
    }
    public IsInteractionComplete() {
        return this.isDone();
    }
    public IsInitialData() {
        return true;
    }

    public async reset() {
        this.isDone(false);
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            PlaceHolder: {
                isDone: this.isDone(),
            }
        };
        return retVal;
    }
}

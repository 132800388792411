import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { log } from '../../../../debug';
import { AS, selectBoxDS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import * as TOKENIZER from '../../../../tokenizer';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18n from './../../../../i18n/i18n';
import * as i18next from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['textentry_manual_edit_data']>>;
type GAP = Q['TextEntryManualEdit']['get']['gaps'][0];

const WIDGET_NAME = 'itemdefinition-kosovo-textentry_manual-edit';

class GapPossibilityVM {
    constructor(readonly vm: ViewModel, readonly gapVM: GapVM, readonly id: string) {
        this.value = this.vm.getValueKO(id);
        this.isReadOnly = vm.isReadOnly;
        this.canEdit = vm.canEdit;
    }
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public deleteAction = new UIAction(undefined, async () => {
        let gaps = this.gapVM.possibilities();

        if (gaps.length <= 1) {
            toastService.warning(i18next.t(['itemdefinition.kosovo.textentry_manual.edit.THIS_VARIANT_CANNOT_BE_DELETED_YOU_NEED_AT_LEAST_ONE_VARIANT_PER_GAP']));
            return;

        }

        const message = i18next.t(['itemdefinition.kosovo.textentry_manual.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_VARIANT']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await legacyPushPull(async () => {
            await ServerConnection.api.textentrymanual_edit_removevalue({
                itemId: this.vm.itemId,
                valueId: this.id,
            });
        });
    });


    public readonly value: ko.Observable<string>;
}
class GapVM {
    constructor(readonly vm: ViewModel, readonly gapId: string) {

        this.isReadOnly = vm.isReadOnly;
        this.canEdit = vm.canEdit;
        this.pattern = vm.getPatternKO(gapId);
        this.validationMessage = vm.getValidationMessageKO(gapId);
    }
    public readonly isReadOnly: boolean;
    public readonly pattern: ko.Observable<string>;
    public readonly validationMessage: ko.Observable<string>;
    public readonly canEdit: boolean;


    public readonly formOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.vm.isReadOnly,
            formData: {
                pattern: this.pattern,
                validationMessage: this.validationMessage,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'pattern',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.textentry_manual.edit.PATTERN']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'validationMessage',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.textentry_manual.edit.VALIDATION_MESSAGE']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
            }),
        }));
        return retVal;
    });

    public update(data: GAP) {
        const newP = data.possibilities.map(x => x.valueId);
        if (this._possibilities().join(' ') !== newP.join(' ')) {
            this._possibilities(newP);
        }
    }
    private readonly _possibilities = ko.observable<string[]>([]);

    public readonly gapTag = ko.pureComputed(() => {
        return TOKENIZER.GAP_TOKEN.compile({
            id: this.gapId
        });
    });
    private readonly _values = new Map<string, GapPossibilityVM>();
    public readonly isSelected = ko.pureComputed(() => this.gapId === this.vm.selectectGapId());
    public readonly possibilities = ko.pureComputed(() => {
        const p = this._possibilities();
        for (const id of p) {
            if (!this._values.has(id)) {
                this._values.set(id, new GapPossibilityVM(this.vm, this, id));
            }
        }
        return p.map(id => this._values.get(id));
    });

    public addVariantAction = new UIAction(undefined, async () => {
        await this.vm.addVariant(this.gapId);
    });
    public selectAction = new UIAction(undefined, async () => {
        this.vm.selectectGapId(this.gapId);
    });
    public deleteGapAction = new UIAction(undefined, async () => {
        const itemData = this.vm.item();
        if (!itemData) {
            return;
        }

        const message = i18next.t(['itemdefinition.kosovo.textentry_manual.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_GAP']);


        if (!await Modal.confirmYesNo(message)) {
            return;
        }
        const text = this.vm.text();
        const tag = `$(gap:${this.gapId})`;
        const newText = text.replace(tag, '');
        await this.vm.updateText(newText);
        //this.vm.text(newText);
        await legacyPushPull(async () => {
            await ServerConnection.api.textentrymanual_edit_removegap({
                itemId: this.vm.itemId,
                gapId: this.gapId
            });
        });
    });
}

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);
    public readonly answers = ko.observableArray();
    public readonly scoring = ko.observable<API.TextEntryManualEdit_Scoring>();
    public readonly perGapScore = ko.observable(0);

    public readonly choiceTextDeleteGapButtonLabel = i18next.t(['itemdefinition.kosovo.textentry_manual.edit.DELETE_GAP']);
    public readonly choiceTextAddVariantButtonLabel = i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ADD_OPTION']);

    public async updateText(newText: string) {
        if (this.text() === newText) {
            return;
        }
        log(`Update text to: ${newText}`);
        this.editor().setText(newText);
    }
    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    private readonly editor = ko.observable<HTMLEDITOR.ViewModel>();

    private readonly _gaps = new Map<string, GapVM>();

    public readonly item = ko.observable<Q['TextEntryManualEdit']['get']>();

    public readonly _gapIds = ko.observable<string[]>([]);

    public readonly gaps = ko.pureComputed(() => {
        return this._gapIds().map(x => this._gaps.get(x));
    });

    public readonly selectectGapId = ko.observable('');
    public readonly text = ko.observable('');


    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                text: this.text,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.TEXT']),
            },
            readOnly: this.isReadOnly,
            dataField: 'text',
            placeholder: this.enterTextPlaceholder(),
            docType: API.Doctype.Item,
            docReferenceId: this.itemId,
            tokenTypes: [TOKENIZER.GAP_TOKEN],
            toolbarItems: [{
                'icon': 'far fa-plus-square',
                'text': i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ADD_GAP']),
                onClick: this.addGapFragmentAction.click,
                disabled: this.isReadOnly,
            }],
            onInitialized: async editor => {
                this.editor(editor);
                this.disposables.addDiposable(editor.currentTag.subscribe(val => {
                    const r = TOKENIZER.GAP_TOKEN.parse(val);
                    const id = r && r.id;
                    if (!id) {
                        this.selectectGapId(undefined);
                        this.selectGapOnRefresh = undefined;
                    } else {
                        if (this._gapIds().find(x => x === id)) {
                            this.selectectGapId(id);
                        } else {
                            this.selectGapOnRefresh = id;
                        }
                    }
                }));
            }
        }));
        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                scoring: this.scoring,
                perGapScore: this.perGapScore,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'scoring',
            label: {
                text: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.POINTS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.TextEntryAutoEdit_Scoring.AllOrNothing,
                    value: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.FOR_ITEM']),
                },
                {
                    key: API.TextEntryAutoEdit_Scoring.PerGap,
                    value: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.PER_GAP']),
                }
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'perGapScore',
            editorType: 'dxNumberBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.textentry_manual.edit.POINTS_PER_GAP'])
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
        }));

        return retVal;
    });

    private selectGapOnRefresh: string;

    public readonly addGapFragmentAction = new UIAction(undefined, async () => {
        const r = await ServerConnection.api.textentry_manual_edit_addgap({
            itemId: this.itemId,
        });
        await this.editor().insertToken(TOKENIZER.GAP_TOKEN.compile({
            id: r.TextEntryManualEdit.addGap.gapId
        }));
        await legacyPushPull();
    });


    public readonly selectedGap = ko.pureComputed(() => {
        const selected = this.selectectGapId();
        if (!selected) {
            return undefined;
        }
        return this._gaps.get(selected);
    });

    private readonly valueKO = new Map<string, ko.Observable<string>>();
    private readonly patternKO = new Map<string, ko.Observable<string>>();
    private readonly validationMessageKO = new Map<string, ko.Observable<string>>();

    public getValueKO(valueId: string) {
        if (!this.valueKO.has(valueId)) {
            throw new Error();
        }
        return this.valueKO.get(valueId);
    }
    public getPatternKO(gapId: string) {
        if (!this.patternKO.has(gapId)) {
            throw new Error();
        }
        return this.patternKO.get(gapId);
    }
    public getValidationMessageKO(gapId: string) {
        if (!this.validationMessageKO.has(gapId)) {
            throw new Error();
        }
        return this.validationMessageKO.get(gapId);
    }

    getCreatePatternKO(g: GAP) {
        const gapId = g.gapId;
        if (this.patternKO.has(gapId)) {
            this.patternKO.get(gapId)(g.pattern);
        } else {
            const val = ko.observable(g.pattern);
            this.onChange(val, `${WIDGET_NAME}/${this.itemId}/pattern/${gapId}`, async v => {
                await ServerConnection.api.textentry_manual_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertGaps: [{
                            gapId,
                            pattern: v
                        }]
                    }
                });
                return DONE;
            });
            this.patternKO.set(gapId, val);
        }
        return this.patternKO.get(gapId);
    }
    getCreateValidationMessageKO(g: GAP) {
        const gapId = g.gapId;
        if (this.validationMessageKO.has(gapId)) {
            this.validationMessageKO.get(gapId)(g.validationMessage.value);
        } else {
            const val = ko.observable(g.validationMessage.value);
            this.onChange(val, `${WIDGET_NAME}/${this.itemId}/validationmessage/${gapId}`, async v => {
                await ServerConnection.api.textentry_manual_edit_update({
                    params: {
                        itemId: this.itemId,
                        upsertGaps: [{
                            gapId,
                            validationMessage: {
                                xnone: v
                            }
                        }]
                    }
                });
                return DONE;
            });
            this.validationMessageKO.set(gapId, val);
        }
        this.validationMessageKO.get(gapId);
    }
    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.textentry_manual_edit_data({
            itemId: this.itemId
        });

        const d = data.TextEntryManualEdit.get;
        if (!d) {
            return;
        }
        this.scoring(d.scoring);
        this.text(d.text.value);
        this.header(d.header.value);
        this.question(d.question.value);
        this.perGapScore(d.perGapScore || 0);
        for (const g of d.gaps) {
            this.getCreatePatternKO(g);
            this.getCreateValidationMessageKO(g);
            if (!this._gaps.has(g.gapId)) {
                this._gaps.set(g.gapId, new GapVM(this, g.gapId));
            }
            for (const v of g.possibilities) {
                const valueId = v.valueId;
                if (this.valueKO.has(valueId)) {
                    this.valueKO.get(valueId)(v.value.value);
                } else {
                    const val = ko.observable(v.value.value);
                    this.onChange(val, `${WIDGET_NAME}/${this.itemId}/value/${valueId}`, async v => {
                        await ServerConnection.api.textentry_manual_edit_update({
                            params: {
                                itemId: this.itemId,
                                upsertValues: [
                                    {
                                        valueId: valueId,
                                        value: xnone(v)
                                    }
                                ]
                            }
                        });
                        return DONE;
                    });
                    this.valueKO.set(valueId, val);
                }
            }
            this._gaps.get(g.gapId).update(g);
        }
        const gapIds = d.gaps.map(x => x.gapId).sort();
        if (gapIds.join(' ') !== this._gapIds().join(' ')) {
            this._gapIds(gapIds);
        }
        this.item(d);

        const selectGap = this.selectGapOnRefresh || this.selectectGapId();
        if (gapIds.find(x => x === selectGap)) {
            this.selectectGapId(selectGap);
        } else {
            this.selectectGapId(undefined);
        }
        this.selectGapOnRefresh = undefined;
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();


        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async v => {
            await ServerConnection.api.textentry_manual_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.text, `${WIDGET_NAME}/${this.itemId}/text`, async v => {
            await ServerConnection.api.textentry_manual_edit_update({
                params: {
                    itemId: this.itemId,
                    text: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async v => {
            await ServerConnection.api.textentry_manual_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(v)
                }
            });
            return DONE;
        });
        this.onChange(this.perGapScore, `${WIDGET_NAME}/${this.itemId}/perGapScore`, async v => {
            await ServerConnection.api.textentry_manual_edit_update({
                params: {
                    itemId: this.itemId,
                    perGapScore: v
                }
            });
            return DONE;
        });
        this.onChange(this.scoring, `${WIDGET_NAME}/${this.itemId}/scoring`, async v => {
            await ServerConnection.api.textentry_manual_edit_update({
                params: {
                    itemId: this.itemId,
                    scoring: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }

    public readonly header = ko.observable('');


    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });
    public readonly question = ko.observable('');

    public async addVariant(gapId: string) {
        await legacyPushPull(async () => {
            const r = await ServerConnection.api.textentry_manual_edit_addvalue({
                itemId: this.itemId,
                gapId: gapId
            });
        });
    }


    public readonly gapPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ANSWER']);
    });

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ENTER_THE_TEXT_HERE']);
    });

    public readonly enterAnswerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_manual.edit.ENTER_THE_ANSWER_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

﻿import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { AS, datagrid, refreshDx, storeState } from '../../../../dx_helper';
import * as Helper from '../../../../helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { registerOnUpdateToolbar } from '../../../../ui/toptoolbar.service';
import { UIAction } from '../../../../ui/uiAction';
import * as i18next from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type UPDATE = Awaited<ReturnType<API.Sdk['linetextentry_grading_update']>>;
const WIDGET_NAME = 'itemdefinition-lavak-linetextentry-grading';

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends DxWidget {
    public readonly loaded = ko.observable(false);
    public readonly mode: ko.Subscribable<string>;
    public readonly itemId: string;
    public readonly sessionId: string;

    constructor(params: IParams) {
        super();
        this.itemId = ko.unwrap(params.itemId);
        this.mode = Helper.ensureObservable(params.mode || 'INTERACTIVE');
    }

    public async initialize() {
        await super.initialize();

        if (this.mode() !== 'GRADING') {
            throw new Error(`${WIDGET_NAME} - mode must be GRADING (was ${this.mode()})`);
        }

        await this.OnRefresh();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));

        this.gridOptions = this.initGridOptions();
        this.loaded(true);
    }

    async OnRefresh() {
        const r = await ServerConnection.api.linetextentry_grading_update(
            {
                itemDocRefId: this.itemId,
            });
        const d = r;
        const s = d.LavakLineTextEntry.evaluator_updateSharedGradingData;
        if (!s) {
            return;
        }
        this.localizedHeader(s.header.value);
        this.localizedQuestion(s.question.value);
        this.topics(s.topics.map(x => new Topic(this, x)));
        this.answers.splice(0, this.answers.length, ...s.answers.map(x => new Answer(this, x)));

        await refreshDx(this);
    }
    public readonly localizedHeader = ko.observable('');

    public readonly localizedQuestion = ko.observable('');

    public readonly topics = ko.observable<Topic[]>();

    public readonly answers: Answer[] = [];

    private readonly grid = ko.observable<DevExpress.ui.dxDataGrid>();

    private readonly gradingSubmitAction = new UIAction(undefined, async () => {
        const message = i18next.t(['itemdefinition.lavak.linetextentry.grading.DO_YOU_WANT_TO_SUBMIT_THE_EVALUATION_OF_THIS_ITEM']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await ServerConnection.api.linetextentry_grading_submit({
            itemDocRefId: this.itemId,
        });
        await legacyPushPull();
    });
    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        toolbar.items.push(AS<DevExpress.ui.dxToolbarItemTemplate>({
            location: 'after',
            widget: 'dxButton',
            options: AS<DevExpress.ui.dxButton.Properties>({
                icon: 'far fa-check-circle',
                text: i18next.t(['itemdefinition.lavak.linetextentry.grading.SUBMIT']),
                onClick: this.gradingSubmitAction.click
            })
        }));
        return DONE;
    }

    public readonly setSelected = new UIAction<Topic>(undefined, async (e, args) => {
        const grid = this.grid();
        if (!grid) {
            return;
        }
        const selected = <Answer[]>await grid.getSelectedRowsData();
        for (const s of selected) {
            await s.select.invoke(args, true);
        }
        grid.deselectAll();
    });

    public readonly helpText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.lavak.linetextentry.grading.HELPTEXT']);
    });


    public gridOptions: DevExpress.ui.dxDataGrid.Properties;
    private initGridOptions() {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.grid,
            config: {
                stateStoring: storeState(WIDGET_NAME),
                columnAutoWidth: true,
                width: '100%',
                headerFilter: {
                    visible: true,
                },
                allowColumnResizing: true,
                allowColumnReordering: true,
                focusedRowEnabled: true,
                selection: {
                    selectAllMode: 'page',
                    allowSelectAll: true,
                    mode: 'multiple',
                    showCheckBoxesMode: 'always',
                },
                keyExpr: 'value',
                columns: [],
            }
        });

        retVal.onRowPrepared = e => {
            if (e.rowType === 'data') {
                const data: Answer = e.data;
                const elem = e.rowElement;
                if (data.submitted()) {
                    elem.addClass('Disabled');
                } else {
                    elem.removeClass('Disabled');
                }
            }
        };

        retVal.onRowUpdated = e => {
            const data: Answer = e.data;
            const topicId = data.topic();
            const topic = this.topics().find(x => x.id() === topicId);
            if (topic) {
                if (topic.answer.isKeyAnswer) {
                    e.element.addClass('KeyAnswer');
                    e.element.removeClass('DistractorAnswer');
                } else {
                    e.element.addClass('DistractorAnsewr');
                    e.element.removeClass('KeyAnswer');
                }
            }
        };

        retVal.onToolbarPreparing = e => {
            for (const topic of this.topics()) {
                e.toolbarOptions.items.unshift(AS<DevExpress.ui.dxToolbarItem>({
                    location: 'before',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    options: AS<DevExpress.ui.dxButton.Properties>({
                        text: topic.text(),
                        type: topic.answer.isKeyAnswer ? 'success' : 'danger',
                        onClick: o => {
                            this.setSelected.click(topic);
                        },
                    }),
                }));
            }
        };
        retVal.dataSource = this.answers;


        retVal.columns.push({
            caption: i18next.t(['itemdefinition.lavak.linetextentry.grading.FREQUENCY']),
            dataField: 'count',
            width: 80,
        });
        retVal.columns.push({
            caption: i18next.t(['itemdefinition.lavak.linetextentry.grading.ANSWER']),
            dataField: 'value',
        });

        retVal.columns.push(AS<DevExpress.ui.dxDataGridColumn>({
            caption: i18next.t(['itemdefinition.lavak.linetextentry.grading.STATUS']),
            dataField: 'status',
            encodeHtml: false,
            width: 80,
        }));

        retVal.columns.push({
            caption: i18next.t(['itemdefinition.lavak.linetextentry.grading.SUBMITTED']),
            dataField: 'submitted',
            width: 80,
        });




        return retVal;
    }

}

class Answer {
    constructor(public readonly model: MyModel, public readonly data: UPDATE['LavakLineTextEntry']['evaluator_updateSharedGradingData']['answers'][0]) {
        this.topic(this.data.topicId);
        this.submitted(this.data.submitted);
    }
    public readonly value = ko.pureComputed(() => this.data.value);
    public readonly count = ko.pureComputed(() => this.data.count);
    public readonly submitted = ko.observable(false);
    public readonly topic = ko.observable('');

    public readonly status = ko.pureComputed(() => {
        if (!this.topic()) {
            return i18next.t(['itemdefinition.lavak.linetextentry.grading.NOT_SET']);
        }
        const t = this.model.topics().find(x => x.id() === this.topic());
        if (t) {
            const className = t.answer.isKeyAnswer ? 'Key' : 'Distractor';
            return `<span class="${className}">${t.text()}</span>`;
        } else {
            return '?';
        }
    });

    public readonly select = new UIAction<Topic>(undefined, async (e, args) => {
        if (this.data.submitted) {
            return;
        }
        const r = await ServerConnection.api.linetextentry_grading_setvalue({
            itemDocRefId: this.model.itemId,
            value: this.value(),
            topicId: args.id(),
        });
        const s = r.LavakLineTextEntry.evaluator_setTopicValue;
        if (s) {
            this.topic(s.topicId);
        }
    });

}


class Topic {
    constructor(public model: MyModel, public readonly answer: UPDATE['LavakLineTextEntry']['evaluator_updateSharedGradingData']['topics'][0]) {

    }
    public readonly id = ko.pureComputed(() => this.answer.topicId);
    public readonly text = ko.pureComputed(() => this.answer.text.value);
}


export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import * as prismjs from 'prismjs';
import { dir, log } from '../../../debug';
import { assertNever } from '../../../helper';
import { formatMessage } from '../../../i18n/data';
import * as API from '../../../its-itembank-api.g';
import { KosovoContainer_TextBlockFormat } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { findInRingBuffer } from '../../../new_array';
import { focusManager } from '../../../ui/focusmanager';
import { ServerConnection } from '../../../ui/RestAPI';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { DATA, ItemComboData, ItemComboData_SubItem } from '../../model/itemcombo/ItemComboData';
import { translate } from '../../model/ItemDataModel';
import { GetSession } from '../../model/session';
import * as WIDGET_HEADER from '../widgets/header/widget';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './container.html.g';
import * as FACTORY from './factory';

export type INIT = Awaited<ReturnType<API.Sdk['item_model_init']>>;
type ATTACHMENT = DATA['multiMedia']['attachments'][0];

const WIDGET_NAME = 'itemdefinition-kosovo-container';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

class SubItem {
    readonly itemDocRefId: string;
    constructor(readonly model: MyModel, readonly subItem: ItemComboData_SubItem) {
        this.itemDocRefId = subItem.itemDocRefId;

        const nr = this.model.data.subItems.findIndex(x => x.itemDocRefId === this.itemDocRefId) + 1;
        const title = formatMessage(i18n.t(['itemdefinition.kosovo.container.PART_NR']), { nr });
        this.title(title);
        this.itemType(subItem.itemType);

    }

    public readonly itemType = ko.observable('');
    public readonly title = ko.observable('');

    public readonly select = new UIAction(undefined, async () => {
        await this.model.selectSubItem.intent(this.itemDocRefId);
    });

    public readonly cssClass = ko.pureComputed(() => {
        if (this.model.selectedSubItemId() === this.itemDocRefId) {
            return 'ATTEMPTING';
        }
        return 'NOTATTEMPTED';
    });

    public readonly isDone = ko.pureComputed(() => {
        return this.model.data.meta.session.getItemData(this.subItem.itemDocRefId).IsInteractionComplete();
    });
}

class AttachmentDownload {
    readonly index: number;
    constructor(readonly model: MyModel, readonly rawData: ATTACHMENT) {
        this.index = model.data._data.multiMedia.attachments.findIndex(x => x.id === rawData.id);
        this.hrefResolved(ServerConnection.getDataUrl(rawData.hrefResolved));
        this._name(rawData.name);
    }
    public readonly _name = ko.observable<string>();
    public readonly hrefResolved = ko.observable<string>();
    public readonly filename = ko.pureComputed(() => {
        return `Attachment ${this.index}`;
    });
    public readonly name = ko.pureComputed(() => {
        if (this._name()) {
            return this._name();
        }
        return formatMessage(i18n.t(['itemdefinition.kosovo.container.ATTACHMENT_NR']), { nr: this.index + 1 });
    });
}
export class MyModel extends AbstractItemDefinition {
    public readonly itemType = FACTORY.id;

    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly data: ItemComboData;

    public readonly withSubItems = ko.pureComputed(() => {
        return this.mode() !== 'PRINT';
    });

    //public readonly focusId: string;
    public readonly isFocused = ko.pureComputed(() => {
        if (!focusManager.isFocused()) {
            return false;
        }
        const topItemFocus = focusManager.topItemFocus();
        if (!topItemFocus) {
            return false;
        }
        /*
        if (topItemFocus.focusId !== this.focusId) {
            return false;
        }
        */
        return true;
    });

    public readonly showItemSwitcher = ko.pureComputed(() => {
        return this.subItems.length > 1;
    });
    public readonly hasFocusMode = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        this.itemDocId = 'item,' + this.itemId;
        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof ItemComboData)) {
            throw new Error();
        }
        this.data = data;
        this.subItems = data.subItems.map(x => new SubItem(this, x));
    }

    public readonly attachments = ko.pureComputed(() => {
        const mm = this.data._data.multiMedia;
        const att = (mm && mm.attachments) || [];
        return att.map(a => new AttachmentDownload(this, a));
    });

    public readonly headerParams = ko.pureComputed(() => {
        const retVal: WIDGET_HEADER.IParams = {
            questionHTML: this.questionHTML(),
            headerText: this.headerText(),
            mode: this.mode()
        };
        return retVal;
    });

    public readonly uploadedResultName = ko.observable('');


    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (this.hasSubItems()) {
            const curSubItem = this.selectedSubItem();
            if (curSubItem) {
                if (!curSubItem.isDone()) {
                    return false;
                }
            }

            const subItems = this.subItems;
            const next = findInRingBuffer(subItems, subItems.findIndex(x => x === this.selectedSubItem()), x => !x.isDone(), 'any');
            if (next) {
                log(`Select next sub item`);
                await next.select.invoke();
                return true;
            }
        }
        return false;
    }




    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);


        this.hasFocusMode(this.data && this.data.meta.hasFocusMode && this.data.meta.isTopItem && this.mode() === 'INTERACTIVE');
        this.multimediaText(translate(this.data._data.multiMedia.text, this.params));
        this.multimediaTextFormat(this.data._data.multiMedia.textFormat);
        const imgAtt = this.data.meta.getAttachment(this.data._data.containerImage?.id);
        this.imgUrl(ServerConnection.getDataUrl(imgAtt?.hrefResolved));
        this.imgAltText(imgAtt?.altText?.value || '');
        const audioAtt = this.data.meta.getAttachment(this.data._data.multiMedia?.mmAudio?.id);
        this.audioUrl(ServerConnection.getDataUrl(audioAtt?.hrefResolved));
        const videoAtt = this.data.meta.getAttachment(this.data._data.multiMedia?.mmVideo?.id);
        this.videoUrl(ServerConnection.getDataUrl(videoAtt?.hrefResolved));

        const ovl = this.data._data.multiMedia?.onlineVideoLink;
        let videoLinkUrl = '';

        if (ovl) {
            const youtube = /\?v=(.*)$/.exec(ovl);
            dir(youtube);
            if (youtube) {
                const id = youtube[1];
                videoLinkUrl = `https://www.youtube.com/embed/${id}?rel=0`;
            }
        }
        this.videoLinkUrl(videoLinkUrl);

        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly noSubItemsText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.container.NO_SUBITEMS_HAVE_BEEN_ADDED_YET']);
    });
    public readonly hasSubItems = ko.pureComputed(() => {
        return this.subItems.length > 0;
    });
    public readonly subItems: SubItem[];

    public readonly selectedSubItemId = ko.pureComputed(() => this.data.selectedSubItemId());
    public readonly selectedSubItemType = ko.pureComputed(() => {
        const si = this.selectedSubItem();
        return si && si.itemType();
    });

    public readonly selectedSubItem = ko.pureComputed(() => {
        return this.subItems.find(x => x.itemDocRefId === this.data.selectedSubItemId());
    });


    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly multimediaText = ko.observable('');
    public readonly multimediaTextFormat = ko.observable<KosovoContainer_TextBlockFormat>(KosovoContainer_TextBlockFormat.Html);

    public readonly hasTextBlock = ko.pureComputed(() => {
        return !!this.multimediaText();
    });
    public readonly hasImage = ko.pureComputed(() => {
        return !!this.imgUrl();
    });
    public readonly imgUrl = ko.observable('');
    public readonly imgAltText = ko.observable('');

    public readonly audioUrl = ko.observable('');
    public readonly videoUrl = ko.observable('');

    public readonly videoLinkUrl = ko.observable('');

    public readonly hasAttachment = ko.pureComputed(() => {
        return this.attachments().length > 0;
    });


    public readonly textBlock = ko.pureComputed(() => {
        const v = this.multimediaText() || '';
        let prismType: string;
        const tv = this.multimediaTextFormat();
        switch (tv) {
            case KosovoContainer_TextBlockFormat.Html:
                return v;
            case KosovoContainer_TextBlockFormat.PlainText:
                const cooked = v
                    .replace(/&/g, '&amp;')
                    .replace(/>/g, '&gt;')
                    .replace(/</g, '&lt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&apos;');
                return `<pre>${cooked}</pre>`;
            case KosovoContainer_TextBlockFormat.PythonSource:
                prismType = 'python';
                break;
            case KosovoContainer_TextBlockFormat.HtmlSource:
                prismType = 'markup';
                break;
            case KosovoContainer_TextBlockFormat.CssSource:
                prismType = 'css';
                break;
            case KosovoContainer_TextBlockFormat.JavaScriptSource:
                prismType = 'javascript';
                break;
            case KosovoContainer_TextBlockFormat.CLikeSource:
                prismType = 'clike';
                break;
            default:
                assertNever(tv);
                break;
        }
        if (prismType) {
            const tokens = prismjs.highlight(v, prismjs.languages[prismType], prismType);
            return `<pre class="language-${prismType}">${tokens}</pre>`;
        }
        return '(unkown formatter)';
    });

    public readonly selectSubItem = new UIAction<string>(undefined, async (e, subItemDocRefId) => {
        await this.data.selectSubItem(subItemDocRefId);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

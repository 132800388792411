import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import * as API from '../../../../its-itembank-api.g';
import { AS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { ServerConnection } from '../../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18n from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';


const WIDGET_NAME = 'itemdefinition-kosovo-essay-edit';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;

    public readonly loaded = ko.observable(false);
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');
    public readonly evaluationInfo = ko.observable('');


    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                evaluationInfo: this.evaluationInfo,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.essay.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.essay.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.essay.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.essay.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'evaluationInfo',
            editorType: 'dxTextArea',
            label: {
                text: i18n.t(['itemdefinition.kosovo.essay.edit.EVALUATION_CRITERIA']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.essay.edit.DEFINE_THE_EVALUATION_CRITERIA_HERE']),
            }),
        }));
        return retVal;
    });

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
    }

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.essay_edit_data({
            itemId: this.itemId
        });
        const d = q.EssayEdit.get;
        this.header(d.header.value);
        this.question(d.question.value);
        this.evaluationInfo(d.evaluationInfo.value);

    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.essay_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.essay_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.evaluationInfo, `${WIDGET_NAME}/${this.itemId}/evaluationInfo`, async val => {
            await ServerConnection.api.essay_edit_update({
                params: {
                    itemId: this.itemId,
                    evaluationInfo: xnone(val)
                }
            });
            return DONE;
        });

        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

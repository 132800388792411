import * as ko from 'knockout';
import { toastService } from '../../toastService';
import { DefaultWindowManager } from '../base';
import { ItemHostBase } from "../ItemHostBase";
import { ServerConnection } from '../RestAPI';
import { UIAction } from '../uiAction';
import { htmlString } from './widget.html.g';




const WIDGET_NAME = 'ui-index';
const WIDGET_PARENT_NAME = 'ui';

class DemoExam {
    constructor(readonly model: App, readonly examId: string) {

    }
    public readonly title = ko.pureComputed(() => {
        return this.examId;
    });

    public readonly click = new UIAction(undefined, async () => {
        const url = ServerConnection.getLaunchDemoExamUrl(this.examId, 'close');
        DefaultWindowManager.prepareOpenWindow('');
        DefaultWindowManager.openWindow(url, '');
    });
}
class SubUrl {
    constructor(readonly model: App, readonly url: string, readonly title: string) {

    }
    public readonly click = new UIAction(undefined, async () => {
        window.location.href = this.url;
    });
}

class Notice {
    constructor(readonly model: App, readonly id: string, readonly text: string) {

    }
    public readonly isDismissed = ko.observable(false);

    public readonly dismiss = new UIAction(undefined, async () => {
        this.isDismissed(true);
    });
}

class Notices {
    constructor(readonly model: App) {

    }
    private noticesStore = new Map<string, Notice>();
    public add(id: string, msg: string) {
        this.noticesStore.set(id, new Notice(this.model, id, msg));
        this.notices(Array.from(this.noticesStore.values()));
    }
    public readonly notices = ko.observable<Notice[]>([]);
    public readonly reset = new UIAction(undefined, async () => {
        for (const val of Array.from(this.noticesStore.values())) {
            val.isDismissed(false);
        }
    });
}
export class App extends ItemHostBase {
    public getURL(location: string): string {
        return ServerConnection.getDataUrl(location);
    }
    public readonly demoExams = ko.pureComputed(() => {
        return ['INTRO', 'AP1', 'AP2', 'GSS', 'GSN'].map(x => new DemoExam(this, x));
    });
    public readonly urls = ko.pureComputed(() => {
        const retVal = new Array<SubUrl>();
        retVal.push(new SubUrl(this, ServerConnection.getDataUrl('ui/serafineadmin.html'), 'Test Administrator'));
        retVal.push(new SubUrl(this, ServerConnection.getDataUrl('ui/serafinecommission.html'), 'Head of Commission'));
        retVal.push(new SubUrl(this, ServerConnection.getDataUrl('ui/author.html'), 'Author'));
        retVal.push(new SubUrl(this, ServerConnection.getDataUrl('ui/subjectcoordinator.html'), 'Subject Coordinator'));
        retVal.push(new SubUrl(this, ServerConnection.getDataUrl('ui/superuser.html'), 'Superuser'));
        return retVal;
    });

    public readonly notices = new Notices(this);
    public readonly notice = ko.pureComputed(() => {
        return this.notices.notices().find(x => !x.isDismissed());
    });

    public readonly NYI = new UIAction(undefined, async () => {
        toastService.info('Not yet implemented');
    });
}

export type IParams = undefined;

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new App();
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

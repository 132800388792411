import * as ko from 'knockout';
import { error, log } from '../../../debug';
import { getGolemData, golemMatch, isStringArray } from '../../../Gremlin';
import { formatMessage } from '../../../i18n/data';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry, pickItems } from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { MultipleChoiceTextData, MultipleChoiceTextData_Answer } from '../../model/multiplechoicetext/MultipleChoiceTextData';
import { GetSession } from '../../model/session';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './multiplechoice.html.g';

const WIDGET_NAME = 'itemdefinition-lavak-multiplechoice';
const WIDGET_PARENT_NAME = 'itemdefinition-lavak-multiplechoice';

export type IParams = IItemDefinitionWidgetParams;

class VMAnswer {
    constructor(readonly model: MyModel, readonly ansData: MultipleChoiceTextData_Answer) {

    }
    public readonly value = ko.pureComputed(() => {
        return this.ansData.value;
    });
    public readonly ansId = ko.pureComputed(() => { return this.ansData.id; });

    public readonly isSelected = ko.pureComputed(() => this.ansData.isSelected());
    public readonly isNotSelected = ko.pureComputed(() => !this.isSelected());
    public readonly isCorrect = ko.pureComputed(() => this.ansData.isCorrectAnswer);

    public readonly click = new UIAction('MultiplechoiceAnswerClick', async () => {
        await this.model.clickAnswer.intent(this.ansId());
    });

    public readonly evaluationRemark = ko.pureComputed(() => {
        const d = this.ansData;
        return d && d.evaluationRemark;
    });

}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.itemDocId = 'item,' + this.itemId;
        this.sessionId = params.sessionId;
        this.mode(params.mode || 'INTERACTIVE');

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof MultipleChoiceTextData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: MultipleChoiceTextData;


    public readonly evaluationRemark = ko.pureComputed(() => this.data.evaluationRemark);
    private golemState: string[];
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                const answers = this.shuffledAnswers();
                if (!this.golemState) {
                    this.golemState = [];
                    const golemData = getGolemData(this.itemId);
                    const ans = answers.map(x => ({
                        key: x.ansData.id,
                        value: x.ansData.value,
                    }));

                    if (isStringArray(golemData)) {
                        for (const partial of golemData) {
                            const ansId = golemMatch(ans, partial);
                            if (ansId) {
                                this.golemState.push(ansId);
                            } else {
                                error(`Golem found no match for ${partial} in item ${this.itemId}`);
                            }
                        }
                    } else {
                        const idx = [];
                        for (let n = this.data.minSelectionLimit; n <= this.data.maxSelectionLimit; ++n) {
                            idx.push(n);
                        }
                        const toSelect = getRandomEntry(idx);
                        this.golemState = pickItems(ans.map(x => x.key), toSelect, { static: false });
                    }
                }
                if (!this.golemState.length) {
                    return false;
                }
                const id = this.golemState.shift();
                const answer = answers.find(x => x.ansData.id === id);
                await answer.click.invoke();
                return true;
            }
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly shuffledAnswers = ko.pureComputed(() => {
        return this.data.answers.map(x => new VMAnswer(this, x));
    });

    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly hint = ko.pureComputed(() => {
        const d = this.data;
        const selected = this.shuffledAnswers().filter(x => x.isSelected()).length;
        const alwaysShowHint = true; //todo - this should probably be configurable in item settings
        if (d.maxSelectionLimit === d.minSelectionLimit) {
            if (alwaysShowHint || (selected !== d.minSelectionLimit)) {
                return formatMessage(i18next.t(['itemdefinition.lavak.multiplechoice.YOU_HAVE_TO_SELECT_EXACTLY_MINSELECTIONLIMIT_ANSWERS']), { minSelectionLimit: d.minSelectionLimit });
            }
        } else {
            if (alwaysShowHint || (selected < d.minSelectionLimit || selected > d.maxSelectionLimit)) {
                return formatMessage(i18next.t(['itemdefinition.lavak.multiplechoice.YOU_HAVE_TO_SELECT_BETWEEN_MINSELECTIONLIMIT_AND_MAXSELECTIONLIMIT_ANSWERS']), {
                    minSelectionLimit: d.minSelectionLimit,
                    maxSelectionLimit: d.maxSelectionLimit
                });
            }
        }
        return '';
    });

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly clickAnswer = new UIAction<string>(undefined, async (e, answerId) => {
        await this.data.switchAnswer(answerId);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { parseTable } from '../../resultscreens/default/tables';
import * as i18n from './../../../i18n/i18n';
import { Item } from './Item';
import { ItemOverviewBodyFieldTemplate } from './ItemOverviewBodyFieldTemplate';
import { ItemOverviewHeaderField } from './ItemOverviewHeaderField';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'ui-widgets-itemoverviewtable';

export interface IParams {
    items: ko.MaybeSubscribable<Item[]>;
    itemOverviewHeader: ko.MaybeSubscribable<ItemOverviewHeaderField[]>

}
export class ViewModel extends AbstractWidget {

    constructor(readonly params: IParams) {
        super();
    }
    public readonly itemOverviewHeader = ko.pureComputed(() => {
        return ko.unwrap(this.params.itemOverviewHeader);
    });

    public readonly items = ko.pureComputed(() => {
        return ko.unwrap(this.params.items);
    });
}

export function parseItemTable(template: string) {
    const replace = (val: string) => {
        return val
            .replace(/\{id\}/g, i18n.t(['ui.resultscreens.default.ITEM_ID']))
            .replace(/\{index\}/g, i18n.t(['ui.resultscreens.default.ITEM_INDEX']))
            .replace(/\{title\}/g, i18n.t(['ui.resultscreens.default.ITEM_TITLE']))
            .replace(/\{pointsPercent\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_PERCENT']))
            .replace(/\{pointsGained\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_GAINED']))
            .replace(/\{pointsPending\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_PENDING']))
            .replace(/\{pointsLost\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_LOST']))
            .replace(/\{pointsMax\}/g, i18n.t(['ui.resultscreens.default.ITEM_POINTS_MAX']))
            .replace(/\{score\}/g, i18n.t(['ui.resultscreens.default.ITEM_SCORE']))
            ;
    };

    return parseTable(template, replace, field => new ItemOverviewHeaderField(field), field => new ItemOverviewBodyFieldTemplate(field, ''));
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import { log } from '../../../../debug';
import { FormBuilder } from '../../../../dxHelper/formBuilder';
import { AS, datagrid, dg_addTreeList, IDxWidget } from '../../../../dx_helper';
import { getAncestorsAndSelf } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import { IUpdateItemMetaDataInput, IUpdateWorkflowDataInput } from '../../../../its-itembank-api.g';
import { ObjectiveStore } from '../../../author/itemedit/itemmetadatablock/ObjectiveStore';
import { UIAction } from '../../../uiAction';
import * as i18n from './../../../../i18n/i18n';
import * as i18next from './../../../../i18n/i18n';
import { SyllabusStore } from './SyllabusStore';

export type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_itemreview_metadata']>>;
export type CHILDITEM = Q['item']['get']['childItems'][0];
export type ALL_AUDIENCE = Q['item']['get']['subject']['audiences'][0];
export type ALL_AUTHOR = Q['item']['get']['subject']['authors'][0];

export type OBJECTIVE = Q['item']['get']['metaData']['objectives'][0];

export type D = Q['item']['get'] & {
    translatedAuthoringStatus: string;
    itemTypeDisplay: string;
    audiences: string[];
    alternations: string[];
    //objectiveId: string[];
    //objectiveScore: number;
};
type U1 = Omit<IUpdateItemMetaDataInput, "itemId">;
type U2 = Omit<IUpdateWorkflowDataInput, "itemId">;

export type U = {
    metadata?: U1,
    workflow?: U2,
    objectiveId?: string;
    objectiveScore?: number;
    currentAuthor?: string;
};

export class MyFormBuilder extends FormBuilder<D, U> {
    constructor(readonly vm: IDxWidget & {
        WIDGET_NAME: string;
        hasObjectives: ko.Subscribable<boolean>;
        isSubItem: ko.Subscribable<boolean>;
        isContainer: ko.Subscribable<boolean>;
        subjectId: ko.Subscribable<string>;
        itemLocked: ko.Subscribable<boolean>;
        itemDone: ko.Subscribable<boolean>;
        itemUnlocked: ko.Subscribable<boolean>;
        objectiveStore: ObjectiveStore;
        displayCopiedFrom: ko.Subscribable<boolean>;
        displayRevisionFrom: ko.Subscribable<boolean>;
        syllabusStore: SyllabusStore;
        categoriesStore: CustomStore;
        alternationsStore: CustomStore;
        allAudiences: ALL_AUDIENCE[];
        allAuthors: ALL_AUTHOR[];
        actionRevise: UIAction<unknown>,
        examOrderGrid: ko.Subscribable<DevExpress.ui.dxDataGrid.Properties>;
        subItemGrid: ko.Subscribable<DevExpress.ui.dxDataGrid.Properties>;
    }) {
        super();
    }
    private whenOpen<T>(handler: (input: T) => U) {
        if (this.vm.itemLocked()) {
            return undefined;
        }
        if (this.vm.itemDone()) {
            return undefined;
        }
        return handler;
    }
    private whenUnlocked<T>(handler: (input: T) => U) {
        if (this.vm.itemLocked()) {
            return undefined;
        }
        return handler;
    }
    protected onInit() {
        super.onInit();

        this.initCommon();
        this.initWorkflow();
        this.initInApp();
        this.initSubItems();
        this.initExamOrders();
    }

    private initSubItems() {
        if (!this.vm.isContainer()) {
            return;
        }
        const g = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.SUBITEMS']),
            colCount: 2,
        });
        this.addDataGrid(g, {
            editorOptions: this.vm.subItemGrid()
        });
    }

    private initExamOrders() {
        if (this.vm.isSubItem()) {
            return;
        }
        const g = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.ADD_REMOVE_ITEM_TO_EXAM_ORDER']),
            colCount: 2,
        });

        this.addDataGrid(g, {
            editorOptions: this.vm.examOrderGrid()
        });
    }
    private initWorkflow() {
        if (this.vm.isSubItem()) {
            return;
        }
        const g = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.AUTHORING_WORKFLOW']),
            colCount: 2,
        });

        this.addSelectBox<string>(g, {
            label: {
                text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.EDITED_BY']),
            },
            dataField: 'workflow.currentAuthor.docReferenceId',
            editorOptions: {
                dataSource: this.vm.allAuthors,
                displayExpr: 'displayName',
                valueExpr: 'docReferenceId',
                applyValueMode: 'useButtons',
                openOnFieldClick: true,
                deferRendering: true,
                acceptCustomValue: false,
                searchEnabled: true,
            }
        }, this.whenOpen(val => ({ workflow: { currentAuthor: val } })));

        this.addCheckbox(g, {
            label: {
                text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.IS_HIDDEN_FOR_AUTHOR'])
            },
            dataField: 'workflow.isHiddenForAuthor'
        }, val => ({ workflow: { isHiddenForAuthor: val } }));

        this.addTextArea(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.NOTE']),
            },
            dataField: 'workflow.noteForAuthor',
            editorOptions: {
                autoResizeEnabled: true
            }
        }, this.whenOpen(val => ({ workflow: { noteForAuthor: val } })));

        this.addCheckbox(g, {
            label: {
                text: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.OBSOLETE']),
            },
            dataField: 'workflow.obsolete'
        }, val => ({ workflow: { obsolete: val } }));

        if (this.vm.displayCopiedFrom()) {
            this.addTextBox(g, {
                label: {
                    text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.COPIED_FROM'])
                },
                dataField: 'metaData.copyFrom.itemId',
            });
        }
        if (this.vm.displayRevisionFrom()) {
            this.addTextArea(g, {
                label: {
                    text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ORIGINAL_ITEM'])
                },
                dataField: 'metaData.revisionFrom.itemId',
            });
        }

    }

    private initInApp() {
        const g = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.SETTINGS_FOR_INAPPLICATION_ITEMS']),
            colCount: 2,
        });
        this.addNumberBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.MINIMUM_WIDTH'])
            },
            dataField: 'metaData.minWidth',
            editorOptions: {
                min: 0,
                max: 4000,
                format: { type: 'decimal', precision: 0 },
            },
        });

        this.addNumberBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.MINIMUM_HEIGHT'])
            },
            dataField: 'metaData.minHeight',
            editorOptions: {
                min: 0,
                max: 4000,
                format: { type: 'decimal', precision: 0 },
            },

        });

        if (!this.vm.isSubItem()) {

            this.addTreeList(g,
                {
                    label: {
                        text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ALTERNATIONS']),
                    },
                    dataField: 'alternations',
                    editorOptions: {
                        dataSource: {
                            store: this.vm.alternationsStore,
                        },
                        displayExpr: 'display',
                        valueExpr: 'id',
                        dropDownOptions: {
                            height: 'auto',
                        },
                    }
                },
                {
                    height: 400,
                    autoExpandAll: true,
                    searchPanel: {
                        visible: false
                    },
                    selection: {
                        mode: 'multiple'
                    },
                    rootValue: '',
                    columns: [
                        {
                            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ID']),
                            dataField: 'id',
                            width: '150'
                        },
                        {
                            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.NAME']),
                            dataField: 'display'
                        }
                    ],
                    editing: {
                        allowAdding: false,
                        allowDeleting: false,
                        allowUpdating: false,
                    },

                });
        }
    }
    private initCommon() {
        const g = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.subjectcoordinator.itemreview.metadata.myformbuilder.COMMON_ITEM_DATA']),
            colCount: 2,
        });

        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.TITLE'])
            },
            dataField: 'metaData.title',
            colSpan: 2,
        }, this.whenOpen(val => ({ metadata: { title: val } })));

        this.initObjectives(g);

        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.STATUS']),
            },
            dataField: 'translatedAuthoringStatus',
        });

        this.addTagBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.AUDIENCE']),
            },
            dataField: 'audiences',
            editorOptions: {
                dataSource: this.vm.allAudiences,
                displayExpr: 'audienceId',
                valueExpr: 'docReferenceId',
                showSelectionControls: true,
                applyValueMode: 'useButtons',
                readOnly: false,
                placeholder: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.SELECT'])
            }
        });

        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ITEM_TYPE']),
            },
            dataField: 'itemTypeDisplay',
        });

        this.addTextBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.LANGUAGE_OF_ITEM']),
            },
            dataField: 'metaData.defaultLocalization.name.value',
        });


    }


    private initObjectives(g: DevExpress.ui.dxForm.Properties) {
        if (!this.vm.hasObjectives()) {
            return;
        }
        this.addLookup<string>(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.OBJECTIVE']),
            },
            dataField: 'metaData.category.taxon',
            editorOptions: {
                displayExpr: 'display',
                valueExpr: 'id',
                dataSource: {
                    store: this.vm.categoriesStore
                }
            }

        }, this.whenUnlocked(val => ({ metadata: { categoryId: val } })));

        /*
        this.addNumberBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.POINTS']),
            },
            dataField: 'objectiveScore',
            editorOptions: {
                min: 0,
            }
        });
*/
        this.addNumberBox(g, {
            label: {
                text: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.DIFFICULTY']),
            },
            dataField: 'metaData.itemDifficultyIndex',
            editorOptions: {
                min: 0,
                max: 100
            }
        });


        const objectivesDg = datagrid({
            WIDGET_NAME: this.vm.WIDGET_NAME,
            discriminator: 'objectivesDg#1',
            widget: this.vm,
            config: {
                editing: {
                    allowAdding: false,
                    allowDeleting: false,
                    allowUpdating: this.vm.itemUnlocked(),
                    mode: 'cell',
                },
                dataSource: {
                    store: this.vm.objectiveStore,
                },
            }
        });

        dg_addTreeList({
            grid: objectivesDg,
            column: {
                dataType: 'string',
                dataField: 'objectiveId',
                caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.CATEGORY']),
                lookup: {
                    displayExpr: 'display',
                    valueExpr: 'id',
                    dataSource: {
                        store: this.vm.syllabusStore
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                    },
                ],
                cellTemplate: (e, cellInfo) => {
                    const objId = cellInfo.value;
                    return this.htmlForObjective(objId);
                },
            },
            /*
            fieldTemplate: (value: { objectiveId: string }) => {
                log(`Field template: ${JSON.stringify(value)}`);
                return this.htmlForObjective(value.objectiveId);
            },
            */
            fieldTemplate: (value: string) => {
                log(`Field template: ${JSON.stringify(value)}`);
                return this.htmlForObjective(value);
            },
            treeListOptions: {
                columns: [
                    {
                        caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.ID']),
                        dataField: 'id',
                        width: '150'
                    },
                    {
                        caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.NAME']),
                        dataField: 'display'
                    },
                ]
            }
        });

        objectivesDg.columns.push({
            dataType: 'number',
            dataField: 'objectiveScore',
            caption: i18next.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.POINTS']),
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
        });

        this.addDataGrid(g, {
            label: {
                text: i18n.t(['ui.author.itemedit.itemmetadatablock.myformbuilder.SCORING']),
            },
            editorOptions: objectivesDg,
        });
    }

    htmlForObjective(objId: string) {

        const texts = getAncestorsAndSelf(objId).reverse().map(x => `<b>${x}</b> ${this.vm.syllabusStore.getDisplay(x)}`);
        return $('<div />').append(
            $('<div />').dxTextBox({ value: objId, readOnly: true }).css('display', 'none'),
            $('<div />').html(texts.join('<br />'))
        );

    }
}

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoEssay_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;

export class EssayData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'KosovoEssay_QueryItemSessionData') {
            throw new Error();
        }
        this.essayText(data.essayText);
    }
    public readonly questionHTML: string;
    public readonly headerText: string;
    public readonly essayText = ko.observable<string>();
    public async setAnswer(text: string) {
        this.essayText(text);
    }

    public IsInteractionStarted() {
        return !!this.essayText();
    }
    public IsInteractionComplete() {
        return !!this.essayText();
    }
    public async reset() {
        this.essayText('');
        return DONE;
    }

    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            Essay: {
                text: this.essayText(),
            }
        };
        return retVal;
    }

}

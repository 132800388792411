import * as ko from 'knockout';
import { TypingTest2023_DisplayTemplateText, TypingTest2023_Fragment_Mode } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { TypingTest2023Data } from '../../model/typingtest2023/TypingTestData';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';


export type IParams = IItemDefinitionWidgetParams;
type Line = TypingTest2023Data['rawData']['tt2023Lines'][0];

const WIDGET_NAME = 'itemdefinition-typingtest2023-result';

export class ViewModel extends AbstractItemDefinition {
    public itemId: string;
    public readonly data: TypingTest2023Data;

    constructor(readonly params: IParams, readonly rootElem: HTMLElement) {
        super();

        this.itemId = params.itemId;
        const item = GetSession(this.params.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof TypingTest2023Data)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly displayTemplate = ko.pureComputed(() => {
        return this.data.rawData.tt2023ReportDisplayTemplateText === TypingTest2023_DisplayTemplateText.Yes;
    });
    public readonly sectionCombined = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.COMBINED']);
    });
    public readonly explanationsectionCombined = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.COMBINEDEXPLAIN']);
    });
    public readonly sectionTyped = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.TYPED']);
    });
    public readonly sectionTemplate = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.TEMPLATE']);
    });
    public readonly messageNoCombinedResult = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.NO_COMBINED_RESULT']);
    });
    public readonly writtenLengthText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.WRITTEN_LENGTH']);
    });
    public readonly errorsText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.ERRORS']);
    });
    public readonly errorQuotaText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.ERROR_QUOTA']);
    });
    public readonly strokesPerMinuteText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.result.STROKES_PER_MINUTE']);
    });
    public readonly strokesPerMinute = ko.pureComputed(() => {
        return this.data.rawData.tt2023StrokesPerMinute ?? 0;
    });
    public readonly writtenLength = ko.pureComputed(() => this.data.rawData.tt2023TextLength);
    public readonly errors = ko.pureComputed(() => this.data.errors());
    public readonly errorQuota = ko.pureComputed(() => {
        if (!this.writtenLength()) {
            return '0%';
        }
        return (Math.round(this.errors() * 100 * 100 / this.writtenLength()) / 100) + '%';
    });

    public readonly loaded = ko.observable(false);

    //public readonly output = ko.observable('');


    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly lines = ko.observable<Line[]>([]);
    public readonly showCombined = ko.observable(true);
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.lines(this.data.rawData.tt2023Lines);
        this.showCombined(this.lines().every(x => x.errors < 60));
        /*
        const outputHtml = this.data.rawData.tt2023Lines.map(x => {
            const pointsSpan = x.errorPoints > 0 ? `<span class="errorPoints"><span>${x.errorPoints}</span></span>` : '';
            switch (x.mode) {
                case TypingTest2023_Fragment_Mode.Normal:
                    return x.text;
                case TypingTest2023_Fragment_Mode.Added:
                    return `<span class="added">${x.text}${pointsSpan}</span>`;
                case TypingTest2023_Fragment_Mode.Removed:
                    return `<span class="removed">${x.text}${pointsSpan}</span>`;
                default:
                    throw new Error();
            }
        }).join('');

        this.output(outputHtml);
        */
        this.loaded(true);
    }

    dispose() {
        super.dispose();
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo.element as HTMLElement);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import { greekToLatin } from '../../../new_array';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'LavakFillinPool_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type VALUE = DATA['values'][0];
type GAP = DATA['fipGaps'][0];

export class FillInPoolData_PoolValue {
    public readonly id: string;
    public readonly latinId: string;
    constructor(readonly model: FillInPoolData, readonly value: VALUE) {
        this.id = this.value.id;
        this.latinId = greekToLatin(this.value.id.substr(-1));
    }

    public text = ko.pureComputed(() => {
        const text = translate(this.value.value, {});
        return RICHTEXTHTML.process({
            inline: true,
            html: text,
            attachments: this.model.meta.attachments,
        });
    });

    public readonly isAvailable = ko.pureComputed(() => {
        return !this.model.gaps.find(val => val.selectedValue() === this.id);
    });

}

export class FillInPoolData_Gap {
    public readonly gap: GAP;
    constructor(readonly model: FillInPoolData, readonly gapId: string) {
        this.gap = this.model._data.fipGaps.find(val => val.id === gapId);
        this.correctValue = this.gap.correctValue;
        this.evaluationRemark = this.gap.evaluationRemark;
        this.selectedValue(this.gap.selectedValue);
    }
    public readonly correctValue: string;
    public readonly evaluationRemark: string;
    public readonly selectedValue = ko.observable<string>();


}

export class FillInPoolData_Text {
    constructor(readonly model: FillInPoolData, readonly fragmentText: string) {
    }
    public readonly text = RICHTEXTHTML.process({
        inline: true,
        html: this.fragmentText,
        attachments: this.model.meta.attachments,
    });
}


export class FillInPoolData extends ItemDataModel {
    public readonly possibilities: FillInPoolData_PoolValue[];

    constructor(readonly meta: ItemModel, readonly _data: DATA) {
        super();
        this.headerText = translate(_data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(_data.question, {}),
            attachments: this.meta.attachments,
        });

        this.possibilities = _data.values.map((value, index) => {
            return new FillInPoolData_PoolValue(this, value);
        });

        this.evaluationRemark = _data.evaluationRemark;

        const templateText = translate(_data.fipText, {});
        const regEx = /\$\(gap:([^)]+)\)/g;
        const fragments = templateText.split(regEx);
        this.fragments = fragments.map((text, i) => {
            if (i % 2 === 0) {
                return new FillInPoolData_Text(this, fragments[i]);
            } else {
                return new FillInPoolData_Gap(this, fragments[i]);
            }
        });
        this.gaps = <FillInPoolData_Gap[]>this.fragments.filter(x => x instanceof FillInPoolData_Gap);
        this.fromJS(_data);
    }
    public fromJS(data: DATA) {
    }
    public readonly headerText: string;
    public readonly questionHTML: string;
    public readonly fragments: Array<FillInPoolData_Gap | FillInPoolData_Text>;
    public readonly evaluationRemark: string;

    public readonly gaps: FillInPoolData_Gap[];
    public async assign({ gapId, valueId }: { gapId: string, valueId: string }) {
        const gap = this.gaps.find(x => x.gapId === gapId);
        if (!gap) {
            throw new Error();
        }
        for (const g of this.gaps.filter(x => x.gapId !== gapId)) {
            if (g.selectedValue() === valueId) {
                g.selectedValue(undefined);
            }
        }

        if (gap && gap instanceof FillInPoolData_Gap) {
            gap.selectedValue(valueId);
        }
    }

    public IsInteractionStarted() {
        return this.gaps.some(x => !!x.selectedValue());
    }
    public IsInteractionComplete() {
        return this.gaps.every(x => !!x.selectedValue());
    }
    public async reset() {
        for (const gap of this.gaps) {
            gap.selectedValue(undefined);
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            FillInPool: {
                gaps: this.gaps.map(x => ({
                    gapId: x.gapId,
                    valueId: x.selectedValue(),
                }))
            }
        };
        return retVal;
    }

}
import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoCrossout_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type LINE = DATA['lines'][0];
type FRAGMENT = LINE['fragments'][0];

export class CrossoutData_Line_Fragment {
    public readonly id: string;
    constructor(private readonly model: CrossoutData, private readonly line: CrossoutData_Line, public readonly rawData: FRAGMENT) {
        this.id = this.rawData.id;
        this.value = this.rawData.value;
        this.isCorrect = this.id === this.line.incorrectFragment;
    }
    public readonly value: string;
    public readonly isCorrect: boolean;
    public readonly isSelected = ko.pureComputed(() => {
        return this.id === this.line.selectedFragment();
    });
}
export class CrossoutData_Line {
    constructor(private readonly model: CrossoutData, public readonly rawData: LINE) {
        this.id = rawData.id;
        this.incorrectFragment = rawData.incorrectFragment;
        this.selectedFragment(rawData.selectedFragment);
        this.fragments = rawData.fragments.map(x => new CrossoutData_Line_Fragment(model, this, x));
    }
    public readonly id: string;
    public readonly fragments: CrossoutData_Line_Fragment[];
    public readonly selectedFragment = ko.observable<string>();
    public readonly incorrectFragment: string;

    public readonly isCorrect = ko.pureComputed(() => {
        return this.incorrectFragment === `${this.id}/Fragment:#`;


    });
    public readonly isLineCorrect = ko.pureComputed(() => {
        return this.selectedFragment() === `${this.id}/Fragment:#`;

    });

}

export class CrossoutData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.lines = data.lines.map(a => new CrossoutData_Line(this, a));
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: meta.attachments,
        });
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'KosovoCrossout_QueryItemSessionData') {
            throw new Error();
        }
        for (const d of data.lines) {
            const a = this.lines.find(x => x.id === d.id);
            a.selectedFragment(d.selectedFragment);
        }
    }
    public readonly questionHTML: string;
    public readonly lines: CrossoutData_Line[];
    public readonly headerText: string;
    public async selectAnswer({ fragmentId, lineId }: { fragmentId: string, lineId: string }) {

        const l = this.lines.find(x => x.id === lineId);
        l.selectedFragment(fragmentId);
    }

    public IsInteractionStarted() {
        return this.lines.some(x => !!x.selectedFragment());
    }
    public IsInteractionComplete() {
        return this.lines.every(x => !!x.selectedFragment());
    }

    public async reset() {
        for (const line of this.lines) {
            line.selectedFragment(undefined);
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            CrossOut: {
                lines: this.lines.map(x => ({
                    lineId: x.id,
                    fragmentId: x.selectedFragment()
                }))
            }
        };
        return retVal;
    }
}

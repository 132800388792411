import * as ko from 'knockout';
import { dir, log } from '../../../debug';
import { getGolemData, isStringMap } from '../../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { translate } from '../../model/ItemDataModel';
import { GetSession } from '../../model/session';
import { TextEntryManualData, TextEntryManualData_Gap, TextEntryManualData_Text } from '../../model/textentry_manual/TextEntryManualData';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './textentry_manual.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-textentry_manual';

const GREMLIN_TEXTENTRY = 'xxx';

class Text implements IGapOrText {
    public readonly html: string;
    constructor(readonly model: MyModel, public readonly data: TextEntryManualData_Text) {
        this.html = data.html;
    }
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }
}

interface IGapOrText {
    readonly isText: () => this is Text;
    readonly isGap: () => this is Gap;
}

class Gap implements IGapOrText {
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }
    public readonly gap: TextEntryManualData_Gap;
    public readonly value: ko.Observable<string>;
    constructor(readonly model: MyModel, readonly gapId: string) {
        this.gap = model.data.gaps.find(gap => gap.gapId === gapId);
        this.value = this.gap.givenAnswer;
    }

    public readonly isGapEmpty = ko.pureComputed(() => {
        const val = this.value();
        if ((val === '') || (!val)) {
            return true;
        }
        return false;
    });

    public readonly gapCorrectText = ko.pureComputed(() => {
        const correct = i18next.t(['itemdefinition.kosovo.textentry_auto.CORRECT']);
        const knownCorrect = this.gap.gap.correctAnswers;
        if (!knownCorrect) {
            return this.gap.givenAnswer();
        }
        return `${this.gap.givenAnswer()} (${correct}: ${translate(knownCorrect[0].value, this.model.params)})`;
    });
    /*
        public readonly gapCorrectText = ko.pureComputed(() => {
            if (!this.gap.correctAnswer) {
                return undefined;
            }
            const ans = this.model.data._data.temAnswers.find(x => x.id === this.gap.correctAnswer);
            if (!ans) {
                return undefined;
            }
            return ans.value.value;
        });
        */
    public readonly isCorrect = ko.pureComputed(() => {
        return this.gap.gap.isCorrect;
    });
}

export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof TextEntryManualData)) {
            throw new Error();
        }
        this.data = data;

        this.fragments = this.data.fragments.map(x => {
            if (x instanceof TextEntryManualData_Gap) {
                return new Gap(this, x.gapId);
            }
            if (x instanceof TextEntryManualData_Text) {
                return new Text(this, x);
            }
            throw new Error();
        });
        this.gaps = <Gap[]>this.fragments.filter(x => x instanceof Gap);

    }

    public readonly data: TextEntryManualData;

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        for (const f of this.gaps) {
            if (f.isGapEmpty()) {
                const golemData = getGolemData(this.itemId);
                let val = GREMLIN_TEXTENTRY;
                dir({
                    golemData,
                    isStringMap: isStringMap(golemData),
                    f
                });
                if (isStringMap(golemData)) {
                    const x = golemData[f.gap.gap.gapId];
                    if (typeof x === 'string') {
                        val = x;
                    }
                }
                f.value(val);
                return true;
            }
        }
        return false;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);


        this.loaded(true);


    }


    public readonly fragments: Array<Gap | Text>;
    public readonly gaps: Gap[];

    public readonly hasImage = ko.pureComputed(() => this.data.hasImage);
    public readonly imgUrl = ko.pureComputed(() => this.data.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.data.imgAlt);

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());



    public readonly gapPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_manual.ANSWER']);
    });

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_manual.ENTER_THE_TEXT_HERE']);
    });

    public readonly enterAnswerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.textentry_manual.ENTER_THE_ANSWER_HERE']);
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

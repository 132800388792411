import { error, log } from '../../../debug';

let scormWindow: Window | undefined | false = undefined;

export function detectScormWindow() {
    if (scormWindow) {
        return scormWindow;
    }
    if (scormWindow === false) {
        return undefined;
    }
    try {
        if (window.parent && window.name === 'scorm1.2') {
            log(`DETECTED SCORM INTEGRATION`);
            scormWindow = window.parent;
            return scormWindow;
        }
    } catch (e) {
        error(e);
    }
    log(`NO SCORM INTEGRATION DETECTED`);
    scormWindow = false;
    return undefined;
}

export interface ScormExitMessage {
    message: 'scorm_exit'
}

export function sendScormData(payload: string) {
    if (!payload) {
        return;
    }
    const wnd = detectScormWindow();
    if (!wnd) {
        return;
    }
    wnd.postMessage({ message: 'scorm_data', payload }, '*');
}

export function sendScormExit(scormExit: ScormExitMessage) {
    const wnd = detectScormWindow();
    if (!wnd) {
        return;
    }
    wnd.postMessage(scormExit, '*');
}

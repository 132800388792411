import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput, IUpdateItemStateInput_SpellingMatrix_Value, SpellingMatrix_GapFormat } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";
//export { SpellingMatrix_GapFormat } from '../init.query.graphql.g';

function getData(item: INIT) {
    if (item.data.__typename === 'SpellingMatrix_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type TABLE = DATA['smTables'][0];
type VALUE = TABLE['smValues'][0];

export class Value {
    constructor(private readonly model: SpellingMatrixData, public readonly table: TABLE, public readonly rawData: VALUE) {
        this.id = rawData.id;
        const parts = rawData.label.split('__');
        this.gapFormat = rawData.gapFormat;
        this.preGap = parts[0];
        this.postGap = parts[1];
        this.fromJS(rawData);
    }
    public readonly gapFormat: SpellingMatrix_GapFormat;
    public readonly id: string;
    public readonly caption: string;
    public readonly preGap: string;
    public readonly postGap: string;
    public readonly given = ko.observable('');

    public fromJS(data: VALUE) {
        this.given(data.given || '');
    }
}

export class Table {
    constructor(private readonly model: SpellingMatrixData, public readonly rawData: TABLE) {
        this.id = rawData.id;
        this.caption = translate(rawData.smCaption, {});
        this.answers = rawData.smAnswers;
        this.values = rawData.smValues.map(x => new Value(model, rawData, x));
    }

    public readonly id: string;
    public readonly answers: string[];
    public readonly caption: string;
    public readonly values: Value[];
    public fromJS(data: TABLE) {
        for (const srcVal of data.smValues) {
            const tgtVal = this.values.find(x => x.id == srcVal.id);
            tgtVal.fromJS(srcVal);
        }
    }
}

export class SpellingMatrixData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        //this.correctAnswer = data.correctAnswer;
        this.headerText = translate(data.header, {});
        this.textHTML = RICHTEXTHTML.process({
            html: translate(data.smText, {}),
            attachments: this.meta.attachments,
        });

        this.tables = data.smTables.map(a => new Table(this, a));
        this.fromJS(data);
    }

    public fromJS(data: DATA) {
        if (data.__typename !== 'SpellingMatrix_QueryItemSessionData') {
            throw new Error();
        }
        for (const srcTb of data.smTables) {
            const tgtTb = this.tables.find(x => x.id == srcTb.id);
            tgtTb.fromJS(srcTb);
        }
    }
    public readonly textHTML: string;
    public readonly tables: Table[];
    public readonly headerText: string;

    private getGiven(): { total: number, data: IUpdateItemStateInput_SpellingMatrix_Value[] } {
        const retVal: { total: number, data: IUpdateItemStateInput_SpellingMatrix_Value[] } = {
            total: 0,
            data: [],
        };
        for (const table of this.tables) {
            for (const val of table.values) {
                retVal.total++;
                const given = val.given();
                if (!given) {
                    continue;
                }
                retVal.data.push({
                    id: val.id,
                    value: val.given()
                });
            }
        }
        return retVal;
    }
    public IsInteractionStarted() {
        const given = this.getGiven();
        return given.data.length > 0;
    }
    public IsInteractionComplete() {
        const given = this.getGiven();
        return given.data.length === given.total;
    }
    public async reset() {
        for (const table of this.tables) {
            for (const val of table.values) {
                val.given('');
            }
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SpellingMatrix: {
                given: this.getGiven().data,
            },
        };
        return retVal;
    }

}

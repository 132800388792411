﻿import STABLE_STRINGIFY from 'json-stable-stringify';
import * as ko from 'knockout';
import * as Rx from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { dir, error, log } from './debug';
import { ILangStringData } from './interfaces';
import * as arrayHelper from './new_array';

export function emptyStringWhenSet<T extends { [key: string]: string }, TKey extends keyof T>(obj: T, key: TKey) {
    if (key in obj) {
        return obj[key] || '';
    }
    return undefined;
}

export function AtoZZ(existing: Set<string>) {
    for (let i = 1; ; ++i) {
        const val = ToBase26(i);
        if (!existing.has(val)) {
            return val;
        }
    }
}

export function ToBase26(myNumber: number): string {
    const array: number[] = [];

    while (myNumber > 26) {
        const value = myNumber % 26;
        if (value == 0) {
            myNumber = myNumber / 26 - 1;
            array.unshift(26);
        }
        else {
            myNumber /= 26;
            array.unshift(value);
        }
    }

    if (myNumber > 0) {
        array.unshift(myNumber);
    }
    return array.map(s => String.fromCharCode(65 + s - 1)).join('');
}

export enum DONEENUM {
    DONE,
}
export const DONE = DONEENUM.DONE;


let htmlIdCounter = 0;

type AsyncConverter<TIn, TOut> = (input: TIn) => Promise<TOut>;

export function isSame<T>(a: T, b: T) {
    return STABLE_STRINGIFY(a) === STABLE_STRINGIFY(b);
}

export function joinStrings(sep: string, ...str: string[]) {
    if (!str) {
        return '';
    }
    return str.filter(x => !!x).join(sep);
}

export function addAll<T>(target: Set<T>, values: Iterable<T> | ArrayLike<T>) {
    if (!values || !target) {
        return;
    }
    for (const val of Array.from(values)) {
        target.add(val);
    }
}
export function isAbsoluteUrl(url: string) {
    return url.indexOf('://') > 0;
}

export function unique<T>(arr: T[], getId: (data: T) => string) {
    if (!arr) {
        return [];
    }
    const visited = new Set<string>();
    return arr.filter(x => {
        const id = getId(x);
        if (visited.has(id)) {
            return false;
        }
        visited.add(id);
        return true;
    });
}
export function feed<TIn, TOut>(
    rxSrc: Rx.Observable<TIn>,
    koDst: ko.Observable<TOut> | ko.Computed<TOut>,
    converter: AsyncConverter<TIn, TOut>,
    disposables: Disposables) {

    disposables.addSubscription(rxSrc.pipe(mergeMap(async x => await converter(x))).subscribe({
        next: d => {
            log(`feed: updating to ${d}`);
            koDst(d);
        },
        error: e => {
            dir(e);
        }
    }));
}
export function sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
}
export function htmlId() {
    return `__autoid_${htmlIdCounter++}`;
}
export function assertNever(x: never): never {
    throw new Error(`Unexpected object: ${JSON.stringify(x)}`);
}

//HACK: Needed for IE
export interface IWindowClipboardExtension extends Window {
    clipboardData: DataTransfer;
}

export interface IUpdaterOptions {
    changeDetector: () => string;
    updater: () => Promise<void>;
    moduleName?: string;
    debugId?: string;
}


export interface IUnsubsribe {
    unsubscribe(): void;
}
export interface IDisposeable {
    dispose(): void;
}

export class Disposables implements IDisposeable {
    private readonly disposables = new Array<IDisposeable>();
    private readonly subscriptions = new Array<IUnsubsribe>();

    public onChange<T>(ko: ko.Subscribable<T>, action: (val: T) => Promise<T>) {
        const subj = new Rx.Subject<T>();
        this.addDiposable(ko.subscribe(val => subj.next(val)));
        this.addSubscription(subj.asObservable().pipe(mergeMap(action)).subscribe());
    }


    public addDiposable(obj: IDisposeable) {
        this.disposables.push(obj);
    }
    public addSubscription<T extends IUnsubsribe>(obj: T) {
        this.subscriptions.push(obj);
        return obj;
    }
    public dispose() {
        const ds = this.disposables.splice(0, this.disposables.length);
        for (const d of ds) {
            if (d) {
                d.dispose();
            }
        }
        const us = this.subscriptions.splice(0, this.subscriptions.length);
        for (const u of us) {
            if (u) {
                u.unsubscribe();
            }
        }
    }
}
export async function updater(options: IUpdaterOptions) {
    const baseObj = ko.computed(() => {
        return options.changeDetector();
    });
    const limiter = baseObj.extend({ rateLimit: { method: 'notifyWhenChangesStop', timeout: 400 } });

    let needsUpdate = false;
    let isUpdateRunning = false;
    const baseMsg = `${options.moduleName} (${options.debugId})`;
    log(`${baseMsg}: created updater`);
    const updater = async () => {
        needsUpdate = true;
        if (isUpdateRunning) {
            log(`${baseMsg}: scheduled update`);
            return;
        }
        isUpdateRunning = true;
        try {
            while (needsUpdate) {
                needsUpdate = false;
                log(`${baseMsg}: running updater async`);
                await options.updater();
                log(`${baseMsg}: completed updater async`);
            }
        } catch (err) {
            error(`${baseMsg}: Updater throws error ${err}`);
        } finally {
            isUpdateRunning = false;
        }
    };
    await updater();

    const subscription = limiter.subscribe(async newVal => {
        log(`${baseMsg}: triggered update ({newVal})`);
        await updater();
    });
    const retVal: IDisposeable = {
        dispose() {
            disposeAll([
                subscription,
                limiter,
                baseObj
            ]);
            log(`${baseMsg}: disposed`);
        }
    };
    return retVal;
}

export function registerBindingHandler(name: string, handler: ko.BindingHandler) {
    //tslint:disable-next-line:no-any
    const anyBindingHandlers = <any>ko.bindingHandlers;
    anyBindingHandlers[name] = handler;
}

export function getTranslateableMessage(messages: Array<{ isoCode: string, message: string }>) {
    const map = new Map<string, string>();

    for (const { isoCode, message } of messages) {
        map.set(isoCode, message);
    }
    return arrayHelper.mapToPropertyMap(map);
}

export function graphQLLangStringToLanguageMap(data: ILangStringData) {
    const map = new Map<string, string>();

    if (data && data.languages) {
        for (const { isoCode, value } of data.languages) {
            map.set(isoCode, value);
        }
    }
    return arrayHelper.mapToPropertyMap(map);
}

export function ensureObservable<T>(observable: ko.MaybeObservable<T>) {
    if (ko.isObservable(observable)) {
        return observable;
    }
    return ko.observable(observable);
}

export interface IUnsubsribe {
    unsubscribe(): void;
}
export interface IDisposeable {
    dispose(): void;
}
export function toDisposable(item: IUnsubsribe) {
    return {
        dispose() {
            item.unsubscribe();
        }
    };
}
export function disposeAll(array: Array<IDisposeable>) {
    if (!array) {
        return;
    }
    for (let i = 0; i < array.length; ++i) {
        if (array[i]) {
            array[i].dispose();
        }
        array[i] = null;
    }
}

//returns the extension in lower case if the name if a png, gif or jpg
//returns false otherwise
//Example
//getImageExtension("./a.JPG") === '.jpg'
//getImageExtension("./images.jpg/image1") === false
//getImageExtension("./image.svg")=== false
//getImageExtension("./test.docx")===false

export function getImageExtension(name: string): string {
    if (!name) {
        return undefined;
    }
    const extension = /\.(jpg|gif|png)$/gi.exec(name);
    if (!extension) {
        return undefined;
    }
    return extension[0].toLowerCase();
}

export function getFilename(path: string): string {
    if (!path) {
        return '';
    }
    const parts = path.split(/[\\\/]/);
    return parts[parts.length - 1];
}
export function getFileBaseName(name: string): string {
    const parts = getFilename(name).split('.');
    parts.pop();
    return parts.join('.');
}

export function getFileExtension(name: string): string {
    const parts = getFilename(name).split('.');
    if (parts.length <= 1) {
        return '';
    }
    return '.' + parts.pop();
}

function resizeWidthKeepAspectRatio(originalWidth: number, originalHeight: number, newWidth: number): { width: number, height: number } {
    const incDec = 100 / originalWidth * newWidth;
    const newHeight = originalHeight * (incDec / 100);
    return { width: newWidth, height: Math.round(newHeight) };
}
function resizeHeightKeepAspectRatio(originalWidth: number, originalHeight: number, newHeight: number): { width: number, height: number } {
    const incDec = 100 / originalHeight * newHeight;
    const newWidth = originalWidth * (incDec / 100);
    return { width: Math.round(newWidth), height: newHeight };
}

export function resize({ original, target }: { original: { width: number, height: number }, target: { width: number, height: number } }) {
    if (target.width > 0 && target.height > 0) {
        return target;
    }
    if (target.width > 0) {
        return resizeWidthKeepAspectRatio(original.width, original.height, target.width);
    }
    if (target.height > 0) {
        return resizeHeightKeepAspectRatio(original.width, original.height, target.height);
    }
    return original;
}

export function handlePasteEvent(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    const clipboard = event.clipboardData || (<IWindowClipboardExtension><unknown>window).clipboardData;
    if (!clipboard) {
        return;
    }
    const clipText = clipboard.getData('Text');

    const sel = window.getSelection();
    if (sel) {
        const range = sel.getRangeAt(0);
        if (range) {
            range.deleteContents();
            const node = document.createTextNode(clipText);
            range.insertNode(node);

            //Set proper cursor position
            range.setStart(node, clipText.length);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }
}
export function getTime(showSeconds: boolean) {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    let retVal = hours + ':' + (minutes < 10 ? '0' : '') + minutes;
    if (showSeconds !== false) {
        const seconds = now.getSeconds();
        retVal += ':' + (seconds < 10 ? '0' : '') + seconds;
    }
    return retVal;
}

export function hashString(s: string) {
    let hash = 0;
    if (!s) { return hash; }
    for (let i = 0; i < s.length; i++) {
        const char = s.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        hash = ((hash << 5) - hash) + char;
        // tslint:disable-next-line:no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function update_observable<T>(val: ko.Observable<T>, newVal: T) {
    const curVal = val();
    if (curVal === newVal) {
        return;
    }
    if (STABLE_STRINGIFY(curVal) === STABLE_STRINGIFY(newVal)) {
        return;
    }
    val(newVal);
}

export function bounds(value: number, min: number, max: number) {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
}

export function BRtoLF(str: string) {
    if (!str) {
        return '';
    }
    return str.replace(/\<br\s*\/?\>/g, '\n');
}
export function LFtoBR(str: string) {
    if (!str) {
        return '';
    }
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
}


export function getParent(objId: string) {
    const parts = (objId || '').split('.');
    parts.pop();
    return parts.join('.');
}

export function getAncestorsAndSelf(objId: string) {
    const parts = (objId || '').split('.');
    return parts.map((val, index) => parts.slice(0, index + 1).join('.'));
}

export function formatSpaces(html: string) {
    return (html || '').replace(/ /g, '<span class="space">·</span>');
}

export function encodeHtml(str: string) {
    if (!str) {
        return '';
    }
    return str
        .replace(/&/g, '&amp;')
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');

}

const SEP_LEFT = '‹';
const SEP_RIGHT = '›';
const PARSE_ERR = /([.0-9A-Z_]+)‹([^›]*)›/;

export function extractEndUserError(fullMessage: string) {
    const match = fullMessage.match(PARSE_ERR);
    if (match) {
        const id = match[1];
        const message = match[2];
        return { id, message };
    } else {
        return undefined;
    }

}

export function normalizeNumber(x: string | number) {
    if (typeof x === 'string') {
        return parseFloat(x);
    }
    if (typeof x === 'number') {
        return x;
    }
    return undefined;
}

export async function forceUIUpdate() {
    ko.tasks.runEarly();
    await sleep(100);
}


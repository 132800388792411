import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_HOME from '../home/route';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

const WIDGET_PARENT_NAME = 'ui-subjectcoordinator';


export class ViewModel extends AbstractWidget {
    public readonly select = new UIAction<string>(undefined, async (e, subjectDocRefId) => {
        await ROUTES.routeManager.navigateToHREF(WIDGET_HOME.FACTORY.href({
            subject: subjectDocRefId
        }));
    });

    constructor(readonly params: IParams) {
        super();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { assertNever } from '../helper';


function toString(variable: FormatMessageArgPrimitive) {
    if (typeof variable === 'string') {
        return variable;
    }
    if (typeof variable === 'number') {
        return variable.toString();
    }
    assertNever(variable);
    throw new Error();
}
export type FormatMessageArgPrimitive = string | number;

export interface IFormatMessageArgs {
    [key: string]: FormatMessageArgPrimitive;
}

/**
 * formatMessage( message, data )
 *
 * @message [String] A message with optional {vars} to be replaced.
 *
 * @data [Array or JSON] Object with replacing-variables content.
 *
 * Return the formatted message. For example:
 *
 * - formatMessage( "{0} second", [ 1 ] ); // 1 second
 *
 * - formatMessage( "{0}/{1}", ["m", "s"] ); // m/s
 *
 * - formatMessage( "{name} <{email}>", {
 *     name: "Foo",
 *     email: "bar@baz.qux"
 *   }); // Foo <bar@baz.qux>
 */

export function formatMessage(message: string, data: IFormatMessageArgs) {

    // Replace {attribute}'s
    message = message.replace(/{[0-9a-zA-Z-_. ]+}/g, name => {
        name = name.replace(/^{([^}]*)}$/, '$1');
        return toString(ko.unwrap(data[name]));
    });

    return message;
}


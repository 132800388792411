import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { datagrid, refreshDx } from '../../../dx_helper';
import * as TRANSLATIONS from '../../../enums/translations';
import * as API from '../../../its-itembank-api.g';
import { ExamOrderSubjectStatus } from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import * as WIDGET_EXAMORDEREDIT from '../examorderedit/route';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './examorder.html.g';
import { IParams, IParamsInner, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_subjectcoordinator_examorder_data']>>;

export class ViewModel extends DxWidget {
    public readonly innerParams: IParamsInner;

    constructor(readonly params: IParams) {
        super();
        this.innerParams = params.currentRoute.widgetParams;
    }

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        key: 'docReferenceId',
                        load: async () => {
                            return this._examOrders;
                        },
                        byKey: async key => {
                            return this._examOrders.find(x => x.docReferenceId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorder.EXAM_ORDER_ID']),
            dataField: 'examinationOrderId',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorder.STATUS']),
            dataField: 'subject.status',
            dataType: 'string',
            calculateDisplayValue: (e: Q['subjectById']['openExamOrders'][0]): string => {
                switch (e.subject.status) {
                    case ExamOrderSubjectStatus.Open:
                        return i18next.t(TRANSLATIONS.SubjectCoordinatorStatusString['open']);
                    case ExamOrderSubjectStatus.Finished:
                        return i18next.t(TRANSLATIONS.SubjectCoordinatorStatusString['finished']);
                    default:
                        return '';
                }
            }
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorder.POINTS']),
            dataType: 'number',
            dataField: 'subject.score',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorder.DATE_OF_EXAM']),
            dataType: 'string',
            dataField: 'examDate',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorder.START_TIME']),
            dataType: 'string',
            dataField: 'examTime',
        });
        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorder.LANGUAGES']),
            dataType: 'string',
            calculateDisplayValue: x => {
                const queryRow: Q['subjectById']['openExamOrders'][0] = x;
                return queryRow.localizations.map(x => x.name.value).sort().join(', ');
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.subjectcoordinator.examorder.ACTIONS']),

            width: '80',
            cellTemplate: (elem, info) => {
                const retVal = $('<a />');
                retVal.text(i18next.t(['ui.subjectcoordinator.examorder.OPEN']));
                retVal.css({ cursor: 'pointer' });
                retVal.on('click', () => {
                    const user: Q['subjectById']['openExamOrders'][0] = info.data;
                    void this.gotoExamOrder(user.docReferenceId);

                });
                return retVal;
            }
        });
        return retVal;
    });


    public async gotoExamOrder(examOrderDocRefId: string) {
        const p = this.innerParams;
        await ROUTES.routeManager.navigateToHREF(WIDGET_EXAMORDEREDIT.FACTORY.href({
            subject: p.subjectDocRefId,
            examorder: examOrderDocRefId
        }));
    }

    public readonly loaded = ko.observable(false);

    private readonly _examOrders: Q['subjectById']['openExamOrders'] = [];
    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.ui_subjectcoordinator_examorder_data({
            subjectDocRef: this.innerParams.subjectDocRefId,
        });

        this._examOrders.splice(0, this._examOrders.length, ...q.subjectById.openExamOrders);
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }

}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../../AbstractWidget';
import { dir, log } from '../../../../debug';
import { AS, datagrid, refreshDx } from '../../../../dx_helper';
import * as API from '../../../../its-itembank-api.g';
import { stringCompare } from '../../../../tree';
import { queueApiCall } from '../../../docmanager';
import { ServerConnection } from '../../../RestAPI';
import * as i18n from './../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_translateexamorder_examorderstrings']>>;

export const WIDGET_NAME = 'ui-translator-translateexamorder-examorderstrings';

export interface IParams {
    examOrderDocRefId: string,
}

interface IDXOnRowUpdate {
    data: IItemTranslationEntry,
    key: IItemTranslationEntry
}

interface IItemTranslationEntry {
    key: string,
    id: string,
    label: string;
    defaultLanguage: string,
    data: { [key: string]: string }
}

export class ViewModel extends DxWidget {
    constructor(readonly params: IParams) {
        super();
    }

    public readonly loading = ko.pureComputed(() => !this.loaded());
    public readonly loaded = ko.observable(false);

    private readonly dataOnServer = new Map<string, string>();
    private lastQuery: Q = undefined;

    public async OnRefresh() {
        await super.OnRefresh();
        const query = await ServerConnection.api.ui_translateexamorder_examorderstrings({
            examOrder: this.params.examOrderDocRefId,
        });
        this.lastQuery = query;
        this.itemListRaw(query);
        await this.updateData();
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.loaded(true);
    }



    public readonly itemListRaw = ko.observable<Q>();

    public readonly data = ko.observable<IItemTranslationEntry[]>();

    private async updateData(): Promise<void> {
        const retVal: IItemTranslationEntry[] = [];
        const rawData = this.itemListRaw();
        if (!rawData) {
            return undefined;
        }
        this.dataOnServer.clear();
        for (const entry of rawData.examOrder.byId.strings) {
            const itemEntry: IItemTranslationEntry = {
                id: entry.id,
                key: entry.key,
                label: entry.label.value,
                defaultLanguage: entry.value.defaultValue,
                data: {}
            };
            for (const translation of entry.value.languages) {
                const cacheKey = `${entry.key}/${translation.isoCode}`;
                this.dataOnServer.set(cacheKey, translation.value);
                log(`Caching ${cacheKey}: ${translation.value}`);
                itemEntry.data[translation.isoCode] = translation.value;
            }
            retVal.push(itemEntry);
        }
        this.data(retVal);
    }

    public readonly dxDataGridOptions = ko.pureComputed(() => {
        const data = this.data() || [];
        if (!data) {
            return undefined;
        }
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                dataSource: data,
                editing: {
                    mode: 'cell',
                    allowUpdating: true
                },
                filterRow: {
                    visible: true,
                },
                searchPanel: {
                    visible: true,
                },
                columns: [
                    {
                        dataField: 'label',
                        caption: i18n.t(['ui.translator.translateexamorder.examorderstrings.NAME']),
                        allowEditing: false
                    }
                ],
                allowColumnResizing: true,
                wordWrapEnabled: true,
                export: {
                    enabled: true,
                    fileName: 'Translations',
                    allowExportSelectedData: false
                },
                hoverStateEnabled: true,
            }
        });
        const codes = new Map<string, string>();
        for (const loc of this.lastQuery.examOrder.byId.localizations) {
            for (const lang of loc.isoLanguages) {
                codes.set(lang.isoCode, lang.name.value);
            }
        }
        codes.delete('x-none');
        retVal.columns.push({
            dataField: 'data.x-none',
            dataType: 'string',
            caption: i18n.t(['ui.translator.translateexamorder.examorderstrings.DEFAULT_VALUE']),
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                readOnly: true
            })
        });

        const sortedCodes = Array.from(codes.entries()).map(v => ({ isoCode: v[0], label: v[1] })).sort((a, b) => stringCompare(a.label, b.label));
        for (const code of sortedCodes) {
            retVal.columns.push({
                dataField: 'data.' + code.isoCode,
                caption: code.label,
                dataType: 'string',
            });
        }
        retVal.onRowUpdated = (e: IDXOnRowUpdate) => this.updateTranslation(e);
        retVal.onEditorPreparing = e => {
            dir(e);
            if (e.parentType !== 'dataRow') {
                return;
            }
            if (!e.dataField) {
                return;
            }
            if (e.dataField.startsWith('data.')) {
                e.editorName = 'dxTextArea';
                e.editorOptions.height = 300;
            }
        };

        return retVal;
    });

    public updateTranslation(e: IDXOnRowUpdate) {
        log(e);
        const data: IItemTranslationEntry = e.data;

        for (const isoCode of Object.keys(data.data)) {
            const id = `${this.params.examOrderDocRefId}:${isoCode}:${data.key}`;
            const value = data.data[isoCode];
            const cacheKey = `${data.key}/${isoCode}`;
            if (this.dataOnServer.get(cacheKey) === value) {
                log(`${cacheKey} did not change (${value})`);
                continue;
            } else {
                log(`${cacheKey} was: ${this.dataOnServer.get(cacheKey)}\nchanging to: ${value}`);
            }
            this.dataOnServer.set(cacheKey, value);
            queueApiCall(`${WIDGET_NAME}/updateTranslation/${id}`, () =>
                ServerConnection.api.ui_translator_translateexamorder_update(
                    {
                        examOrder: this.params.examOrderDocRefId,
                        strings: [
                            {
                                key: data.key,
                                value: {
                                    add: [
                                        {
                                            key: isoCode,
                                            value,
                                        }
                                    ],
                                },
                            }
                        ],
                    }
                ));
        }
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

export const id = 'kosovo-textentry_manual';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '079fd69e-063a-4b99-93de-56ba081849a4',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`],
            ['GRADING', `itemdefinition-${id}-grading`]
        ])
    },
    grading: 'BULK',
});
﻿import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { error, log } from '../../../debug';
import { itemDefinitionFactoryProvider } from '../../../itemdefinition/factoryProvider';
import { EDIT_SESSION } from '../../../magic';
import { IItemPresenterWidgetParams, ItemMode } from '../../../model/interfaces';
import { focusManager } from '../../focusmanager';
import { htmlString } from './itempresenter.html.g';

const WIDGET_NAME = 'ui-widgets-itempresenter';
let IDX = 0;

export type IParams = IItemPresenterWidgetParams;

export class ViewModel extends AbstractWidget {
    private readonly id = `${WIDGET_NAME}#${IDX++}`;

    public readonly itemDocRefId: string;
    public readonly mode: ItemMode;

    public readonly localizationId: string;
    public readonly sessionId: string;
    //public readonly focusId: string;

    public dispose() {
        log(`${this.id}: disposing`);
        super.dispose();
    }

    constructor(readonly params: IItemPresenterWidgetParams) {
        super();

        this.localizationId = params.localizationId || '';
        this.mode = params.mode || 'INTERACTIVE';
        this.sessionId = params.sessionId || EDIT_SESSION;
        this.itemDocRefId = params.item;
        //this.focusId = focusManager.newFocusId();
        const widgetName = itemDefinitionFactoryProvider.getWidgetName(params.itemType, this.mode);
        this.widgetName(widgetName);
        log(`${this.id}: ${this.itemDocRefId} ${this.mode} (${this.sessionId}) ${this.params.isFocusModeTarget ? 'for focus mode' : 'for other purpose'}`);
    }

    public readonly showItem = ko.observable(false);

    public readonly widgetName = ko.observable<string>();

    public readonly msg = ko.observable('');

    public async initialize() {
        await super.initialize();
        if (!this.itemDocRefId) {
            error(`${this.id}: parameter 'item' was not set`);
            return;
        }
        //await runQueue({ noRefresh: true });
        let showItem = false;

        const isFocusModeTarget = !!this.params.isFocusModeTarget;
        const isInFocusMode = !!focusManager.topItemFocus();
        if (!this.params.isTopItem) {
            showItem = true;
        } else if (this.mode !== 'INTERACTIVE') {
            showItem = true;
        } else {
            showItem = isInFocusMode == isFocusModeTarget;
        }

        log(`${this.id} ${isFocusModeTarget ? 'for focus mode' : 'for other purposes'} ${this.widgetName()} ${this.itemDocRefId}: ${showItem ? 'SHOW' : 'IGNORE'}`);

        this.showItem(showItem);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

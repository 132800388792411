import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { log } from '../../debug';
import * as ROUTES from '../../ui/routes';
import { UIAction } from '../../ui/uiAction';
import { htmlString } from './widget.html.g';



const WIDGET_NAME = 'widgets-crumbbar';
const WIDGET_PARENT_NAME = 'widgets';

export type IParams = undefined;

class BreadCrumb {
    constructor(private route: ROUTES.ANYROUTE) {

    }
    public readonly display = ko.pureComputed(() => this.route.title());
    public readonly actionClick = new UIAction(undefined, async () => {
        await ROUTES.routeManager.navigateToHREF(this.route.path);
    });
}

export class ViewModel extends AbstractWidget {
    constructor(readonly params: IParams) {
        super();
    }
    public readonly currentRoute = ko.pureComputed(() => {
        return ROUTES.routeManager.current();
    });

    public async initialize() {
        await super.initialize();

        log(`${WIDGET_NAME} initialize called`);
        this.disposables.addDiposable(ROUTES.routeManager.current.subscribe(x => this.currentRouteChanged(x)));

    }
    private async currentRouteChanged(current: ROUTES.ANYROUTE) {
        log(`${WIDGET_NAME} current route changed`);
        const paths = current && current.parentPaths || [];
        log(`${WIDGET_NAME} parent paths: ${paths}`);
        const routes = await Promise.all(current.parentPaths.map(path => {
            const r = ROUTES.matchRoute(path);
            if (!r) {
                return undefined;
            }
            return r.factory.createFromMap(r.map);
        }));

        const breadCrumbs = routes.reverse().map(x => new BreadCrumb(x));
        this.breadcrumbs(breadCrumbs);

    }
    public readonly currentRouteTitle = ko.pureComputed(() => {
        const current = this.currentRoute();
        if (!current) {
            return;
        }
        return current.title();

    });

    public readonly breadcrumbs = ko.observable<BreadCrumb[]>();
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as GRAPHQL_REQUEST from 'graphql-request';
import $ from 'jquery';
import { error, info, log } from '../debug';
import * as API from '../its-itembank-api.g';
import * as i18next from './../i18n/i18n';
import * as ko from 'knockout';
import { getAmbientBrowserSessionId, isNoNetwork } from "./getAmbientBrowserSessionId";


export type Q = Awaited<ReturnType<API.Sdk['ui_init']>>;

interface GraphQLRequestError extends Error {
    message: string;
    request: {
        query: string;
    }
    response: {
        error: string;
        status: number;
    }
}
function IsGraphQLRequestError(x: Error): x is GraphQLRequestError {
    if (!('request' in x)) {
        return false;
    }
    if (!('response' in x)) {
        return false;
    }
    return true;
}

export enum ConnectionStatus {
    Connected,
    DisconnectedTemporily,
    DisconnectedLoginRequired,
}
export class RestAPIBase {
    private _bearer: string;
    public get apiKeyValue() {
        if (!this._bearer) {
            throw new Error(`RestAPIBase::Init() not called yet!`);
        }
        return this._bearer;
    }
    public apiKeyHeaderName = 'browserSessionId';
    private _isAnonymous: boolean;
    public get isAnonymous() {
        if (!this._bearer) {
            throw new Error(`RestAPIBase::Init() not called (or awaited) yet`);
        }
        return this._isAnonymous;
    }
    public initData: Q;
    public readonly isNoNetwork: boolean;
    private readonly _grClient: GRAPHQL_REQUEST.GraphQLClient;
    private _api: API.Sdk;
    public get api() {
        if (this.isNoNetwork) {
            throw new Error('Programmers fault: network disabled');
        }
        return this._api;
    }

    public getDataUrl(url: string, queryArgs?: {
        [key: string]: string;
    }, includeSessionId?: boolean) {
        if (!url) {
            return undefined;
        }
        let retVal = '';
        if (url.indexOf('://') === -1) {
            retVal = this.dataURL + url;
        }
        else {
            retVal = url;
        }
        if (includeSessionId) {
            if (!queryArgs) {
                queryArgs = {};
            }
            if (this.apiKeyValue) {
                queryArgs['browserSessionId'] = this.apiKeyValue;
            }
        }
        if (queryArgs) {
            if (retVal.indexOf('?') == -1) {
                retVal += '?';
            }
            for (const key of Object.keys(queryArgs)) {
                retVal += '&';
                retVal += encodeURIComponent(key);
                retVal += '=';
                retVal += encodeURIComponent(queryArgs[key]);
            }
        }
        return retVal;
    }
    public readonly connectionStatus = ko.observable<ConnectionStatus>(ConnectionStatus.Connected);

    public readonly dataURL: string;
    constructor(dataURL: string, readonly options: { fetch?: WindowOrWorkerGlobalScope['fetch'], fetchOptions?: unknown }) {
        dataURL = dataURL.replace(/graphql$/, '');
        if (!dataURL.endsWith('/')) {
            dataURL += '/';
        }
        this.dataURL = dataURL;

        this.isNoNetwork = isNoNetwork();
        if (this.isNoNetwork) {
            log('Network communication intentionally disabled');
            return;
        }
        const graphQLUrl = this.getDataUrl('graphql');
        log(`Sending graphQL queryies to ${graphQLUrl}`);

        this._grClient = new GRAPHQL_REQUEST.GraphQLClient(graphQLUrl, {
            responseMiddleware: x => {
                if (x instanceof Error) {
                    error(`Server cannot be reached`);
                    if (IsGraphQLRequestError(x)) {
                        console.log('response: %o', x.response);
                        if (x.response.status === 403) {
                            this.connectionStatus(ConnectionStatus.DisconnectedLoginRequired);
                            return;
                        }
                    }
                    this.connectionStatus(ConnectionStatus.DisconnectedTemporily);
                } else {
                    if (this.connectionStatus() !== ConnectionStatus.Connected) {
                        info('Server is available again');
                        this.connectionStatus(ConnectionStatus.Connected);
                    }
                }
            }
        });
        this._api = API.getSdk(this._grClient);
    }

    public graphQLUrl() {
        return this.getDataUrl('graphql', {}, true).replace(/^http/, 'ws');
    }

    public async Init() {
        const ambient = getAmbientBrowserSessionId();
        let bearer = ambient?.id;
        let source = ambient?.source ?? 'none';
        if (!bearer) {
            log(`initialize new browser session`);
            this._grClient.setHeaders({});
            //this._grClient.setHeader("Authorization", 'anonymous');
            const loginResult = await this._api.ui_login_anonymous({
                lang: '',
            });
            bearer = loginResult.loginAnonymous.browserSessionId;
            log(`new browser session id: ${bearer}`);
            this._isAnonymous = true;
        }
        this._bearer = bearer;
        log(`Set api key to '${bearer}'`);
        sessionStorage.setItem('browserSessionId', bearer);
        this._grClient.setHeaders({ Authorization: `Bearer ${bearer}` });
        try {
            const r = await this.api.ui_init({});
            this.initData = r;
        } catch (e) {
            console.log('Unable to login');
            console.dir(e);
            this._bearer = undefined;
            sessionStorage.removeItem('browserSessionId');
            if (source == 'session-storage') {
                console.log('Unable to reuse stored browserSessionId, retry');
                await this.Init();
            }
        }
    }
    protected async ajax<TRetVal>(settings: JQueryAjaxSettings) {
        if (!settings.dataType) {
            settings.dataType = 'json';
        }
        if (!settings.contentType) {
            settings.contentType = 'application/json';
        }
        if (!settings.method) {
            settings.method = 'GET';
        }
        if (settings.method === 'GET') {
            if (!settings.cache) {
                settings.cache = false;
            }
        }
        settings.url = this.dataURL + settings.url;
        settings.headers = settings.headers || {};
        if (this.apiKeyValue) {
            if (this.apiKeyHeaderName) {
                settings.headers[this.apiKeyHeaderName] = this.apiKeyValue;
            }
        }
        try {
            // tslint:disable-next-line
            return <TRetVal>await $.ajax(settings);
        }
        catch (err) {
            error('Failed to load ' + settings.url + '\n' + err.status + ' ' + err.responseText);
            if (err.responseText) {
                throw err;
            }
            else {
                throw new Error(i18next.t(['ui.restapibase.UNABLE_TO_CONNECT_TO_SERVER_THE_INTERNET_CONNECTION_MAY_BE_DOWN']));
            }
        }
    }
    // tslint:disable-next-line:function-name
    protected async GET<T>(url: string) {
        return this.ajax<T>({ url: url });
    }
    // tslint:disable-next-line:function-name
    protected async POST<T>(url: string, data: {}) {
        return this.ajax<T>({ url: url, data: JSON.stringify(data), method: 'POST' });
    }
}

import CustomStore from 'devextreme/data/custom_store';
import $ from 'jquery';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { datagrid, refreshDx } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './grading.html.g';
import { IParams, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_manualgrading_grading_data']>>;
type UPDATE = Awaited<ReturnType<API.Sdk['ui_manualgrading_grading_update']>>;

export class ViewModel extends DxWidget {
    public readonly loaded = ko.observable(false);
    public readonly subjectDocRefId = ko.pureComputed(() => {
        return this.params.currentRoute.widgetParams.subjectDocRefId;
    });

    public readonly itemDocRefId = ko.pureComputed(() => {
        return this.params.currentRoute.widgetParams.itemDocRefId;
    });

    constructor(readonly params: IParams) {
        super();
    }

    public async initialize() {
        await super.initialize();
        await this.initGradingData();
        await this.OnRefresh();
        this.loaded(true);
    }

    public async initGradingData() {
        const result = await ServerConnection.api.ui_manualgrading_grading_data({
            itemId: this.itemDocRefId()
        });
        this._sessions.splice(0, this._sessions.length, ...result.itemForManualGrading.sessions);
        this.itemType(result.itemForManualGrading.item.itemType.id);
        this.isTopItem(result.itemForManualGrading.item.isTopItem);
    }
    private readonly _sessions: string[] = [];
    private readonly _data: UPDATE['itemGradingData'] = [];
    public readonly isTopItem = ko.observable(true);
    public async OnRefresh() {
        await super.OnRefresh();
        const u = await ServerConnection.api.ui_manualgrading_grading_update({
            itemId: this.itemDocRefId(),
            sessions: this._sessions,
        });

        this._data.splice(0, this._data.length, ...u.itemGradingData);
        const allDone = this._data.every(x => x.submitted);
        /*
        if (!allDone) {
            await ROUTES.routeManager.back();
        }
        */
        await refreshDx(this);

    }

    public readonly selectedSessionId = ko.observable('');

    public readonly itemType = ko.observable('');

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            config: {
                allowColumnResizing: true,
                sorting: {
                    mode: 'single',
                },
                searchPanel: {
                    visible: true
                },
                filterPanel: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                groupPanel: {
                    visible: false
                },
                dataSource: {
                    store: new CustomStore({
                        loadMode: 'raw',
                        load: async () => {
                            return this._data;
                        },
                        byKey: async key => {
                            return this._data.find(x => x.sessionId === key);
                        }
                    })
                }
            }
        });

        retVal.columns.push({
            caption: i18next.t(['ui.manualgrading.grading.SESSION']),
            dataField: 'sessionId',
            sortOrder: 'asc',
            sortIndex: 0,
            dataType: 'string',
        });

        retVal.columns.push({
            caption: i18next.t(['ui.manualgrading.grading.ACTIONS']),

            width: '80',
            cellTemplate: (elem, info) => {
                const retVal = $('<a />');
                retVal.text(i18next.t(['ui.manualgrading.grading.OPEN']));
                retVal.css({ cursor: 'pointer' });
                retVal.on('click', () => {
                    const user: UPDATE['itemGradingData'][0] = info.data;
                    this.selectedSessionId(user.sessionId);
                });
                return retVal;
            }
        });
        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

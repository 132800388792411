export const id = 'instruction';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';

itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '77bf9ffb-b284-452f-a3b5-344709d033e4',
    aliases: [
    ],
    widgetNames: {
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ]),
        fallback: 'itemdefinition-' + id

    },
});

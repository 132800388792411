import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { ISurveyComparisionEditUpsertInput } from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { ServerConnection } from '../../../ui/RestAPI';
import * as HTMLEDITOR from '../../../widgets/htmleditor/widget';
import * as i18n from './../../../i18n/i18n';
import { ColumnDS } from './ColumnDS';
import { ObjectiveDS } from './ObjectiveDS';
import { RowDS } from './RowDS';
import { ScaleDS } from './ScaleDS';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveycomparision-edit';

export type IParams = IItemDefinitionWidgetParams;
type Q = Awaited<ReturnType<API.Sdk['surveycomparision_edit_data']>>;

type D = {
    header: string,
    question: string,
};
type U = Omit<ISurveyComparisionEditUpsertInput, 'itemId'>;



class MyFormBuilder extends FormBuilder<D, U> {
    constructor(readonly vm: {
        isReadOnly: boolean,
        itemId: string,
        scaleDS: ScaleDS,
        rowDS: RowDS,
        columnDS: ColumnDS,
        objectiveDS: ObjectiveDS,
    }) {
        super();
    }

    protected onInit() {
        super.onInit();

        const g1 = this.addGroup(this.formOptions, {
            caption: i18n.t(['itemdefinition.surveycomparision.edit.COMMON'])
        });
        this.addTextBox(g1, {
            dataField: 'header',
            label: {
                text: i18n.t(['itemdefinition.surveycomparision.edit.HEADER']),
            },
            editorOptions: {
            },
        }, val => ({ header: { xnone: val } }));
        this.addSimpleItem<string>(g1, HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.surveycomparision.edit.QUESTION']),
            },
            readOnly: this.vm.isReadOnly,
            dataField: 'question',
            docReferenceId: this.vm.itemId,
            docType: API.Doctype.Item,
        }), val => ({ question: { xnone: val } }));

        const g2 = this.addGroup(this.formOptions, {
            caption: i18n.t(['itemdefinition.surveycomparision.edit.COLUMNS'])
        });
        this.addDataGrid(g2, {
            label: {
                text: i18n.t(['itemdefinition.surveycomparision.edit.COLUMNS']),
                location: 'top',
            },
            editorOptions: {
                dataSource: {
                    store: this.vm.columnDS.store
                },
                editing: {
                    mode: 'cell',
                    allowAdding: !this.vm.isReadOnly,
                    allowDeleting: !this.vm.isReadOnly,
                    allowUpdating: !this.vm.isReadOnly,
                },
                onInitNewRow: this.vm.columnDS.initNewRow,
                columns: [
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.ID']),
                        dataField: 'index',
                        dataType: 'string',
                        allowEditing: false,
                        width: '30',
                    },
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.TEXT']),
                        dataField: 'caption.value',
                        dataType: 'string',
                    },
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.OBJECTIVE']),
                        dataField: 'objectiveId',
                        dataType: 'string',
                        lookup: {
                            dataSource: {
                                store: this.vm.objectiveDS,
                            },
                            allowClearing: true,
                            displayExpr: 'objectiveId',
                            valueExpr: 'objectiveId',
                        }
                    }
                ]
            },

        });

        const g3 = this.addGroup(this.formOptions, {
            caption: i18n.t(['itemdefinition.surveycomparision.edit.ROWS']),
        });
        this.addDataGrid(g3, {
            label: {
                text: i18n.t(['itemdefinition.surveycomparision.edit.ROWS']),
                location: 'top',
            },
            editorOptions: {
                dataSource: {
                    store: this.vm.rowDS.store
                },
                editing: {
                    mode: 'cell',
                    allowAdding: !this.vm.isReadOnly,
                    allowDeleting: !this.vm.isReadOnly,
                    allowUpdating: !this.vm.isReadOnly,
                },
                onInitNewRow: this.vm.rowDS.initNewRow,
                columns: [
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.ID']),
                        dataField: 'index',
                        dataType: 'string',
                        allowEditing: false,
                        width: '30',
                    },
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.TEXT']),
                        dataField: 'caption.value',
                        dataType: 'string',
                    },
                ]
            },

        });
        const g4 = this.addGroup(this.formOptions, {
            caption: i18n.t(['itemdefinition.surveycomparision.edit.SCALE']),
        });
        this.addDataGrid(g4, {
            label: {
                text: i18n.t(['itemdefinition.surveycomparision.edit.SCALE']),
                location: 'top',
            },
            editorOptions: {
                dataSource: {
                    store: this.vm.scaleDS.store
                },
                editing: {
                    mode: 'cell',
                    allowAdding: !this.vm.isReadOnly,
                    allowDeleting: !this.vm.isReadOnly,
                    allowUpdating: !this.vm.isReadOnly,
                },
                onInitNewRow: this.vm.scaleDS.initNewRow,
                columns: [
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.ID']),
                        dataField: 'index',
                        dataType: 'string',
                        allowEditing: false,
                        width: '30',
                    },
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.VALUE']),
                        dataField: 'value',
                        dataType: 'number',
                        width: '100',
                    },
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.HINT']),
                        dataField: 'hint.value',
                        dataType: 'string',
                    },
                    {
                        caption: i18n.t(['itemdefinition.surveycomparision.edit.INVALID']),
                        dataField: 'invalid',
                        dataType: 'boolean',
                    },
                ]
            },

        });
    }

}

export class ViewModel extends DxWidget {
    private readonly formData: D = {
        header: '',
        question: '',
    };
    public readonly formBuilder = new MyFormBuilder(this);
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);

    public readonly scaleDS: ScaleDS;
    public readonly columnDS: ColumnDS;
    public readonly rowDS: RowDS;
    public readonly objectiveDS: ObjectiveDS;


    constructor(params: IParams) {
        super();
        const itemId = params.itemId;
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;

        this.scaleDS = new ScaleDS({
            itemId,
            fetch: () => this.lastFetch && this.lastFetch.SurveyComparisionEdit.get.scale || [],
        });
        this.columnDS = new ColumnDS({
            itemId,
            fetch: () => this.lastFetch && this.lastFetch.SurveyComparisionEdit.get.columns || [],
        });
        this.rowDS = new RowDS({
            itemId,
            fetch: () => this.lastFetch && this.lastFetch.SurveyComparisionEdit.get.rows || [],
        });
        this.objectiveDS = new ObjectiveDS({
            itemId,
            fetch: () => this.lastFetch && this.lastFetch.item.get.metaData.objectives || [],
        });
    }

    private lastFetch: Q;
    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.surveycomparision_edit_data({
            itemId: this.itemId,
            itemIdStr: this.itemId,
        });
        const d = q.SurveyComparisionEdit.get;
        this.lastFetch = q;
        this.formData.question = d.question.value;
        this.formData.header = d.header.value;
        await refreshDx(this);
    }

    private async updateDatabase(fieldName: string, value: any) {
        const itemId = this.itemId;
        const up = this.formBuilder.fields.get(fieldName);
        if (up) {
            await ServerConnection.api.surveycomparision_edit_update({
                params: Object.assign({
                    itemId,
                }, up(value))
            });
            return DONE;
        }
        return DONE;
    }
    public async initialize() {
        await super.initialize();
        this.formBuilder.init(this.formData, this.forms);
        this.formBuilder.formOptions.onFieldDataChanged = x => {
            void this.updateDatabase(x.dataField, x.value);
        };
        await this.OnRefresh();

        this.loaded(true);
    }
}


export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

export const id = 'typingtest';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';

itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'c04892bf-0b16-4069-beda-35b63242df50',
    widgetNames: {
        fallback: `itemdefinition-${id}-result`,
        modes: new Map<ItemMode, string>([
            ['INTERACTIVE', `itemdefinition-${id}-interactive`],
            ['RESULT', `itemdefinition-${id}-result`],
            ['EDIT', `itemdefinition-${id}-edit`],
        ])
    },
});

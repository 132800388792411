﻿import * as ko from 'knockout';
import { RoutableBase } from "../RoutableBase";
import * as ROUTES from '../routes';
import * as WIDGET_LOGIN from './login/route';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'ui-login';

export class App extends RoutableBase {
    protected getRoleSwitcherMenu() {
        return super.getRoleSwitcherMenuBase(WIDGET_NAME);
    }

    constructor() {
        super({ allowAnonymousAccess: true });
    }
    public async initialize() {
        await super.initialize();

        await ROUTES.routeManager.navigateToHREF(WIDGET_LOGIN.FACTORY.href({}));
    }
}

export type IParams = undefined;

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new App();
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
//import * as Q from './init.query.graphql.g';
import * as API from '../../its-itembank-api.g';
import { stringCompare } from '../../tree';
import { DefaultWindowManager } from '../base';
import { ItemHostBase } from "../ItemHostBase";
import { ServerConnection } from '../RestAPI';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './demo.html.g';
type Q = Awaited<ReturnType<API.Sdk['ui_demo_init']>>;

const WIDGET_NAME = 'ui-demo';

type Examination = Q['examination']['getDemoExams'][0] & {
    primaryAlias: string
};

export class App extends ItemHostBase {
    constructor() {
        super({
            allowAnonymousAccess: true
        });
    }
    public readonly refreshing = ko.observable(0);
    public readonly hasOpenWindow = ko.observable(false);

    public readonly examinations = ko.observable<Examination[]>();

    public readonly idText = ko.pureComputed(() => {
        return i18next.t(['ui.demo.ID']);
    });
    public readonly titleText = ko.pureComputed(() => {
        return i18next.t(['ui.demo.TITLE']);
    });
    public readonly lastModifiedText = ko.pureComputed(() => {
        return i18next.t(['ui.demo.LAST_MODIFIED']);
    });

    public async start(exam: Examination) {
        const examId = exam.docReferenceId;
        const url = ServerConnection.getLaunchDemoExamUrl(examId, 'close');
        DefaultWindowManager.prepareOpenWindow('');
        DefaultWindowManager.openWindow(url, examId);
    }


    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.ui_demo_init({});
        const exams = (data.examination.getDemoExams || []).map(x => Object.assign(x, {
            primaryAlias: x.examAliases[0]
        }));

        exams.sort((a, b) => stringCompare(a.title, b.title));
        this.examinations(exams);
    }
    public async onInitialize() {
        await super.onInitialize();
        await this.OnRefresh();
    }
}


export type IParams = undefined;

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new App();
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

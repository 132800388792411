import * as query from '../getquery';
import { WindowManager } from './WindowManager';

export function getPollInterval(intervalName: string, defaultValue: number) {
    const option = query.params.get(intervalName);
    if (!option) {
        return defaultValue;
    }
    const retVal = parseFloat(option);
    if (isNaN(retVal)) {
        return defaultValue;
    }
    return retVal;
}

export const DefaultWindowManager = new WindowManager();

export const helpWindow = new WindowManager();

export interface IAppOptions {
    allowAnonymousAccess: boolean;
    noNetwork: boolean;
}





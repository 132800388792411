import { custom } from "devextreme/ui/dialog";
import * as GREMLIN from './Gremlin';
import { sleep } from './helper';
import * as i18next from './i18n/i18n';

export enum ModalResult {
    YES,
    NO
}

export interface IDialogOptions {
    message: string;
    yesCaption?: string;
    noCaption?: string;
}

export async function confirmYesNo(message: string) {
    const result = await dialog({ message });
    return result === ModalResult.YES;
}

export async function dialog(this: void, options: IDialogOptions) {

    if (GREMLIN.hasGremlins) {
        await sleep(500);
        return ModalResult.YES;
    }

    const yes = options.yesCaption || i18next.t(['modal.YES']);
    const no = options.noCaption || i18next.t(['modal.NO']);
    const dlg = custom({
        showTitle: true,
        messageHtml: options.message,
        buttons: [{
            text: yes,
            onClick: (e) => { return ModalResult.YES; }
        }, {
            text: no,
            onClick: (e) => { return ModalResult.NO; }
        }]
    });
    const retVal = await dlg.show();
    return retVal;
    /*
    const message = options.message;
    const html = `
    <p>${message}</p>
    <p class="materialize-it" style="margin-top:1rem">
        <button class="btn Button-Yes">${yes}</button>
        <button class="btn Button-No">${no}</button>
    </p>
    `;
    return show(html, options.onYes, options.onNo);
    */
}

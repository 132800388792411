import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS } from '../../../../dx_helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import { legacyPushPull, queueApiCall } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18n from './../../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';
type Q = Awaited<ReturnType<API.Sdk['orderingsentences_edit_data']>>;
const WIDGET_NAME = 'itemdefinition-kosovo-orderingsentences-edit';

export type IParams = IItemDefinitionWidgetParams;

class AnswerItem {
    constructor(
        readonly model: ViewModel,
        readonly answerItem: string,
        readonly answerIndex: number,
        readonly answer: Q['OrderingSentencesEdit']['get']['answers'][0]) {
        this._value(answer.value.value);
        this.canEdit = model.canEdit;
        this.isReadOnly = model.isReadOnly;
    }
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    private readonly _value = ko.observable('');

    public readonly isItemSelected = ko.pureComputed(() => {
        return this.model.selectedItem() === this.answer.index;
    });


    public readonly value = ko.pureComputed({
        read: () => {
            return this._value();
        },
        write: newVal => {
            this._value(newVal);
            queueApiCall(`${WIDGET_NAME}/${this.model.itemId}/upsertAnswer/${this.answer.index}`, () =>
                ServerConnection.api.orderingsentences_edit_update({
                    params: {
                        itemId: this.model.itemId,
                        upsertAnswers: [{
                            index: this.answer.index,
                            value: xnone(newVal)
                        }]
                    }
                }));
        }
    });
    public readonly id = ko.pureComputed(() => {
        return this.answer.index;
    });
    public readonly index = ko.pureComputed(() => {
        return this.answerIndex + 1;
    });

    public readonly select = new UIAction(undefined, async () => {
        this.model.selectedItem(this.answer.index);
    });

    public readonly answerDelete = new UIAction(undefined, async () => {
        if (this.model.answers().length <= 4) {
            toastService.error(i18n.t(['itemdefinition.kosovo.orderingsentences.edit.YOU_CANNOT_DELETE_THIS_SENTENCE_THERE_HAVE_TO_BE_AT_LEAST_4_SENTENCES']));
            return;
        }

        const message = i18n.t(['itemdefinition.kosovo.orderingsentences.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_SENTENCE']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }

        await legacyPushPull(async () => {
            await ServerConnection.api.orderingsentences_edit_update({
                params: {
                    itemId: this.model.itemId,
                    removeAnswers: [this.answer.index]
                }
            });
        });
    });
}

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly loaded = ko.observable(false);
    public readonly selectedItem = ko.observable('');
    private selectedItemIndex() {
        const index = this.selectedItem();
        if (!index) {
            return -1;
        }

        return (this.correctOrder() || []).findIndex(x => x === index);
    }

    public readonly correctOrderText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.orderingsentences.edit.CORRECT_ORDER']);
    });
    public readonly correctOrder = ko.observable<string[]>();

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;

    }

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.orderingsentences.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public async OnRefresh() {
        await super.OnRefresh();

        const q = await ServerConnection.api.orderingsentences_edit_data({
            itemId: this.itemId
        });
        const d = q.OrderingSentencesEdit.get;
        this.answers(d.correctOrder.map((answerIndex, index) => new AnswerItem(this, answerIndex, index, d.answers.find(x => x.index == answerIndex))));
        this.question(d.question.value);
        this.header(d.header.value);
        this.correctOrder(d.correctOrder);
    }

    public readonly orderingSentencesDeleteAnswerButtonLabel = i18n.t(['itemdefinition.kosovo.orderingsentences.edit.DELETE']);

    public readonly orderingSentencesAddSentenceLabel = i18n.t(['itemdefinition.kosovo.orderingsentences.edit.ADD_NEW_SENTENCE']);

    public async initialize() {
        await super.initialize();

        await this.OnRefresh();

        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.orderingsentences_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)

                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.orderingsentences_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val)
                }
            });
            return DONE;
        });
        this.loaded(true);
    }

    public readonly answers = ko.observable<AnswerItem[]>();


    public readonly answerAdd = new UIAction(undefined, async () => {
        const id = this.findUnusedAnswerId();
        if (!id) {
            toastService.error(i18n.t(['itemdefinition.kosovo.orderingsentences.edit.YOU_CANNOT_ADD_MORE_THAN_26_SENTENCES']));
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.orderingsentences_edit_update({
                params: {
                    itemId: this.itemId,
                    upsertAnswers: [{
                        index: id,
                        value: {
                            'xnone': ''
                        }
                    }]
                }
            });
        });
    });

    public findUnusedAnswerId() {
        const existing: { [key: string]: boolean } = {};
        const answers = this.answers();
        if (!this.answers) {
            return undefined;
        }
        if (answers.length >= 26) {
            return undefined;
        }

        for (let i = 0; i < answers.length; ++i) {
            existing[answers[i].id()] = true;
        }
        for (let i = 0; i < 26; ++i) {
            const letter = String.fromCharCode(65 + i);
            if (!(letter in existing)) {
                return letter;
            }
        }
        return undefined;
    }

    public readonly isItemMovableDown = ko.pureComputed(() => {
        const nr = this.selectedItemIndex();
        return nr >= 0 && nr < this.answers().length - 1;
    });

    public readonly answerMoveDown = new UIAction(undefined, async () => {
        const selectedIndex = this.selectedItemIndex();
        if (selectedIndex < 0) {
            return;
        }
        if (selectedIndex >= this.answers().length - 1) {
            return;
        }
        const correctAnswer = this.correctOrder();
        correctAnswer.splice(selectedIndex, 2, correctAnswer[selectedIndex + 1], correctAnswer[selectedIndex]);
        await legacyPushPull(async () => {
            await ServerConnection.api.orderingsentences_edit_update({
                params: {
                    itemId: this.itemId,
                    setCorrectOrder: correctAnswer
                }
            });
        });
    });

    public readonly isItemMovableUp = ko.pureComputed(() => {
        return this.selectedItemIndex() > 0;
    });


    public readonly correctOrderHTML = ko.pureComputed(() => {
        const order = this.correctOrder();
        if (!order) {
            return '';
        }
        return order.join(' ');
    });

    public readonly answerMoveUp = new UIAction(undefined, async () => {
        const selectedIndex = this.selectedItemIndex();
        if (selectedIndex < 1) {
            return;
        }

        const correctAnswer = this.correctOrder();
        correctAnswer.splice(selectedIndex - 1, 2, correctAnswer[selectedIndex], correctAnswer[selectedIndex - 1]);
        await legacyPushPull(async () => {
            await ServerConnection.api.orderingsentences_edit_update({
                params: {
                    itemId: this.itemId,
                    setCorrectOrder: correctAnswer
                }
            });
        });
    });
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.orderingsentences.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.orderingsentences.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.orderingsentences.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.orderingsentences.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        return retVal;
    });
    public readonly enterSentencePlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.orderingsentences.edit.ENTER_ONE_SENTENCE_HERE']);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

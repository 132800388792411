import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { log } from '../../debug';
import { AS } from '../../dx_helper';
import * as HELPER from '../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { GetSession } from '../model/session';
import { SpellingMatrixData, Table, Value } from '../model/spellingmatrix/SpellingMatrixData';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-spellingmatrix';

export type IParams = IItemDefinitionWidgetParams;

interface ButtonGroupItem<T> extends DevExpress.ui.dxButtonGroupItem {
    key: T
}

export class VMValue {
    private formatGap(val: string) {
        if (!val) {
            return val;
        }
        switch (this.value.gapFormat) {
            case 'AsIs':
                return val;
            case 'FirstLetterUpperCase':
                return val.substr(0, 1).toUpperCase() + val.substr(1);
            default:
                throw new Error();
        }
    }
    public readonly wordCss = ko.pureComputed(() => {
        if (this.model.mode() === 'INTERACTIVE') {
            return '';
        }
        const correct = this.value.rawData.correctValue;
        if (!correct) {
            return '';
        }
        const given = this.value.given();
        if (!given) {
            return 'word-notattempted';
        }
        if (correct === this.value.given()) {
            return 'word-correct';
        } else {
            return 'word-incorrect';
        }
    });

    public readonly gapHtml = ko.pureComputed(() => {
        const retVal = [];
        const given = this.value.given();
        retVal.push(this.preGap);
        if (given) {
            retVal.push(`<span class='matrix-gap-set'>${this.formatGap(given)}</span>`);
        } else {
            retVal.push(`<span class='matrix-gap-empty'><i class="fa fa-question" aria-hidden="true"></i></span>`);
        }
        retVal.push(this.postGap);
        return retVal.join('');
    });

    constructor(readonly model: MyModel, readonly value: Value) {
        this.preGap = value.preGap;
        this.postGap = value.postGap;

        this.bgOptions = {
            items: [],
            keyExpr: 'key',
            selectionMode: 'single',
            selectedItemKeys: [value.given()],
            onSelectionChanged: e => {
                if (!e.addedItems || !e.addedItems.length) {
                    this.value.given('');
                } else {
                    const item: ButtonGroupItem<string> = e.addedItems[0];
                    this.value.given(item.key);
                }
            }
        };
        for (const ans of value.table.smAnswers) {
            this.bgOptions.items.push(AS<ButtonGroupItem<string>>({
                text: ans,
                key: ans,
            }));
        }
    }
    public readonly preGap: string;
    public readonly postGap: string;
    public readonly gap = ko.pureComputed(() => {
        const given = this.value.given();
        if (!given) {
            return '__';
        }
        return given;
    });
    public readonly bgOptions: DevExpress.ui.dxButtonGroup.Properties;
}

export class VMTable {
    public readonly caption: string;
    public readonly values: VMValue[];
    constructor(readonly model: MyModel, readonly table: Table) {
        this.caption = table.caption;
        this.values = table.values.map(x => new VMValue(model, x));
    }

}
export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly tables: VMTable[];

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SpellingMatrixData)) {
            throw new Error();
        }
        this.data = data;
        this.tables = data.tables.map(x => new VMTable(this, x));

    }


    public readonly data: SpellingMatrixData;

    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                HELPER.assertNever(mode);
                throw new Error();

        }
    });

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });



    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        return false;
        /*
        if (this.shapes.some(x => x.answer.isSelected())) {
            return false;
        }
        const golemData = getGolemData(this.itemId);
        let pickedId: string;
        if (typeof golemData === 'string') {
            pickedId = golemMatch(this.shapes.map(x => ({
                key: x.answer.id,
                value: x.answer.shape
            })), golemData);

        }
        if (!pickedId) {
            log(`Golem has no suggestion for ${this.itemId}. Pick a random one.`);
            pickedId = getRandomEntry(this.shapes).answer.id;
        }
        const pick = this.shapes.find(x => x.answer.id === pickedId);
        await pick.select.invoke(undefined, true);
        */
        return true;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();


        this.loaded(true);
    }

    public readonly textHTML = ko.pureComputed(() => this.data.textHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME),
});

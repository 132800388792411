import $ from 'jquery';
import * as ko from 'knockout';
import Snap from 'snapsvg';
import { log } from '../../../debug';
import { golemMatch } from '../../../golem';
import { getGolemData } from '../../../Gremlin';
import * as HELPER from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry } from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { SingleChoiceHotSpotData, SingleChoiceHotSpotData_Answer } from '../../model/singlechoicehotspot/SingleChoiceImageData';
import { htmlString } from './singlechoicehotspot.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-singlechoicehotspot';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';
const CIRCLE_REGEX = /^Circle\(x=([0-9]+)%,y=([0-9]+)%,r=([0-9]+)%\)$/i;

export type IParams = IItemDefinitionWidgetParams;

class Shape {
    public readonly svgElem: Snap.Element;

    public readonly select = new UIAction(undefined, async () => {
        await this.vm.data.setAnswer(this.answer.id);
    });

    constructor(readonly vm: MyModel, readonly answer: SingleChoiceHotSpotData_Answer) {
        const shapeStr = answer.shape;

        const regEx = CIRCLE_REGEX.exec(shapeStr);
        if (!regEx) {
            throw new Error(`Unhandled shape format '${shapeStr}'`);

        }
        let x = parseFloat(regEx[1]);
        let y = parseFloat(regEx[2]);
        let r = parseFloat(regEx[3]);

        let circle = this.vm.snap.circle(this.vm.data.width * x / 100, this.vm.data.height * y / 100, this.vm.data.width * r / 100);
        circle.attr({ class: 'Circle' });
        circle.click(() => {
            if (vm.mode() === 'INTERACTIVE') {
                this.select.click();
            }
        });
        this.svgElem = circle;

    }

    update() {
        this.svgElem.toggleClass('Correct', this.answer.isCorrectAnswer());
        this.svgElem.toggleClass('Selected', this.answer.isSelected());

    }
}
export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    public snap: Snap.Paper;

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SingleChoiceHotSpotData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: SingleChoiceHotSpotData;

    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                HELPER.assertNever(mode);
                throw new Error();

        }
    });

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });

    private shapes: Shape[];

    private update() {
        for (const shape of this.shapes) {
            shape.update();
        }
    }
    private initSVG() {
        const element = $(this.componentInfo.element).find('.question-content');
        const htmlElement = element.get(0);
        const vm = this;

        for (let i = htmlElement.childNodes.length - 1; i >= 0; i--) {
            ko.removeNode(htmlElement.childNodes[i]);
        }

        const imgWidth = this.data.width;
        const imgHeight = this.data.height;
        const imgUrl = vm.imgUrl();
        if (!imgWidth || !imgHeight || !imgUrl) {
            return;
        }

        //tslint:disable-next-line:no-http-string
        const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        $(svgElement).attr({
            width: imgWidth,
            height: Math.max(imgHeight, 300),
        });
        htmlElement.appendChild(svgElement);
        const snap = Snap(svgElement);
        this.snap = snap;
        const img = snap.image(imgUrl, 0, 0, imgWidth, imgHeight);

        img.attr({ 'xlink:href': imgUrl });
        img.attr({ alt: this.imgAlt() });
        img.attr({ width: imgWidth });
        img.attr({ height: imgHeight });
        img.attr({ preserveAspectRatio: 'none' });

        this.shapes = this.data.answers.map(x => new Shape(this, x));
    }

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (this.shapes.some(x => x.answer.isSelected())) {
            return false;
        }
        const golemData = getGolemData(this.itemId);
        let pickedId: string;
        if (typeof golemData === 'string') {
            pickedId = golemMatch(this.shapes.map(x => ({
                key: x.answer.id,
                value: x.answer.shape
            })), golemData);

        }
        if (!pickedId) {
            log(`Golem has no suggestion for ${this.itemId}. Pick a random one.`);
            pickedId = getRandomEntry(this.shapes).answer.id;
        }
        const pick = this.shapes.find(x => x.answer.id === pickedId);
        await pick.select.invoke(undefined, true);
        return true;
    }
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();

        this.initSVG();
        this.update();
        this.disposables.addDiposable(this.data.selectedAnswer.subscribe(() => {
            this.update();
        }));
        this.loaded(true);
    }

    public readonly imgUrl = ko.pureComputed(() => this.data.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.data.imgAlt);
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

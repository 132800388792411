
export function formatNumber(x: number) {
    if (isNaN(x) || typeof x !== 'number') {
        return '-';
    }
    const str = x.toFixed(1);
    if (str.endsWith('0')) {
        return str.substr(0, str.length - 2);
    } else {
        return str;
    }
}

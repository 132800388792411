import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { log } from '../../debug';
import { DisplayProgress, DisplayTime } from '../../its-itembank-api.g';
import { colorService } from '../colorservice';
import { ItemRaw, ParticipationContext, SectionRaw } from "../service/participation/ParticipationContext";
import { ParticipationService } from '../service/participation/service';
import { UIAction } from '../uiAction';
import * as i18n from './../../i18n/i18n';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'ui-overview';

class ItemVM {
    public readonly isPostponed = ko.pureComputed(() => {
        return this.status() === 'POSTPONED';
    });
    public readonly isRevisable = ko.pureComputed(() => {
        return this.status() === 'REVISABLE';
    });

    public readonly actionGoto: UIAction<{}>;
    public readonly width = ko.pureComputed(() => {
        const title = this.title;
        if (!title) {
            return '1em';
        }
        return title.length + 'em';
    });

    public readonly id: string;
    public readonly itemId: string;
    public readonly title: string;
    public readonly participationContext: ParticipationContext;

    constructor(readonly section: SectionVM, readonly item: ItemRaw) {
        this.id = item.id;
        this.participationContext = section.participationContext;
        this.itemId = item.itemId;
        this.title = item.index.toString();
        const itemInfo = this.participationContext.getTopItemItemInfo(this.itemId);


        this.actionGoto = new UIAction(`${WIDGET_NAME}__Goto--item-${this.itemId}`, async (e) => {
            if (itemInfo.locked) {
                e.success = false;
                e.object = { itemId: this.itemId };
                e.error = { message: 'item is locked' };
                return;
            }
            await this.participationContext.GotoItem({ itemId: this.itemId }, e);
        }, {
            isEnabled: () => !itemInfo.locked
        });
    }

    public readonly subjectColor = ko.pureComputed(() => {
        return colorService.getColor(this.section?.section.sectionInfo.color || '0');
    });

    public readonly subjectColorLight = ko.pureComputed(() => {
        return colorService.getColorLight(this.section?.section.sectionInfo.color || '0', 30);
    });

    public readonly cssClassName = ko.pureComputed(() => {
        const css = new Array<string>();
        css.push('item');

        css.push(this.status());

        return css.join(' ');
    });

    public readonly status = ko.pureComputed(() => {
        return this.participationContext.getNavigationStatus(this.itemId);
    });

    public readonly isDone = ko.pureComputed(() => {
        return this.participationContext.isTopItemDone(this.item.itemDocRefId);
    });
    public readonly MidIconHtml = ko.pureComputed(() => {
        if (this.isDone()) {
            return '<i class="material-icons left">done</i>';
        }
        if (this.isRevisable()) {
            return '<i class="material-icons left">replay</i>';
        }
        if (this.isPostponed()) {
            return '<i class="material-icons left">pause</i>';
        }
        return '';
    });
    public readonly MidText = ko.pureComputed(() => {
        if (!this.MidIconHtml()) {
            return this.title;
        }
        return undefined;
    });
    public readonly TopLeftText = ko.pureComputed(() => {
        if (this.MidIconHtml()) {
            return this.title;
        }
        return undefined;
    });
}

class SectionVM {

    public readonly id: string;
    public readonly name: string;
    public readonly title: string;
    public readonly description: string;

    public readonly itemsTotal: number;
    public readonly itemsPending: number;

    public readonly actionStart: UIAction<{}>;

    public readonly progressText = ko.pureComputed(() => {
        return this.parentVM.sessionData().examInfo.executionPage.progressLabel.value || i18next.t(['ui.overview.PROGRESS_SECTION']);
    });
    public readonly minutesPassedOrRemainingText = ko.pureComputed(() => {
        return this.parentVM.minutesPassedOrRemainingText();
    });
    public readonly statusText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.STATUS']);
    });
    public readonly participationContext: ParticipationContext;

    constructor(readonly parentVM: ViewModel, readonly section: SectionRaw) {
        this.id = section.id;
        this.participationContext = parentVM.participationContext;
        this.name = section.sectionInfo.name;
        this.title = section.sectionInfo.title;
        this.description = section.sectionInfo.description;

        const { total, pending } = this.participationContext.sectionProgress(section.id);
        this.itemsTotal = total;
        this.itemsPending = pending;

        this.actionStart = new UIAction('ui-overview__Start--' + this.id, async (e) => {
            if (this.isDone()) {
                return;
            }
            await this.parentVM.participationContext.gotoSection(this.section.id, e);

        });

    }

    public readonly items = ko.pureComputed(() => {
        return this.section.items && this.section.items.map(item => new ItemVM(this, item)) || [];
    });



    public readonly buttonCompletedPercent = ko.pureComputed(() => {
        const total = this.itemsTotal;
        const pending = this.itemsPending;
        if (!total) {
            return '0%';
        }
        const percent = Math.round((total - pending) * 100 / total);
        return percent + '%';
    });

    public readonly staticButtonColor = ko.pureComputed(() => {
        if (this.isSelected()) {
            return this.subjectColorLight20();
        } else {
            return 'white';
        }
    });

    public readonly buttonColorCSS = ko.pureComputed(() => {
        const cssClasses = new Array<string>();
        cssClasses.push('tabbutton');
        if (this.isSelected()) {
            cssClasses.push('selected');
        }
        if (this.isDone()) {
            cssClasses.push('completed');
        }
        return cssClasses.join(' ');
    });

    public readonly startText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.START']);
    });

    public readonly questionsText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.QUESTIONS']);
    });
    public readonly categoryColorCSS = ko.pureComputed(() => {
        const cssClasses = new Array<string>();
        cssClasses.push('category');
        if (this.isDone()) {
            cssClasses.push('completed');
        }
        return cssClasses.join(' ');
    });

    public readonly subjectColor = ko.pureComputed(() => {
        return colorService.getColor(this.section.sectionInfo.color || '0');
    });
    public readonly subjectColorLight10 = ko.pureComputed(() => {
        return colorService.getColorLight(this.section.sectionInfo.color || '0', 10);

    });
    public readonly subjectColorLight20 = ko.pureComputed(() => {
        return colorService.getColorLight(this.section.sectionInfo.color || '0', 20);

    });
    public readonly subjectColorLight30 = ko.pureComputed(() => {
        return colorService.getColorLight(this.section.sectionInfo.color || '0', 30);

    });



    public readonly backgroundColorCSS = ko.pureComputed(() => {
        const cssClasses = new Array<string>();
        cssClasses.push('bgd-watermark');
        cssClasses.push('background');
        return cssClasses.join(' ');
    });

    public readonly isSelected = ko.pureComputed(() => {
        const sectionId = this.parentVM.currentSection() && this.parentVM.currentSection().id;
        return this.id === sectionId;
    });

    public readonly actionSelectSection = new UIAction(undefined, async (e) => {
        this.parentVM.currentSectionId(this.id);
    });

    public readonly isDone = ko.pureComputed(() => {
        const itemInfo = this.participationContext.getSectionItemInfo(this.section.id);
        return itemInfo.pending === 0;
    });

    public readonly progressPercent = ko.pureComputed(() => {
        const itemsTotal = this.itemsTotal;
        const itemsPending = this.itemsPending;
        switch (this.parentVM.displayProgressSetting()) {
            case DisplayProgress.Passed:
                return Math.round((itemsTotal - itemsPending) * 100 / itemsTotal);
            case DisplayProgress.Remaining:
                return Math.round(itemsPending * 100 / itemsTotal);
            default:
                return 100;
        }

    });

    public readonly circleProgressSectionDegree = ko.pureComputed(() => {
        const p = Math.round(this.progressPercent() * 360 / 100);
        return `rotate(${p}deg)`;
    });

    public readonly circleProgressSectionCSS = ko.pureComputed(() => {
        const percent = this.progressPercent();

        return (percent > 50) ?
            'progress-pie-chart completion-percentage gt-50' : 'progress-pie-chart completion-percentage ';
    });

    public readonly circleBackgroundColorCSS = ko.pureComputed(() => {
        const cssClasses = new Array<string>();
        cssClasses.push('ppc-percents');
        cssClasses.push('background');
        return cssClasses.join(' ');
    });

    public readonly percentSectionCompleted = ko.pureComputed(() => {
        const itemsTotal = this.itemsTotal;
        const itemsPending = this.itemsPending;
        switch (this.parentVM.displayProgressSetting()) {
            case DisplayProgress.Passed:
                return Math.round((itemsTotal - itemsPending) * 100 / itemsTotal);
            case DisplayProgress.Remaining:
                return Math.round(itemsPending * 100 / itemsTotal);
            default:
                return 100;
        }
    });
}

export interface IParams {
    sessionId: string;
}

export class ViewModel extends AbstractWidget {
    public participationContext: ParticipationContext;
    public readonly currentItemId = ko.pureComputed(() => {
        return this.participationContext.currentItem();
    });

    public readonly forceOverview = ko.pureComputed({
        read: () => {
            return this.participationContext.forceOverview() || false;
        }, write: newVal => {
            this.participationContext.forceOverview(newVal);
        }, owner: this
    });

    /*
    public async OnRefresh() {
        await this.refetchGraphQLSession();
        return super.OnRefresh();
    }
    */

    public readonly showFinishedComplete = ko.pureComputed(() => {
        const session = this.sessionData();
        if (!session) {
            return undefined;
        }
        switch (session.examInfo.executionPage.finishButton.visibility) {
            case 'show':
                return true;
            case 'hide':
                return false;
        }
        return session.examInfo.navigationStyle == 'PreviousNext';
    });

    public readonly showFinishedPartial = ko.pureComputed(() => {
        const session = this.sessionData();
        if (!session) {
            return undefined;
        }
        switch (session.examInfo.welcomePage.cancelButton.visibility) {
            case 'show':
                return true;
            case 'hide':
                return false;
        }
        return false;
    });

    public readonly finishCSS = ko.pureComputed(() => {

    });


    public readonly hasTimeDisplay = ko.pureComputed(() => {
        return this.displayTime() !== DisplayTime.No;
    });

    public readonly finishCompleteText = ko.pureComputed(() => {
        const session = this.sessionData();
        if (!session) {
            return undefined;
        }
        return session.examInfo.executionPage.finishButton.label.value || i18next.t(['ui.overview.FINISH_SUBMIT']);
    });
    public readonly finishPartialText = ko.pureComputed(() => {
        const session = this.sessionData();
        if (!session) {
            return undefined;
        }
        return session.examInfo.welcomePage.cancelButton.label.value || i18next.t(['ui.overview.ABORT_EXAM']);
    });

    public readonly showButtons = ko.pureComputed(() => {
        return this.showFinishedComplete() || this.showFinishedPartial();
    });

    public readonly actionFinishComplete = new UIAction('ActionFinish', async (e) => {
        await this.participationContext.finishCompleteExam();
    });
    public readonly actionFinishPartial = new UIAction('ActionFinishPartial', async (e) => {
        await this.participationContext.finishPartialExam();
    });
    public readonly isSubmitPostpone = ko.pureComputed(() => {
        const session = this.sessionData();
        if (!session) {
            return undefined;
        }
        return (session.examInfo.navigationStyle === 'SubmitPostpone') || (session.examInfo.navigationStyle === 'SubmitPostponeAutoFocus');
    });
    public readonly isPreviousNext = ko.pureComputed(() => {
        return this.participationContext.isPreviousNext();
    });

    public readonly itemsTotal = ko.pureComputed(() => {
        const itemInfo = this.participationContext.getSessionItemInfo();
        return itemInfo && itemInfo.total;
    });
    public readonly itemsPending = ko.pureComputed(() => {

        const itemInfo = this.participationContext.getSessionItemInfo();
        if (!itemInfo) {
            return undefined;
        }
        if (this.isSubmitPostpone()) {
            return itemInfo.pending;
        } else {
            return itemInfo.total - itemInfo.completed;
        }
    });
    constructor(readonly params: IParams) {
        super();
        this.participationContext = ParticipationService.GetSession(params.sessionId);
    }
    public readonly isAvailable = ko.pureComputed(() => !!this.sessionData());



    private async gremlins() {
        if (!this.isAvailable()) {
            return true;
        }
        const currentSection = this.currentSection();
        if (currentSection) {
            const items = currentSection.items();
            for (const i of items) {
                const enabled = i.actionGoto && i.actionGoto.isEnabled();
                if (enabled) {
                    await i.actionGoto.invoke();
                    return true;
                }
            }
        }

        if (this.participationContext.isExamComplete()) {
            await this.actionFinishComplete.invoke();
            return true;
        }

        const sections = this.sections();
        for (const section of sections) {
            if (section.isDone()) {
                continue;
            }
            await section.actionStart.invoke();
            return true;
        }
        return false;
    }

    public async initialize() {
        await super.initialize();
        this.registerGremlin({
            name: `${WIDGET_NAME}`,
            priority: 1000,
            action: () => this.gremlins()
        });

        //const currentItem = this.currentItemId();
        //const currentSection = this.participationContext.isExamComplete() ? '' : currentItem && this.data().examSession.sections.find(x => x.items.some(x => x.itemDocRefId === currentItem));
        // this.currentSectionId(currentSection && currentSection.id);
    }


    public readonly welcomeText = ko.pureComputed(() => {
        return this.data().examSession.examInfo.welcomePage.text.value;
    });

    public readonly data = ko.pureComputed(() => {
        const data = this.participationContext.initData();
        if (!data) {
            return undefined;
        }
        return data;
    });
    public readonly sessionData = ko.pureComputed(() => {
        const data = this.participationContext.initData();
        if (!data) {
            return undefined;
        }
        return data.examSession;
    });

    public readonly actionClose = new UIAction(undefined, async () => {
        this.forceOverview(false);
    });

    public readonly actionOverview = new UIAction(undefined, async () => {
        this.currentSectionId('');
    });

    public readonly sections = ko.pureComputed(() => {
        const sessionData = this.sessionData();
        if (!sessionData) {
            return undefined;
        }
        const sections = sessionData.sections;
        return sections.map(section => new SectionVM(this, section));
    });

    public readonly currentSectionId = ko.observable('');

    public readonly currentSection = ko.pureComputed(() => {
        return this.sections().find(x => x.id === this.currentSectionId());
    });

    ///returns an integer value in the range [0, 100]
    ///returns false if there is no time limit
    private readonly timePercent = ko.pureComputed(() => {
        switch (this.displayTime()) {
            case DisplayTime.Passed:
                return this.participationContext.tickTock.timePassedPercent();
            case DisplayTime.Remaining:
                return this.participationContext.tickTock.timeleftPercent();
            default:
                return 100;
        }

    });

    public readonly circleTimeCSS = ko.pureComputed(() => {
        const timePercent = this.timePercent();
        log(`${WIDGET_NAME} circleTimeCSS timePercent:${timePercent}`);
        if (timePercent > 50) {
            return 'progress-pie-chart completion-percentage gt-50';
        } else {
            return 'progress-pie-chart completion-percentage ';
        }
    });

    public readonly overviewText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.OVERVIEW']);
    });
    public readonly minutesPassedOrRemainingText = ko.pureComputed(() => {
        const label = this.sessionData().examInfo.executionPage.timeLabel.value;
        if (label) {
            return label;
        }
        const dt = this.displayTime();
        switch (dt) {
            case DisplayTime.No:
                return '';
            case DisplayTime.Remaining:
                return i18next.t(['ui.overview.MINUTES_LEFT']);
            case DisplayTime.Passed:
                return i18n.t(['ui.overview.MINUTES_PASSED']);
            default:
                throw new Error();
        }
    }); public readonly progressOverviewText = ko.pureComputed(() => {
        return this.sessionData().examInfo.executionPage.progressLabel.value || i18next.t(['ui.overview.PROGRESS_OVERVIEW']);
    });
    public readonly legendCurrentItemText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.YOU_ARE_CURRENTLY_WORKING_ON_THIS_QUESTION']);
    });
    public readonly legendSubmittedText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.THIS_QUESTION_HAS_BEEN_ANSWERED']);
    });
    public readonly legendRevisableText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.YOU_CAN_REVISE_THIS_QUESTION']);
    });
    public readonly legendIncompleteItemText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.THIS_QUESTION_HAS_NOT_BEEN_FILLED_OUT_COMPLETELY']);
    });
    public readonly legendDoneItemText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.THIS_QUESTION_HAS_BEEN_ANSWERED']);
    });
    public readonly legendPostponedText = ko.pureComputed(() => {
        return i18next.t(['ui.overview.POSTPONED_QUESTIONS_ARE_HIGHLIGHTED_IN_RED']);
    });

    public readonly overviewCSS = ko.pureComputed(() => {
        const css = new Array<string>();
        if (!this.currentSection()) {
            css.push('tabbutton selected');
        }
        return css.join(' ');
    });

    public readonly circleProgressDegree = ko.pureComputed(() => {
        const p = Math.round(this.progressPercent() * 360 / 100);
        return `rotate(${p}deg)`;
    });

    public readonly circleTimeDegree = ko.pureComputed(() => {
        const p = this.timePercent() * 360 / 100;
        return `rotate(${p}deg)`;
    });

    public readonly progressPercent = ko.pureComputed(() => {
        const { total: itemsTotal, pending: itemsPending } = this.participationContext.examProgress();
        switch (this.displayProgressSetting()) {
            case DisplayProgress.Passed:
                return Math.round((itemsTotal - itemsPending) * 100 / itemsTotal);
            case DisplayProgress.Remaining:
                return Math.round(itemsPending * 100 / itemsTotal);
            default:
                return 100;
        }

    });

    public readonly circleProgressCSS = ko.pureComputed(() => {
        const percent = this.progressPercent();

        return (percent > 50) ? 'progress-pie-chart completion-percentage gt-50' : 'progress-pie-chart completion-percentage ';
    });

    public readonly minutesPassedOrRemaining = ko.pureComputed(() => {
        switch (this.displayTime()) {
            case DisplayTime.Passed:
                return this.participationContext.tickTock.timePassedMinutes();
            case DisplayTime.Remaining:
                return this.participationContext.tickTock.timeleftMinutes();
            default:
                return undefined;

        }
    });

    public readonly displayTime = ko.pureComputed(() => {
        const dt = this.participationContext.initData().examSession.examInfo.executionPage.displayTime;
        switch (dt) {
            case DisplayTime.No:
            case DisplayTime.Passed:
                return dt;
            case DisplayTime.Default:
            case DisplayTime.Remaining:
            default:
                return DisplayTime.Remaining;
        }

    });
    public readonly displayProgressSetting = ko.pureComputed(() => {
        const dt = this.participationContext.initData().examSession.examInfo.executionPage.displayProgress;
        switch (dt) {
            case DisplayProgress.Remaining:
            case DisplayProgress.No:
                return dt;
            default:
                return DisplayProgress.Passed;
        }
    });

    public readonly displayProgress = ko.pureComputed(() => {
        return this.participationContext.displayProgress();
    });

    public readonly resetOnNavigate = ko.pureComputed(() => {
        const sd = this.sessionData();
        if (!sd) {
            return undefined;
        }
        return sd.examInfo.resetOnNavigate;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

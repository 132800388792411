import $ from 'jquery';
import * as ko from 'knockout';
import { error, log } from '../debug';
import * as query from '../getquery';
import { BrowserSessionType } from '../its-itembank-api.g';
import { initITSR3, normalWindowMode } from './focusmanager/index';
import { isNoNetwork } from './getAmbientBrowserSessionId';
import { ServerConnection } from './RestAPI';
import { isUIWorking } from './uiAction';
import { ConnectionStatus } from './RestAPIBase';

export let app: EntryApp;

export class EntryApp {
    private readonly mainWidget: string;
    constructor(readonly mapping: Map<string, string>) {
        this.mainWidget = query.params.get('main') || '';
        this.noNetwork(isNoNetwork());
        app = this;
    }
    public readonly connectionLost = ko.pureComputed(() => {
        switch (ServerConnection.connectionStatus()) {
            case ConnectionStatus.DisconnectedLoginRequired:
            case ConnectionStatus.DisconnectedTemporily:
                return true;
            default:
                return false;
        }
    });
    public readonly noNetwork = ko.observable(false);
    public readonly selectedApp = ko.observable('');
    private readonly isLoaded = ko.observable(false);
    public readonly isUIWorking = ko.pureComputed(() => isUIWorking());

    public selectApp(widgetName: string) {
        let w = widgetName;
        if (this.mapping && this.mapping.has(widgetName)) {
            w = this.mapping.get(w);
        }
        this.selectedApp(w);
    }

    public async run() {
        try {
            await ServerConnection.Init();
        } catch (e) {
            console.log('Unable to initalize server connection: %o', e);
            return;
        }

        try {
            await initITSR3();
            normalWindowMode();
            if (this.mainWidget) {
                this.selectApp(this.mainWidget);
            } else {
                switch (ServerConnection.initData.whoAmI.type) {
                    case BrowserSessionType.Anonymous:
                        this.selectApp('ui-login');
                        break;
                    case BrowserSessionType.User:
                        this.selectApp('ui-app');
                        break;
                    case BrowserSessionType.Candidate:
                    case BrowserSessionType.Review: {
                        if (query.params.get('mode') === 'print') {
                            log(`Launching PRINT mode for candidate`);
                            this.selectApp('ui-print');
                        } else if (query.params.get('mode') === 'review') {
                            log(`Launching REVIEW mode for candidate`);
                            this.selectApp('ui-sessionreport');
                        } else {
                            const itemHost = ServerConnection.initData.whoAmI.myExamSession.examInfo.itemHost;
                            switch (itemHost) {
                                case 'hierarchical':
                                    log(`Launching ui-participate for candidate`);
                                    this.selectApp('ui-participate');
                                    break;
                                case 'singleitem':
                                    log(`Launching ui-participatesingle for candidate`);
                                    this.selectApp('ui-participatesingle');
                                    break;
                                default:
                                    error(`Unknown Itemhost for candidate session.`);
                                    break;
                            }
                        }
                        break;
                    }
                    default:
                        error(`No main widget. Valid values are: ${Array.from(this.mapping.keys()).join(',')}`);
                }
            }
            ko.options.deferUpdates = true;
            ko.applyBindings(this, document.body);
            this.isLoaded(true);
            $(document.body).removeClass('itsr3-loading').addClass('itsr3-loaded');
        }
        catch (err) {
            console.log(err);
            alert('Fehler: ' + err.toString());
        }
    }
}

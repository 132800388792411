import type DevExpress from 'devextreme/bundles/dx.all';
import dxDataGrid from 'devextreme/ui/data_grid';
import $ from 'jquery';
import { log } from '../debug';
import { AS } from '../dx_helper';

type DxFormItem = DevExpress.ui.dxForm.SimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem;

interface DxSimpleItem<OPTIONS> extends DevExpress.ui.dxForm.SimpleItem {
    editorOptions?: OPTIONS
}

export type HasItems = { items?: DxFormItem[] };


export type UpdateFunction<UPDATE, VAL> = (val: VAL) => UPDATE;
type SimpleItem<TOptions> = Omit<Omit<DxSimpleItem<TOptions>, "editorType">, "itemType">;
type SimpleItemType = Pick<DevExpress.ui.dxForm.SimpleItem, "editorType">;

export class FormBuilder<T, UPDATE> {
    public readonly formOptions: DevExpress.ui.dxForm.Properties = {};
    private _form: DevExpress.ui.dxForm | undefined;
    public get form() {
        return this._form;
    }
    public readonly fields = new Map<string, UpdateFunction<UPDATE, any>>();

    public addSimpleItem<VAL>(collection: HasItems, item: Omit<DevExpress.ui.dxForm.SimpleItem, "itemType">, update?: UpdateFunction<UPDATE, VAL>) {
        return this.addItem(collection, {
            itemType: 'simple',
            ...item,
        }, update);
    }
    public addCheckbox(collection: HasItems, item: SimpleItem<DevExpress.ui.dxCheckBox.Properties>, update?: UpdateFunction<UPDATE, boolean>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, {
            editorType: 'dxCheckBox',
            ...item,
        }, update);
    }
    public addColorBox(collection: HasItems, item: SimpleItem<DevExpress.ui.dxColorBox.Properties>, colors: string[], update?: UpdateFunction<UPDATE, string>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (colors && colors.length) {
            let cbEditor: DevExpress.ui.dxColorBox = undefined;
            item.editorOptions.buttons = item.editorOptions.buttons || [];
            const buttons = item.editorOptions.buttons;

            if (item.editorOptions.showClearButton && buttons.indexOf('clear') === -1) {
                buttons.push('clear');
            }
            for (let i = 0; i < colors.length; ++i) {
                const color = colors[i];
                const label = `${i + 1}`;
                const svg = `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg"><rect stroke-width="0" id="svg_1" height="100" width="100" y="0" x="0" stroke="#000" fill="${color}"/></svg>`;
                buttons.push({
                    name: label,

                    location: 'after',
                    options: {
                        icon: 'data:image/svg+xml,' + encodeURIComponent(svg),
                        stylingMode: 'outlined',
                        //width: 15,
                        //height: 15,
                        hint: color,
                        onClick: e => {
                            log(`Change color to ${color}`);
                            cbEditor.option('value', color);
                        }
                    }
                });
            }
            buttons.push('dropDown');
            item.editorOptions.onInitialized = e => cbEditor = e.component;
            item.editorOptions.onDisposing = e => cbEditor = undefined;
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, {
            editorType: 'dxColorBox',
            ...item,
        }, update);
    }

    public addSelectBox<VAL extends string>(collection: HasItems, item: SimpleItem<DevExpress.ui.dxSelectBox.Properties>, update?: UpdateFunction<UPDATE, VAL>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, {
            editorType: 'dxSelectBox',
            ...item,
        }, update);
    }
    public addLookup<VAL extends string>(collection: HasItems, item: SimpleItem<DevExpress.ui.dxLookup.Properties>, update?: UpdateFunction<UPDATE, VAL>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, Object.assign(item, AS<SimpleItemType>({
            editorType: 'dxLookup',
        })), update);
    }
    public addTreeList<VAL extends string>(collection: HasItems, item: SimpleItem<DevExpress.ui.dxDropDownBox.Properties>, treeListOptions: DevExpress.ui.dxTreeList.Properties, update?: UpdateFunction<UPDATE, string[]>) {
        const ddOptions: DevExpress.ui.dxDropDownBox.Properties = Object.assign(item.editorOptions, AS<DevExpress.ui.dxDropDownBox.Properties>({
            contentTemplate: e => {
                let onContentReadyCalled = 0;
                const value = e.component.option('value');
                const o: DevExpress.ui.dxTreeList.Properties = Object.assign(
                    AS<DevExpress.ui.dxTreeList.Properties>({
                        dataStructure: 'plain',
                        keyExpr: 'id',
                        parentIdExpr: 'parentId',
                        searchPanel: {
                            visible: false
                        },
                        rootValue: '',
                        editing: {
                            allowAdding: false,
                            allowDeleting: false,
                            allowUpdating: false,
                        },
                        height: 400,
                        autoExpandAll: true,
                        selection: {
                            mode: 'multiple'
                        },
                    }),
                    treeListOptions,
                    AS<DevExpress.ui.dxTreeList.Properties>({
                        dataSource: e.component.option('dataSource'),
                        onContentReady: args => {
                            if (onContentReadyCalled > 0) {
                                return;
                            }
                            ++onContentReadyCalled;
                            if (!value) {
                                args.component.clearSelection();
                            } else {
                                args.component.selectRows(value, false);
                            }
                        },
                        onSelectionChanged: args => {
                            const value = args.selectedRowKeys;
                            e.component.option('value', value);
                        },

                    }));
                const $treeView = $('<div />').dxTreeList(o);
                const treeView = $treeView.dxTreeList('instance');
                e.component.on('valueChanged', (args: any) => {
                    if (args.value) {
                        treeView.selectRows(args.value, false);
                    } else {
                        treeView.clearSelection();
                    }
                });
                return $treeView;
            },
        }));

        if (!update) {
            ddOptions.readOnly = true;
        }
        const i: DevExpress.ui.dxForm.SimpleItem = Object.assign(
            item,
            AS<SimpleItemType>({
                editorType: 'dxDropDownBox',
            }),
            AS<SimpleItem<DevExpress.ui.dxDropDownBox.Properties>>({
                editorOptions: ddOptions,
            }));
        return this.addSimpleItem(collection, i, update);
    }
    public addButton(collection: HasItems, item: Omit<DevExpress.ui.dxFormButtonItem, "itemType">) {
        return this.addItem<DevExpress.ui.dxFormButtonItem>(collection, {
            itemType: 'button',
            ...item,
        });
    }

    public addGroup(collection: HasItems, item: Omit<DevExpress.ui.dxFormGroupItem, "itemType">) {
        return this.addItem<DevExpress.ui.dxFormGroupItem>(collection, {
            itemType: 'group',
            ...item,
        });
    }

    public addTextBox(collection: HasItems, item: SimpleItem<DevExpress.ui.dxTextBox.Properties>, update?: UpdateFunction<UPDATE, string>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, {
            editorType: 'dxTextBox',
            ...item,
        }, update);
    }

    public addDateBox(collection: HasItems, item: SimpleItem<DevExpress.ui.dxDateBox.Properties>, update?: UpdateFunction<UPDATE, string>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, {
            editorType: 'dxDateBox',
            ...item,
        }, update);
    }
    public addDataGrid(collection: HasItems, item: Omit<SimpleItem<DevExpress.ui.dxDataGrid.Properties>, "template">) {
        const gridOptions = item.editorOptions;
        item.editorOptions = undefined;
        const i: DevExpress.ui.dxForm.SimpleItem = Object.assign({
            template: () => {
                const retVal: any = $('<div />');
                new dxDataGrid(retVal, gridOptions);
                return retVal;
            }
        });
        return this.addSimpleItem(collection, i);
    }
    public addTextArea(collection: HasItems, item: SimpleItem<DevExpress.ui.dxTextArea.Properties>, update?: UpdateFunction<UPDATE, string>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, {
            editorType: 'dxTextArea',
            ...item
        }, update);
    }
    public addNumberBox(collection: HasItems, item: SimpleItem<DevExpress.ui.dxNumberBox.Properties>, update?: UpdateFunction<UPDATE, number>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, {
            editorType: 'dxNumberBox',
            ...item,
        }, update);
    }
    public addTagBox(collection: HasItems, item: SimpleItem<DevExpress.ui.dxTagBox.Properties>, update?: UpdateFunction<UPDATE, string[]>) {
        if (!item.editorOptions) {
            item.editorOptions = {};
        }
        if (!update) {
            item.editorOptions.readOnly = true;
        }
        return this.addSimpleItem(collection, Object.assign(item, AS<SimpleItemType>({
            editorType: 'dxTagBox'
        })), update);
    }

    public addItem<X extends DxFormItem>(collection: HasItems, item: X, update?: UpdateFunction<UPDATE, any>) {
        if (!collection.items) {
            collection.items = [];
        }
        collection.items.push(item);
        if (update) {
            const simpleItem = item as DevExpress.ui.dxForm.SimpleItem;
            if (simpleItem.dataField) {
                this.fields.set(simpleItem.dataField, update);
            } else {
                throw new Error('Programmers fault. Update function definied,');
            }
        }
        return item;
    }

    protected onInit() {

    }
    public init(data: T, forms: Set<DevExpress.ui.dxForm>) {
        const retVal = this.formOptions;
        retVal.formData = data;
        retVal.items = [];
        retVal.onInitialized = x => {
            this._form = x.component;
            forms.add(x.component);
        };
        retVal.onDisposing = x => {
            this._form = undefined;
            forms.delete(x.component);
        };
        this.onInit();
    }

}
import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import * as API from '../../../its-itembank-api.g';
import { toastService } from '../../../toastService';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
//import * as CopyItemToContainerMutation from './copyItemToContainer.mutation.graphql.g';
//import * as CopyItemToSubjectMutation from './copyItemToSubject.mutation.graphql.g';
//import * as Q from './init.query.graphql.g';
import { htmlString } from './manageitem.html.g';
import { IParams, IParamsInner, WIDGET_NAME } from './route';

type Q = Awaited<ReturnType<API.Sdk['ui_author_manageitem_init']>>;
interface SubjectInfo {
    subjectId: string
    subjectTitle: string
    subjectDocRefId: string
}

interface RadioGroupOption {
    name: string;
}

type ActionType = 'copy';
interface ActionRadioGroupOption extends RadioGroupOption {
    value: ActionType
}
type TargetType = 'subject' | 'container';
interface TargetRadioGroupOption extends RadioGroupOption {
    value: TargetType
}

export class ViewModel extends AbstractWidget {
    public readonly innerParams: IParamsInner;

    constructor(readonly params: IParams) {
        super();
        this.innerParams = params.currentRoute.widgetParams;
    }

    public readonly supportsSubItems = ko.observable(false);
    public async OnRefresh() {
        await super.OnRefresh();

        const retVal = new Map<string, SubjectInfo>();
        const r = await ServerConnection.api.ui_author_manageitem_init({
            currentItemId: this.params.currentRoute.widgetParams.itemDocRefId,
            targetSubjectId: this.params.currentRoute.widgetParams.subjectDocRefId,
        });
        for (const subject of r.subjects) {
            retVal.set(
                subject.docReferenceId, {
                subjectDocRefId: subject.docReferenceId,
                subjectId: subject.subjectId,
                subjectTitle: subject.title
            }
            );
        }
        this.availableSubjects(Array.from(retVal.values()));

        const subjectItems = r.subjectById.itemsForAuthor;
        this.availableContainer(subjectItems.filter(
            item => item.workflow.authoringStatus !== 'done' && !item.workflow.locked));
        this.supportsSubItems(r.item.get.itemType.supportsSubItems);
    }

    public readonly loaded = ko.observable(false);
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly availableSubjects = ko.observable<SubjectInfo[]>();
    public readonly availableContainer = ko.observableArray<Q['subjectById']['itemsForAuthor'][0]>();

    public readonly selectedAction = ko.observable<ActionType>('copy');

    public readonly actionHasTarget = ko.pureComputed(() => {
        return true;
    });

    public readonly selectedTarget = ko.observable('');
    public readonly targetRadioGroupOptions = ko.pureComputed(() => {
        const targets: TargetRadioGroupOption[] = [
            {
                name: i18next.t(['ui.author.manageitem.SUBJECT']),
                value: 'subject'
            }
        ];
        if (!this.supportsSubItems()) {
            targets.push({
                name: i18next.t(['ui.author.manageitem.COMBO']), value: 'container'
            });
        }
        const retVal = <DevExpress.ui.dxRadioGroup.Properties>{
            dataSource: targets,
            displayExpr: 'name',
            valueExpr: 'value',
            layout: 'horizontal',
            value: this.selectedTarget
        };
        return retVal;
    });

    public readonly needsContainerSelection = ko.pureComputed(() => {
        const target = this.selectedTarget();
        if (target === 'container') {
            return true;
        }
        return false;
    });
    public readonly needsSubjectSelection = ko.pureComputed(() => {
        const target = this.selectedTarget();
        if (target === 'subject') {
            return true;
        }
        return false;
    });

    public readonly selectedSubjectDocRefId = ko.observable<string>();
    public readonly subjectSelectBoxOptions = ko.pureComputed(() => {
        const retVal = <DevExpress.ui.dxSelectBox.Properties>{
            dataSource: this.availableSubjects(),
            placeholder: i18next.t(['ui.author.manageitem.SELECT_SUBJECT']),
            displayExpr: 'subjectTitle',
            valueExpr: 'subjectDocRefId',
            value: this.selectedSubjectDocRefId
        };
        return retVal;
    });
    public readonly selectedContainerDocRefId = ko.observable<string>();
    public readonly containerSelectBoxOptions = ko.pureComputed(() => {
        const retVal = <DevExpress.ui.dxSelectBox.Properties>{
            dataSource: this.availableContainer(),
            placeholder: i18next.t(['ui.author.manageitem.SELECT_COMBO']),
            displayExpr: 'itemId',
            valueExpr: 'docReferenceId',
            value: this.selectedContainerDocRefId
        };
        return retVal;
    });

    public readonly click = new UIAction(undefined, async () => {
        const action = this.selectedAction();
        switch (action) {
            case 'copy':
                await this.copyItem();
                break;
            default:
                throw new Error('Unknown action!');
        }
    });

    public readonly dxFormOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: {
                selectedSubject: this.selectedSubjectDocRefId,
                selectedContainer: this.selectedContainerDocRefId,
                selectedAction: this.selectedAction,
                selectedTarget: this.selectedTarget
            },
            colCount: 1,
            items: []
        };
        retVal.items.push({
            itemType: 'group',
            caption: i18next.t(['ui.author.manageitem.COPY_ITEM']),
            colCount: 3,
            items: []
        });
        if (this.actionHasTarget()) {
            const parent = retVal.items[0] as DevExpress.ui.dxFormGroupItem;
            parent.items.push({
                dataField: 'selectedTarget',
                editorType: 'dxRadioGroup',
                editorOptions: this.targetRadioGroupOptions(),
                label: {
                    text: i18next.t(['ui.author.manageitem.TARGET'])
                }
            });
        }
        if (this.needsSubjectSelection()) {
            const parent = retVal.items[0] as DevExpress.ui.dxFormGroupItem;
            parent.items.push({
                dataField: 'selectedSubject',
                editorType: 'dxSelectBox',
                editorOptions: this.subjectSelectBoxOptions(),
                label: {
                    text: i18next.t(['ui.author.manageitem.SUBJECT'])
                }
            });
        }
        if (this.needsContainerSelection()) {
            const parent = retVal.items[0] as DevExpress.ui.dxFormGroupItem;
            parent.items.push({
                dataField: 'selectedContainer',
                editorType: 'dxSelectBox',
                editorOptions: this.containerSelectBoxOptions(),
                label: {
                    text: i18next.t(['ui.author.manageitem.COMBO'])
                }
            });
        }
        var b: DevExpress.ui.dxFormButtonItem = {
            itemType: 'button',
            buttonOptions: {
                text: i18next.t(['ui.author.manageitem.EXECUTE']),
                onClick: this.click.click
            }
        };
        retVal.items.push(b);
        return retVal;
    });

    private async copyItem() {
        const target = this.selectedTarget();
        if (target === 'subject') {
            const subjectDocRefId = this.selectedSubjectDocRefId();
            const result = await legacyPushPull(() => ServerConnection.api.ui_author_manageitem_copyitemtosubject(
                {
                    item: {
                        docRefId: this.innerParams.itemDocRefId,
                        noteForAuthor: null
                    },
                    subjectDocRefId: subjectDocRefId
                }));
            toastService.info(result.item.copyItem.toSubject.message.value);
        } else if (target === 'container') {
            const selectedContainerDocRefId = this.selectedContainerDocRefId();
            const result = await legacyPushPull(() => ServerConnection.api.ui_author_manageitem_copyitemtocontainer(
                {
                    item: {
                        docRefId: this.innerParams.itemDocRefId,
                        noteForAuthor: null
                    },
                    containerDocRefId: selectedContainerDocRefId
                }));
            toastService.info(result.item.copyItem.toContainer.message.value);
        }
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

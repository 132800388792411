import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import { INIT, ItemDataModel } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'TypingTest_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;


export class TypingTestData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly rawData: DATA) {
        super();
        this.text = rawData.ttText;
        this.fromJS(rawData);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'TypingTest_QueryItemSessionData') {
            throw new Error();
        }
        this.typedText(data.typedText);
        this.errors(data.errors);
    }
    public readonly text: string;
    public readonly typedText = ko.observable<string>();
    public readonly errors = ko.observable<number>();
    public async setAnswer(text: string) {
        this.typedText(text);
    }

    public IsInteractionStarted() {
        return !!this.typedText();
    }
    public IsInteractionComplete() {
        return !!this.typedText();
    }
    public async reset() {
        this.typedText('');
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            TypingTest: {
                typedText: this.typedText(),
            }
        };
        return retVal;
    }

}

﻿import { deparam } from './uri';
let searchString = '';
if (window && window.location && window.location.search) {
    searchString = window.location.search.substr(1);
}
export const params = deparam(searchString);
if (window.ITSR3_ARGS) {
    for (const key of Object.keys(window.ITSR3_ARGS)) {
        if (!params.has(key)) {
            params.set(key, window.ITSR3_ARGS[key]);
        }
    }
}
import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoOrderingSelection_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type SELECTEDANSWER = DATA['selectedAnswers'][0];

export class OrderingSelectionData_Answer {
    constructor(private readonly model: OrderingSelectionData, public readonly rawData: SELECTEDANSWER) {
        this.id = rawData.answerId;
        this.value = translate(rawData.value, {});
    }
    public readonly id: string;
    public readonly value: string;
    public readonly index = ko.pureComputed(() => {
        const data = this.model.selectedAnswers();
        return data.indexOf(this.id);
    });
}

export class OrderingSelectionData extends ItemDataModel {
    public readonly selectedAnswers = ko.observable<string[]>();
    public readonly availableAnswers = ko.observable<string[]>();

    constructor(readonly meta: ItemModel, readonly rawData: DATA) {
        super();
        this.answers = [];
        this.answers.push(...rawData.selectedAnswers.map(a => new OrderingSelectionData_Answer(this, a)));
        this.answers.push(...rawData.availableAnswers.map(a => new OrderingSelectionData_Answer(this, a)));

        this.headerText = translate(rawData.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(rawData.question, {}),
            attachments: this.meta.attachments,
        });
        this.fromJS(rawData);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'KosovoOrderingSelection_QueryItemSessionData') {
            throw new Error();
        }
        this.selectedAnswers(data.selectedAnswers.map(a => a.answerId));
        this.availableAnswers(data.availableAnswers.map(a => a.answerId));
    }

    public readonly questionHTML: string;
    public readonly answers: OrderingSelectionData_Answer[];
    public readonly headerText: string;

    public async moveUp(answerId: string) {
        if (!answerId) {
            return;
        }
        const answer = this.answers.find(x => x.id === answerId);

        const selectedAnswerIndex = answer.index();
        if (selectedAnswerIndex === -1) {
            return;
        }

        const order = this.selectedAnswers();
        order.splice(selectedAnswerIndex - 1, 0, ...order.splice(selectedAnswerIndex, 1));
        this.selectedAnswers(order);
    }
    public async moveDown(answerId: string) {
        if (!answerId) {
            return;
        }
        const answer = this.answers.find(x => x.id === answerId);

        const selectedAnswerIndex = answer.index();
        if (selectedAnswerIndex > (this.answers.length - 1)) {
            return;
        }
        const order = this.selectedAnswers();
        order.splice(selectedAnswerIndex + 1, 0, ...order.splice(selectedAnswerIndex, 1));
        this.selectedAnswers(order);

    }

    public async select(answerId: string) {
        if (!answerId) {
            return;
        }
        const selected = this.selectedAnswers();
        const available = this.availableAnswers();
        const idx = available.indexOf(answerId);
        if (idx === -1) {
            return;
        }
        available.splice(idx, 1);
        selected.push(answerId);
        this.availableAnswers(available);
        this.selectedAnswers(selected);

    }
    public async deselect(answerId: string) {
        if (!answerId) {
            return;
        }
        const selected = this.selectedAnswers();
        const available = this.availableAnswers();
        const idx = selected.indexOf(answerId);
        if (idx === -1) {
            return;
        }
        selected.splice(idx, 1);
        available.push(answerId);
        this.selectedAnswers(selected);
        this.availableAnswers(available);

    }

    public IsInteractionStarted() {
        return this.selectedAnswers().length >= 1;
    }
    public IsInteractionComplete() {
        return this.selectedAnswers().length >= 2;
    }

    public async reset() {
        this.selectedAnswers([]);
        this.availableAnswers(this.answers.map(x => x.id));
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            OrderingSelection: {
                order: this.selectedAnswers()
            }
        };
        return retVal;
    }

}
import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { error, log } from './debug';
import { IDxWidget } from './dx_helper';
import * as GREMLIN from './Gremlin';
import { Disposables, DONE, DONEENUM } from './helper';
import { queueApiCall, RegisterRefreshFunction } from './ui/docmanager';

export const widgets = new Set<AbstractWidget>();
export const widgetsChanged = ko.observable(0);

async function Refresh() {
    log(`Triggering referesh on all active widgets`);
    await Promise.all(Array.from(widgets).map(async x => {
        try {
            x && x.OnRefresh();
        } catch (e) {
            log(`Error on refresh: ${e.message}`);
        }
    }));
    return DONE;
}

RegisterRefreshFunction(Refresh);

export class AbstractWidget {
    public readonly disposables = new Disposables();
    protected WIDGET_NAME: string;

    public DoInit({ WIDGET_NAME }: { WIDGET_NAME: string }): void {
        this.WIDGET_NAME = WIDGET_NAME;
        const init = async () => {
            try {
                await this.initialize();
                if (this.initCalled !== 1) {
                    const msg = `${WIDGET_NAME}: super.initialize() must be called exacly once (was: ${this.initCalled})`;
                    error(msg);
                    throw new Error(msg);
                }
            } catch (e) {
                error(`${WIDGET_NAME}: Error during init`, e);
            }
        };
        void init();
    }

    private initCalled = 0;
    protected async initialize() {
        widgets.add(this);
        widgetsChanged(widgetsChanged() + 1);
        this.initCalled++;
    }

    public async OnRefresh() {

    }

    protected onChange<T>(observable: ko.Subscribable<T>, id: string, changed: (val: T) => Promise<DONEENUM>, options?: { triggerRefresh?: boolean }) {
        this.disposables.addDiposable(observable.subscribe(newVal => {
            queueApiCall(id, async () => {
                return changed(newVal);
            }, options && options.triggerRefresh || false);
        }));
    }

    protected registerGremlin(gremlin: GREMLIN.IGremlin) {
        this.disposables.addDiposable(GREMLIN.registerGremlin(gremlin));
    }
    public dispose() {
        widgets.delete(this);
        widgetsChanged(widgetsChanged() + 1);
        this.disposables.dispose();
    }
}

export abstract class DxWidget extends AbstractWidget implements IDxWidget {
    public readonly grids = new Set<DevExpress.ui.dxDataGrid>();
    public readonly forms = new Set<DevExpress.ui.dxForm>();
    public readonly widgets = new Set<DevExpress.ui.Widget<unknown>>();

    public triggerResize() {
        log('calling repaint on forms');
        for (const f of Array.from(this.forms)) {
            f.repaint();
        }

    }
}
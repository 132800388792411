// tslint:disable:max-line-length:variable-name:class-name
import * as ko from 'knockout';
import { log } from '../../../debug';
import { golemMatch } from '../../../golem';
import { getGolemData, isStringMap } from '../../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import * as ARRAYHELPER from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { FillInGapData, FillInGapData_Gap, FillInGapData_PoolValue, FillInGapData_Text } from '../../model/fillingap/FillInGapData';
import { translate } from '../../model/ItemDataModel';
import { GetSession } from '../../model/session';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './fillingap.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-fillingap';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;

class PoolValue {
    public readonly id: string;
    constructor(private readonly model: MyModel, readonly value: FillInGapData_PoolValue, readonly index: number, readonly gap: PoolForGap) {
        this.id = value.id;
    }

    public readonly letter = ko.pureComputed(() => {
        return ARRAYHELPER.greekToLatin(this.id.substr(-1));
    });

    public readonly html = ko.pureComputed(() => this.value.text());

    public readonly isAvailable = ko.pureComputed(() => this.value.isAvailable());

    public readonly pressValue = new UIAction(undefined, async () => {
        await this.model.selectGapValue.intent({
            gapId: this.gap.id,
            valueId: this.id
        });
    });
}

class TextOrGap {
    public isText(): this is Text {
        return this instanceof Text;
    }
    public isGap(): this is Gap {
        return this instanceof Gap;
    }
}
class Text extends TextOrGap {
    constructor(private readonly model: MyModel, readonly data: FillInGapData_Text) {
        super();
    }

    public readonly text = ko.pureComputed(() => this.data.text);

}
class Gap extends TextOrGap {
    public readonly gapId: string;
    constructor(private readonly model: MyModel, readonly gap: FillInGapData_Gap) {
        super();
        this.gapId = gap.gapId;
    }
    public readonly isCorrect = ko.pureComputed(() => {
        return this.gap.correctValue === this.gap.selectedValue();
    });
    public readonly isGapEmpty = ko.pureComputed(() => {
        return !this.gap.selectedValue();
    });
    public readonly gapText = ko.pureComputed(() => {
        const val = this.gap.possibilities().find(val => val.id === this.gap.selectedValue());
        if (!val) {
            return '';
        }
        return val.text();
    });

    public readonly gapLetter = ko.pureComputed(() => {
        const selectedValue = this.gap.selectedValue();
        if (!selectedValue) {
            return undefined;
        }
        return ARRAYHELPER.greekToLatin(selectedValue.substr(-1));
    });
    public readonly gapCorrectLetter = ko.pureComputed(() => {
        const correctValue = this.gap.correctValue;
        if (!correctValue) {
            return undefined;
        }
        return ARRAYHELPER.greekToLatin(correctValue.substr(-1));
    });
}

class PoolForGap {
    public readonly id: string;
    constructor(private readonly model: MyModel, readonly itemGap: FillInGapData_Gap, readonly index: number) {
        this.id = itemGap.gapId;
    }

    public readonly gapText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.fillingap.GAP']);
    });
    public readonly possibilities = ko.pureComputed(() => {
        return this.itemGap.possibilities().map((value, ind) => {
            return new PoolValue(this.model, value, ind, this);
        });
    });
}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;


        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof FillInGapData)) {
            throw new Error();
        }
        this.data = data;


    }


    public readonly data: FillInGapData;

    private async gremlin() {
        if (!this.loaded()) {
            return true;
        }
        const gaps = this.gaps().filter(x => !x.itemGap.selectedValue());
        if (gaps.length === 0) {
            return false;
        }
        const gap = gaps[0];

        const golemData = getGolemData(this.itemId);
        let toSelect: PoolValue;
        const gapId = gap.itemGap.gap.gapId;
        if (isStringMap(golemData)) {

            const values = gap.possibilities().map(x => ({
                key: x.id,
                value: translate(x.value.value.value, this.params)
            }));
            const val = golemData[gapId];


            if (typeof val === 'string') {
                const valId = golemMatch(values, val);
                toSelect = gap.possibilities().find(x => x.id === valId);
            }
        }
        if (!toSelect) {
            log(`Golem has no suggestion for ${this.itemId} Gap #${gapId}`);
            toSelect = ARRAYHELPER.getRandomEntry(gap.possibilities());
        }
        await toSelect.pressValue.invoke(undefined, true);
        return true;
    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlin()
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);
    }

    public readonly gaps = ko.pureComputed(() => {
        return this.data.gaps.map((itemGap, index) => {
            return new PoolForGap(this, itemGap, index);
        });
    });

    public readonly groupedGaps = ko.pureComputed(() => {
        const retVal = new Array<{ Group: string, gaps: PoolForGap[] }>();
        let currentGroup: number;
        const gaps = this.gaps();
        if (!gaps) {
            return undefined;
        }
        for (let i = 0; i < gaps.length; i++) {
            if (i === 0 || (i > 1 && i % 3 === 0)) {
                retVal.push({
                    Group: 'group' + currentGroup,
                    gaps: []
                });
                currentGroup = retVal.length - 1;
            }
            retVal[currentGroup].gaps.push(gaps[i]);
        }
        return retVal;
    });

    public readonly selectGapValue = new UIAction<{ gapId: string, valueId: string }>(undefined, async (e, args) => {
        await this.data.assign({
            gapId: args.gapId,
            valueId: args.valueId
        });
    });

    public readonly fragments = ko.pureComputed(() => {
        const retVal: Array<Text | Gap> = this.data.fragments.map(x => {
            if (x instanceof FillInGapData_Text) {
                return new Text(this, x);
            } else if (x instanceof FillInGapData_Gap) {
                return new Gap(this, x);
            } else {
                throw new Error();
            }
        });

        return retVal;
    });


    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

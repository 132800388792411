import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { log } from '../../../debug';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { metadataAsString } from '../../../format';
import * as API from '../../../its-itembank-api.g';
import { IMAGE_TOKEN } from '../../../tokenizer';
import { ServerConnection } from '../../../ui/RestAPI';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g'; //tslint:disable-line

type Q = Awaited<ReturnType<API.Sdk['ui_htmleditor_imgtageditor_data']>>;

export const WIDGET_NAME = 'widgets-htmleditor-imgtageditor';

export interface IParams {
    docRefId: string;
    docType: API.Doctype;
    tag: ko.Computed<string> | ko.Observable<string>;
}
interface D {
    filename: string;
    width: number;
    height: number;
    offsetY: number;
    zoom: boolean;
}
type ATTACHMENT = Q['documents']['get']['attachments'][0];

interface VM {
    _attachments: ATTACHMENT[];
}
class MyFormBuilder extends FormBuilder<D, Partial<D>> {
    constructor(readonly vm: VM) {
        super();
    }
    protected onInit() {
        super.onInit();

        this.addSelectBox(this.formOptions, {
            label: {
                text: i18next.t(['widgets.htmleditor.imgtageditor.IMAGE']),
            },
            editorOptions: {
                dataSource: this.vm._attachments,
                valueExpr: 'name',
                displayExpr: (obj: ATTACHMENT) => {
                    return `${obj.name} ${metadataAsString(obj)}`;
                },
                //value: this.filename
            },
            dataField: 'filename',
        }, val => ({ filename: val }));

        this.addNumberBox(this.formOptions, {
            label: {
                text: i18next.t(['widgets.htmleditor.imgtageditor.WIDTH']),
            },
            editorOptions: {
                min: 0,
            },
            dataField: 'width',
        }, val => ({ width: val }));

        this.addNumberBox(this.formOptions, {
            label: {
                text: i18next.t(['widgets.htmleditor.imgtageditor.HEIGHT']),
            },
            editorOptions: {
                min: 0,
            },
            dataField: 'height',
        }, val => ({ height: val }));

        this.addNumberBox(this.formOptions, {
            label: {
                text: i18next.t(['widgets.htmleditor.imgtageditor.OFFSETY']),
            },
            editorOptions: {
            },
            dataField: 'offsetY',
        }, val => ({ offsetY: val }));

        this.addCheckbox(this.formOptions, {
            label: {
                text: i18n.t(['widgets.htmleditor.imgtageditor.ENABLE_IMAGE_ZOOM']),
            },
            dataField: 'zoom',
        }, val => ({ zoom: val }));

    }
}
export class ViewModel extends DxWidget {

    public readonly form = new MyFormBuilder(this);
    public readonly formData: D = <D>{};

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        //this.form.formOptions.colCount = 5;
        this.form.formOptions.onFieldDataChanged = x => {
            const up = this.form.fields.get(x.dataField);
            if (!up) {
                return;
            }
            const data = up(x.value);
            if (typeof data.filename === 'string') {
                this._filename(data.filename);
            }
            if (typeof data.width === 'number') {
                this._width(data.width);
            }
            if (typeof data.height === 'number') {
                this._height(data.height);
            }
            if (typeof data.offsetY === 'number') {
                this._offsetY(data.offsetY);
            }
            if (typeof data.zoom === 'boolean') {
                this._zoom(data.zoom);
            }
            this.pushBack();
        };
    }

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
    }

    private readonly _filename = ko.observable('');
    private readonly _width = ko.observable<number>();
    private readonly _height = ko.observable<number>();
    private readonly _offsetY = ko.observable<number>();
    private readonly _zoom = ko.observable(false);

    public readonly _attachments: Q['documents']['get']['attachments'] = [];

    public previewHtml = ko.pureComputed(() => {
        const attachment = this._attachments.find(x => x.name === this._filename());
        if (!attachment) {
            return undefined;
        }
        const isVideo = !!attachment.videoMetadata;
        const meta = attachment.imageMetadata ?? attachment.videoMetadata;
        if (!meta) {
            return undefined;
        }
        let width = this._width() || meta.width;
        let height = this._height() || meta.height;
        if (isVideo) {
            return `<video-player source="${attachment.hrefResolved}" width="${width}" height="${height}"></video-player>`;
        }
        return `<img src="${attachment.hrefResolved} width="${width}" height="${height}"></img>`;
    });

    private pushBack() {
        const newTag = IMAGE_TOKEN.compile({
            filename: this._filename(),
            width: this._width(),
            height: this._height(),
            offsetY: this._offsetY(),
            zoom: this._zoom(),
        });
        log(newTag);
        if (newTag) {
            this.params.tag(newTag);
        }
        //this.vm.updateCurrentTag(newTag);
    }
    private update(tag: string) {
        const r = IMAGE_TOKEN.parse(tag);
        if (!r) {
            this._filename(undefined);
            this._width(undefined);
            this._height(undefined);
            this._offsetY(undefined);
            this._zoom(false);
            return;
        }
        this._filename(r.filename);
        this._width(r.width);
        this._height(r.height);
        this._offsetY(r.offsetY);
        this._zoom(!!r.zoom);
    }
    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.ui_htmleditor_imgtageditor_data({
            docReferenceId: this.params.docRefId,
            docType: this.params.docType
        });
        this._attachments.splice(0, this._attachments.length, ...data.documents.get.attachments);
    }

    public readonly loaded = ko.observable(false);

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.initFormOptions();
        this.update(this.params.tag());
        if (!this._filename() && this._attachments.length) {
            this._filename(this._attachments[0].name);
        }
        this.formData.filename = this._filename();
        this.formData.width = this._width();
        this.formData.height = this._height();
        this.formData.offsetY = this._offsetY();
        this.formData.zoom = this._zoom();

        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
interface FILE {
    imageMetadata?: {
        width: number;
        height: number;
    }
    videoMetadata?: {
        width: number;
        height: number;
        duration: number;
    }
}
export function metadataAsString(file: FILE) {
    if (file.imageMetadata) {
        return `${file.imageMetadata.width} x ${file.imageMetadata.height}`;
    }
    if (file.videoMetadata) {
        const duration = Math.round(file.videoMetadata.duration);
        return `${file.videoMetadata.width} x ${file.videoMetadata.height} (${duration} seconds)`;
    }
    return '';
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
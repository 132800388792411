import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { AS, refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
//import * as UPDATE from './update.mutation.graphql.g';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './init.query.graphql.g';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './schooltypeedit.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_audience_init']>>;
type AUDIENCE = Q['audiences']['byId'];
type FormData = AUDIENCE & { userSubjects: string[] };
type SUBJECT = Q['subjects'][0];

export class ViewModel extends DxWidget {

    private readonly _subjects: SUBJECT[] = [];

    private readonly formData: FormData = <any>{};


    public async OnRefresh() {
        await super.OnRefresh();
        const r = await ServerConnection.api.ui_superuser_audience_init({ docRef: this.params.currentRoute.widgetParams.audienceDocRefId });
        this._subjects.splice(0, this._subjects.length, ...r.subjects);
        Object.assign(this.formData, r.audiences.byId);
        this.formData.userSubjects = this.formData.subjects.map(x => x.docReferenceId);
        await refreshDx(this);
    }

    // tslint:disable-next-line:max-func-body-length
    public readonly formOptions = ko.pureComputed(() => {
        const s1: DevExpress.ui.dxForm.SimpleItem = {
            dataField: 'title',
            editorOptions: {
                placeholder: this.titlePlaceholder,
            },
            label: {
                text: i18next.t(['ui.superuser.schooltypeedit.NAME_OF_AUDIENCE'])
            }
        };
        const s2: DevExpress.ui.dxForm.SimpleItem = {
            dataField: 'audienceId',
            label: {
                text: i18next.t(['ui.superuser.schooltypeedit.SHORTCUT'])
            },
            editorOptions: {
                placeholder: this.audiencePlaceholder,
            },
            validationRules: [
                {
                    type: 'required',
                    message: i18next.t(['ui.superuser.schooltypeedit.A_SHORTCUT_IS_REQUIRED'])
                },
                {
                    type: 'pattern',
                    pattern: '^[A-Z,0-9]+$',
                    message: i18next.t(['ui.superuser.schooltypeedit.THE_SHORTCUT_CAN_ONLY_CONSIST_OF_UPPERCASE_LETTERS_AND_DIGITS'])
                }
            ]
        };
        const s3: DevExpress.ui.dxForm.SimpleItem = {
            dataField: 'userSubjects',
            label: {
                text: i18next.t(['ui.superuser.schooltypeedit.SUBJECTS'])
            },
            editorType: 'dxTagBox',
            editorOptions: AS<DevExpress.ui.dxTagBox.Properties>({
                dataSource: this._subjects,
                displayExpr: 'subjectId',
                valueExpr: 'docReferenceId',
                showSelectionControls: true,
                applyValueMode: 'useButtons'
            })
        };
        const s4: DevExpress.ui.dxForm.SimpleItem = {
            dataField: 'isDeleted',
            editorType: 'dxCheckBox',
            editorOptions: {
            },
            label: {
                text: i18next.t(['ui.superuser.schooltypeedit.DEACTIVATE_AUDIENCE'])
            }
        };
        const s5: DevExpress.ui.dxForm.SimpleItem = {
            dataField: '_id',
            editorOptions: {
                readOnly: true,
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
            },
            label: {
                text: i18next.t(['ui.superuser.schooltypeedit.INTERNAL_ID'])
            }
        };

        const g1: DevExpress.ui.dxFormGroupItem = {
            itemType: 'group',
            colCount: 2,
            caption: i18next.t(['ui.superuser.schooltypeedit.AUDIENCE_DATA']),
            items: [s1, s2, s3]

        };
        const g2: DevExpress.ui.dxFormGroupItem = {
            itemType: 'group',
            caption: i18next.t(['ui.superuser.schooltypeedit.SYSTEM']),
            colCount: 2,
            items: [s4, s5]

        };

        const retVal: DevExpress.ui.dxForm.Properties = {
            formData: this.formData,
            colCount: 1,
            items: [g1, g2],
            onFieldDataChanged: x => {
                void this.updateDatabase(x.dataField, x.value);
            },
            onInitialized: x => {
                this.forms.add(x.component);
            },
            onDisposing: x => {
                this.forms.delete(x.component);
            }
        };
        return retVal;
    });

    private async updateDatabase(field: string, value: any) {
        const audienceDocRef = this.params.currentRoute.widgetParams.audienceDocRefId;
        const audienceId = this.formData.audienceId;

        switch (field) {
            case 'title':
                await ServerConnection.api.ui_superuser_audienceedit_update({
                    params: {
                        audienceDocRef,
                        audienceId,
                        title: value
                    }
                });
                return DONE;
            case 'audienceId':
                await ServerConnection.api.ui_superuser_audienceedit_update({
                    params: {
                        audienceDocRef,
                        audienceId: value
                    }
                });
                return DONE;
            case 'userSubjects':
                {
                    const subjects = (value as string[]).map(x => this._subjects.find(y => y.docReferenceId === x).subjectId);
                    await ServerConnection.api.ui_superuser_audienceedit_update({
                        params: {
                            audienceDocRef,
                            audienceId,
                            setSubjects: subjects
                        }
                    });
                    return DONE;
                }
            case 'isDeleted':
                await ServerConnection.api.ui_superuser_audienceedit_update({
                    params: {
                        audienceDocRef,
                        audienceId,
                        isDeleted: value
                    }
                });
                return DONE;
        }
        return DONE;
    }
    public readonly audiencePlaceholder = i18next.t(['ui.superuser.schooltypeedit.ENTER_THE_SHORTCUT_HERE']);
    public readonly titlePlaceholder = i18next.t(['ui.superuser.schooltypeedit.ENTER_THE_NAME_HERE']);


    constructor(readonly params: IParams) {
        super();
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    public readonly loaded = ko.observable(false);
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { connectionLost } from '../../ui/docmanager';
import { ServerConnection } from '../../ui/RestAPI';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'widgets-uiblock';
const WIDGET_PARENT_NAME = 'widgets';

type IParams = undefined;

export class ViewModel extends AbstractWidget {
    public readonly stopOnConnectionLost = ko.observable(false);
    constructor(params: IParams) {
        super();
    }

    public async initialize() {
        await super.initialize();
        this.stopOnConnectionLost(ServerConnection.initData.config.stopOnConnectionLost);
    }

    public readonly title = ko.pureComputed(() => {
        return i18next.t(['widgets.uiblock.CONNECTION_LOST']);
    });

    public readonly text = ko.pureComputed(() => {
        return i18next.t(['widgets.uiblock.THE_CONNECTION_TO_THE_SERVER_HAS_BEEN_LOST_BR_PLEASE_TRY_TO_RELOAD_BY_REFRESHING_THE_BROWSER_F5_IF_THE_PROBLEM_PERSISTS_PLEASE_CONTACT_YOUR_MAIN_CONTACT']);
    });

    public readonly visible = ko.pureComputed(() => {
        return connectionLost() && this.stopOnConnectionLost();
    });
    public readonly popupOptions = ko.pureComputed(() => {
        const connectionLost = this.visible();
        const retVal: DevExpress.ui.dxPopup.Properties = {
            visible: connectionLost,
            showCloseButton: false,
            closeOnOutsideClick: false,
            width: 500,
            height: 150,
            onInitialized: this.onInit
        };
        return retVal;
    });

    public onInit(e: any) {
        e.component.registerKeyHandler('escape', (arg: any) => {
            arg.stopPropagation();
        });
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

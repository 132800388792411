import $ from 'jquery';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import * as HELPER from '../../helper';
import { htmlString } from './videoplayer.html.g';

const WIDGET_NAME = 'widgets-videoplayer';
const WIDGET_PARENT_NAME = 'widgets';
const subscriptions = new Array<HELPER.IDisposeable>();

const jPlayerVideo: ko.BindingHandler = {
    preprocess: (value, name, addBindingCallback) => {
        addBindingCallback('uniqueName', true);
        return value;
    },
    init: (element, valueAccessor) => {
        const $el = $('.jp-jplayer', element);
        element.id = element.name;
        if (ko.isObservable(valueAccessor())) {
            subscriptions.push(valueAccessor().subscribe((newVal: string) => {
                $el.jPlayer('destroy');
                $el.jPlayer({
                    ready: () => {
                        $el.jPlayer('setMedia', {
                            title: '',
                            m4v: newVal,
                            ogv: newVal,
                            webmv: newVal
                        });
                    },
                    swfPath: '../../dist/jplayer',
                    supplied: 'webmv, ogv, m4v',
                    size: {
                        width: '640px',
                        height: '360px',
                        cssClass: 'jp-video-360p'
                    },
                    useStateClassSkin: true,
                    autoBlur: false,
                    smoothPlayBar: true,
                    keyEnabled: false,
                    remainingDuration: true,
                    toggleDuration: true
                });

                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    // This will be called when the element is removed by Knockout or
                    // if some other part of your code calls ko.removeNode(element)
                    $el.jPlayer('destroy');
                });
            }));
        }
        const params = ko.unwrap(valueAccessor());
        if (typeof params === 'string') {
            $el.jPlayer({
                ready: () => {
                    $el.jPlayer('setMedia', {
                        title: '',
                        m4v: params,
                        ogv: params,
                        webmv: params
                    });
                },
                swfPath: '../../dist/jplayer',
                supplied: 'webmv, ogv, m4v',
                size: {
                    width: '640px',
                    height: '360px',
                    cssClass: 'jp-video-360p'
                },
                useStateClassSkin: true,
                autoBlur: false,
                smoothPlayBar: true,
                keyEnabled: true,
                remainingDuration: true,
                toggleDuration: true
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                // This will be called when the element is removed by Knockout or
                // if some other part of your code calls ko.removeNode(element)
                $el.jPlayer('destroy');
            });
        }
    }
};

HELPER.registerBindingHandler('jPlayerVideo', jPlayerVideo);

export interface IParams {
    url: string;
}

export class MyModel extends AbstractWidget {
    public readonly url = ko.observable('');
    constructor(readonly params: IParams) {
        super();
        this.url(params && params.url);
    }
    public async initialize() {
        await super.initialize();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import { AS } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { toastService } from '../../../toastService';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-examorderedit';

export interface IParamsInner {
    examOrderDocRefId: string;
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class ExamOrderEditRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;

    public readonly title = ko.pureComputed(() => {
        return this.options.title;
    });

    public readonly routeActions = ko.pureComputed(() => {
        const actions: ROUTES.IRouteAction[] = [];

        actions.push({
            icon: 'help',
            caption: i18next.t(['ui.superuser.examorderedit.HELP']),
            action: this.showHelp
        });
        return actions;
    });


    constructor(readonly widgetParams: IParamsInner, readonly options: { title: string, status: API.ExaminationOrderStatus }) {
    }

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.examorderedit.THE_AMOUNT_OF_ITEMS_IS_INSERTED_BY_THE_SYSTEM_WHEN_THE_SUBJECT_COORDINATOR_RELEASES_THE_EXAM_ORDER_WHEN_YOU_HAVE_CHOSEN_AN_AUDIENCE_YOU_CAN_ADD_SUBJECTS_TO_THE_EXAM_ORDER_AND_DIVIDE_THE_TOTAL_AMOUNT_OF_POINTS_TO_THE_SUBJECTS_YOU_CAN_CREATE_AN_EXAM_OUT_OF_ANY_EXAM_ORDER_WITH_THE_STATUS_READY_IN_THE_CARD_EXAM_THERE_IS_CREATED_A_LINK_FOR_EACH_DEMO_EXAM_THIS_EXAM_CAN_BE_ADDED_TO_AN_EXAM_WAVE']);

        toastService.info(msg);
    });
}

export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/superuser/examorder/:id',
    fromParams: (p, map) => {
        map.set('id', p.examOrderDocRefId);
    },
    toParams: map => {
        return AS<IParamsInner>({
            examOrderDocRefId: map.get('id')
        });
    },
    create: async params => {
        const data = await ServerConnection.api.ui_superuser_examorderedit_route({
            docRef: params.examOrderDocRefId
        });

        const retVal = new ExamOrderEditRoute(params,
            {
                title: data.examOrder.byId.examinationOrderId,
                status: data.examOrder.byId.status,
            }
        );
        return retVal;
    },
});
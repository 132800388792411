import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { DEUS } from '../../../magic';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as WIDGET_EXAMINATIONOVERVIEW from '../examinationoverview/route';
import * as WIDGET_EXAMORDEROVERVIEW from '../examorderoverview/route';
import * as WIDGET_GOLEMSCRIPT from '../golemscriptoverview/route';
import * as WIDGET_ITEMTYPEOVERVIEW from '../itemtypeoverview/route';
import * as WIDGET_LAUNCHTEST from '../launchtest/route';
import * as WIDGET_PROFILEOVERVIEW from '../profileoverview/route';
import * as WIDGET_AUDIENCEOVERVIEW from '../schooltypeoverview/route';
import * as WIDGET_SCORMTOKENOVERVIEW from '../scormtokenoverview/route';
import * as WIDGET_SESSIONS from '../sessions/route';
import * as WIDGET_SETTINGS from '../settings/route';
import * as WIDGET_STATISTICEXPORT from '../statisticexport/route';
import * as WIDGET_SUBJECTOVERVIEW from '../subjectoverview/route';
import * as WIDGET_ALIASOVERVIEW from '../testaliasoverview/route';
import * as WIDGET_USEROVERVIEW from '../useroverview/route';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

interface ICardAction {
    readonly caption: ko.Subscribable<string>;
    readonly action: UIAction<undefined>;
}

interface ICard {
    readonly cardTitle: ko.Subscribable<string>;
    readonly cardText: ko.Subscribable<string>;
    readonly actions: ko.Subscribable<ICardAction[]>;
    readonly defaultAction?: ko.Subscribable<ICardAction>;
}

export interface ICardOptions {
    title: string;
    route: string;
}

class Card implements ICard {
    constructor(readonly viewModel: ViewModel, readonly options: ICardOptions) {

    }
    public readonly cardTitle = ko.pureComputed(() => {
        return this.options.title;
    });
    public readonly cardText = ko.pureComputed(() => '');
    public readonly defaultAction = ko.pureComputed(() => {
        return <ICardAction>{
            caption: ko.pureComputed(() => {
                return i18next.t(['ui.superuser.home.OPEN']);
            }),
            action: new UIAction(undefined, async () => {
                await ROUTES.routeManager.navigateToHREF(this.options.route);
            })
        };
    });
    public readonly actions = ko.pureComputed(() => {
        const retVal: ICardAction[] = [];
        if (this.defaultAction && this.defaultAction()) {
            retVal.push(this.defaultAction());
        }
        return retVal;
    });
}

export class ViewModel extends AbstractWidget {
    public readonly topRoutes = ko.observable<Card[]>();

    constructor(readonly params: IParams) {
        super();
    }

    public async initialize() {
        await super.initialize();
        const data = await ServerConnection.api.ui_superuser_home_data({
        });
        const isDeus = data.whoAmI.loginId === DEUS;
        const topRoutes = [];
        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.USER']),
                route: WIDGET_USEROVERVIEW.FACTORY.href({}),
            }));
        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.SUBJECT']),
                route: WIDGET_SUBJECTOVERVIEW.FACTORY.href({})
            }));
        topRoutes.push(

            new Card(this, {
                title: i18next.t(['ui.superuser.home.AUDIENCE']),
                route: WIDGET_AUDIENCEOVERVIEW.FACTORY.href({})
            }));

        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.EXAM_ORDER']),
                route: WIDGET_EXAMORDEROVERVIEW.FACTORY.href({}),
            }));
        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.EXAM']),
                route: WIDGET_EXAMINATIONOVERVIEW.FACTORY.href({}),
            }));
        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.LAUNCH_TEST']),
                route: WIDGET_LAUNCHTEST.FACTORY.href({}),
            }));
        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.TEST_ALIASES']),
                route: WIDGET_ALIASOVERVIEW.FACTORY.href({}),
            }));

        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.TEST_SESSIONS']),
                route: WIDGET_SESSIONS.FACTORY.href({}),
            }));


        topRoutes.push(

            new Card(this, {
                title: i18next.t(['ui.superuser.home.ITEM_TYPE']),
                route: WIDGET_ITEMTYPEOVERVIEW.FACTORY.href({}),
            }));
        topRoutes.push(

            new Card(this, {
                title: i18next.t(['ui.superuser.home.TEST_DEFINITION_PROFILES']),
                route: WIDGET_PROFILEOVERVIEW.FACTORY.href({}),
            }));
        topRoutes.push(

            new Card(this, {
                title: i18next.t(['ui.superuser.home.STATISTIC_EXPORT']),
                route: WIDGET_STATISTICEXPORT.FACTORY.href({}),
            }));

        if (isDeus) {
            topRoutes.push(
                new Card(this, {
                    title: i18next.t(['ui.superuser.home.SETTINGS']),
                    route: WIDGET_SETTINGS.FACTORY.href({}),
                }));
        }
        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.GOLEM_SCRIPTS']),
                route: WIDGET_GOLEMSCRIPT.FACTORY.href({}),
            }));
        topRoutes.push(
            new Card(this, {
                title: i18next.t(['ui.superuser.home.SCORMTOKENS']),
                route: WIDGET_SCORMTOKENOVERVIEW.FACTORY.href({}),
            })
        )
        this.topRoutes(topRoutes);
    }
}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { error } from '../../../debug';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { ServerConnection } from '../../../ui/RestAPI';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'SurveyTextEntry_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type GAP = DATA['steGaps'][0];

export class SurveyTextEntryData_Gap {
    public readonly gap: GAP;
    constructor(readonly model: SurveyTextEntryData, readonly gapId: string) {
        this.gap = this.model._data.steGaps.find(val => val.id === gapId);
        this.validationMessage = translate(this.gap.validationMessage, {});
        const pattern = this.gap.pattern && ('^' + this.gap.pattern + '$');
        try {
            if (pattern) {
                this.pattern = new RegExp(this.gap.pattern);
            }
        } catch (e) {
            error(`RegEx Pattern for gap ${gapId} is not valid: ${this.gap.pattern}`);
        }
        let givenAnswer = ko.observable<string>();
        if (this.pattern) {
            givenAnswer = givenAnswer.extend(<any>{
                pattern: {
                    message: this.validationMessage,
                    params: pattern,
                }
            });
        }
        givenAnswer(this.gap.givenAnswer);
        this.givenAnswer = givenAnswer;

    }
    public readonly givenAnswer: ko.Observable<string>;

    public readonly validationMessage: string;
    public readonly pattern: RegExp;

    public readonly IsInteractionComplete = ko.pureComputed(() => {
        const given = this.givenAnswer();
        if (!given) {
            return false;
        }
        if (this.pattern) {
            if (!this.pattern.test(given)) {
                return false;
            }
        }
        return true;
    });
}

export class SurveyTextEntryData_Text {
    constructor(readonly model: SurveyTextEntryData, readonly fragmentText: string) {
        this.text = this.fragmentText;
        this.html = RICHTEXTHTML.process({
            inline: true,
            html: this.fragmentText,
            attachments: model.meta.attachments,
        });
    }
    public readonly text: string;
    public readonly html: string;
}


export class SurveyTextEntryData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly _data: DATA) {
        super();
        this.headerText = translate(_data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(_data.question, {}),
            attachments: this.meta.attachments,
        });

        const templateText = translate(_data.text, {});
        const regEx = /\$\(gap:([^)]+)\)/g;
        const fragments = templateText.split(regEx);
        this.fragments = fragments.map((text, i) => {
            if (i % 2 === 0) {
                return new SurveyTextEntryData_Text(this, fragments[i]);
            } else {
                return new SurveyTextEntryData_Gap(this, fragments[i]);
            }
        });
        this.gaps = <SurveyTextEntryData_Gap[]>this.fragments.filter(x => x instanceof SurveyTextEntryData_Gap);

        const imgAtt = meta.getAttachment(_data?.steImage?.id);
        this.imgUrl = ServerConnection.getDataUrl(imgAtt?.hrefResolved);
        this.imgAlt = imgAtt?.altText?.value ?? '';
        this.hasImage = !!this.imgUrl;
        this.fromJS(_data);
    }
    public fromJS(data: DATA) {
    }
    public readonly hasImage: boolean;
    public readonly imgUrl: string;
    public readonly imgAlt: string;
    public readonly headerText: string;
    public readonly questionHTML: string;
    public readonly fragments: Array<SurveyTextEntryData_Gap | SurveyTextEntryData_Text>;
    public readonly gaps: SurveyTextEntryData_Gap[];

    public setAnwer({ gapId, value }: { gapId: string, value: string }) {
        const gap = this.gaps.find(x => x.gapId === gapId);
        if (!gap) {
            throw new Error();
        }
        gap.givenAnswer(value);
    }

    public IsInteractionStarted() {
        return this.gaps.some(x => !!x.givenAnswer());
    }
    public IsInteractionComplete() {
        return this.gaps.every(x => x.IsInteractionComplete());
    }

    public async reset() {
        for (const gap of this.gaps) {
            gap.givenAnswer('');
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SurveyTextEntry: {
                gaps: this.gaps.map(x => ({
                    gapId: x.gapId,
                    text: x.givenAnswer(),
                })),
            }
        };
        return retVal;
    }

}
// tslint:disable:max-func-body-length
import * as ko from 'knockout';
import { ImageTokenParams, IMAGE_TOKEN, tokenize } from '../tokenizer';

export type IMAGERESOLVER = (data: ImageTokenParams) => string;

export function processImageTags(str: string, cb: IMAGERESOLVER) {
    const parts = tokenize(str, [IMAGE_TOKEN]);
    let retVal = [];
    for (const part of parts) {
        if (part.type === 'image') {
            const imageToken = IMAGE_TOKEN.parse(part.value);
            retVal.push(cb(imageToken));
        } else {
            retVal.push(part.value);
        }
    }
    return retVal.join('');

}
export type NameToHREF = (imgSrc: string) => string;
export type RESOLVER = string | ko.Observable<string> | NameToHREF;

export function callImageFunction(func: RESOLVER, imgSrc: string): string {
    if (ko.isObservable(func)) {
        return func() + imgSrc;
    }
    if (typeof func === 'function') {
        return func(imgSrc);
    }
    if (typeof func === 'string') {
        return func + imgSrc;
    }
    return imgSrc;
}

// tslint:disable:object-literal-key-quotes
import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-subjectcoordinator-itemreviewselection';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
}

export class ItemReviewSelectionRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.subjectcoordinator.itemreviewselection.SELECTION_FOR_ITEM_REVIEW']);
    });

    constructor(readonly widgetParams: IParamsInner) {
    }

    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.subjectcoordinator.itemreviewselection.HELP']),
                action: this.showHelp
            }];
    });
    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.subjectcoordinator.itemreviewselection.YOU_CAN_REVIEW_ALL_ITEMS_THAT_THE_AUTHOR_HAS_FINISHED_THE_FILTER_OPTIONS_HELP_YOU_TO_ADJUST_THE_LIST_TO_YOUR_NEEDS']);

        toastService.info(msg);
    });
}


export const FACTORY = ROUTES.registerRoute<{ subject: string }>({
    route: '/subjectcoordinator/subject/:subject/item',
    toParams: map => ({
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('subject', p.subject);
    },
    create: async p => {
        const route = new ItemReviewSelectionRoute(
            {
                subjectDocRefId: p.subject,
            });
        return route;
    },
});
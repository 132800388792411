export const id = 'lavak-fillinpool';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';


itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '1e8d88d9-0946-424a-b691-5955e188e852',
    widgetNames: {
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ]),
        fallback: 'itemdefinition-' + id
    },
});

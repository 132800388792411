export const id = 'kosovo-orderingsentences';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';


itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '2557e50e-c905-4477-91a5-96a8e03e7150',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'LavakLineTextEntry_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type LINE = DATA['lteLines'][0];

export class TextEntryPool_Line {
    constructor(private readonly model: TextEntryPoolData, public readonly rawData: LINE) {
        this.id = rawData.id;
        this.lineIndex = rawData.lineIndex;
        this.fromJS(rawData);
    }
    public readonly id: string;
    public readonly lineIndex: number;
    public readonly isCorrect = ko.observable<boolean>();
    public readonly value = ko.observable<string>();
    public readonly evaluationRemark = ko.observable<string>();

    public fromJS(data: LINE) {
        this.value(data.value);
        this.evaluationRemark(data.evaluationRemark);
        this.isCorrect(data.isCorrect);
    }
}

export class TextEntryPoolData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.lines = data.lteLines.map(a => new TextEntryPool_Line(this, a));
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'LavakLineTextEntry_QueryItemSessionData') {
            throw new Error();
        }
        this.evaluationRemark(data.evaluationRemark);
        for (const line of data.lteLines) {
            const l = this.lines.find(x => x.id === line.id);
            l.fromJS(line);
        }
    }
    public readonly questionHTML: string;
    public readonly lines: TextEntryPool_Line[];
    public readonly headerText: string;
    public readonly evaluationRemark = ko.observable('');
    public setAnswer(lineIndex: number, value: string) {
        const l = this.lines.find(x => x.lineIndex === lineIndex);
        l.value(value);
    }


    public IsInteractionStarted() {
        return this.lines.some(x => !!x.value());
    }
    public IsInteractionComplete() {
        return this.lines.some(x => !!x.value());
    }
    public async reset() {
        for (const line of this.lines) {
            line.value('');
        }
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            TextEntryPool: {
                lines: this.lines.map(x => ({
                    lineId: x.id,
                    text: x.value(),
                })),
            },
        };
        return retVal;
    }

}

import CodeMirror from 'codemirror';
import type DevExpress from 'devextreme/bundles/dx.all';
import $ from 'jquery';
import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { log } from '../../debug';
import * as API from '../../its-itembank-api.g';
import { IMAGE_TOKEN, insertToken, tokenize, TokenType } from '../../tokenizer';
import { ServerConnection } from '../../ui/RestAPI';
import { UIAction } from '../../ui/uiAction';
import * as i18next from './../../i18n/i18n';
import * as IMGTAGEDITOR from './imgtageditor/widget';
import { htmlString } from './widget.html.g'; //tslint:disable-line

const WIDGET_NAME = 'widgets-htmleditor';

export interface IToolbarItem {
    text: string;
    icon: string;
    disabled: ko.MaybeSubscribable<boolean>
    onClick: () => void;
}
export interface IParams {
    data: ko.Observable<string> | ko.PureComputed<string>;
    docType: API.Doctype,
    docReferenceId: string;
    placeholder?: string;
    tokenTypes?: TokenType[];
    toolbarItems?: IToolbarItem[];
    readOnly: boolean;
    onInitialized?: (editor: ViewModel) => Promise<void>
}

export class ViewModel extends AbstractWidget {

    public readonly insertImageLabel = ko.pureComputed(() => i18next.t(['widgets.htmleditor.ADD_IMAGE']));

    public readonly toolbarItems: IToolbarItem[] = [];

    public readonly currentText = ko.pureComputed({
        read: () => {
            return this.params.data();
        },
        write: val => {
            this.params.data(val);
        }
    });

    private readonly tokens: TokenType[] = [];

    private initEditor(element: HTMLElement) {
        const textArea = $(element).find('.editor');
        if (!textArea.length) {
            alert('Huh?');
            return;
        }
        const text = this.currentText();
        const options: CodeMirror.EditorConfiguration = {
            mode: 'text/html',
            value: text,
            scrollbarStyle: 'native',
            //scrollbarStyle: 'null',
            viewportMargin: Infinity,
            readOnly: this.params.readOnly,
            placeholder: this.params.placeholder || ''
        };

        this.editor = CodeMirror(textArea.get(0), options);
        this.editor.setValue(text);
        this.editor.on('change', cm => {
            const val = cm.getValue();
            this.currentText(val);
        });
        this.editor.on('cursorActivity', editor => {
            const doc = editor.getDoc();
            const pos = doc.getCursor();
            const line = doc.getLine(pos.line);

            const t = tokenize(line, this.tokens);
            const entry = t.find(x => pos.ch <= x.end);

            this.currentTag(entry && entry.value);
        });
    }
    public readonly topBoxOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxBox.Properties = {
            direction: 'row',
            width: '100%',
            onInitialized: (e) => {
                return;
            },
            onItemRendered: e => {
                if (e.itemIndex == 0) {
                    this.initEditor(<HTMLElement><any>e.itemElement);
                }
            }
        };
        return retVal;
    });
    public readonly insertImageAction = new UIAction(undefined, async () => {
        const r = await ServerConnection.api.ui_htmleditor_newimagestub({
            docType: this.params.docType,
            docReferenceId: this.params.docReferenceId
        });
        const imgName = r.document.newImageStub.name;
        const token = IMAGE_TOKEN.compile({
            filename: imgName
        });
        await this.insertToken(token);
    });

    public setText(newText: string) {
        this.editor.setValue(newText);
        this.currentText(newText);
    }
    public async insertToken(token: string) {
        if (!this.editor) {
            return;
        }
        const doc = this.editor.getDoc();
        const pos = doc.getCursor();
        const line = doc.getLine(pos.line);
        const r = insertToken(line, this.tokens, token, pos.ch);
        doc.setCursor({ line: pos.line, ch: r.startPos });
        doc.replaceSelection(token);
        this.editor.focus();
        const val = this.editor.getValue();
        log(`editor: update currentText to ${val}`);
        this.currentText(val);
    }

    private editor: CodeMirror.Editor;

    public readonly currentTag = ko.observable('');

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();

        this.disposables.addDiposable(this.currentText.subscribe(val => {
            log(val);
            log(this.currentText());
        }));

        this.toolbarItems.push({
            icon: 'far fa-image',
            text: this.insertImageLabel(),
            onClick: this.insertImageAction.click,
            disabled: this.params.readOnly,
        });
        this.toolbarItems.push({
            icon: 'far fa-edit',
            text: this.editTagLabel(),
            onClick: this.editTag.click,
            disabled: this.isEditTagDisabled
        });
        if (this.params.toolbarItems) {
            this.toolbarItems.push(... this.params.toolbarItems);
        }
    }

    public readonly isEditTagDisabled = ko.pureComputed<boolean>(() => {
        if (this.params.readOnly) {
            return true;
        }
        const tag = this.currentTag();
        if (!tag) {
            return true;
        }
        const isImageTag = IMAGE_TOKEN.parse(tag);
        if (isImageTag) {
            return false;
        }
        return true;
    });
    public updateCurrentTag(tag: string) {
        const doc = this.editor.getDoc();
        const pos = doc.getCursor();
        const line = doc.getLine(pos.line);

        const t = tokenize(line, this.tokens);
        const entry = t.find(x => pos.ch <= x.end);
        doc.replaceRange(
            tag,
            {
                line: pos.line,
                ch: entry.start
            },
            {
                line: pos.line,
                ch: entry.end
            });
    }
    public readonly loaded = ko.observable(false);

    public async initialize() {
        await super.initialize();

        this.tokens.push(IMAGE_TOKEN);
        if (this.params.tokenTypes) {
            for (const t of this.params.tokenTypes) {
                if (!this.tokens.includes(t)) {
                    this.tokens.push(t);
                }
            }
        }
        if (this.params.onInitialized) {
            await this.params.onInitialized(this);
        }
        this.loaded(true);
    }

    public readonly popupTag = ko.observable<string>();
    public readonly popupEditorParams = ko.pureComputed(() => {
        if (!this.popupVisible()) {
            return undefined;
        }
        const params: IMGTAGEDITOR.IParams = {
            docType: this.params.docType,
            docRefId: this.params.docReferenceId,
            tag: this.popupTag
        };
        return {
            name: IMGTAGEDITOR.WIDGET_NAME,
            params
        };
    });
    public readonly popupVisible = ko.observable(false);
    private popup: DevExpress.ui.dxPopup;

    public readonly popupOptions = ko.pureComputed<DevExpress.ui.dxPopup.Properties>(() => {
        const retVal: DevExpress.ui.dxPopup.Properties = {
            visible: <any>this.popupVisible,
            title: i18next.t(['widgets.htmleditor.EDIT_IMAGE']),
            onHiding: () => {
                this.updateCurrentTag(this.popupTag());
            },
            onInitialized: e => {
                this.popup = e.component;
            },
            onDisposing: e => {
                this.popup = undefined;
            },
            toolbarItems: [],
        };

        retVal.toolbarItems.push({
            widget: 'dxButton',
            location: 'after',
            toolbar: 'bottom',
            options: {
                text: 'Close',
                onClick: () => {
                    this.popup.hide();
                }
            }
        });
        return retVal;
    });

    public readonly editTag = new UIAction(undefined, async () => {
        this.popupTag(this.currentTag());
        this.popupVisible(true);
    });
    public readonly editTagLabel = ko.pureComputed(() => {
        return i18next.t(['widgets.htmleditor.EDIT']);
    });

    public dispose() {
        if (this.editor) {
            $(this.editor.getWrapperElement()).remove();
        }
        super.dispose();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});


interface IHTMLEditorFormOptions {
    label?: {
        text?: string,
        visible?: boolean,
        showColon?: boolean,
        location?: 'left' | 'right' | 'top',
        alignment?: 'center' | 'left' | 'right'
    };
    dataField: string;
    docType: API.Doctype;
    docReferenceId: string;
    placeholder?: string;
    readOnly: boolean;
    tokenTypes?: TokenType[];
    toolbarItems?: IToolbarItem[];
    onInitialized?: (editor: ViewModel) => Promise<void>
}
export function FormItemHtmlEditor(options: IHTMLEditorFormOptions) {
    const retVal: DevExpress.ui.dxForm.SimpleItem = {
        dataField: options.dataField,
        label: options.label,
        template: (data, itemElement) => {
            const initialValue: string = ko.unwrap(data.component.option('formData')[data.dataField]) || '';
            const _data = ko.observable(initialValue);
            const obs = ko.pureComputed({
                read: () => _data(),
                write: v => {
                    _data(v);
                    data.component.updateData(data.dataField, v);
                }
            });
            const params: IParams = {
                data: obs,
                docType: options.docType,
                docReferenceId: options.docReferenceId,
                placeholder: options.placeholder,
                tokenTypes: options.tokenTypes,
                toolbarItems: options.toolbarItems,
                readOnly: options.readOnly || false,
                onInitialized: options.onInitialized,
            };
            const x = $(`<div data-bind="component:{name:'${WIDGET_NAME}',params:params}"></div>`);
            ko.applyBindings({ params }, x.get(0));
            x.appendTo(itemElement);
        }
    };
    return retVal;
}

import Popup from "devextreme/ui/popup";
import { log } from '../debug';
import $ from 'jquery';
import { ElementWrapper } from 'devextreme/core/element';

interface ISplashOptions {
    seconds: number;
    width?: number;
    height?: number;
}

interface ISplashHtmlOptions extends ISplashOptions {
    html: string;
}

export function splashHtml(options: ISplashHtmlOptions) {
    const url = `html://dummy/${encodeURIComponent(options.html)}`;
    if (window.itsr3) {
        window.itsr3.showSplash(url, options.seconds, options.width, options.height);
        return true;
    } else {
        log('show dxPopup');
        const element = document.createElement('div');
        document.body.appendChild(element);
        const popup = new Popup(element, {
            showCloseButton: false,
            showTitle: false,
            contentTemplate: function (contentElement: Element) {
                $(contentElement).html(options.html);
                $(contentElement).on('click', () => {
                    popup.dispose();
                });
            },
            width: "520px",
            height: "340px"
        });
        popup.show();
        if (options.seconds > 0) {
            setTimeout(() => popup.dispose(), options.seconds * 1000)
        }
        return true;
    }
    return false;
}
import * as ko from 'knockout';
import * as i18n from '../../../i18n/i18n';
import * as ROUTES from '../../routes';

export const WIDGET_NAME = 'ui-superuser-home';

export const topTitle = ko.pureComputed(() => i18n.t(['ui.superuser.SUPER_USER']));
export class HomeRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly languageSwitcher = true;
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18n.t(['ui.superuser.home.HOME']);
    });
    public readonly topTitle = topTitle;
    constructor(readonly widgetParams: IParamsInner) {
    }
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export type IParamsInner = undefined;

export const FACTORY = ROUTES.registerRoute({
    route: '/superuser',
    toParams: () => ({}),
    fromParams: () => { },
    create: async p => {
        return new HomeRoute(undefined);
    },
});

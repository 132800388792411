export const id = 'surveycombo';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '5c80142c-bd13-4601-9412-54668b3cf108',
    widgetNames: {
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`],
            ['RESULT', `itemdefinition-${id}-result`],
            ['REVIEW', `itemdefinition-${id}-result`],
            ['PRINT', `itemdefinition-${id}-result`],
            ['GRADING', `itemdefinition-${id}-result`],
            ['INTERACTIVE', `itemdefinition-${id}-interactive`]

        ]),
    },
    grading: 'SESSION',
});

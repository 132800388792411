import * as Modal from '../../../modal';
import { toastService } from '../../../toastService';
import { legacyPushPull } from '../../docmanager';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
//import * as DELETEITEM from './deleteItem.mutation.graphql.g';

export async function deleteItem(itemId: string) {

    const message = i18next.t(['ui.author.itemedit.operations.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM']);
    if (!await Modal.confirmYesNo(message)) {
        return false;
    }
    const msg = await legacyPushPull(() => ServerConnection.api.ui_author_itemedit_deleteitem({
        itemId: itemId
    }));
    if (msg.item.deleteItem.ok) {
        return true;
    }
    toastService.error(msg.item.deleteItem.message.value);
    return false;
}


import * as ko from 'knockout';
import { toastService } from '../../../toastService';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-schooltypeoverview';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class AudienceOverviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.schooltypeoverview.AUDIENCE']);
    });

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.superuser.schooltypeoverview.THE_SUBJECTS_ASSIGNED_TO_AN_AUDIENCE_ARE_LISTED_WITH_THEIR_SHORTCUTS_IF_YOU_WANT_TO_SEE_THE_FULL_NAME_OF_THE_SUBJECT_GO_TO_SUBJECT']);

        toastService.info(msg);
    });
    public readonly routeActions = ko.pureComputed(() => {
        return [
            {
                icon: 'help',
                caption: i18next.t(['ui.superuser.schooltypeoverview.HELP']),
                action: this.showHelp
            }
        ];
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/audience',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return new AudienceOverviewRoute(undefined);
    },
});

import { defaultKeymap, standardKeymap } from "@codemirror/commands";
import { EditorSelection, EditorState } from '@codemirror/state';
import { drawSelection, EditorView, highlightActiveLine, keymap, lineNumbers } from "@codemirror/view";
import $ from 'jquery';
import * as ko from 'knockout';
import { log, debug } from '../../../debug';
import { getGolemData } from '../../../Gremlin';
import { DONE } from '../../../helper';
import * as ISO8601 from '../../../iso8601';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { ParticipationContext } from '../../../ui/service/participation/ParticipationContext';
import { ParticipationService } from '../../../ui/service/participation/service';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { GetSession } from '../../model/session';
import { TypingTest2023Data } from '../../model/typingtest2023/TypingTestData';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';


export type IParams = IItemDefinitionWidgetParams;

const WIDGET_NAME = 'itemdefinition-typingtest2023-interactive';

function clean(text: string) {
    return text.replace(/\s+/g, ' ');
}

export class ViewModel extends AbstractItemDefinition {
    public itemId: string;
    private templateView: EditorView = undefined;
    private inputEditor: EditorView = undefined;
    private readonly ctx: ParticipationContext | undefined = undefined;
    public readonly data: TypingTest2023Data;
    public readonly timePassed = ko.pureComputed(() => {
        const tpSeconds = this.ctx?.tickTock.timePassedSeconds() || 0;
        const res = ISO8601.centisecsToDuration(tpSeconds * 100);
        return res.text;
    });

    constructor(readonly params: IParams, readonly rootElem: HTMLElement) {
        super();

        this.itemId = params.itemId;

        this.ctx = ParticipationService.GetSession(this.params.sessionId);
        const item = GetSession(this.params.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof TypingTest2023Data)) {
            throw new Error();
        }
        this.data = data;

        const elem = <HTMLElement>this.rootElem;
        const templateElem = $(elem).find(`[data-marker='template']`).get(0);
        const inputEditor = $(elem).find(`[data-marker='input']`).get(0);

        this.templateView = new EditorView({
            extensions: [
                lineNumbers(),
                drawSelection(),
                highlightActiveLine(),
                EditorState.readOnly.of(true),
                keymap.of([]),
                EditorView.domEventHandlers({
                    paste: (ev, view) => {
                        log(`Prevented PASTE`);
                        ev.preventDefault();
                        return true;
                    },
                    copy: (ev, view) => {
                        log(`Prevented COPY`);
                        ev.preventDefault();
                        return true;
                    },
                    cut: (ev, view) => {
                        log(`Prevented CUT`);
                        ev.preventDefault();
                        return true;
                    }
                }),
            ],
            parent: templateElem
        });
        /*
                this.inputEditor = CodeMirror(inputEditor, {
                    ...options,
                    styleActiveLine: true,
                    readOnly: false,
                    placeholder: 'Geben Sie hier den Text ein.' || '',
                    extraKeys: {
                        'Ctrl-V': () => { },
                        'Ctrl-C': () => { },
                        'Ctrl-X': () => { },
                        // 'Enter': () => { },
                    },
                });
                */

        this.inputEditor = new EditorView({
            extensions: [
                highlightActiveLine(),
                EditorView.inputHandler.of((view, from, to, text) => {
                    //log(`InputHandler from:${from} to:${to} '${text}'`);
                    const line = view.state.doc.lineAt(from);
                    const ch = from - line.from;
                    //log(`InputHandler: ${line.number} ${line.length} ch:${ch}`);
                    if (line.length >= 80) {
                        log(`InputHandler: prevent further input - line length reached`);
                        return true;
                    }
                    if (ch > 0) {
                        const prevCh = line.text.at(ch - 1);
                        //log(`InputHandler: prev '${prevCh}'`);
                        if (prevCh === ' ' && text === ' ') {
                            log(`InputHandler: prevented duplicate spaces`);
                            return true;
                        }
                    }
                    return false;
                }),
                EditorView.updateListener.of(up => {
                    const offset = up.state.selection.main.head;
                    //log(`Cursor head: ${offset}`);
                    const line = up.state.doc.lineAt(offset);
                    const pos = offset - line.from;
                    //log(`Line: ${line.number} / Ch ${pos}`);
                    const tgtLine = this.templateView.state.doc.line(line.number);

                    //log(`TgtLine: ${tgtLine.number} len:${tgtLine.length} from:${tgtLine.from} to:${tgtLine.to}`);
                    const tgtOffset = tgtLine.from + pos;
                    if (pos >= tgtLine.length) {
                        const selection = EditorSelection.cursor(tgtLine.from);
                        debug(`to much text`);
                        console.dir(selection);
                        this.templateView.dispatch({ selection, scrollIntoView: true });

                    } else {
                        const selection = EditorSelection.range(tgtOffset, tgtOffset + 1);
                        console.dir(selection);
                        this.templateView.dispatch({
                            selection, scrollIntoView: true, effects: [
                                EditorView.scrollIntoView(selection, {
                                    y: 'center'
                                })
                            ]
                        });
                    }
                    this.inputText(up.state.doc.toString());
                }),
                lineNumbers(),
                EditorView.domEventHandlers({
                    paste: (ev, view) => {
                        log(`Prevented PASTE`);
                        ev.preventDefault();
                        return true;
                    },
                    copy: (ev, view) => {
                        log(`Prevented COPY`);
                        ev.preventDefault();
                        return true;
                    },
                    cut: (ev, view) => {
                        log(`Prevented CUT`);
                        ev.preventDefault();
                        return true;
                    }
                }),
                keymap.of([...standardKeymap]),
            ],
            parent: inputEditor,

        });
        this.inputEditor.focus();
    }
    public readonly inputText = ko.observable('');

    public readonly typedCharactersText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.typingtest2023.interactive.TYPED_CHARACTERS']);
    });

    public readonly writtenLength = ko.pureComputed(() => {
        return this.inputText().length;
    });

    public readonly loaded = ko.observable(false);

    public readonly output = ko.observable('');

    private gremlinState: {
        autoText: String;
    };

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (!this.gremlinState) {
            const data = getGolemData(this.itemId);
            if (typeof data === 'string') {
                this.gremlinState = {
                    autoText: clean(data),
                };
            } else {
                this.gremlinState = {
                    autoText: clean(this.data.text)
                };
            }
        }

        const toInsert = this.gremlinState.autoText.substring(0, 30);
        this.gremlinState.autoText = this.gremlinState.autoText.substring(30);
        if (toInsert) {
            this.inputEditor.dispatch({ selection: { anchor: this.inputEditor.state.doc.toString().length } });
            this.inputEditor.dispatch(this.inputEditor.state.replaceSelection(toInsert));
            return true;
        }
        return false;

    }

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => this.gremlins()
        });

        await super.initialize();

        this.templateView.dispatch({
            changes: {
                from: 0,
                to: this.templateView.state.doc.length,
                insert: this.data.text
            }
        });
        this.inputEditor.dispatch({
            changes: {
                from: 0,
                to: this.inputEditor.state.doc.length,
                insert: this.data.typedText()
            }
        });
        /*
        this.inputEditor.refresh();
        this.templateEditor.refresh();

        this.inputEditor.on('change', cm => {
            this.inputText(cm.getValue());
        });
        this.inputEditor.on('cursorActivity', editor => {
            const cursor = editor.getDoc().getCursor();
            const doc = this.templateEditor.getDoc();
            const word = this.templateEditor.findWordAt(cursor);
            doc.setCursor(cursor);
            doc.setSelection(word.anchor, word.head);
        });
        */

        if (this.params.mode === 'INTERACTIVE') {
            this.onChange(this.inputText, `${WIDGET_NAME}/${this.itemId}/text`, async () => {
                await this.data.setAnswer(this.inputText());
                return DONE;
            });
        }
        this.loaded(true);
    }
    dispose() {
        this.templateView.destroy();
        this.inputEditor.destroy();
        super.dispose();
    }
    public async OnReset() {
        this.inputEditor.dispatch({
            changes: {
                from: 0,
                to: this.inputEditor.state.doc.length,
                insert: this.data.typedText()
            }
        });
        //this.inputEditor.refresh();
        return DONE;
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params, componentInfo.element as HTMLElement);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import * as i18next from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'ui-author-noeditmode';

export type IParams = undefined;

export class ViewModel extends AbstractWidget {

    constructor(readonly params: IParams) {
        super();
    }
    public readonly text = ko.pureComputed(() => i18next.t(['ui.author.noeditmode.THIS_ITEM_TYPE_DOES_NOT_SUPPORT_AN_ONLINE_EDITOR_IT_CAN_BE_IMPORTED_THROUGH_DOCX_FILES_ONLY']));
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

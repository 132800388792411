import * as ko from 'knockout';

export interface IBulkSetOptions<T, X> {
    data: X[];
    key: (entry: X) => string;
    value: (entry: X) => T;
    clear: boolean;
}

export class KoMap<T> {
    constructor() {

    }
    private readonly data = new Map<string, T>();
    private readonly revision = ko.observable(0);

    public values() {
        this.detectDirty();
        return Array.from(this.data.values());
    }
    public keys() {
        this.detectDirty();
        return Array.from(this.data.keys());
    }
    public entries() {
        this.detectDirty();
        return Array.from(this.data.entries());
    }

    public has(key: string) {
        this.detectDirty();
        return this.data.has(key);
    }

    private dirty() {
        this.revision(this.revision() + 1);
    }
    private detectDirty() {
        this.revision();
    }

    public clear() {
        this.data.clear();
        this.dirty();
    }
    // tslint:disable-next-line:no-reserved-keywords
    public delete(key: string) {
        if (!this.data.has(key)) {
            return false;
        }
        this.data.delete(key);
        this.dirty();
        return true;
    }
    public keep(keys: Set<string>) {
        const toRemove = [];
        for (const key of this.keys()) {
            if (keys.has(key)) {
                continue;
            }
            toRemove.push(key);
        }
        if (!toRemove.length) {
            return;
        }
        for (const key of toRemove) {
            this.data.delete(key);
        }
        this.dirty();
        return;
    }

    public forEach(callbackfn: (value: T, index?: string, map?: Map<string, T>) => void) {
        this.detectDirty();
        this.data.forEach(callbackfn);
    }
    // tslint:disable-next-line:no-reserved-keywords
    public get(key: string) {
        this.detectDirty();
        return this.data.get(key);
    }
    // tslint:disable-next-line:no-reserved-keywords
    public set(key: string, value?: T) {
        this.data.set(key, value);
        this.dirty();
        return this;
    }
    public bulkSet<X>(options: IBulkSetOptions<T, X>) {
        if (options.clear) {
            this.data.clear();
        }
        for (const x of options.data) {
            this.data.set(options.key(x), options.value(x));
        }
        this.dirty();
    }
    public get size() {
        this.detectDirty();
        return this.data.size;
    }
}
import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir } from '../../../debug';
import { refreshDx } from '../../../dx_helper';
import { DONE } from '../../../helper';
import { ServerConnection } from '../../RestAPI';
import * as i18next from './../../../i18n/i18n';
import { FORMDATA, getPrivilegeType, MyFormBuilder, privilege_order, Q, SUBJECT } from './myformbuilder';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './useredit.html.g';


export class ViewModel extends DxWidget {
    public readonly allSubjects: SUBJECT[] = [];
    public readonly localizationDS = ko.observable<Q['config']['localizations']>();

    public readonly form = new MyFormBuilder(this);

    public async OnRefresh() {
        await super.OnRefresh();
        const result = await ServerConnection.api.ui_superuser_useredit_init({
            userDocRef: this.params.currentRoute.widgetParams.userDocRefId
        });
        const u = result.getUserById;
        const subjectRoles = u.privileges.map(x => x.__typename === 'SubjectPrivilege' && x).filter(x => !!x);
        const localizationRoles = u.privileges.map(x => x.__typename === 'LocalizationPrivilege' && x).filter(x => !!x);
        const contextlessRoles = u.privileges.map(x => x.__typename === 'BasicPrivilege' && x).filter(x => !!x);

        Object.assign(this.formData, {
            _id: u._id,
            isDeleted: u.isDeleted,
            userId: u.userId,
            displayName: u.displayName,
            initials: u.initials,
            mobilephone: u.mobilephone,
            secret: u.password,
            contextlessPrivileges: {},
            subjectPrivileges: {},
            localizationPrivileges: {},
        });
        for (const p of privilege_order) {
            switch (getPrivilegeType(p)) {
                case 'basic':
                    this.formData.contextlessPrivileges[p] = !!contextlessRoles.find(x => x.privilege === p);
                    break;
                case 'subject':
                    const subj = subjectRoles.find(x => x.privilege === p);
                    this.formData.subjectPrivileges[p] = subj && subj.subjects && subj.subjects.map(x => x.docReferenceId) || [];
                    break;
                case 'localization':
                    const loc = localizationRoles.find(x => x.privilege === p);
                    this.formData.localizationPrivileges[p] = loc && loc.localizations && loc.localizations.map(x => x.localizationId) || [];
                    break;
            }
        }
        this.allSubjects.splice(0, this.allSubjects.length, ...result.subjects);
        this.localizationDS(result.config.localizations);
        dir(this.formData);
        await refreshDx(this);
    }

    public readonly isPasswordVisible = ko.observable(false);


    private async updateDatabase(field: string, value: any) {
        const userDocRef = this.params.currentRoute.widgetParams.userDocRefId;
        const userId = this.formData.userId;
        const up = this.form.fields.get(field);
        if (up) {
            await ServerConnection.api.ui_superuser_useredit_update({
                params: Object.assign({
                    userDocRef,
                    userId,
                }, up(value))
            });
            return DONE;
        }
        return DONE;
    }

    private readonly formData: FORMDATA = <any>{};

    private initFormOptions() {
        this.form.init(this.formData, this.forms);
        this.form.formOptions.onFieldDataChanged = x => {
            void this.updateDatabase(x.dataField, x.value);
        };
    }

    public readonly subjectsTagBoxOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxTagBox.Properties = {
            dataSource: this.allSubjects,
            displayExpr: (data: SUBJECT) => data && data.subjectId || '?',
            valueExpr: 'docReferenceId',
            showSelectionControls: true,
            applyValueMode: 'useButtons',
            searchEnabled: true,
            searchExpr: ['subjectId']
        };
        return retVal;
    });
    public readonly localizationsTagBoxOptions = ko.pureComputed(() => {
        const datasource = this.localizationDS() || [];
        const retVal: DevExpress.ui.dxTagBox.Properties = {
            dataSource: datasource,
            displayExpr: (data: Q['config']['localizations'][0]) => data && data.name.value || '?',
            valueExpr: 'localizationId',
            showSelectionControls: true,
            applyValueMode: 'useButtons',
            searchEnabled: true,
            searchExpr: ['name.value']
        };
        return retVal;
    });

    public readonly isActiveLabel = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.useredit.ACTIVE']);
    });

    constructor(readonly params: IParams) {
        super();
    }
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.initFormOptions();
        this.loaded(true);
    }
    public readonly loaded = ko.observable(false);
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
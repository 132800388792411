import * as ko from 'knockout';
import { AbstractWidget } from '../../../AbstractWidget';
import { htmlString } from './grading.html.g';
import { IParams, WIDGET_NAME } from './route';

const WIDGET_PARENT_NAME = 'ui-bulkgrading';

export class ViewModel extends AbstractWidget {
    public readonly loaded = ko.observable(false);
    constructor(readonly params: IParams) {
        super();
    }

    public readonly itemDocRefId = ko.pureComputed(() => {
        return this.params.currentRoute.widgetParams.itemDocRefId;
    });

    public async initialize() {
        await super.initialize();
        this.loaded(true);
    }

}
export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

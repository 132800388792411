import { apiUrl } from '../config';
import { BUILD_INFO } from '../grow_version.g';
import { RestAPI } from './RestAPI.1';
export * from './api_interfaces';

export const ServerConnection = new RestAPI(apiUrl);

function isLink(elem: HTMLElement): elem is HTMLLinkElement {
    if (!elem) {
        return false;
    }
    return elem.nodeName === 'LINK';
}

const styleSheet = document.getElementById('UI_LOCATION');
if (!isLink(styleSheet)) {
    throw new Error(`Unable to determine UI Source`);
}
export const uiUrl = new URL('.', styleSheet.href).toString();

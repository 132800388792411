export const id = 'kosovo-handson';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';

itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'e0bd3a44-03fe-4f61-be8f-62cb2fa00b13',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

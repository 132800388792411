import * as ko from 'knockout';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';

export const WIDGET_NAME = 'ui-subjectcoordinator-home';

export class HomeRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => this.widgetParams.subjectTitle);
    constructor(readonly widgetParams: IParamsInner) {
    }
    public static async CREATE(subjectDocRefId: string) {
        const data = await ServerConnection.api.ui_subjectcoordinator_home_route({
            subjectId: subjectDocRefId
        });
        return new HomeRoute({
            subjectDocRefId,
            subjectTitle: data.subjectById.title
        });
    }
}

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
    subjectTitle: string;
}

export const FACTORY = ROUTES.registerRoute<{ subject: string }>({
    route: '/subjectcoordinator/subject/:subject',
    toParams: map => ({
        subject: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('subject', p.subject);
    },
    create: async p => {
        const hc = await HomeRoute.CREATE(p.subject);
        return hc;
    },
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import { IUpdateItemStateInput } from '../../../its-itembank-api.g';
import * as RICHTEXTHTML from '../../../richtext/html';
import { ServerConnection } from '../../../ui/RestAPI';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from "../ItemModel";

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoSingleChoiceImage_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type ANSWER = DATA['sciAnswers'][0];

export class SingleChoiceImageData_Answer {
    constructor(private readonly model: SingleChoiceImageData, public readonly rawData: ANSWER) {
        this.id = rawData.id;
        const attachment = model.meta.getAttachment(rawData.sciImage?.id);
        this.imgUrl = ServerConnection.getDataUrl(attachment?.hrefResolved);
        this.imgAlt = attachment?.altText?.value ?? '';
    }
    public readonly imgAlt: string;
    public readonly imgUrl: string;
    public readonly id: string;
    public readonly isSelected = ko.pureComputed(() => this.id === this.model.selectedAnswer());
    public readonly isCorrectAnswer = ko.pureComputed(() => this.id === this.model.correctAnswer);
}

export class SingleChoiceImageData extends ItemDataModel {
    constructor(readonly meta: ItemModel, data: DATA) {
        super();
        this.answers = data.sciAnswers.map(a => new SingleChoiceImageData_Answer(this, a));
        this.correctAnswer = data.correctAnswer;
        this.headerText = translate(data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(data.question, {}),
            attachments: this.meta.attachments,
        });
        this.imgWidth = data.imgWidth;
        this.imgHeight = data.imgHeight;
        this.fromJS(data);
    }
    public fromJS(data: DATA) {
        if (data.__typename !== 'KosovoSingleChoiceImage_QueryItemSessionData') {
            throw new Error();
        }
        this.selectedAnswer(data.selectedAnswer);
    }
    public readonly imgWidth: number;
    public readonly imgHeight: number;
    public readonly questionHTML: string;
    public readonly answers: SingleChoiceImageData_Answer[];
    public readonly selectedAnswer = ko.observable<string>();
    public readonly correctAnswer: string;
    public readonly headerText: string;
    public async setAnswer(answerId: string) {
        this.selectedAnswer(answerId);
    }

    public IsInteractionStarted() {
        return !!this.selectedAnswer();
    }
    public IsInteractionComplete() {
        return !!this.selectedAnswer();
    }
    public async reset() {
        this.selectedAnswer(undefined);
        return DONE;
    }
    public getItemState() {
        const retVal: IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            SingleChoiceImage: {
                selected: this.selectedAnswer(),
            }
        };
        return retVal;
    }

}

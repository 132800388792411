//tslint:disable:max-func-body-length
import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { log } from '../../debug';
import { DONE } from '../../helper';
import * as API from '../../its-itembank-api.g';
import * as AUTHOR_HOME from '../author/selectsubject/route';
import * as BULKGRADING_HOME from '../bulkgrading/selectsubject/route';
import { focusManager } from '../focusmanager';
import * as MANUALGRADING_HOME from '../manualgrading/selectsubject/route';
import { RoutableBase } from "../RoutableBase";
import * as ROUTES from '../routes';
import * as SC_HOME from '../subjectcoordinator/selectsubject/route';
import * as SUPERUSER_WIDGET_HOME from '../superuser/home/route';
import { registerOnUpdateToolbar } from '../toptoolbar.service';
import * as TRANSLATOR_HOME from '../translator/selectexamorder/route';
import { UIAction } from '../uiAction';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_base_getconfig']>>;

const WIDGET_NAME = 'ui-app';

class Role {
    readonly topTitle: string;
    constructor(readonly mainRoute: string, topTitle: ko.PureComputed<string>) {
        this.topTitle = topTitle();
    }
}

export class App extends RoutableBase {

    constructor() {
        super();
        this.disposables.addDiposable(registerOnUpdateToolbar(x => this.onPrepareTopToolbar(x)));
        this.disposables.addDiposable(ROUTES.routeManager.current.subscribe(async route => {
            if (!route) {
                this.currentRole('');
                return;
            }
            let topRoute: ROUTES.ANYROUTE;
            if (!route.parentPaths.length) {
                topRoute = route;
            } else {
                const topHref = route.parentPaths[route.parentPaths.length - 1];
                const r = ROUTES.matchRoute(topHref);
                if (r) {
                    topRoute = await r.factory.createFromMap(r.map);
                }
            }
            if (!topRoute) {
                this.currentRole('');
                return;
            }
            this.currentRole(ko.unwrap(topRoute.topTitle) || topRoute.title());
        }));
    }

    public readonly isNotFocusMode = ko.pureComputed(() => {
        return !focusManager.isFocused();
    });

    public readonly currentRole = ko.observable('');

    protected getRoleSwitcherMenu() {
        const retVal = {
            location: 'after',
            widget: 'dxDropDownMenu',
            locateInMenu: 'auto',
            options: {
                text: 'Change Role',
                icon: 'far fa-user',
                buttonIcon: 'far fa-user',
                buttonText: <any>this.currentRole,
                items: new Array<{}>()
            }
        };
        const eps = this.uiRoles() || [];
        for (const ep of eps) {
            retVal.options.items.push({
                text: ep.topTitle,
                onClick: () => this.changeRole.intent({ ep: ep })
            });
        }
        return retVal;
    }

    private onPrepareTopToolbar(toolbar: DevExpress.ui.dxToolbar.Properties) {
        return DONE;
    }
    public async initialize() {
        await super.initialize();

        this.initRoles();
        const initialHash = (location.hash || '').replace(/^#/, '');
        if (initialHash) {
            await ROUTES.routeManager.navigateToHREF(initialHash, true);
        } else {
            if (this.uiRoles().length) {
                await ROUTES.routeManager.navigateToHREF(this.uiRoles()[0].mainRoute, true);
            }
        }

    }

    private readonly uiRoles = ko.observable<Role[]>();
    private initRoles() {
        const ep: Role[] = [];
        if (this.isSuperUser()) {
            ep.push(new Role(SUPERUSER_WIDGET_HOME.FACTORY.href({}), SUPERUSER_WIDGET_HOME.topTitle));
        }
        if (this.isSubjectCoordinator()) {
            ep.push(new Role(SC_HOME.FACTORY.href({}), SC_HOME.topTitle));
        }
        if (this.isAuthor()) {
            ep.push(new Role(AUTHOR_HOME.FACTORY.href({}), AUTHOR_HOME.topTitle));
        }
        if (this.isEvaluator()) {
            ep.push(new Role(MANUALGRADING_HOME.FACTORY.href({}), MANUALGRADING_HOME.topTitle));
            ep.push(new Role(BULKGRADING_HOME.FACTORY.href({}), BULKGRADING_HOME.topTitle));
        }
        if (this.isTranslator()) {
            ep.push(new Role(TRANSLATOR_HOME.FACTORY.href({}), TRANSLATOR_HOME.topTitle));
        }
        this.uiRoles(ep);
    }

    private changeRole = new UIAction<{
        ep: Role;
    }>(undefined, async (e, args) => {
        log(`Changing main widget to: ${args.ep.topTitle}`);
        await ROUTES.routeManager.navigateToHREF(args.ep.mainRoute);
    });

}

export type IParams = undefined;

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new App();
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { log } from '../../debug';
import * as query from '../../getquery';
import * as HELPER from '../../helper';
import * as API from '../../its-itembank-api.g';
import { ButtonVisibility, EMailConfirmed, ResultPagePdf } from '../../its-itembank-api.g';
import * as Modal from '../../modal';
import * as RICHTEXTHTML from '../../richtext/html';
import { DefaultWindowManager } from '../base';
import { runQueue } from '../docmanager';
import { ItemHostBase } from "../ItemHostBase";
import { ServerConnection } from '../RestAPI';
import * as RESULTSCREEN_DEFAULT from '../resultscreens/default/widget';
import { ParticipationContext } from "../service/participation/ParticipationContext";
import { ParticipationService } from '../service/participation/service';
import { UIAction } from '../uiAction';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

type INIT = Awaited<ReturnType<API.Sdk['ui_participatesingle_init']>>;
type Q = Awaited<ReturnType<API.Sdk['ui_participatesingle_examsession']>>;
const WIDGET_NAME = 'ui-participatesingle';
let IDX = 0;

export class App extends ItemHostBase {
    public readonly id = `${WIDGET_NAME}#${IDX++}`;

    //private readonly timerFinished = ko.observable(false);
    public readonly email = ko.observable('');
    public readonly sessionDocRefId = ko.observable<string>();
    public readonly canSubmit = ko.observable(true);
    public readonly emailEnabled = ko.observable(false);
    public readonly currentItemDocRefId = ko.observable<string>();
    public readonly currentItemType = ko.observable<string>();
    public readonly participationContext = ko.observable<ParticipationContext>();

    public readonly isAvailable = this.toCtx(ctx => ctx.isAvailable(), 'isAvailable');

    public readonly loadPanelVisible = this.toCtx(ctx => ctx.loadPanelVisible(), 'loadPanelVisible');
    public readonly abortButtonLabel = this.toCtx(ctx => ctx.abortButtonLabel(), 'abortButtonLabel');
    public readonly startButtonLabel = this.toCtx(ctx => ctx.startButtonLabel(), 'startButtonLabel');
    public readonly isTimeLeftEnabled = this.toCtx(ctx => ctx.isTimeLeftEnabled(), 'isTimeLeftEnabled');
    public readonly isTimePassedEnabled = this.toCtx(ctx => ctx.isTimePassedEnabled(), 'isTimePassedEnabled');
    public readonly hasSubmitButton = this.toCtx(ctx => ctx.hasSubmitButton(), 'hasSubmitButton');
    public readonly isTimeEnabled = this.toCtx(ctx => ctx.isTimeEnabled(), 'isTimeEnabled');
    public readonly customlogo1 = this.toCtx(ctx => ctx.customlogo1(), 'customlogo1');
    public readonly customlogo2 = this.toCtx(ctx => ctx.customlogo2(), 'customlogo2');

    public readonly itemIncompleteMessage = ko.pureComputed(() => {
        return i18next.t(['ui.participatesingle.THIS_QUESTION_HAS_NOT_BEEN_FILLED_OUT_COMPLETELY']);
    });

    public readonly timeLeftLabel = ko.pureComputed(() => i18next.t(['ui.participatesingle.MINUTES_LEFT']));
    public readonly timePassedLabel = ko.pureComputed(() => i18next.t(['ui.participatesingle.MINUTES_PASSED']));

    public readonly loadPanelMessage = ko.pureComputed(() => {
        return i18next.t(['ui.participatesingle.LOADING']);
    });



    public readonly initData = ko.observable<INIT>();

    public readonly data = ko.observable<Q>();
    public readonly sessionData = ko.pureComputed(() => {
        const data = this.data();
        if (!data) {
            return undefined;
        }
        return data.examSession;
    });



    public readonly minutesLeft = this.toCtx(ctx => ctx.tickTock.timeleftMinutes(), 'minutesLeft');

    public readonly minutesPassed = this.toCtx(ctx => ctx.tickTock.timePassedMinutes(), 'minutesPassed');


    public readonly submitButtonCSS = this.toCtx(ctx => {
        const color = ctx.currentSectionColor();
        const css = [];
        css.push(`SubmitButton${color}`);
        if (this.canSubmit()) {
            css.push('green');
            css.push('white-text');
        } else {
            css.push('SubmitButtonDisabled');
        }
        return css.join(' ');
    }, 'submitButtonCSS');

    public async OnRefresh() {
        await super.OnRefresh();

        const sessionDocRefId = this.sessionDocRefId();
        const x = await ServerConnection.api.ui_participatesingle_examsession({
            params: {
                sessionDocRefId,
            }
        });

        //this.data(x.data);
        this.emailEnabled(x.examSession.notification.emailConfirmed !== EMailConfirmed.No);
        const es = x.examSession;
        const ci = es && es.currentItem;
        const itemId = ci && ci.itemDocRefId;
        log(`${this.id}: refreshed. current item: ${itemId} (was: ${this.currentItemDocRefId()})`);
        this.data(x);
        HELPER.update_observable(this.currentItemDocRefId, itemId);
        HELPER.update_observable(this.currentItemType, ci && ci.itemType);
        log(`${this.id}: Top Item is ${this.currentItemDocRefId()}`);

    }

    public readonly sessionAvailable = ko.pureComputed(() => {
        return !!this.sessionData();
    });



    public readonly mode = this.toCtx(ctx => ctx.mode(), 'mode');

    public readonly isStartPage = this.toCtx(ctx => ctx.isStartPage(), 'isStartPage');
    public readonly isExecutionPage = this.toCtx(ctx => ctx.isExecutionPage(), 'isExecutionPage');
    public readonly isPausePage = this.toCtx(ctx => ctx.isPausePage(), 'isPausePage');
    public readonly isAbortedPage = this.toCtx(ctx => ctx.isAbortedPage(), 'isAbortedPage');
    public readonly isFinishPage = this.toCtx(ctx => ctx.isFinishPage(), 'isFinishPage');


    public readonly actionStartExam = new UIAction(undefined, async () => {
        const ctx = this.participationContext();
        if (!ctx) {
            return;
        }
        await ctx.startExam(true);
    });

    public readonly actionAbortExam = new UIAction(undefined, async () => {
        if (!await Modal.confirmYesNo(i18next.t(['ui.participatesingle.DO_YOU_WANT_TO_ABORT_THE_EXAM']))) {
            return;
        }
        this.closeWindow();
    });

    public readonly actionPDF = new UIAction(undefined, async () => {
        const wnd = DefaultWindowManager.prepareOpenWindow('');
        const url = ServerConnection.getDataUrl(`sessions/${this.sessionDocRefId()}/pdf`, {}, true);
        DefaultWindowManager.openWindow(url, '');
    });

    public readonly hasPDFButton = ko.pureComputed(() => {
        if (!this.isFinishPage()) {
            return false;
        }
        return !!this.pdfButtonLabel();
    });
    public readonly hasEMail = ko.pureComputed(() => {
        if (!this.isFinishPage()) {
            return false;
        }
        const id = this.initData();
        if (!id) {
            return false;
        }
        const rp = id.examSession.examInfo.resultPage;
        return rp && rp.email !== 'no';
    });
    public readonly pdfButtonLabel = ko.pureComputed(() => {
        const id = this.initData();
        if (!id) {
            return '';
        }

        const rp = id.examSession.examInfo.resultPage;
        if ((rp && rp.pdfButton.visibility) === ButtonVisibility.Hide) {
            return '';
        }
        if ((rp && rp.pdf) !== ResultPagePdf.Yes) {
            return '';
        }

        return (rp && rp.pdfButton.label.value) || i18next.t(['ui.participatesingle.PDF']);
    });

    public readonly emailDisabled = ko.pureComputed(() => !this.emailEnabled());
    public readonly emailConsentText = ko.pureComputed(() => {
        const id = this.initData();
        if (!id) {
            return '';
        }
        const rp = id.examSession.examInfo.resultPage;
        return rp && rp.emailText.value;
    });


    public readonly emailLabel = ko.pureComputed(() => {
        const id = this.initData();
        if (!id) {
            return '';
        }
        const rp = id.examSession.examInfo.resultPage;
        return rp && rp.emailLabel.value;
    });
    public readonly finishButtonLabel = ko.pureComputed(() => {
        const id = this.initData();
        if (!id) {
            return '';
        }
        const rp = id.examSession.examInfo.executionPage;
        if ((rp && rp.finishButton.visibility) === ButtonVisibility.Hide) {
            return '';
        }
        return rp && rp.finishButton.label.value || i18next.t(['ui.participatesingle.FINISH']);
    });

    public readonly actionClose = new UIAction(undefined, async () => {
        await runQueue({ noRefresh: true });
        this.closeWindow();
    });

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        const ctx = this.participationContext();
        if (!ctx) {
            return true;
        }
        if (this.isStartPage()) {
            log('Gremlin: start exam!');
            await this.actionStartExam.invoke();
            return true;
        }
        if (this.isExecutionPage()) {
            log('Gremlin: submit it!');
            if (this.hasSubmitButton()) {
                log('Submit item!');
                await ctx.actionSubmit.invoke();
                return true;
            }
        }
        return false;
    }


    public readonly welcomePageText = ko.pureComputed(() => {
        const d = this.initData();
        const ei = d && d.examSession && d.examSession.examInfo;
        const wp = ei && ei.welcomePage;
        return RICHTEXTHTML.process({
            html: wp && wp.text && wp.text.value,
            convertLFtoBR: 'auto'
        });
    });

    public readonly examTitle = ko.pureComputed(() => {
        const d = this.initData();
        const ei = d && d.examSession && d.examSession.examInfo;
        return ei && (ei.title || ei.examAlias);
    });

    public readonly finishPagePDFButtonOptions = ko.pureComputed(() => {
        const label = this.pdfButtonLabel();
        if (!label) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxButton.Properties = {
            onClick: this.actionPDF.click,
            text: label
        };
        return retVal;
    });
    public readonly startPageStartButton = ko.pureComputed(() => {
        const label = this.initData().examSession.examInfo.welcomePage.startButton.label.value || i18next.t(['ui.participatesingle.BEGIN_TEST']);
        const retVal: DevExpress.ui.dxButton.Properties = {
            onClick: this.actionStartExam.click,
            text: label
        };
        return retVal;
    });
    public readonly startPageCancelButton = ko.pureComputed(() => {
        const label = this.abortButtonLabel();
        if (!label) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxButton.Properties = {
            onClick: this.actionAbortExam.click,
            text: label
        };
        return retVal;
    });
    public readonly finishPageSubmitButton = this.toCtx(ctx => {
        const label = this.finishButtonLabel();
        if (!label) {
            return undefined;
        }
        const retVal: DevExpress.ui.dxButton.Properties = {
            onClick: ctx.actionSubmit.click,
            text: label
        };
        return retVal;
    });
    public readonly finishPageCloseButtonOptions = ko.pureComputed(() => {
        const label = this.initData().examSession.examInfo.resultPage.closeButton.label.value || i18next.t(['ui.participatesingle.CLOSE']);
        const retVal: DevExpress.ui.dxButton.Properties = {
            onClick: this.actionClose.click,
            text: label
        };
        return retVal;
    });

    public readonly finishPageEMailOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxTextBox.Properties = {
            value: <any>this.email,
            disabled: <any>this.emailDisabled,
        };
        return retVal;
    });
    public readonly emailConsentOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxCheckBox.Properties = {
            value: <any>this.emailEnabled,
            text: this.emailConsentText(),
        };
        return retVal;

    });

    protected async onInitialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME}`,
            priority: 1000,
            action: () => this.gremlins()
        });

        await super.onInitialize();
        const session = query.params.get('session');
        if (!session) {
            throw new Error(i18next.t(['ui.participatesingle.INVALID_QUERY_PARAMETERS']));
        }
        this.sessionDocRefId(session);

        const ctx = await ParticipationService.InitSession({
            session,
            stayInSection: true,
            hasOverviewPage: true
        });
        this.participationContext(ctx);

        this.appTitleSection(ctx.appTitleSection());
        this.disposables.addDiposable(ctx.appTitleSection.subscribe(x => {
            this.appTitleSection(x);
        }));

        const initial = await ServerConnection.api.ui_participatesingle_init({
            params: {
                sessionDocRefId: this.sessionDocRefId(),
            },
        });
        this.initData(initial);

        await this.OnRefresh();

        this.onChange(this.email, `${WIDGET_NAME}/email`, async val => {
            await ServerConnection.api.ui_paricipatesingle_setnotificationemail({
                sessionId: this.sessionDocRefId(),
                email: val
            });
            return HELPER.DONE;
        });
        this.onChange(this.emailEnabled, `${WIDGET_NAME}/emailEnabled`, async val => {
            await ServerConnection.api.ui_paricipatesingle_setnotificationemail({
                sessionId: this.sessionDocRefId(),
                confirmed: val
            });
            return HELPER.DONE;
        });
    }



    public readonly resultScreenParams = ko.pureComputed(() => {

        const retVal: RESULTSCREEN_DEFAULT.IParams = {
            sessionId: this.sessionDocRefId(),
            mode: 'screen',
        };
        return retVal;
    });




    public readonly isNotFocusMode = ko.pureComputed(() => {
        return !this.focusManager.isFocused();
    });

    private toCtx<T>(cb: (ctx: ParticipationContext) => T, debug_name?: string) {
        const obs = ko.pureComputed(() => {
            const ctx = this.participationContext();
            if (!ctx) {
                return undefined;
            }
            return cb(ctx);
        });
        this.disposables.addDiposable(obs.subscribe(x => {
            log(`${this.id}: ${debug_name || 'observable'} changed to '${x}'`);
        }));
        return obs;
    }


}

export type IParams = undefined;

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new App();
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

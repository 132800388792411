import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { DxWidget } from '../../../../../AbstractWidget';
import { AS, datagrid, refreshDx, storeState } from '../../../../../dx_helper';
import { encodeHtml } from '../../../../../helper';
import { UIAction } from '../../../../../ui/uiAction';
import { GapValue, GradingGap } from '../data';
import * as i18next from './../../../../../i18n/i18n';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'itemdefinition-kosovo-textentry_manual-grading-gap';


export interface IImage {
    ind: number;
    name: string;
}

export interface IParams {
    gap: GradingGap
}

export class ViewModel extends DxWidget {
    public readonly loaded = ko.observable(false);

    public readonly gap: GradingGap;
    constructor(readonly params: IParams) {
        super();
        this.gap = params.gap;
    }

    private readonly selectedCorrect = new UIAction(undefined, async () => {
        const grid = this.grid();
        if (!grid) {
            return;
        }
        const data = <GapValue[]>await grid.getSelectedRowsData();
        for (const d of data) {
            await d.selectCorrect.invoke(undefined, true);
        }
    });
    private readonly selectedIncorrect = new UIAction(undefined, async () => {
        const grid = this.grid();
        if (!grid) {
            return;
        }
        const data = <GapValue[]>await grid.getSelectedRowsData();
        for (const d of data) {
            await d.selectIncorrect.invoke(undefined, true);
        }
    });
    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
    public async OnRefresh() {
        await super.OnRefresh();
        await refreshDx(this);
    }

    public readonly curGapCorrect = ko.pureComputed(() => {
        const gap = this.gap;
        return i18next.t(['itemdefinition.kosovo.textentry_manual.grading.CORRECT']) + ': ' + gap.gap.possibilities.map(x => `<span class='correct'>${encodeHtml(x.value.value)}</span>`).join(', ');
    });

    private readonly selectedGapValues = ko.pureComputed(() => {
        const gap = this.gap;
        return gap.values();
    });
    private readonly grid = ko.observable<DevExpress.ui.dxDataGrid>();

    public readonly gridOptions = ko.pureComputed(() => {
        const curGap = this.gap;
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.grid,
            config: {
                stateStoring: storeState(WIDGET_NAME),
                columnAutoWidth: true,
                focusedRowEnabled: true,
                keyExpr: 'answer',
                headerFilter: {
                    visible: true,
                },
                allowColumnResizing: true,
                selection: {
                    selectAllMode: 'page',
                    allowSelectAll: true,
                    mode: 'multiple',
                    showCheckBoxesMode: 'always',
                },
                columns: [],
            }
        });

        retVal.onToolbarPreparing = e => {
            e.toolbarOptions.items.unshift(AS<DevExpress.ui.dxToolbarItem>(
                {
                    location: 'before',
                    widget: 'dxButton',
                    options: AS<DevExpress.ui.dxButton.Properties>({
                        text: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.INCORRECT']),
                        onClick: this.selectedIncorrect.click
                    })
                }
            ));
            e.toolbarOptions.items.unshift(AS<DevExpress.ui.dxToolbarItem>(
                {
                    location: 'before',
                    widget: 'dxButton',
                    options: AS<DevExpress.ui.dxButton.Properties>({
                        text: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.CORRECT']),
                        onClick: this.selectedCorrect.click
                    })
                }));

        };
        retVal.dataSource = this.selectedGapValues();

        retVal.columns.push({
            caption: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.FREQUENCY']),
            dataField: 'count',
        });
        retVal.columns.push({
            caption: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.ANSWER']),
            dataField: 'answer',
        });
        retVal.columns.push({
            caption: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.STATUS']),
            dataField: 'isCorrect'
        });

        retVal.columns.push({
            caption: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.SUBMITTED']),
            dataField: 'submitted'
        });

        retVal.columns.push({
            caption: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.ACTIONS']),
            type: 'buttons',

            width: '120',
            buttons: [
                {
                    text: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.CORRECT']),
                    name: 'Correct',
                    visible: (o) => {
                        const data = o.row.data as GapValue;
                        return !data.submitted();
                    },
                    onClick: e => {
                        const data = e.row.data as GapValue;
                        void data.selectCorrect.click();
                    }
                },
                {
                    text: i18next.t(['itemdefinition.kosovo.textentry_manual.grading.INCORRECT']),
                    name: 'Incorrect',
                    visible: (o) => {
                        const data = o.row.data as GapValue;
                        return !data.submitted();
                    },
                    onClick: e => {
                        const data = e.row.data as GapValue;
                        void data.selectIncorrect.click();
                    }
                }
            ]
        });


        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME),
});

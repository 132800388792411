import $ from 'jquery';
import * as ko from 'knockout';
import Snap from 'snapsvg';
import { error, log } from '../../../debug';
import { getGolemData, golemMatch, isStringMap } from '../../../Gremlin';
import * as HELPER from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { getRandomEntry } from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { MatchingHotspotData, MatchingHotspotData_Answer, MatchingHotspotData_Shape } from '../../model/matchinghotspot/MatchingHotspotData';
import { GetSession } from '../../model/session';
import { htmlString } from './matchinghotspot.html.g';


const WIDGET_NAME = 'itemdefinition-kosovo-matchinghotspot';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

export type IParams = IItemDefinitionWidgetParams;


interface IPoint {
    x: number;
    y: number;
}

interface ICurAnswer {
    id: string;
    svgElem: Snap.Element;
    answerClass: string;
    connectorPos: IPoint;
    connectedTo: ko.Observable<string>;
    userConnector: { svgPath: Snap.Element, svgEnd: Snap.Element };
    correctConnectTo: string;
    rightConnector: {
        svgPath: Snap.Element,
        svgEnd: Snap.Element
    };
}

interface ICurShape {
    id: string;
    svgElem: Snap.Element;
    connectorPos: IPoint;
}

const START_LEFT = 500;

class CurAnswer {

    public readonly id: string;
    public readonly svgElem: Snap.Element;
    public readonly connectorPos: { x: number, y: number };
    public readonly connectedTo = ko.observable<string>();
    public readonly userConnector: { svgPath: Snap.Element, svgEnd: Snap.Element };
    public readonly correctConnectTo: string;
    public readonly rightConnector: { svgPath: Snap.Element, svgEnd: Snap.Element };
    public readonly answerClass: string;

    constructor(readonly vm: MyModel, readonly model: MatchingHotspotData_Answer) {
        const nAnswers = vm.data.answers.length;
        const nAnswer = model.nAnswer;

        const imgHeight = Math.max(vm.data.height, 300);
        const TOTAL_HEIGHT = imgHeight;
        const PER_ANSWER_HEIGHT = TOTAL_HEIGHT / (nAnswers + 1);
        const PER_ANSWER_GAP = TOTAL_HEIGHT / nAnswers;

        const startY = PER_ANSWER_GAP * nAnswer;
        const rect = vm.snap.rect(0, startY, START_LEFT * 0.9, PER_ANSWER_HEIGHT, 5, 5);
        const rectBBox = rect.getBBox();
        const y = startY + PER_ANSWER_HEIGHT / 2;
        const text = vm.snap.text(START_LEFT * 0.9 / 2, y, model.text);
        text.attr({ 'text-anchor': 'middle' });
        const bbox = text.getBBox();
        const realY = startY + (PER_ANSWER_HEIGHT - bbox.height) / 2;
        text.attr({ y: y - bbox.y + realY });

        this.id = model.id;
        this.svgElem = rect;
        this.connectorPos = { x: rectBBox.x2, y: rectBBox.y + rectBBox.height / 2 };
        this.connectedTo = model.connectedTo;
        this.userConnector = { svgPath: null, svgEnd: null };
        this.correctConnectTo = model.correctConnectTo;
        this.rightConnector = {

            svgPath: vm.snap.path('').addClass('ConnectorResult').addClass('Answer' + nAnswer),
            svgEnd: vm.snap.circle(0, 0, 3).addClass('ConnectorEndResult').attr({ visibility: 'hidden' })
        };
        this.answerClass = 'Answer' + nAnswer;

        this.vm.disposables.addDiposable(this.connectedTo.subscribe((shapeId) => {
            this.updateConnector();
        }));

    }

    public updateConnector() {
        let end: IPoint = null;
        const connectedTo = this.connectedTo();
        this.vm.svg_shapes.forEach((shape) => {
            if (shape.id === connectedTo) {
                end = shape.connectorPos;
                shape.svgElem.toggleClass(this.answerClass, true);
            } else {
                shape.svgElem.toggleClass(this.answerClass, false);
            }
        });
        if (end === null) {
            this.userConnector.svgPath.attr({ visibility: 'hidden' });
            this.userConnector.svgEnd.attr({ visibility: 'hidden' });
            this.svgElem.toggleClass(this.answerClass, false);
        } else {
            const path = getConnectorPath(this.connectorPos, end);
            this.svgElem.toggleClass(this.answerClass, true);
            this.userConnector.svgPath.attr({ d: path, visibility: 'visible' });
            this.userConnector.svgEnd.attr({ cx: end.x, cy: end.y, visibility: 'visible' });
        }
    }

}

class CurShape {
    public readonly id: string;
    public readonly svgElem: Snap.Element;
    public readonly connectorPos: { x: number, y: number };

    public isConnected() {
        return this.vm.svg_answers.some(x => x.connectedTo() === this.id);
    }
    constructor(readonly vm: MyModel, readonly model: MatchingHotspotData_Shape) {

        const imgWidth = vm.imageDimensions().width;
        const imgHeight = vm.imageDimensions().height;
        if (!imgWidth || !imgHeight) {
            return;
        }
        const x = model.x;
        const y = model.y;
        const r = model.r;
        const center = { x: START_LEFT + imgWidth * x / 100, y: imgHeight * y / 100 };
        const circle = vm.snap.circle(center.x, center.y, imgWidth * r / 100);
        circle.addClass('Circle');
        this.id = model.id;
        this.svgElem = circle;
        this.connectorPos = center;

    }
}

function getConnectorPath(start: IPoint, end: IPoint) {
    const r = Math.round;
    //draw only the stright line
    return ['M', r(start.x), ',', r(start.y), 'Q', start.x, ',', r(start.y), ' ',
        r(start.x), ',', r(start.y), 'T', r(end.x), ',', r(end.y)].join('');
}





export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public snap: Snap.Paper;
    public svg_answers: Array<CurAnswer> = [];
    public svg_shapes: Array<CurShape> = [];
    public readonly data: MatchingHotspotData;

    constructor(readonly params: IParams, readonly componentInfo: ko.components.ComponentInfo) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof MatchingHotspotData)) {
            throw new Error();
        }
        this.data = data;
    }

    public readonly cssClasses = ko.pureComputed(() => {
        const mode = this.mode();
        switch (mode) {
            case 'INTERACTIVE':
                return 'question Interactive';
            case 'REVIEW':
            case 'RESULT':
                return 'NotInteractive';
            case 'PRINT':
                return 'NotInteractive Print';
            case 'EDIT':
            case 'INSPECT':
            case 'GRADING':
            case 'SHOWTOOL':
                return '';
            default:
                HELPER.assertNever(mode);
                throw new Error();

        }
    });

    public readonly showScore = ko.pureComputed(() => {
        switch (this.mode()) {
            case 'RESULT':
            case 'PRINT':
                return true;

        }
        return false;
    });

    private showCorrectConnect(answer: CurAnswer) {
        let rightend: IPoint = null;
        const rightconnectedTo = answer.correctConnectTo;
        this.svg_shapes.forEach((shape) => {
            if (shape.id === rightconnectedTo) {
                rightend = shape.connectorPos;
                shape.svgElem.toggleClass(answer.answerClass, true);
            } else {
                shape.svgElem.toggleClass(answer.answerClass, false);
            }

        });
        if (rightend === null) {
            answer.rightConnector.svgPath.attr({ visibility: 'hidden' });
            answer.rightConnector.svgEnd.attr({ visibility: 'hidden' });
            answer.svgElem.toggleClass(answer.answerClass, false);
        } else {
            const rightpath = getConnectorPath(answer.connectorPos, rightend);
            answer.svgElem.toggleClass(answer.answerClass, true);
            answer.rightConnector.svgPath.attr({ d: rightpath, visibility: 'visible' });
            answer.rightConnector.svgEnd.attr({ cx: rightend.x, cy: rightend.y, visibility: 'visible' });
        }
    }


    private selectedAnswerId: string = null;
    private selectedShapeId: string = null;

    private updateSelectionStatus() {
        this.svg_answers.forEach((xAnswer) => {
            xAnswer.svgElem.toggleClass('Selected', this.selectedAnswerId === xAnswer.id);
        });
        this.svg_shapes.forEach((xShape) => {
            xShape.svgElem.toggleClass('Selected', this.selectedShapeId === xShape.id);
        });
    }

    private async tryConnect() {
        this.updateSelectionStatus();
        if (!this.selectedAnswerId || !this.selectedShapeId) {
            return;
        }
        await this.connect.intent({
            answerId: this.selectedAnswerId,
            shapeId: this.selectedShapeId
        });

        this.selectedAnswerId = null;
        this.selectedShapeId = null;
        this.updateSelectionStatus();

    }

    private readonly selectAnswer = new UIAction<ICurAnswer>(undefined, async (e, answer) => {
        this.selectedAnswerId = answer.id;
        await this.tryConnect();
    });
    private readonly selectShape = new UIAction<ICurShape>(undefined, async (e, shape) => {
        this.selectedShapeId = shape.id;
        await this.tryConnect();
    });
    private correctConnect() {
        this.svg_answers.forEach((answer) => {
            this.showCorrectConnect(answer);
        });
    }


    private initSVG() {
        const element = $(this.componentInfo.element).find('.question-content');
        const htmlElement = element.get(0);
        const vm = this;

        for (let i = htmlElement.childNodes.length - 1; i >= 0; i--) {
            ko.removeNode(htmlElement.childNodes[i]);
        }

        const imgWidth = this.data.width;
        const imgHeight = this.data.height;
        if (!imgWidth || !imgHeight) {
            return;
        }

        //tslint:disable-next-line:no-http-string
        const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        $(svgElement).attr({
            width: imgWidth + START_LEFT,
            height: imgHeight > 300 ? imgHeight : 300
        });
        htmlElement.appendChild(svgElement);
        this.snap = Snap(svgElement);
        const img = this.snap.image(vm.imgUrl(), START_LEFT, 0, imgWidth, imgHeight);

        this.svg_answers = this.data.answers.map(x => new CurAnswer(this, x));
        this.svg_shapes = this.data.shapes.map(x => new CurShape(this, x));

        img.attr({ 'xlink:href': vm.imgUrl() });
        img.attr({ alt: vm.imgAlt() });
        img.attr({ width: imgWidth });
        img.attr({ height: imgHeight });
        img.attr({ x: START_LEFT });
        img.attr({ preserveAspectRatio: 'none' });
        this.svg_answers.forEach((answer) => {
            answer.userConnector.svgPath = this.snap.path('').addClass('Connector');
            if (vm.mode() === 'RESULT' || vm.mode() === 'PRINT') {
                const isCorrectShape = this.svg_answers.find(val => val.id === answer.id).model.correctConnectTo === answer.connectedTo();
                answer.userConnector.svgPath.toggleClass('Correct', isCorrectShape);
                answer.userConnector.svgPath.toggleClass('UnCorrect', !isCorrectShape);
            } else {
                answer.userConnector.svgPath.addClass(answer.answerClass);
            }
            answer.userConnector.svgEnd = this.snap.circle(0, 0, 3).addClass('ConnectorEnd').attr({ visibility: 'hidden' });
            answer.updateConnector();
        });
        if (vm.mode() === 'RESULT' || vm.mode() === 'PRINT') {
            this.correctConnect();
        }
        this.svg_answers.forEach((answer) => {

            answer.svgElem.addClass('Selectable');
            if (vm.mode() === 'INTERACTIVE') {
                answer.svgElem.click(() => {
                    this.selectAnswer.click(answer);
                });
            }
        });
        this.svg_shapes.forEach((shape) => {
            shape.svgElem.addClass('Selectable');
            if (vm.mode() === 'INTERACTIVE') {
                shape.svgElem.click(() => {
                    this.selectShape.click(shape);
                });
            }
        });

    }
    private golemState: Map<string, string>;
    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                if (!this.golemState) {
                    this.golemState = new Map<string, string>();
                    const answers = this.svg_answers.map(x => ({
                        key: x.id,
                        value: x.model.text,
                    }));
                    const shapes = this.svg_shapes.map(x => ({
                        key: x.id,
                        value: x.model.shape.shape,
                    }));
                    const usedShapes = new Set<string>();
                    const golemData = getGolemData(this.itemId);
                    if (isStringMap(golemData)) {

                        for (const key of Object.keys(golemData)) {
                            const answerId = golemMatch(answers, key);
                            if (this.golemState.has(answerId)) {
                                error(`Golem for ${this.itemId} has duplicate match for '${key}'`);
                                continue;
                            }
                            if (answerId) {
                                const shapeId = golemMatch(shapes, golemData[key]);
                                if (!shapeId) {
                                    error(`Golem for ${this.itemId} answer ${answerId} has no matching shape ${golemData[key]}`);
                                    continue;
                                }
                                if (usedShapes.has(shapeId)) {
                                    error(`Golem for ${this.itemId} uses shape ${shapeId} more than once`);
                                    continue;
                                }
                                this.golemState.set(answerId, shapeId);
                            }
                        }
                    }
                    for (const ans of answers) {
                        if (this.golemState.has(ans.key)) {
                            continue;
                        }
                        const randomShape = getRandomEntry(shapes.filter(x => !usedShapes.has(x.key)));
                        log(`Golem has no suggestion for ${this.itemId}/${ans.key} - picking ${randomShape.key}`);
                        usedShapes.add(randomShape.key);
                        this.golemState.set(ans.key, randomShape.key);
                    }
                    log(JSON.stringify({
                        golemData,
                        itemId: this.itemId,
                        golemState: Array.from(this.golemState.entries())
                    }));
                }
                for (const a of this.svg_answers) {
                    if (!a.connectedTo()) {
                        const shapeId = this.golemState.get(a.id);
                        const shape = this.svg_shapes.find(x => x.id === shapeId);
                        if (!shape) {
                            log(`Gremlin ${this.itemId} - no shape for ${a.id}->${shapeId}`);
                            return false;
                        }
                        await this.connect.invoke({
                            answerId: a.id,
                            shapeId: shape.id,
                        });
                        return true;
                    }
                }
                return false;
            }
        });
        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        this.imageWidth(this.data.width);
        this.imageHeight(this.data.height);
        await this.OnRefresh();
        this.initSVG();
        this.loaded(true);
    }

    public readonly imgUrl = ko.pureComputed(() => this.data.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.data.imgAlt);
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly connect = new UIAction<{ answerId: string, shapeId: string }>(undefined, async (e, args) => {
        const answerId = args.answerId;
        const shapeId = args.shapeId;
        if (!answerId || !shapeId) {
            return;
        }
        await this.data.connect({ answerId, shapeId });
    });

    private readonly imageHeight = ko.observable(0);
    private readonly imageWidth = ko.observable(0);

    public readonly imageDimensions = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }, target: {
                width: this.data.width,
                height: this.data.height,
            }
        });
    });

    public async OnReset() {
        this.selectedAnswerId = undefined;
        this.selectedShapeId = undefined;
        this.updateSelectionStatus();
        return HELPER.DONE;
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params, componentInfo);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

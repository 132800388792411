export const id = 'inapplicationcombo';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'd615e9a9-d630-4139-bcd1-5b5f17b0706c',
    widgetNames: {
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`],
            ['RESULT', `itemdefinition-${id}-result`],
            ['REVIEW', `itemdefinition-${id}-result`],
            ['PRINT', `itemdefinition-${id}-result`],
            ['INTERACTIVE', `itemdefinition-${id}-interactive`]
        ]),
    },
});

import * as ko from 'knockout';
import { AbstractWidget } from '../../AbstractWidget';
import { formatMessage } from '../../i18n/data';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './score.html.g';

const WIDGET_NAME = 'widgets-score';
const WIDGET_PARENT_NAME = 'widgets';

export interface IParams {
    points: {
        pointsGained: number;
        pointsLost: number;
        pointsPending: number;
        pointsMax: number;
    }
}

function max2(nr: number) {
    return Math.round(nr * 100) / 100;
}
export class MyModel extends AbstractWidget {
    constructor(readonly params: IParams) {
        super();
        this.pointsGained = this.params.points.pointsGained || 0;
        this.pointsLost = this.params.points.pointsLost || 0;
        this.pointsPending = this.params.points.pointsPending || 0;
        this.pointsMax = this.params.points.pointsMax || 0;
    }

    private readonly pointsGained: number;
    private readonly pointsLost: number;
    private readonly pointsPending: number;
    private readonly pointsMax: number;


    textParams() {
        return {
            ScoreGained: max2(this.pointsGained),
            ScoreMax: max2(this.pointsMax),
            ScoreLost: max2(this.pointsLost),
            ScorePending: max2(this.pointsPending),
        };
    }

    public readonly toolTip = ko.pureComputed(() => {
        return formatMessage(i18next.t(['widgets.score.SCOREGAINED_OF_SCOREMAX_POINTS_WERE_ACHIEVED']), this.textParams());
    });
    public readonly text = ko.pureComputed(() => {
        return formatMessage(i18next.t(['widgets.score.SCOREGAINED_POINTS_OF_SCOREMAX']), this.textParams());
    });
    public readonly gainedLeft = ko.pureComputed(() => {
        return '0%';
    });
    public readonly gainedWidth = ko.pureComputed(() => {
        const retVal = this.pointsGained * 100 / this.pointsMax;
        if (isNaN(retVal)) {
            return '0%';
        }
        return Math.round(retVal) + '%';
    });

    public readonly lostLeft = ko.pureComputed(() => {
        const retVal = 100 - this.pointsLost * 100 / this.pointsMax;
        if (isNaN(retVal)) {
            return '100%';
        }
        return Math.round(retVal) + '%';
    });
    public readonly lostWidth = ko.pureComputed(() => {
        const retVal = this.pointsLost * 100 / this.pointsMax;
        if (isNaN(retVal)) {
            return '0%';
        }
        return Math.round(retVal) + '%';
    });
    public readonly pendingLeft = ko.pureComputed(() => {
        return this.gainedWidth();
    });
    public readonly pendingWidth = ko.pureComputed(() => {
        const retVal = this.pointsPending * 100 / this.pointsMax;
        if (isNaN(retVal)) {
            return '0%';
        }
        return Math.round(retVal) + '%';
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

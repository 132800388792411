import type DevExpress from 'devextreme/bundles/dx.all';
import { saveAs } from 'file-saver';
import * as ko from 'knockout';
import { DxWidget } from '../../AbstractWidget';
import { datagrid, datagridButton, refreshDx } from '../../dx_helper';
import { formatBytes, metadataAsString } from '../../format';
import * as API from '../../its-itembank-api.g';
import { legacyPushPull } from '../../ui/docmanager';
import { ServerConnection } from '../../ui/RestAPI';
import { UIAction } from '../../ui/uiAction';
import * as i18n from './../../i18n/i18n';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_assetmanager_data']>>;
const WIDGET_NAME = 'widgets-assetmanager';

export interface IParams {
    docRef: string;
    docType: API.Doctype;
}

interface IGridEntry {
    id: string;
    hrefResolved: string;
    name: string;
    size: number;
    dimension: string;
    altText: string;
}

export class ViewModel extends DxWidget {
    constructor(readonly params: IParams) {
        super();
    }

    private readonly grid = ko.observable<DevExpress.ui.dxDataGrid>();
    private readonly _storeData: IGridEntry[] = [];

    public readonly gridOptions = ko.pureComputed(() => {
        const retVal = datagrid({
            WIDGET_NAME,
            widget: this,
            gridVar: this.grid,
            discriminator: 'v3',
            config: {
                onRowRemoving: e => {
                    e.cancel = (async () => {
                        await legacyPushPull(() => ServerConnection.api.ui_assetmanager_remove({
                            docType: this.params.docType,
                            docRef: this.params.docRef,
                            attachmentId: e.key
                        }));
                    })();
                },
                onRowUpdating: e => {
                    e.cancel = (async () => {
                        console.dir(e);
                        await ServerConnection.api.ui_assetmanager_update({
                            docType: this.params.docType,
                            docRef: this.params.docRef,
                            data: {
                                name: e.key,
                                altText: {
                                    xnone: e.newData.altText
                                }
                            }
                        });
                    })();
                },
                keyExpr: 'name',
                dataSource: this._storeData,
                columns: [],
                editing: {
                    allowAdding: false,
                    allowUpdating: true,
                    allowDeleting: true,
                    mode: 'cell',
                },
                selection: {
                    mode: 'single',
                },

            }
        });
        retVal.columns.push({
            name: 'name',
            dataField: 'name',
            caption: i18next.t(['widgets.assetmanager.NAME']),
            allowEditing: false,
        });
        retVal.columns.push({
            name: 'alttext',
            dataField: 'altText',
            caption: i18next.t(['widgets.assetmanager.ALTTEXT']),
            allowEditing: true,
        });
        retVal.columns.push({
            name: 'size',
            dataField: 'size',
            format: (value: number) => formatBytes(value),
            caption: i18next.t(['widgets.assetmanager.SIZE']),
            allowEditing: false,
        });
        retVal.columns.push({
            name: 'dimension',
            dataField: 'dimension',
            caption: i18n.t(['widgets.assetmanager.DIMENSION']),
            allowEditing: false,
        });
        retVal.columns.push({
            visible: false,
            name: 'hrefResolved',
            dataField: 'hrefResolved',
            caption: i18next.t(['widgets.assetmanager.URI']),
            allowEditing: false,
        });
        retVal.columns.push({
            type: 'buttons',
            buttons: [
                datagridButton({
                    text: i18next.t(['widgets.assetmanager.DOWNLOAD']),
                    action: this.actionDownload,
                }),
                'edit',
                'cancel',
                'save',
                'delete',
            ]
        });
        return retVal;
    });

    public readonly actionDownload = new UIAction<IGridEntry>(undefined, async (e, args) => {
        saveAs(args.hrefResolved, args.name);
    });


    public getFileUploaderOptions(): DevExpress.ui.dxFileUploader.Properties {

        const retVal: DevExpress.ui.dxFileUploader.Properties = {
            uploadMode: 'useButtons',
            multiple: true,
            uploadMethod: 'POST',
            onValueChanged: e => {
                const uploadUrl = ServerConnection.getDataUrl(`attachment/${this.params.docType}/${this.params.docRef}`, {}, true);
                e.component.option('uploadUrl', uploadUrl);
            },
            onUploaded: e => {
                void legacyPushPull();
            }
        };
        return retVal;
    }


    public async OnRefresh() {
        const data = await ServerConnection.api.ui_assetmanager_data({
            docType: this.params.docType,
            docRef: this.params.docRef
        });
        this._storeData.splice(0, this._storeData.length);

        for (const file of data.documents.get.attachments) {
            this._storeData.push({
                id: file.id,
                name: file.name,
                size: file.size,
                hrefResolved: ServerConnection.getDataUrl(file.hrefResolved),
                dimension: metadataAsString(file),
                altText: file?.altText?.defaultValue || '',
            });
        }

        await refreshDx(this);
    }
    public readonly uploadFileTitle = ko.pureComputed(() => i18next.t(['widgets.assetmanager.UPLOAD_ASSET']));

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

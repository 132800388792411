import * as ko from 'knockout';
import { dir, log } from '../../../debug';
import { getGolemData, golemMatch, isStringArray } from '../../../Gremlin';
import { DONE } from '../../../helper';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import * as ARRAYHELPER from '../../../new_array';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import { translate } from '../../model/ItemDataModel';
import { OrderingSelectionData, OrderingSelectionData_Answer } from '../../model/orderingselection/OrderingSelectionData';
import { GetSession } from '../../model/session';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './orderingselection.html.g';

const WIDGET_NAME = 'itemdefinition-kosovo-orderingselection';

interface IAnswerListItem {
    id: string;
    value: string;
}
export type IParams = IItemDefinitionWidgetParams;

class AnswerItem {
    public readonly id: string;
    public readonly latinId: string;
    public readonly value: string;
    constructor(readonly model: MyModel, readonly data: OrderingSelectionData_Answer) {
        this.id = data.id;
        this.latinId = ARRAYHELPER.greekToLatin(this.id);
        this.value = data.value;
    }

    public readonly isSelected = ko.pureComputed(() => {
        return this.model.selectedAnswerId() === this.id;
    });
    public readonly click = new UIAction(undefined, async () => {
        await this.model.selectAnswer.intent(this.id);
    });
    public readonly select = new UIAction(undefined, async () => {
        this.model.selectedAnswerId(this.id);
    });
}

export class MyModel extends AbstractItemDefinition {
    public itemId: string;
    public itemDocId: string;
    public sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);
    public readonly answers = new Map<string, AnswerItem>();
    public readonly data: OrderingSelectionData;

    constructor(private params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;
        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof OrderingSelectionData)) {
            throw new Error();
        }
        this.data = data;
        for (const answer of data.answers.map(x => new AnswerItem(this, x))) {
            this.answers.set(answer.id, answer);
        }

    }


    private gremlinState: {
        targetOrder: string[]
    };
    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        if (!this.gremlinState) {
            const golemData = getGolemData(this.itemId);
            let targetOrder: string[];
            if (isStringArray(golemData)) {

                const options = this.data.answers.map(x => ({
                    key: x.id,
                    value: this.data.answers.find(a => a.id === x.id).value
                }));
                dir({
                    options,
                    golemData
                });
                targetOrder = golemData.map(key => golemMatch(options, key));
            }
            if (!targetOrder) {
                targetOrder = ARRAYHELPER.shuffleArray(this.data.answers.map(x => x.id));
            }
            this.gremlinState = {
                targetOrder
            };
        }
        for (const id of this.gremlinState.targetOrder) {
            const item = this.shuffledAnswers().find(x => x.id === id);
            if (!item) {
                continue;
            }
            await item.click.invoke(undefined, true);
            return true;
        }
        for (let nWord = 0; nWord < this.gremlinState.targetOrder.length; ++nWord) {
            const ans = this.selectedAnswers();
            if (ans[nWord].id === this.gremlinState.targetOrder[nWord]) {
                continue;
            }
            const pos = ans.findIndex(x => x.id === this.gremlinState.targetOrder[nWord]);
            const p = ans[pos];
            if (!p.isSelected()) {
                await p.select.invoke(undefined, true);
                return true;
            }
            const moveUp = pos - nWord;
            if (moveUp > 0) {
                await this.moveUp.invoke(undefined, true);
                return true;
            }
        }
        return false;
    }



    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);
        await this.OnRefresh();
        this.loaded(true);
    }

    public async OnReset() {
        this.selectedAnswerId(undefined);
        return DONE;
    }

    public readonly moveVisible = ko.pureComputed(() => {
        return !!this.selectedAnswerId();
    });

    public selectAnswer = new UIAction<string>(undefined, async (e, answerId) => {
        this.selectedAnswerId(answerId);
        await this.data.select(answerId);
    });

    public readonly selectedAnswerId = ko.observable('');
    public readonly shuffledAnswers = ko.pureComputed(() => {
        return this.data.availableAnswers().map(id => this.answers.get(id));
    });

    public readonly selectedAnswers = ko.pureComputed(() => {
        return this.data.selectedAnswers().map(id => this.answers.get(id));
    });

    public readonly unselectVisible = ko.pureComputed(() => {
        return this.selectedAnswers() && this.selectedAnswers().length > 0;
    });
    public readonly unselectAvailable = ko.pureComputed(() => {
        return !!this.selectedAnswerId();
    });
    public readonly unselectCurrentAnswer = new UIAction(undefined, async () => {
        await this.data.deselect(this.selectedAnswerId());
        this.selectedAnswerId('');
    });

    public readonly navVisible = ko.pureComputed(() => {
        return this.data.selectedAnswers().length >= 2;
    });

    public readonly isMovableUp = ko.pureComputed(() => {
        const pos = this.selectedAnswers().findIndex(x => x.id === this.selectedAnswerId());
        return pos > 0;
    });
    public readonly moveUp = new UIAction(undefined, async () => {
        await this.data.moveUp(this.selectedAnswerId());
    });
    public readonly isMovableDown = ko.pureComputed(() => {
        const pos = this.selectedAnswers().findIndex(x => x.id === this.selectedAnswerId());
        return pos >= 0 && pos < this.selectedAnswers().length - 1;
    });
    public readonly moveDown = new UIAction(undefined, async () => {
        await this.data.moveDown(this.selectedAnswerId());
    });

    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);

    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());
    public readonly correctOrderText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.orderingselection.CORRECT_ORDER']);
    });

    public readonly correctAnswerIdString = ko.pureComputed(() => {
        return this.data.rawData.osCorrectAnswer.map(val => ARRAYHELPER.greekToLatin(val.answerId)).join(' ');
    });

    public readonly answerList = ko.pureComputed(() => {
        const retVal: Array<IAnswerListItem> = [];
        this.data.rawData.osCorrectAnswer.map(val => {
            retVal.push({
                id: ARRAYHELPER.greekToLatin(val.answerId),
                value: translate(val.value, this.params),
            });
        });
        return retVal;
    });

    public readonly distractorsText = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.orderingselection.DISTRACTORS']);
    });

    public readonly distractorList = ko.pureComputed(() => {
        const retVal: Array<IAnswerListItem> = [];
        this.data.rawData.distractorAnswers.map(val => {
            retVal.push({
                id: ARRAYHELPER.greekToLatin(val.id.substr(-1)),
                value: translate(val.value, this.params),
            });
        });
        return retVal;
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

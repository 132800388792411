import type DevExpress from 'devextreme/bundles/dx.all';
import { saveAs } from 'file-saver';
import * as ko from 'knockout';
import { DxWidget } from '../../../AbstractWidget';
import { dir } from '../../../debug';
import { AS, refreshDx } from '../../../dx_helper';
import * as API from '../../../its-itembank-api.g';
import { ServerConnection } from '../../RestAPI';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';
import { IParams, WIDGET_NAME } from './route';
import { htmlString } from './widget.html.g';

type Q = Awaited<ReturnType<API.Sdk['ui_superuser_statisticexport_data']>>;
type STATISTIC = Q['statisticexportconfig']['all'][0];

export class ViewModel extends DxWidget {

    public readonly loaded = ko.observable(false);

    public readonly noExportConfigurationText = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.statisticexport.NO_EXPORT_CONFIGURATION_DEFINED']);
    });

    public readonly label = {
        header: ko.pureComputed(() => i18n.t(['ui.superuser.statisticexport.EXPORT_STATISTIC'])),
        configuration: ko.pureComputed(() => i18n.t(['ui.superuser.statisticexport.CONFIGURATION'])),
        file: ko.pureComputed(() => i18n.t(['ui.superuser.statisticexport.FILE'])),
        language: ko.pureComputed(() => i18n.t(['ui.superuser.statisticexport.LANGUAGE'])),
        save: ko.pureComputed(() => i18n.t(['ui.superuser.statisticexport.SAVE'])),
    };

    constructor(readonly params: IParams) {
        super();
    }
    private readonly _configurations: STATISTIC[] = [];
    public readonly configuration = ko.observable('');
    public readonly configurationOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxSelectBox.Properties = {
            value: <any>this.configuration,
            dataSource: this._configurations,
            valueExpr: 'configurationId',
            displayExpr: (item: STATISTIC) => `${item.title} (${item.configurationId})`
        };
        return retVal;
    });

    private file: Blob = undefined;
    public readonly hasFile = ko.observable(false);
    public readonly language = ko.observable('');

    public readonly saveFileButton = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxButton.Properties = {
            onClick: () => {
                saveAs(this.file, 'export.zip');
                //file_saver.fileSaver.saveAs('export', 'EXCEL', this.file);
            },
            text: i18next.t(['ui.superuser.statisticexport.SAVE_FILE']),
        };
        return retVal;
    });
    public readonly languageParams = ko.pureComputed(() => {
        return AS<DevExpress.ui.dxTextBox.Properties>({
            value: <any>this.language
        });
    });

    public readonly uploadUrl = ko.pureComputed(() => {
        return ServerConnection.getDataUrl(`statistic-export/${this.configuration()}?lang=${this.language()}`, {
        }, true);
    });

    public readonly uploaderParams = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxFileUploader.Properties = {
            minFileSize: 1024,
            maxFileSize: 5 * 1024 * 1024,
            allowedFileExtensions: ['.xlsx'],
            name: 'files[]',
            uploadMethod: 'POST',
            uploadMode: 'useButtons',
            uploadUrl: <any>this.uploadUrl,
            onUploadStarted: x => {
                x.request.responseType = 'blob';
                this.file = undefined;
                this.hasFile(false);
            },
            onUploaded: x => {
                dir(x.request.responseType);
                this.file = x.request.response;
                this.hasFile(true);
            }
        };
        return retVal;
    });

    public readonly hasData = ko.observable(false);
    public async OnRefresh() {
        await super.OnRefresh();
        const config = await ServerConnection.api.ui_superuser_statisticexport_data({
        });
        this._configurations.splice(0, this._configurations.length, ...config.statisticexportconfig.all);
        this.hasData(this._configurations.length > 0);
        const c = this.configuration();
        if (!this._configurations.length) {
            this.configuration('');
        } else {
            if (!this._configurations.find(x => x.configurationId === c)) {
                this.configuration(this._configurations[0].configurationId);
            }
        }
        await refreshDx(this);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});
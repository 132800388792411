import * as ko from 'knockout';
import { log } from '../../debug';
import { getGolemData, golemMatch } from '../../Gremlin';
import { IItemDefinitionWidgetParams, ItemMode } from '../../model/interfaces';
import { getRandomEntry } from '../../new_array';
import { UIAction } from '../../ui/uiAction';
import { AbstractItemDefinition } from '../base_itemdefinition';
import { translate } from '../model/ItemDataModel';
import { GetSession } from '../model/session';
import { SurveySingleChoiceTextData, SurveySingleChoiceTextData_Answer } from '../model/surveysinglechoicetext/SurveySingleChoiceTextData';
import * as i18next from './../../i18n/i18n';
import { htmlString } from './singlechoicetext.html.g';

const WIDGET_NAME = 'itemdefinition-surveysinglechoicetext';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';

class VMAnswer {
    constructor(private readonly model: MyModel, public readonly data: SurveySingleChoiceTextData_Answer) {
        if (data.hasTextEntry) {
            this.textEntry = model.data.userText;
        }
        this.hasTextEntry = data.hasTextEntry;
    }

    public readonly textEntryPlaceholder = ko.pureComputed(() => i18next.t(['itemdefinition.surveysinglechoicetext.OTHER_ANSWER']));
    public readonly hasTextEntry: boolean;
    public readonly id = ko.pureComputed(() => this.data.id);
    public readonly value = ko.pureComputed(() => this.data.value);

    public readonly isSelected = ko.pureComputed(() => this.data.isSelected());

    public readonly select = new UIAction(undefined, async () => {
        await this.model.clickAnswer.invoke(this.id(), true);
    });

    public readonly textEntry: ko.Observable<string>;
}
export type IParams = IItemDefinitionWidgetParams;

export class MyModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly mode = ko.observable<ItemMode>();

    public readonly loaded = ko.observable(false);

    public readonly surveyOptionText = ko.pureComputed(() => {
        switch (this.data.option) {
            case 'Required':
                return '';
            case 'Optional':
                return i18next.t(['itemdefinition.surveysinglechoicetext.YOU_DO_NOT_NEED_TO_ANSWER_THIS_ITEM']);
            default:
                return '';
        }
    });

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;
        this.mode(params.mode || 'INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SurveySingleChoiceTextData)) {
            throw new Error();
        }
        this.data = data;

    }

    public readonly data: SurveySingleChoiceTextData;

    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: async () => {
                if (!this.loaded()) {
                    return true;
                }
                const answers = this.shuffledAnswers();
                const golemData = getGolemData(this.itemId);
                if (!answers.some(x => x.isSelected())) {
                    if (typeof golemData === 'string') {
                        const answerId = golemMatch(answers.map(x => ({
                            key: x.id(),
                            value: translate(x.data.rawData.text, this.params),
                        })), golemData);
                        if (answerId) {
                            log(`Golem says we should use answer ${answerId}`);
                            const a = answers.find(x => x.id() === answerId);
                            await a.select.invoke();
                            return true;
                        }
                    }
                    await getRandomEntry(answers).select.invoke();
                    return true;
                }
                return false;
            }
        });

        await super.initialize();
        const mode = this.mode();
        if (mode === 'EDIT') {
            throw new Error(`${WIDGET_NAME} - no edit mode supported!`);
        }
        log(`${WIDGET_NAME} initialize in mode ${mode} (${this.params.mode}) (item: ${this.itemId}`);

        await this.OnRefresh();
        this.loaded(true);
    }


    public readonly hasImage = ko.pureComputed(() => this.data.hasImage);
    public readonly imgUrl = ko.pureComputed(() => this.data.imgUrl);
    public readonly imgAlt = ko.pureComputed(() => this.data.imgAlt);
    public readonly shuffledAnswers = ko.pureComputed(() => {
        return this.data.answers.map(x => new VMAnswer(this, x));
    });
    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);

    public readonly headerText = ko.pureComputed(() => this.data.headerText);
    public readonly score = ko.pureComputed(() => this.data.meta.accumulatedScore());

    public readonly clickAnswer = new UIAction<string>(undefined, async (e, answerId) => {
        await this.data.setAnswer(answerId);
    });

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

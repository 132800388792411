import type DevExpress from 'devextreme/bundles/dx.all';
import { FormBuilder } from '../../../dxHelper/formBuilder';
import { selectBoxDS } from '../../../dx_helper';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import * as API from '../../../its-itembank-api.g';
import { ButtonVisibility, ContinuationModeEnum, ContinueWithEnum, DisplayItemIdEnum, DisplayObjectiveIdEnum, DisplayProgress, DisplayTime, ExaminationTypeEnum, ItemHostEnum, IUpsertTestDefinitionProfile, NavigationStyleEnum, PdfReportSettingsIncludeItems, ResetOnNavigateEnum, ResultPageEMail, ResultPagePdf, ShowImmediateResult, ShowItemMaxScore } from '../../../its-itembank-api.g';
import { REVISABLE_ITEMS_THRESHOLD } from '../../../magic';
import * as DS from './../../../enums/datasource';
import * as i18n from './../../../i18n/i18n';
import * as i18next from './../../../i18n/i18n';

export type Q = Awaited<ReturnType<API.Sdk['ui_superuser_profileedit_data']>>;
export type PROFILE = Q['TestDefinitionProfile']['byId'];

export function xnone(data: string) {
    if (typeof data !== 'string') {
        return undefined;
    }
    return {
        'xnone': data
    };
}
function DIRTY<U>(v: unknown): U {
    return undefined;
}

type U = Omit<Omit<IUpsertTestDefinitionProfile, "id">, "docRef"> & { id?: string };
//type Omit<T, K>=Pick<T, Exclude<keyof T, K>>;
export class MyFormBuilder extends FormBuilder<PROFILE, U>  {
    constructor(readonly vm: {
        gradeGridOptions: ko.Computed<DevExpress.ui.dxDataGrid.Properties>;
        helpVisible: ko.Observable<boolean>;
    }) {
        super();
    }
    protected onInit() {
        super.onInit();
        this.formOptions.colCount = 1;
        /*
        this.formOptions.minColWidth = 200;
        
        this.formOptions.screenByWidth = (width: number) => {
            const elem = this.form?.element();
            const elemWidth = elem?.width() || width;
            //if (this.vm.helpVisible()) {
            //    return 'sm';
            //}
            if (elemWidth > 1000) {
                return 'lg';
            }
            return 'sm'

        }
        */
        this.initProfileForm();
        this.initNavigationForm();
        this.initWelcomePage();
        this.initExecutionPage();
        this.initResultPage();
        this.initPDF();
        this.initEMail();
        this.initGrades();
        this.initSystem();
    }
    private initSystem() {
        const g2 = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.SYSTEM']),
            colCount: 2
        });
        this.addCheckbox(g2, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.DELETED'])
            },
            dataField: 'isDeleted',
        }, DIRTY);

        this.addTextBox(g2, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.INTERNAL_ID'])
            },
            dataField: '_id',
            editorOptions: {
                elementAttr: {
                    class: 'itsr3-uitest-hide',
                },
            },
        });
    }
    private initExecutionPage() {
        const gExecutionPage = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.EXECUTION_PAGE']),
            colCount: 1,
        });
        this.addSelectBox<DisplayTime>(gExecutionPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.DISPLAY_TIME'])
            },
            dataField: 'executionPage.displayTime',
            editorOptions: selectBoxDS(DS.TimeDisplay()),
        }, DIRTY);
        this.addTextBox(gExecutionPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.TIME_LABEL'])
            },
            dataField: 'executionPage.timeLabel.value',
        }, DIRTY);
        this.addSelectBox<DisplayProgress>(gExecutionPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.DISPLAY_PROGRESS'])
            },
            dataField: 'executionPage.displayProgress',
            editorOptions: selectBoxDS(DS.ProgressDisplay()),
        }, DIRTY);
        this.addTextBox(gExecutionPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.PROGRESS_LABEL'])
            },
            dataField: 'executionPage.progressLabel.value',
        }, DIRTY);
        this.addSelectBox<ShowItemMaxScore>(gExecutionPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.SHOW_ITEM_MAX_SCORE']),
            },
            dataField: 'executionPage.showItemMaxScore',
            editorOptions: selectBoxDS(DS.ShowItemMaxScore())

        }, DIRTY);

        const gExecutionPageButtons = this.addGroup(gExecutionPage, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.BUTTONS']),
            colCount: 2,
            colSpan: 2,
        });
        this.addSelectBox<ButtonVisibility>(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.SUBMIT_BUTTON_VISIBILITY'])
            },
            dataField: 'executionPage.submitButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);

        this.addTextBox(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.SUBMIT_BUTTON_LABEL'])
            },
            dataField: 'executionPage.submitButton.label.value',
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.POSTPONE_BUTTON_VISIBILITY'])
            },
            dataField: 'executionPage.postponeButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.POSTPONE_BUTTON_LABEL'])
            },
            dataField: 'executionPage.postponeButton.label.value',
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.RESET_BUTTON_VISIBILITY'])
            },
            dataField: 'executionPage.resetButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.RESET_BUTTON_LABEL'])
            },
            dataField: 'executionPage.resetButton.label.value',
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.FINISH_BUTTON_VISIBILITY'])
            },
            dataField: 'executionPage.finishButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.FINISH_BUTTON_LABEL'])
            },
            dataField: 'executionPage.finishButton.label.value',
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.PREVIOUS_BUTTON_VISIBILITY'])
            },
            dataField: 'executionPage.previousButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.PREVIOUS_BUTTON_LABEL'])
            },
            dataField: 'executionPage.previousButton.label.value',
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.NEXT_BUTTON_VISIBILITY'])
            },
            dataField: 'executionPage.nextButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.NEXT_BUTTON_LABEL'])
            },
            dataField: 'executionPage.nextButton.label.value',
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OVERVIEW_BUTTON_VISIBILITY'])
            },
            dataField: 'executionPage.overviewButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gExecutionPageButtons, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OVERVIEW_BUTTON_LABEL'])
            },
            dataField: 'executionPage.overviewButton.label.value',
        }, DIRTY);

        const gExecutionPageWindowSize = this.addGroup(gExecutionPage, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.WINDOW_SIZE']),
            colCount: 2,
            colSpan: 2,
        });
        this.addNumberBox(gExecutionPageWindowSize, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.MINIMUM_WIDTH'])
            },
            dataField: 'executionPage.minWidth',
            editorOptions: {
                min: 0,
                max: 4000,
                format: { type: 'decimal', precision: 0 },
            },
        }, DIRTY);
        this.addNumberBox(gExecutionPageWindowSize, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.MINIMUM_HEIGHT'])
            },
            dataField: 'executionPage.minHeight',
            editorOptions: {
                min: 0,
                max: 4000,
                format: { type: 'decimal', precision: 0 },
            },
        }, DIRTY);

        const gExecutionPageImmediateResult = this.addGroup(gExecutionPage, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.IMMEDIATE_RESULT']),
            colCount: 2,
            colSpan: 2,
        });
        this.addSelectBox<ShowImmediateResult>(gExecutionPageImmediateResult, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.SHOW_IMMEDIATE_RESULT'])
            },
            dataField: 'executionPage.showImmediateResult',
            editorOptions: selectBoxDS(DS.ShowImmediateResult())
        }, DIRTY);
        this.addNumberBox(gExecutionPageImmediateResult, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.SHOW_IMMEDIATE_RESULT_FOR_X_SECONDS'])
            },
            dataField: 'executionPage.immediateResultSeconds',
            editorOptions: {
                min: 0,
                max: 30,
                format: { type: 'decimal' },
            },
        }, DIRTY);
        this.addTextBox(gExecutionPageImmediateResult, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.IMMEDIATE_RESULT_TEXT'])
            },
            dataField: 'executionPage.immediateResultText.value',
        }, DIRTY);
    }
    private initWelcomePage() {
        const gWelcomePage = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.WELCOME_PAGE']),
            colCount: 2,
            items: []
        });
        this.addTextArea(gWelcomePage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.TEXT'])
            },
            dataField: 'welcomePage.text.value',
            colSpan: 2,
            editorOptions: {
                autoResizeEnabled: true,
            },
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gWelcomePage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.START_BUTTON_VISIBILITY'])
            },
            dataField: 'welcomePage.startButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gWelcomePage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.START_BUTTON_LABEL'])
            },
            dataField: 'welcomePage.startButton.label.value',
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gWelcomePage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.CANCEL_BUTTON_VISIBILITY'])
            },
            dataField: 'welcomePage.cancelButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gWelcomePage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.CANCEL_BUTTON_LABEL'])
            },
            dataField: 'welcomePage.cancelButton.label.value',
        }, DIRTY);
    }
    private initProfileForm() {
        const g1 = this.addGroup(this.formOptions, {
            colCount: 2,
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.PROFILE'])
        });
        this.addTextBox(g1, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.ID'])
            },
            dataField: 'id',
        }, DIRTY);

        this.addTextBox(g1, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.TITLE'])
            },
            dataField: 'title',
        }, DIRTY);
    }
    private initResultPage() {
        const gResultPage = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.RESULT_PAGE']),
            colCount: 2,
        });
        this.addTextArea(gResultPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.TEXT'])
            },
            dataField: 'resultPage.text.value',
            colSpan: 2,
            editorOptions: {
                autoResizeEnabled: true,
            },
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gResultPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.CLOSE_BUTTON_VISIBILITY'])
            },
            dataField: 'resultPage.closeButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gResultPage, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.CLOSE_BUTTON_LABEL'])
            },
            dataField: 'resultPage.closeButton.label.value',
        }, DIRTY);


        const gResultPageObjectiveOverview = this.addGroup(gResultPage, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVES']),
            colSpan: 2,
        });
        this.addTextBox(gResultPageObjectiveOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_HEADER'])
            },
            dataField: 'resultPage.objectiveHeader.value',
        }, DIRTY);

        this.addTextBox(gResultPageObjectiveOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_TEXT'])
            },
            dataField: 'resultPage.objectiveText.value',
        }, DIRTY);

        this.addNumberBox(gResultPageObjectiveOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_MIN_DEPTH'])
            },
            dataField: 'resultPage.objectiveMinDepth',
            editorOptions: {
                min: 0,
                max: 10
            }
        }, DIRTY);
        this.addNumberBox(gResultPageObjectiveOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_MAX_DEPTH'])
            },
            dataField: 'resultPage.objectiveMaxDepth',
            editorOptions: {
                min: 0,
                max: 10
            }
        }, DIRTY);
        this.addSelectBox<DisplayObjectiveIdEnum>(gResultPageObjectiveOverview, {
            label: {
                text: i18n.t(['ui.superuser.profileedit.myformbuilder.DISPLAY_OBJECTIVE_ID'])
            },
            dataField: 'resultPage.displayObjectiveId',
            editorOptions: selectBoxDS(DS.DisplayObjectiveId())
        }, DIRTY);

        const gResultPageItemOverview = this.addGroup(gResultPage, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.ITEM_OVERVIEW']),
            colSpan: 2,
        });
        this.addTextBox(gResultPageItemOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.ITEM_HEADER'])
            },
            dataField: 'resultPage.itemHeader.value',
        }, DIRTY);
        this.addTextBox(gResultPageItemOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.ITEM_TEXT'])
            },
            dataField: 'resultPage.itemText.value',
        }, DIRTY);

        this.addSelectBox<DisplayItemIdEnum>(gResultPageItemOverview, {
            label: {
                text: i18n.t(['ui.superuser.profileedit.myformbuilder.DISPLAY_ITEM_ID'])
            },
            dataField: 'resultPage.displayItemId',
            editorOptions: selectBoxDS(DS.DisplayItemId())
        }, DIRTY);
    }
    private initEMail() {
        const gEMail = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.E_MAIL']),
            colCount: 2,
        });
        this.addSelectBox<ResultPageEMail>(gEMail, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.E_MAIL'])
            },
            dataField: 'resultPage.email',
            editorOptions: selectBoxDS(DS.ResultPageEMail())
        }, DIRTY);

        this.addTextBox(gEMail, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.E_MAIL_LABEL'])
            },
            dataField: 'resultPage.emailLabel.value',
        }, DIRTY);
        this.addTextArea(gEMail, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.E_MAIL_TEXT'])
            },
            dataField: 'resultPage.emailText.value',
            editorOptions: {
                autoResizeEnabled: true,
            },
        }, DIRTY);
        this.addTextBox(gEMail, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.E_MAIL_SUBJECT'])
            },
            colSpan: 2,
            dataField: 'pdfReportSettings.emailSubject.value',
        }, DIRTY);
        this.addTextArea(gEMail, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.E_MAIL_BODY'])
            },
            dataField: 'pdfReportSettings.emailBody.value',
            colSpan: 2,
            editorOptions: {
                autoResizeEnabled: true,
            },
        }, DIRTY);
    }
    private initPDF() {
        const gPDF = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.PDF']),
            colCount: 3,
        });
        this.addSelectBox<ResultPagePdf>(gPDF, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.PDF_ENABLED'])
            },
            dataField: 'resultPage.pdf',
            editorOptions: selectBoxDS(DS.ResultPagePDF())
        }, DIRTY);
        this.addSelectBox<ButtonVisibility>(gPDF, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.PDF_BUTTON_VISIBILITY'])
            },
            dataField: 'resultPage.pdfButton.visibility',
            editorOptions: selectBoxDS(DS.ButtonVisibility())
        }, DIRTY);
        this.addTextBox(gPDF, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.PDF_BUTTON_LABEL'])
            },
            dataField: 'resultPage.pdfButton.label.value',
        }, DIRTY);
        this.addTextArea(gPDF, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.TEXT'])
            },
            dataField: 'pdfReportSettings.text.value',
            colSpan: 3,
            editorOptions: {
                autoResizeEnabled: true,
            },
        }, DIRTY);

        const gObjectives = this.addGroup(gPDF, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVES']),
            colSpan: 3,
        });

        this.addTextBox(gObjectives, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_HEADER'])
            },
            dataField: 'pdfReportSettings.objectiveHeader.value',
            editorOptions: {}
        }, DIRTY);
        this.addTextBox(gObjectives, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_TEXT'])
            },
            dataField: 'pdfReportSettings.objectiveText.value',
            editorOptions: {}
        }, DIRTY);
        this.addNumberBox(gObjectives, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_MIN_DEPTH'])
            },
            dataField: 'pdfReportSettings.objectiveMinDepth',
            editorOptions: {
                min: 0,
                max: 10
            }
        }, DIRTY);
        this.addNumberBox(gObjectives, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.OBJECTIVE_MAX_DEPTH'])
            },
            dataField: 'pdfReportSettings.objectiveMaxDepth',
            editorOptions: {
                min: 0,
                max: 10
            }
        }, DIRTY);


        this.addSelectBox<DisplayObjectiveIdEnum>(gObjectives, {
            label: {
                text: i18n.t(['ui.superuser.profileedit.myformbuilder.DISPLAY_OBJECTIVE_ID'])
            },
            dataField: 'pdfReportSettings.displayObjectiveId',
            editorOptions: selectBoxDS(DS.DisplayObjectiveId())
        }, DIRTY);

        const gItemOverview = this.addGroup(gPDF, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.ITEM_OVERVIEW']),
            colSpan: 3
        });

        this.addTextBox(gItemOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.ITEM_HEADER'])
            },
            dataField: 'pdfReportSettings.itemHeader.value',
        }, DIRTY);
        this.addTextBox(gItemOverview, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.ITEM_TEXT'])
            },
            dataField: 'pdfReportSettings.itemText.value',
        }, DIRTY);

        this.addSelectBox<DisplayItemIdEnum>(gItemOverview, {
            label: {
                text: i18n.t(['ui.superuser.profileedit.myformbuilder.DISPLAY_ITEM_ID'])
            },
            dataField: 'pdfReportSettings.displayItemId',
            editorOptions: selectBoxDS(DS.DisplayItemId())
        }, DIRTY);

        const gItemDetails = this.addGroup(gPDF, {
            caption: i18n.t(['ui.superuser.profileedit.myformbuilder.ITEM_DETAILS']),
            colSpan: 3
        });
        this.addSelectBox<PdfReportSettingsIncludeItems>(gItemDetails, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.INCLUDE_ITEMS']),
            },
            dataField: 'pdfReportSettings.includeItems',
            editorOptions: selectBoxDS(DS.ResultPageIncludeItems())
        }, DIRTY);

        this.addTextBox(gItemDetails, {
            label: {
                text: i18n.t(['ui.superuser.profileedit.myformbuilder.ITEM_DETAIL_CAPTION']),
            },
            dataField: 'pdfReportSettings.itemDetailCaption.value',
        }, DIRTY);
    }
    private initGrades() {
        const gGrades = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.GRADES']),
            colCount: 1,
            items: []
        });
        this.addSelectBox<API.ScoreRoundingModeEnum>(gGrades, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.SCORE_ROUNDING_MODE']),
            },
            editorOptions: Object.assign(selectBoxDS(DS.ScoreRoundingMode()), {
                searchEnabled: false,
            }),
            dataField: 'scoreRoundingMode',
        }, DIRTY);
        this.addDataGrid(gGrades, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.GRADES'])
            },
            editorOptions: this.vm.gradeGridOptions()
        });
    }
    private initNavigationForm() {
        const gNavigation = this.addGroup(this.formOptions, {
            caption: i18next.t(['ui.superuser.profileedit.myformbuilder.NAVIGATION_AND_LAYOUT']),
            colCount: 2,
            items: []
        });
        this.addSelectBox<ContinuationModeEnum>(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.CONTINUATION_MODE'])
            },
            dataField: 'continuationMode',
            editorOptions: selectBoxDS(DS.ContinuationModeString())
        }, DIRTY);
        this.addSelectBox<ContinueWithEnum>(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.CONTINUE_WITH'])
            },
            dataField: 'continueWith',
            editorOptions: selectBoxDS(DS.ContinueWithString())
        }, DIRTY);
        this.addSelectBox<ExaminationTypeEnum>(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.EXAMINATION_TYPE'])
            },
            dataField: 'examinationType',
            editorOptions: selectBoxDS(DS.ExaminationTypeString())
        }, DIRTY);
        this.addSelectBox<ItemHostEnum>(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.LAYOUT'])
            },
            dataField: 'itemHost',
            editorOptions: selectBoxDS(DS.ItemHost())
        }, DIRTY);
        this.addSelectBox<ResetOnNavigateEnum>(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.RESET_ON_NAVIGATE'])
            },
            dataField: 'resetOnNavigate',
            editorOptions: selectBoxDS(DS.ResetOnNavigate())
        }, DIRTY);
        this.addTextBox(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.MAX_TEST_TIME'])
            },
            dataField: 'maxTimeAllowed',
            editorOptions: {}
        }, DIRTY);
        this.addSelectBox<NavigationStyleEnum>(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.NAVIGATION_STYLE'])
            },
            dataField: 'navigationStyle',
            editorOptions: selectBoxDS(DS.NavigationStyle())
        }, DIRTY);
        this.addNumberBox(gNavigation, {
            label: {
                text: i18next.t(['ui.superuser.profileedit.myformbuilder.REVISABLE_ITEM_COUNT'])
            },
            dataField: 'revisableItemCount',
            editorOptions: {
                min: 0,
                max: REVISABLE_ITEMS_THRESHOLD,
            }
        }, DIRTY);
    }
}

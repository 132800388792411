import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-bulkgrading-selectsubject';

export const topTitle = ko.pureComputed(() => i18next.t(['ui.bulkgrading.EVALUATOR_BULK']));

export class SelectSubjectRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.bulkgrading.selectsubject.SUBJECTS']);
    });
    public readonly topTitle = topTitle;
    constructor(readonly widgetParams: IParamsInner) {
    }
}

export type IParamsInner = {};

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export const FACTORY = ROUTES.registerRoute({
    route: '/bulkgrading',
    toParams: () => ({}),
    fromParams: () => { },
    create: async p => {
        return new SelectSubjectRoute(undefined);
    },
});

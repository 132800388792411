import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS } from '../../../dx_helper';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { ServerConnection } from '../../../ui/RestAPI';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-typingtest-edit';

export type IParams = IItemDefinitionWidgetParams;

type Q = Awaited<ReturnType<API.Sdk['typingtext_edit_data']>>;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly loaded = ko.observable(false);
    public readonly text = ko.observable('');
    public readonly exportErrors = ko.observable('');
    public readonly exportTextLength = ko.observable('');
    public readonly exportErrorQuota = ko.observable('');
    public readonly maxErrorQuota = ko.observable(0);

    public formOptions(): DevExpress.ui.dxForm.Properties {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            items: [],
            formData: {
                text: this.text,
                maxErrorQuota: this.maxErrorQuota,
                exportErrors: this.exportErrors,
                exportTextLength: this.exportTextLength,
                exportErrorQuota: this.exportErrorQuota,
            }
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            itemType: 'simple',
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.typingtest.edit.TEXT'])
            },
            dataField: 'text',
            editorType: 'dxTextArea',
            editorOptions: AS<DevExpress.ui.dxTextArea.Properties>({
                height: 400,
            })
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            itemType: 'simple',
            label: {
                text: i18next.t(['itemdefinition.typingtest.edit.MAX_ERROR_QUOTA'])
            },
            dataField: 'maxErrorQuota',
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
                max: 100,
            }),
        }));
        const section = AS<DevExpress.ui.dxFormGroupItem>({
            itemType: 'group',
            caption: i18next.t(['itemdefinition.typingtest.edit.CUSTOM_FIELDS']),
            items: []
        });
        retVal.items.push(section);

        section.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            itemType: 'simple',
            label: {
                text: i18next.t(['itemdefinition.typingtest.edit.ERRORS']),
            },
            editorType: 'dxTextBox',
            dataField: 'exportErrors',
        }));
        section.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            itemType: 'simple',
            label: {
                text: i18next.t(['itemdefinition.typingtest.edit.TEXT_LENGTH']),
            },
            editorType: 'dxTextBox',
            dataField: 'exportTextLength',
        }));
        section.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            itemType: 'simple',
            label: {
                text: i18next.t(['itemdefinition.typingtest.edit.ERROR_QUOTA']),
            },
            editorType: 'dxTextBox',
            dataField: 'exportErrorQuota',
        }));

        return retVal;
    }

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
    }


    public readonly item = ko.observable<Q['TypingTestEdit']['get']>();


    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.typingtext_edit_data({
            itemId: this.itemId
        });

        const d = data.TypingTestEdit.get;
        if (!d) {
            return;
        }
        this.text(d.text);
        this.maxErrorQuota(d.maxErrorQuota);
        this.exportErrorQuota(d.exportErrorQuota);
        this.exportErrors(d.exportErrors);
        this.exportTextLength(d.exportTextLength);
        this.item(d);
    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.onChange(this.text, `${WIDGET_NAME}/${this.itemId}/text`, async () => {
            await ServerConnection.api.typingtest_edit_update({
                params: {
                    itemId: this.itemId,
                    text: this.text(),
                }
            });
            return DONE;
        });
        this.loaded(true);
        this.onChange(this.maxErrorQuota, `${WIDGET_NAME}/${this.itemId}/maxErrorQuota`, async () => {
            await ServerConnection.api.typingtest_edit_update({
                params: {
                    itemId: this.itemId,
                    maxErrorQuota: this.maxErrorQuota(),
                }
            });
            return DONE;
        });
        this.onChange(this.exportErrorQuota, `${WIDGET_NAME}/${this.itemId}/exportErrorQuota`, async () => {
            await ServerConnection.api.typingtest_edit_update({
                params: {
                    itemId: this.itemId,
                    exportErrorQuota: this.exportErrorQuota(),
                }
            });
            return DONE;
        });
        this.onChange(this.exportErrors, `${WIDGET_NAME}/${this.itemId}/exportErrors`, async () => {
            await ServerConnection.api.typingtest_edit_update({
                params: {
                    itemId: this.itemId,
                    exportErrors: this.exportErrors(),
                }
            });
            return DONE;
        });
        this.onChange(this.exportTextLength, `${WIDGET_NAME}/${this.itemId}/exportTextLength`, async () => {
            await ServerConnection.api.typingtest_edit_update({
                params: {
                    itemId: this.itemId,
                    exportTextLength: this.exportTextLength(),
                }
            });
            return DONE;
        });
        this.loaded(true);
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

import CustomStore from 'devextreme/data/custom_store';
import * as API from '../../../its-itembank-api.g';

type Q = Awaited<ReturnType<API.Sdk['surveycomparision_edit_data']>>;

export type OBJECTIVE = Q['item']['get']['metaData']['objectives'][0];


export class ObjectiveDS extends CustomStore {
    constructor({ itemId, fetch }: {
        itemId: string;
        fetch: () => OBJECTIVE[];
    }) {
        super({
            loadMode: 'raw',
            key: 'objectiveId',
            load: async () => fetch(),
            byKey: async (id: string) => {
                return fetch().find(x => x.objectiveId === id);
            }
        });
    }
}

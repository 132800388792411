import * as ko from 'knockout';
import * as API from '../../../its-itembank-api.g';
import { toastService } from '../../../toastService';
import { ServerConnection } from '../../RestAPI';
import * as ROUTES from '../../routes';
import { UIAction } from '../../uiAction';
import * as i18next from './../../../i18n/i18n';
//import * as R from './route.query.graphql.g';
type R = Awaited<ReturnType<API.Sdk['ui_author_itemedit_route']>>;
export const WIDGET_NAME = 'ui-author-itemedit';

export type IParams = ROUTES.IRoutableParams<IParamsInner>;
export interface IParamsInner {
    subjectDocRefId: string;
    itemDocRefId: string;
}


export class ItemEditRoute implements ROUTES.IRoute<IParamsInner> {

    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return this._data.item.get.itemId;
    });
    public readonly routeActions = ko.pureComputed(() => {
        const actions: ROUTES.IRouteAction[] = [];

        actions.push({
            icon: 'help',
            caption: i18next.t(['ui.author.itemedit.HELP']),
            action: this.showHelp
        });
        return actions;
    });



    readonly widgetParams: IParamsInner;
    private constructor(readonly _data: R) {
        this.widgetParams = {
            subjectDocRefId: _data.item.get.metaData.subject.docReferenceId,
            itemDocRefId: _data.item.get.docReferenceId
        };
    }


    public static async CREATE(itemDocRefId: string) {
        const data = await ServerConnection.api.ui_author_itemedit_route({
            itemId: itemDocRefId
        });
        const item = data.item.get;
        const route = new ItemEditRoute(data);
        return route;
    }

    private readonly showHelp = new UIAction(undefined, async () => {
        const msg = i18next.t(['ui.author.itemedit.AFTER_YOU_HAVE_FINISHED_AN_ITEM_YOU_CAN_NO_LONGER_EDIT_IT_ONLY_FINISHED_ITEMS_ARE_VISIBLE_FOR_THE_SUBJECT_COORDINATOR_TO_FINISH_AN_ITEM_YOU_HAVE_TO_CHOOSE_A_CATEGORY_AN_OBJECTIVE_AND_DEFINE_A_TITLE_UNDER_LANGUAGE_YOU_DEFINE_THE_LANGUAGE_FOR_THE_ITEM_CREATION_IF_THE_LANGUAGE_IS_SET_TO_DEFAULT_THE_ITEM_WILL_NOT_BE_TRANSLATED_FOREIGN_LANGUAGE_SUBJECTS']);

        toastService.info(msg);
    });
}

export const FACTORY = ROUTES.registerRoute<IParamsInner>({
    route: '/author/subject/:subject/item/:item',
    toParams: map => ({
        itemDocRefId: map.get('item'),
        subjectDocRefId: map.get('subject'),
    }),
    fromParams: (p, map) => {
        map.set('item', p.itemDocRefId);
        map.set('subject', p.subjectDocRefId);
    },
    create: async p => {
        return ItemEditRoute.CREATE(p.itemDocRefId);
    },
});

import * as ko from 'knockout';
import { DONE } from '../../../helper';
import * as API from '../../../its-itembank-api.g';
import { greekToLatin } from '../../../new_array';
import * as RICHTEXTHTML from '../../../richtext/html';
import { INIT, ItemDataModel, translate } from '../ItemDataModel';
import { ItemModel } from '../ItemModel';

function getData(item: INIT) {
    if (item.data.__typename === 'KosovoChoiceText_QueryItemSessionData') {
        return item.data;
    }
    throw new Error();
}

type DATA = ReturnType<typeof getData>;
type GAP = DATA['gaps'][0];
type VALUE = GAP['values'][0];

export class ChoiceTextData_PoolValue {
    public readonly latinId: string;
    constructor(readonly model: ChoiceTextData, readonly value: VALUE, readonly gap: ChoiceTextData_Gap) {
        this.latinId = greekToLatin(this.value.id.substr(-1));
    }

    public text = ko.pureComputed(() => {
        const text = translate(this.value.value, {});
        return RICHTEXTHTML.process({
            inline: true,
            html: text,
            attachments: this.model.meta.attachments,
        });
    });

    public readonly isAvailable = ko.pureComputed(() => {
        return this.value.id !== this.gap.selectedValue();
    });
    public readonly isCorrect = ko.pureComputed(() => {
        return this.value.id === this.gap.gap.correctValue;
    });
}

export class ChoiceTextData_Gap {
    public readonly gap: GAP;
    constructor(readonly model: ChoiceTextData, readonly gapId: string) {
        this.gap = this.model._data.gaps.find(val => val.id === gapId);
        this.selectedValue(this.gap.selectedValue);
    }
    public readonly selectedValue = ko.observable<string>();

    public readonly possibilities = ko.pureComputed(() => {
        return this.gap.values.map((value, index) => {
            return new ChoiceTextData_PoolValue(this.model, value, this);
        });
    });
}

export class ChoiceTextData_Text {
    constructor(readonly model: ChoiceTextData, readonly fragmentText: string) {
    }
    public readonly text = RICHTEXTHTML.process({
        inline: true,
        html: this.fragmentText,
        attachments: this.model.meta.attachments,
    });
}


export class ChoiceTextData extends ItemDataModel {
    constructor(readonly meta: ItemModel, readonly _data: DATA) {
        super();
        this.headerText = translate(_data.header, {});
        this.questionHTML = RICHTEXTHTML.process({
            html: translate(_data.question, {}),
            attachments: meta.attachments,
        });

        const templateText = translate(_data.text, {});
        const regEx = /\$\(gap:([^)]+)\)/g;
        const fragments = templateText.split(regEx);
        this.fragments = fragments.map((text, i) => {
            if (i % 2 === 0) {
                return new ChoiceTextData_Text(this, fragments[i]);
            } else {
                return new ChoiceTextData_Gap(this, fragments[i]);
            }
        });
        this.gaps = <ChoiceTextData_Gap[]>this.fragments.filter(x => x instanceof ChoiceTextData_Gap);

        this.fromJS(_data);
    }
    public fromJS(data: DATA) {
    }
    public readonly headerText: string;
    public readonly questionHTML: string;
    public readonly fragments: Array<ChoiceTextData_Gap | ChoiceTextData_Text>;
    public readonly gaps: ChoiceTextData_Gap[];

    public async assign({ gapId, valueId }: { gapId: string, valueId: string }) {
        const gap = this.fragments.find(x => x instanceof ChoiceTextData_Gap && x.gapId === gapId);
        if (!gap) {
            throw new Error();
        }
        if (gap && gap instanceof ChoiceTextData_Gap) {
            gap.selectedValue(valueId);
        }
    }

    public IsInteractionStarted() {
        return this.gaps.some(x => !!x.selectedValue());
    }
    public IsInteractionComplete() {
        return this.gaps.every(x => !!x.selectedValue());
    }
    async reset() {
        for (const gap of this.gaps) {
            gap.selectedValue(undefined);
            //await this.assign({ gapId: gap.gapId, valueId: '' });
        }
        return DONE;
    }

    public getItemState() {
        const retVal: API.IUpdateItemStateInput = {
            itemId: this.meta.itemId,
            ChoiceText: {
                gaps: this.gaps.map(x => ({
                    gapId: x.gapId,
                    valueId: x.selectedValue()
                }))
            }
        };
        return retVal;
    }

}
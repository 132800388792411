import * as ko from 'knockout';
import { INIT, ItemDataModel } from './ItemDataModel';
import { SessionBase } from './sessionbase';
export type AccumulatedScore = INIT['accumulatedScore'];
export type HandsOnDataRaw = INIT['HandsOn_Data'];
export type Attachment = INIT['attachments'][0];

export class ItemModel {
    public readonly id: string;
    public readonly itemId: string;
    public readonly isTopItem: boolean;
    public readonly isOnlyChild: boolean;
    public readonly hasFocusMode: boolean;
    public readonly minWidth: number;
    public readonly minHeight: number;
    public readonly handsonData: HandsOnDataRaw;
    public readonly attachments: Attachment[];
    public data: ItemDataModel;
    public getAttachment(id: string) {
        if (!id) {
            return undefined;
        }
        return this.attachments.find(x => x.id === id);
    }
    public readonly accumulatedScore = ko.observable<AccumulatedScore>();
    constructor(readonly session: SessionBase, rawData: INIT) {
        this.id = rawData.id;
        this.isTopItem = rawData.isTopItem;
        this.itemId = rawData.itemId;
        this.hasFocusMode = rawData.hasFocusMode;
        this.minWidth = rawData.minWidth;
        this.minHeight = rawData.minHeight;
        this.isOnlyChild = rawData.isOnlySibling;
        this.handsonData = rawData.HandsOn_Data;
        this.attachments = rawData.attachments;
        this.fromJS(rawData);
    }
    public fromJS(data: INIT) {
        this.accumulatedScore(data.accumulatedScore);
        if (this.data) {
            this.data.fromJS(data && data.data);
        }
    }
}

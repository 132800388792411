import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import dxDataGrid from 'devextreme/ui/data_grid';
import { error, log } from '../debug';
import { DONEENUM } from '../helper';
import { IndexGenerator } from './graphQLDataSource';

export type KoObservableObject<T> = {
    readonly [P in keyof T]: ko.Observable<T[P]>;
};

export function UpdateObject<T>(obj: KoObservableObject<T>, data: Partial<T>) {
    for (const propName of Object.keys(data)) {
        const prop: keyof T = <any>propName;
        obj[prop](<any>data[prop]);
    }
}

export class KoStore<T extends KoObservableObject<TGridData>, TGridData, TKey extends keyof T> {
    readonly data: Map<T[TKey], T> = new Map();
    readonly store: CustomStore;
    readonly state = {
        fetched: false,
    };

    getKey(record: T): T[TKey] {
        const x = record[this.options.key];
        if (typeof x !== 'string') {
            throw new Error(`data['${String(this.options.key)}'] is not a string`);
        }
        return x;
    }

    constructor(readonly options: {
        fetch: () => T[],
        key: TKey,
        indexGenerator: IndexGenerator<T, TKey>,
        onNewRow: (data: Partial<TGridData>) => Promise<void>,
        insert: (row: Partial<TGridData>) => Promise<T>,
        update: (key: T[TKey], row: Partial<TGridData>) => Promise<DONEENUM>,
        remove: (key: T[TKey]) => Promise<DONEENUM>
    }) {
        this.store = new CustomStore({
            loadMode: 'raw',
            key: <string>options.key,
            byKey: async key => {
                const retVal = this.data.get(key);
                return <any>retVal;
            },
            load: async (options) => {
                log(`Load DS`);
                for (const o of this.options.fetch()) {
                    this.data.set(this.getKey(o), o);
                }
                //todo - remove old
                this.state.fetched = true;
                return Array.from(this.data.values());
            },
            update: async (key: T[TKey], values: Partial<TGridData>) => {
                log(`Update ${key} to ${JSON.stringify(values)}`);
                const existing = this.data.get(key);
                for (const propName of Object.keys(values)) {
                    const prop: keyof TGridData = <any>propName;
                    existing[prop](<any>values[prop]);
                }
                await this.options.update(key, values);
            },
            errorHandler: (err: Error) => {
                error(err.message);
            },
            remove: async (key: T[TKey]) => {
                log(`Delete ${key}`);
                this.data.delete(key);
                if (this.options.remove) {
                    await this.options.remove(key);
                }
            },
            insert: async (values: Partial<TGridData>) => {
                const newOne = await options.insert(values);
                const key = this.getKey(newOne);
                const existing = this.data.get(key);
                if (existing) {
                    throw new Error('Key already exists!');
                }
                log(`Insert ${key} with values ${JSON.stringify(values)}`);
                this.data.set(key, newOne);
                return values;
            },
        });
    }

    initNewRow = (e: DevExpress.events.EventInfo<dxDataGrid> &
        DevExpress.ui.dxDataGrid.NewRowInfo<Partial<TGridData>>) => {
        e.data = {};
        e.promise = this.options.onNewRow(e.data);
        return;
    }

}

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import * as API from '../../../../its-itembank-api.g';
import { AS } from '../../../../dx_helper';
import * as HELPER from '../../../../helper';
import { DONE } from '../../../../helper';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18n from './../../../../i18n/i18n';
import * as i18next from './../../../../i18n/i18n';
//import * as ADDCIRCLE from './addcircle.mutation.graphql.g';
//import * as Q from './data.query.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';
type Q = Awaited<ReturnType<API.Sdk['singlechoicehotspot_edit_data']>>;
const WIDGET_NAME = 'itemdefinition-kosovo-singlechoicehotspot-edit';
const WIDGET_PARENT_NAME = 'itemdefinition-kosovo';
const SHAPES = [{
    id: 'circle',
    regEx: /^Circle\(x=([0-9]+)%,y=([0-9]+)%,r=([0-9]+)%\)$/i
}];
export type IParams = IItemDefinitionWidgetParams;

class Circle {
    constructor(readonly model: ViewModel,
        readonly x: number,
        readonly y: number,
        readonly r: number,
        iscorrect: boolean,
        readonly id: string) {
        this.iscorrect(iscorrect);
    }
    public readonly iscorrect = ko.observable<boolean>();
    public readonly circleClick = new UIAction(undefined, async () => {
        await this.model.circleClick.intent(this.id);
    });
}

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;
    public readonly loaded = ko.observable(false);

    public readonly radius = ko.observable(5);
    //modes of shapes editing
    public readonly circleDrawMode = ko.observable(false);
    public readonly circleDeleteMode = ko.observable(false);
    public readonly correctAreaMode = ko.observable(false);
    public readonly question = ko.observable('');
    public readonly header = ko.observable('');

    public readonly singleChoiceHotspotDefineCorrectCircleButtonLabel = i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.DEFINE_CORRECT_ANSWER']);

    public readonly singleChoiceHotspotDeleteCircleButtonLabel = i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.DELETE_CIRCLE']);

    public readonly singleChoiceHotspotAddCircleButtonLabel = i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.ADD_CIRCLE']);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                header: this.header,
                question: this.question,
                image: this.image,
                imageWidth: this.imageWidth,
                imageHeight: this.imageHeight,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'image',
            editorType: 'dxSelectBox',
            label: {
                text: i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.IMAGE']),
            },
            editorOptions: this.imageOptions(),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageWidth',
            editorType: 'dxNumberBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.singlechoicehotspot.edit.IMAGE_WIDTH']),
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                showSpinButtons: true,
                min: 0,
            }),
        }));
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'imageHeight',
            editorType: 'dxNumberBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.singlechoicehotspot.edit.IMAGE_HEIGHT']),
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                showSpinButtons: true,
                min: 0,
            }),
        }));

        return retVal;
    });


    public readonly imageOptions = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxSelectBox.Properties = {
            value: this.image,
            dataSource: this._attachments,
            valueExpr: 'name',
            displayExpr: 'name',
        };
        return retVal;
    });

    public readonly imgValid = ko.pureComputed(() => {
        return !!this.imgUrl();
    });

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });

    public readonly imgUrl = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        if (!att) {
            return undefined;
        }
        return ServerConnection.getDataUrl(att && att.hrefResolved);
    });

    public readonly naturalImageWidth = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.width || 100;
    });
    public readonly naturalImageHeight = ko.pureComputed(() => {
        const att = this._attachments.find(x => x.name === this.image());
        return att?.imageMetadata?.height || 100;
    });

    public readonly actualWidth = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).width;
    });
    public readonly actualHeight = ko.pureComputed(() => {
        return HELPER.resize({
            original: {
                width: this.naturalImageWidth(),
                height: this.naturalImageHeight()
            },
            target: {
                width: this.imageWidth(),
                height: this.imageHeight()
            }
        }).height;
    });

    public readonly circles = ko.pureComputed(() => {
        const imgWidth = this.actualWidth();
        const imgHeight = this.actualHeight();

        const shapes = this._shapes() || [];
        return shapes.map(shape => {
            for (const shapeFormat of SHAPES) {
                const regEx = shapeFormat.regEx.exec(shape.shape);
                if (!regEx) {
                    continue;
                }
                switch (shapeFormat.id) {
                    case 'circle':
                        const x = parseFloat(regEx[1]);
                        const y = parseFloat(regEx[2]);
                        const r = parseFloat(regEx[3]);
                        const circle = new Circle(this, imgWidth * x / 100, imgHeight * y / 100, imgWidth * r / 100, shape.correct, shape.index);
                        return circle;
                }
            }
            throw new Error(`Unhandled shape format ${shape.shape} (format not detected)`);
        });
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const q = await ServerConnection.api.singlechoicehotspot_edit_data({
            itemId: this.itemId,
        });
        const d = q.SingleChoiceHotspotEdit.get;
        this.question(d.question.value);
        this.header(d.header.value);


        this.image(d.image?.name);
        this.imageWidth(d.imageWidth || 0);
        this.imageHeight(d.imageHeight || 0);
        this._attachments.splice(0, this._attachments.length, ...q.documents.get.attachments);
        this._shapes(d.shapes);

    }
    public readonly image = ko.observable('');
    private readonly _attachments: Q['documents']['get']['attachments'] = [];
    private readonly _shapes = ko.observable<Q['SingleChoiceHotspotEdit']['get']['shapes']>();

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async val => {
            await ServerConnection.api.singlechoicehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    question: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async val => {
            await ServerConnection.api.singlechoicehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    header: xnone(val)
                }
            });
            return DONE;
        });
        this.onChange(this.image, `${WIDGET_NAME}/${this.itemId}/image`, async val => {
            await ServerConnection.api.singlechoicehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    image: val
                }
            });
            return DONE;
        });
        this.onChange(this.imageWidth, `${WIDGET_NAME}/${this.itemId}/imageWidth`, async val => {
            await ServerConnection.api.singlechoicehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    imageWidth: val
                }
            });
            return DONE;
        });
        this.onChange(this.imageHeight, `${WIDGET_NAME}/${this.itemId}/imageHeight`, async val => {
            await ServerConnection.api.singlechoicehotspot_edit_update({
                params: {
                    itemId: this.itemId,
                    imageHeight: val
                }
            });
            return DONE;
        });
        this.loaded(true);
    }

    public readonly hasImage = ko.pureComputed(() => {
        return !!this.imgUrl();
    });

    public circleClick = new UIAction<string>(undefined, async (e, circleId) => {
        //Edit mode click on circle shape handler
        if (this.circleDeleteMode()) { //delete answer
            const answer = this.circles().find(e => { return e.id === circleId; });
            if (answer.iscorrect()) {
                toastService.error(i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.YOU_CANNOT_DELETE_THE_CORRECT_ANSWER']));
                return;
            }
            if (this.circles().length <= 4) {
                toastService.error(i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.THERE_HAVE_TO_BE_AT_LEAST_4_CIRCLES']));
                return;
            }
            await legacyPushPull(async () => {
                await ServerConnection.api.singlechoicehotspot_edit_update(
                    {
                        params: {
                            itemId: this.itemId,
                            removeShapes: [answer.id]
                        }
                    });
            });
        }
        if (this.correctAreaMode()) { //set correctAnswer
            await legacyPushPull(async () => {
                await ServerConnection.api.singlechoicehotspot_edit_update(
                    {
                        params: {
                            itemId: this.itemId,
                            correct: circleId,
                        }
                    });
            });
        }
    });

    public readonly defineAnswerAreasText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.DEFINE_ANSWER_AREAS']);
    });
    public readonly radiusText = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.RADIUS']);
    });
    public readonly addCircleHint = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.CLICK_ON_THE_DESIRED_AREA_TO_ADD_A_CIRCLE']);
    });
    public readonly deleteCircleHint = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.CLICK_ON_THE_CIRCLE_YOU_WANT_TO_DELETE']);
    });
    public readonly connectHint = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.kosovo.singlechoicehotspot.edit.CLICK_ON_THE_CIRCLE_DEFINING_THE_CORRECT_AREA']);
    });


    public readonly addCircle = new UIAction(undefined, async () => {
        this.circleDrawMode(!this.circleDrawMode());
        if (this.circleDrawMode()) {
            this.circleDeleteMode(false);
            this.correctAreaMode(false);
        }
    });
    public readonly deleteCircle = new UIAction(undefined, async () => {
        this.circleDeleteMode(!this.circleDeleteMode());
        if (this.circleDeleteMode()) {
            this.circleDrawMode(false);
            this.correctAreaMode(false);
        }
    });
    public readonly correctShape = new UIAction(undefined, async () => {
        this.correctAreaMode(!this.correctAreaMode());
        if (this.correctAreaMode()) {
            this.circleDrawMode(false);
            this.circleDeleteMode(false);
        }
    });

    private getNewKey() {
        const alphaBet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        let res: string;
        alphaBet.every((letter) => {
            if (this.circles().filter((e) => { return e.id === letter; }).length === 0) {
                res = letter;
                return false;
            }
            return true;
        });
        return res;
    }

    public drawShape = (ths: void, e: MouseEvent) => {
        if (this.circleDrawMode()) {
            const newKey = this.getNewKey();
            const tgt = <SVGSVGElement>e.target;
            const targ = tgt.getBoundingClientRect();
            const offsetX = e.offsetX === undefined ? e.clientX - targ.left : e.offsetX;
            const offsetY = e.offsetY === undefined ? e.clientY - targ.top : e.offsetY;
            const x = Math.round(offsetX * 100 / tgt.width.baseVal.value);
            const y = Math.round(offsetY * 100 / tgt.height.baseVal.value);
            const radius = Math.round(this.radius());
            /*
            this.circles.push(new VMCircle(this,
                offsetX,
                offsetY,
                this.item().imageWidth() * r / 100,
                false,
                newKey
            ));
            */
            void legacyPushPull(async () => {
                await ServerConnection.api.singlechoicehotspot_edit_addcircle({
                    itemId: this.itemId,
                    x, y, radius,
                });
            });
        }
    }
    public readonly imageHeight = ko.observable(0);
    public readonly imageWidth = ko.observable(0);
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME).replace(/@@/g, WIDGET_PARENT_NAME)
});

import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18n from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-translator-selectexamorder';

export const topTitle = ko.pureComputed(() => i18n.t(['ui.translator.selectexamorder.TRANSLATOR']));

export class SelectSubjectRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly languageSwitcher = true;
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18n.t(['ui.translator.selectexamorder.EXAM_ORDERS']);
    });
    public readonly topTitle = topTitle;
    constructor(readonly widgetParams: IParamsInner) {
    }
}

export type IParamsInner = {};

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export const FACTORY = ROUTES.registerRoute({
    route: '/translator',
    toParams: () => ({}),
    fromParams: () => { },
    create: async p => {
        return new SelectSubjectRoute(undefined);
    },
});

import type DevExpress from 'devextreme/bundles/dx.all';
import * as ko from 'knockout';
import { AS, selectBoxDS } from '../../../../dx_helper';
import * as HELPER from '../../../../helper';
import { DONE } from '../../../../helper';
import * as API from '../../../../its-itembank-api.g';
import * as Modal from '../../../../modal';
import { IItemDefinitionWidgetParams } from '../../../../model/interfaces';
import { xnone } from '../../../../model/languagemap';
import { toastService } from '../../../../toastService';
import * as TOKENIZER from '../../../../tokenizer';
import { legacyPushPull } from '../../../../ui/docmanager';
import { ServerConnection } from '../../../../ui/RestAPI';
import { UIAction } from '../../../../ui/uiAction';
import * as HTMLEDITOR from '../../../../widgets/htmleditor/widget';
import { AbstractItemDefinition } from '../../../base_itemdefinition';
import * as i18n from './../../../../i18n/i18n';
//import * as ADD_GAP from './addgap.mutation.graphql.g';
//import * as ADD_VALUE from './addvalue.mutation.graphql.g';
//import * as Q from './data.query.graphql.g';
//import * as REMOVE_GAP from './removegap.mutation.graphql.g';
//import * as REMOVE_VALUE from './removevalue.mutation.graphql.g';
//import * as UPDATE from './update.mutation.graphql.g';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'itemdefinition-kosovo-fillingap-edit';

export type IParams = IItemDefinitionWidgetParams;
type Q = Awaited<ReturnType<API.Sdk['fillingap_edit_data']>>;

class GapPossibilityVM {
    constructor(readonly vm: MyModel, readonly gapVM: GapVM, readonly id: string) {
        this.canEdit = vm.canEdit;
    }

    public readonly canEdit: boolean;
    public readonly isCorrect = ko.pureComputed(() => {
        return this.gapVM.correct() === this.id;
    });

    public deleteAction = new UIAction(undefined, async () => {
        let gaps = this.gapVM.possibilities();

        if (gaps.length <= 2) {
            toastService.warning(i18n.t(['itemdefinition.kosovo.fillingap.edit.THIS_VARIANT_CANNOT_BE_DELETED_YOU_NEED_AT_LEAST_TWO_VARIANTS_PER_GAP']));
            return;

        }
        const message = i18n.t(['itemdefinition.kosovo.fillingap.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_VARIANT']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.fillingap_edit_removevalue({
                itemId: this.vm.itemId,
                valueId: this.id,
            });
        });
    });

    public readonly setCorrectAction = new UIAction(undefined, async () => {
        if (this.vm.isReadOnly) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.fillingap_edit_upsert({
                params: {
                    itemId: this.vm.itemId,
                    upsertGaps: [{
                        gapId: this.gapVM.gapId,
                        correct: this.id
                    }]
                }
            });
        });
    });

    public readonly value = ko.observable('');
    public readonly htmlEditorParams = ko.pureComputed<HTMLEDITOR.IParams>(() => {
        const retVal: HTMLEDITOR.IParams = {
            docType: API.Doctype.Item,
            docReferenceId: this.vm.itemId,
            data: this.vm.getValueKO(this.id),
            readOnly: this.vm.isReadOnly,
        };
        return retVal;
    });
}
class GapVM {
    constructor(readonly vm: MyModel, readonly gapId: string) {
        this.canEdit = vm.canEdit;
    }
    public readonly canEdit: boolean;

    public readonly correct = ko.observable('');
    public update(data: Q['FillInGapEdit']['get']['gaps'][0]) {
        const newP = data.possibilities.map(x => x.valueId);
        if (this._possibilities().join(' ') !== newP.join(' ')) {
            this._possibilities(newP);
        }
        this.correct(data.correct.valueId);
    }
    public readonly gapTag = ko.pureComputed(() => {
        return TOKENIZER.GAP_TOKEN.compile({
            id: this.gapId
        });
    });
    public readonly isSelected = ko.pureComputed(() => this.gapId === this.vm.selectectGapId());

    private readonly _possibilities = ko.observable<string[]>([]);

    private readonly _values = new Map<string, GapPossibilityVM>();

    public readonly possibilities = ko.pureComputed(() => {
        const p = this._possibilities();
        for (const id of p) {
            if (!this._values.has(id)) {
                this._values.set(id, new GapPossibilityVM(this.vm, this, id));
            }
        }
        return p.map(id => this._values.get(id));
    });

    public addVariantAction = new UIAction(undefined, async () => {
        await this.vm.addVariant(this.gapId);
    });
    public selectAction = new UIAction(undefined, async () => {
        this.vm.selectectGapId(this.gapId);
    });
    public deleteGapAction = new UIAction(undefined, async () => {
        const message = i18n.t(['itemdefinition.kosovo.fillingap.edit.DO_YOU_REALLY_WANT_TO_DELETE_THIS_GAP']);
        if (!await Modal.confirmYesNo(message)) {
            return;
        }
        await legacyPushPull(async () => {
            await ServerConnection.api.fillingap_edit_removegap({
                itemId: this.vm.itemId,
                gapId: this.gapId
            });
        });
    });
}

export class MyModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly canEdit: boolean;

    public readonly loaded = ko.observable(false);
    public readonly answers = ko.observableArray();
    public readonly shuffleModeString = ko.observable<API.FillInGapEdit_ShuffleMode>();
    public readonly scoringString = ko.observable<API.FillInGapEdit_Scoring>();
    public readonly perGapScore = ko.observable(0);


    public readonly choiceTextDeleteGapButtonLabel = i18n.t(['itemdefinition.kosovo.fillingap.edit.DELETE_GAP']);

    public readonly choiceTextAddVariantButtonLabel = i18n.t(['itemdefinition.kosovo.fillingap.edit.ADD_OPTION']);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
        this.canEdit = !this.isReadOnly;
    }

    public readonly form1Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                scoringString: this.scoringString,
                perGapScore: this.perGapScore,
                shuffleModeString: this.shuffleModeString,
                header: this.header,
                question: this.question,
                text: this.text,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'header',
            editorType: 'dxTextBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.fillingap.edit.HEADER']),
            },
            editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                placeholder: i18n.t(['itemdefinition.kosovo.fillingap.edit.ENTER_THE_QUESTION_TEXT_HERE']),
            }),
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.fillingap.edit.QUESTION']),
            },
            readOnly: this.isReadOnly,
            dataField: 'question',
            docReferenceId: this.itemId,
            docType: API.Doctype.Item,
            placeholder: i18n.t(['itemdefinition.kosovo.fillingap.edit.ENTER_THE_INSTRUCTION_TEXT_HERE'])
        }));
        retVal.items.push(HTMLEDITOR.FormItemHtmlEditor({
            label: {
                location: 'top',
                text: i18n.t(['itemdefinition.kosovo.fillingap.edit.TEXT']),
            },
            readOnly: this.isReadOnly,
            dataField: 'text',
            placeholder: this.enterTextPlaceholder(),
            docType: API.Doctype.Item,
            docReferenceId: this.itemId,
            tokenTypes: [TOKENIZER.GAP_TOKEN],
            toolbarItems: [{
                'icon': 'far fa-plus-square',
                'text': i18n.t(['itemdefinition.kosovo.fillingap.edit.ADD_GAP']),
                onClick: this.addGapFragmentAction.click,
                disabled: this.isReadOnly,
            }],
            onInitialized: async editor => {
                this.editor(editor);
                this.disposables.addDiposable(editor.currentTag.subscribe(val => {
                    const r = TOKENIZER.GAP_TOKEN.parse(val);
                    const id = r && r.id;
                    if (!id) {
                        this.selectectGapId(undefined);
                        this.selectGapOnRefresh = undefined;
                    } else {
                        if (this._gapIds().find(x => x === id)) {
                            this.selectectGapId(id);
                        } else {
                            this.selectGapOnRefresh = id;
                        }
                    }
                }));
            }
        }));
        return retVal;
    });
    public readonly form2Options = ko.pureComputed(() => {
        const retVal: DevExpress.ui.dxForm.Properties = {
            readOnly: this.isReadOnly,
            formData: {
                scoringString: this.scoringString,
                perGapScore: this.perGapScore,
                shuffleModeString: this.shuffleModeString,
            },
            items: [],
        };
        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'scoringString',
            label: {
                text: i18n.t(['itemdefinition.kosovo.fillingap.edit.POINTS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.FillInGapEdit_Scoring.AllOrNothing,
                    value: i18n.t(['itemdefinition.kosovo.fillingap.edit.FOR_ITEM'])
                },
                {
                    key: API.FillInGapEdit_Scoring.PerGap,
                    value: i18n.t(['itemdefinition.kosovo.fillingap.edit.PER_GAP'])
                }
            ]),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'perGapScore',
            editorType: 'dxNumberBox',
            label: {
                text: i18n.t(['itemdefinition.kosovo.fillingap.edit.POINTS_PER_GAP'])
            },
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 0,
            }),
        }));

        retVal.items.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'shuffleModeString',
            label: {
                text: i18n.t(['itemdefinition.kosovo.fillingap.edit.MODE_FOR_GAPS'])
            },
            editorType: 'dxSelectBox',
            editorOptions: selectBoxDS([
                {
                    key: API.FillInGapEdit_ShuffleMode.Shuffled,
                    value: i18n.t(['itemdefinition.kosovo.fillingap.edit.SHUFFLED'])
                },
                {
                    key: API.FillInGapEdit_ShuffleMode.Ordered,
                    value: i18n.t(['itemdefinition.kosovo.fillingap.edit.FIXED'])
                },
                {
                    key: API.FillInGapEdit_ShuffleMode.ShuffledExceptLast,
                    value: i18n.t(['itemdefinition.kosovo.fillingap.edit.SHUFFLED_EXCEPT_LAST'])
                },
            ]),
        }));

        return retVal;
    });

    private readonly valueKO = new Map<string, ko.Observable<string>>();
    public getValueKO(valueId: string) {
        if (!this.valueKO.has(valueId)) {
            throw new Error();
        }
        return this.valueKO.get(valueId);
    }
    private readonly _gaps = new Map<string, GapVM>();

    public readonly item = ko.observable<Q['FillInGapEdit']['get']>();

    public readonly _gapIds = ko.observable<string[]>([]);

    public readonly gaps = ko.pureComputed(() => {
        return this._gapIds().map(x => this._gaps.get(x));
    });

    private readonly editor = ko.observable<HTMLEDITOR.ViewModel>();

    public readonly selectectGapId = ko.observable('');
    public readonly text = ko.observable('');

    private selectGapOnRefresh: string;

    public readonly selectedGap = ko.pureComputed(() => {
        const selected = this.selectectGapId();
        if (!selected) {
            return undefined;
        }
        return this._gaps.get(selected);
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.fillingap_edit_data({
            itemId: this.itemId
        });

        const d = data.FillInGapEdit.get;
        if (!d) {
            return;
        }
        this.shuffleModeString(d.shuffleMode);
        this.scoringString(d.scoring);
        this.text(d.text.value);
        this.header(d.header.value);
        this.question(d.question.value);
        this.perGapScore(d.perGapScore || 0);
        for (const g of d.gaps) {
            if (!this._gaps.has(g.gapId)) {
                this._gaps.set(g.gapId, new GapVM(this, g.gapId));
            }
            for (const v of g.possibilities) {
                const valueId = v.valueId;
                if (this.valueKO.has(valueId)) {
                    this.valueKO.get(valueId)(v.value.value);
                } else {
                    const val = ko.observable(v.value.value);
                    this.onChange(val, `${WIDGET_NAME}/${this.itemId}/value/${valueId}`, async v => {
                        await ServerConnection.api.fillingap_edit_upsert({
                            params: {
                                itemId: this.itemId,
                                upsertValues: [
                                    {
                                        valueId: valueId,
                                        value: xnone(v)
                                    }
                                ]
                            }
                        });
                        return DONE;
                    });
                    this.valueKO.set(valueId, val);
                }
            }
            this._gaps.get(g.gapId).update(g);
        }
        const gapIds = d.gaps.map(x => x.gapId).sort();
        if (gapIds.join(' ') !== this._gapIds().join(' ')) {
            this._gapIds(gapIds);
        }
        this.item(d);

        const selectGap = this.selectGapOnRefresh || this.selectectGapId();
        if (gapIds.find(x => x === selectGap)) {
            this.selectectGapId(selectGap);
        } else {
            this.selectectGapId(undefined);
        }
        this.selectGapOnRefresh = undefined;
    }

    public readonly header = ko.observable('');

    public readonly headerPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.fillingap.edit.ENTER_THE_QUESTION_TEXT_HERE']);
    });
    public readonly question = ko.observable('');

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();

        this.onChange(this.question, `${WIDGET_NAME}/${this.itemId}/question`, async () => {
            await ServerConnection.api.fillingap_edit_upsert({
                params: {
                    itemId: this.itemId,
                    question: xnone(this.question())
                }
            });
            return DONE;
        });
        this.onChange(this.text, `${WIDGET_NAME}/${this.itemId}/text`, async () => {
            await ServerConnection.api.fillingap_edit_upsert({
                params: {
                    itemId: this.itemId,
                    text: xnone(this.text())
                }
            });
            return DONE;
        });
        this.onChange(this.header, `${WIDGET_NAME}/${this.itemId}/header`, async () => {
            await ServerConnection.api.fillingap_edit_upsert({
                params: {
                    itemId: this.itemId,
                    header: xnone(this.header())
                }
            });
            return DONE;
        });
        this.onChange(this.perGapScore, `${WIDGET_NAME}/${this.itemId}/perGapScore`, async v => {
            await ServerConnection.api.fillingap_edit_upsert({
                params: {
                    itemId: this.itemId,
                    perGapScore: v
                }
            });
            return DONE;
        });
        this.onChange(this.shuffleModeString, `${WIDGET_NAME}/${this.itemId}/shuffleMode`, async v => {
            await ServerConnection.api.fillingap_edit_upsert({
                params: {
                    itemId: this.itemId,
                    shuffleMode: v
                }
            });
            return DONE;
        });
        this.onChange(this.scoringString, `${WIDGET_NAME}/${this.itemId}/scoring`, async v => {
            await ServerConnection.api.fillingap_edit_upsert({
                params: {
                    itemId: this.itemId,
                    scoring: v
                }
            });
            return DONE;
        });

        this.loaded(true);
    }
    public dispose() {
        super.dispose();
        this.editor(undefined);
    }
    public readonly valuesValidationMessage = ko.pureComputed<string>(() => {
        const itemData = this.item();
        if (!itemData) {
            return undefined;
        }

        let isError = false;
        /*
        itemData.values().forEach(value => {
            if (value.value.localizedValue() === '') {
                isError = true;
            }
        });
        if (isError) {
            return 'Add text to all gaps.';
        } else {
            return undefined;
        }
        */
        return '';
    });

    public readonly gapsValidationMessage = ko.pureComputed(() => {
        return '';
        /*
        const itemData = this.item();
        if (!itemData) {
            return undefined;
        }
        let isError = false;
        itemData.gaps.forEach(gap => {
            const variants = ARRAYHELPER.splitString(gap.possibilities());
            if (variants.length < 2) {
                return isError = true;
            }
            return undefined;
        });
        if (isError) {
            return 'There has to be a minimum of two variants.';
        } else {
            return undefined;
        }
        */
    });

    public addGapFragmentAction = new UIAction(undefined, async () => {
        const r = await ServerConnection.api.fillingap_edit_addgap({
            itemId: this.itemId,
        });
        await this.editor().insertToken(TOKENIZER.GAP_TOKEN.compile({
            id: r.FillInGapEdit.addGap.gapId
        }));
        await legacyPushPull();
    });


    public async addVariant(gap: string) {
        await legacyPushPull(async () => {
            const r = await ServerConnection.api.fillingap_edit_addvalue({
                itemId: this.itemId,
                gapId: gap
            });
        });
    }

    public readonly enterTextPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.fillingap.edit.ENTER_THE_TEXT_HERE']);
    });
    public readonly enterPossibilityPlaceholder = ko.pureComputed(() => {
        return i18n.t(['itemdefinition.kosovo.fillingap.edit.ENTER_ONE_OPTION_HERE']);
    });

    public handlePaste(data: {}, event: ClipboardEvent) {
        HELPER.handlePasteEvent(event);
    }

}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

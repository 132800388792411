import * as ko from 'knockout';
import * as ROUTES from '../../routes';
import * as i18next from './../../../i18n/i18n';

export const WIDGET_NAME = 'ui-superuser-golemscriptoverview';

export type IParamsInner = undefined;

export type IParams = ROUTES.IRoutableParams<IParamsInner>;

export class GolemScriptOverviewRoute implements ROUTES.IRoute<IParamsInner> {
    public readonly widgetName = WIDGET_NAME;
    public readonly title = ko.pureComputed(() => {
        return i18next.t(['ui.superuser.golemscriptoverview.GOLEM_SCRIPT']);
    });

    constructor(readonly widgetParams: IParamsInner) {
    }
}

export const FACTORY = ROUTES.registerRoute<{}>({
    route: '/superuser/golemscript',
    toParams: map => ({
    }),
    fromParams: (p, map) => {
    },
    create: async p => {
        return new GolemScriptOverviewRoute(undefined);
    },
});

import * as ko from 'knockout';
import { IItemDefinitionWidgetParams } from '../../../model/interfaces';
import { ServerConnection } from '../../../ui/RestAPI';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import * as i18next from './../../../i18n/i18n';
//import * as Q from './data.query.graphql.g';
import { htmlString } from './widget.html.g';

export const WIDGET_NAME = 'itemdefinition-placeholder-edit';

export type IParams = IItemDefinitionWidgetParams;

export class ViewModel extends AbstractItemDefinition {
    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly isReadOnly: boolean;
    public readonly loaded = ko.observable(false);

    constructor(params: IParams) {
        super();
        this.itemId = params.itemId;
        this.sessionId = params.sessionId;
        this.isReadOnly = params.mode === 'INSPECT';
    }


    public readonly noEditModeHint = ko.pureComputed(() => {
        return i18next.t(['itemdefinition.placeholder.edit.PLACEHOLDER_NOTHING_TO_EDIT']);
    });

    public async OnRefresh() {
        await super.OnRefresh();
        const data = await ServerConnection.api.placeholder_edit_data({
            itemId: this.itemId
        });

        const d = data.PlaceholderEdit.get;
        if (!d) {
            return;
        }

    }

    public async initialize() {
        await super.initialize();
        await this.OnRefresh();
        this.loaded(true);
    }
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new ViewModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

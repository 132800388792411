export const id = 'surveytextentry';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'a6d98f92-d324-47c7-87af-825a515e6f22',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

export const id = 'placeholder';

import { ItemMode } from '../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../factoryProvider';

itemDefinitionFactoryProvider.register({
    id: id,
    uuid: 'bf20c2c1-73dc-4a4c-a2ce-e7481282d1b3',
    aliases: [
        'kosovo-resultfiles',
    ],
    widgetNames: {
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ]),
        fallback: 'itemdefinition-' + id

    },
});

import * as ko from 'knockout';
import { log } from '../../../debug';
import { AS } from '../../../dx_helper';
import { formatMessage } from '../../../i18n/data';
import { SurveyCombo_ShowSubItems } from '../../../its-itembank-api.g';
import { confirmYesNo } from '../../../modal';
import { IItemDefinitionWidgetParams, ItemMode } from '../../../model/interfaces';
import { focusManager } from '../../../ui/focusmanager';
import { UIAction } from '../../../ui/uiAction';
import { AbstractItemDefinition } from '../../base_itemdefinition';
import * as WIDGET_HEADER from '../../kosovo/widgets/header/widget';
import { ItemDataModel } from '../../model/ItemDataModel';
import { GetSession } from '../../model/session';
import { SurveyComboData, SurveyComboData_SubItem } from "../../model/surveycombo/SurveyComboData";
import * as FACTORY from '../factory';
import * as i18n from './../../../i18n/i18n';
import { htmlString } from './widget.html.g';

const WIDGET_NAME = 'itemdefinition-surveycombo-interactive';

export type IParams = IItemDefinitionWidgetParams;

class SubItem {
    private index: number;
    private readonly _subitemmodel: ItemDataModel;
    constructor(readonly model: MyModel, readonly _data: SurveyComboData_SubItem) {
        this.itemDocRefId = _data.itemDocRefId;
        this._subitemmodel = this.model.data.meta.session.getItemData(_data.itemDocRefId);
        const nr = this._data.index + 1;
        const title = formatMessage(i18n.t(['itemdefinition.surveycombo.interactive.PART_NR']), { nr });
        this.title(title);
        this.itemType(_data.itemType);

    }
    public readonly itemDocRefId: string;

    public readonly title = ko.observable('');
    public readonly itemType = ko.observable('');

    public readonly isInitialData = ko.pureComputed(() => {
        return this._subitemmodel.IsInitialData();
    });

    public readonly isStarted = ko.pureComputed(() => {
        return this._subitemmodel.IsInteractionStarted();
    });
    async reset() {
        await this._subitemmodel.reset();
    }
    public readonly isDone = ko.pureComputed(() => {
        return this._subitemmodel.IsInteractionComplete();
    });
}

interface Btn {
    select: UIAction<unknown>;
    isSelected: ko.Subscribable<boolean>;
    value: string;
}

export class MyModel extends AbstractItemDefinition {
    public readonly itemType = FACTORY.id;

    public readonly itemId: string;
    public readonly sessionId: string;
    public readonly mode = ko.observable<ItemMode>();
    public readonly loaded = ko.observable(false);

    //public readonly focusId: string;
    public readonly isFocused = ko.pureComputed(() => {
        if (!focusManager.isFocused()) {
            return false;
        }
        const topItemFocus = focusManager.topItemFocus();
        if (!topItemFocus) {
            return false;
        }
        /*
        if (topItemFocus.focusId !== this.focusId) {
            return false;
        }
        */
        return true;
    });

    public readonly data: SurveyComboData;

    constructor(readonly params: IParams) {
        super();
        this.itemId = params.itemId;

        const mode = params.mode;
        if (mode !== 'INTERACTIVE') {
            throw new Error(`${WIDGET_NAME} - only interactive mode supported!`);
        }

        this.mode('INTERACTIVE');
        this.sessionId = params.sessionId;

        const item = GetSession(this.sessionId).GetItemModel(this.itemId);
        const data = item.data;
        if (!(data instanceof SurveyComboData)) {
            throw new Error();
        }
        this.data = data;
        this._subItems = data.subItems.map(x => new SubItem(this, x));
    }


    public readonly headerParams = ko.pureComputed(() => {
        const retVal: WIDGET_HEADER.IParams = {
            questionHTML: this.questionHTML(),
            headerText: this.headerText(),
            mode: this.mode()
        };
        return retVal;
    });

    private async gremlins() {
        if (!this.loaded()) {
            return true;
        }
        /*
        if (this.hasSubItems()) {
            const subItems = this.subItems;
            const next = findInRingBuffer(subItems, 0, x => !x.isDone(), 'any');
            if (next) {
                log(`Select next sub item`);
                await next.select.invoke();
                return true;
            }
        }
        */
        return false;
    }


    public async initialize() {
        this.registerGremlin({
            name: `${WIDGET_NAME} ${this.itemId}`,
            action: () => this.gremlins()
        });
        await super.initialize();
        log(`${WIDGET_NAME} initialize in mode ${this.mode()} (item: ${this.itemId}`);

        await this.OnRefresh();

        this.loaded(true);
    }

    public readonly hasSubItems = ko.pureComputed(() => {
        return this.subItems.length > 0;
    });

    private readonly _subItems: SubItem[];

    public readonly subItems = ko.pureComputed(() => {
        if (this.data.subItemsVisible()) {
            return this._subItems;
        }
        return [];
    });


    public readonly questionHTML = ko.pureComputed(() => this.data.questionHTML);
    public readonly headerText = ko.pureComputed(() => this.data.headerText);


    public readonly isShowSelected = ko.pureComputed(() => {
        return this.data.showSubItems() === SurveyCombo_ShowSubItems.Yes;
    });
    public readonly isHideSelected = ko.pureComputed(() => {
        return this.data.showSubItems() === SurveyCombo_ShowSubItems.No;
    });

    public readonly buttons = ko.pureComputed(() => {
        if (this.data.order === 'None') {
            return undefined;
        }
        const retVal: Btn[] = [];
        const show = AS<Btn>({
            select: this.showAction,
            value: this.data.showCaption,
            isSelected: this.isShowSelected,
        });
        const hide = AS<Btn>({
            select: this.hideAction,
            value: this.data.hideCaption,
            isSelected: this.isHideSelected,
        });
        if (this.data.order === 'ShowHide') {
            return [show, hide];
        }
        if (this.data.order === 'HideShow') {
            return [hide, show];
        }
        throw new Error();
    });
    public readonly showAction = new UIAction(undefined, async () => {
        this.data.showSubItems(SurveyCombo_ShowSubItems.Yes);
    });
    public readonly hideAction = new UIAction(undefined, async () => {
        if (this._subItems.some(x => !x.isInitialData())) {
            if (!await confirmYesNo(i18n.t(['itemdefinition.surveycombo.interactive.YOU_ALREADY_STARTED_ANSWERING_THE_ITEMS_THIS_WILL_RESET_YOUR_ANSWERS']))) {
                return;
            }
        }
        for (const si of this._subItems) {
            await si.reset();
        }
        this.data.showSubItems(SurveyCombo_ShowSubItems.No);
    });
}

export function create(params: IParams, componentInfo: ko.components.ComponentInfo) {
    const retVal = new MyModel(params);
    retVal.DoInit({ WIDGET_NAME });
    return retVal;
}

ko.components.register(WIDGET_NAME, {
    viewModel: {
        createViewModel: create
    },
    template: htmlString.replace(/@@@/g, WIDGET_NAME)
});

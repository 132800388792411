import type DevExpress from 'devextreme/bundles/dx.all';
import * as DX_DLG from 'devextreme/ui/dialog';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import dxTreeList from 'devextreme/ui/tree_list';
import $ from 'jquery';
import * as ko from 'knockout';
import { dir, error, log } from './debug';
import * as $LANG from './i18n/data';
import { UIAction } from './ui/uiAction';

//extracted from dx.all.d.ts GridBaseOptions
interface STATESTORING_CONFIG {
    enabled?: boolean
    storageKey?: string
    type?: 'custom' | 'localStorage' | 'sessionStorage'
    customLoad?: (() => Promise<any> | JQueryPromise<any>)
    customSave?: ((state: any) => any), savingTimeout?: number
}

export interface IDxWidget {
    grids: Set<DevExpress.ui.dxDataGrid>,
    forms: Set<DevExpress.ui.dxForm>,
    widgets: Set<DevExpress.ui.Widget<unknown>>,
}

export function dxAddTab_Component<TParams>(o: {
    widget: IDxWidget,
    selectedTabLabel?: ko.Observable<string>,
    tabPanel: DevExpress.ui.dxTabPanel.Properties,
    tab: Omit<DevExpress.ui.dxTabPanelItem, "template">,
    component: string,
    componentParams: ko.Subscribable<TParams>
}) {
    const tab: DevExpress.ui.dxTabPanelItem = o.tab;
    if (!tab.visible) {
        tab.visible = true;
    }
    if (!o.tabPanel.items) {
        o.tabPanel.items = [];
    }
    tab.template = () => {
        const x = $(`
        <!-- ko if:selectedLabel()===title -->
        <div data-bind="dxScrollView:{width:'100%',height:'100%',direction:'vertical',showScrollbar:'always',useNative:false}">
            <div class="dx-viewport" data-bind="component:{name:'${o.component}', params:componentParams()}"></div>
        </div>
        <!-- /ko-->
        `);
        ko.applyBindings({
            selectedLabel: o.selectedTabLabel || ko.pureComputed(() => tab.title),
            title: tab.title,
            componentParams: o.componentParams,
        }, x.get(0));
        return x;
    };
    o.tabPanel.items.push(tab);
    if (!o.tabPanel.selectedItem) {
        if (ko.unwrap(tab.visible)) {
            o.tabPanel.selectedItem = tab;
            if (o.selectedTabLabel) {
                o.selectedTabLabel(tab.title);
            }
        }
    }
}

export async function dxAlert(messageHtml: string, title?: string) {
    await DX_DLG.alert(messageHtml, title || 'ITS R3');
}
export function datagrid<TRowData = any, TKey = any>(o: {
    WIDGET_NAME: string,
    discriminator?: string,
    scope?: string,
    config: Omit<Omit<DevExpress.ui.dxDataGrid.Properties<TRowData, TKey>, "onInitialized">, "onDisposing">,
    widget: IDxWidget,
    gridVar?: ko.Observable<DevExpress.ui.dxDataGrid<TRowData, TKey>>
}) {
    const config: DevExpress.ui.dxDataGrid.Properties<TRowData, TKey> = o.config || {};
    if (!config.columns) {
        config.columns = [];
    }
    config.stateStoring = storeState(o.WIDGET_NAME, o.discriminator, o.scope);
    config.showColumnLines = true;
    config.showRowLines = true;
    config.onInitialized = e => {
        o.widget.grids.add(e.component);
        if (o.gridVar) {
            o.gridVar(e.component);
        }
    };
    config.onDisposing = e => {
        if (o.gridVar) {
            o.gridVar(undefined);
        }
        o.widget.grids.delete(e.component);
    };
    return config;
}

export function storeState(widgetName: string, discriminator?: string, scope?: string) {
    const retVal: STATESTORING_CONFIG = {
        enabled: true,
        type: 'localStorage',
        storageKey: `${widgetName}|${discriminator || ''}|${scope || ''}`
    };
    return retVal;
}

export function dxClick(action: UIAction<unknown>) {
    return (e: DevExpress.ui.dxButton.ClickEvent) => {
        log(`Prevent default from ${action.name}`);
        e.event.stopPropagation();
        e.event.preventDefault();
        action.click();
    };
}

export function datagridButton(options: { text: string, action: UIAction<any>, cssClass?: string }) {
    const text = options.text;
    const cssClass = options.cssClass;
    const retVal: DevExpress.ui.dxDataGridColumnButton = {
        text,
        cssClass,
        onClick: async e => {
            e.event.stopPropagation();
            e.event.preventDefault();
            await options.action.intent(e.row.data);
        }
    };
    return retVal;
}

export function lookupDS<T extends string>(datasource: $LANG.LangDataSource<T>, options?: { allowClearing?: boolean }): DevExpress.ui.dxDataGrid.ColumnLookup {
    const retVal = {
        ...options,
        dataSource: datasource,

        valueExpr: 'key',
        displayExpr: 'value',
    };
    dir(retVal);
    return retVal;
}

export function selectBoxDS<T extends string>(datasource: $LANG.LangDataSource<T>) {
    const retVal: DevExpress.ui.dxSelectBox.Properties = {
        dataSource: $LANG.translateDataSource(datasource),

        valueExpr: 'key',
        displayExpr: 'value',
    };
    return retVal;
}

export async function refreshDx(options: IDxWidget) {
    const p = [];
    if (options.grids) {
        for (const grid of Array.from(options.grids)) {
            if (grid) {
                p.push(grid.refresh());
            }
        }
    }
    if (options.forms) {
        for (const form of Array.from(options.forms)) {
            if (form) {
                form.repaint();
            }
        }
    }
    if (options.widgets) {
        for (const widget of Array.from(options.widgets)) {
            if (widget instanceof dxTreeList) {
                p.push(widget.refresh());
            }
            if (widget) {
                widget.repaint();
            }
        }
    }
    if (p.length) {
        await Promise.all(p);
    }
}
export function addTextBox(form: DevExpress.ui.dxForm.Properties, item: DevExpress.ui.dxForm.SimpleItem, options: DevExpress.ui.dxTextBox.Properties) {
    const i = {
        ...item
    };
    i.itemType = 'simple';
    i.editorType = 'dxTextBox';
    i.editorOptions = { ...i.editorOptions, ...options };
    form.items.push(i);
}
export function AS<X>(data: X) {
    return data;
}

export function addTextArea(form: DevExpress.ui.dxForm.Properties, item: DevExpress.ui.dxForm.SimpleItem, options: DevExpress.ui.dxTextArea.Properties) {
    const i = {
        ...item
    };
    i.itemType = 'simple';
    i.editorType = 'dxTextArea';
    i.editorOptions = { ...i.editorOptions, ...options };
    form.items.push(i);
}

export function addSelectBox(form: DevExpress.ui.dxForm.Properties, item: DevExpress.ui.dxForm.SimpleItem, options: DevExpress.ui.dxSelectBox.Properties) {
    const i = {
        ...item
    };
    i.itemType = 'simple';
    i.editorType = 'dxSelectBox';
    i.editorOptions = { ...i.editorOptions, ...options };
    form.items.push(i);
}

type MyToolbarItem<T> = Omit<Omit<DevExpress.ui.dxToolbarItem, "widget">, "options"> & { options: T };

export function tbAddButton<T>(toolbar: DevExpress.ui.dxToolbar.Properties, action: UIAction<T>, item: MyToolbarItem<DevExpress.ui.dxButton.Properties>) {
    const retVal: DevExpress.ui.dxToolbarItem = {
        widget: 'dxButton',
        ...item
    };
    if (!toolbar.items) {
        toolbar.items = [];
    }
    if (action) {
        item.options.onClick = dxClick(action);
    }
    toolbar.items.push(retVal);
    return retVal;
}

export function dg_addTreeList({ grid, column, treeListOptions, fieldTemplate }: { grid: DevExpress.ui.dxDataGrid.Properties, column: DevExpress.ui.dxDataGridColumn, treeListOptions: DevExpress.ui.dxTreeList.Properties, fieldTemplate: (value: any) => JQuery }) {

    //public addTreeList<VAL extends string>(collection: HasItems, item: SimpleItem<DevExpress.ui.dxDropDownBox.Properties>, treeListOptions: DevExpress.ui.dxTreeList.Properties, update?: UpdateFunction<UPDATE, string[]>) {


    /*
    if (!update) {
        ddOptions.readOnly = true;
    }
    column.
    const i: DevExpress.ui.dxForm.SimpleItem = Object.assign(
        item,
        AS<SimpleItemType>({
            editorType: 'dxDropDownBox',
        }),
        AS<SimpleItem<DevExpress.ui.dxDropDownListOptions>>({
            editorOptions: ddOptions,
        }));
*/
    column.editCellTemplate = (container, cellInfo) => {
        const div: any = $('<div/>');
        const ddOptions: DevExpress.ui.dxDropDownBox.Properties = {
            fieldTemplate,
            dataSource: grid.dataSource,
            value: cellInfo.value,
            contentTemplate: e => {
                const value = cellInfo.value;
                log(`current Value: ${value}`);
                if (value && typeof value != 'string') {
                    error(`cellInfo.value is not a string ${JSON.stringify(cellInfo.value)}`);
                }
                //let onContentReadyCalled = 0;
                const selectedRowKeys = value && [value];
                const o: DevExpress.ui.dxTreeList.Properties = Object.assign(
                    AS<DevExpress.ui.dxTreeList.Properties>({
                        dataStructure: 'plain',
                        keyExpr: 'id',
                        parentIdExpr: 'parentId',
                        searchPanel: {
                            visible: false
                        },
                        rootValue: '',
                        editing: {
                            allowAdding: false,
                            allowDeleting: false,
                            allowUpdating: false,
                        },
                        height: 400,
                        autoExpandAll: true,
                        selection: {
                            mode: 'single'
                        },
                        selectedRowKeys,
                    }),
                    treeListOptions,
                    AS<DevExpress.ui.dxTreeList.Properties>({
                        dataSource: <any>column.lookup.dataSource,
                        /*
                        onContentReady: args => {
                            if (onContentReadyCalled > 0) {
                                return;
                            }
                            ++onContentReadyCalled;
                            if (!value) {
                                args.component.clearSelection();
                            } else {
                                args.component.selectRows(value, false);
                            }
                        },
                        */
                        onSelectionChanged: args => {
                            const value = args.selectedRowKeys;
                            if (Array.isArray(value)) {
                                e.component.option('value', value[0]);
                                cellInfo.setValue(value[0]);
                                e.component.close();
                            }
                        },

                    }));
                const $treeView = $('<div />').dxTreeList(o);
                const treeView = $treeView.dxTreeList('instance');
                /*
                e.component.on('valueChanged', (args: any) => {
                    if (args.value) {
                        treeView.selectRows(args.value, false);
                    } else {
                        treeView.clearSelection();
                    }
                });
                */
                return $treeView;
            },
        };
        const x = new dxDropDownBox(div, {
            ...ddOptions,
        });
        div.appendTo(container);
    };
    grid.columns.push(column);
}
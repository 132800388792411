export const id = 'kosovo-singlechoicematrix';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';




itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '03d6ce7d-1716-4951-b2f7-1b2ba807d84f',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`]
        ])
    },
});

import * as ko from 'knockout';
import { ISerializeable } from './interfaces';


export async function koArray_ensureMinimumCount<T>(koArray: ko.ObservableArray<T>, count: Number, factory: () => Promise<T>) {
    if (count <= 0) {
        return;
    }
    if (!koArray) {
        return;
    }
    if (koArray.length >= count) {
        return;
    }
    const newItems = [];
    for (let i = koArray.length; i < count; ++i) {
        newItems.push(await factory());
    }
    koArray_addItems(koArray, newItems);
}

export function koArray_addItems<T>(koArray: ko.ObservableArray<T>, newItems: T[]) {
    if (!newItems) {
        return;
    }
    if (!newItems.length) {
        return;
    }
    const koArraySrc = koArray();
    ko.utils.arrayPushAll(koArraySrc, newItems);
    koArray.valueHasMutated();
}

export function koArray_replaceItems<T>(koArray: ko.ObservableArray<T>, newItems: T[]) {
    const koArraySrc = koArray();
    koArraySrc.splice(0, koArraySrc.length);
    ko.utils.arrayPushAll(koArraySrc, newItems);
    koArray.valueHasMutated();

}

export function isInList(list: ko.Observable<string[]>, item: string) {
    const x = new Set(list() || []);
    return x.has(item);
}

export function addToList(list: ko.Observable<string[]>, item: string) {
    const x = new Set(list() || []);
    x.add(item);
    const newArr = Array.from(x);
    newArr.sort();
    list(newArr);
}
export function deleteFromList(list: ko.Observable<string[]>, item: string) {
    const x = list();
    if (!x) {
        return;
    }
    const i = x.indexOf(item);
    if (i === -1) {
        return;
    }
    x.splice(i, 1);
    list(x);
}
export interface IKoArrayOptions<T, TJson> {
    key: (data: T) => string;
    jsonKey: (data: TJson) => string;
    factory: (data: TJson) => T;

    update?: (ko: T, data: TJson) => void;
}

export interface IJSONObservableArray<T, TJson> extends ko.ObservableArray<T> {
    options: IKoArrayOptions<T, TJson>;
}

export function koArray_new<T, TJson>(options: IKoArrayOptions<T, TJson>) {
    const retVal = <IJSONObservableArray<T, TJson>>ko.observableArray<T>();
    retVal.options = options;
    return retVal;
}

export function koArray_toJS<T extends ISerializeable<TJson>, TJson>(
    koArray: IJSONObservableArray<T, TJson>,
    factory: (data: TJson) => T) {
    const koArraySrc = koArray();
    return koArraySrc.map(x => x.toJS());
}

export function koArray_getKeys<T, TJson>(koArray: IJSONObservableArray<T, TJson>) {
    const koArraySrc = koArray();
    const options = koArray.options;
    if (!options) {
        throw new Error('options are not specified');
    }
    const retVal = new Set<string>();
    for (const obj of koArraySrc) {
        retVal.add(options.key(obj));
    }
    return retVal;
}

export function koArray_fromJS<T extends ISerializeable<TJson>, TJson>(
    koArray: IJSONObservableArray<T, TJson>,
    jsArray: TJson[]) {
    const koArraySrc = koArray();
    const options = koArray.options;
    const needsPushAll = false;
    if (!options) {
        throw new Error('options are not specified');
    }
    if (!options.factory) {
        throw new Error('Factory must be given');
    }

    if (!jsArray || !jsArray.length) {
        koArray.removeAll();
        return;
    }

    const koKeys = new Map<string, number>();
    for (const [index, obj] of Array.from(koArraySrc.entries())) {
        koKeys.set(options.key(obj), index);
    }

    const newKoArray: Array<PromiseLike<T> | T> = [];

    function update<T, TData>(obj: T, data: TData, updater: (obj: T, data: TData) => void) {
        updater(obj, data);
        return obj;
    }
    function fromJS<T extends ISerializeable<TData>, TData>(obj: T, data: TData) {
        obj.fromJS(data);
        return obj;
    }

    jsArray.forEach(jsObj => {
        const jsKey = options.jsonKey(jsObj);
        let koIndex = -1;
        let koObj: T = null;
        if (koKeys.has(jsKey)) {
            koIndex = koKeys.get(jsKey);
            koObj = koArraySrc[koIndex];
            if (options.update) {
                options.update(koObj, jsObj);
                newKoArray.push(koObj);
            } else {
                newKoArray.push(fromJS(koObj, jsObj));
            }
        } else {
            newKoArray.push(options.factory(jsObj));
        }
    });

    const koObjects = newKoArray;
    koArraySrc.splice(0, koArraySrc.length);
    ko.utils.arrayPushAll(koArraySrc, koObjects);
    koArray.valueHasMutated();

}

export function koArray_getItem<T, TJson>(
    koArray: IJSONObservableArray<T, TJson>,
    keyValue: string) {
    const koArraySrc = koArray();
    const options = koArray.options;
    if (!options) {
        throw new Error('options are not specified');
    }
    for (const obj of koArraySrc) {
        if (!obj) {
            continue;
        }
        if (options.key(obj) === keyValue) {
            return obj;
        }
    }
    return undefined;
}

const alpha = 'α'.charCodeAt(0);
const aLatin = 'A'.charCodeAt(0);

export function indexToGreek(nr: number) {
    if (nr < 0 || nr > 23) {
        throw new Error(`indexToGreek - nr must be in range [0-23] (was: ${nr})`);
    }
    return String.fromCharCode(alpha + nr);
}
export function indexToLatin(nr: number) {
    if (nr < 0 || nr > 25) {
        throw new Error(`indexToLatin - nr must be in range [0-25] (was: ${nr})`);
    }
    return String.fromCharCode(aLatin + nr);
}

export function greekToIndex(greekLetter: string) {
    if (greekLetter.length != 1) {
        throw new Error(`greekToIndex - greekLetter must be exactly one letter (was: ${greekLetter})`);
    }
    const index = greekLetter.charCodeAt(0) - alpha;
    if (index < 0 || index >= 24) {
        throw new Error(`greekToIndex - greekLetter must be a lowercase greek letter (was: ${greekLetter}`);
    }
    return index;
}
export function greekToLatin(greekLetter: string) {
    if (greekLetter.length != 1) {
        throw new Error(`greekToIndex - greekLetter must be exactly one letter (was: ${greekLetter})`);
    }
    const index = greekLetter.charCodeAt(0) - alpha;

    return String.fromCharCode(aLatin + index);
}

export function splitString(str: string, separator?: string | RegExp) {
    const sep = separator || ' ';
    const retVal: string[] = [];
    if (str) {
        const parts = str.split(sep);
        for (const x of parts) {
            const xTrimmed = x.trim();
            if (xTrimmed) {
                retVal.push(xTrimmed);
            }
        }
    }
    return retVal;
}

export function stringToSet(str: string) {
    const retVal = new Set<string>();
    if (!str) {
        return retVal;
    }
    ('' + str).split(' ').forEach(x => {
        if (x) {
            retVal.add(x);
        }
    });
    return retVal;

}
export function setToString(data: Set<string>) {
    const arr = Array.from(data);
    arr.sort();
    return arr.join(' ');
}

export function stringToBoolHash(str: string) {
    const retVal: { [key: string]: boolean } = {};
    ('' + str).split(' ').forEach(x => {
        if (x) {
            retVal[x] = true;
        }
    });
    return retVal;
}

export function boolHashToString(boolHash: { [key: string]: boolean }) {
    const arr: string[] = [];
    for (const key of Object.keys(boolHash)) {
        if (boolHash[key] === true) {
            arr.push(key);
        }
    }
    arr.sort();
    return arr.join(' ');
}

export function setValue(obj: any, accessor: string, value: any) {
    if (ko.isObservable(obj[accessor])) {
        obj[accessor](value);
    } else {
        obj[accessor] = value;
    }
}

export function setValues(obj: any, values: { [key: string]: any }) {
    for (const key of Object.keys(values)) {
        setValue(obj, key, values[key]);
    }
}

/// returns up to limit random items from the array - the returned items are
/// in the order of the array
export function pickItems<T>(array: T[], limit: number, options: IShuffleArrayOptions) {
    const retVal: T[] = [];
    if (limit >= array.length) {
        array.forEach(item => retVal.push(item));
    } else {
        let nr: number[] = [];
        for (let i = array.length - 1; i >= 0; --i) {
            nr.push(i);
        }
        shuffleArray(nr, options);
        nr = nr.splice(0, limit);
        nr.sort();
        for (let i = 0; i < nr.length; ++i) {
            retVal.push(array[nr[i]]);
        }
    }
    return retVal;

}

export interface IShuffleArrayOptions {
    static: boolean;
}
/**
 * Randomize array element order in-place.
 * Using Fisher-Yates shuffle algorithm.
 */
export function shuffleArray<T>(array: T[], options?: IShuffleArrayOptions) {
    if (options && options.static) {
        for (let i = 0; i < array.length - 1; i += 2) {
            const temp = array[i];
            array[i] = array[i + 1];
            array[i + 1] = temp;
        }
        return array;
    }
    let i = array.length;
    let j: number;
    let temp: T;
    while (--i > 0) {
        j = Math.floor(Math.random() * (i + 1));
        temp = array[j];
        array[j] = array[i];
        array[i] = temp;
    }

    return array;
}

export function getRandomEntry<T>(array: T[]) {
    if (!array.length) {
        return undefined;
    }
    return array[Math.floor(Math.random() * array.length)];

}

export function firstIndex<T>(array: T[], predicate: (item: T) => boolean) {
    const len = array.length;

    for (let i = 0; i < len; ++i) {
        const item = array[i];
        if (predicate(item)) {
            return i;
        }
    }
    return -1;
}

export function joinArray<T>(arrays: T[][], tgtArray: T[]) {
    if (!tgtArray) {
        tgtArray = [];
    }
    if (arrays && arrays.length) {
        for (let nArray = 0; nArray < arrays.length; ++nArray) {
            const arr = arrays[nArray];
            for (let nItem = 0; nItem < arr.length; ++nItem) {
                tgtArray.push(arr[nItem]);
            }
        }
    }
    return tgtArray;
}

export function filterArray<T>(array: T[], predicate: (item: T) => boolean) {
    const len = array.length;
    const retVal: T[] = [];

    for (let i = 0; i < len; ++i) {
        const item = array[i];
        if (predicate(item)) {
            retVal.push(item);
        }
    }
    return retVal;
}

export function filenameFromMimeType(baseName: string, mimeType: string) {
    switch (mimeType) {
        case 'image/png':
            return baseName + '.png';
        case 'image/jpg':
        case 'image/jpeg':
            return baseName + '.jpg';
        default:
            return baseName + '.bin';
    }
}

export type RingBufferSearchOption = 'next' | 'previous' | 'any';

export function findInRingBuffer<T>(arr: T[], startIndex: number, predicate: (item: T, index: number, arr: T[]) => boolean, searchOption?: RingBufferSearchOption): T {
    if (!arr) {
        return null;
    }
    const findNext = () => {
        for (let i = startIndex + 1; i < arr.length; ++i) {
            const item = arr[i];
            if (predicate(item, i, arr)) {
                return item;
            }
        }
        return undefined;
    };
    const findPrevious = () => {
        if (startIndex === -1) {
            startIndex = 0;
        }
        for (let i = startIndex - 1; i >= 0; --i) {
            const item = arr[i];
            if (predicate(item, i, arr)) {
                return item;
            }
        }
        return undefined;
    };
    const findNextFromStart = () => {
        for (let i = 0; i < startIndex; ++i) {
            const item = arr[i];
            if (predicate(item, i, arr)) {
                return item;
            }
        }
        return undefined;
    };
    const findAny = () => {
        val = findNext();
        return val ? val : findNextFromStart();
    };

    let val: T;
    if (!searchOption) {
        return findAny();
    }
    switch (searchOption) {
        case 'next':
            val = findNext();
            break;
        case 'previous':
            val = findPrevious();
            break;
        case 'any':
            val = findAny();
            break;
        default:
            throw new Error(`This option does not exist: ${searchOption}`);
    }
    return val;
}
export function getCreate<T>(map: Map<string, T>, key: string, cb: (key: string) => T): T {
    if (map.has(key)) {
        return map.get(key);
    }
    const v = cb(key);
    map.set(key, v);
    return v;
}

export function mapToPropertyMap<T>(map: Map<string, T>) {
    const result: { [key: string]: T } = {};
    for (const key of Array.from(map.keys())) {
        result[key] = map.get(key);
    }
    return result;
}
export function propertyMapToMap<T>(obj: { [key: string]: T }) {
    const retVal = new Map<string, T>();
    Object.keys(obj).forEach(objKey => {
        retVal.set(objKey, obj[objKey]);
    });
    return retVal;
}
export function toPropertyMap<T>(arr: T[], keyCallback: (item: T, index?: number) => string) {
    const retVal: { [key: string]: T } = {};
    if (arr && arr.length) {
        arr.forEach((value, index) => {
            const key = keyCallback(value, index);
            if (!key) {
                return;
            }
            retVal[key] = value;
        });
    }
    return retVal;
}

export function bucket<T, TKey>(arr: T[], keyCallback: (item: T, index?: number) => TKey): Map<TKey, T[]> {
    const retVal = new Map<TKey, T[]>();
    if (arr && arr.length) {
        arr.forEach((value, index) => {
            const key = keyCallback(value, index);
            if (!retVal.has(key)) {
                retVal.set(key, []);
            }
            retVal.get(key).push(value);
        });
    }
    return retVal;
}
export const id = 'kosovo-essay';

import { ItemMode } from '../../../model/interfaces';
import { itemDefinitionFactoryProvider } from '../../factoryProvider';



itemDefinitionFactoryProvider.register({
    id: id,
    uuid: '506f49af-8a99-476d-8d5d-a51f4ee3020e',
    widgetNames: {
        fallback: 'itemdefinition-' + id,
        modes: new Map<ItemMode, string>([
            ['EDIT', `itemdefinition-${id}-edit`],
            ['GRADING', `itemdefinition-${id}-grading`]
        ])
    },
    grading: 'SESSION',
});

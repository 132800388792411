import * as ko from 'knockout';
import { Color } from '../color';
import { KoMap } from '../komap';


export interface IColor {
    readonly name: string;
    readonly value: string;
}

const colors = new Array<IColor>(
    {
        name: '0',
        value: '#b57bcc'
    },
    {
        name: '1',
        value: '#7BA170'
    },
    {
        name: '2',
        value: '#b65d6f'
    },
    {
        name: '3',
        value: '#B4B4B4'
    },
    {
        name: '4',
        value: '#8fa8fc'
    },
    {
        name: '5',
        value: '#FD8204'
    },
    {
        name: '6',
        value: '#8FC440'
    },
    {
        name: '7',
        value: '#00A0AF'
    });


class ColorService {
    public readonly colorMap = new KoMap<IColor>();

    constructor() {
        this.colorMap.bulkSet({
            clear: true,
            data: colors,
            key: x => x.name,
            value: x => x
        });
    }
    public readonly colors = ko.pureComputed(() => {
        return Array.from(colors.values());
    });
    public getColor(name: string) {
        if (!name) {
            return '#000';
        }
        const x = this.colorMap.get(name);
        if (x) {
            return x.value;
        }
        if (name.startsWith('#')) {
            return name;
        }
        if (name.startsWith('rgb')) {
            return name;
        }
        return '#000';
    }
    public getColorLight(name: string, increase: number) {
        const x = this.getColor(name);
        const color = new Color(x);
        return color.lighten(increase).toHex();
    }
}

export const colorService = new ColorService();
